import { Component } from 'react';
import PropTypes from 'prop-types';

import { SideBar } from '../../../../../components/SideBar/SideBar';
import { withOverlay } from '../../../OverlayRoot/withOverlay';

/* istanbul ignore next */
const noop = () => {};

export class SideBarWrapper extends Component {
  constructor(props) {
    super(props);

    this.state = {
      slideAnimation: false,
    };
  }

  setSlideAnimation = (value) => {
    this.setState({ slideAnimation: value });
  };

  handleClose = () => {
    const { onClose, closeOverlay } = this.props;

    this.setSlideAnimation(false);
    // NOTE on this timeout: this is a small hack to avoid refactoring the
    // overlay logic in order to make the sidebar slide out animation to work
    setTimeout(onClose || closeOverlay, 200);
  };

  render() {
    const { title, footer, component, children, theme } = this.props;
    const { slideAnimation } = this.state;

    return (
      <SideBar
        open
        onClose={this.handleClose}
        title={title}
        footer={footer}
        slideAnimation={slideAnimation}
        setSlideAnimation={this.setSlideAnimation}
        theme={theme}
      >
        {component || children}
      </SideBar>
    );
  }
}

SideBarWrapper.propTypes = {
  title: PropTypes.any,
  footer: PropTypes.any,
  component: PropTypes.any,
  children: PropTypes.node,
  closeOverlay: PropTypes.func,
  onClose: PropTypes.func,
};

SideBarWrapper.defaultProps = {
  title: 'Menü',
  footer: null,
  component: null,
  children: null,
  closeOverlay: noop,
  onClose: null,
};

export const ConnectedSideBarWrapper = withOverlay(SideBarWrapper);

import styled from 'styled-components';

import { variables } from '../variables';

const { zIndex } = variables;

const elementHeight = '40px';

export const ContainerSt = styled.div`
  position: relative;
`;

export const InnerContainerSt = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);

  width: 100%;
  border-radius: 8px;

  background-color: ${({ theme: { colors } }) => colors.graySuperLight};

  cursor: pointer;
  user-select: none;
`;

export const LabelSt = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: ${zIndex.switchLabel};
  height: ${elementHeight};

  overflow: hidden;
  white-space: nowrap;
  font-size: 14px;
  text-overflow: ellipsis;
  line-height: 1.71;

  ${({ $isSelected }) => $isSelected && 'font-weight : bold'};

  ${({ theme: { colors }, $isSelected }) =>
    `color: ${!$isSelected ? colors.tarmacGrey : colors.brandSecondaryLight};`}
`;

export const ButtonSt = styled.div`
  position: absolute;
  top: 0;
  z-index: ${zIndex.switchButton};

  width: 33.3%;
  height: ${elementHeight};
  padding: 8px;
  border-radius: 8px;

  border: solid 1px ${({ theme: { colors } }) => colors.brandPrimary};
  background-color: rgb(237, 248, 246);

  ${({ $position }) => {
    switch ($position) {
      case 0:
        return 'transition: all ease-in 140ms; transform: translateX(0%)';
      case 2:
        return 'transition: all ease-in 140ms; transform: translateX(200%)';
      default:
        return 'transition: all ease-in 140ms; transform: translateX(100%)';
    }
  }}
`;

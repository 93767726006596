import { IGAMConfig } from 'app/shared/modules/Campaign/GAM/GamBanner/GamBanner.types';

export const bannerSizes = {
  sm: [{ width: 296, height: 112 }],
  md: [{ width: 712, height: 88 }],
  lg: [{ width: 952, height: 134 }],
  xl: [{ width: 876, height: 88 }],
};

export const bannerArea = {
  sm: { width: 296, height: 112 },
  md: { width: 712, height: 88 },
  lg: { width: 952, height: 134 },
  xl: { width: 876, height: 88 },
};

export const CLP_BANNER: IGAMConfig = {
  adUnitId: 'div-gam-clp-banner',
  adUnitPath: '/22376835783/clp-banner',
  sizes: bannerSizes,
  bannerArea,
  trackingData: {
    clickEvent: {
      eventName: 'clp_banner_click',
      placement: 'CLP',
    },
    impressionEvent: {
      category: 'CLP',
      label: 'CLP banner loaded',
    },
  },
};

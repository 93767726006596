import { combineReducers } from 'redux';

import { deliveryLocation } from './deliveryLocation';
import { pickupLocation } from './pickupLocation';
import { partnerDealer } from './partnerDealer';
import { insurance } from './insurance';
import { salesOffer } from './salesOffer';
import { paymentInfo } from './paymentInfo';
import { calculationInfo } from './calculationInfo';
import { cancelationPolicy } from './cancelationPolicy';
import { damagesReport } from './damagesReport';
import { accidentInfo } from './accidentInfo';

export const checkoutJourneyInfo = combineReducers({
  deliveryLocation,
  pickupLocation,
  partnerDealer,
  insurance,
  salesOffer,
  paymentInfo,
  calculationInfo,
  cancelationPolicy,
  damagesReport,
  accidentInfo,
});

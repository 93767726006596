import React from 'react';

import { TRACKING_LIST } from 'app/shared/modules/tracking/list';
import { trackAction } from 'app/shared/utils/tracking';
import { TBreakpoint } from 'app/shared/utils/utils';

import { CLPTeaser } from '../CLPTeaser/CLPTeaser';

interface ICarsale24Teaser {
  breakpoint: TBreakpoint;
}
export const Carsale24Teaser = ({
  breakpoint,
}: ICarsale24Teaser): JSX.Element => {
  function handleClick() {
    trackAction(TRACKING_LIST.INTEGRATIONS.CARSALE_24.CLP_ENTRY_POINT, {
      category: 'CLP',
      description: 'user clicks on CS24 teaser on CLP',
    });
  }

  const link = {
    label: 'Jetzt kostenlos verkaufen',
    url: '/verkaufen?customData=clp_tile',
    isInternalLink: true,
  };

  const imageMapping = {
    sm: '/assets/tradein/carsale24/clp-cs24_sm.png',
    md: '/assets/tradein/carsale24/clp-cs24_md.png',
    lg: '/assets/tradein/carsale24/clp-cs24_lg.png',
    xl: '/assets/tradein/carsale24/clp-cs24_xl.png',
  };

  const { title, description } =
    breakpoint === 'sm'
      ? { title: 'Verkaufe dein Auto online!', description: '' }
      : {
          title: 'Du willst dein altes Auto verkaufen?',
          description:
            'Reduziere die Kosten für deinen Neuen und verkaufe dein altes Auto zu Top-Preisen. Komplett stressfrei und nur an geprüfte Händler.',
        };

  return (
    <CLPTeaser
      title={title}
      description={description}
      link={link}
      imageUrl={imageMapping[breakpoint]}
      onClick={handleClick}
      breakpoint={breakpoint}
    />
  );
};

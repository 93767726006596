import { Heading4, Icon } from 'app/shared/ui';

import { trackAction } from 'app/shared/utils/tracking';
import {
  BenifitSt,
  LinkButtonSt,
} from 'app/marketplace/home/OnlineKaufTeaser/components/OnlineKaufTeaserDescription/OnlineKaufTeaserDescription.css';

export const OnlineKaufTeaserDescription = (): JSX.Element => {
  return (
    <>
      <Heading4 mt={['24px', 0]}>Online-Bestellung auf heycar</Heading4>
      <BenifitSt>
        <li>Wunsch-Fahrzeug finden und Konditionen anpassen</li>
        <li>Fahrzeuganfrage bequem online erstellen</li>
        <li>Vertrag digital abschließen - durch Online-Signatur</li>
        <li>
          Kostenlose Zulassung sowie Haustürlieferung oder Abholung beim Händler
          festlegen
        </li>
      </BenifitSt>
      <LinkButtonSt
        to="/auto-online-bestellen"
        variant="secondary"
        target="_self"
        onClick={() =>
          trackAction('online_purchase_more_info_cta_click', {
            category: 'home',
            description:
              'user clicks on purchase more cta from video component',
          })
        }
      >
        <Icon icon="arrow-right-long" mr={16} iconSize="xsmall" />
        Mehr zur Online-Bestellung
      </LinkButtonSt>
    </>
  );
};

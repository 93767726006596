/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { IDamagesReport } from 'app/shared/modules/DamagesReport/DamagesReport.types';

import {
  DAMAGES_REPORT_GET,
  DAMAGES_REPORT_GET_SUCCESS,
  DAMAGES_REPORT_GET_ERROR,
} from '../../constants';

interface IDamagesReportState {
  report?: IDamagesReport;
  isLoading: boolean;
  error?: IErrorShape;
}

export const initialState: IDamagesReportState = {
  isLoading: false,
  report: undefined,
  error: undefined,
};

export function damagesReport(
  state = initialState,
  action: { type: string; payload: IDamagesReport | IErrorShape | null },
) {
  const { type, payload } = action;

  switch (type) {
    case DAMAGES_REPORT_GET:
      return { ...state, error: undefined, isLoading: true };
    case DAMAGES_REPORT_GET_SUCCESS:
      return { ...state, report: payload, isLoading: false };
    case DAMAGES_REPORT_GET_ERROR:
      return { ...state, error: payload, isLoading: false };
    default:
      return state;
  }
}

/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, useState } from 'react';
import { useInView } from 'react-intersection-observer';

import { noop } from 'app/shared/utils/utils';
import { trackAction } from 'app/shared/utils/tracking';
import { MakeLogo } from 'app/shared/components/MakeLogo/MakeLogo';

import {
  TileContainerSt,
  TileSt,
  calculateTileContainerWidth,
  getSlideWidth,
} from './Tile.css';
import {
  MakesSt,
  MakesTitleSt,
  SliderOuterSt,
  TooltipSt,
  TooltipArrowSt,
  ArrowLeftSt,
  ArrowRightSt,
} from './Makes.css';

interface IPossibleMake {
  count: number;
  displayName: string;
  key: string;
  make: string;
  modelsPerMake: any[];
}
export interface IMakes {
  possibleMakes: IPossibleMake[];
  resetListing: () => void;
  breakpoint?: string;
  resetFilters?: (filters: string[]) => void;
}
export const Makes = ({
  resetFilters = noop,
  resetListing,
  possibleMakes,
  breakpoint = 'sm',
}: IMakes): JSX.Element => {
  const [sliderPosition, setSliderPosition] = useState(0);
  const [makeContainerRef, inView] = useInView({
    triggerOnce: true,
    rootMargin: '200px 0px',
  });

  const resetSliderState = () => {
    setSliderPosition(0);
  };

  useEffect(() => {
    resetFilters(['allMakes']);
    window.addEventListener('resize', resetSliderState);

    return () => {
      window.removeEventListener('resize', resetSliderState);
    };
  }, []);

  const getMakeOptions = () => {
    return possibleMakes.map((make: IPossibleMake) => ({
      label: make.displayName,
      value: make.key,
      link: `/gebrauchtwagen/${make.key}`,
    }));
  };

  const onSlide = (toLeft = false) => {
    const containerWidth = calculateTileContainerWidth(
      possibleMakes.length,
      breakpoint,
    );
    const toSlide = getSlideWidth(breakpoint);
    const currentPosition = sliderPosition;
    let shouldSlide = false;

    trackAction('our_brands_scroll_click', {
      category: 'home',
      label: toLeft ? 'left' : 'right',
      description: 'user clicks the make carousel direction arrows',
    });

    if (toLeft) {
      shouldSlide = toSlide + currentPosition <= 0;
      setSliderPosition(shouldSlide ? toSlide + currentPosition : 0);
    } else {
      shouldSlide = toSlide - currentPosition <= containerWidth;
      setSliderPosition(shouldSlide ? currentPosition - toSlide : 0);
    }
  };

  const onBrandClick = (make: string) => {
    resetListing();

    trackAction('our_brands_logo_click', {
      category: 'home',
      label: `${make}`,
      description: 'user clicks on a make logo SEO link',
    });
  };

  return (
    <MakesSt grid md={12} ref={makeContainerRef}>
      <MakesTitleSt as="h2">Unsere Marken</MakesTitleSt>

      <SliderOuterSt>
        <TileContainerSt
          tileNumber={possibleMakes.length}
          position={sliderPosition}
        >
          {getMakeOptions().map((option) => (
            <TileSt
              key={option.value}
              to={option.link}
              onClick={() => onBrandClick(option.value)}
              data-testid={`marketplace-home-Makes-${option.value}`}
            >
              <MakeLogo
                makeKey={option.value}
                size={100}
                scaleTo={70}
                adjustPosition
                isVisible={inView}
                shouldLoadAllMakes
              />

              <TooltipSt>
                {option.label}
                <TooltipArrowSt />
              </TooltipSt>
            </TileSt>
          ))}
        </TileContainerSt>
      </SliderOuterSt>

      <ArrowLeftSt
        className="if-icon-arrow-left-short"
        disabled={sliderPosition === 0}
        onClick={() => onSlide(true)}
      />
      <ArrowRightSt
        className="if-icon-arrow-right-short"
        onClick={() => onSlide()}
      />
    </MakesSt>
  );
};

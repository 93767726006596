import styled, { css } from 'styled-components';
import { Flex } from '@rebass/grid';

import { color, media } from 'app/shared/styles_js/variables';

export const CartileImageUnavailableContainerSt = styled(Flex).attrs(() => ({
  alignItems: 'center',
  flexDirection: 'column',
  justifyContent: 'center',
}))`
  position: relative;
  overflow: hidden;
  width: 100vw;
  height: 100%;
  border-radius: 4px 4px 0 0;
  background-color: ${color.grayButton};

  ${media.md(css`
    width: 100%;
  `)}
`;

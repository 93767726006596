/* eslint-disable react-perf/jsx-no-new-function-as-prop */
import { Redirect, Route } from 'react-router';

import { getAsyncRouteComponentLoader } from 'app/shared/utils/utils';
import { ConnectedPageTemplate } from 'app/shared/modules/pageTemplate/PageTemplate/ConnectedPageTemplate';

export const PremiumRoutes = (
  <Route
    name="Beratung"
    component={(props) => (
      <ConnectedPageTemplate target="Beratung" {...props} />
    )}
  >
    <Route
      path="/kostenlose-beratung"
      getComponent={getAsyncRouteComponentLoader(
        'ConnectedPremiumLandingPage',
        () => import('./PremiumLandingPage/ConnectedPremiumLandingPage'),
      )}
    />
    <Redirect from="/persoenliche-beratung" to="/kostenlose-beratung" />
  </Route>
);

import { push } from 'react-router-redux';
import scrollIntoView from 'scroll-into-view';

import { height } from 'app/shared/styles_js/variables';
import { triggerFormChange } from 'app/shared/modules/FDL/CalculatorV3/redux/actions';
import { isAndroidDevice, isDesktopSafari } from 'app/shared/utils/utils';
import { getIsDealerFinanceVehicle3Way } from 'app/marketplace/utils/getIsDealerFinanceVehicle3Way';

import { trackAction } from '../../utils/tracking';
import { TRACKING_LIST } from '../tracking/list';

import { fdlDealerCheck, getTrackingSettings } from './utils';
import { SET_SHOW_FIXED_BUTTON, TOGGLE_WIZARDPOPUP } from './constants';

const trackingSettings = getTrackingSettings();
const OFFSET_TOP = height.topNavBar;

export function toggleWizardPopup() {
  return (dispatch, getState) => {
    const {
      fdlCalculator: { isOpenWizardPopup },
    } = getState();

    trackAction(
      trackingSettings.name,
      {
        category: trackingSettings.properties.category,
        label: 'toggle_wizard_popup',
        value: !isOpenWizardPopup + 0,
      },
      trackingSettings.additionalProperties,
    );

    return dispatch({ type: TOGGLE_WIZARDPOPUP });
  };
}

/**
 * Scroll action for FDL Calculator
 * (REDUX ACTION)
 *
 * @export
 * @param {number|string} listing_id Listing ID (Vehicle ID)
 * @param {boolean} isD2cVehicle is it a d2c vehicle or not
 * @returns {function} redux action
 */

export function goToFDLSection(listing_id, isD2cVehicle, additionalScroll = 0) {
  return (dispatch, getState) => {
    const {
      vehicle: { vehicle },
    } = getState();
    const hasFDL = fdlDealerCheck(
      vehicle.financingPrecalc?.searchFilters?.captiveId,
      vehicle,
    );
    const isDealerFinancingVehicle = getIsDealerFinanceVehicle3Way(vehicle);

    if (hasFDL) {
      if (isD2cVehicle) {
        trackAction(
          'interacted_with_d2c_fin_calculator',
          {
            category: 'fdl',
            label: 'opened',
          },
          {
            listing_id,
          },
        );
      } else if (isDealerFinancingVehicle) {
        trackAction(
          TRACKING_LIST.FINANCING.CALCULATOR.INTERACTION,
          {
            category: 'fdl',
            label: 'opened',
            value: 'finanzierung_starten_bboni_cta',
          },
          {
            listing_id,
            captiveId: vehicle?.financingPrecalc?.searchFilters?.captiveId,
          },
        );
      } else {
        trackAction(
          TRACKING_LIST.FINANCING.CALCULATOR.INTERACTION,
          {
            category: 'fdl',
            label: 'opened',
          },
          {
            listing_id,
            captiveId: vehicle?.financingPrecalc?.searchFilters?.captiveId,
          },
        );
      }
    }

    const scrollToSection = () => {
      const htmlEl = document.querySelector('#financial-accordion-item');

      const winScrollY = window.scrollY || window.pageYOffset;
      if (htmlEl) {
        const scrollY =
          htmlEl.getBoundingClientRect().top -
          OFFSET_TOP +
          winScrollY +
          additionalScroll;

        if (htmlEl.scrollTo) {
          // Ignore scroll animation in desktop safari browsers as it's not smooth
          // Also, android browsers do not support scrollIntoView
          if (isDesktopSafari() || isAndroidDevice()) {
            /* istanbul ignore next */
            window.scrollTo({
              behavior: 'smooth',
              top: scrollY,
            });
          } else {
            scrollIntoView(htmlEl, {
              time: 800,
              behavior: 'smooth',
              align: { top: 0, topOffset: OFFSET_TOP + additionalScroll },
            });
          }
        } else {
          window.scroll(0, scrollY);
        }
      }
    };

    scrollToSection();

    if (hasFDL) {
      dispatch(triggerFormChange());
    }
  };
}

/**
 * Redirect user to CLP
 * (REDUX ACTION)
 *
 * @export
 * @param {string} [financingProduct] Financing product to search for
 * @param {boolean} [forceVehicleLoad=false] Force Vehicle Load
 * @returns {function} redux action
 */
export function goToSearch(financingProduct, forceVehicleLoad = false) {
  return (dispatch) => {
    return dispatch(
      push({
        pathname: '/gebrauchtwagen',
        query: {
          financingProduct,
        },
        state: { forceVehicleLoad },
      }),
    );
  };
}

export function setShowFixedButton({ showFixedButton }) {
  return {
    type: SET_SHOW_FIXED_BUTTON,
    showFixedButton,
  };
}

import styled from 'styled-components';

import { Icon, Text3 } from 'app/shared/ui';
import { colorV2, zIndex } from 'app/shared/styles_js/variables';
import { InputSearchWithSuggestions } from 'app/marketplace/home/Stage/LocationFilter/InputSearchWithSuggestions/InputSearchWithSuggestions';

export const FilterPanelLocationFilterContainerSt = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  margin: 8px 0;
`;

export const FilterPanelLocationFilterIconSt = styled(Icon)`
  position: absolute;
  z-index: ${zIndex.navbar};
  top: 18px;
  right: 16px;
  cursor: pointer;
`;

export const FilterPanelLocationFilterErrorTextSt = styled(Text3)`
  margin-top: 8px;
  text-align: center;
  background-color: white;
  color: ${colorV2.brandDanger};
`;

export const FilterPanelLocationFilterInputSt = styled(
  InputSearchWithSuggestions,
)``;

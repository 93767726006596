/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-return */
import { Text2, Link, ContainerSt } from 'app/shared/ui';
import styled, { css } from 'styled-components';

import { color, media } from 'app/shared/styles_js/variables';

export const CategoryLinksSt = styled(ContainerSt)`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding-top: 24px;

  ${media.md(
    css`
      padding-top: 32px;
    `,
  )};
`;

export const CategorySt = styled(Link)`
  position: relative;
  min-width: ${100 / 3}%;
  margin-bottom: 24px;
  text-align: center;
  font-weight: normal !important;
  text-decoration: none !important;
  color: ${({ theme: { colors } }) => colors.micaBlue};

  ${media.md(css`
    min-width: ${100 / 4}%;

    &:after {
      width: 1px;
    }
    &:last-child:after,
    &:nth-child(5n):after {
      width: 0;
    }
  `)};

  ${media.lg(css`
    min-width: ${100 / 8}%;

    &:after,
    &:nth-child(5n):after {
      width: 1px;
    }
    &:last-child:after {
      width: 0;
    }
  `)};

  &:hover {
    color: ${({ theme: { colors } }) => colors.actionBlue};
    font-weight: unset;
  }

  &::after {
    position: absolute;
    top: 25%;
    right: 0;
    height: 70%;
    content: '';
    background-color: ${color.grayLight};
  }

  & > i {
    font-size: 56px;

    ${media.md(css`
      font-size: 72px;
    `)};
  }
`;

export const LabelSt = styled(Text2)`
  display: block;
  margin-top: -8px;

  ${media.md(
    css`
      margin-top: 0;
    `,
  )};
`;

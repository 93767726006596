/* istanbul ignore file */

import styled, { css } from 'styled-components';
import { Text } from 'app/shared/ui';

import { media } from 'app/shared/styles_js/variables';

export const ShowMoreSt = styled.div`
  position: relative;
  display: inline-block;

  ${({ showAll }) =>
    !showAll &&
    css`
      min-height: ${({ height }) => `${height}px`};
      height: ${({ height }) => `${height}px`};
      ${media.md(css`
        min-height: ${({ height }) => `${height}px`};
        height: ${({ height }) => `${height}px`};
      `)};
      overflow: hidden;
    `}
`;

const getColorCss = ({ theme: { colors }, legalText }) => {
  let color;
  let hoverColor;
  if (legalText) {
    color = colors.grayDark;
    hoverColor = colors.grayLight;
  } else {
    color = colors.mintDark;
    hoverColor = colors.brandPrimary;
  }

  return css`
    color: ${color};
    transition: 200ms color ease-in-out;

    &:hover {
      color: ${hoverColor};
    }
  `;
};

export const ShowLessButtonSt = styled(Text).attrs(() => ({
  as: 'span',
  display: 'inline-block',
  color: 'mintDark',
  hoverColor: 'brandPrimary',
  fontWeight: 'bold',
  fontSize: '8px',
  lineHeight: '10px',
}))`
  padding: 0 10px 0 3px;
  cursor: pointer;
  text-decoration: underline;
  white-space: nowrap;

  background: ${({ theme: { colors }, $onWhite }) =>
    !$onWhite ? colors.sunbeanSuperLight : colors.white};

  ${getColorCss}

  span {
    ${getColorCss}
  }
`;

export const ShowMoreButtonSt = styled(Text).attrs(() => ({
  as: 'span',
  color: 'grayDark',
  fontSize: '8px',
  lineHeight: '10px',
}))`
  position: absolute;
  right: 0;
  bottom: 0;
  display: flex;
  padding: 0 10px 0 3px;
  white-space: nowrap;
  background: ${({ theme: { colors }, $onWhite }) =>
    !$onWhite ? colors.sunbeanSuperLight : colors.white};
`;

export const ActionTextSt = styled(Text).attrs(
  /* istanbul ignore next */ (props) => ({
    as: 'span',
    fontWeight: 'bold',
    fontSize: '8px',
    lineHeight: '10px',
    ...props,
  }),
)`
  cursor: pointer;
  text-decoration: underline;
  ${getColorCss}

  span {
    ${getColorCss}
  }
`;

import React from 'react';

import { TilePillSt } from './TilePill.css';
import { ITilePillProps } from './TilePill.types';

export const TilePill = ({
  children,
  isLoading,
  className,
  bg = 'grayLightest',
  color = 'tarmacGrey',
  ...props
}: ITilePillProps): JSX.Element => (
  <TilePillSt
    isLoading={isLoading}
    className={className}
    bg={bg}
    color={color}
    {...props}
  >
    {children}
  </TilePillSt>
);

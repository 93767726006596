import styled, { createGlobalStyle, css } from 'styled-components';

import { color as outdatedColors } from 'app/shared/ui/components/variables/outdated-colors';
import grid from 'app/shared/ui/styles_js/grid';
import { iconFont } from 'app/shared/ui/styles_js/iconFont';
import { media, fontSizes, fontWeights } from 'app/shared/styles_js/variables';
import { BODY_OVERLAY_LOCK_CLASS } from 'app/shared/components/Overlay/Overlay';
// This css file was added on behalf of: https://heycar.atlassian.net/browse/PROMO-3661
import { normalize } from 'app/normalize.css.js';
import { colors } from 'app/shared/ui/components/variables/colors';

function createCSSCustomPropertiesFromObject(object) {
  return Object.entries(object).map(
    ([colorName, colorValue]) => `--${colorName}: ${colorValue};`,
  );
}

// eslint-disable-next-line
export const GlobalStyle = createGlobalStyle`
  ${normalize}
  ${grid}

  :root {
    /* makes our theme colors available as css variables in the HTML root */
    ${() =>
      createCSSCustomPropertiesFromObject({ ...outdatedColors, ...colors })}
  }

  *,
  *::before,
  *::after {
    box-sizing: inherit;
    outline: none;
  }

  html {
    box-sizing: border-box;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  }

  body {
    overflow: hidden;
    font-size: ${fontSizes.p2};
    font-weight: ${fontWeights.regular};
    line-height: 1.71;
    color: var(--tarmacGrey);
    background-color: #fff;
    /**
     * Use the adjusted font with the nearest resemblance to the original font initially until the original fonts are loaded to avoid Flash Of Unstyled Text
     * See: https://screenspan.net/fallback
     */
    font-family: 'Adjusted Arial Fallback', sans-serif, 'Helvetica Neue', Helvetica, Arial;
    letter-spacing: 0.6px;

    .fonts-loaded &,
    .fonts-observer-fail & {
      letter-spacing: 0;
      font-family: 'Objektiv', 'Adjusted Arial Fallback', sans-serif, 'Helvetica Neue', Helvetica, Arial;
    }
  }

  button span {
    letter-spacing: 0.4px;
    font-weight: 500;
    font-family: 'Adjusted Arial Fallback', sans-serif, 'Helvetica Neue', Helvetica, Arial;

    .fonts-loaded &,
    .fonts-observer-fail & {
      letter-spacing: 0;
      font-weight: 300;
      font-family: 'Objektiv', 'Adjusted Arial Fallback', sans-serif, 'Helvetica Neue', Helvetica, Arial;
    }
  }

  ${`body.${BODY_OVERLAY_LOCK_CLASS}`} {
    overflow: hidden !important;
  }

  .grecaptcha-badge {
    visibility: hidden;
  }

  ${media.md(css`
    body {
      font-size: ${fontSizes.p};
      line-height: 2;
    }
  `)};

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 0;
    font-weight: ${fontWeights.bold};
  }

  a {
    color: var(--mintDark);
    text-decoration: none;

    &:hover,
    &:focus {
      color: var(--brandPrimary);
      text-decoration: none;
    }

    &:focus {
      /* WebKit-specific. Other browsers will keep their default outline style.
      (Initially tried to also force default via 'outline: initial',
      but that seems to erroneously remove the outline in Firefox altogether.) */
      outline: 5px auto -webkit-focus-ring-color;
      outline-offset: -2px;
    }

    &:hover {
      cursor: pointer;
    }
  }

  b,
  strong {
    font-weight: ${fontWeights.bold};
  }

  img {
    max-width: 100%;
    vertical-align: middle;
  }

  input,
  button,
  select,
  textarea {
    font: inherit;
    line-height: inherit;
  }

  ::-webkit-input-placeholder {
    line-height: normal;
  }

  .button-reset {
    padding: 0;
    border: 0;
    background-color: transparent;
    border-radius: 0;
  }

  i::before {
    content: "□";
  }

  .text-left {text-align: left;}
  .text-right {text-align: right;}
  .text-center {text-align: center;}
  .text-justify {text-align: justify;}
  .text-nowrap {white-space: nowrap;}

  .text-lowercase {text-transform: lowercase;}
  .text-uppercase {text-transform: uppercase;}
  .text-capitalize {text-transform: capitalize;}

  ${iconFont}

  .adBanner {
    display: block;
    height: 2px;
    width: 2px;
    visibility:hidden;
  }
`;

/* eslint-disable */
export const AppSt = styled.div`
  display: block;
`;
/* eslint-enable */

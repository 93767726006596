import { DefaultTheme } from 'styled-components';

import { variables } from '../variables';
import { OutdatedColorsTheme } from '../../types/styled.d';

import { buttons } from './buttons';

const { color, colorRgb, colorV2, colorV2Rgb, getRgba, iconSizes } = variables;

export const theme: OutdatedColorsTheme = {
  mode: 'webApp',
  fontSizes: [10, 12, 14, 16, 20, 24, 32, 40, 48, 64],
  fontWeights: {
    regular: '200',
    medium: '300',
    bold: '500',
  },
  colors: color,
  colorsRgb: colorRgb,
  getRgba,
  space: [0, 4, 8, 16, 32, 64, 128, 256],
  fonts: {
    sans: 'system-ui, sans-serif',
    mono: 'Menlo, monospace',
  },
  breakpoints: [768, 1024, 1280],
  iconSizes,
  buttons,
};

// showcase demo theme
export const darkTheme: OutdatedColorsTheme = {
  mode: 'webAppDark',
  fontSizes: theme.fontSizes,
  fontWeights: theme.fontWeights,
  colors: {
    ...color,
    brandPrimaryDark: '#004c3d',
    brandPrimary: '#004035',
  },
  space: theme.space,
  fonts: theme.fonts,
  breakpoints: theme.breakpoints,
  iconSizes,

  buttons: {
    primary: buttons.primary,
    secondary: {
      default: 'white',
      hover: 'white',
      active: 'white',
      disabled: color.grayLight,
      color: color.brandSecondary,
      hoverColor: color.brandPrimary,
      activeColor: color.brandSecondary,
      borderColor: 'transparent',
      disabledColor: 'white',
    },
    tertiary: {
      default: 'transparent',
      hover: 'transparent',
      active: 'transparent',
      disabled: 'transparent',
      color: 'white',
      hoverColor: color.brandPrimary,
      activeColor: 'white',
      borderColor: 'white',
      disabledColor: color.grayDark,
      disabledBorderColor: color.grayDark,
    },
  },
};

// TODO: move into web-app when rebranding is finished
export const themeV2: DefaultTheme = {
  ...theme,
  mode: 'v2',
  rebranding: true,
  colors: colorV2,
  colorsRgb: colorV2Rgb,
  getRgba,
  buttons, // TODO - will be replaced with CTA PR - PROMO-1733
};

import { call, put, takeEvery } from 'redux-saga/effects';

import * as Config from 'app/config';
import * as ActionTypes from 'app/marketplace/contactDealer/constants';
import { apiCallWithRecaptchaSaga } from 'app/shared/modules/recaptcha/utils/apiCallWithRecaptcha';
import { trackCallStartButton } from 'app/marketplace/contactDealer/Call/tracking';

import { PHONECALL_LEAD_ENDPOINT } from '../utils';

export function* initiateCallSaga({
  vehicle,
  openSidebar,
  target,
  eventLabel,
  response,
}) {
  const { phoneNumber, pin: phonePin } = response;

  if (phoneNumber) {
    yield call(trackCallStartButton, vehicle, phonePin, target, eventLabel);
    const phoneToCall = `${phoneNumber}${phonePin ? `,${phonePin}` : ''}`;
    window.location.assign(`tel:${phoneToCall}`);
  } else {
    // fallback in case of some error
    openSidebar('call');
  }
}

/**
 * Posts data to backend in order to get the Freespee or Twilio number back.
 * @param {object} payload: contains the url and form data
 */
export function* getPhoneNumber({ payload }) {
  const {
    form,
    vehicle,
    eventLabel,
    target,
    openSidebar,
    shouldInitiateCall,
  } = payload;
  try {
    const response = yield call(
      apiCallWithRecaptchaSaga,
      `${Config.COMMON_API_URL}${PHONECALL_LEAD_ENDPOINT}`,
      'phone_lead_get_number',
      { body: JSON.stringify(form) },
    );
    yield put({
      type: ActionTypes.GET_PHONE_INFO_SUCCESS,
      payload: response,
    });

    if (shouldInitiateCall) {
      yield call(initiateCallSaga, {
        vehicle,
        openSidebar,
        target,
        eventLabel,
        response,
      });
    }
  } catch (error) {
    yield put({ type: ActionTypes.GET_PHONE_INFO_FAILURE, payload: { error } });
    if (shouldInitiateCall) openSidebar('call');
  }
}

/* Watches the action in order to get the corresponding phone number */
export function* watchGetPhoneNumber() {
  yield takeEvery([ActionTypes.GET_PHONE_INFO_PENDING], getPhoneNumber);
}

import styled, { css } from 'styled-components';
import {
  Container,
  xlContainerStyles,
  lgContainerStyles,
  mdContainerStyles,
} from 'app/shared/ui';

import { media } from 'app/shared/styles_js/variables';
import { SpecialOfferTile } from 'app/marketplace/specialOffers/SpecialOfferTile/SpecialOfferTile';
import { SpecialOfferTeaserCard } from 'app/marketplace/specialOffers/SpecialOfferTeaserCard/SpecialOfferTeaserCard';

export const SpecialOffersSliderSt = styled(Container)`
  display: block;
  overflow: hidden;
  margin-top: 48px;
  margin-right: auto;
  margin-left: auto;
  width: 100%;
  ${mdContainerStyles}
  ${lgContainerStyles}
  ${xlContainerStyles}
  ${media.md(css`
    margin-top: 56px;
    overflow: visible;
  `)}
  ${media.lg(css`
    margin-top: 64px;
  `)}
`;

export const SpecialOfferTileSt = styled(SpecialOfferTile)`
  margin: 8px;

  ${media.md(css`
    margin: 12px;
  `)}
`;

export const SpecialOfferTeaserCardSt = styled(SpecialOfferTeaserCard)`
  margin: 8px;

  ${media.md(css`
    margin: 12px;
  `)}
`;

export const CustomNavArrow = ({
  direction,
  currentSlide,
  slideCount,
  className,
  ...rest
}) => (
  <div className={`${className}`}>
    <i className={`if-icon-arrow-${direction}-short`} {...rest} />
  </div>
);
// this is not cool, but the shared component does not allow for free styling of these arrows
export const NavArrowSt = styled(CustomNavArrow)`
  top: -32px !important;
  ${({ theme: { colors } }) =>
    css`
      transition: color 200ms ease-in-out;
      &.slick-arrow {
        color: ${colors.micaBlue};

        &:hover, &:active, &:focus {
          color: ${colors.brandPrimary};
        }

        &.slick-disabled {
          color: ${colors.blueLighter};
        }
      `}
`;

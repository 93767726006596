import PropTypes from 'prop-types';
import { Box } from '@rebass/grid';
import styled from 'styled-components';

export const ContentBoxSt = styled(Box).attrs(
  ({ title, hasNoContent, styles }) => ({
    as: 'section',
    role: 'tabpanel',
    width: 1,
    'aria-labelledby': /* istanbul ignore next */ hasNoContent ? '' : title,
    ...styles,
  }),
)`
  z-index: 2;
`;

export const ContentBox = ({
  children,
  title,
  label,
  hasNoContent,
  ...rest
}) => {
  return (
    <ContentBoxSt title={hasNoContent ? '' : label || title} {...rest}>
      {children}
    </ContentBoxSt>
  );
};

ContentBox.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  hasNoContent: PropTypes.bool,
  label: PropTypes.string,
};

ContentBox.defaultProps = {
  hasNoContent: false,
  label: '',
};

import { IGAMConfig } from 'app/shared/modules/Campaign/GAM/GamBanner/GamBanner.types';

const bannerSizes = {
  sm: [{ width: 320, height: 50 }],
  md: [{ width: 728, height: 90 }],
  lg: [{ width: 970, height: 250 }],
  xl: [
    { width: 1172, height: 250 },
    { width: 970, height: 250 },
  ],
};

const bannerArea = {
  sm: { width: 320, height: 50 },
  md: { width: 728, height: 90 },
  lg: { width: 970, height: 250 },
  xl: { width: 1172, height: 250 },
};

export const CLP_TOP_BANNER: IGAMConfig = {
  adUnitId: 'div-gam-clp-top-banner',
  adUnitPath: '/22376835783/clp-top-banner',
  sizes: bannerSizes,
  bannerArea,
  trackingData: {
    clickEvent: {
      eventName: 'clp_top_banner_clicked',
      placement: 'CLP',
      category: 'CLP',
      label: 'CLP Top banner clicked',
    },
    impressionEvent: {
      eventName: 'clp_top_banner_loaded',
      category: 'CLP',
      label: 'CLP Top banner served',
    },
  },
};

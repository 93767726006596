import { createMigrate, persistReducer } from 'redux-persist';
import { combineReducers } from 'redux';
import localStorage from 'redux-persist/lib/storage';
import storageSession from 'redux-persist/lib/storage/session';

import { sessionStorageReducers } from 'app/session-reducers';
import { localStore, sessionStore } from 'app/shared/utils/storageFactory';

import { cookieReducers } from './cookie-reducers';
import { localStorageReducers } from './local-reducers';
import { memoryStorageReducers } from './memory-reducers';
import * as ActionTypes from './constants';
import * as FlagrActionTypes from './shared/modules/flagr/constants';
import { isCookieSupported } from './shared/utils/utils';

const getPersistedStorageReducer = (storage, key, reducer) => {
  const migrationConfigs = {
    cookieDashboard: {
      currentVersion: 1, // Must match the migrations key we want to use
      migrations: createMigrate({
        1: (state) => ({
          ...state,
          hasSavedCookieSettings: false,
          preferences: {
            essential: true,
            functional: !!state.preferences?.functional,
            analytical: !!state.preferences?.analytical,
            marketing: !!state.preferences?.marketing,
          },
        }),
      }),
    },
  };

  const persistConfig = {
    key,
    storage,
    version: migrationConfigs[key]?.currentVersion || -1,
    migrate: migrationConfigs[key]?.migrations || null,
    timeout: null, // Bug in redux-persist: https://github.com/rt2zz/redux-persist/issues/786
  };

  if (reducer.whitelist) {
    persistConfig.whitelist = reducer.whitelist;
  }

  if (reducer.blacklist) {
    persistConfig.blacklist = reducer.blacklist;
  }

  return persistReducer(persistConfig, reducer);
};

const createStoredReducer = (storage, reducers) => {
  const result = {};

  Object.entries(reducers).forEach(([name, reducer]) => {
    result[name] = getPersistedStorageReducer(storage, name, reducer);
  });

  return result;
};

const initialState = {
  featureFlags: [],
  areFeatureFlagsInitialised: false,
  deviceType: 'mobile',
  theme: {},
};

export const getAppReducer = (cookieStorage, hasUserConsent = true) => {
  const appReducer = (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {
      case ActionTypes.SET_PREVIOUS_LOCATION:
        return { ...state, previousLocation: payload };
      case FlagrActionTypes.GET_FEATURE_FLAGS:
        return { ...state, featureFlags: payload };
      case FlagrActionTypes.FEATURE_FLAGS_INITIALISED:
        return { ...state, areFeatureFlagsInitialised: payload };
      case FlagrActionTypes.FEATURE_FLAGS_GETUSERGROUP:
        return { ...state, abGroup: payload };
      case ActionTypes.SET_SHORT_CIRCUIT:
        return { ...state, shortCircuit: payload };
      case ActionTypes.SET_ERROR_MESSAGE:
        return { ...state, errorMessage: payload.error };
      case ActionTypes.SET_REDIRECT:
        return { ...state, redirect: payload };
      case ActionTypes.SET_THEME:
        return { ...state, theme: payload };
      default:
        return state;
    }
  };

  const persistedCookieStorageReducer =
    isCookieSupported(cookieStorage) && hasUserConsent
      ? createStoredReducer(cookieStorage, cookieReducers)
      : cookieReducers;

  const persistedLocalStorageReducer =
    localStore.isSupported() && hasUserConsent
      ? createStoredReducer(localStorage, localStorageReducers)
      : localStorageReducers;

  const persistedSessionStorageReducer =
    sessionStore.isSupported() && hasUserConsent
      ? createStoredReducer(storageSession, sessionStorageReducers)
      : sessionStorageReducers;

  const reducers = {
    ...persistedCookieStorageReducer,
    ...persistedLocalStorageReducer,
    ...memoryStorageReducers,
    ...persistedSessionStorageReducer,
    app: appReducer,
  };

  return combineReducers(reducers);
};

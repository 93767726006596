import styled from 'styled-components';
import { Flex } from '@rebass/grid';

export const RadioSt = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  flex-direction: ${(props) => (props.isRight ? 'row-reverse' : 'row')};
  margin-bottom: 16px;
  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
  user-select: none;
`;

export const IconSt = styled.i`
  padding-right: 14px;
  font-size: ${(props) => `${props.size}px`};
  line-height: normal;
  color: ${({ theme, isChecked, disabled }) =>
    isChecked && !disabled ? theme.colors.brandPrimary : theme.colors.gray};

  &:active {
    color: ${({ theme, disabled }) => !disabled && theme.colors.brandPrimary};
  }
`;

export const ChildrenSt = styled.div`
  display: flex;
  flex: 1;
  line-height: 24px;
  color: ${({ theme, disabled }) =>
    disabled ? theme.colors.gray : theme.colors.brandSecondary};
  font-size: ${({ textSize }) => `${textSize}px`};
`;

export const RadioContainerSt = styled(Flex)`
  ${RadioSt} {
    margin-bottom: ${({ flexDirection }) =>
      flexDirection === 'row' ? '4px' : '16px'};
  }

  div:last-of-type {
    margin-bottom: 0;
  }
`;

export const ErrorWrapper = styled.div`
  min-height: 24px;
  margin-top: 4px;
  font-size: 12px;
  color: ${({ theme }) => theme.colors.brandDanger};
`;

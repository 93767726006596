/* eslint no-console: "off" */

export const runEasterEgg = () => {
  if (CLIENT && PRODUCTION && window.console) {
    console.log(`
 __                                                   
/\\ \\                                                  
\\ \\ \\___      __   __  __        ___     __     _ __  
 \\ \\  _ \`\\  /'__\`\\/\\ \\/\\ \\      /'___\\ /'__\`\\  /\\\`'__\\
  \\ \\ \\ \\ \\/\\  __/\\ \\ \\_\\ \\  __/\\ \\__//\\ \\L\\.\\_\\ \\ \\/ 
   \\ \\_\\ \\_\\ \\____\\\\/\`____ \\/\\_\\ \\____\\ \\__/.\\_\\\\ \\_\\ 
    \\/_/\\/_/\\/____/ \`/___/> \\/_/\\/____/\\/__/\\/_/ \\/_/ 
                       /\\___/                         
                       \\/__/                          
  `);

    console.log(`
    Wanna work with us?
    
    Buzzwords:
    - AWS
    - Kubernetes
    - React
    - Redux
    - Continuous Deployment
    - 100% Test Coverage
    `);
    console.log('Apply here: https://heycargroup.com/careers');
  }
};

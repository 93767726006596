import { IColorsList, TOutdatedColor } from './index.types';

export const colors: Readonly<Omit<IColorsList, TOutdatedColor>> = {
  grayDarker: '#838383',
  grayDark: '#838383',
  gray: '#C1C1C1',
  grayLight: '#C1C1C1',
  grayLightest: '#F5F5F5',
  graySuperLight: '#F5F5F5',
  black: '#000',
  white: '#fff',
  transparent: 'transparent',

  brandPrimaryDark: '#00846A',
  brandPrimary: '#00cea5',
  brandPrimaryLight: '#99eddb',
  brandPrimaryLighter: '#d9f7f2',
  brandPrimaryLightest: '#edf8f6',
  brandPrimarySuperLight: '#99F0DF',
  brandSecondary: '#0b2043',
  brandSecondaryLight: '#052962',
  brandSecondaryLighter: '#164ca3',
  brandSecondarySuperLight: '#9cbadd',
  brandHighlighted: '#fbd289',
  brandSuccess: 'red',
  brandInfo: 'red',
  brandWarning: '#f37951',
  brandDanger: '#CF442A',
  brandDangerAlt: '#ff579d',

  actionBlue: '#164ca3',
  blueLighter: '#9cbadd',
  heycarMint: '#00cea5',
  micaBlue: '#052962',
  mintLight: '#99eddb',
  mintLighter: '#d9f7f2',
  mintDark: '#00846A',
  mintSuperLight: '#edf8f6',
  tarmacGrey: '#303030',
  oldFerrari: '#FF755D',
  ferrariDark: '#CF442A',
  ferrariLight: '#FFAC9E',
  ferrariLighter: '#FFC8BE',
  sunbeanDark: '#95CEED',
  sunbeanBlue: '#C0E6FF',
  sunbeanLight: '#D9F0FF',
  sunbeanLighter: '#ECF7FF',
  sunbeanSuperLight: '#F5F7FB',
  mustangDark: '#E89800',
  mustangYellow: '#F9B43A',
  mustangLight: '#FBD289',
  mustangLighter: '#FEF0D8',
  championshipWhite: '#FFFFFF',
  darkBlue: '#0B2043',
};

export const colorsRgb: Readonly<Omit<IColorsList, TOutdatedColor>> = {
  grayDarker: 'rgb(131, 131, 131)',
  grayDark: 'rgb(131, 131, 131)',
  gray: 'rgb(193, 193, 193)',
  grayLight: 'rgb(193, 193, 193)',
  grayLightest: 'rgb(245, 245, 245)',
  graySuperLight: 'rgb(245, 245, 245)',
  black: 'rgb(0, 0, 0)',
  white: 'rgb(255, 255, 255)',
  transparent: 'rgb(0, 0, 0)',
  brandPrimaryDark: 'rgb(0, 132, 106)',
  brandPrimary: 'rgb(0, 206, 165)',
  brandPrimaryLight: 'rgb(102, 226, 201)',
  brandPrimaryLighter: 'rgb(178, 240, 228)',
  brandPrimaryLightest: 'rgb(229, 250, 246)',
  brandPrimarySuperLight: 'rgb(153, 240, 223)',
  brandSecondary: 'rgb(11, 32, 67)',
  brandSecondaryLight: 'rgb(5, 41, 98)',
  brandSecondaryLighter: 'rgb(22, 76, 163)',
  brandSecondarySuperLight: 'rgb(161, 183, 217)',
  brandHighlighted: 'rgb(251, 210, 137)',
  brandSuccess: 'rgb(0, 0, 237)',
  brandInfo: 'rgb(0, 0, 237)',
  brandWarning: 'rgb(243, 121, 81)',
  brandDanger: 'rgb(207, 68, 42)',
  brandDangerAlt: 'rgb(255, 87, 157)',

  actionBlue: 'rgb(22, 76, 163)',
  blueLighter: 'rgb(161, 183, 217)',
  heycarMint: 'rgb(0, 206, 165)',
  micaBlue: 'rgb(5, 41, 98)',
  mintLight: 'rgb(102, 226, 201)',
  mintLighter: 'rgb(178, 240, 228)',
  mintDark: 'rgb(0, 132, 106)',
  mintSuperLight: 'rgb(229, 250, 246)',
  tarmacGrey: 'rgb(48, 48, 48)',
  oldFerrari: 'rgb(255, 117, 93)',
  ferrariDark: 'rgb(207, 68, 42)',
  ferrariLight: 'rgb(255, 172, 158)',
  ferrariLighter: 'rgb(255, 200, 190)',
  sunbeanDark: 'rgb(149, 206, 237)',
  sunbeanBlue: 'rgb(192, 230, 255)',
  sunbeanLight: 'rgb(217, 240, 255)',
  sunbeanLighter: 'rgb(236, 247, 255)',
  sunbeanSuperLight: 'rgb(245, 247, 251)',
  mustangDark: 'rgb(232, 152, 0)',
  mustangYellow: 'rgb(249, 180, 58)',
  mustangLight: 'rgb(251, 210, 137)',
  mustangLighter: 'rgb(254, 240, 216)',
  championshipWhite: 'rgb(255, 255, 255)',
  darkBlue: 'rgb(11, 32, 67)',
};

import styled, { css } from 'styled-components';

import { media, zIndex } from 'app/shared/styles_js/variables';

const bgColorChooser = (isTransparent, useWhiteBg) => {
  if (useWhiteBg) return '#fff';
  return isTransparent ? 'rgba(0, 0, 0, 0)' : 'rgba(0, 0, 0, 0.7)';
};

export const OverlaySt = styled.div`
  position: fixed;
  z-index: ${zIndex.overlay};
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: ${(props) => (props.isShown ? 'block' : 'none')};
  background-color: ${(props) =>
    bgColorChooser(props.isTransparent, props.useWhiteBg)};

  ${media.md(css`
    background-color: ${(props) => bgColorChooser(props.isTransparent)};
    ${({ specialStyles }) => specialStyles};
  `)};
  ${({ specialStyles }) => specialStyles};
`;

import PropTypes from 'prop-types';
import { Flex } from '@rebass/grid';

import { noop } from 'app/shared/utils/utils';
import { isFavourite } from 'app/shared/utils/vehicle';
import { GetCartileData } from 'app/shared/modules/Cartile/CartileWebApp';

import { ModalWrapperSt } from './ListingsContainerModal.css';

export const ListingsContainerModal = ({
  target,
  vehicles,
  isLoading,
  className,
  breakpoint,
  lazyImages,
  favouriteIds,
  toggleFavourite,
  performanceUnit,
  budgetRangeValue,
}) => {
  return (
    <Flex m={0} flexWrap="wrap" className={className}>
      {vehicles.map((vehicle, vIndex) => {
        const { CartileContainer, props } = GetCartileData({
          target,
          vehicle,
          isLoading,
          width: [1],
          breakpoint,
          index: vIndex,
          performanceUnit,
          toggleFavourite,
          budgetRangeValue,
          isLazy: lazyImages,
          isFavourite: isFavourite(favouriteIds, vehicle.id),
        });
        return (
          <ModalWrapperSt key={vehicle.id}>
            <CartileContainer {...props} />
          </ModalWrapperSt>
        );
      })}
    </Flex>
  );
};

ListingsContainerModal.propTypes = {
  target: PropTypes.string,
  isLoading: PropTypes.bool,
  lazyImages: PropTypes.bool,
  className: PropTypes.string,
  toggleFavourite: PropTypes.func,
  breakpoint: PropTypes.string.isRequired,
  favouriteIds: PropTypes.array.isRequired,
  performanceUnit: PropTypes.string.isRequired,
  budgetRangeValue: PropTypes.string.isRequired,
  vehicles: PropTypes.arrayOf(PropTypes.object).isRequired,
};

ListingsContainerModal.defaultProps = {
  target: 'PDP',
  className: '',
  isLoading: false,
  lazyImages: true,
  toggleFavourite: noop,
};

import { returnFirstElFromArray } from 'app/marketplace/utils/returnFirstElFromArray';

import * as ActionTypes from './constants';

export const specialOffersDefaultState = () => {
  return {
    pending: false,
    specialOfferTeaser: null,
    specialOffers: null,
    specialOffersHome: null,
    error: null,
    clpBanner: null,
    isPdpCampaignTopBannerAvailable: true,
  };
};

export const specialOffers = (state = specialOffersDefaultState(), action) => {
  const { type, payload, error } = action;

  if (type === ActionTypes.GET_SPECIALOFFERS_REQUEST) {
    return { ...state, pending: true };
  }

  if (type === ActionTypes.GET_SPECIALOFFERS_SUCCESS) {
    return { ...state, specialOffers: payload, pending: false, error: null };
  }

  if (type === ActionTypes.GET_SPECIALOFFERS_FAILURE) {
    return { ...state, error, pending: false, specialOffers: null };
  }
  if (type === ActionTypes.GET_SPECIALOFFERSHOME_REQUEST) {
    return { ...state, pending: true };
  }

  if (type === ActionTypes.GET_SPECIALOFFERSHOME_SUCCESS) {
    return {
      ...state,
      specialOffersHome: returnFirstElFromArray(payload),
      pending: false,
      error: null,
    };
  }

  if (type === ActionTypes.GET_SPECIALOFFERSHOME_FAILURE) {
    return { ...state, error, pending: false, specialOffersHome: null };
  }

  if (type === ActionTypes.GET_SPECIALOFFER_TEASER_REQUEST) {
    return { ...state, pending: true };
  }

  if (type === ActionTypes.GET_SPECIALOFFERS_CLP_BANNER_REQUEST) {
    return { ...state, clpBannerPending: true };
  }
  if (type === ActionTypes.GET_SPECIALOFFERS_CLP_BANNER_FAILURE) {
    return {
      ...state,
      clpBanner: null,
    };
  }
  if (type === ActionTypes.GET_SPECIALOFFERS_CLP_BANNER_SUCCESS) {
    return {
      ...state,
      clpBanner: payload,
    };
  }

  if (type === ActionTypes.GET_SPECIALOFFER_TEASER_SUCCESS) {
    return {
      ...state,
      specialOfferTeaser: payload,
      pending: false,
      error: null,
    };
  }

  if (type === ActionTypes.GET_SPECIALOFFER_TEASER_FAILURE) {
    return { ...state, error, pending: false, specialOfferTeaser: null };
  }

  if (type === ActionTypes.SET_IS_PDP_CAMPAIGN_TOP_BANNER_AVAILABLE) {
    return {
      ...state,
      isPdpCampaignTopBannerAvailable: payload?.isPdpCampaignTopBannerAvailable,
    };
  }

  return state;
};

import { ActionTypes } from './constants';

export const addCarToComparison = payload => {
  return {
    type: ActionTypes.ADD_CAR_TO_COMPARISON,
    payload,
  };
};

export const removeCarFromComparison = payload => {
  return {
    type: ActionTypes.REMOVE_CAR_FROM_COMPARISON,
    payload,
  };
};

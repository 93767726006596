// PLEASE KEEP ORDERED
export const icons = [
  '3-doors',
  '5-doors',
  '360-degree-images',
  'ac',
  'arrow-down-short-long',
  'arrow-down-short-short',
  'arrow-left-long',
  'arrow-left-short',
  'arrow-right-long',
  'arrow-right-short',
  'arrow-up-short-long',
  'arrow-up-short-short',
  'attention',
  'auto',
  'avatar',
  'bluetooth',
  'branch',
  'bubble-points',
  'bubble-question',
  'budget',
  'cabrio',
  'calendar',
  'call-back',
  'call',
  'car-body',
  'chat-happy',
  'chat-sad',
  'chat',
  'check-outline',
  'check',
  'checkbox-active',
  'checkbox-inactive',
  'checkbox-mixed-selection',
  'checklist',
  'clock',
  'close-outline',
  'close',
  'color',
  'comfort',
  'consumption',
  'copy',
  'coupe',
  'cruise-control',
  'cup',
  'delivery',
  'edit',
  'electric',
  'electricity',
  'engine',
  'facebook',
  'favorite-active',
  'favorite-inactive',
  'fb-messenger',
  'filter',
  'flame',
  'fullview',
  'gasoline',
  'gearshift',
  'glassdoor',
  'grid',
  'hide',
  'info',
  'instagram',
  'isofix',
  'key',
  'kleinwagen',
  'kombi',
  'kompaktklasse',
  'kununu',
  'large-boot',
  'leaf',
  'leather',
  'limousine',
  'link-default',
  'linkedin',
  'list',
  'location',
  'lock-closed',
  'lock-open',
  'logged',
  'logout',
  'mail',
  'menu',
  'mileage',
  'minus-outline',
  'minus',
  'more',
  'multimedia-request',
  'multimedia',
  'new-car',
  'notifiction-active',
  'notifiction-inactive',
  'page-check',
  'page-close',
  'page-pdf',
  'page-question',
  'page-search',
  'photos',
  'pickup',
  'pinterest',
  'plugin-hybrid',
  'plus-outline',
  'plus',
  'radiobutton-active',
  'radiobutton-inactive',
  'read',
  'reg-nr',
  'regular-boot',
  'reload',
  'reverse-camera',
  'safety',
  'savedsearch',
  'search',
  'seat',
  'semi-auto',
  'show',
  'sorting',
  'sportscar',
  'start',
  'sunroof',
  'suv',
  'swap',
  'target',
  'taxi',
  'tools',
  'tradein',
  'transporter',
  'trash',
  'twitter',
  'usb',
  'utility',
  'van',
  'video',
  'whatsapp',
  'xl-boot',
  'youtube',
];

export const iconFont = `  
i {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'heycar_iconfont' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.if-icon-3-doors:before {
  content: "\\e900";
}
.if-icon-5-doors:before {
  content: "\\e901";
}
.if-icon-360-degree-images:before {
  content: "\\e902";
}
.if-icon-ac:before {
  content: "\\e903";
}
.if-icon-arrow-down-short-long:before {
  content: "\\e904";
}
.if-icon-arrow-down-short-short:before {
  content: "\\e905";
}
.if-icon-arrow-left-long:before {
  content: "\\e906";
}
.if-icon-arrow-left-short:before {
  content: "\\e907";
}
.if-icon-arrow-right-long:before {
  content: "\\e908";
}
.if-icon-arrow-right-short:before {
  content: "\\e909";
}
.if-icon-arrow-up-short-long:before {
  content: "\\e90a";
}
.if-icon-arrow-up-short-short:before {
  content: "\\e90b";
}
.if-icon-attention:before {
  content: "\\e90c";
}
.if-icon-auto:before {
  content: "\\e90d";
}
.if-icon-avatar:before {
  content: "\\e90e";
}
.if-icon-bluetooth:before {
  content: "\\e90f";
}
.if-icon-branch:before {
  content: "\\e910";
}
.if-icon-bubble-points:before {
  content: "\\e911";
}
.if-icon-bubble-question:before {
  content: "\\e912";
}
.if-icon-budget:before {
  content: "\\e913";
}
.if-icon-cabrio:before {
  content: "\\e914";
}
.if-icon-calendar:before {
  content: "\\e915";
}
.if-icon-call-back:before {
  content: "\\e916";
}
.if-icon-call:before {
  content: "\\e917";
}
.if-icon-car-body:before {
  content: "\\e918";
}
.if-icon-chat-happy:before {
  content: "\\e919";
}
.if-icon-chat-sad:before {
  content: "\\e91a";
}
.if-icon-chat:before {
  content: "\\e91b";
}
.if-icon-check-outline:before {
  content: "\\e91c";
}
.if-icon-check:before {
  content: "\\e91d";
}
.if-icon-checkbox-active:before {
  content: "\\e91e";
}
.if-icon-checkbox-inactive:before {
  content: "\\e91f";
}
.if-icon-checkbox-mixed-selection:before {
  content: "\\e920";
}
.if-icon-checklist:before {
  content: "\\e921";
}
.if-icon-clock:before {
  content: "\\e922";
}
.if-icon-close-outline:before {
  content: "\\e923";
}
.if-icon-close:before {
  content: "\\e924";
}
.if-icon-color:before {
  content: "\\e925";
}
.if-icon-comfort:before {
  content: "\\e926";
}
.if-icon-consumption:before {
  content: "\\e927";
}
.if-icon-copy:before {
  content: "\\e928";
}
.if-icon-coupe:before {
  content: "\\e929";
}
.if-icon-cruise-control:before {
  content: "\\e92a";
}
.if-icon-cup:before {
  content: "\\e92b";
}
.if-icon-delivery:before {
  content: "\\e92c";
}
.if-icon-edit:before {
  content: "\\e92d";
}
.if-icon-electric:before {
  content: "\\e92e";
}
.if-icon-electricity:before {
  content: "\\e92f";
}
.if-icon-engine:before {
  content: "\\e930";
}
.if-icon-facebook:before {
  content: "\\e931";
}
.if-icon-favorite-active:before {
  content: "\\e932";
}
.if-icon-favorite-inactive:before {
  content: "\\e933";
}
.if-icon-fb-messenger:before {
  content: "\\e934";
}
.if-icon-filter:before {
  content: "\\e935";
}
.if-icon-flame:before {
  content: "\\e936";
}
.if-icon-fullview:before {
  content: "\\e937";
}
.if-icon-gasoline:before {
  content: "\\e938";
}
.if-icon-gearshift:before {
  content: "\\e939";
}
.if-icon-glassdoor:before {
  content: "\\e93a";
}
.if-icon-grid:before {
  content: "\\e93b";
}
.if-icon-hide:before {
  content: "\\e93c";
}
.if-icon-info:before {
  content: "\\e93d";
}
.if-icon-instagram:before {
  content: "\\e93e";
}
.if-icon-isofix:before {
  content: "\\e93f";
}
.if-icon-key:before {
  content: "\\e940";
}
.if-icon-kleinwagen:before {
  content: "\\e941";
}
.if-icon-kombi:before {
  content: "\\e942";
}
.if-icon-kompaktklasse:before {
  content: "\\e943";
}
.if-icon-kununu:before {
  content: "\\e944";
}
.if-icon-large-boot:before {
  content: "\\e945";
}
.if-icon-leaf:before {
  content: "\\e946";
}
.if-icon-leather:before {
  content: "\\e947";
}
.if-icon-limousine:before {
  content: "\\e948";
}
.if-icon-link-default:before {
  content: "\\e949";
}
.if-icon-linkedin:before {
  content: "\\e94a";
}
.if-icon-list:before {
  content: "\\e94b";
}
.if-icon-location:before {
  content: "\\e94c";
}
.if-icon-lock-closed:before {
  content: "\\e94d";
}
.if-icon-lock-open:before {
  content: "\\e94e";
}
.if-icon-logged:before {
  content: "\\e94f";
}
.if-icon-logout:before {
  content: "\\e950";
}
.if-icon-mail:before {
  content: "\\e951";
}
.if-icon-menu:before {
  content: "\\e952";
}
.if-icon-mileage:before {
  content: "\\e953";
}
.if-icon-minus-outline:before {
  content: "\\e954";
}
.if-icon-minus:before {
  content: "\\e955";
}
.if-icon-more:before {
  content: "\\e956";
}
.if-icon-multimedia-request:before {
  content: "\\e957";
}
.if-icon-multimedia:before {
  content: "\\e958";
}
.if-icon-new-car:before {
  content: "\\e959";
}
.if-icon-notifiction-active:before {
  content: "\\e95a";
}
.if-icon-notifiction-inactive:before {
  content: "\\e95b";
}
.if-icon-page-check:before {
  content: "\\e95c";
}
.if-icon-page-close:before {
  content: "\\e95d";
}
.if-icon-page-pdf:before {
  content: "\\e95e";
}
.if-icon-page-question:before {
  content: "\\e95f";
}
.if-icon-page-search:before {
  content: "\\e960";
}
.if-icon-photos:before {
  content: "\\e961";
}
.if-icon-pickup:before {
  content: "\\e962";
}
.if-icon-pinterest:before {
  content: "\\e963";
}
.if-icon-plugin-hybrid:before {
  content: "\\e964";
}
.if-icon-plus-outline:before {
  content: "\\e965";
}
.if-icon-plus:before {
  content: "\\e966";
}
.if-icon-radiobutton-active:before {
  content: "\\e967";
}
.if-icon-radiobutton-inactive:before {
  content: "\\e968";
}
.if-icon-read:before {
  content: "\\e969";
}
.if-icon-reg-nr:before {
  content: "\\e96a";
}
.if-icon-regular-boot:before {
  content: "\\e96b";
}
.if-icon-reload:before {
  content: "\\e96c";
}
.if-icon-reverse-camera:before {
  content: "\\e96d";
}
.if-icon-safety:before {
  content: "\\e96e";
}
.if-icon-savedsearch:before {
  content: "\\e96f";
}
.if-icon-search:before {
  content: "\\e970";
}
.if-icon-seat:before {
  content: "\\e971";
}
.if-icon-semi-auto:before {
  content: "\\e972";
}
.if-icon-show:before {
  content: "\\e973";
}
.if-icon-sorting:before {
  content: "\\e974";
}
.if-icon-sportscar:before {
  content: "\\e975";
}
.if-icon-start:before {
  content: "\\e976";
}
.if-icon-sunroof:before {
  content: "\\e977";
}
.if-icon-suv:before {
  content: "\\e978";
}
.if-icon-swap:before {
  content: "\\e979";
}
.if-icon-target:before {
  content: "\\e97a";
}
.if-icon-taxi:before {
  content: "\\e97b";
}
.if-icon-tools:before {
  content: "\\e97c";
}
.if-icon-tradein:before {
  content: "\\e97d";
}
.if-icon-transporter:before {
  content: "\\e97e";
}
.if-icon-transportUtility:before {
  content: "\\e97e";
}
.if-icon-trash:before {
  content: "\\e97f";
}
.if-icon-twitter:before {
  content: "\\e980";
}
.if-icon-usb:before {
  content: "\\e981";
}
.if-icon-utility:before {
  content: "\\e982";
}
.if-icon-van:before {
  content: "\\e983";
}
.if-icon-video:before {
  content: "\\e984";
}
.if-icon-whatsapp:before {
  content: "\\e985";
}
.if-icon-xl-boot:before {
  content: "\\e986";
}
.if-icon-youtube:before {
  content: "\\e987";
}

`;

import {
  trackAction,
  getAdditionalVehicleTrackingParams,
} from 'app/shared/utils/tracking';
import { createGUID } from 'app/shared/utils/utils';
import {
  generateAdditionalTrackingParams,
  LEAD_CREATION_EVENTS,
} from 'app/marketplace/contactDealer/trackingUtils';
import { tvTrackingPixelName } from 'app/marketplace/cookieDashboard/utils';
import { triggerTrackAction } from 'app/marketplace/vehicle/utils';
import { getAverageWebVitalsScores } from 'app/shared/modules/Campaign/utils';

export const trackCallButton = (
  vehicle,
  callDealerEventId,
  target = 'PDP',
  isModal = false,
  eventLabel,
  autoTriggered = false,
  source = '',
) => {
  const payload = {
    category: target,
    description: 'user filled the form and really wants to contact the dealer',
  };

  const eventId = createGUID();

  /* open on mount tracking is handled in Vehicle */
  if (!autoTriggered) {
    triggerTrackAction(autoTriggered, vehicle, target, eventLabel, source);
  }

  trackAction(
    isModal ? 'open_call_modal' : 'call-dealer_button_click',
    {
      ...payload,
      label: `${vehicle.id}`,
    },
    generateAdditionalTrackingParams(vehicle, {
      eventId,
      event_label: eventLabel,
    }),
  );

  trackAction(
    'marketing_contact_dealer',
    {
      ...payload,
      label: `call:${vehicle.id}`,
    },
    {
      sessionId: callDealerEventId,
      eventId: callDealerEventId,
      item: [
        {
          product_id: `${vehicle.id}`,
          price: `${vehicle.price}`,
          value: `${vehicle.price}`,
          quantity: 1,
        },
      ],
      eventLabel,
      ...getAdditionalVehicleTrackingParams(vehicle),
    },
  );
};

export const trackCallStartButton = (
  vehicle,
  phonePin,
  category = 'PDP',
  eventLabel,
) => {
  const payload = {
    category,
    description: 'user clicks call dealer button',
  };

  const webVitals = getAverageWebVitalsScores();

  trackAction(
    LEAD_CREATION_EVENTS.CALL_START_BUTTON_CLICK,
    {
      ...payload,
      label: vehicle.id,
    },
    {
      phonePin,
      vehicleId: vehicle.id,
      datetime: new Date().toISOString(),
      event_label: eventLabel,
      ...(webVitals && { webVitals }),
    },
  );
};

export const tvTrackingCall = (vehicle, phonePin) => {
  if (
    Array.isArray(window._adftrack) &&
    typeof window.loadTvTracking === 'function' &&
    typeof window.loadTvTrackingPixel === 'function'
  ) {
    window._adftrack.push({
      pm: 1230013,
      divider: encodeURIComponent('|'),
      pagename: encodeURIComponent('XM_HeyCar - Anrufen'),
      order: {
        sv1: phonePin,
        itms: [
          {
            productname: vehicle.id,
            productsales: vehicle.price,
          },
        ],
      },
    });

    window.loadTvTracking();
    window.loadTvTrackingPixel(tvTrackingPixelName.call);
  }
};

export const seoFooterLinks = [
  {
    heading: 'Gebrauchte Fahrzeugtypen',
    id: 'seo_links_grp1',
    items: [
      { label: 'SUV gebraucht', url: '/gebrauchtwagen?category=suv' },
      {
        label: 'Kleinwagen gebraucht',
        url: '/gebrauchtwagen?category=kleinwagen',
      },
      { label: 'Cabrio gebraucht', url: '/gebrauchtwagen?category=cabrio' },
      { label: 'Kombi gebraucht', url: '/gebrauchtwagen?category=kombi' },
      { label: 'Van gebraucht', url: '/gebrauchtwagen?category=van' },
      {
        label: 'Transporter gebraucht',
        url: '/gebrauchtwagen?category=transporter',
      },
      {
        label: 'Elektroauto gebraucht',
        url: '/gebrauchtwagen?fuelType=electric',
      },
    ],
  },
  {
    heading: 'Beliebte Suchen',
    id: 'seo_links_grp2',
    items: [
      { label: 'BMW X3', url: '/gebrauchtwagen/bmw/x3' },
      { label: 'Audi RS3', url: '/gebrauchtwagen/audi/rs3' },
      { label: 'Audi A5', url: '/gebrauchtwagen/audi/a5' },
      { label: 'VW Arteon', url: '/gebrauchtwagen/vw/arteon' },
      {
        label: 'Mercedes B Klasse',
        url:
          '/gebrauchtwagen/mercedes-benz/b-160,b-180,b-200,b-220,b-250,b-electric-drive',
      },
      { label: 'Jeep Wrangler', url: '/gebrauchtwagen/jeep/wrangler' },
      { label: 'Audi Q2', url: '/gebrauchtwagen/audi/q2' },
    ],
  },
  {
    heading: 'Beliebte Suchen',
    id: 'seo_links_grp3',
    items: [
      {
        label: 'Mercedes A Klasse',
        url:
          '/gebrauchtwagen/mercedes-benz/a-160,a-180,a-200,a-220,a-250,a-45-amg',
      },
      { label: 'Seat Ateca', url: '/gebrauchtwagen/seat/ateca' },
      { label: 'Kia Sport', url: '/gebrauchtwagen/kia/sportage' },
      { label: 'BMW Gebrauchtwagen', url: '/gebrauchtwagen/bmw' },
      { label: 'Audi Gebrauchtwagen', url: '/gebrauchtwagen/audi' },
      { label: 'Porsche Cayenne', url: '/gebrauchtwagen/porsche/cayenne' },
      { label: 'VW Touran', url: '/gebrauchtwagen/vw/touran' },
    ],
  },
  {
    heading: 'Autohersteller',
    id: 'seo_links_grp4',
    items: [
      { label: 'Skoda Superb', url: '/gebrauchtwagen/skoda/superb' },
      { label: 'Porsche Gebrauchtwagen', url: '/gebrauchtwagen/porsche' },
      {
        label: 'Mercedes Gebrauchtwagen',
        url: '/gebrauchtwagen/mercedes-benz',
      },
      {
        label: 'Land Rover Gebrauchtwagen',
        url: '/gebrauchtwagen/land-rover',
      },
      { label: 'Seat Gebrauchtwagen', url: '/gebrauchtwagen/seat' },
      { label: 'VW Gebrauchtwagen', url: '/gebrauchtwagen/vw' },
      { label: 'Mini Gebrauchtwagen', url: '/gebrauchtwagen/mini' },
    ],
  },
];

import {
  ORDER_CASH_POST,
  ORDER_CASH_POST_SUCCESS,
  ORDER_CASH_POST_ERROR,
  ORDER_CASH_ADD_CONSENT,
  ORDER_SUBMITTED_RESET,
  ORDER_REDIRECT_URL_POST,
  ORDER_REDIRECT_URL_POST_SUCCESS,
  ORDER_REDIRECT_URL_POST_ERROR,
  ORDER_REDIRECT_URL_RESET,
  DEALER_CASH_CONSENTS_AGREE,
  DEALER_CASH_CONSENTS_RESET,
} from '../../constants';

export const initialState = {
  consent: {
    marketingConsent: false,
    tuvConsent: false,
    legalConsent: false,
    cancellationPolicyConsent: false,
    tuvAgreement: false,
    legalAgreement: false,
  },
  redirectUrl: undefined,
  isRedirectLoading: false,
  isLoading: false,
  error: undefined,
  emailErrorData: undefined,
  isSubmitSuccess: undefined,
  isSessionSuccess: undefined,
  paymentInfo: undefined,
  orderId: undefined,
};

export function cash(state = initialState, action) {
  const { type, payload, error } = action;

  switch (type) {
    case ORDER_CASH_POST:
      return {
        ...state,
        isLoading: true,
        error: undefined,
        isSubmitSuccess: undefined,
        paymentInfo: undefined,
      };
    case ORDER_CASH_POST_SUCCESS:
      return {
        ...state,
        paymentInfo: payload,
        isLoading: false,
        isSubmitSuccess: true,
        orderId: payload.order.orderId,
      };
    case ORDER_CASH_POST_ERROR:
      return {
        ...state,
        isLoading: false,
        isSubmitSuccess: false,
        error: { ...payload, isOrderError: true },
        emailErrorData: { ...error, isOrderError: true },
      };
    case ORDER_CASH_ADD_CONSENT:
      return {
        ...state,
        consent: { ...state.consent, ...payload },
      };
    case ORDER_SUBMITTED_RESET:
      return {
        ...state,
        isSubmitSuccess: undefined,
      };
    case ORDER_REDIRECT_URL_POST:
      return { ...state, isRedirectLoading: true, error: undefined };
    case ORDER_REDIRECT_URL_POST_SUCCESS:
      return {
        ...state,
        ...payload,
        isRedirectLoading: false,
        isSessionSuccess: true,
      };
    case ORDER_REDIRECT_URL_POST_ERROR:
      return {
        ...state,
        isRedirectLoading: false,
        isSessionSuccess: false,
        error: {
          ...payload,
          isRedirectUrlError: true,
        },
      };
    case ORDER_REDIRECT_URL_RESET:
      return {
        ...state,
        error: undefined,
        isRedirectLoading: false,
        isSessionSuccess: undefined,
      };

    case DEALER_CASH_CONSENTS_AGREE:
      return {
        ...state,
        consent: {
          ...state.consent,
          ...{ legalConsent: true, tuvConsent: true },
        },
      };

    case DEALER_CASH_CONSENTS_RESET:
      return {
        ...state,
        consent: {
          ...state.consent,
          ...{
            legalConsent: false,
            tuvConsent: false,
          },
        },
      };

    default:
      return state;
  }
}

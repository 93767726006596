import { Route } from 'react-router';

import { getAsyncRouteComponentLoader } from 'app/shared/utils/utils';

export const mobileFilterPageRoutes = (
  <Route name="mobileFilterPage">
    <Route
      path="/mobileFilter"
      getComponent={getAsyncRouteComponentLoader(
        'ConnectedDetailsFiltersPage',
        () => import('../DetailsFiltersPage/ConnectedDetailsFiltersPage'),
      )}
    />
  </Route>
);

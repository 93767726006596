/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-return */
import styled from 'styled-components';

import { Link } from '../../../../../../../Link/Link';

export const NavItemCategoryLink = styled(Link).attrs(() => ({
  textDecoration: 'none',
  fontWeight: 'normal',
}))`
  display: block;
  min-height: 52px;
  padding-bottom: 24px;
  text-decoration: 'none';
  font-family: inherit;

  &:last-child {
    padding-bottom: 0;
  }

  &:hover {
    font-weight: bold;
    /* we need to force this, as the standard for link is either text decoration for all states or no text decoration at all */
    text-decoration: underline !important;
  }

  font-size: 14px;
`;

export const NavItemCategoryButton = styled('button')`
  display: block;
  padding: 0 0 24px 0;
  border: none;
  background: none;
  cursor: pointer;
  text-decoration: 'none';
  color: ${(props) => props.theme.colors.tarmacGrey};
  font-weight: 'normal';

  font-size: 14px;

  &:last-child {
    padding-bottom: 0;
  }

  &:hover {
    font-weight: bold;
    color: ${(props) => props.theme.colors.brandPrimaryDark};
    text-decoration: underline;
  }
`;

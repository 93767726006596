import React, { memo, useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { useInitialMount } from '../../utils/useInitialMount';

import {
  ToggleButtonContainerSt,
  ToggleButtonIconSt,
  ToggleButtonIconContainerSt,
} from './ToggleButton.css';

export const ToggleButtonCmp = (props) => {
  const {
    isOn,
    iconOn,
    iconOff,
    colorOn,
    colorOff,
    onToggleButtonClick,
  } = props;
  const [isActive, setIsActive] = useState(isOn);
  const isInitialMount = useInitialMount();

  const handleOnClick = () => {
    setIsActive(!isActive);
  };

  useEffect(() => {
    if (!isInitialMount) {
      onToggleButtonClick(isActive);
    }
  }, [isActive]);

  return (
    <ToggleButtonContainerSt
      isOn={isActive}
      onClick={handleOnClick}
      colorOn={colorOn}
      colorOff={colorOff}
    >
      <ToggleButtonIconContainerSt isActive={isActive}>
        {iconOn && iconOff && (
          <ToggleButtonIconSt
            className={`if-icon-${isActive ? iconOn : iconOff}`}
          />
        )}
      </ToggleButtonIconContainerSt>
    </ToggleButtonContainerSt>
  );
};

ToggleButtonCmp.displayName = 'ToggleButton';

ToggleButtonCmp.propTypes = {
  isOn: PropTypes.bool,
  colorOn: PropTypes.string,
  colorOff: PropTypes.string,
  iconOn: PropTypes.string,
  iconOff: PropTypes.string,
  onToggleButtonClick: PropTypes.func.isRequired,
};

ToggleButtonCmp.defaultProps = {
  isOn: true,
  iconOn: '',
  iconOff: '',
  colorOff: 'grayLight',
  colorOn: 'brandPrimary',
};

export const ToggleButton = memo(ToggleButtonCmp);

/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable react/jsx-props-no-spreading */
/* istanbul ignore file */
import { useRef, useEffect } from 'react';

import { ResponsiveImage } from 'app/marketplace/contentful/shared/ResponsiveImage/ResponsiveImage';
import { getPdpCampaignTopBanner } from 'app/marketplace/shared/NewMarketplaceTopNavBar/utils';
import { TCampaignData } from 'app/shared/modules/Campaign/Campaign.types';
import { GAM_AD_UNITS } from 'app/shared/modules/Campaign/GAM/ad-units';
import { ConnectedGamBanner } from 'app/shared/modules/Campaign/GAM/GamBanner/ConnectedGamBanner';
import { isDealerCashVehicle } from 'app/shared/utils/vehicle';
import { trackAction } from 'app/shared/utils/tracking';
import { IVehicle } from 'app/types/entities/Vehicle.types';
import { TBreakpoint } from 'app/types/style.types';
import { useDefineMenuAction } from 'app/marketplace/vehicle/hooks/useDefineMenuAction';
import { TAdditionalListings } from 'app/marketplace/contactDealer/Mail/MailModal/AdditionalLeads/types';
import { useSentryTags } from 'app/shared/hooks/useSentryTags';

import {
  BannerSt,
  BannerWrapperSt,
  HiddenButtonSt,
  ImgWrapperSt,
} from './CampaignTopBanner.css';

const { PDP_TOP_BANNER } = GAM_AD_UNITS;

interface ICampaignTopBannerProps {
  isTopNavBarHidden: boolean;
  campaignData: TCampaignData;
  breakpoint: TBreakpoint;
  isCarAvailable: boolean;
  isPdpCampaignTopBannerAvailable: boolean;
  setIsPdpCampaignTopBannerAvailable: (
    isPdpCampaignTopBannerAvailable: boolean,
  ) => void;
  isAdBlockerDetected: boolean;
  vehicle: IVehicle;
  closeOverlay: () => void;
  openOverlay: () => void;
  signupForNewsletterWithConsent: () => void;
  isFlag248OneClickLeadsShow: boolean;
  oneClickLeads: TAdditionalListings;
  location: ILocation;
}

interface IGamData {
  orderId: string;
  lineItemId: string;
  creativeId: string;
}

export const CampaignTopBanner = ({
  isTopNavBarHidden,
  campaignData,
  breakpoint,
  isCarAvailable,
  isPdpCampaignTopBannerAvailable,
  setIsPdpCampaignTopBannerAvailable,
  isAdBlockerDetected,
  vehicle,
  closeOverlay,
  openOverlay,
  signupForNewsletterWithConsent,
  isFlag248OneClickLeadsShow,
  oneClickLeads,
  location,
}: ICampaignTopBannerProps): JSX.Element | null => {
  useSentryTags({ GROWTH_DE: true });
  const gamData = useRef<IGamData>();

  const { handleEmailClick } = useDefineMenuAction({
    target: 'PDP',
    location,
    vehicle,
    breakpoint,
    openOverlay,
    closeOverlay,
    campaignData,
    signupForNewsletterWithConsent,
    shouldShowCampaignInfoModal: true,
  });

  const {
    primaryCampaign,
    matchingCampaigns,
    primaryCampaignMarker,
  } = campaignData;

  useEffect(() => {
    /**
     * Hide PDP Campaign Top Banner for the non-campaign listings
     * in case an ad blocker is detected -meaning no backup banner
     * from Contentful will be shown-
     *
     * See: https://heycar.atlassian.net/browse/PROMO-3658
     */
    if (!primaryCampaign && isAdBlockerDetected) {
      setIsPdpCampaignTopBannerAvailable(false);
    } else {
      setIsPdpCampaignTopBannerAvailable(true);
    }
  }, [primaryCampaign, isAdBlockerDetected]);

  // Should not be rendered if it's not available or if it's not a PDP
  if (!isPdpCampaignTopBannerAvailable || !vehicle?.id) return null;

  const {
    make,
    model,
    price,
    fuelType,
    firstRegistrationDate,
    mileage,
    categories,
    performance,
  } = vehicle;

  const isDealerCashEligible = isDealerCashVehicle(vehicle);
  const hasDealerCashCampaign = campaignData?.matchingCampaigns?.some(
    (campaign) => campaign.id === '2211_dc_1000',
  );

  // eslint-disable-next-line @typescript-eslint/no-unsafe-call
  const { responsiveImage, trackingData } = getPdpCampaignTopBanner(
    primaryCampaign?.pdpTopBanner,
  );

  const dimensionVal = {
    width: PDP_TOP_BANNER.bannerArea[breakpoint].width,
    height: PDP_TOP_BANNER.bannerArea[breakpoint].height,
  };

  const imgDimension = {
    width: dimensionVal?.width ? `${dimensionVal.width}px` : '100%',
    height: dimensionVal?.height ? `${dimensionVal.height}px` : '100%',
  };

  const handleTopBannerClick = () => {
    handleEmailClick({
      oneClickLeads,
      isFlag248OneClickLeadsShow,
      initExpandedCampaignInfoContentIndex: -1,
      visibleCampaign:
        // eslint-disable-next-line no-nested-ternary
        isDealerCashEligible && hasDealerCashCampaign
          ? primaryCampaign
            ? 'SHOW_ALL'
            : 'SHOW_DEALERCASH_ONLY'
          : 'SHOW_DEFAULT',
    });

    const { orderId, lineItemId, creativeId } = gamData?.current || {};
    const { eventName, placement } = PDP_TOP_BANNER.trackingData.clickEvent;
    if (orderId && eventName) {
      trackAction(
        eventName,
        {
          value: (
            campaignData?.matchingCampaigns?.map(
              (campaign) => campaign?.id as string,
            ) ?? []
          ).join(),
        }, // no category, label or description for now
        {
          banner_source: 'GAM',
          page: placement,
          order_id: orderId,
          line_item_id: lineItemId,
          creative_id: creativeId,
        },
      );
    } else {
      const { description, label, category, name } = trackingData || {};
      trackAction(name, { description, category, label });
    }
  };

  const callback = (data: IGamData) => {
    gamData.current = data;

    /**
     * Hide PDP Campaign Top Banner for the non-campaign listings
     * in case there is no banner coming from GAM -meaning no backup
     * banner from Contentful will be shown-
     *
     * See: https://heycar.atlassian.net/browse/PROMO-3658
     */
    if ((!primaryCampaign || isDealerCashEligible) && !data) {
      setIsPdpCampaignTopBannerAvailable(false);
    }
  };

  const vehicleTargetings = {
    make: make?.id,
    model: model?.id,
    price,
    fuelType,
    firstRegistrationDate,
    mileage,
    category: categories,
    performance,
    isDealerCashEligible: String(isDealerCashEligible),
    campaignCount: matchingCampaigns?.length || 0,
    isDealerCashCampaign: String(isDealerCashEligible && hasDealerCashCampaign),
  };

  const TopBannerComponent = (
    <ImgWrapperSt width={imgDimension.width} height={imgDimension.height}>
      <ResponsiveImage {...responsiveImage} breakpoint={breakpoint} />
    </ImgWrapperSt>
  );

  return (
    <BannerSt
      isTopNavBarHidden={!isTopNavBarHidden}
      data-campaign-id={primaryCampaign?.id}
      data-campaign-element="pdp-top-banner"
    >
      <BannerWrapperSt isCarAvailable={isCarAvailable}>
        {(primaryCampaign || hasDealerCashCampaign) && (
          <HiddenButtonSt
            width={imgDimension.width}
            height={imgDimension.height}
            onClick={handleTopBannerClick}
            type="button"
          >
            {responsiveImage?.title}
          </HiddenButtonSt>
        )}
        <ConnectedGamBanner
          adUnitId={PDP_TOP_BANNER.adUnitId}
          adUnitPath={PDP_TOP_BANNER.adUnitPath}
          sizes={PDP_TOP_BANNER.sizes}
          bannerArea={PDP_TOP_BANNER.bannerArea}
          targeting={{
            markerId: String(primaryCampaignMarker?.id),
            ...vehicleTargetings,
          }}
          fallbackComponent={isDealerCashEligible ? null : TopBannerComponent}
          trackingData={PDP_TOP_BANNER.trackingData}
          callback={callback}
          renderWhenViewable={false}
          isScalable={false}
          markerIds={primaryCampaignMarker?.id}
          collectDurationMetric
          useSmallSpinner
        />
      </BannerWrapperSt>
    </BannerSt>
  );
};

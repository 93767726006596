import { slideInBannerReducer } from 'app/marketplace/SlideInBanners/shared/redux/reducer';

import { contactDetails } from './marketplace/contactDealer/cookieReducer';
import { tracking } from './shared/modules/tracking/reducer';
import { cookieDashboard } from './marketplace/cookieDashboard/reducer';
import { user } from './shared/modules/user/reducer';

export const cookieReducers = {
  tracking,
  cookieDashboard,
  user,
  contactDetails,
  slideIn: slideInBannerReducer,
};

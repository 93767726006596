/**
 * Checks if input string matches D2C Vehicle key
 * @param {String} source
 * @return {Boolean}
 */
export const getIsD2CVehicle = (source) => {
  return (
    source === 'GWC_D2C' ||
    source === 'GWC_D2C,INSTAMOTION' ||
    source === 'INSTAMOTION'
  );
};

export const getIsBuyOnlineVehicle = (params = '') => {
  const offerings = params.split(',');

  return (
    offerings.includes('DE_DEALER_CASH') ||
    offerings.includes('DE_D2C_CASH') ||
    offerings.includes('DE_INSTAMOTION_CASH')
  );
};

export const getHasFinanceJourney = (activeFilters) => {
  const offerings = activeFilters?.productOffering;

  return offerings !== undefined && offerings.length > 0 &&
    (offerings[0].value.includes('DE_D2C_FINANCING_AC') ||
      offerings[0].value.includes('DE_DEALER_FINANCING_AC'));
};

import styled, { css } from 'styled-components';

export const CartileImageRemoveIconSt = styled.i`
  position: absolute;
  z-index: 2;
  top: 5px;
  padding: 8px;
  background: white;
  border-radius: 50%;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.15);
  font-size: 16px;
  cursor: pointer;
  pointer-events: auto;
  ${({ isHorizontal, paddingHorizontal }) =>
    isHorizontal
      ? css`
          left: calc(${paddingHorizontal}px + 5px);
        `
      : css`
          right: calc(${paddingHorizontal}px + 5px);
        `}
`;

import styled, { css } from 'styled-components';
import { Button, Select } from 'app/shared/ui';
import { Flex } from '@rebass/grid';

import { color, media } from 'app/shared/styles_js/variables';
import {
  TabsWrapperSt,
  TabSt,
} from 'app/shared/components/TabbedContent/TabbedContent.css';

export const FilterPanelContainerSt = styled(Flex).attrs((props) => ({
  p: [12, 16, 24],
  ...props,
}))`
  position: relative;
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;

  & > .sk-fading-circle {
    width: 40px;
    height: 40px;
  }

  & > .sk-fading-circle > .sk-circle::before {
    width: 3px;
    height: 3px;
    background-color: ${color.brandSecondary};
    border-radius: 50%;
  }
`;

export const FilterPanelInputWrapperSt = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
`;

export const MakeModelWrapperSt = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin-top: 12px;

  ${media.md(css`
    flex-direction: column;
    margin-top: 8px;
  `)}
  ${media.lg(css`
    flex-direction: row;
    margin-top: 12px;
  `)}
`;

export const MakeSelectSt = styled(Select)`
  width: 100%;
  height: 48px;
  margin: 0 4px 8px 0;
  font-weight: normal;

  ${media.md(css`
    margin: 0 0 4px 0;
  `)}
  ${media.lg(css`
    margin: 0 8px 8px 0;
  `)}
`;

export const ModelSelectSt = styled(Select)`
  width: 100%;
  height: 48px;
  margin: 0 0 8px 4px;
  font-weight: normal;

  ${media.md(css`
    margin: 8px 0 4px 0;
  `)}
  ${media.lg(css`
    margin: 0 0 8px 8px;
  `)}
`;

export const ButtonSt = styled(Button)`
  width: 100%;
  margin: 0;
  white-space: nowrap;
`;

export const MoreFilterContainerSt = styled.div`
  display: flex;
  width: 100%;
  align-items: baseline;
  justify-content: center;
  margin-top: 12px;

  & > span {
    color: ${({ theme: { colors } }) => colors.tarmacGrey};
  }

  ${media.md(css`
    bottom: -48px;
  `)}
`;

export const ContainerSt = styled.div`
  ${TabsWrapperSt} {
    height: 56px;
    padding: 0;
    margin: 0;
    border-radius: 4px 4px 0 0;
  }
`;

export const TabStWithBg = styled(TabSt)`
  padding: 18px;
  border-right: none;
  border-radius: 0;
  box-shadow: none;
  ${media.md(css`
    padding: 12px;
  `)}
  ${({ active, index: tabIndex }) =>
    !active &&
    css`
      color: ${({ theme: { colors } }) => colors.tarmacGrey};
      background-color: ${({ theme: { colors } }) => colors.mintSuperLight};
      /* istanbul ignore next */
      box-shadow: ${tabIndex === 0
        ? 'inset -2px -2px 4px 0 rgba(0, 0, 0, 0.15)'
        : ' inset 2px -2px 4px 0 rgba(0, 0, 0, 0.15)'};
    `}
`;

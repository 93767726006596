import { css } from 'styled-components';

export const ContainerSt = css`
  position: sticky;
  z-index: 1;
  top: 0;
  background-color: ${
    /* istanbul ignore next */ ({ theme: { colorsRgb } }) => colorsRgb.white
  };
`;

export const OuterBoxSt = css`
  height: 6px;
  background-color: ${
    /* istanbul ignore next */ ({ theme: { colorsRgb, getRgba } }) =>
      getRgba(colorsRgb.brandSecondary, 0.08)
  };
`;

export const ProgressBarSt = css`
  height: 6px;
  background-color: ${
    /* istanbul ignore next */ ({ theme: { colorsRgb } }) =>
      colorsRgb.brandPrimary
  };
`;

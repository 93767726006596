import { connect } from 'react-redux';

import {
  setIsHiddenTopNavBar,
  mouseExitScreenHandler,
  setIsTopNavbarStuck,
  setBreakpoint,
  setScrollDirection,
  setIsAdBlockerDetected,
} from 'app/shared/modules/globalEvents/actions';
import { GlobalEventHandlers } from 'app/shared/modules/globalEvents/GlobalEventHandlers/GlobalEventHandlers';

function mapStateToProps(state) {
  return {
    breakpoint: state.globalEvents.breakpoint,
    isTopNavBarHidden: state.globalEvents.isTopNavBarHidden,
    location: state.routing.locationBeforeTransitions,
  };
}

export const ConnectedGlobalEventHandlers = connect(mapStateToProps, {
  setBreakpoint,
  mouseExitScreenHandler,
  setIsHiddenTopNavBar,
  setScrollDirection,
  setIsTopNavbarStuck,
  setIsAdBlockerDetected,
})(GlobalEventHandlers);

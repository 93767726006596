import {
  DELIVERY_LOCATION_GET,
  DELIVERY_LOCATION_GET_SUCCESS,
  DELIVERY_LOCATION_GET_ERROR,
  DELIVERY_LOCATION_RESET,
  DELIVERY_LOCATION_PUT,
  DELIVERY_LOCATION_PUT_ERROR,
  DELIVERY_LOCATION_PUT_SUCCESS,
} from '../../constants';

export const initialState = {
  orderDeliveryOption: undefined, // 'delivery' or 'pickup'
  pickupLocation: {
    id: undefined,
    street: undefined,
    city: undefined,
    houseNumber: undefined,
    zipcode: undefined,
    brand: undefined,
  },
  deliveryLocation: {
    firstName: undefined,
    lastName: undefined,
    street: undefined,
    houseNumber: undefined,
    postalCode: undefined,
    city: undefined,
  },
  isLoading: false,
  error: undefined,
  deliverySubmited: undefined,
};

export function deliveryLocation(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case DELIVERY_LOCATION_GET:
      return { ...state, isLoading: true, error: undefined };
    case DELIVERY_LOCATION_GET_SUCCESS:
      return {
        ...state,
        ...payload,
        isLoading: false,
        deliverySubmited: Boolean(payload?.orderDeliveryOption),
      };
    case DELIVERY_LOCATION_GET_ERROR:
      return { ...state, error: payload, isLoading: false };
    case DELIVERY_LOCATION_RESET:
      return { ...initialState };
    case DELIVERY_LOCATION_PUT:
      return { ...state, isLoading: true, error: undefined };
    case DELIVERY_LOCATION_PUT_SUCCESS:
      return {
        ...state,
        ...payload,
        isLoading: false,
        deliverySubmited: true,
      };
    case DELIVERY_LOCATION_PUT_ERROR:
      return { ...state, error: payload, isLoading: false };
    default:
      return state;
  }
}

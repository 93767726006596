import {
  ConfigStatus,
  PaginationProps,
  savedSearchesData,
  SavedSearchDeleteId,
  SaveSearchActionTypes,
  savedSearchAPIResponse,
  SavedSearchNotificationConfigs,
} from 'app/marketplace/saveSearch/saveSearch.types';

export interface saveSearchReducer {
  pending: boolean;
  error: string | null;
  pagination: PaginationProps | null;
  savedSearches: savedSearchesData[];
  isAccountAvailable: boolean;
}

type Action =
  | {
      type: typeof SaveSearchActionTypes.SAVE_SEARCH_PENDING;
    }
  | {
      type: typeof SaveSearchActionTypes.SAVE_SEARCH_FETCH_SEARCHES_SUCCESS;
      payload: savedSearchAPIResponse;
    }
  | {
      type: typeof SaveSearchActionTypes.SAVE_SEARCH_POST_SEARCH_SUCCESS;
      payload: savedSearchesData;
    }
  | {
      type: typeof SaveSearchActionTypes.SAVE_SEARCH_FETCH_SEARCHES_FAILED;
      error: string;
    }
  | {
      type: typeof SaveSearchActionTypes.SAVE_SEARCH_POST_SEARCH_FAILED;
      error: string;
    }
  | {
      type: typeof SaveSearchActionTypes.SAVE_SEARCH_UPDATE_NOTIFICATION_STATUS_SUCCESS;
      payload: SavedSearchNotificationConfigs;
    }
  | {
      type: typeof SaveSearchActionTypes.SAVE_SEARCH_UPDATE_NOTIFICATION_STATUS_FAILED;
      error: string;
    }
  | {
      type: typeof SaveSearchActionTypes.SAVE_SEARCH_UPDATE_SEARCH_SUCCESS;
      payload: savedSearchesData;
    }
  | {
      type: typeof SaveSearchActionTypes.SAVE_SEARCH_UPDATE_SEARCH_FAILED;
      error: string;
    }
  | {
      type: typeof SaveSearchActionTypes.SAVE_SEARCH_DELETE_SEARCH_SUCCESS;
      payload: SavedSearchDeleteId;
    }
  | {
      type: typeof SaveSearchActionTypes.SAVE_SEARCH_DELETE_SEARCH_FAILED;
      error: string;
    }
  | {
      type: typeof SaveSearchActionTypes.SAVE_SEARCH_ACCOUNT_REGISTERED_SUCCESS;
      payload: boolean;
    }
  | {
      type: typeof SaveSearchActionTypes.SAVE_SEARCH_ACCOUNT_REGISTERED_FAILURE;
      error: string;
    };

export const toggleConfigStatus = (status: string): ConfigStatus =>
  status === ConfigStatus.ON
    ? ConfigStatus.OFF /* istanbul ignore next */
    : ConfigStatus.ON;

export const updateSaveSearchNotification = (
  state: saveSearchReducer,
  payload: SavedSearchNotificationConfigs,
) =>
  state.savedSearches.map((saveSearch: savedSearchesData) => {
    if (saveSearch.savedSearchNotificationConfigs[0].id === payload.id) {
      const newSaveSearch = { ...saveSearch };
      newSaveSearch.savedSearchNotificationConfigs[0].configStatus = toggleConfigStatus(
        saveSearch.savedSearchNotificationConfigs[0].configStatus,
      );
      return newSaveSearch;
    }
    return saveSearch;
  });

export const updateSavedSearch = (
  state: saveSearchReducer,
  payload: savedSearchesData,
) =>
  state.savedSearches.map((saveSearch: savedSearchesData) => {
    if (saveSearch.id === payload.id) {
      return payload;
    }
    return saveSearch;
  });

export const deleteSavedSearch = (state: saveSearchReducer, id: string) =>
  state.savedSearches.filter(
    (saveSearch: savedSearchesData) => saveSearch.id !== id,
  );

export const defaultState = (): saveSearchReducer => ({
  error: null,
  pending: true,
  pagination: {
    pageSize: 0,
    pageNumber: 0,
    totalPages: 1,
    totalElements: 0,
  },
  savedSearches: [],
  isAccountAvailable: false,
});

export const saveSearch = (
  state = defaultState(),
  action: Action,
): saveSearchReducer => {
  switch (action.type) {
    case SaveSearchActionTypes.SAVE_SEARCH_PENDING:
      return { ...state, pending: true };

    // GET SAVED SEARCHES CASES

    case SaveSearchActionTypes.SAVE_SEARCH_FETCH_SEARCHES_SUCCESS:
      return {
        ...state,
        pending: false,
        pagination: {
          ...action.payload.pagination,
          totalPages: action.payload.totalPages,
          totalElements: action.payload.totalElements,
        },
        savedSearches: action.payload.savedSearches,
      };

    case SaveSearchActionTypes.SAVE_SEARCH_FETCH_SEARCHES_FAILED:
      return {
        ...state,
        error: action.error,
        pending: false,
      };

    // POST SAVED SEARCHES CASES

    case SaveSearchActionTypes.SAVE_SEARCH_POST_SEARCH_SUCCESS:
      return {
        ...state,
        savedSearches: (
          state.savedSearches /* istanbul ignore next */ || []
        ).concat(action.payload),
        pending: false,
      };

    case SaveSearchActionTypes.SAVE_SEARCH_POST_SEARCH_FAILED:
      return {
        ...state,
        error: action.error,
        pending: false,
      };

    // UPDATE NOTIFICATION CASES

    case SaveSearchActionTypes.SAVE_SEARCH_UPDATE_NOTIFICATION_STATUS_SUCCESS:
      return {
        ...state,
        savedSearches: updateSaveSearchNotification(state, action.payload),
      };

    case SaveSearchActionTypes.SAVE_SEARCH_UPDATE_NOTIFICATION_STATUS_FAILED:
      return {
        ...state,
        error: action.error,
        pending: false,
      };

    // UPDATE SAVED SEARCH CASES

    case SaveSearchActionTypes.SAVE_SEARCH_UPDATE_SEARCH_SUCCESS:
      return {
        ...state,
        savedSearches: updateSavedSearch(state, action.payload),
      };

    case SaveSearchActionTypes.SAVE_SEARCH_UPDATE_SEARCH_FAILED:
      return {
        ...state,
        error: action.error,
        pending: false,
      };

    // DELETE SAVED SEARCH CASES

    case SaveSearchActionTypes.SAVE_SEARCH_DELETE_SEARCH_SUCCESS:
      return {
        ...state,
        savedSearches: deleteSavedSearch(state, action.payload.id),
      };

    case SaveSearchActionTypes.SAVE_SEARCH_DELETE_SEARCH_FAILED:
      return {
        ...state,
        error: action.error,
        pending: false,
      };
    case SaveSearchActionTypes.SAVE_SEARCH_ACCOUNT_REGISTERED_SUCCESS:
      return {
        ...state,
        isAccountAvailable: action.payload,
      };
    case SaveSearchActionTypes.SAVE_SEARCH_ACCOUNT_REGISTERED_FAILURE:
      return {
        ...state,
        error: action.error,
        isAccountAvailable: false,
      };
    default:
      return state;
  }
};

import { Fragment } from 'react';
import PropTypes from 'prop-types';

import { isFavourite } from 'app/shared/utils/vehicle';
import { GetCartileData } from 'app/shared/modules/Cartile/CartileWebApp';
import { ListingsContainerSt } from 'app/marketplace/shared/components/ListingsContainer/ListingsContainer.css';

export const ListingsContainer = ({
  width,
  target,
  vehicles,
  isLoading,
  className,
  breakpoint,
  lazyImages,
  favouriteIds,
  containerProps,
  toggleFavourite,
  performanceUnit,
  budgetRangeValue,
  vehiclesAmountMap,
  backgroundColor,
}) => {
  const someVehicles = vehicles.slice(0, vehiclesAmountMap[breakpoint]);

  return (
    <ListingsContainerSt {...containerProps} className={className}>
      {someVehicles.map((vehicle, index) => {
        const { CartileContainer, props } = GetCartileData({
          backgroundColor,
          index,
          width,
          target,
          vehicle,
          isLoading,
          breakpoint,
          performanceUnit,
          toggleFavourite,
          budgetRangeValue,
          isLazy: lazyImages,
          isFavourite: isFavourite(favouriteIds, vehicle.id),
        });
        return (
          <Fragment key={vehicle.id}>
            <CartileContainer {...props} />
          </Fragment>
        );
      })}
    </ListingsContainerSt>
  );
};

ListingsContainer.propTypes = {
  width: PropTypes.array,
  isLoading: PropTypes.bool,
  className: PropTypes.string,
  preventFavourite: PropTypes.bool,
  containerProps: PropTypes.object,
  target: PropTypes.string.isRequired,
  lazyImages: PropTypes.bool.isRequired,
  breakpoint: PropTypes.string.isRequired,
  favouriteIds: PropTypes.array.isRequired,
  toggleFavourite: PropTypes.func.isRequired,
  performanceUnit: PropTypes.string.isRequired,
  budgetRangeValue: PropTypes.string.isRequired,
  additionalTrackingProperties: PropTypes.object,
  vehiclesAmountMap: PropTypes.objectOf(PropTypes.number),
  vehicles: PropTypes.arrayOf(PropTypes.object).isRequired,
  backgroundColor: PropTypes.string.isRequired,
};

ListingsContainer.defaultProps = {
  width: [1],
  vehiclesAmountMap: {
    sm: 3,
    md: 6,
    lg: 6,
    xl: 8,
  },
  containerProps: {
    xlCarsPerRow: 4,
    lgCarsPerRow: 3,
    mdCarsPerRow: 2,
  },
  className: '',
  isLoading: false,
  preventFavourite: false,
  additionalTrackingProperties: {},
};

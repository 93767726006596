import { Icon, Text } from 'app/shared/ui';
import { useDefineMenuAction } from 'app/marketplace/vehicle/hooks/useDefineMenuAction';

import { trackCallCtaOnCLP } from '../../utils';

import { CallButtonSt } from './CartileCallCta.css';
import { ICartileCallCtaProps } from './CartileCallCta.types';

export const CartileCallCta = ({
  isLoading,
  vehicle,
  breakpoint,
  target,
  isPhoneActionLoading,
  openOverlay,
  closeOverlay,
  initiateCall,
  signupForNewsletterWithConsent,
  hideCallCta,
}: ICartileCallCtaProps): JSX.Element => {
  const { handlePhoneClick } = useDefineMenuAction({
    target,
    vehicle,
    location: null,
    breakpoint,
    openOverlay,
    closeOverlay,
    initiateCall,
    signupForNewsletterWithConsent,
  });

  const handleOnClick = (event: React.SyntheticEvent) => {
    if (handlePhoneClick) handlePhoneClick(target);
    trackCallCtaOnCLP();
    event.stopPropagation();
    event.preventDefault();
  };

  return (!hideCallCta && (
    <CallButtonSt
      onClick={handleOnClick}
      isLoading={isLoading || isPhoneActionLoading}
    >
      <Icon icon="call" />
      <Text ml="8px">Händler anrufen</Text>
    </CallButtonSt>
  )) as JSX.Element;
};

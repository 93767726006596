import styled, { css } from 'styled-components';
import { Text2, GridContainer } from 'app/shared/ui';

import { media } from 'app/shared/styles_js/variables';
import { FlexRow } from 'app/marketplace/shared/components/Layouts/FlexRow';
import { FlexCol } from 'app/marketplace/shared/components/Layouts/FlexCol';

export const ButtonArea = styled(FlexRow)`
  flex-direction: row;
  margin-top: 30px;

  ${media.md(css`
    margin-top: 0;
  `)};

  ${media.lg(css`
    flex-direction: row;
  `)};
`;

export const ButtonAreaCol = styled(FlexCol)`
  &:first-child {
    margin-top: 0;
  }

  ${media.md(css`
    &:first-child {
      padding-left: 0;
    }
    &:last-child {
      padding-left: 16px;
      padding-right: 0;
    }
  `)}

  ${media.lg(css`
    margin-top: 0;

    &:first-child {
      padding-left: 32px;
    }
  `)};
`;

export const Link = styled.a`
  display: inline-block;
  height: 40px;

  & img {
    max-width: 135px;
    height: 40px;
  }

  ${media.md(css`
    height: 41px;
  `)};

  ${media.lg(css`
    height: 42px;
  `)};

  ${media.xl(css`
    height: 55px;
  `)};
`;

export const Title = styled.div`
  position: relative;

  & > h6 {
    margin-top: 0;
  }

  ${media.md(css`
    grid-column: 1 / span 8;
  `)};
`;

export const Info = styled(Text2).attrs(() => ({ m: 0 }))`
  & > p {
    margin: 0;
    margin-bottom: 40px;

    &:last-child {
      margin-bottom: 0;
    }

    ${media.md(css`
      margin-bottom: 48px;
    `)};
  }

  ${media.md(css`
    grid-column: 9 / span 3;
  `)};
`;

export const InnerContainer = styled(GridContainer).attrs(() => ({
  hasDefaultWidth: true,
}))`
  align-items: center;
  justify-items: center;
`;

export const Container = styled.div`
  padding: 40px 0;
  margin: 40px auto 0;
  background-color: ${({ theme: { colors } }) => colors.brandSecondary};

  ${media.md(css`
    padding: 40px 0;
  `)};
`;

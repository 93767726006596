import styled, { css } from 'styled-components';

import { color, media } from './variables';

export const H1 = styled.h1`
  position: relative;
  margin-top: 0;
  margin-bottom: 32px;
  font-family: 'ObjektivMk1Bold', sans-serif, 'Helvetica Neue', Helvetica, Arial;
  font-size: 32px;
  font-weight: 100;
  line-height: 1.25;

  ${media.md(css`
    font-size: 40px;
    line-height: 1.2;
  `)}

  ${media.lg(css`
    font-size: 48px;
    line-height: 1.17;
  `)}

  ${media.xl(css`
    font-size: 64px;
    line-height: 1.12;
  `)}

  &::before {
    position: absolute;
    top: -8px;
    left: 0;
    width: 40px;
    height: 4px;
    background-color: ${color.brandPrimary};
    content: '';

    ${media.md(css`
      top: -16px;
      width: 80px;
      height: 8px;
    `)}
  }
`;

export const H2 = styled.h2`
  margin-top: 0;
  margin-bottom: 24px;
  font-size: 24px;
  font-weight: 400;
  line-height: 1.33;

  ${media.lg(css`
    font-size: 32px;
    line-height: 1.5;
  `)}
`;

export const P1 = styled.p`
  margin-top: 0;
  margin-bottom: 24px;
  font-size: 20px;
  font-weight: 100;
  line-height: 1.4;

  ${media.lg(css`
    font-size: 24px;
    line-height: 1.33;
  `)}

  ${media.xl(css`
    font-size: 32px;
    line-height: 1.5;
  `)}
`;

export const P2 = styled.p`
  margin-top: 0;
  margin-bottom: 24px;
  font-weight: 100;
  font-size: 20px;
  line-height: 1.4;

  ${media.lg(css`
    font-size: 24px;
    line-height: 1.33;
  `)}

  ${media.xl(css`
    line-height: 1.67;
  `)}
`;

export const SEOList = styled.div`
  margin-top: 40px;
  letter-spacing: 0.2px;
  line-height: 2;

  ${media.xl(css`
    margin-top: 56px;
  `)}

  & > strong,
  & > a {
    display: block;
    color: ${({ theme: { colors } }) => colors.micaBlue};
  }

  & > a {
    &:hover,
    &:active {
      color: ${({ theme: { colors } }) => colors.actionBlue};
    }
  }
`;

import { push } from 'react-router-redux';

import * as ActionTypes from 'app/marketplace/home/constants';
import { closeOverlay } from 'app/shared/modules/overlay/actions';
import { getQueryFromFilters } from 'app/marketplace/filter/utils';

export const updateVehicleCount = (newCount) => {
  return { type: ActionTypes.GET_VEHICLE_COUNT, payload: newCount };
};

export const goToSearch = () => {
  return (dispatch, getState) => {
    const query = getQueryFromFilters(
      getState().filter,
      false,
      true,
      new Set(['page']),
    );

    dispatch(closeOverlay());

    if (
      (query.includes('productOffering=DE_DEALER_CASH') &&
        query.replace('productOffering=DE_DEALER_CASH', '').length === 0) ||
      (query.includes('productOffering=DE_D2C_CASH') &&
        query.replace('productOffering=DE_D2C_CASH', '').length === 0) ||
      (query.includes('productOffering=DE_INSTAMOTION_CASH') &&
        query.replace('productOffering=DE_INSTAMOTION_CASH', '').length ===
          0) ||
      (query.includes(
        'productOffering=DE_DEALER_CASH,DE_D2C_CASH,DE_INSTAMOTION_CASH,DE_DEALER_FINANCING_AC,DE_D2C_FINANCING_AC',
      ) &&
        query.replace(
          'productOffering=DE_DEALER_CASH,DE_D2C_CASH,DE_INSTAMOTION_CASH,DE_DEALER_FINANCING_AC,DE_D2C_FINANCING_AC',
          '',
        ).length === 0)
    ) {
      return dispatch(
        push({
          pathname: '/gebrauchtwagen/auto-online-kaufen',
        }),
      );
    }

    return dispatch(
      push({
        pathname: '/gebrauchtwagen',
        search: `?${query}`,
      }),
    );
  };
};

export const searchByMakeModelURL = (make = null, model = null) => {
  return (dispatch) => {
    let pathname;
    if (make && model) {
      pathname = `/gebrauchtwagen/${make}/${model}`;
    } else if (make && model === null) {
      pathname = `/gebrauchtwagen/${make}`;
    }

    dispatch(closeOverlay());

    return dispatch(
      push({
        pathname,
      }),
    );
  };
};

// Sets loading state of stage
export const setLoading = (isLoading) => ({
  type: ActionTypes.SET_LOADING,
  payload: isLoading,
});

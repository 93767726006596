import { put, fork, all, debounce } from 'redux-saga/effects';
import { LOCATION_CHANGE } from 'react-router-redux';

import { resetLocation } from 'app/marketplace/geolocation/actions';

import { getCampaignRoutes } from '../contentful/config';

export function* handleSpecialOfferPage({ payload: { pathname } }) {
  if (window._WAS_RENDERED_ON_SERVER_) {
    return;
  }
  if (
    pathname === '/angebote' ||
    getCampaignRoutes({ leadingSlash: true }).includes(pathname)
  ) {
    yield put(resetLocation());
  }
}

export function* updateFiltersSpecialOffersPage() {
  yield debounce(20, LOCATION_CHANGE, handleSpecialOfferPage);
}

export function* specialOfferSagas() {
  yield all([fork(updateFiltersSpecialOffersPage)]);
}

/* eslint-disable react/jsx-props-no-spreading */
export const returnCard = ({
  offer,
  TeaserComponent,
  OfferComponent,
  resetLocation,
  gamInfo,
}) => {
  const fields = offer?.fields;
  const id = offer?.sys?.id;
  const type = offer?.sys?.contentType?.sys?.id;
  const target = fields?.target;

  /* istanbul ignore next */
  switch (type) {
    case 'specialOfferTeaser':
      return (
        <TeaserComponent
          {...fields}
          key={offer?.sys?.id}
          target={target}
          gamInfo={gamInfo}
        />
      );
    default:
      return (
        <OfferComponent
          {...fields}
          key={id}
          target={target}
          resetLocation={resetLocation}
        />
      );
  }
};

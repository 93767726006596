/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */

import { compose } from 'redux';
import { connect } from 'react-redux';

import { IVehicle } from 'app/marketplace/vehicle/utils';

import { MobileNavControls } from './MobileNavControls';

export const ConnectedMobileNavControls = compose(
  connect((state: TRootState) => {
    const vehicle: IVehicle = {
      ...state?.checkoutVehicle?.vehicle,
      ...state?.checkoutVehicle,
    };

    return {
      vehicleId: vehicle?.id,
      dealerId: vehicle?.dealer?.id,
      checkoutType: state?.checkoutOrderInfo?.info?.checkoutType,
      checkoutStep: state?.checkoutOrderInfo?.info?.checkoutStep,
    };
  }),
)(MobileNavControls);

import { Route } from 'react-router';

import { getAsyncRouteComponentLoader } from 'app/shared/utils/utils';

export const DealerRoutes = (
  <Route name="contract-dealer">
    <Route
      path="contract-dealer/accept-sales-offer/:hash"
      getComponent={getAsyncRouteComponentLoader('DealerSalesOffer', () =>
        import('./DealerSalesOffer/DealerSalesOffer'),
      )}
    />
  </Route>
);

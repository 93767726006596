import { formatCurrency, formatNumber } from 'app/shared/utils/formatNumber';
import { objectToString } from 'app/shared/utils/objectToString';

import { getFinancingOptionalsServices } from './getFinancingOptionalsServices';
/**
 * @description removes unaltered calculator values from the template
 * */
const filterOutUnalteredValues = (parameters, value, responseValue) =>
  parameters
    .map((property) => property.id === value && property[responseValue])
    .find((element) => element);

/**
 * @description generates message out of user's financing selected values
 * */
export const getSelectedFinancingOptions = (calculationData) => {
  const {
    request: { parameters },
    result: { price, summary },
  } = calculationData;

  const mileage = filterOutUnalteredValues(parameters, 'mileage', 'value');
  const downPayment = filterOutUnalteredValues(
    parameters,
    'downPayment',
    'value',
  );

  const template = {
    financingProduct: filterOutUnalteredValues(
      parameters,
      'financingProduct',
      'label',
    ),
    duration: filterOutUnalteredValues(parameters, 'duration', 'label'),
    mileage: mileage && formatNumber(mileage),
    optionalServices: getFinancingOptionalsServices(parameters),
    rate: formatCurrency(price?.value),
    downPayment: downPayment && formatNumber(downPayment),
    finalRate: summary
      ?.map((item) => item.id === 'finalRate' && formatCurrency(item.value))
      .find((element) => element),
  };
  const formattedTemplate = objectToString(template);

  return formattedTemplate;
};

import { IGAMConfig } from 'app/shared/modules/Campaign/GAM/GamBanner/GamBanner.types';

const bannerSizes = {
  sm: [{ width: 320, height: 40 }],
  md: [{ width: 768, height: 40 }],
  lg: [{ width: 768, height: 40 }],
  xl: [{ width: 768, height: 40 }],
};

const bannerArea = {
  sm: { width: 320, height: 40 },
  md: { width: 768, height: 40 },
  lg: { width: 768, height: 40 },
  xl: { width: 768, height: 40 },
};

export const PDP_TOP_BANNER: IGAMConfig = {
  adUnitId: 'div-gam-pdp-top-banner',
  adUnitPath: '/22376835783/pdp-top-banner',
  sizes: bannerSizes,
  bannerArea,
  trackingData: {
    clickEvent: {
      eventName: 'campaign_pdp_top_banner_click',
      placement: 'PDP',
    },
    impressionEvent: {
      category: 'PDP',
      label: 'PDP top banner loaded',
    },
  },
};

import { getNormalizedVehicle } from 'app/shared/utils/vehicle';
import { ActionTypes } from 'app/marketplace/favourites/constants';

export const toggleFavouritesIds = (state, payload) => {
  let ids = state.favouriteIds.slice(0);

  if (ids.filter(id => id === payload.vehicleId).length === 0) {
    ids.push(payload.vehicleId);
  } else {
    ids = ids.filter(id => id !== payload.vehicleId);
  }
  return ids;
};

const defaultState = () => {
  return {
    pending: false,
    pendingVehicle: null,
    favouriteIds: [],
    favouriteVehicles: [],
    localFavouriteIds: [],
    showFavLoginPopup: true,
    showFavouriteTip: false,
    totalPages: 1,
    currentPage: 0,
    error: null,
    currentVehicleId: null,
    subscriptionEmail: null,
    subscriptionLastUpdatedAt: null,
    subscriptionStatus: null,
    subscriptionUserProfileId: null,
  };
};

export const favourites = (state = defaultState(), action) => {
  const { payload, error } = action;

  switch (action.type) {
    case ActionTypes.GET_FAVOURITE_IDS_STARTED:
    case ActionTypes.GET_FAVOURITE_VEHICLES_STARTED:
      return {
        ...state,
        pending: true,
      };
    case ActionTypes.TOGGLE_SAVED_VEHICLE_STARTED:
      return {
        ...state,
        pending: true,
        pendingVehicle: payload.vehicle,
      };
    case ActionTypes.GET_FAVOURITE_IDS_COMPLETED:
    case ActionTypes.GET_FAVOURITE_IDS_LOCAL_COMPLETED:
      return {
        ...state,
        favouriteIds: payload,
        pending: false,
        error: null,
      };
    case ActionTypes.GET_FAVOURITE_VEHICLES_COMPLETED: {
      const { content, totalPages, pageable } = payload;
      const pageNumber = pageable ? pageable.pageNumber : 0;

      return {
        ...state,
        favouriteVehicles: content
          ? content.map(v => getNormalizedVehicle(v))
          : [],
        pending: false,
        error: null,
        totalPages: totalPages || 1,
        currentPage: pageNumber,
      };
    }
    case ActionTypes.GET_FAVOURITE_IDS_FAILED:
      return {
        ...state,
        error,
        pending: false,
      };
    case ActionTypes.GET_FAVOURITE_VEHICLES_FAILED:
      return {
        ...state,
        error,
        pending: false,
        favouriteVehicles: [],
      };
    case ActionTypes.TOGGLE_SAVED_VEHICLE_COMPLETED:
      return {
        ...state,
        favouriteIds: [
          ...new Set([...payload.vehicleId, ...state.favouriteIds]),
        ],
        currentVehicleId: !payload.isSync ? payload.vehicleId[0] : null,
        pending: false,
        error: null,
        pendingVehicle: null,
      };
    case ActionTypes.SUBSCRIBE_TO_FAVOURITES_COMPLETED:
      return {
        ...state,
        subscriptionEmail: payload.subscriptionEmail,
        subscriptionUserProfileId: payload.userProfileId,
      };
    case ActionTypes.GET_SUBSCRIPTION_STATUS_COMPLETED:
      return {
        ...state,
        subscriptionStatus: payload.subscriptionStatus,
        subscriptionLastUpdatedAt: payload.subscriptionLastUpdatedAt,
      };
    case ActionTypes.RESET_WATCH_PRICE_EMAIL_COMPLETED:
      return {
        ...state,
        subscriptionEmail: null,
      };
    case 'RESET_CURRENT_FAV_VEHICLE':
      return {
        ...state,
        currentVehicleId: null,
      };
    case ActionTypes.TOGGLE_SAVED_VEHICLE_FAILED:
    case ActionTypes.SUBSCRIBE_TO_FAVOURITES_FAILED:
    case ActionTypes.UNSUBSCRIBE_TO_FAVOURITE_FAILED:
    case ActionTypes.GET_SUBSCRIPTION_STATUS_FAILED:
    case ActionTypes.TOGGLE_DELETED_VEHICLE_FAILED:
      return {
        ...state,
        error,
        pending: false,
      };
    case ActionTypes.TOGGLE_DELETED_VEHICLE_COMPLETED:
      return {
        ...state,
        favouriteIds: state.favouriteIds.filter(
          favouriteId => favouriteId !== payload.vehicleId,
        ),
        pending: false,
        error: null,
        pendingVehicle: null,
      };
    case ActionTypes.RESET_LOCAL_FAVOURITES:
      return {
        ...state,
        localFavouriteIds: [],
      };
    case ActionTypes.TOGGLE_LOCAL_FAVOURITE:
      return {
        ...state,
        localFavouriteIds: toggleFavouritesIds(state, payload),
      };
    case ActionTypes.SHOW_FAV_LOGIN_POPUP:
      return {
        ...state,
        showFavLoginPopup: payload.flag,
        showFavouriteTip: true,
      };
    case ActionTypes.HIDE_FAV_LOGIN_POPUP:
      return {
        ...state,
        showFavouriteTip: false,
      };
    default:
      return state;
  }
};

favourites.whitelist = [
  'favouriteIds',
  'localFavouriteIds',
  'showFavLoginPopup',
  'subscriptionEmail',
  'subscriptionUserProfileId',
];

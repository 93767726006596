/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import React, { memo, SyntheticEvent } from 'react';
import { useInView } from 'react-intersection-observer';

// @TODO Apply correct interface for styles
interface ILazyImage {
  imageUrl: string;
  alt: string;
  styles?: any;
  width?: string;
  height?: string;
  className?: string;
  handleError?: (error: SyntheticEvent) => void;
}

export const LazyImage = memo(
  ({
    imageUrl,
    alt,
    styles = {},
    width = 'auto',
    height = 'auto',
    className = '',
    handleError = undefined,
  }: ILazyImage) => {
    const [ref, inView] = useInView({
      triggerOnce: true,
      rootMargin: '200px 0px',
    });
    return (
      <img
        src={inView ? imageUrl : ''}
        alt={alt}
        style={styles}
        width={width}
        height={height}
        ref={ref}
        className={className}
        onError={(error: SyntheticEvent) => handleError && handleError(error)}
      />
    );
  },
);

import { memo } from 'react';
import PropTypes from 'prop-types';

import { CartileMakeModelTrim } from 'app/shared/modules/Cartile/CartileUIComponents/CartileMakeModelTrim/CartileMakeModelTrim';
import { CartileFavouriteButton } from 'app/shared/modules/Cartile/CartileUIComponents/CartileFavouriteButton/CartileFavouriteButton';

const CartileHeaderWithFavButtonCmp = ({
  make,
  trim,
  model,
  isLoading,
  isFavourite,
  isId3Vehicle,
  handleToggleFavourites,
  isNewVehicle,
}) => (
  <>
    <CartileMakeModelTrim
      make={make}
      trim={trim}
      model={model}
      isLoading={isLoading}
      isNewVehicle={isNewVehicle}
    />
    {!isId3Vehicle && (
      <CartileFavouriteButton
        isLoading={isLoading}
        isFavourite={isFavourite}
        handleToggleFavourites={handleToggleFavourites}
      />
    )}
  </>
);
CartileHeaderWithFavButtonCmp.propTypes = {
  isLoading: PropTypes.bool,
  isId3Vehicle: PropTypes.bool,
  make: PropTypes.string.isRequired,
  trim: PropTypes.string.isRequired,
  model: PropTypes.string.isRequired,
  isFavourite: PropTypes.bool.isRequired,
  handleToggleFavourites: PropTypes.func.isRequired,
  isNewVehicle: PropTypes.bool,
};
CartileHeaderWithFavButtonCmp.defaultProps = {
  isLoading: false,
  isId3Vehicle: false,
  isNewVehicle: false,
};

export const CartileHeaderWithFavButton = memo(CartileHeaderWithFavButtonCmp);

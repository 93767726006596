import { memo } from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash.isempty';
import { BLOCKS } from '@contentful/rich-text-types';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import htmlParser from 'html-react-parser';

import {
  TextSt,
  TitleSt,
  HomeSEOTextSt,
} from 'app/marketplace/contentful/shared/HomeSEOText/HomeSEOText.css';

const Paragraph = (node, children) => <p>{children}</p>;

const options = {
  renderNode: {
    [BLOCKS.PARAGRAPH]: Paragraph,
  },
};

const HomeSEOTextCmp = ({ seoText }) => {
  if (isEmpty(seoText) || seoText.error) {
    return null;
  }

  const { text, header } = seoText;

  return (
    <HomeSEOTextSt grid md={12}>
      <TitleSt as="h2">{htmlParser(header)}</TitleSt>
      <TextSt>{documentToReactComponents(text, options)}</TextSt>
    </HomeSEOTextSt>
  );
};

HomeSEOTextCmp.propTypes = {
  seoText: PropTypes.object.isRequired,
};

export const HomeSEOText = memo(HomeSEOTextCmp);

import { Fragment, memo } from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash.isempty';

import { Link } from 'app/shared/ui';
import { trackAction } from 'app/shared/utils/tracking';
import { createGUID } from 'app/shared/utils/utils';
import {
  SeoSt,
  ListSt,
  FieldListSt,
  HeadlineSt,
  GridContainerSt,
} from 'app/marketplace/contentful/shared/SeoLinks/SeoLinks.css';

export const SeoLinksCmp = ({ resetListing, seoLinks, deviceType }) => {
  const isMobile = deviceType === 'mobile';
  if (isEmpty(seoLinks) || seoLinks.error) {
    return null;
  }

  const onClick = (e) => {
    const linkText = e.target.innerHTML;

    resetListing();
    trackAction('direct-search_text_click', {
      category: 'home',
      label: `${linkText}`,
      description: 'user clicks on a SEO link',
    });
  };

  return (
    <SeoSt>
      {seoLinks.map((links) => (
        <GridContainerSt
          grid
          md={3}
          xl={6}
          key={createGUID()}
          className="grid-container"
        >
          <HeadlineSt>{links.heading}</HeadlineSt>

          {links.linkList.map(({ fields: levelOneLinks }) => {
            return levelOneLinks.url ? (
              <FieldListSt key={createGUID()}>
                <Link
                  to={levelOneLinks.url}
                  onClick={onClick}
                  textDecoration="underline"
                  fontWeight="medium"
                  hovercolor="actionBlue"
                  color="micaBlue"
                >
                  {levelOneLinks.title}
                </Link>
              </FieldListSt>
            ) : (
              <ListSt key={createGUID()}>
                <strong>{levelOneLinks.title}</strong>

                {levelOneLinks.links.map(({ fields: levelTwoLinks }) => {
                  const level2LinkTitle = levelTwoLinks.title;
                  const linkTitle =
                    level2LinkTitle.length > 17 && isMobile
                      ? `${levelTwoLinks.title.substring(0, 14)}...`
                      : level2LinkTitle;
                  return (
                    <Fragment key={createGUID()}>
                      <Link
                        to={levelTwoLinks.url}
                        onClick={onClick}
                        textDecoration="underline"
                        fontWeight="medium"
                        data-testid={`marketplace-contentful-shared-SeoLinks-${level2LinkTitle}`}
                        hovercolor="actionBlue"
                        color="micaBlue"
                      >
                        {linkTitle}
                      </Link>
                    </Fragment>
                  );
                })}
              </ListSt>
            );
          })}
        </GridContainerSt>
      ))}
    </SeoSt>
  );
};

SeoLinksCmp.propTypes = {
  resetListing: PropTypes.func.isRequired,
  seoLinks: PropTypes.array.isRequired,
};

export const SeoLinks = memo(SeoLinksCmp);

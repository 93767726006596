import { memo } from 'react';
import PropTypes from 'prop-types';

import { Text } from 'app/shared/ui';
import {
  CartileCityLocationTextSt,
  CartileCityLocationContainerSt,
} from 'app/shared/modules/Cartile/CartileUIComponents/CartileLocation/CartileCityLocation/CartileCityLocation.css';
import { CartileLoadingStyle } from 'app/shared/modules/Cartile/CartileUIComponents/CartileUIComponents.css';

const CartileCityLocationCmp = ({ city, isLoading }) => (
  <CartileCityLocationContainerSt css={[isLoading && CartileLoadingStyle]}>
    <Text as="i" mr="4px" className="if-icon-location" />
    <CartileCityLocationTextSt fontSize={['10px', '12px']}>
      {city}
    </CartileCityLocationTextSt>
  </CartileCityLocationContainerSt>
);

CartileCityLocationCmp.propTypes = {
  isLoading: PropTypes.bool,
  city: PropTypes.string.isRequired,
};

CartileCityLocationCmp.defaultProps = {
  isLoading: false,
};

export const CartileCityLocation = memo(CartileCityLocationCmp);

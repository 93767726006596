import { createSelector } from 'reselect';

export const selectRoutingLocation = (state) =>
  state.routing.locationBeforeTransitions;

export const selectSpecialOffers = (state) => state.specialOffers.specialOffers;

export const isPdpCampaignTopBannerAvailableSelector = (state) => {
  return (
    // Fixes the loading glitch of TopBanner while the vehicle is loading
    (state.specialOffers.isPdpCampaignTopBannerAvailable || state.vehicle.pending)
  );
};

/**
 *
 * @param l - the list of pairs e.g.: [['a', 1], ['b', 2]]
 * @return {*} = the object created from that e.g.: {a: 1, b: 2}
 */
const createObjectFromEntries = (l) =>
  l.reduce((a, [k, v]) => ({ ...a, [k]: v }), {});

/**
 * Removes keys from an object if they are in the blacklist passed as second argument
 * @param queryObject - the object you want to clean
 * @param blacklist - the list of keys that are not allowed
 * @return {{}}
 */
export const cleanQueryObject = (queryObject = {}, blacklist = []) => {
  const result = {};
  Object.keys(queryObject).forEach((key) => {
    if (!blacklist.includes(key)) {
      result[key] = queryObject[key];
    }
  });

  return result;
};

/**
 * Selects the current special offer based on the current URL
 * Does that by finding the matching specialOffers to that URL and returning the one with the most overlap between url
 * query parameters and specialOffer filterUrl query parameters
 * @returns {Object} special offer that fits the current route or null
 */
export const getSpecialOfferSelector = createSelector(
  [selectSpecialOffers, selectRoutingLocation],
  (specialOffers, routingLocation) => {
    const query = routingLocation?.search;
    if (!query || !specialOffers) {
      return null;
    }
    // an object representing the current urls query parameters, cleaned from Filters like 'page' - see notFiltersThatCountAsFilters, but with lat and lon
    const queryObject = cleanQueryObject(
      createObjectFromEntries([...new URLSearchParams(query).entries()]),
      [
        'q',
        'page',
        'error',
        'allMakes',
        'possibleMakes',
        'possibleModels',
        'performanceRangeSwitch',
        'budgetRangeSwitch',
        'sortBy',
        'fullQuery',
        'lifeStyle',
        'headerCLP',
        // marketing ad IDs
        'gLocationId',
        'gclid',
        'cid',
        'adid',
        'agid',
        'kw',
        'mt',
      ],
    );

    let smallestDifference = null;
    let bestMatch = null;

    // try and greedily match the query parameters from the contentful entries for specialOffer and return the best match
    specialOffers.forEach((specialOffer) => {
      const specialOfferQueryObject = createObjectFromEntries([
        ...new URLSearchParams(specialOffer.fields.filterUrl).entries(),
      ]);
      // TODO this matcher is not sufficient
      const doesMatch =
        Object.keys(specialOfferQueryObject).filter((queryKey) => {
          return specialOfferQueryObject[queryKey] !== queryObject[queryKey];
        }).length === 0;
      if (doesMatch) {
        const diff =
          Object.keys(queryObject).length -
          Object.keys(specialOfferQueryObject).length;
        if (smallestDifference === null) {
          smallestDifference = diff;
          bestMatch = specialOffer;
        }
        if (diff < smallestDifference) {
          smallestDifference = diff;
          bestMatch = specialOffer;
        }
      }
    });
    return bestMatch;
  },
);

/**
 * Get the default CLP Special Offers Banner
 * @returns {Object} - the default CLP Special Offers Banner (Contentful's fields)
 */
export const getClpBanner = (state) => state?.specialOffers?.clpBanner;

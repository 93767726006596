/* istanbul ignore file */
import merge from 'lodash.merge';

import * as types from './types';

export const defaultState = {
  isReady: true, // the step is ready or not to show the user
  step: 0,
  steps: {
    0: { screen: 0 },
  },
  data: {},
  screens: [], // screen names for the wizards
  PDPvehicle: null,
  survey: {
    show: true,
  },
};

export default (state = defaultState, action = {}) => {
  const nextStep = state.step + 1;
  const currentStep = state.steps[state.step] || {};
  const { screen: currentScreen } = currentStep;
  switch (action.type) {
    case types.TRADEIN_SET_VEHICLE_PDP:
      return { ...state, PDPvehicle: action.PDPvehicle };

    case types.TRADEIN_SET_SCREEN_NAMES:
      return { ...state, screens: action.screens };

    case types.TRADEIN_SET_VEHICLE_STATUS:
      return {
        ...state,
        valuatedVehicle: action.data,
      };

    case types.TRADEIN_RUN: {
      return {
        ...state,
        data: merge(state.data, action.payload.request),
        steps: {
          ...state.steps,
          [state.step]: {
            ...currentStep,
            ...action.payload,
          },
        },
      };
    }

    case types.TRADEIN_RESET:
      return {
        ...JSON.parse(JSON.stringify(defaultState)),
        valuatedVehicle: state.valuatedVehicle,
      };
    case types.TRADEIN_UPDATE_STEP:
      return {
        ...state,
        data: merge(state.data, action.payload.request),
        steps: {
          ...state.steps,
          [state.step]: {
            ...currentStep,
            ...action.payload,
          },
        },
      };

    case types.TRADEIN_SET_VEHICLE:
      return {
        ...state,
        data: { ...state.data, vehicle: action.vehicle },
      };

    case types.TRADEIN_SET_STATUS:
      return {
        ...state,
        isReady: action.status,
      };

    case types.TRADEIN_RESET_STEP: {
      return {
        ...state,
        step: 0,
        steps: {
          0: { screen: 0 },
        },
        screens: [],
        data: {},
      };
    }
    case types.TRADEIN_SCREEN_NEXT:
      return {
        ...state,
        step: nextStep,
        steps: {
          ...state.steps,
          [nextStep]: {
            screen: currentScreen + 1,
            data: action.data,
          },
        },
      };

    case types.TRADEIN_STEP_NEXT:
      return {
        ...state,
        step: nextStep,
        steps: {
          ...state.steps,
          [nextStep]: {
            screen: currentScreen,
            data: action.data,
          },
        },
      };
    case types.TRADEIN_SCREEN_CHANGE:
      return {
        ...state,
        step: nextStep,
        steps: {
          ...state.steps,
          [nextStep]: {
            screen: action.screenIndex,
            data: action.data,
          },
        },
      };

    case types.TRADEIN_STEP_PREV:
      return {
        ...state,
        step: state.step - 1,
      };

    case types.TRADEIN_USER_SURVEY:
      return {
        ...state,
        survey: {
          ...state.survey,
          show: action.status,
        },
      };

    default:
      return state;
  }
};

import { Redirect, Route } from 'react-router';

import { getAsyncRouteComponentLoader } from 'app/shared/utils/utils';
import { ConnectedPageTemplate } from 'app/shared/modules/pageTemplate/PageTemplate/ConnectedPageTemplate';

import { getCampaignRoutes } from './config';

export const ContentfulRoutes = (
  <Route
    name="contentful"
    // eslint-disable-next-line react-perf/jsx-no-new-function-as-prop
    component={(props) => (
      // eslint-disable-next-line react/jsx-props-no-spreading
      <ConnectedPageTemplate target="Contentful" {...props} />
    )}
  >
    <Route
      path="magazine"
      exact
      getComponent={getAsyncRouteComponentLoader(
        'ConnectedContentfulMainPage',
        () =>
          import(
            './spaces/magazine/entries/ContentfulMainPage/ConnectedContentfulMainPage'
          ),
      )}
    />
    <Route
      path="magazine/category/*"
      getComponent={getAsyncRouteComponentLoader(
        'ConnectedContentfulCategory',
        () =>
          import(
            './spaces/magazine/entries/ContentfulCategory/ConnectedContentfulCategory'
          ),
      )}
    />
    <Route
      path="magazine/*"
      getComponent={getAsyncRouteComponentLoader(
        'ConnectedContentfulArticle',
        () =>
          import(
            './spaces/magazine/ContentfulArticle/ConnectedContentfulArticle'
          ),
      )}
    />
    <Route
      path="angebote"
      getComponent={getAsyncRouteComponentLoader('ConnectedSpecialOffers', () =>
        import(
          './pages/SpecialOffersPage/ConnectedSpecialOffers/ConnectedSpecialOffers'
        ),
      )}
    />
    {getCampaignRoutes().map((campaign) => (
      <Route
        key={campaign}
        path={campaign}
        getComponent={getAsyncRouteComponentLoader(
          'ConnectedCampaignLandingPage',
          () => import('./pages/Campaign/ConnectedCampaignLandingPage'),
        )}
      />
    ))}

    <Route
      path="auto/gebrauchtwagen/*"
      getComponent={getAsyncRouteComponentLoader(
        'ConnectedContentfulLocationPage',
        () =>
          import(
            './shared/LocationPages/ContentfulLocationPage/ConnectedContentfulLocationPage'
          ),
      )}
    />
    <Route
      path="auto-online-bestellen"
      getComponent={getAsyncRouteComponentLoader(
        'ConnectedOnlinePurchase',
        () => import('./pages/OnlinePurchase/ConnectedOnlinePurchase'),
      )}
    />
    <Route
      path="click-und-collect"
      getComponent={getAsyncRouteComponentLoader(
        'ConnectedClickAndCollect',
        () => import('./pages/ClickAndCollect/ConnectedClickAndCollect'),
      )}
    />
    <Route
      path="kfz-versicherung"
      getComponent={getAsyncRouteComponentLoader('ConnectedInsuranceHub', () =>
        import('./pages/InsuranceHub/ConnectedInsuranceHub'),
      )}
    />
    <Route
      path="gebrauchsspuren-qualitaet"
      getComponent={getAsyncRouteComponentLoader(
        'ConnectedUsageDamagePage',
        () => import('./pages/UsageDamagePage/ConnectedUsageDamagePage'),
      )}
    />
    <Route
      path="autokauf-online-so-funktionierts"
      getComponent={getAsyncRouteComponentLoader(
        'ConnectedHowItWorksPage',
        () => import('./pages/HowItWorks/ConnectedHowItWorksPage'),
      )}
    />

    <Route
      path="auto"
      getComponent={getAsyncRouteComponentLoader('ConnectedAllMakes', () =>
        import('./spaces/seo/pages/AllMakes/ConnectedAllMakes'),
      )}
    />
    <Route
      path="auto/:make"
      getComponent={getAsyncRouteComponentLoader('ConnectedMake', () =>
        import('./spaces/seo/pages/Make/ConnectedMake'),
      )}
    />
    <Route
      path="auto/:make/:model"
      getComponent={getAsyncRouteComponentLoader('ConnectedModel', () =>
        import('./spaces/seo/pages/Model/ConnectedModel'),
      )}
    />
    <Route
      path="auto/:make/:model/:variant"
      getComponent={getAsyncRouteComponentLoader('ConnectedVariant', () =>
        import('./spaces/seo/pages/Variant/ConnectedVariant'),
      )}
    />
    <Redirect from="/gewinnspiel" to="/" />
    <Route
      path="*"
      getComponent={getAsyncRouteComponentLoader(
        'ConnectedContentfulSpace',
        () => import('./spaces/heycar/ConnectedContentfulSpace'),
      )}
    />
  </Route>
);

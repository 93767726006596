export const screens = {
  FORM: 'FORM',
  SOLD_OUT: 'SOLD_OUT',
  EMAIL_COMPLETE: 'EMAIL_COMPLETE',
  VIDEO_COMPLETE: 'VIDEO_COMPLETE',
};

export const leadTypes = {
  EMAIL: 'email',
  VIDEO: 'video',
};

export const AUTOBILD_API_URL = '/partner/leads/';

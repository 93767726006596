import { memo } from 'react';
import PropTypes from 'prop-types';

import { noop } from 'app/shared/utils/utils';
import { trackAction } from 'app/shared/utils/tracking';
import {
  StageHeading,
  StageContainerSt,
  OnBoardingContainerSt,
  ContentfulStageWrapperSt,
  ContentfulStageContainerSt,
  OnBoardingContainerWrapperSt,
} from 'app/marketplace/home/Stage/ContentfulStage/ContentfulStage.css';
import { ConnectedFilterPanel } from 'app/marketplace/home/Stage/FilterPanel/ConnectedFilterPanel';

import { StageBanner } from '../StageBanner/StageBanner';

const ContentfulStageCmp = ({
  hasOverlay,
  breakpoint,
  stage,
  onLoadImages,
  isLoading,
}) => {
  const {
    title,
    showTitle,
    hasSingleImage,
    singleImage,
    sm,
    md,
    lg,
    xl,
    buttonLink,
    buttonTrackingName,
  } = stage;

  // eslint-disable-next-line react-perf/jsx-no-new-function-as-prop
  /* istanbul ignore next */
  const handleStageBannerClick = () => {
    if (buttonTrackingName)
      trackAction(buttonTrackingName, {
        category: 'home',
        description: 'user clicked CTA on homepage stage',
      });
  };

  const imageUrl = singleImage?.fields.file.url;
  const responsiveImage = {
    sm,
    md,
    lg,
    xl,
  };

  return (
    <ContentfulStageContainerSt isLoading={isLoading} hasOverlay={hasOverlay}>
      <ContentfulStageWrapperSt>
        <OnBoardingContainerWrapperSt>
          <OnBoardingContainerSt>
            <ConnectedFilterPanel />
          </OnBoardingContainerSt>
        </OnBoardingContainerWrapperSt>
        <StageContainerSt>
          {showTitle && (
            <StageHeading as="h1" color="white" mt={[24, 56]} mb={[24, 48]}>
              {title}
            </StageHeading>
          )}
          <StageBanner
            breakpoint={breakpoint}
            imageUrl={imageUrl}
            hasSingleImage={hasSingleImage}
            onLoadImages={onLoadImages}
            responsiveImage={responsiveImage}
            stageBannerLink={buttonLink}
            handleStageBannerClick={handleStageBannerClick}
            title={title}
          />
        </StageContainerSt>
      </ContentfulStageWrapperSt>
    </ContentfulStageContainerSt>
  );
};

export const ContentfulStage = memo(ContentfulStageCmp);

ContentfulStageCmp.propTypes = {
  stage: PropTypes.object.isRequired,
  isLoading: PropTypes.bool,
  hasOverlay: PropTypes.bool,
  breakpoint: PropTypes.string,
  onLoadImages: PropTypes.func,
};

ContentfulStageCmp.defaultProps = {
  isLoading: true,
  breakpoint: 'sm',
  hasOverlay: false,
  onLoadImages: noop,
};

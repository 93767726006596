import { colors } from '../components/variables/colors';

// todo - add new colors to color palette
export const backdrops = {
  championshipWhite: colors.championshipWhite,
  heycarMint: colors.heycarMint,
  darkBlue: colors.brandSecondary,
  micaBlue: colors.brandSecondaryLight,
  sunbeanLighter: colors.sunbeanLighter,
  graySuperLight: colors.graySuperLight,
  mintSuperLight: colors.mintSuperLight,
  sunbeanSuperLight: colors.sunbeanSuperLight,
};

import styled, { css } from 'styled-components';
import { Flex } from '@rebass/grid';

export const CartilePriceContainersSt = styled(Flex).attrs(
  ({ flexDirection }) => ({
    alignItems: 'flex-end',
    flexDirection: flexDirection || 'column',
    justifyContent: 'flex-end',
  }),
)`
  ${({ isLoading }) =>
    !isLoading &&
    css`
      flex: 1 0 auto;
    `}
  button {
    padding: 0;
    > div span {
      font-weight: 500;
    }
  }
`;

export const CartilePriceContainersMtlTotalPriceReducedSt = styled(Flex).attrs(
  () => ({
    alignItems: 'baseline',
  }),
)`
  ${() =>
    css`
      display: block;
    `}
  & > div:first-child {
    margin-right: 5px;
  }
`;

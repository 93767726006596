import { memo } from 'react';

import { LinkButton } from 'app/shared/ui';

import {
  TextSt,
  ImageSt,
  TuvBannerSt,
  TextDescriptionSt,
} from './TuvBanner.css';

const TuvBannerCmp = () => {
  return (
    <TuvBannerSt>
      <ImageSt src="/assets/home/tuv-home.png" />
      <TextSt>
        Unsere Kundenzufriedenheit ist{' '}
        <LinkButton to="/about" target="_blank" variant="tertiary" pr={0}>
          TÜV zertifiziert
        </LinkButton>
        !
      </TextSt>
      <TextDescriptionSt>
        Bei uns ist Ihre Zufriedenheit Priorität und in der unabhängigen
        Kundenbefragung vom TÜV haben dies 375 Kunden bestätigt.
      </TextDescriptionSt>
    </TuvBannerSt>
  );
};

TuvBannerCmp.displayName = 'TuvBanner';

TuvBannerCmp.defaultProps = {
  maxValue: null,
  isMtlRate: false,
  handlePriceToggle: null,
};

export const TuvBanner = memo(TuvBannerCmp);

import React, { useEffect, useRef } from 'react';

const MicroComboTrustpilotWidget = (): JSX.Element => {
  const ref = useRef(null);

  useEffect(() => {
    if (window?.Trustpilot) {
      window.Trustpilot.loadFromElement(ref.current, true);
    }
  }, []);
  return (
    <div
      className="trustpilot-widget"
      data-locale="de-DE"
      data-template-id="5419b6ffb0d04a076446a9af"
      data-businessunit-id="5c65db740526720001ee299b"
      data-style-height="20px"
      data-style-width="100%"
      data-theme="light"
      data-text-color="#ffffff"
      ref={ref}
    />
  );
};
export default MicroComboTrustpilotWidget;

import { ActionTypes, SUCCESS_STATUS } from './constants';

export const defaultState = () => {
  return {
    submitting: false,
    sweepstakesRegistration: null,
  };
};

export const crm = (state = defaultState(), action) => {
  const { type, payload } = action;

  switch (type) {
    case ActionTypes.SWEEPSTAKES_SUBMISSION_STARTED:
    case ActionTypes.NEWSLETTER_SUBMISSION_STARTED: {
      return {
        ...state,
        submitting: true,
      };
    }

    case ActionTypes.NEWSLETTER_SUBMISSION_COMPLETED: {
      return {
        ...state,
        submitting: false,
        newsletterRegistration: SUCCESS_STATUS,
      };
    }

    case ActionTypes.SWEEPSTAKES_SUBMISSION_COMPLETED: {
      return {
        ...state,
        submitting: false,
        sweepstakesRegistration: SUCCESS_STATUS,
      };
    }

    case ActionTypes.NEWSLETTER_SUBMISSION_FAILED: {
      return {
        ...state,
        submitting: false,
        newsletterRegistration: {
          error: payload.error,
        },
      };
    }

    case ActionTypes.SWEEPSTAKES_SUBMISSION_FAILED: {
      return {
        ...state,
        submitting: false,
        sweepstakesRegistration: {
          error: payload.error,
        },
      };
    }

    default:
      return state;
  }
};

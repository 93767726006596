import { Children, cloneElement, PureComponent } from 'react';
import PropTypes from 'prop-types';

import { Overlay } from '../Overlay/Overlay';
import { ErrorBoundary } from '../ErrorBoundary/ErrorBoundary';

import {
  HeaderIconSt,
  SideBarContentSt,
  SideBarFooterSt,
  SideBarHeaderSt,
  SideBarSt,
} from './SideBar.css';

export class SideBar extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      open: null,
      contentRef: null,
      isInputFocused: false,
      windowInnerHeight: false,
    };

    this.onFocusInput = this.onFocusInput.bind(this);
    this.onBlurInput = this.onBlurInput.bind(this);
    this.updateInnerHeightIfChanged = this.updateInnerHeightIfChanged.bind(
      this,
    );
  }

  static getDerivedStateFromProps(props, state) {
    const { open } = props;

    return {
      open,
      windowInnerHeight:
        (open && CLIENT && window.innerHeight) === state.windowInnerHeight
          ? state.windowInnerHeight
          : window.innerHeight,
    };
  }

  componentDidMount() {
    // the line below is a hack to make the animation of the menu work
    // we will use this until we have more information or someone has a different solution
    // eslint-disable-next-line no-unused-vars
    const scrolled =
      window.pageYOffset ||
      document.documentElement.scrollTop ||
      document.body.scrollTop ||
      0;
    const { setSlideAnimation } = this.props;
    setSlideAnimation(true);
  }

  onFocusInput() {
    this.setState({ isInputFocused: true });
  }

  onBlurInput() {
    this.setState({ isInputFocused: false });
  }

  updateInnerHeightIfChanged() {
    setTimeout(() => {
      if (window.innerHeight !== this.state.windowInnerHeight) {
        this.setState({ windowInnerHeight: window.innerHeight });
      }
    }, 200);
  }

  scrollTo(y) {
    // eslint-disable-next-line react/no-direct-mutation-state
    this.state.contentRef.scrollTop = y;
  }

  render() {
    const {
      children,
      title,
      footer,
      headerButton,
      onClose,
      theme,
    } = this.props;
    const { open, contentRef, windowInnerHeight } = this.state;
    const childrenWithProps = Children.map(children, child => {
      return cloneElement(child, {
        parentRef: contentRef,
        // the following is an ios hack - remove this and suffer the wrath of disappearing dom elements
        // any component that receives this (filterpane, makefilter, sidebarcontentst)
        onFocusInput: this.onFocusInput,
        onBlurInput: this.onBlurInput,
        style: { height: '100%' },
      });
    });

    const sideBarHeader = (
      <SideBarHeaderSt theme={theme}>
        {title}
        {headerButton}
        <HeaderIconSt
          className="if-icon-close"
          onClick={onClose}
          theme={theme}
        />
      </SideBarHeaderSt>
    );

    const sideBarContent = (
      <SideBarContentSt
        ref={c => {
          if (!this.state.contentRef) {
            this.setState({ contentRef: c }, () => {
              c.addEventListener('touchend', this.updateInnerHeightIfChanged);
            });
          }
        }}
        theme={theme}
      >
        <ErrorBoundary>{childrenWithProps}</ErrorBoundary>
      </SideBarContentSt>
    );

    const sideBarFooter = footer && (
      <SideBarFooterSt isOpen={open} theme={theme}>
        {footer}
      </SideBarFooterSt>
    );
    return (
      <>
        <Overlay show={open} onClose={onClose} useWhiteBg />
        <SideBarSt
          slideAnimation={this.props.slideAnimation}
          hasFooter={footer}
          windowInnerHeight={windowInnerHeight}
          theme={theme}
        >
          {sideBarHeader}
          {sideBarContent}
          {sideBarFooter}
        </SideBarSt>
      </>
    );
  }
}

SideBar.propTypes = {
  children: PropTypes.any,
  open: PropTypes.bool,
  headerButton: PropTypes.node,
  footer: PropTypes.node,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  onClose: PropTypes.func.isRequired,
  setSlideAnimation: PropTypes.func,
  slideAnimation: PropTypes.bool,
};

SideBar.defaultProps = {
  children: null,
  open: false,
  headerButton: null,
  footer: null,
  title: '',
  setSlideAnimation: () => {},
  slideAnimation: true,
};

import styled, { css } from 'styled-components';
import { Flex } from '@rebass/grid';

export const CartileAdditionalInfosContainerSt = styled(Flex)`
  z-index: 1;
  margin-top: 8px;
  ${({ hasMarginBottom }) =>
    hasMarginBottom &&
    css`
      margin-bottom: 8px;
    `}

  ${({ isUnavailable }) =>
    isUnavailable &&
    css`
      opacity: 0.4;
      pointer-events: none;
    `}
`;

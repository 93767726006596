import { auth as authMarketplace } from './marketplace/accounts/reducer';
import { contactDealer } from './marketplace/contactDealer/reducer';
import { activeLeadManagement } from './marketplace/premium/reducer';
import { carComparison } from './marketplace/carComparison/reducer';
import { favourites } from './marketplace/favourites/reducer';

export const localStorageReducers = {
  authMarketplace,
  contactDealer,
  activeLeadManagement,
  carComparison,
  favourites,
};

import { memo } from 'react';
import PropTypes from 'prop-types';
import uuid from 'uuid/v4';

import { Heading4 } from 'app/shared/ui';

import { ThreeArticlesLayoutSt } from '../SharedComponentsSt/SharedComponentsSt.css';
import { ArticleButton } from '../ArticleButton/ArticleButton';
import { ArticlePreview } from '../ArticlePreview/ArticlePreview';

import { MarketplaceFeaturedArticlesContainerSt } from './MarketplaceFeaturedArticles.css';

const MarketplaceFeaturedArticlesCmp = ({
  featuredArticles,
  getArticlesFromStore,
}) => {
  let featuredArticlesList = [];
  if (Array.isArray(featuredArticles) && featuredArticles.length > 0) {
    featuredArticlesList = featuredArticles.slice(0, 3);
  }

  return featuredArticlesList.length > 0 ? (
    <MarketplaceFeaturedArticlesContainerSt>
      <Heading4
        as="h2"
        mb={[24, 24, 32]}
        fontSize={[24, 28, 32]}
        lineHeight={[1.67, 1.5]}
        fontWeight={800}
      >
        heyMagazin
      </Heading4>

      <ThreeArticlesLayoutSt>
        {featuredArticles.slice(0, 3).map((article) => (
          <ArticlePreview
            key={uuid()}
            entry={article}
            getArticlesFromStore={getArticlesFromStore}
            articleType="featuredArticles"
          />
        ))}
      </ThreeArticlesLayoutSt>
      <ArticleButton
        buttonSlug="/magazine"
        buttonText="Mehr Artikel entdecken"
      />
    </MarketplaceFeaturedArticlesContainerSt>
  ) : null;
};

export const MarketplaceFeaturedArticles = memo(MarketplaceFeaturedArticlesCmp);

MarketplaceFeaturedArticlesCmp.propTypes = {
  featuredArticles: PropTypes.array,
  getArticlesFromStore: PropTypes.func.isRequired,
};

MarketplaceFeaturedArticlesCmp.defaultProps = {
  featuredArticles: [],
};

import { TOGGLE_WIZARDPOPUP, SET_SHOW_FIXED_BUTTON } from './constants';
import { reducer as calculatorReducer } from './CalculatorV3/redux/reducer';

export const defaultState = {
  loading: true,
  isChanged: false,
  selectedOption: '',
  error: false,
  errorMsg: null,
  calculations: [],
  showFixedButton: false,
  isOpenWizardPopup: false,
  disclaimers: [],
  requestId: '',
  result: {},
  specificData: {},
  sections: [],
  isResetToPrecalc: false,
};

export function storeReducer(state = defaultState, action = {}) {
  switch (action.type) {
    /**
     * opens/closes FDLWizard (home page's financing wizard)
     */
    case TOGGLE_WIZARDPOPUP:
      return { ...state, isOpenWizardPopup: !state.isOpenWizardPopup };

    /**
     * on mobile devices, updates context of CTA buttons at the
     * screen's bottom when over the financing section of PDP
     */
    case SET_SHOW_FIXED_BUTTON:
      return { ...state, showFixedButton: action.showFixedButton };
    default:
      return calculatorReducer(state, action);
  }
}

export const reducer = (state = defaultState, action = {}) =>
  storeReducer(state, action);

import styled from 'styled-components';
import { color } from '../../../../shared/styles_js/variables';

export const Title = styled.h3`
  color: ${props =>
    props.themeName === 'white' || props.themeName === 'grayLightest'
      ? color.brandSecondary
      : color.white};
  font-weight: 500;
  font-size: 16px;
  margin: 0 0 24px;
`;

export const ListContainer = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;
`;

export const ListItem = styled.li`
  padding: 0;
  margin: 0;
  margin-bottom: 16px;
`;

export const Container = styled.div`
  background-color: ${props => color[props.themeName]};
`;

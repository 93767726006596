import { useEffect, useState } from 'react';

import { TAG_ID, RECAPTCHA_SITE_TOKEN_V3 } from '../constants';

/**
 * @description Appends Google Recaptcha script tag to page body
 * @param {Boolean} [loadScriptOnMount=true] determines if recaptcha loads on hook mount
 * @param {string} [defaultAction=''] default action name to be used when fetching tokens
 * @returns {Array}
 */
export const useRecaptcha = (loadScriptOnMount = true, defaultAction = '') => {
  const [isLoading, setIsLoading] = useState(loadScriptOnMount);

  const checkLoadingStatus = () => {
    window.grecaptcha.ready(() => setIsLoading(false));
  };

  const getToken = (action = defaultAction) =>
    window.grecaptcha.execute(RECAPTCHA_SITE_TOKEN_V3, {
      action,
    });

  const loadScript = () =>
    new Promise(resolve => {
      const onScriptLoad = () => {
        checkLoadingStatus();
        resolve();
      };

      if (
        window.document.querySelector(`#${TAG_ID}`) ||
        typeof window.grecaptcha !== 'undefined'
      ) {
        onScriptLoad(resolve);
        return;
      }

      setIsLoading(true);

      const script = document.createElement('script');
      script.type = 'text/javascript';
      script.src = `https://www.google.com/recaptcha/api.js?render=${RECAPTCHA_SITE_TOKEN_V3}`;
      script.id = TAG_ID;
      script.async = true;
      script.defer = true;

      window.document.body.appendChild(script);
      script.addEventListener('load', onScriptLoad);

      // eslint-disable-next-line consistent-return
      return /* istanbul ignore next */ () =>
        script.removeEventListener('load', onScriptLoad);
    });

  useEffect(() => {
    if (!loadScriptOnMount) return;
    loadScript();
  }, []);

  return [isLoading, getToken, loadScript];
};

import { IGAMConfig } from 'app/shared/modules/Campaign/GAM/GamBanner/GamBanner.types';

const dimensions = { width: 300, height: 250 };

export const bannerSizes = {
  sm: [{ ...dimensions }],
  md: [{ ...dimensions }],
  lg: [{ ...dimensions }],
  xl: [{ ...dimensions }],
};

export const bannerArea = {
  sm: { ...dimensions },
  md: { ...dimensions },
  lg: { ...dimensions },
  xl: { ...dimensions },
};

export const CLP_FILTER_MEDIUM_RECTANGLE_BANNER: IGAMConfig = {
  adUnitId: 'div-clp-filter-medium-rectangle-banner',
  adUnitPath: '/22376835783/clp-filter-medium-rectangle-banner',
  sizes: bannerSizes,
  bannerArea,
  trackingData: {
    clickEvent: {
      eventName: 'clp_filter_medium_rectangle_banner_clicked',
      placement: 'CLP',
      category: 'CLP',
      label: 'CLP filter medium rectangle banner clicked',
    },
    impressionEvent: {
      eventName: 'clp_filter_medium_rectangle_banner_loaded',
      category: 'CLP',
      label: 'CLP filter medium rectangle banner served',
    },
  },
};

import styled, { css } from 'styled-components';
import { Heading4, Heading5, Button, ContainerSt } from 'app/shared/ui';

import { LazyImage } from 'app/shared/components/LazyImage/LazyImage';

import { media, zIndex } from '../../../shared/styles_js/variables';

export const GuaranteeBenefitsSt = styled(ContainerSt)`
  margin-top: 40px;

  ${media.md(
    css`
      margin-top: 72px;
    `,
  )}
  ${media.lg(
    css`
      margin-top: 80px;
    `,
  )}
  ${media.xl(
    css`
      margin-top: 72px;
    `,
  )}
`;

export const ContentSt = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 48px;
`;

export const ImageSectionSt = styled.div`
  width: 100%
    ${media.md(
      css`
        width: 50%;
      `,
    )};
  ${media.lg(
    css`
      width: 60%;
    `,
  )};
  ${media.xl(
    css`
      width: ${(100 * 2) / 3}%;
    `,
  )};
`;

export const ImageSt = styled(LazyImage)`
  width: calc(100% + 48px);
  max-width: none;
  height: auto;
  margin-right: -24px;
  margin-bottom: 32px;
  margin-left: -24px;

  ${media.lg(
    css`
      margin-bottom: 0;
    `,
  )};
`;

export const BenefitsSectionSt = styled.div`
  width: 100%;
  ${media.md(
    css`
      width: 50%;
    `,
  )};
  ${media.lg(
    css`
      width: 40%;
    `,
  )};
  ${media.xl(
    css`
      width: ${100 / 3}%;
    `,
  )};
`;

export const TitleSt = styled(Heading4).attrs(
  /* istanbul ignore next */ () => ({
    fontSize: [24, 28, 32],
    lineHeight: [1.67, 1.5],
    fontWeight: 800,
    mt: [40],
    mb: [24, null, null, 32],
  }),
)`
  z-index: ${zIndex.homeBenefitsTitle};
`;

/* TODO: Make use of Button cleaner, in this case ButtonSt can be replaced by Text1 */
export const GuaranteeInfoButtonSt = styled(Button).attrs({
  variant: 'secondary',
})`
  width: 100%;
  margin-top: 16px;
`;

export const Heading5St = styled(Heading5).attrs(({ theme }) => ({
  color: theme.colors.tarmacGrey,
}))`
  sup {
    top: -0.7em;
    left: 0.2em;
  }
`;

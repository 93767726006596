import { createSelector } from 'reselect';

import { getNormalizedVehicle } from 'app/shared/utils/vehicle';

// #region redux state selectors
/**
 * ----------------------------------------
 * @description redux state selectors
 * ----------------------------------------
 */
export const getDealerProfileState = ({ dealerProfile }) => dealerProfile;

/**
 * @function selectDealerProfileLoading
 * @returns {boolean}
 */
export const selectDealerProfileLoading = createSelector(
  [getDealerProfileState],
  ({ loading }) => loading,
);

/**
 * @function selectDealerProfileHttpError
 * @returns {any}
 */
export const selectDealerProfileHttpError = createSelector(
  [getDealerProfileState],
  ({ httpError }) => httpError,
);

/**
 * @function selectDealerProfileData
 * @returns {import('./reducer').ProfileData}
 */
export const selectDealerProfileData = createSelector(
  [getDealerProfileState],
  ({ profileData }) => profileData,
);

export const selectDealerProfileDataIsReady = createSelector(
  [selectDealerProfileData],
  (data = {}) => Object.keys(data).length > 0,
);

// #endregion redux state selectors

// #region dealer profile data getters

/**
 * ----------------------------------------
 * @description dealer profile data getters
 * ----------------------------------------
 */

/**
 * @constant getDealerProfileData
 * @param {Object} param
 * @param {Object[]} [param.content] - array of vehicles
 * @param {number} [param.totalElements] - numbers of vehicles in api result
 *
 * @returns {import('./reducer').ProfileData}
 */
export const getDealerProfileData = ({ content, totalElements } = {}) => {
  let dealer;
  let mobileId;
  let vehicles;
  if (content?.length > 0) {
    // 1st element in content property of search api response
    // has all necessary dealer information data
    [{ dealer, mobileId }] = content;
    // content property of search api response
    // is an array of vehicles
    vehicles = content.map(getNormalizedVehicle);
  }

  return {
    dealer,
    mobileId: mobileId?.toLowerCase(),
    vehicles,
    totalElements,
  };
};

/**
 * @function getDealerProfileOpeningTimes
 * @param {Object} dealer
 */
export const getDealerProfileOpeningTimes = (dealer = {}) => {
  const { openingTimes = [] } = dealer;
  const weekday = openingTimes.find(({ dayOfWeek }) => dayOfWeek === 1);
  const saturday = openingTimes.find(({ dayOfWeek }) => dayOfWeek === 6);
  const sunday = openingTimes.find(({ dayOfWeek }) => dayOfWeek === 7);
  return {
    weekdayFrom: weekday?.openFrom,
    weekdayTo: weekday?.openTo,
    saturdayFrom: saturday?.openFrom,
    saturdayTo: saturday?.openTo,
    sundayFrom: sunday?.openFrom,
    sundayTo: sunday?.openTo,
  };
};

/**
 * @description
 * Takes `profileUrlSlug` and gets string after the last hyphen
 * this is guarenteed to be the shortId for a valid dealer profile url
 *
 * @param {string} profileUrlSlug
 * @returns {string}
 */
export const getShortId = (profileUrlSlug = '') =>
  profileUrlSlug.substring(profileUrlSlug.lastIndexOf('-')).replace(/^-/, '');

// #endregion dealer profile data getters

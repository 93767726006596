import {
  put,
  all,
  fork,
  select,
  debounce,
  takeLatest,
} from 'redux-saga/effects';
import { LOCATION_CHANGE } from 'react-router-redux';

import {
  getFilterObject,
  getFiltersFromUri,
  getQueryFromFilters,
} from 'app/marketplace/filter/utils';
import {
  changeUrl,
  getVehicles,
  updateLocation,
  changeUrlfromLandingPage,
  getD2CVehicles,
} from 'app/marketplace/search/actions';
import {
  SET_FILTERS,
  RESET_FILTERS,
  UPDATE_FILTERS,
  OVERRIDE_FILTERS,
  RESET_SINGLE_FILTER,
  RESET_FILTER_CATEGORY,
  UPDATE_MULTITRIM_FILTER,
  UPDATE_MULTIMODEL_FILTER,
  RESET_FILTERS_EXCEPT_CATEGORY,
  RESET_MAKEMODELVARIANTE_FILTER,
} from 'app/marketplace/filter/constants';
import { PAGINATION_SIZE } from 'app/config';
import { getSEOData } from 'app/marketplace/seo/actions';
import { getFilterStateSelector } from 'app/marketplace/filter/selectors';
import { overrideFilters, setFilters } from 'app/marketplace/filter/actions';
import { RESET_CURRENT_LOCATION } from 'app/marketplace/geolocation/constants';
import { resetLocation as resetGeoLocation } from 'app/marketplace/geolocation/actions';

/**
 * All filter actions that should trigger an update of the url
 * @type {*[]}
 */
const filterActionsToWatch = [
  SET_FILTERS,
  RESET_FILTERS,
  UPDATE_FILTERS,
  OVERRIDE_FILTERS,
  RESET_SINGLE_FILTER,
  RESET_FILTER_CATEGORY,
  RESET_CURRENT_LOCATION,
  UPDATE_MULTITRIM_FILTER,
  UPDATE_MULTIMODEL_FILTER,
  RESET_FILTERS_EXCEPT_CATEGORY,
  RESET_MAKEMODELVARIANTE_FILTER,
];

let previousLocation = {};
let isOnMobileFilters = false;

let switcher = true;
let isFirstTimeLandingOnQuickClp = false;

/**
 * The handler for every location(url) change happening on the CLP
 * @param pathname - the pathname part of the url
 * @param query - the query in the url
 * @param search - the search part of the query
 * @return {IterableIterator<PutEffect<*> | *>}
 */
export function* handleLocationChange({
  payload: { pathname, query, search },
}) {
  const splitPathName = pathname.substr(1).split('/');
  const [base, make, model] = [...splitPathName];

  const params = {
    make,
    model,
  };
  const activeFilters = yield select(getFilterStateSelector);
  const previousRouterLocation = yield select(
    (state) => state.routing.locationBeforeTransitions,
  );

  // only react on CLP
  if (base === 'gebrauchtwagen') {
    /*
      if we have a make that is missing its corresponding `{make}_all` model (e.g. user removed it
      manually from the URL), then we need to recover it from the Filters and include it on the URL
    */
    const hasOnlyMake = query.make && !query.model;
    /*
      if we are on the CLP and the page was rendered on the server, then we already have the vehicles and seo data
      so we don't need to load it again
     */
    if (window._WAS_RENDERED_ON_SERVER_ && !hasOnlyMake) {
      return;
    }

    // when we are coming from mobile Filters we have to make sure we override the Filters
    if (isOnMobileFilters) {
      yield put(
        overrideFilters(
          getFiltersFromUri(params, query, {
            make: activeFilters.possibleMakes,
            model: activeFilters.possibleModels,
            usedCarSeal: activeFilters.allUsedCarSeal,
          }),
        ),
      );
      isOnMobileFilters = false;
    }
    if (
      previousLocation.search !== search ||
      previousRouterLocation.path !== '/gebrauchtwagen'
    ) {
      const filterQuery = yield `?${getQueryFromFilters(activeFilters, false)}`;
      if (search !== filterQuery) {
        /*
        this will trigger another cycle this can happen, when Filters are set and the user lands on the CLP for
        the first time.
        TODO is there a better way to trigger update of the Filters from the url?
          */
        yield put(updateLocation(activeFilters, { query }, params));
        // Force resetting geoLocation when the location filter was active, but it's not anymore
        if (
          filterQuery.includes('lat') &&
          filterQuery.includes('lon') &&
          !search.includes('lat') &&
          !search.includes('lon')
        ) {
          yield put(resetGeoLocation());
        }
        switcher = false;
      } else {
        // if the url changed within the CLP itself OR if we just land on the CLP then load the vehicles
        yield put(getSEOData());
        yield put(getVehicles(PAGINATION_SIZE));
        yield put(getD2CVehicles());
      }
    }
    previousLocation = { pathname, query, search };
  }
  if (base !== 'landing') {
    isFirstTimeLandingOnQuickClp = true;
  }
}

/**
 * watches url changes and stores when we land on the mobile Filters page
 * @param pathname
 * @return {IterableIterator<boolean>}
 */
export function* handleReturnFromMobileFilters({ payload: { pathname } }) {
  if (pathname && pathname.includes('/mobileFilter')) {
    yield (isOnMobileFilters = true);
  }
}

/**
 * currently this only handles one functionality which is: update the url when a filter action occurs
 * @return {IterableIterator<*|*>}
 */
export function* handleFilterChanges(action) {
  // some singular filter changes we want to ignore
  const filtersToIgnore = ['q'];
  if (filtersToIgnore.includes(action?.payload?.category)) {
    return;
  }
  /*
    we only want to actually update the url, when we have already entered the CLP
   */

  const { pathname } = yield select((state) =>
    state.routing.locationBeforeTransitions
      ? state.routing.locationBeforeTransitions
      : {},
  );
  const { breakpoint } = yield select((state) => state.globalEvents);

  const splitPathName = pathname.substr(1).split('/');
  const [base, make, model] = [...splitPathName];
  const params = {
    make,
    model,
    base,
  };
  if (breakpoint !== 'sm' && base === 'landing') {
    yield put(changeUrlfromLandingPage('/gebrauchtwagen'));
    isFirstTimeLandingOnQuickClp = false;
  }
  if (base === 'landing' && breakpoint === 'sm') {
    if (!isFirstTimeLandingOnQuickClp) {
      yield put(changeUrlfromLandingPage('/gebrauchtwagen'));
    }
    isFirstTimeLandingOnQuickClp = false;
  }

  if (
    base === 'gebrauchtwagen' &&
    (make !== undefined || model !== undefined) &&
    !switcher
  ) {
    yield put(getSEOData());
    yield put(getVehicles(PAGINATION_SIZE));
    yield put(getD2CVehicles());
    switcher = true;
  } else if (
    pathname &&
    pathname.includes('/gebrauchtwagen') &&
    (!pathname.includes('/auto') || pathname.includes('/auto-online-kaufen'))
  ) {
    if (
      pathname.includes('/auto-online-kaufen') &&
      action.type === OVERRIDE_FILTERS
    ) {
      return;
    }
    yield put(changeUrl('/gebrauchtwagen'));
    switcher = false;
  }
}

/**
 * We listen to all actions that change the Filters and in turn should change the url of the CLP
 * @return {IterableIterator<ForkEffect | *>}
 */
export function* searchPageUrlUpdater() {
  yield debounce(20, filterActionsToWatch, handleFilterChanges);
}

export function* searchPageLandingUpdater() {
  yield debounce(20, LOCATION_CHANGE, handleReturnFromMobileFilters);
}

/**
 * When the LOCATION_CHANGE action is triggered we want to act on it (sometimes)
 * @return {IterableIterator<ForkEffect>}
 */
export function* searchVehicleLoadCycle() {
  yield takeLatest(LOCATION_CHANGE, handleLocationChange);
}

/**
 * When the location filter is removed, and currently is sorted by distance then we need to reset that sort by filter
 * @return {IterableIterator<SimpleEffect<"PUT", PutEffectDescriptor<{payload: *, type: *}>>|SimpleEffect<"SELECT", SelectEffectDescriptor>|SimpleEffect<"TAKE", TakeEffectDescriptor>>}
 */
export function* removeSort() {
  const { sortBy } = yield select(getFilterStateSelector);
  if (sortBy.length > 0 && sortBy[0].value === 'distance') {
    yield put(setFilters(getFilterObject({ category: 'sortBy', value: null })));
  }
}
export function* handleGeoLocationChange() {
  yield takeLatest(RESET_CURRENT_LOCATION, removeSort);
}
/**
 * Handling the triggering of multiple sagas
 * @return {IterableIterator<AllEffect<SimpleEffect<"FORK", ForkEffectDescriptor>> | AllEffect<any>>}
 */
export function* searchSagas() {
  yield all([
    fork(searchVehicleLoadCycle),
    fork(searchPageUrlUpdater),
    fork(searchPageLandingUpdater),
    fork(handleGeoLocationChange),
  ]);
}

import { CAPTIVES, FINANCING_OPTIONS } from 'app/shared/modules/FDL/constants';

export const getHumanReadableTextOfFinancingProduct = (label) => {
  switch (label) {
    case FINANCING_OPTIONS.PRIVATE_LEASING:
      return 'Privat-Leasing';
    case FINANCING_OPTIONS.TWO_WAY_FINANCING:
      return 'Finanzierung mit Schlussrate';
    case FINANCING_OPTIONS.THREE_WAY_FINANCING:
      return '3-Wege-Finanzierung';
    case FINANCING_OPTIONS.STANDARD_FINANCING:
      return 'Standardfinanzierung';
    default:
      return label;
  }
};

export const getHumanReadableTextOfCaptive = (val) => {
  if (CAPTIVES.VOLKSWAGEN.includes(val)) {
    return 'Volkswagen Bank';
  }
  if (CAPTIVES.BMW.includes(val)) {
    return 'BMW Bank';
  }
  if (CAPTIVES.MERCEDES.includes(val)) {
    return 'Mercedes-Benz Bank';
  }
  if (CAPTIVES.PORSCHE.includes(val)) {
    return 'Porsche Bank';
  }
  if (CAPTIVES.RENAULT.includes(val)) {
    return 'Mobilize Financial Services';
  }
  if (CAPTIVES.NISSAN.includes(val)) {
    return 'Nissan Financial Services';
  }
  return val;
};

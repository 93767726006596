/* eslint-disable @typescript-eslint/restrict-plus-operands */
import styled, { css } from 'styled-components';
import { Link } from 'app/shared/ui';

import { media } from 'app/shared/styles_js/variables';
import { TooltipSt } from 'app/marketplace/home/Makes/Makes.css';

interface ITileContainerSt {
  position: number;
  tileNumber: number;
}
export const tileDimension = {
  width: 80,
  marginRight: {
    sm: 16,
    md: 24,
    lg: 24,
    xl: 24,
  },
  row: {
    sm: 3,
    md: 3,
    lg: 3,
    xl: 3,
  },
  col: {
    // columns visible at the same time in the viewport
    sm: 3,
    md: 4,
    lg: 5,
    xl: 7,
  },
};

export const calculateTileContainerWidth = (
  tileNumber: number,
  bp: string,
): number => {
  const { row, width, marginRight: margin } = tileDimension;
  const result = Math.ceil(tileNumber / row[bp]) * (width + margin[bp]);
  return result - margin[bp];
};

export const getSlideWidth = (bp: string): number => {
  const { col, width, marginRight: margin } = tileDimension;
  return col[bp] * (width + margin[bp]);
};

export const TileContainerSt = styled.div<ITileContainerSt>`
  position: relative;
  display: inline-flex;
  flex-flow: column wrap;
  transform: translate3d(${({ position }) => position}px, 0, 0);
  transition: transform 0.4s;
  height: 348px;
  width: ${({ tileNumber }) => calculateTileContainerWidth(tileNumber, 'sm')}px;

  ${media.md(
    css<ITileContainerSt>`
      height: 388px;
      width: ${({ tileNumber }) =>
        calculateTileContainerWidth(tileNumber, 'md')}px;
    `,
  )}

  ${media.xl(css<ITileContainerSt>`
    transition-duration: 0.8s;
    height: 400px;
    width: ${({ tileNumber }) =>
      calculateTileContainerWidth(tileNumber, 'xl')}px;
  `)}
`;

export const TileSt = styled(Link)`
  position: relative;
  border: 1px solid ${({ theme: { colors } }) => colors.grayLightest};
  transition-property: box-shadow, border-color;
  transition-duration: 200ms;
  transition-timing-function: ease-in-out;
  width: ${tileDimension.width}px;
  height: ${tileDimension.width}px;
  margin: 0 ${tileDimension.marginRight.sm}px 32px 0;

  ${media.md(css`
    margin-right: ${tileDimension.marginRight.md}px;
  `)}

  &:hover,
  &:active,
  &:focus {
    outline: none;
    border-color: ${({ theme: { colors } }) => colors.actionBlue};
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
  }

  &:hover > ${TooltipSt} {
    ${media.xl(css`
      display: block;
    `)}
  }
`;

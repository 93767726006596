import 'isomorphic-fetch';
import Cookies from 'js-cookie';

import { getStore } from '../../../store';

import {
  cleanPageProperties,
  convertFlatObjectToTagValues,
  createGUID,
  getCookiesContainingKey,
} from './utils';
import {
  selligentEventNameWhiteList,
  selligentAllowedTags,
} from './trackingConfig';

let identifyInterval = null;
let pageStack = [];
let actionStack = [];

// const isSelligentSiteModuleAvailable = () => {
//   return (
//     typeof window !== 'undefined' &&
//     window.wa &&
//     window.wa.bt_queue &&
//     /* eslint-disable */
//     window.wa.hasOwnProperty('btPreviewMode')
//     /* eslint-enable */
//   );
// };

const isAnalyticsAvailable = () => {
  /*
  we have a hard dependency here between selligent and segment to prevent having to build a convoluted solution
  for tracking all page events. downside is: if selligent cannot be loaded, then there will be no segment tracking
  either
  */

  return (
    typeof window !== 'undefined' && window?.analytics
    // && isSelligentSiteModuleAvailable()
  );
};

/**
 * @param {IUserData} userFormData
 * @returns {{firstName, lastName, phone, email}|null}
 */
function generateUserTraits(userFormData) {
  return userFormData
    ? {
        firstName: userFormData.firstName,
        lastName: userFormData.lastName,
        email: userFormData.email,
        phone: userFormData.phone,
      }
    : null;
}

export const setTrackingVersion = () => {
  if (isAnalyticsAvailable()) {
    window?.analytics.ready(() => {
      window?.ga('set', 'appName', 'heycar');
      window?.ga('set', 'appVersion', '1.0.0');
    });
  }
};

export const getAdditionalVehicleTrackingParams = (vehicle) => {
  return {
    content_ids: [vehicle.id],
    products: [
      {
        product_id: vehicle.id,
        price: `${vehicle.price}`,
        value: `${vehicle.price}`,
        quantity: '1',
      },
    ],
    content_type: 'vehicle',
    makeId: `${vehicle.make.id}`,
    modelId: `${vehicle.model.id}`,
    vehicleCategory: `${vehicle.categories.join(', ')}`,
    category: `${vehicle.categories.join(', ')}`,
    price: `${vehicle.price}`,
    value: `${vehicle.price}`,
    listingId: `${vehicle.id}`,
    dealerId: `${vehicle.dealer.id}`,
  };
};

/**
 * Base for tracking of events that should go to selligent
 * @param {Object} options - the options object
 * @param {Object[]} options.tagValues - key/value pairs that should be sent
 * @param {Boolean} (options.isEvent) - is this an event or a page call?
 * @returns {void};
 */
export const trackSelligent = (options) => {
  if (!CLIENT || !PRODUCTION) {
    return;
  }

  const normalizedOptions = {
    customIdentifier: options.customIdentifier || window?.HEYCAR_USERID,
    tagValues: options.tagValues || [],
    isEvent: typeof options.isEvent === 'undefined' ? true : options.isEvent,
  };

  if (window && window?.wa && window?.wa.bt_queue) {
    window?.wa.bt_queue.push(normalizedOptions);
  }
};

export const trackSelligentEvent = (actionName, properties) => {
  const eventAllowed =
    Object.keys(selligentEventNameWhiteList).filter(
      (name) => actionName === name,
    ).length > 0;

  if (eventAllowed) {
    const tagValues = convertFlatObjectToTagValues(properties)
      .filter((tagObject) =>
        selligentAllowedTags.find((tagName) => tagObject.tag === tagName),
      ) // filter empty
      .filter((tagObject) => tagObject.value !== null);

    tagValues.push({
      tag: 'eventName',
      value: actionName,
    });

    trackSelligent({ tagValues });
  }
};

export const trackSelligentPage = (pageProperties) => {
  const cleanedPageProperties = cleanPageProperties(pageProperties);

  trackSelligent({
    isEvent: false,
    tagValues: convertFlatObjectToTagValues(cleanedPageProperties),
  });
};

export const trackPage = (change, page) => {
  const date = new Date();
  const guid = createGUID();
  const userAccount =
    getStore().getState().authMarketplace &&
    getStore().getState().authMarketplace.user;
  const userAccountId = (userAccount && userAccount.sub) || null;
  const pageProperties = page || {
    url: window?.location.href,
    path: window?.location.pathname,
    search: window?.location.search,
    title: document.title,
    referrer: document.referrer,
    hitTimestamp: date.getTime(),
    eventId: guid,
    sessionId: guid,
    userAccountId,
  };
  if (isAnalyticsAvailable() && pageStack.length === 0) {
    // trackSelligentPage(pageProperties);
    // segment tracking
    window?.analytics.page('', pageProperties);
  } else if (CLIENT && PRODUCTION) {
    pageStack.push(pageProperties);
  }
};

export const normalizeValue = (value) => {
  if (typeof value === 'number') {
    return value;
  }
  if (!value) {
    return null;
  }
  return value;
};

const settingsType = {
  essential: 'essential',
  functional: 'functional',
  analytical: 'analytical',
  marketing: 'marketing',
};

const cookieTypes = Object.keys(settingsType).filter(
  (setting) => setting !== settingsType.essential,
);

const defaultCookieConsentState = () => {
  let cookieDashboard = [];
  let cookieDashboardObj;
  let preferences = {};
  if (typeof document !== 'undefined') {
    cookieDashboard = getCookiesContainingKey(
      document.cookie,
      'persist%3AcookieDashboard',
    );
  }

  if (cookieDashboard.length > 0) {
    cookieDashboardObj = JSON.parse(
      decodeURIComponent(cookieDashboard[0]['persist%3AcookieDashboard']),
    );
  }

  if (cookieDashboardObj?.preferences) {
    preferences = JSON.parse(
      decodeURIComponent(cookieDashboardObj.preferences),
    );

    return {
      preferences,
    };
  }

  const defaultState = cookieTypes.reduce(
    (obj, key) => Object.assign(obj, { [key]: false }),
    {},
  );

  return {
    preferences: { ...defaultState, essential: true },
  };
};

/**
 * @description Tracking user/website interactions and push all information to tracking server
 * @export
 * @param {string} actionName Tracking Action Name
 * @param {object} [option={}] Optional Data - To logging data on client
 * @param {object} [additionalProperties={}] Additional Properties
 * @param {boolean} logTracker If true, log what was tracked
 * @param {IUserData} [userFormData=null] Contains the user information
 * @returns {void}
 */
export const trackAction = (
  actionName,
  option = {},
  additionalProperties = {},
  logTracker = false,
  userFormData = null,
) => {
  const date = new Date();
  const guid = createGUID();
  const { category, label, value } = option;
  const givenValue = normalizeValue(value);
  const userAccount =
    getStore()?.getState().authMarketplace &&
    getStore().getState().authMarketplace.user;
  const userAccountId = (userAccount && userAccount.sub) || null;

  const eventProperties = Object.assign(additionalProperties, {
    category,
    label: label || null,
    value: givenValue,
    hitTimestamp: date.getTime(),
    userAccountId,
    // allow the eventId to be overridden by additionalproperties - this is an exception
    eventId: additionalProperties.eventId ? additionalProperties.eventId : guid,
    sessionId: additionalProperties.sessionId
      ? additionalProperties.sessionId
      : guid,
    interactedWith: additionalProperties.interactedWith
      ? additionalProperties.interactedWith
      : null,
    email: window?.HEYCAR_USERID,
    fbp: Cookies.get('_fbp'),
    fbc: Cookies.get('_fbc'),
  });

  // trackSelligentEvent(actionName, eventProperties);

  const trackingData = {
    actionName,
    option,
    additionalProperties: {
      ...additionalProperties,
      hitTimestamp: date.getTime(),
      eventId: guid,
      sessionId: guid,
      userAccountId,
      email: window?.HEYCAR_USERID,
    },
  };

  const dataLayerAttributes = {
    event: actionName,
    ...trackingData.additionalProperties,
  };

  if (window?.navigator.userAgent.toLowerCase().indexOf('headless') !== -1) {
    // If using headless browsers, do not track the action events e.g. in e2e tests
    return;
  }

  // eslint-disable-next-line no-unused-expressions
  window?.dataLayer?.push(dataLayerAttributes);

  if (isAnalyticsAvailable() && actionStack.length === 0) {
    const cookieState = defaultCookieConsentState();
    const functionalCookieValue = cookieState?.preferences?.functional === true;
    const analyticalCookieValue = cookieState?.preferences?.analytical === true;
    const marketingCookieValue = cookieState?.preferences?.marketing === true;
    const userTraits = generateUserTraits(userFormData);

    // eslint-disable-next-line no-unused-expressions
    window?.analytics.track(actionName, eventProperties, {
      context: {
        ip: window?.anonIp,
        page: {
          url: window?.location.href,
          path: window?.location.pathname,
          search: window?.location.search,
          title: document.title,
          referrer: document.referrer,
        },
        ...(userTraits && { traits: userTraits }),
      },
      integrations: {
        AdWords: marketingCookieValue, // Google Ads (Classic)
        'Bing Ads': marketingCookieValue,
        Criteo: marketingCookieValue,
        'Facebook Conversions API (Actions)': marketingCookieValue,
        'Facebook Pixel': marketingCookieValue,
        Firebase: analyticalCookieValue,
        'Google AdWords New': marketingCookieValue, // Google Ads (Gtag)
        'Google Analytics': analyticalCookieValue, // aka Google Universal Analytics
        'Google Ads Remarketing Lists': marketingCookieValue,
        'Google Tag Manager': functionalCookieValue,
        Hotjar: analyticalCookieValue,
        HubSpot: marketingCookieValue,
        Mixpanel: analyticalCookieValue,
      },
    });
  } else if (identifyInterval && CLIENT && PRODUCTION) {
    // If it's on prod, push tracking data to server
    actionStack.push(trackingData);
  } else if (DEVELOPMENT && logTracker) {
    // if it's on dev and logTracker option is enabled, trace the data
    console.trace(trackingData); // eslint-disable-line no-console
  }
};

export const trackTimeToInteraction = () => {
  if (CLIENT && PRODUCTION) {
    /*
     There are two conditions here, that I could not test to full coverage, because jest in our setup uses jsdom as
     the default environment which has an unoverrideable 'window?.performance' object. this means the first line of each
     of these two conditions cannot be covered. Changing the environment to the 'node' environment does throw so many
     errors that I don't know where to start.
      */
    // eslint-disable-next-line no-mixed-operators
    /* istanbul ignore next */
    const domInteractive =
      (window?.performance &&
        window?.performance.timing &&
        window?.performance.timing.domInteractive) ||
      0;
    // eslint-disable-next-line no-mixed-operators
    /* istanbul ignore next */
    const requestStart =
      (window?.performance &&
        window?.performance.timing &&
        window?.performance.timing.requestStart) ||
      0;

    const tti = domInteractive - requestStart;
    const option = {
      category: 'performance',
      description: 'performance properties this user',
      label: 'time_to_interaction',
      value: tti || null,
    };

    trackAction('performance_tti', option);
  }
};

export const trackConnectionQuality = () => {
  if (CLIENT) {
    let connection =
      window && window?.navigator && window?.navigator.connection;

    if (!connection) {
      connection = {};
    }
    const option = {
      category: 'connection',
      description: 'connection properties of the user',
      label: `downlink:${connection.downlink || null};effectiveType:${
        connection.effectiveType || null
      };rtt:${connection.rtt || null}`,
      value: connection.downlink || 0,
    };

    trackAction('connection_performance', option);
  }
};

export const trackIdentify = (userId, email) => {
  if (isAnalyticsAvailable()) {
    const userAccount =
      getStore().getState().authMarketplace &&
      getStore().getState().authMarketplace.user;
    const userAccountId = (userAccount && userAccount.sub) || null;
    window?.analytics?.identify?.(userId, {
      trackingId: userId,
      userAccountId,
      email,
      language: 'DE_DE_X_INFORMAL',
    });

    // DANGEROUS SIDE EFFECT, used in selligent tracking
    window.HEYCAR_USERID = userId;
    if (identifyInterval) {
      const pageStackCopy = pageStack.slice();

      pageStack = [];
      pageStackCopy.map((page) => trackPage('', page));
      trackPage();
      const actionStackCopy = actionStack.slice();

      actionStack = [];
      actionStackCopy.map((action) =>
        trackAction(
          action.actionName,
          action.option,
          action.additionalProperties,
        ),
      );
      clearInterval(identifyInterval);
      identifyInterval = null;
    }
  } else if (!identifyInterval && CLIENT && PRODUCTION) {
    identifyInterval = setInterval(trackIdentify, 100, userId, email);
  }
};

export const getAnonymousSegmentID = () => {
  if (isAnalyticsAvailable()) {
    return window?.analytics?.user()?.anonymousId();
  }
  return null;
};

export const paginationTrackingParams = {
  event: 'pagination_click',
  description: 'user clicks pagination',
};

export const isHotjarAvailable = () => {
  return typeof window?.hj !== 'undefined';
};

export const triggerHotjarAction = ({
  triggerName,
  logTrigger = false,
  type = 'trigger',
}) => {
  if (DEVELOPMENT && logTrigger) {
    // eslint-disable-next-line no-console
    console.log(`triggerHotjarAction: ${triggerName}`);
  }
  if (isHotjarAvailable() && triggerName) {
    window?.hj(type, triggerName);
  }
};

import PropTypes from 'prop-types';

import { breakpointShape } from 'app/shared/utils/propTypes';

export const variantShape = PropTypes.shape({
  title: PropTypes.string,
  path: PropTypes.string,
  id: PropTypes.string,
});

export const modelShape = PropTypes.shape({
  title: PropTypes.string,
  path: PropTypes.string,
  id: PropTypes.string,
  variants: PropTypes.arrayOf(PropTypes.shape({ fields: variantShape })),
});

export const modelsShape = PropTypes.arrayOf(
  PropTypes.shape({ fields: modelShape }),
);

export const makeShape = PropTypes.shape({
  title: PropTypes.string,
  path: PropTypes.string,
  id: PropTypes.string,
  models: modelsShape,
});

export const makesShape = PropTypes.arrayOf(
  PropTypes.shape({
    fields: makeShape,
  }),
);

export const cardShape = PropTypes.oneOfType([modelShape, makeShape]);

export const ctaShape = PropTypes.shape({
  fields: PropTypes.shape({
    title: PropTypes.string.isRequired,
    isExternalLink: PropTypes.bool,
    to: PropTypes.string,
    passedUrl: PropTypes.string,
    trackingData: PropTypes.object,
    showNumber: PropTypes.bool,
    number: PropTypes.number,
    align: PropTypes.object,
    useEnteredLink: PropTypes.bool,
  }),
});

export const responsiveImageShape = PropTypes.shape({
  fields: PropTypes.shape({
    title: PropTypes.string,
    small: PropTypes.array,
    medium: PropTypes.array,
    large: PropTypes.array,
    xLarge: PropTypes.array,
    breakpoint: breakpointShape,
    isLazy: PropTypes.bool,
  }),
});

export const logoImageShape = PropTypes.shape({
  fields: PropTypes.shape({
    title: PropTypes.string,
    cardImage: responsiveImageShape,
    linkLogo: responsiveImageShape,
  }),
});

import { SaveSearchActionTypes } from 'app/marketplace/saveSearch/saveSearch.types';

import * as ActionTypes from './constants';

export const defaultState = () => {
  return {
    createdAt: null,
    isReturningUser: null,
    hasSignedUpForNewsletter: false,
    searchUserId: null,
    saveSearchConfirmed: false,
    hasSeenProfileInfo: false,
    isNotificationIndicatorVisible: false,
  };
};

export function user(state = defaultState(), action) {
  const { type, payload } = action;

  if (type === ActionTypes.SET_USER_CREATED) {
    return { ...state, createdAt: payload.timestamp };
  }

  if (type === ActionTypes.SET_USER_STATE) {
    return { ...state, isReturningUser: payload.isReturningUser };
  }

  if (type === ActionTypes.SET_SIGNED_UP_FOR_NEWSLETTER) {
    return { ...state, hasSignedUpForNewsletter: payload };
  }

  if (type === SaveSearchActionTypes.SAVE_SEARCH_POST_SEARCH_SUCCESS) {
    return {
      ...state,
      searchUserId: payload.searchUserProfile.id,
      saveSearchConfirmed: payload.searchUserProfile.confirmed,
    };
  }

  if (type === SaveSearchActionTypes.SAVE_SEARCH_FETCH_SEARCHES_SUCCESS) {
    return {
      ...state,
      searchUserId: payload[0] && payload[0].searchUserProfile.id,
      saveSearchConfirmed: payload[0] && payload[0].searchUserProfile.confirmed,
    };
  }

  if (type === ActionTypes.CLOSE_PROFILE_INFO) {
    return {
      ...state,
      hasSeenProfileInfo: true,
    };
  }

  if (type === ActionTypes.SHOW_NOTIFICATION_INDICATOR) {
    return {
      ...state,
      isNotificationIndicatorVisible: true,
    };
  }

  if (type === ActionTypes.HIDE_NOTIFICATION_INDICATOR) {
    return {
      ...state,
      isNotificationIndicatorVisible: false,
    };
  }

  /**
   * These have been moved to state.contactDealer (local storage reducer)
   */
  if (type === ActionTypes.REMOVE_LEAD_VEHICLE_LISTS) {
    const newState = { ...state };
    delete newState.contactedVehicleIds;
    delete newState.soldOutVehicleIds;

    return newState;
  }

  return state;
}

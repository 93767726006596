export const SET_CURRENT_LOCATION_PENDING = 'SET_CURRENT_LOCATION_PENDING';
export const SET_CURRENT_LOCATION_SUCCESS = 'SET_CURRENT_LOCATION_SUCCESS';
export const SET_CURRENT_LOCATION_ERROR = 'SET_CURRENT_LOCATION_ERROR';
export const RESET_CURRENT_LOCATION = 'RESET_CURRENT_LOCATION';
export const SET_GOOGLE_LOCATION_ID = 'SET_GOOGLE_LOCATION_ID';
export const GET_LOCATION_SUGGESTIONS_ERROR = 'GET_LOCATION_SUGGESTIONS_ERROR';
export const GET_LOCATION_SUGGESTIONS_SUCCESS =
  'GET_LOCATION_SUGGESTIONS_SUCCESS';
export const GET_LOCATION_SUGGESTIONS_PENDING =
  'GET_LOCATION_SUGGESTIONS_PENDING';
export const SET_USER_LOCATION_DENIED = 'SET_USER_LOCATION_DENIED';
export const SET_LOCATION_API_NOT_SUPPORTED = 'SET_LOCATION_API_NOT_SUPPORTED';

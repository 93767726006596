import { connect } from 'react-redux';

import {
  addModal,
  removeModal,
  triggerModal,
} from '../../../../marketplace/contentful/actions';
import { goToSearch, toggleWizardPopup } from '../actions';

import { FDLWizard } from './FDLWizard';

export function mapStateToProps(state = {}, ownProps = {}) {
  if (state === null) {
    state = {};
  }

  if (ownProps === null) {
    ownProps = {};
  }

  return {
    isOpen:
      ownProps.open ||
      (state.contentful &&
        ownProps.modalId &&
        state.contentful.modals[ownProps.modalId]) ||
      (state.fdlCalculator && state.fdlCalculator.isOpenWizardPopup) ||
      false,
  };
}

/**
 * @memberof index.js (Contentful index) / FDLBanner.js
 * @description Finanzierungsratgeber's (financing guide page) wizard
 * that guides the user through a group of questions and returns the
 * recommended financing type based on the given answers.
 */
export const ConnectedFDLWizard = connect(
  mapStateToProps,
  {
    addModal,
    removeModal,
    triggerModal,
    goToSearch,
    toggleWizardPopup,
  },
)(FDLWizard);

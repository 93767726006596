import styled, { css } from 'styled-components';
import { Heading4 } from 'app/shared/ui';

import { media, zIndex } from '../../../shared/styles_js/variables';

export const MessageBannerSt = styled.div`
  position: fixed;
  z-index: ${zIndex.navbar + 100};
  top: 0;
  display: flex;
  width: 100%;
  min-height: 200px;
  justify-content: center;
  background-color: ${({ theme: { colors } }) => colors.white};
  padding: 24px;
  color: ${({ theme: { colors } }) => colors.tarmacGrey};
`;

export const ContentContainerSt = styled.div`
  width: 90%;
  ${media.md(css`
    width: 80%;
  `)};
  ${media.lg(css`
    width: 60%;
  `)};
`;

export const ButtonContainerSt = styled.div`
  margin-top: 32px;
`;

export const MessagerContainerSt = styled(Heading4).attrs(() => ({
  mb: 24,
}))`
  color: ${({ theme: { colors } }) => colors.tarmacGrey};
`;

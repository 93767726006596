import styled from 'styled-components';
import { Box } from '@rebass/grid';

export const PageBreak = styled.div`
  @media print {
    clear: both;
    page-break-after: always;
  }
`;

export const DisclaimerBoxSt = styled(Box).attrs(() => ({
  as: 'section',
}))`
  font-size: 10px;
  line-height: 1.6;
  margin-top: 24px;
  margin-bottom: 24px;

  sup {
    color: ${({ theme: { colors } }) => colors.grayDark};
  }

  p {
    display: inline;

    &::after {
      content: ' ';
      white-space: pre;
      display: block;
    }
  }
`;

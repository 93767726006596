import {
  INSURANCE_GET,
  INSURANCE_GET_ERROR,
  INSURANCE_GET_SUCCESS,
  INSURANCE_PUT,
  INSURANCE_PUT_ERROR,
  INSURANCE_PUT_SUCCESS,
} from '../../constants';

export const initialState = {
  listingId: undefined,
  marketingConsent: undefined,
  magicLink: undefined,
  error: undefined,
  isLoading: false,
};

export function insurance(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case INSURANCE_GET:
      return { ...state, error: undefined, isLoading: true };
    case INSURANCE_GET_SUCCESS:
      return { ...state, ...payload, isLoading: false };
    case INSURANCE_GET_ERROR:
      return { ...state, error: payload, isLoading: false };
    case INSURANCE_PUT:
      return { ...state, error: undefined, isLoading: true };
    case INSURANCE_PUT_SUCCESS:
      return { ...state, marketingConsent: true, isLoading: false };
    case INSURANCE_PUT_ERROR:
      return { ...state, error: payload, isLoading: false };
    default:
      return state;
  }
}

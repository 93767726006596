import {
  ORDER_PATCH,
  ORDER_PATCH_SUCCESS,
  ORDER_PATCH_ERROR,
} from 'app/marketplace/vehicleCheckout/redux/constants';

export const initialState = {
  callbackTime: undefined,
};

export function callbackTime(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case ORDER_PATCH:
      return { ...state, isLoading: true, error: undefined };
    case ORDER_PATCH_SUCCESS:
      return { ...state, ...payload, isLoading: false };
    case ORDER_PATCH_ERROR:
      return {
        ...state,
        isLoading: false,
        error: { ...payload, isOrderError: true },
      };
    default:
      return state;
  }
}

import * as ActionTypes from './constants';

export const setBreakpoint = (breakpoint) => ({
  type: ActionTypes.SET_BREAKPOINT,
  payload: { breakpoint },
});

export const mouseExitScreenHandler = (direction = ActionTypes.TOP) => ({
  type: ActionTypes.MOUSE_EXIT_SCREEN,
  payload: direction,
});

export const setIsHiddenTopNavBar = (payload) => ({
  type: ActionTypes.TOGGLE_TOPNAVBAR,
  payload,
});

export const setScrollDirection = (payload) => ({
  type: ActionTypes.TOGGLE_SCROLL_DIRECTION,
  payload,
});

export const setIsTopNavbarStuck = (payload) => ({
  type: ActionTypes.TOGGLE_IS_STUCK,
  payload,
});

export const setIsAdBlockerDetected = (isAdBlockerDetected) => ({
  type: ActionTypes.SET_IS_AD_BLOCKER_DETECTED,
  payload: { isAdBlockerDetected },
});

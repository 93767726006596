import React from 'react';
import PropTypes from 'prop-types';

import { WrapperSt, CircleSt, SliderSt } from './ProgressIndicator.css';

export const ProgressIndicator = props => {
  const { className, slideCount, slidePosition } = props;
  const getTransform = () => {
    if (slideCount <= 5) {
      return 0;
    }
    if (slidePosition > slideCount - 5) {
      return 20 * (slideCount - 5);
    }
    if (slidePosition < 3) {
      return 0;
    }
    return (slidePosition - 2) * 20;
  };

  const getCircles = () => {
    return [...new Array(slideCount)].map((item, idx) => (
      <CircleSt
        active={slidePosition === idx}
        medium={slidePosition - 1 === idx || slidePosition + 1 === idx}
        key={`${idx}-i`}
      />
    ));
  };

  const progressCircles = getCircles();
  const transform = getTransform();
  const progressSliderStyle = {
    width: `${slideCount * 20}px`,
    transform: `translateX(${transform * -1}px)`,
  };

  const progressSlider = (
    <WrapperSt
      className={className}
      style={{ width: slideCount >= 5 ? '100px' : `${slideCount * 20}px` }}
    >
      <SliderSt style={progressSliderStyle}>{progressCircles}</SliderSt>
    </WrapperSt>
  );

  return slideCount ? progressSlider : null;
};

ProgressIndicator.propTypes = {
  className: PropTypes.string,
  slideCount: PropTypes.number,
  slidePosition: PropTypes.number,
};

ProgressIndicator.defaultProps = {
  className: '',
  slideCount: 0,
  slidePosition: 0,
};

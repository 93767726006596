/* eslint-disable react/jsx-props-no-spreading */
import { Text } from 'app/shared/ui';
import PropTypes from 'prop-types';
import ReactDOM from 'react-dom';

import { Overlay } from '../Overlay/Overlay';

import {
  IconCloseSt,
  ModalContentSt,
  LargeModalContentSt,
  ModalSt,
  ModalHeaderSt,
} from './Modal.css';

export const Modal = (props) => {
  const {
    open,
    onClose,
    children,
    largeModal,
    iconColor,
    iconSize,
    className,
    title,
  } = props;

  const modalRoot =
    document.querySelector('#modals') || document.createElement('div');

  const content = (
    <>
      <ModalHeaderSt
        justifyContent={['space-between', 'flex-end']}
        alignItems="center"
        px={[0, 25]}
        mt={[0, 25]}
        mb={[0, -40]}
      >
        <Text fontSize={[16]} fontWeight={[700]} id="modal-header-title">
          {title && title}
        </Text>
        <IconCloseSt
          className="if-icon-close"
          onClick={onClose}
          color={iconColor}
          iconSize={iconSize}
        />
      </ModalHeaderSt>
      {children}
    </>
  );

  const modal = (
    <ModalSt isOpen={open} className={className}>
      <Overlay show={open} onClose={onClose} />

      {largeModal ? (
        <LargeModalContentSt {...props}>{content}</LargeModalContentSt>
      ) : (
        <ModalContentSt {...props}>{content}</ModalContentSt>
      )}
    </ModalSt>
  );

  return CLIENT ? ReactDOM.createPortal(modal, modalRoot) : null;
};

Modal.propTypes = {
  open: PropTypes.bool,
  narrow: PropTypes.bool,
  children: PropTypes.any,
  onClose: PropTypes.func,
  sizeMd: PropTypes.string,
  sizeXl: PropTypes.string,
  className: PropTypes.string,
  height: PropTypes.string,
  width: PropTypes.string,
  padding: PropTypes.string,
  largeModal: PropTypes.bool,
  iconColor: PropTypes.string,
  iconSize: PropTypes.number,
  title: PropTypes.string,
};

Modal.defaultProps = {
  open: false,
  narrow: false,
  onClose: () => {},
  sizeMd: '464px',
  sizeXl: '568px',
  iconSize: 16,
};

/* eslint-disable no-unused-expressions, react/jsx-props-no-spreading, no-underscore-dangle */
import 'regenerator-runtime/runtime';
import 'raf/polyfill';
import entries from 'object.entries';
import { hydrate } from 'react-dom';
import { Provider } from 'react-redux';
import {
  applyRouterMiddleware,
  browserHistory,
  match,
  Router,
} from 'react-router';
import { syncHistoryWithStore } from 'react-router-redux';
import routerUseScroll from 'react-router-scroll/lib/useScroll';
import CookieStorage from 'redux-persist-cookie-storage';
import * as OfflinePluginRuntime from 'offline-plugin/runtime';

import { SERVICE_WORKERS_ENABLED } from 'app/config';
import { getRoutes } from 'app/routes';
import { initiateSentrySDK } from 'client/initiateSentrySDK';
import { isExternalPathname } from 'app/shared/utils/isExternalPathname';
import { localStorageReducers } from 'app/local-reducers';
import { localStore, sessionStore } from 'app/shared/utils/storageFactory';
import { recursiveParse } from 'app/shared/utils/utils';
import { trackPage } from 'app/shared/utils/tracking';

import { configureStore } from '../config';

// Sentry
initiateSentrySDK();

let clpScrollPosition;
let shouldclpScrollPositionUpdate = true;
// Install service workers
if (SERVICE_WORKERS_ENABLED) {
  OfflinePluginRuntime.install();
}

// defines Object.entries if it is not available
if (!Object.entries) {
  entries.shim();
}

const mergeLocalStateIntoPreloadedState = (preloadedState) => {
  /*
   * We need to do this because some users have cookies disabled which causes the
   * browser to disallow access to localstorage
   */
  if (!localStore.isSupported()) {
    return preloadedState;
  }

  Object.keys(localStorageReducers).forEach((key) => {
    const preloadedItem = preloadedState[key];

    if (preloadedItem) {
      const localStorageItem = recursiveParse(
        window.localStorage.getItem(`persist:${key}`),
      );

      // eslint-disable-next-line no-param-reassign
      preloadedState[key] = Object.assign(preloadedItem, localStorageItem);
    }
  });
  return preloadedState;
};

const preloadedState = mergeLocalStateIntoPreloadedState(
  // eslint-disable-next-line no-underscore-dangle
  window._PRELOADED_STATE__,
);

const { pathname, search } = window?.location;
const hasForceBlockedConsent =
  isExternalPathname(pathname) && search.includes('ga-consent=0');
const { store } = configureStore({
  hasUserConsent: !hasForceBlockedConsent,
  preloadedState,
  history: browserHistory,
  storage: new CookieStorage({
    expiration: {
      default: 364 * 4 * 86400,
      'persist:contactDetails': 7 * 86400,
    },
  }),
});

const history = syncHistoryWithStore(browserHistory, store);

history.listen(() => {
  if (shouldclpScrollPositionUpdate) {
    clpScrollPosition = window.scrollY || window.pageYOffset;
  }
});

browserHistory.listen((change) => {
  // TODO: Check ad blocker detection on client-side route change if GAM scripts are needed.

  if (change.state && change.state.doNotTrack) {
    return;
  }

  trackPage(change);
});

window.store = store;

// Browsers treat scroll restoration differently
// Here we can define the behaviour we need
const scrollMiddleware = routerUseScroll((prevRouterProps, { location }) => {
  const prevLocation = prevRouterProps ? prevRouterProps.location.pathname : '';
  const currentLocation = location.pathname;
  if (!prevLocation) {
    return false;
  }

  if (
    prevLocation.indexOf('/gebrauchtwagen') > -1 &&
    currentLocation.indexOf('/vehicle') > -1
  ) {
    shouldclpScrollPositionUpdate = false;
  }

  if (
    prevLocation.indexOf('/vehicle') > -1 &&
    currentLocation.indexOf('/gebrauchtwagen') > -1
  ) {
    shouldclpScrollPositionUpdate = true;
    return [0, clpScrollPosition];
  }

  if (
    prevLocation.indexOf('/gebrauchtwagen') > -1 &&
    currentLocation.indexOf('/gebrauchtwagen') > -1
  ) {
    window.scrollTo(0, 0);
    return false;
  }

  if (
    prevLocation.indexOf('/detailsuche') > -1 &&
    currentLocation.indexOf('/detailsuche') > -1
  ) {
    return false;
  }

  return [0, 0];
});

// eslint-disable-next-line handle-callback-err
match({ routes: getRoutes(store), history }, (err, redirect, props) => {
  const isPartnerRoute = props?.location?.pathname?.indexOf('/partners') > -1;
  const isStorageAvailable = sessionStore.isSupported();
  const middleware = applyRouterMiddleware(scrollMiddleware);

  /*
      Workaround to avoid unhandled sessionStorage exceptions
      by react-router-scroll in iframe routes.
      https://github.com/taion/react-router-scroll/issues/60
     */
  const propsWithMiddleware = {
    ...props,
    ...(!isPartnerRoute && isStorageAvailable && { render: middleware }),
  };

  /*
      Set the defaultlocale to the same as the defaultlocale on our application - this prevents warnings
      also see html-template.js - the messages have been removed from there - if you want to translate this application
      start sending the messages to the client somehow
     */

  /*
    fix google translate replacing texts with <font/>
    Most probabbly not needed if all condtional rendering in the app 
    for text happens inside a <span/>
  */

  if (typeof Node === 'function' && Node.prototype) {
    const originalRemoveChild = Node.prototype.removeChild;
    Node.prototype.removeChild = function (child) {
      if (child.parentNode !== this) {
        if (console) {
          console.error(
            'Cannot remove a child from a different parent',
            child,
            this,
          );
        }
        return child;
      }
      return originalRemoveChild.apply(this, [child]);
    };

    const originalInsertBefore = Node.prototype.insertBefore;
    Node.prototype.insertBefore = function (newNode, referenceNode) {
      if (referenceNode && referenceNode.parentNode !== this) {
        if (console) {
          console.error(
            'Cannot insert before a reference node from a different parent',
            referenceNode,
            this,
          );
        }
        return newNode;
      }
      return originalInsertBefore.apply(this, [newNode, referenceNode]);
    };
  }

  hydrate(
    <Provider store={store}>
      <Router {...propsWithMiddleware} />
    </Provider>,
    document.querySelector('#app'),
  );
});

/* istanbul ignore file */
import { Route } from 'react-router';

import { getAsyncRouteComponentLoader } from 'app/shared/utils/utils';
import { ConnectedPageTemplate } from 'app/shared/modules/pageTemplate/PageTemplate/ConnectedPageTemplate';

export const VehicleRoutes = (
  <Route
    name="vehicle"
    component={(props) => <ConnectedPageTemplate target="PDP" {...props} />}
  >
    <Route
      path="/vehicle/:listingId/accident-info"
      exact
      getComponent={getAsyncRouteComponentLoader('DamagesReport', () =>
        import('app/shared/modules/DamagesReport/DamagesReport'),
      )}
    />
    <Route
      path="/vehicle/quickview/:id(/:extra)"
      getComponent={getAsyncRouteComponentLoader('ErrorPage', () =>
        import('app/shared/components/ErrorPage/ErrorPage'),
      )}
    />
    <Route
      path="/vehicle/:id(/:extra)"
      getComponent={getAsyncRouteComponentLoader('ConnectedPdpWrapper', () =>
        import('./PdpWrapper/ConnectedPdpWrapper'),
      )}
    />
  </Route>
);

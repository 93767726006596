import styled, { css } from 'styled-components';

import { zIndex } from 'app/shared/styles_js/variables';

export const itemHeight = 56;

export const PopoverSt = styled.div`
  z-index: ${props => (props.isShown ? zIndex.navbar : -100)};
  overflow: hidden;
  width: 100%;
  max-height: ${props => props.maxDisplayedItems * itemHeight}px;
  border-radius: 0 0 4px 4px;
  box-shadow: 8px 8px 30px rgba(0, 0, 0, 0.07);
  opacity: ${props => (props.isShown ? 1 : 0)};
  overflow-y: scroll;
  pointer-events: ${props => !props.isShown && 'none'};
  transition: opacity 0.3s ease-in;
  position: absolute;
  top: ${itemHeight}px;
  left: 0;
  background-color: ${({ theme: { colors } }) => colors.sunbeanSuperLight};

  ${props =>
    props.hasError &&
    css`
      padding: 16px 20px;
      background-color: ${({ theme: { colors } }) => colors.grayLighter};
      color: ${({ theme: { colors } }) => colors.brandDanger};
      font-weight: 100;
    `};

  ${props => props.extraStyles};
`;

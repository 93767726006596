import { connect } from 'react-redux';

import {
  changeUrl,
  resetListing,
  getLastSearch,
  getVehicleCount,
} from 'app/marketplace/search/actions';
import {
  getFeaturedArticles,
  getArticlesFromStore,
  getPage,
  getCampaigns,
} from 'app/marketplace/contentful/actions';
import {
  overrideFilters,
  resetFilters,
  resetFilterCategory,
  resetFiltersExceptCategory,
} from 'app/marketplace/filter/actions';
import {
  getFavouriteVehicleIds,
  handleSubcriptionWatchprice,
} from 'app/marketplace/favourites/actions';
import { Home } from 'app/marketplace/home/Home/Home';
import {
  BASE_URL,
  CONTENTFUL_MAGAZINE,
  CONTENTFUL_HEYCAR,
  WEB_APP_URL,
} from 'app/config';
import { getFiltersFromUri } from 'app/marketplace/filter/utils';
import { setLoading } from 'app/marketplace/home/actions';
import { updateLocationFromUri } from 'app/marketplace/geolocation/actions';
import { deleteSavedSearch } from 'app/marketplace/saveSearch/actions';
import { openOverlay, closeOverlay } from 'app/shared/modules/overlay/actions';
import { checkFeatureFlagHasToken, deviceSelector } from 'app/selectors';

Home.loadAction = (state, props) => {
  return (dispatch) => {
    const {
      location: { query },
      params,
    } = props;

    dispatch(overrideFilters(getFiltersFromUri(params, query, {})));
    return dispatch(updateLocationFromUri(query)).then(() => {
      return Promise.all([
        dispatch(getFeaturedArticles(CONTENTFUL_MAGAZINE, BASE_URL)),
        dispatch(getLastSearch()),
        dispatch(getFavouriteVehicleIds()),
        dispatch(getVehicleCount()),
      ]);
    });
  };
};

function mapStateToProps(state) {
  const { home, filter, search, globalEvents, overlay, contentful } = state;

  const children = contentful.home && contentful.home[0]?.fields?.children;

  const getContentTypeFromChildren = (type, helper) => {
    return (
      children &&
      children[helper]((child) => child.sys.contentType.sys.id === type)
    );
  };

  const stage = getContentTypeFromChildren('stage', 'find')?.fields;

  const specialOffersHome = getContentTypeFromChildren(
    'specialOffersHome',
    'find',
  )?.fields;

  const seoLinks = children?.reduce((arr, { sys, fields }) => {
    if (sys.contentType.sys.id === 'seoLinks') arr.push(fields);
    return arr;
  }, []);

  const seoText = getContentTypeFromChildren('homeSeoText', 'find')?.fields;

  const isFlag256ShowNewPromotionTiles = checkFeatureFlagHasToken(
    state,
    256,
    'show_variant',
  );

  const { error, vehicleCount, queryParams, isLoading } = home;
  const { possibleMakes, allMakes, possibleModels } = filter;
  return {
    //  state.home
    error,
    isLoading,
    vehicleCount,
    queryParams,
    //  state.filter
    possibleMakes,
    allMakes,
    possibleModels,
    activeFilters: filter,

    //  ...rest
    breakpoint: globalEvents.breakpoint,
    deviceType: deviceSelector(state),
    hasOverlay: !!overlay.activeOverlayComponent,
    featuredArticles: contentful.featuredArticles,
    vehicleCountLoading: search.vehicleCountPending > 0,
    seoText,
    stage,
    specialOffersHome,
    seoLinks,
    isFlag256ShowNewPromotionTiles,
  };
}

export const ConnectedHome = connect(mapStateToProps, {
  changeUrl,
  setLoading,
  openOverlay,
  resetListing,
  closeOverlay,
  getVehicleCount,
  overrideFilters,
  getFeaturedArticles,
  getArticlesFromStore,
  updateLocationFromUri,
  deleteSavedSearch,
  resetFilters,
  resetFilterCategory,
  resetFiltersExceptCategory,
  handleSubcriptionWatchprice,
  getPage,
})(Home);

import { all, fork, put, takeLatest } from 'redux-saga/effects';

import { SearchActionTypes, TSearchActions } from '../../redux/types';

import { updateListingsCountOnFilters } from './actions';

/**
 *
 * @param action - the GET_TOTAL_LISTINGS_COUNT action
 * @description gets the listings count from search response's `aggregations`
 *  and triggers the updateListingsCountOnFilters action
 */
export function* getListingsCountFromAggregations(
  action: TSearchActions,
): Generator {
  yield put(updateListingsCountOnFilters(action.payload.aggregations));
}

/**
 *  @description: GET_TOTAL_LISTINGS_COUNT is dispatched whenever the
 *  filters are changed, triggering getListingsCountFromAggregations
 *  */
export function* watchGetVehicleCount(): Generator {
  yield takeLatest(
    SearchActionTypes.GET_TOTAL_LISTINGS_COUNT,
    getListingsCountFromAggregations,
  );
}

export function* searchFilterSagas(): Generator {
  yield all([fork(watchGetVehicleCount)]);
}

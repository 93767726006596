import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const svgDot = (thickness, color) => {
  // eslint-disable-next-line no-param-reassign
  color = color.replace('#', '');
  return `data:image/svg+xml;charset=UTF-8,%3csvg viewBox='0 0 ${
    thickness === 2 ? '70' : '60'
  } 20' xmlns='http://www.w3.org/2000/svg'%3e%3ccircle cx='10' cy='10' r='10' fill='%23${color}' /%3e%3c/svg%3e`;
};

export const DottedLineComponent = styled.div`
  flex-grow: 1;
  width: 100%;
  position: relative;

  &:after {
    position: absolute;
    width: 100%;
    bottom: ${props => props.bottomOffset}px;
    height: ${props => props.thickness}px;
    display: block;
    content: ' ';
    background: url("${props => svgDot(props.thickness, props.color)}");
  }
`;

// eslint-disable-next-line react/jsx-props-no-spreading
export const DottedLine = props => <DottedLineComponent {...props} />;

DottedLine.propTypes = {
  /** The thickness of the dotted line */
  thickness: PropTypes.oneOf([1, 2]),
  /** The desired color of the dotted line, in hex color format */
  color: PropTypes.string,
  /** The distance from bottom, used to be flexable with line heights */
  bottomOffset: PropTypes.number,
};

DottedLine.defaultProps = {
  thickness: 1,
  color: '#dddfe2',
  bottomOffset: 0,
};

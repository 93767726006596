import { memo } from 'react';
import PropTypes from 'prop-types';
import { css } from 'styled-components';

import { Text } from 'app/shared/ui';
import {
  CartileTotalPriceTextSt,
  CartileTotalPriceVATTextSt,
  CartileTotalPriceContainerSt,
} from 'app/shared/modules/Cartile/CartileUIComponents/CartilePrice/CartileTotalPrice/CartileTotalPrice.css';
import { CartileLoadingStyle } from 'app/shared/modules/Cartile/CartileUIComponents/CartileUIComponents.css';
import { CartileVatOptions } from 'app/shared/modules/Cartile/CartileUIComponents/CartilePrice/CartilePriceVatOptions/CartilePriceVatOptions';
import { CartileCrossedOutPrice } from 'app/shared/modules/Cartile/CartileUIComponents/CartilePrice/CartileCrossedOutPrice/CartileCrossedOutPrice';
import { formatCurrency } from 'app/shared/utils/formatNumber';

const CartileTotalPriceCmp = ({
  size,
  price,
  discount,
  isLoading,
  vatReclaimable,
  highestPrice,
  color,
  isD2C,
}) => {
  return (
    <>
      <CartileTotalPriceContainerSt
        discount={discount}
        css={[isLoading && CartileLoadingStyle]}
      >
        {discount &&
          (isD2C ? (
            <Text
              fontSize="10px"
              m={0}
              color="grayLight"
              fontWeight="bold"
              p={0}
              mb={-2}
              css={css`
                text-decoration: line-through;
              `}
            >
              {formatCurrency(highestPrice)}
            </Text>
          ) : (
            <CartileCrossedOutPrice
              highestPrice={highestPrice}
              isLoading={isLoading}
            />
          ))}
        <CartileTotalPriceTextSt size={size} color={color}>
          {formatCurrency(price)}
        </CartileTotalPriceTextSt>
      </CartileTotalPriceContainerSt>
      <CartileTotalPriceVATTextSt
        size="small"
        discount={discount}
      >
        <CartileVatOptions isVatReclaimable={vatReclaimable} />
      </CartileTotalPriceVATTextSt>
    </>
  );
};

CartileTotalPriceCmp.propTypes = {
  discount: PropTypes.bool,
  isLoading: PropTypes.bool,
  size: PropTypes.string.isRequired,
  price: PropTypes.number.isRequired,
  vatReclaimable: PropTypes.bool.isRequired,
  color: PropTypes.string,
  highestPrice: PropTypes.number,
  isD2C: PropTypes.bool,
};

CartileTotalPriceCmp.defaultProps = {
  discount: false,
  isLoading: false,
  color: undefined,
  highestPrice: 0,
  isD2C: false,
};

export const CartileTotalPrice = memo(CartileTotalPriceCmp);

/* istanbul ignore file  : https://heycar.atlassian.net/browse/MTEC-1198 */
/* eslint-disable react-perf/jsx-no-new-function-as-prop,react/jsx-props-no-spreading */
import PropTypes from 'prop-types';

import { noop } from 'app/shared/utils/utils';
import { MenuTitle } from 'app/marketplace/vehicle/Vehicle/MenuTitle';
import { MultiStepSidebar } from 'app/shared/modules/MultiStepSidebar/MultiStepSidebar';
import {
  defineSidebar,
  isNotValidLeadAction,
} from 'app/marketplace/vehicle/Vehicle/Sidebar.utils';

export const Sidebar = ({
  action,
  target,
  vehicle,
  loadMLT,
  location,
  closeOverlay,
  calculationId,
  handleMenuAction,
  callDealerEventId,
  signupForNewsletterWithConsent,
  ...additionalProps
}) => {
  if (isNotValidLeadAction(action)) return null;

  const handleSidebarClose = () => {
    closeOverlay();
    signupForNewsletterWithConsent();
  };
  const sidebar = defineSidebar(action);
  const isRequestVideo = action === 'requestVideo';
  const openMail = () => handleMenuAction('mail');
  const openPhone = () => handleMenuAction('call');

  return (
    <MultiStepSidebar
      target={target}
      vehicle={vehicle}
      loadMLT={loadMLT}
      location={location}
      onClickMail={openMail}
      onClickPhone={openPhone}
      onGoBackClick={openPhone}
      onLogout={handleSidebarClose}
      calculationId={calculationId}
      openSidebar={handleMenuAction}
      onComplete={handleSidebarClose}
      isRequestVideo={isRequestVideo}
      onCallbackClick={handleMenuAction}
      callDealerEventId={callDealerEventId}
      sidebarTitle={<MenuTitle action={action} />}
      {...additionalProps}
    >
      {sidebar}
    </MultiStepSidebar>
  );
};

Sidebar.propTypes = {
  loadMLT: PropTypes.bool,
  target: PropTypes.string,
  closeOverlay: PropTypes.func,
  calculationId: PropTypes.string,
  additionalProps: PropTypes.object,
  handleSidebarClose: PropTypes.func,
  action: PropTypes.string.isRequired,
  vehicle: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  handleMenuAction: PropTypes.func.isRequired,
  callDealerEventId: PropTypes.string.isRequired,
  signupForNewsletterWithConsent: PropTypes.func,
};

Sidebar.defaultProps = {
  target: 'PDP',
  loadMLT: false,
  closeOverlay: noop,
  calculationId: null,
  additionalProps: null,
  handleSidebarClose: noop,
  signupForNewsletterWithConsent: noop,
};

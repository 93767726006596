/* eslint-disable react-perf/jsx-no-new-function-as-prop */
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { getHomePageSchema, Locale } from '@hey-car/intl-seo-hub.schema-markup';

import SliderTrustPilotWidget from 'app/shared/components/TrustPilot/SliderTrustPilotWidget/SliderTrustPilotWidget';
import { HomeSEOText } from 'app/marketplace/contentful/shared/HomeSEOText/HomeSEOText';
import { BASE_URL, CONTENTFUL_HEYCAR, CONTENTFUL_MAGAZINE } from 'app/config';
import { Makes } from 'app/marketplace/home/Makes/Makes';
import { getFiltersFromUri } from 'app/marketplace/filter/utils';
import { SeoLinks } from 'app/marketplace/contentful/shared/SeoLinks/SeoLinks';
import { HtmlHeader } from 'app/shared/components/HtmlHeader/HtmlHeader';
import { GuaranteeBenefits } from 'app/marketplace/home/GuaranteeBenefits/GuaranteeBenefits';
import { MessageBanner } from 'app/marketplace/home/MessageBanner/MessageBanner';
import { CategoryLinks } from 'app/marketplace/home/CategoryLinks/CategoryLinks';
import { ConnectedFDLBanner as FDLBanner } from 'app/shared/modules/FDL/FDLBanner/ConnectedFDLBanner';
import { ConnectedLastSearch as LastSearch } from 'app/marketplace/home/LastSearch/ConnectedLastSearch';
import { ConnectedReducedPriceCars as ReducedPriceCars } from 'app/marketplace/home/ReducedPriceCars/ConnectedReducedPriceCars';
import { MarketplaceFeaturedArticles } from 'app/marketplace/contentful/spaces/magazine/entries/MarketplaceFeaturedArticles/MarketplaceFeaturedArticles';
import { noop } from 'app/shared/utils/utils';
import { ContentfulStage } from 'app/marketplace/home/Stage/ContentfulStage/ContentfulStage';
import { SpecialOffersHome } from 'app/marketplace/specialOffers/SpecialOffersHome/SpecialOffersHome';
import { resetLocation } from 'app/marketplace/geolocation/actions';
import { OnlineKaufTeaser } from 'app/marketplace/home/OnlineKaufTeaser/OnlineKaufTeaser';

import { TuvBanner } from '../TuvBanner/TuvBanner';

const messages = {
  pageTitle: 'Autos gebraucht kaufen mit Garantie bei heycar',
  metaDescription:
    'Hochwertige Gebrauchtwagen von Top Händlern gründlich geprüft. Qualität und Sicherheit beim Kauf. Finde hier noch heute dein Traumauto!',
  searchHeading: 'Finde gebrauchte Autos mit Garantie.',
};

export class Home extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      showBanner: false,
      bannerType: null,
    };
  }

  componentDidMount() {
    const {
      location,
      vehicleCount,
      getVehicleCount,
      overrideFilters,
      featuredArticles,
      getFeaturedArticles,
      updateLocationFromUri,
      deleteSavedSearch,
      resetFilters,
      getPage,
      handleSubcriptionWatchprice,
      specialOffersHome,
    } = this.props;
    const { query } = location;
    if (Object.keys(query).length > 0) {
      overrideFilters(getFiltersFromUri({}, query, {}));
      // eslint-disable-next-line promise/catch-or-return
      updateLocationFromUri(query).then(getVehicleCount);
    } else {
      resetFilters();
      if (!vehicleCount) {
        getVehicleCount();
      }
    }

    if (
      this.queryParamIsPresent('userId') &&
      this.queryParamIsPresent('searchUnsubscribeId')
    ) {
      deleteSavedSearch(query.searchUnsubscribeId, query.userId);
      this.setState({
        showBanner: true,
        bannerType: 'saveSearch',
      });
    }

    // Watch price subscription/unsubscription
    if (this.queryParamIsPresent('user_id')) {
      const isUnSubscribe = this.queryParamIsPresent('utm_term') === 'unsub';

      // eslint-disable-next-line promise/catch-or-return
      handleSubcriptionWatchprice(query.user_id, isUnSubscribe).then(() => {
        this.setState({
          showBanner: true,
          // eslint-disable-next-line promise/always-return
          bannerType: isUnSubscribe
            ? 'watchPriceUnsubscribe'
            : 'watchPriceSubscribe',
        });
      });
    }

    if (featuredArticles.length === 0) {
      getFeaturedArticles(CONTENTFUL_MAGAZINE);
    }
    if (!specialOffersHome) getPage('home', CONTENTFUL_HEYCAR);
  }

  hideUnsubscribeBanner = () => {
    this.setState({
      showBanner: false,
    });
  };

  queryParamIsPresent = (param) => {
    const { location } = this.props;
    return (
      location.query &&
      Object.keys(location.query).length !== 0 &&
      location.query[param]
    );
  };

  render() {
    const {
      allMakes,
      location,
      breakpoint,
      deviceType,
      hasOverlay,
      resetListing,
      featuredArticles,
      getArticlesFromStore,
      resetFiltersExceptCategory,
      stage,
      isLoading,
      setLoading,
      resetFilterCategory,
      specialOffersHome,
      seoLinks,
      seoText,
      isFlag256ShowNewPromotionTiles,
    } = this.props;

    const { showBanner, bannerType } = this.state;

    const showDraft = location.search?.includes('isPreviewContentful');
    // marketplace.home.Stage.filter.title
    const schema = getHomePageSchema({
      locale: Locale.DE_DE,
      page: {
        name: messages.pageTitle,
        description: messages.metaDescription,
        headline: messages.searchHeading,
        url: `${BASE_URL}${location.pathname}`,
        image: {
          url: `${BASE_URL}/assets/heycar_color.jpg`,
          widthInPx: 2000,
          heightInPx: 850,
        },
        organization: {
          logo: `${BASE_URL}/assets/heycar_color.jpg`,
        },
      },
    });

    return (
      <>
        <HtmlHeader
          title={messages.pageTitle}
          description={messages.metaDescription}
          index="index,follow"
          canonicalPath={`${BASE_URL}${location.pathname}`}
          currentUrl={`${BASE_URL}${location.pathname}${location.search}`}
          jsonLd={[schema]}
          showDefaultJsonLd={false}
        >
          <link rel="alternate" hrefLang="de-DE" href="https://hey.car/" />
          <link rel="alternate" hrefLang="en-GB" href="https://heycar.co.uk/" />
          <link rel="alternate" hrefLang="x-default" href="https://hey.car/" />
        </HtmlHeader>
        {showBanner && (
          <MessageBanner
            onDismiss={this.hideUnsubscribeBanner}
            bannerType={bannerType}
          />
        )}
        <ContentfulStage
          stage={stage}
          isLoading={isLoading}
          hasOverlay={hasOverlay}
          breakpoint={breakpoint}
          onLoadImages={/* istanbul ignore next */ () => setLoading(false)}
        />
        <CategoryLinks resetFilters={resetFiltersExceptCategory} />
        <OnlineKaufTeaser />

        <SpecialOffersHome
          specialOffersHome={specialOffersHome}
          breakpoint={breakpoint}
          resetLocation={resetLocation}
          showDraft={showDraft}
          isFlag256ShowNewPromotionTiles={isFlag256ShowNewPromotionTiles}
        />
        <ReducedPriceCars />
        <LastSearch target="HOME" />
        <GuaranteeBenefits breakpoint={breakpoint} />
        <TuvBanner />
        <FDLBanner location={location} />
        <MarketplaceFeaturedArticles
          getArticlesFromStore={getArticlesFromStore}
          featuredArticles={featuredArticles}
        />
        <SliderTrustPilotWidget />
        <Makes
          resetFilters={resetFilterCategory}
          possibleMakes={allMakes}
          resetListing={resetListing}
          breakpoint={breakpoint}
        />
        <SeoLinks
          seoLinks={seoLinks}
          resetListing={resetListing}
          deviceType={deviceType}
        />
        <HomeSEOText seoText={seoText} />
      </>
    );
  }
}

Home.propTypes = {
  deviceType: PropTypes.string.isRequired,
  vehicleCount: PropTypes.number.isRequired,
  getVehicleCount: PropTypes.func.isRequired,
  overrideFilters: PropTypes.func.isRequired,
  resetFilters: PropTypes.func.isRequired,
  resetFiltersExceptCategory: PropTypes.func.isRequired,
  allMakes: PropTypes.array.isRequired,
  updateLocationFromUri: PropTypes.func.isRequired,
  location: PropTypes.any.isRequired,
  resetListing: PropTypes.func.isRequired,
  deleteSavedSearch: PropTypes.func.isRequired,
  getFeaturedArticles: PropTypes.func.isRequired,
  getArticlesFromStore: PropTypes.func.isRequired,
  handleSubcriptionWatchprice: PropTypes.func.isRequired,
  breakpoint: PropTypes.string,
  hasOverlay: PropTypes.bool,
  featuredArticles: PropTypes.array,
  setLoading: PropTypes.func,
  isLoading: PropTypes.bool,
  stage: PropTypes.object,
  getPage: PropTypes.func,
  resetFilterCategory: PropTypes.func,
  specialOffersHome: PropTypes.object,
  seoLinks: PropTypes.array,
  seoText: PropTypes.object,
  isFlag256ShowNewPromotionTiles: PropTypes.bool,
};

Home.defaultProps = {
  breakpoint: 'sm',
  hasOverlay: false,
  featuredArticles: [],
  setLoading: noop,
  isLoading: true,
  stage: { title: '' },
  getPage: noop,
  resetFilterCategory: noop,
  specialOffersHome: null,
  seoLinks: [],
  seoText: {},
  isFlag256ShowNewPromotionTiles: false,
};

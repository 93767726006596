export const currencyOpts = {
  currency: 'EUR',
  minimumFractionDigits: 0,
  maximumFractionDigits: 0,
  style: 'currency',
};

export const mappedServices = [
  { label: 'Porsche GAP', value: 'A' },
  { label: 'Garantieverlängerung', value: 'extendedGuarantee' },
  { label: 'Wartung und Inspektion', value: 'maintenanceAndInspection' },
];

export const userInterestsForLeadCreation = {
  financingAndTradeIn: 'financingAndTradeInInterest',
  tradeIn: 'tradeInInterest',
  financing: 'financingInterest',
  none: 'none',
};

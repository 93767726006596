/* eslint-disable react/no-multi-comp */
/* istanbul ignore file */
import styled from 'styled-components';
import { Box } from '@rebass/grid';

/**
 * CSS animation source: https://loading.io/css/
 */
export const SpinnerSt = styled(Box)`
  /* stylelint-disable selector-pseudo-element-colon-notation */
  position: relative;
  display: inline-block;
  width: ${({ size }) => size}px;
  height: ${({ size }) => size}px;

  div {
    transform-origin: ${({ size }) => Math.round(size * 0.5)}px
      ${({ size }) => Math.round(size * 0.5)}px;
    animation: lds-spinner 1s linear infinite;
  }

  div:after {
    position: absolute;
    top: ${({ top }) => top}px;
    left: ${({ left }) => left}px;
    display: block;
    width: ${({ width }) => width}px;
    height: ${({ height }) => height}px;
    content: ' ';
    border-radius: 20%;
    background: ${({ theme, color }) => theme?.colors?.[color] || color};
  }

  div:nth-child(1) {
    transform: rotate(0deg);
    animation-delay: -0.916666666666667s;
  }

  div:nth-child(2) {
    transform: rotate(30deg);
    animation-delay: -0.833333333333333s;
  }

  div:nth-child(3) {
    transform: rotate(60deg);
    animation-delay: -0.75s;
  }

  div:nth-child(4) {
    transform: rotate(90deg);
    animation-delay: -0.666666666666667s;
  }

  div:nth-child(5) {
    transform: rotate(120deg);
    animation-delay: -0.583333333333333s;
  }

  div:nth-child(6) {
    transform: rotate(150deg);
    animation-delay: -0.5s;
  }

  div:nth-child(7) {
    transform: rotate(180deg);
    animation-delay: -0.416666666666667s;
  }

  div:nth-child(8) {
    transform: rotate(210deg);
    animation-delay: -0.333333333333333s;
  }

  div:nth-child(9) {
    transform: rotate(240deg);
    animation-delay: -0.25s;
  }

  div:nth-child(10) {
    transform: rotate(270deg);
    animation-delay: -0.166666666666667s;
  }

  div:nth-child(11) {
    transform: rotate(300deg);
    animation-delay: -0.083333333333333s;
  }

  div:nth-child(12) {
    transform: rotate(330deg);
    animation-delay: 0s;
  }

  @keyframes lds-spinner {
    0% {
      opacity: 1;
    }

    100% {
      opacity: 0;
    }
  }
`;

/* eslint-disable react/no-multi-comp, */
/* istanbul ignore file */
import styled, { css } from 'styled-components';
import { Flex, Box } from '@rebass/grid';
import {
  display,
  space,
  textAlign,
  border,
  borderColor,
  lineHeight,
  fontSize,
  fontWeight,
} from 'styled-system';
import styledTheme from 'styled-theming';

import { theme as defaultTheme, themeV2 } from '../theme/theme';

export const buttonTheme = styledTheme.variants('mode', 'variant', {
  primary: {
    webApp: {
      default: defaultTheme.colors.brandPrimary,
      hover: defaultTheme.colors.brandPrimaryLight,
      active: defaultTheme.colors.brandPrimaryDark,
      disabled: defaultTheme.colors.brandPrimaryLighter,
      color: 'white',
      hoverColor: 'white',
      activeColor: 'white',
      borderColor: 'transparent',
      disabledColor: '',
      disabledBorderColor: '',
    },
    dark: {},
    v2: {
      default: ({ backdrop }) =>
        ['darkBlue', 'micaBlue'].includes(backdrop)
          ? themeV2.colors.brandPrimary
          : themeV2.colors.brandSecondaryLighter,
      hover: ({ backdrop }) =>
        ['darkBlue', 'micaBlue'].includes(backdrop)
          ? themeV2.colors.brandPrimaryLight
          : themeV2.colors.brandSecondaryLight,
      active: ({ backdrop }) =>
        ['darkBlue', 'micaBlue'].includes(backdrop)
          ? themeV2.colors.brandPrimaryLight
          : themeV2.colors.brandSecondaryLight,
      disabled: ({ backdrop }) => {
        if (['darkBlue', 'micaBlue'].includes(backdrop)) {
          return themeV2.colors.brandSecondarySuperLight;
        }

        return backdrop === 'heycarMint'
          ? themeV2.colors.brandPrimaryLighter
          : themeV2.colors.brandSecondarySuperLight;
      },
      color: ({ backdrop }) =>
        ['darkBlue', 'micaBlue'].includes(backdrop)
          ? themeV2.colors[backdrop]
          : themeV2.colors.white,

      hoverColor: ({ backdrop }) =>
        ['darkBlue', 'micaBlue'].includes(backdrop)
          ? themeV2.colors[backdrop]
          : themeV2.colors.white,
      activeColor: ({ backdrop }) =>
        ['darkBlue', 'micaBlue'].includes(backdrop)
          ? themeV2.colors[backdrop]
          : themeV2.colors.white,
      borderColor: themeV2.colors.transparent,
      disabledColor: ({ backdrop }) =>
        backdrop === 'heycarMint' ? themeV2.colors.brandPrimaryLight : '',
      disabledBorderColor: '',
    },
  },
  secondary: {
    webApp: {
      default: defaultTheme.colors.brandSecondaryLight,
      hover: defaultTheme.colors.brandSecondaryLighter,
      active: defaultTheme.colors.brandSecondary,
      disabled: defaultTheme.colors.grayLight,
      color: 'white',
      hoverColor: 'white',
      activeColor: 'white',
      borderColor: 'transparent',
      disabledColor: 'white',
      disabledBorderColor: '',
    },
    dark: {},
    v2: {
      default: themeV2.colors.sunbeanBlue,
      hover: themeV2.colors.sunbeanDark,
      active: themeV2.colors.sunbeanBlue,
      disabled: themeV2.colors.sunbeanLighter,
      color: ({ backdrop }) =>
        ['darkBlue', 'micaBlue'].includes(backdrop)
          ? themeV2.colors.white
          : themeV2.colors.micaBlue,
      hoverColor: ({ backdrop }) =>
        ['darkBlue', 'micaBlue'].includes(backdrop)
          ? themeV2.colors.brandPrimary
          : themeV2.colors.micaBlue,
      borderColor: ({ backdrop }) => {
        if (['darkBlue', 'micaBlue'].includes(backdrop)) {
          return themeV2.colors.white;
        }
        if (backdrop === 'heycarMint') {
          return themeV2.colors.grayDark;
        }
        return themeV2.colors.transparent;
      },
      activeColor: ({ backdrop }) =>
        ['darkBlue', 'micaBlue'].includes(backdrop)
          ? themeV2.colors.white
          : themeV2.colors.micaBlue,
      disabledColor: ({ backdrop }) =>
        backdrop === 'heycarMint'
          ? themeV2.colors.brandPrimaryLight
          : themeV2.colors.blueLighter,
      hoverBorderColor: ({ backdrop }) =>
        ['darkBlue', 'micaBlue'].includes(backdrop)
          ? themeV2.colors.brandPrimary
          : themeV2.colors.sunbeanDark,
      activeBorderColor: ({ backdrop }) =>
        ['darkBlue', 'micaBlue'].includes(backdrop)
          ? themeV2.colors.brandPrimary
          : themeV2.colors.sunbeanBlue,
      disabledBorderColor: ({ backdrop }) =>
        backdrop === 'heycarMint'
          ? themeV2.colors.brandPrimaryLight
          : themeV2.colors.sunbeanLighter,
    },
  },
  tertiary: {
    webApp: {
      default: 'white',
      hover: 'white',
      active: 'white',
      disabled: defaultTheme.colors.grayLight,
      color: defaultTheme.colors.brandSecondary,
      hoverColor: defaultTheme.colors.brandPrimary,
      activeColor: defaultTheme.colors.brandSecondary,
      borderColor: defaultTheme.colors.gray,
      disabledColor: 'white',
      disabledBorderColor: defaultTheme.colors.grayLight,
    },
    dark: {},
    v2: {
      default: themeV2.colors.transparent,
      hover: themeV2.colors.transparent,
      active: themeV2.colors.transparent,
      disabled: themeV2.colors.transparent,
      color: ({ backdrop, tertiaryVariant }) => {
        if (['darkBlue', 'micaBlue'].includes(backdrop)) {
          return themeV2.colors.brandPrimary;
        }
        if (backdrop === 'heycarMint') {
          return themeV2.colors.micaBlue;
        }
        return tertiaryVariant === 'primary'
          ? themeV2.colors.brandPrimaryDark
          : themeV2.colors.brandSecondaryLight;
      },
      hoverColor: ({ backdrop, tertiaryVariant }) => {
        if (tertiaryVariant === 'primary') {
          if (['darkBlue', 'micaBlue'].includes(backdrop)) {
            return themeV2.colors.brandPrimaryLight;
          }
          if (backdrop === 'heycarMint') {
            return themeV2.colors.actionBlue;
          }
          return themeV2.colors.brandPrimary;
        }
        return ['darkBlue', 'micaBlue'].includes(backdrop)
          ? themeV2.colors.brandPrimaryLight
          : themeV2.colors.brandSecondaryLighter;
      },
      activeColor: ({ backdrop, tertiaryVariant }) => {
        if (tertiaryVariant === 'primary') {
          if (['darkBlue', 'micaBlue'].includes(backdrop)) {
            return themeV2.colors.brandPrimaryLight;
          }
          if (backdrop === 'heycarMint') {
            return themeV2.colors.actionBlue;
          }
          return themeV2.colors.brandPrimary;
        }
        return ['darkBlue', 'micaBlue'].includes(backdrop)
          ? themeV2.colors.brandPrimaryLight
          : themeV2.colors.brandSecondaryLighter;
      },
      borderColor: themeV2.colors.transparent,
      disabledColor: ({ backdrop }) =>
        backdrop === 'heycarMint'
          ? themeV2.colors.brandPrimaryLight
          : themeV2.colors.brandSecondarySuperLight,
      disabledBorderColor: themeV2.colors.grayLight,
    },
  },
  quarternary: {
    webApp: {},
    dark: {},
    v2: {
      default: themeV2.colors.transparent,
      hover: themeV2.colors.transparent,
      active: themeV2.colors.transparent,
      disabled: themeV2.colors.transparent,
      color: ({ backdrop }) =>
        ['darkBlue', 'micaBlue'].includes(backdrop)
          ? themeV2.colors.white
          : themeV2.colors.brandSecondaryLight,
      hoverColor: ({ backdrop }) =>
        ['darkBlue', 'micaBlue'].includes(backdrop)
          ? themeV2.colors.brandPrimary
          : themeV2.colors.brandSecondaryLighter,
      activeColor: ({ backdrop }) =>
        ['darkBlue', 'micaBlue'].includes(backdrop)
          ? themeV2.colors.brandPrimary
          : themeV2.colors.brandSecondaryLighter,
      borderColor: themeV2.colors.transparent,
      disabledColor: ({ backdrop }) =>
        backdrop === 'heycarMint'
          ? themeV2.colors.brandPrimaryLight
          : themeV2.colors.brandSecondarySuperLight,
    },
  },
});

const disabledSt = css`
  background: ${(props) => buttonTheme(props).disabled};
  color: ${(props) => buttonTheme(props).disabledColor};
  border-color: ${(props) => buttonTheme(props).disabledBorderColor};
  pointer-events: none;
  cursor: default;
`;

export const ButtonSt = styled(Flex).attrs((props) => ({
  as: props.as || 'button',
  display: 'inline-flex',
  justifyContent: 'center',
  alignItems: 'center',
  lineHeight: 'normal',
  ...props,
}))`
  cursor: pointer;
  ${({ theme }) => {
    // v2 theme
    if (theme.rebranding) {
      return `
        transition-property: color, background, border-color;
        transition-duration: 200ms;
        transition-timing-function: ease-in-out;  
      `;
    }
  }}

  background: ${(props) => buttonTheme(props).default};
  color: ${(props) => buttonTheme(props).color};

  ${({ theme, variant }) => {
    // v2 theme
    if (theme.rebranding) {
      if (['primary', 'secondary'].includes(variant)) {
        return `
          min-height: 44px;
          border-radius: 4px;
          border: 1px solid;
          padding: 8px 16px;
          font-size: 14px;
          font-weight: 300;
        `;
      }

      return `
        font-weight: ${variant !== 'quarternary' ? 300 : 400};
        text-decoration: ${variant === 'tertiary' && 'underline'};
        border: none;
      `;
    }

    // webApp theme
    return `
      min-height: 48px;
      border: 1px solid;
      padding: 8px 16px;
      border-radius: 4px;
      font-weight: 300;
    `;
  }}

  border-color: ${(props) => buttonTheme(props).borderColor};

  &:hover {
    background: ${(props) => buttonTheme(props).hover};
    color: ${(props) => buttonTheme(props).hoverColor};
    border-color: ${(props) => buttonTheme(props).hoverBorderColor};
  }

  &:active,
  &:focus {
    background: ${(props) => buttonTheme(props).active};
    color: ${(props) => buttonTheme(props).activeColor};
  }

  &:focus {
    /* WebKit-specific. Other browsers will keep their default outline style.
      (Initially tried to also force default via 'outline: initial',
      but that seems to erroneously remove the outline in Firefox altogether.) */
    outline: 5px auto -webkit-focus-ring-color;
    outline-offset: -2px;
  }

  &:disabled {
    ${disabledSt};
  }

  ${({ disabled, as }) => disabled && as !== 'button' && disabledSt};

  && {
    -webkit-appearance: none;
  }

  ${display};
  ${space};
  ${fontSize};
  ${fontWeight};
  ${textAlign};
  ${lineHeight};
  ${border};
  ${borderColor};
`;

export const UnstyledSt = styled(Box).attrs(({ theme, ...props }) => ({
  as: 'button',
  p: 0,
  m: 0,
  textAlign: 'left',
  lineHeight: 'normal',
  border: 0,
  ...props,
}))`
  background-color: transparent;
  cursor: pointer;

  &:focus {
    /* WebKit-specific. Other browsers will keep their default outline style.
      (Initially tried to also force default via 'outline: initial',
      but that seems to erroneously remove the outline in Firefox altogether.) */
    outline: 5px auto -webkit-focus-ring-color;
    outline-offset: -2px;
  }

  &:disabled {
    cursor: default;
  }

  ${({ disabled }) =>
    disabled &&
    css`
      cursor: default;
    `};

  ${space};
  ${textAlign};
  ${border};
`;

/* istanbul ignore file */
import { connect } from 'react-redux';

import { setIsPdpCampaignTopBannerAvailable } from 'app/marketplace/specialOffers/actions';
import { TopBanner } from 'app/marketplace/shared/TopBanner/TopBanner';
import { breakpointSelector } from 'app/shared/modules/globalEvents/selectors';
import { selectCampaignData } from 'app/shared/modules/Campaign/selectors';
import { openOverlay, closeOverlay } from 'app/shared/modules/overlay/actions';
import { signupForNewsletterWithConsent } from 'app/marketplace/vehicle/actions';
import { getAutomatedListings } from 'app/marketplace/vehicle/selectors';
import { checkFeatureFlagHasToken } from 'app/selectors';

const mapStateToProps = (state) => {
  return {
    breakpoint: breakpointSelector(state),
    vehicle: state.vehicle.vehicle,
    isTopNavBarHidden: state.globalEvents.isTopNavBarHidden,
    campaignData: selectCampaignData(state),
    isPdpCampaignTopBannerAvailable:
      state.specialOffers.isPdpCampaignTopBannerAvailable ||
      state.vehicle.pending, // Fixes the loading glitch of TopBanner while the vehicle is loading
    isAdBlockerDetected: state.globalEvents.isAdBlockerDetected,
    automatedListings: getAutomatedListings(state),
    isFlag248OneClickLeadsShow: checkFeatureFlagHasToken(
      state,
      248,
      'show_variant',
    ),
  };
};

export const ConnectedTopBanner = connect(mapStateToProps, {
  setIsPdpCampaignTopBannerAvailable,
  openOverlay,
  closeOverlay,
  signupForNewsletterWithConsent,
})(TopBanner);

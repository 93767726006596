import { trackAction } from 'app/shared/utils/tracking';

const target = 'homepage_filter_panel';

export const handleTracking = (filterType) => {
  /* istanbul ignore next */
  trackAction('hp_filter_panel', {
    category: target,
    label: `${filterType}`,
    description: `user interact with ${filterType} filter`,
  });
};

export const trackResultClick = () => {
  trackAction('filterresult_homepage_click', {
    category: target,
    label: 'clicked on main CTA of the location page',
    value: 'filter_string',
  });
};

export const trackSearchSaleTabChange = (e, index) => {
  /* istanbul ignore next */
  const event = 'tab_homepage_click';

  /* istanbul ignore next */
  const trackLabel = index === 0 ? 'Suchen' : 'Verkaufen';

  /* istanbul ignore next */
  trackAction(event, {
    category: target,
    label: `${trackLabel}`,
    description: `user clicks on ${trackLabel} item in Homepage`,
  });
};

// ---- 3 Financing options A/B test -----

export const trackPriceChangeFilter = (label, value = null) => {
  trackAction('hp_filter_panel', {
    category: 'homepage_filter_panel',
    label: `${label}Price`,
    value,
  });
};

export const trackFinancingOptionFilter = (actionName) => {
  trackAction(actionName, {
    category: 'HP',
    label: actionName,
  });
};

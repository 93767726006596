/* eslint-disable react/no-multi-comp */
import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';

import { DOMAINS, getSuggestion } from './EmailDomains';
import {
  ErrorTextSt,
  HelperTextSt,
  InputBoxSt,
  InputSt,
  PrefixSt,
  SuffixSt,
  LabelSt,
  TrailingIconSt,
  FieldsetSt,
  OuterBoxSt,
  Legend,
  InputMask,
} from './InputBox.css';

export const InputBox = props => {
  const {
    autoFocus,
    autoComplete,
    className,
    isRequired,
    isLabelBelowInput,
    isMobileDevice,
    type,
    name,
    label,
    id,
    placeholder,
    value,
    prefix,
    suffix,
    rightAlign,
    disabled,
    tabIndex,
    dataQa,
    min,
    max,
    pattern,
    inputMode,
    multiline,
    resize,
    rows,
    size,
    compact,
    disableAutofill,
    helperText,
    errorText,
    onFocus,
    onChange,
    onBlur,
    selectOnFocus,
    blurOnEnter,
    checkValidation,
    helpTextRightAlign,
    trimValue,
    maxLength,
    onKeyDown,
    onPaste,
    onCopy,
  } = props;
  const inputRef = useRef();
  const [errorMessage, setErrorMessage] = useState(errorText);
  const [hasFocus, setHasFocus] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [suggestion, setSuggestion] = useState('');

  useEffect(() => {
    if (disabled) {
      setHasFocus(false)
    }
  }, [disabled])

  useEffect(() => {
    if (autoFocus && inputRef?.current) inputRef.current.focus();
  }, []);

  useEffect(() => {
    if (errorText !== errorMessage) {
      setErrorMessage(errorText);
    }
  }, [errorText]);

  /**
   * Updating selection range according to input's value + suggestion
   * @param {string} newSuggestion: new suggestion value
   * @param {string} newValue: input's value last typed
   */
  const updateSelection = (newSuggestion, newValue) => {
    if (!newSuggestion || newSuggestion.length === 0) return;

    const endPos = String(newValue).length;
    const startPos = endPos - newSuggestion.length;
    inputRef.current.setSelectionRange(startPos, endPos);
  };

  /**
   * Updating suggestion value according to input's value
   * @param {Object} e: JS event object
   */
  const updateSuggestion = ({ target }) => {
    if (target.selectionStart !== target.value.length) {
      return;
    }
    const previousValue = value.substr(0, value.lastIndexOf(suggestion));
    const didValueExtend = target.value.length > previousValue.length;
    /*
     * Workaround to stop selecting last character before deleting it.
     * It checks if there's only one less character and the current
     * input's value already has the complete suggestion value.
     */
    const isErasingFirstTime =
      target.value.length === value.length - 1 &&
      DOMAINS.includes(value.split('@')[1]);
    const newSuggestion =
      didValueExtend && !isErasingFirstTime ? getSuggestion(target.value) : '';
    target.value += newSuggestion;
    setSuggestion(newSuggestion);
    updateSelection(newSuggestion, target.value);
  };

  /**
   * Handling value change as well as internal email suggestions
   * @param {Object} e: JS event object
   */
  const handleChange = (e) => {
    if (type === 'email' && !isMobileDevice) {
      updateSuggestion(e);
    }

    /**
     * Users can fill the email address by using suggestions or autofill from the mobile
     * virtual keyboard that has whitespace(s) in it. That causes the field to get invalid
     * immediately if it's required.
     *
     * This is the workaround to prevent whitespaces on any field.
     */
    if (trimValue && e.target.value) e.target.value = e.target.value.trim();

    onChange(e);
    checkValidation(e);
  };

  /**
   * Applying the necessary logic when the input is focused
   * @param {Object} e: JS event object
   */
  const handleFocus = e => {
    setHasFocus(true);
    onFocus(e);

    if (selectOnFocus && inputRef?.current) {
      inputRef.current.select();
    }
  };

  /**
   * Applying the necessary logic when the input loses focus
   * @param {Object} e: JS event object
   */
  const handleBlur = e => {
    setHasFocus(false);
    checkValidation(e);
    onBlur(e);
  };

  /**
   * Applying blur effect when the user presses "Enter"
   * @param {Object} e: JS event object
   */
  const onKeyPress = ({ key, target }) => {
    if (key === 'Enter' && blurOnEnter) {
      target.blur();
    }
  };

  /**
   * Handling correct input's mask for passwords
   */
  const togglePasswordMasking = () => {
    setShowPassword(!showPassword);
  };

  /**
   * Rendering additional texts to the input wrapper, such as error
   * messages or helper texts
   */
  const renderAddonText = () => {
    if (errorMessage && !compact) {
      return <ErrorTextSt>{errorMessage}</ErrorTextSt>;
    }

    if (helperText) {
      return (
        <HelperTextSt rightAlign={helpTextRightAlign}>
          {helperText}
        </HelperTextSt>
      );
    }

    return null;
  };

  const hasValue = !!value || value === 0;
  const hasError = errorMessage;
  const hasPrefix = !!prefix;
  const hasSuffix = !!suffix;
  const hasAddonText = (errorMessage && !compact) || helperText;
  const additionalProps = {};

  let inputType = type;
  let pwdSwitch = null;
  const hasFixedLabel = Boolean(placeholder) || type === 'tel';
  const isPhonePrefix = hasPrefix && type === 'tel';
  const hasLabelFocus = hasValue || hasFocus || hasFixedLabel;

  if (type === 'password') {
    inputType = showPassword ? 'text' : 'password';

    pwdSwitch = (
      <TrailingIconSt onClick={togglePasswordMasking}>
        <i className={`if-icon-${showPassword ? 'hide' : 'show'}`} />
      </TrailingIconSt>
    );
  } else if (type === 'number' || type === 'date') {
    additionalProps.min = min;
    additionalProps.max = max;
    additionalProps.onWheel = e => e.target.blur();
  } else if (type === 'email' && !isMobileDevice) {
    inputType = 'text'; // setSelectionRange fails on type=email
    additionalProps.inputMode = 'email'; // but we still want to help
  }

  return (
    <OuterBoxSt size={size} hasAddonText={hasAddonText}>
      <InputBoxSt className={className} hasPrefix={hasPrefix}>
        {hasPrefix && (
          <PrefixSt isPhonePrefix={isPhonePrefix}>{prefix}</PrefixSt>
        )}

        {/* It has to be rendered before <InputSt /> since this is the workaround to make disabling autofill work. */}
        {disableAutofill && <InputMask tabIndex="-1" />}

        <InputSt
          as={multiline ? 'textarea' : 'input'}
          autoComplete={disableAutofill ? 'off' : autoComplete}
          hasValue={hasValue}
          hasError={hasError}
          prefix={prefix}
          hasPrefix={hasPrefix}
          isPhonePrefix={isPhonePrefix}
          hasSuffix={hasSuffix}
          hasFocus={hasFocus}
          isRightAligned={rightAlign}
          type={inputType}
          id={id}
          name={disableAutofill ? '' : name}
          label={label}
          placeholder={placeholder}
          required={isRequired}
          value={hasValue ? value : ''}
          onChange={handleChange}
          onBlur={handleBlur}
          onKeyPress={onKeyPress}
          onFocus={handleFocus}
          disabled={disabled}
          tabIndex={tabIndex}
          data-qa={dataQa}
          pattern={pattern}
          inputMode={inputMode}
          ref={inputRef}
          onKeyDown={onKeyDown}
          resize={resize}
          rows={multiline ? rows : null}
          size={size}
          maxLength={maxLength}
          onPaste={onPaste}
          onCopy={onCopy}
          {...additionalProps}
        />

        {hasSuffix && <SuffixSt>{suffix}</SuffixSt>}

        <LabelSt
          disabled={disabled}
          hasFocus={hasFocus}
          hasValue={hasValue}
          hasError={hasError}
          hasFixedLabel={hasFixedLabel}
          htmlFor={id}
          prefix={prefix}
          hasPrefix={hasPrefix}
          isPhonePrefix={isPhonePrefix}
          isLabelBelowInput={isLabelBelowInput}
        >
          {label}
          {isRequired && label && '*'}
        </LabelSt>

        {pwdSwitch}

        {/* Fieldset & legend only used for aesthetic purposes (Material Design labels) */}
        {/* Fieldset borders can be natively broken by a legend child */}
        <FieldsetSt
          disabled={disabled}
          hasError={hasError}
          hasValue={hasValue}
          hasFocus={hasFocus}
          size={size}
          aria-hidden="true"
        >
          {/* transparent legend used to break fieldset border */}
          {label && (
            <Legend isFocused={hasLabelFocus}>
              {label}
              {isRequired && '*'}
            </Legend>
          )}
        </FieldsetSt>
      </InputBoxSt>
      {renderAddonText()}
    </OuterBoxSt>
  );
};

InputBox.propTypes = {
  autoComplete: PropTypes.string,
  className: PropTypes.string,
  isRequired: PropTypes.bool,
  isLabelBelowInput: PropTypes.bool,
  isMobileDevice: PropTypes.bool,
  type: PropTypes.string,
  name: PropTypes.string,
  label: PropTypes.node,
  id: PropTypes.string,
  placeholder: PropTypes.string,
  helperText: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  errorText: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  autoFocus: PropTypes.bool,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
  onPaste: PropTypes.func,
  onCopy: PropTypes.func,
  checkValidation: PropTypes.func,
  prefix: PropTypes.any,
  suffix: PropTypes.any,
  rightAlign: PropTypes.bool,
  helpTextRightAlign: PropTypes.bool,
  selectOnFocus: PropTypes.bool,
  blurOnEnter: PropTypes.bool,
  disabled: PropTypes.bool,
  tabIndex: PropTypes.number,
  dataQa: PropTypes.string,
  min: PropTypes.string,
  max: PropTypes.string,
  pattern: PropTypes.string,
  inputMode: PropTypes.oneOf([
    'text',
    'none',
    'decimal',
    'numeric',
    'tel',
    'search',
    'email',
    'url',
  ]),
  multiline: PropTypes.bool,
  resize: PropTypes.oneOf([
    'none',
    'both',
    'horizontal',
    'vertical',
    'block',
    'inline',
    'inherit',
    'initial',
    'unset',
  ]),
  rows: PropTypes.number,
  size: PropTypes.string, // Size of the elements (eg. small, normal)
  disableAutofill: PropTypes.bool,
  compact: PropTypes.bool,
  trimValue: PropTypes.bool,
  maxLength: PropTypes.number,
  onKeyDown: PropTypes.func,
};

InputBox.defaultProps = {
  autoComplete: '',
  className: '',
  isRequired: false,
  isLabelBelowInput: false,
  isMobileDevice: false,
  type: 'text',
  name: '',
  label: '',
  id: null,
  placeholder: '',
  helperText: '',
  errorText: '',
  value: '',
  autoFocus: false,
  prefix: null,
  suffix: null,
  rightAlign: false,
  helpTextRightAlign: false,
  onChange: () => {},
  onBlur: () => {},
  onFocus: () => {},
  checkValidation: () => {},
  onPaste: () => {},
  onCopy: () => {},
  selectOnFocus: false,
  blurOnEnter: false,
  disabled: false,
  tabIndex: 0,
  dataQa: '',
  min: '',
  max: '',
  pattern: null,
  inputMode: null,
  multiline: false,
  resize: 'none',
  rows: 2,
  size: 'normal',
  disableAutofill: false,
  compact: false,
  trimValue: false,
  maxLength: undefined,
  onKeyDown: () => {},
};

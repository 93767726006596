import { FilterMappings } from 'app/marketplace/filter/mappings';

export const RatgeberMenuItems = [
  {
    title: 'Information',
    elements: [
      {
        label: 'FAQ',
        url: '/faq',
      },
      {
        label: 'Kostenlose Beratung',
        url: '/kostenlose-beratung',
      },
      {
        label: 'hey Magazine',
        url: '/magazine',
      },
      {
        label: 'Alle Marken und Modelle',
        url: '/auto',
      },
      {
        label: 'Alles über Elektrofahrzeuge',
        url: '/elektro',
      },
    ],
  },
];

export const PaymentMenuItems = () => {
  const onlineProductOffereings =
    'DE_DEALER_CASH,DE_D2C_CASH,DE_INSTAMOTION_CASH';
  return [
    {
      title: 'Fahrzeugsuche',
      elements: [
        {
          label: 'Alle Marken',
          url: '/gebrauchtwagen/auto-online-kaufen',
          useRouter: true,
        },
        {
          label: 'Audi',
          url: `/gebrauchtwagen?make=audi&model=audi_all&trim=audi_all_all&productOffering=${onlineProductOffereings}`,
          useRouter: true,
        },
        {
          label: 'Volkswagen',
          url: `/gebrauchtwagen?make=vw&model=vw_all&trim=vw_all_all&productOffering=${onlineProductOffereings}`,
          useRouter: true,
        },
        {
          label: 'Mercedes',
          url: `/gebrauchtwagen?make=mercedes-benz&productOffering=${onlineProductOffereings}`,
          useRouter: true,
        },
      ],
    },
    {
      title: 'Unsere Angebote',
      elements: [
        {
          label: 'Alle Fahrzeuge anzeigen',
          url: '/gebrauchtwagen/auto-online-kaufen',
          useRouter: true,
        },
        {
          label: 'Fahrzeuge zum online kaufen',
          url: '/gebrauchtwagen/auto-online-kaufen',
          useRouter: true,
        },
        {
          label: 'Fahrzeuge zum online finanzieren',
          url:
            '/gebrauchtwagen?productOffering=DE_D2C_FINANCING_AC,DE_DEALER_FINANCING_AC',
          useRouter: true,
        },
        {
          label: 'Fahrzeuge zum online leasen',
          url: '/gebrauchtwagen?productOffering=DE_D2C_LEASING',
          target: '_self',
        },
      ],
    },
    {
      title: 'Wissenswertes',
      elements: [
        {
          label: 'Warum online bestellen?',
          url: '/auto-online-bestellen',
        },
        {
          label: 'Wie funktioniert es?',
          url: '/autokauf-online-so-funktionierts',
        },
        {
          label: 'FAQs',
          url: 'https://portal.heycar.de/',
        },
      ],
    },
  ];
};

export const CarSearchItems = [
  {
    title: 'Gebrauchtwagen',
    elements: [
      {
        label: 'Alle Gebrauchtwagen',
        url: '/gebrauchtwagen',
        useRouter: true,
      },
      {
        label: 'Elektrofahrzeuge',
        url: '/gebrauchtwagen?fuelType=electric',
        useRouter: true,
      },
      {
        label: 'Automatik',
        url: '/gebrauchtwagen?gearBox=automatic_gear',
        useRouter: true,
      },
    ],
  },
  {
    title: 'Online Angebote',
    elements: [
      {
        label: 'Alle verfügbaren Fahrzeuge',
        url: '/gebrauchtwagen/auto-online-kaufen',
        useRouter: true,
      },
      {
        label: 'Fahrzeuge zum online kaufen',
        url: '/gebrauchtwagen/auto-online-kaufen',
        useRouter: true,
      },
      {
        label: 'Fahrzeuge zum online leasen',
        url: '/gebrauchtwagen?productOffering=DE_D2C_LEASING',
        useRouter: false,
        target: '_self',
      },
    ],
  },
  {
    title: 'Deals und Specials',
    elements: [
      {
        label: 'Alle Angebote',
        url: '/angebote',
        useRouter: true,
      },
      {
        label: 'Preisreduziert',
        url: '/gebrauchtwagen?specialOffers=reducedPrice',
        useRouter: true,
      },
      {
        label: 'Auto Abo',
        url: '/autoabo',
        useRouter: true,
      },
    ],
  },
  {
    title: 'Suche nach Stadt',
    elements: [
      {
        label: 'München',
        url: '/auto/gebrauchtwagen/bayern/muenchen',
        useRouter: false,
      },
      {
        label: 'Hamburg',
        url: '/auto/gebrauchtwagen/hamburg',
        useRouter: false,
      },
      {
        label: 'Hannover',
        url: '/auto/gebrauchtwagen/niedersachsen/hannover',
        useRouter: false,
      },
      {
        label: 'Leipzig',
        url: '/auto/gebrauchtwagen/sachsen/leipzig',
        useRouter: false,
      },
    ],
  },
];

export const BrandsMenuItems = [
  {
    id: 'audi',
    url: '/gebrauchtwagen/audi',
    label: 'Audi',
  },
  {
    id: 'mercedes',
    url: '/gebrauchtwagen/mercedes-benz',
    label: 'Mercedes',
  },
  {
    id: 'volkswagen',
    url: '/gebrauchtwagen/vw',
    label: 'Volkswagen',
  },
  {
    id: 'bmw',
    url: '/gebrauchtwagen/bmw',
    label: 'BMW',
  },
  {
    id: 'fiat',
    url: '/gebrauchtwagen/fiat',
    label: 'Fiat',
  },
  {
    id: 'ford',
    url: '/gebrauchtwagen/ford',
    label: 'Ford',
  },
  {
    id: 'mazda',
    url: '/gebrauchtwagen/mazda',
    label: 'Mazda',
  },
  {
    id: 'mini',
    url: '/gebrauchtwagen/mini',
    label: 'Mini',
  },
  {
    id: 'opel',
    url: '/gebrauchtwagen/opel',
    label: 'Opel',
  },
  {
    id: 'volvo',
    url: '/gebrauchtwagen/volvo',
    label: 'Volvo',
  },
];

import { CONTENTFUL_HEYCAR } from 'app/config';

import { getEntries } from '../../utils';

export const SUBMIT_ENTRIES_REQUEST = 'propositionBar/SUBMIT_ENTRIES_REQUEST';
export const SUCCESS_ENTRIES_REQUEST = 'propositionBar/SUCCESS_ENTRIES_REQUEST';
export const FAILURE_ENTRIES_REQUEST = 'propositionBar/FAILURE_ENTRIES_REQUEST';

export const getPropositionBarEntries = (baseUrl) => dispatch => {
  const queryObj = {
    content_type: 'propositionBar',
    'fields.urlId': '',
  };

  dispatch({ type: SUBMIT_ENTRIES_REQUEST });

  return dispatch(getEntries(queryObj, CONTENTFUL_HEYCAR, baseUrl))
    .then(response =>
      dispatch({ type: SUCCESS_ENTRIES_REQUEST, payload: response }),
    )
    .catch(error => dispatch({ type: FAILURE_ENTRIES_REQUEST, error }));
};

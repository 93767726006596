import { useEffect, useCallback, useRef, useState } from 'react';

export const useInterval = (
  fn: () => void,
  milliseconds = 1,
  isDisabled = false,
): [() => void, boolean] => {
  const timeout = useRef<NodeJS.Timeout>();
  const callback = useRef<() => void>(fn);
  const [isCleared, setIsCleared] = useState<boolean>(false);

  // the clear method
  const clear = useCallback(() => {
    /* istanbul ignore next */
    if (timeout.current) {
      setIsCleared(true);
      clearInterval(timeout.current);
    }
  }, []);

  // if the provided function changes, change its reference
  useEffect(() => {
    callback.current = fn;
  }, [fn]);

  // when the milliseconds change, reset the timeout if it's not disabled
  useEffect(() => {
    if (!isDisabled) {
      timeout.current = setInterval(() => {
        callback.current();
      }, milliseconds);
    }

    // cleanup previous interval
    return clear;
  }, [milliseconds]);

  // when component unmount clear the timeout
  useEffect(
    () => () => {
      clear();
    },
    [],
  );

  return [clear, isCleared];
};

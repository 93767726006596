export const promotionTiles = [
  {
    id: 2,
    title: '<mark>500 € Rabatt<sup>1</sup></mark>',
    subtitle: 'bei der Finanzierung<br />ausgewählter Gebrauchter',
    imageUrl: '/assets/promotionTiles/car-image-4.png',
    tileBackground: '#00CEA5',
    titleColor: '#fff',
    titleMarkBackground: '#052962',
    href: '/500euro_rabatt',
    trackingData: {
      value: '202204_VGRD_VOUCHER_500',
    },
  },
  {
    id: 3,
    title: 'Einfach versichert,<br />z.B. 200 € gespart<sup>y</sup>',
    subtitle:
      'mit bis zu <mark>3 beitragsfreien</mark> Versicherungsmonaten<sup>z</sup>',
    imageUrl: '/assets/promotionTiles/car-image.png',
    tileBackground: '#052962',
    titleColor: '#fff',
    subtitleMarkBackground: '#00CEA5',
    href: '/trybeforebuy',
    trackingData: {
      value: '202203_TRY_BEFORE_BUY',
    },
  },
  {
    id: 4,
    title: '<mark>Unter 5 Liter</mark> im Verbrauch',
    subtitle: 'Ausgewählte reduzierte Kleinwagen mit <5L Verbrauch',
    imageUrl: '/assets/promotionTiles/car-image-6.png',
    tileBackground: '#C0E6FF',
    titleColor: '#303030',
    titleMarkBackground: '#FAC361',
    href:
      '/gebrauchtwagen?consumptionRange=0-5&category=kleinwagen&specialOffers=reducedPrice',
    trackingData: {
      value: 'under_five_liter_usage_campaign',
    },
  },
  {
    id: 5,
    title: 'Aufgeladen und runtergesetzt',
    subtitle: 'Alle <mark>reduzierten</mark> Elektro-Fahrzeuge',
    imageUrl: '/assets/promotionTiles/car-image-2.png',
    tileBackground: '#FAC361',
    titleColor: '#303030',
    subtitleMarkBackground: '#fff',
    href: '/gebrauchtwagen?fuelType=electric&specialOffers=reducedPrice',
    trackingData: {
      value: 'e_cars_campaign',
    },
  },
  {
    id: 6,
    title: 'Unsere <mark>0% Deals</mark>',
    subtitle: 'Alle Fahrzeuge mit einer 0% Finanzierung<sup>6</sup>',
    imageUrl: '/assets/promotionTiles/car-image-3.png',
    tileBackground: '#FF755D',
    titleColor: '#303030',
    titleMarkBackground: '#fff',
    href: '/gebrauchtwagen?effInterestRateRange=0-0',
    trackingData: {
      value: 'zero_percent_financing_campaign',
    },
  },
  {
    id: 7,
    title: 'Dach und Preise runter',
    subtitle: 'Unsere <mark>reduzierten Cabrios</mark>',
    imageUrl: '/assets/promotionTiles/car-image-8.png',
    tileBackground: '#00CEA5',
    titleColor: '#fff',
    subtitleMarkBackground: '#052962',
    href: '/gebrauchtwagen?category=cabrio&specialOffers=reducedPrice',
    trackingData: {
      value: 'reduced_cabrio_campaign',
    },
  },
  {
    id: 8,
    title: 'Große Wagen,<br />kleine Preise',
    subtitle: 'SUVs <mark>unter 30.000 €</mark>',
    imageUrl: '/assets/promotionTiles/car-image-7.png',
    tileBackground: '#C0E6FF',
    titleColor: '#303030',
    subtitleMarkBackground: '#FAC361',
    href:
      '/gebrauchtwagen?priceRange=7000-30000&category=suv&specialOffers=reducedPrice',
    trackingData: {
      value: 'lower_price_suv_campaign',
    },
  },
];

export const promotionTilesForSubcategory = [
  {
    id: 2,
    title: '<mark>500 € Rabatt<sup>1</sup></mark>',
    subtitle: 'bei der Finanzierung<br />ausgewählter Gebrauchter',
    imageUrl: '/assets/promotionTiles/car-image-4.png',
    tileBackground: '#00CEA5',
    titleColor: '#fff',
    titleMarkBackground: '#052962',
    href: '/gebrauchtwagen?markerId=202204_VGRD_VOUCHER_500',
    trackingData: {
      value: '202204_VGRD_VOUCHER_500',
    },
  },
  {
    id: 3,
    title: 'Einfach versichert,<br />z.B. 200 € gespart<sup>y</sup>',
    subtitle:
      'mit bis zu <mark>3 beitragsfreien</mark> Versicherungsmonaten<sup>z</sup>',
    imageUrl: '/assets/promotionTiles/car-image.png',
    tileBackground: '#052962',
    titleColor: '#fff',
    subtitleMarkBackground: '#00CEA5',
    href: '/gebrauchtwagen?markerId=202203_TRY_BEFORE_BUY',
    trackingData: {
      value: '202203_TRY_BEFORE_BUY',
    },
  },
  {
    id: 4,
    title: '<mark>Unter 5 Liter</mark> im Verbrauch',
    subtitle: 'Ausgewählte reduzierte Kleinwagen mit <5L Verbrauch',
    imageUrl: '/assets/promotionTiles/car-image-6.png',
    tileBackground: '#C0E6FF',
    titleColor: '#303030',
    titleMarkBackground: '#FAC361',
    href:
      '/gebrauchtwagen?consumptionRange=0-5&category=kleinwagen&specialOffers=reducedPrice',
    trackingData: {
      value: 'under_five_liter_usage_campaign',
    },
  },
  {
    id: 5,
    title: 'Aufgeladen und runtergesetzt',
    subtitle: 'Alle <mark>reduzierten</mark> Elektro-Fahrzeuge',
    imageUrl: '/assets/promotionTiles/car-image-2.png',
    tileBackground: '#FAC361',
    titleColor: '#303030',
    subtitleMarkBackground: '#fff',
    href: '/gebrauchtwagen?fuelType=electric&specialOffers=reducedPrice',
    trackingData: {
      value: 'e_cars_campaign',
    },
  },
  {
    id: 6,
    title: 'Unsere <mark>0% Deals</mark>',
    subtitle: 'Alle Fahrzeuge mit einer 0% Finanzierung<sup>6</sup>',
    imageUrl: '/assets/promotionTiles/car-image-3.png',
    tileBackground: '#FF755D',
    titleColor: '#303030',
    titleMarkBackground: '#fff',
    href: '/gebrauchtwagen?effInterestRateRange=0-0',
    trackingData: {
      value: 'zero_percent_financing_campaign',
    },
  },
  {
    id: 7,
    title: 'Dach und Preise runter',
    subtitle: 'Unsere <mark>reduzierten Cabrios</mark>',
    imageUrl: '/assets/promotionTiles/car-image-8.png',
    tileBackground: '#00CEA5',
    titleColor: '#fff',
    subtitleMarkBackground: '#052962',
    href: '/gebrauchtwagen?category=cabrio&specialOffers=reducedPrice',
    trackingData: {
      value: 'reduced_cabrio_campaign',
    },
  },
  {
    id: 8,
    title: 'Große Wagen,<br />kleine Preise',
    subtitle: 'SUVs <mark>unter 30.000 €</mark>',
    imageUrl: '/assets/promotionTiles/car-image-7.png',
    tileBackground: '#C0E6FF',
    titleColor: '#303030',
    subtitleMarkBackground: '#FAC361',
    href:
      '/gebrauchtwagen?priceRange=7000-30000&category=suv&specialOffers=reducedPrice',
    trackingData: {
      value: 'lower_price_suv_campaign',
    },
  },
];

import {
  put,
  fork,
  all,
  debounce,
  take,
  select,
  call,
} from 'redux-saga/effects';
import { LOCATION_CHANGE, replace } from 'react-router-redux';

import { loginWithAuthorizationCode } from 'app/marketplace/accounts/actions';
import { InfoNotification } from 'app/shared/components/Notification/InfoNotification';
import { showNotification } from 'app/shared/modules/notifications/actions';
import {
  getAccessToken,
  getRefreshToken,
  getTimeOfTokenCreation,
  getTokenExpiresIn,
  getUserInfo,
} from 'app/marketplace/accounts/selectors';
import { userService } from 'app/marketplace/accounts/service';
import { tokenNearExpiry } from 'app/shared/utils/tokenNearExpiry';
import { to } from 'app/marketplace/accounts/helpers/to';
import { trackAction } from 'app/shared/utils/tracking';
import { ActionTypes } from 'app/marketplace/accounts/constants';
import { OPEN_ID_LOGIN_PATH } from 'app/marketplace/accounts/service.config';
import { BASE_URL } from 'app/config';

const errorInfo = (
  <InfoNotification
    type="error"
    title="Etwas ist schiefgelaufen bitte versuch es später nochmal"
  />
);

const successInfo = (
  <InfoNotification
    type="success"
    title="Vielen Dank! Du bist jetzt mit Deinem Verimi Account eingeloggt."
  />
);

export function* doLoginFromOpenIdRedirect({ payload: { pathname, query } }) {
  if (pathname === '/account/openIdLogin') {
    const { code } = query;
    yield put(
      loginWithAuthorizationCode({
        code,
        redirect_uri: `${BASE_URL}${OPEN_ID_LOGIN_PATH}`,
      }),
    );
    const { type } = yield take([
      ActionTypes.LOGIN_COMPLETED,
      ActionTypes.LOGIN_FAILED,
    ]);

    if (type === ActionTypes.LOGIN_FAILED) {
      yield put(showNotification(errorInfo));
    } else {
      yield put(showNotification(successInfo));
    }
    yield put(replace('/'));
  }
}

export function* refreshTokenSaga() {
  const refreshTokenFromStore = yield select(getRefreshToken);
  const tokenExpiresIn = yield select(getTokenExpiresIn);
  const tokenCreatedAt = yield select(getTimeOfTokenCreation);
  const tokenExpirationTime = new Date(tokenCreatedAt);
  const userInfo = yield select(getUserInfo);

  tokenExpirationTime.setSeconds(
    tokenExpirationTime.getSeconds() + tokenExpiresIn,
  );
  const isTokenNearExpiration = tokenNearExpiry(tokenExpirationTime);

  if (!isTokenNearExpiration) {
    return null;
  }

  const [error, token] = yield call(
    to,
    userService.getNewToken(refreshTokenFromStore),
  );
  if (error) {
    return yield put({ type: ActionTypes.LOGIN_FAILED, payload: error });
  }
  if (userInfo) {
    return yield put({
      type: ActionTypes.LOGIN_COMPLETED,
      payload: { token, user: userInfo },
    });
  }
  const user = yield call(to, userService.getUser(token));
  if (user?.length > 1) {
    yield call(trackAction, 'get_user_info_after_refresh_token', {
      category: 'user account',
      label: 'get_user_info_success',
      description: 'user info was received successfully',
    });
    return yield put({
      type: ActionTypes.LOGIN_COMPLETED,
      payload: { token, user: user[1] },
    });
  }
  yield call(trackAction, 'get_user_info_after_refresh_token', {
    category: 'user account',
    label: 'get_user_info_fail',
    description: 'user info was not received',
  });
  return yield put({
    type: ActionTypes.LOGIN_COMPLETED,
    payload: { token, user: null },
  });
}

export function* getProfileSaga() {
  yield put({ type: ActionTypes.GET_PROFILE_STARTED });
  yield call(trackAction, 'click_update_profile_button', {
    category: 'profile_form',
  });

  try {
    yield call(refreshTokenSaga);
    const accessToken = yield select(getAccessToken);
    const profile = yield call(userService.getUserProfile, accessToken);
    yield put({ type: ActionTypes.GET_PROFILE_COMPLETED, payload: profile });
  } catch (error) {
    yield put({ type: ActionTypes.GET_PROFILE_FAILED, payload: error });
  }
}

export function* updateProfileSaga({
  firstName,
  lastName,
  email,
  phoneNumber,
}) {
  yield put({ type: ActionTypes.UPDATE_PROFILE_STARTED });

  try {
    yield call(refreshTokenSaga);
    const accessToken = yield select(getAccessToken);
    yield call(
      userService.updateUserProfile,
      { firstName, lastName, email, phoneNumber },
      accessToken,
    );
    yield call(getProfileSaga);
    yield put({ type: ActionTypes.UPDATE_PROFILE_COMPLETED });
  } catch (error) {
    yield put({ type: ActionTypes.UPDATE_PROFILE_FAILED, payload: error });
    console.error(error);
  }
}

/**
 * when the user is redirected back to our page after using openId to log in, we need to handle the 'actual' login
 * on our end, meaning fetching the token from keycloak with a 'code' given in the query params by the redirect
 * @return {Generator<<"FORK", ForkEffectDescriptor>, void, ?>}
 */
export function* handleOpenidLogin() {
  yield debounce(20, LOCATION_CHANGE, doLoginFromOpenIdRedirect);
}

export function* accountSagas() {
  yield all([fork(handleOpenidLogin)]);
}

import { Heading7 } from 'app/shared/ui';
import { trackAction } from 'app/shared/utils/tracking';
import { LazyImage } from 'app/shared/components/LazyImage/LazyImage';

import {
  Container,
  Info,
  Title,
  InnerContainer,
  ButtonArea,
  ButtonAreaCol,
  Link,
} from './MobileAppFooter.css';

/**
 * @description After clicking Android Play store button
 * @export
 */
export function onAndroidLinkClick() {
  trackAction('footer_badge_click', {
    label: 'googleplay',
  });
}

/**
 * @description After clicking Apple store button
 * @export
 */
export function onAppStoreLinkClick() {
  trackAction('footer_badge_click', {
    label: 'applestore',
  });
}

/**
 * @description Footer Part for Mobile App Store Links
 */
export const MobileAppFooter = () => (
  <Container>
    <InnerContainer>
      <Title>
        <Heading7 as="span" mt={[40, 0]} fontWeight={500}>
          <span>
            Hol dir jetzt die neue heycar App und finde dein Traumauto mit
            Garantie – jederzeit und überall.
          </span>
        </Heading7>
      </Title>
      <Info as="div">
        <ButtonArea columnCount={2} noSplit autoWidth>
          <ButtonAreaCol>
            <Link
              href="https://heycar.page.link/aL7u"
              onClick={onAndroidLinkClick}
              target="_blank"
              rel="noopener noreferrer"
            >
              <LazyImage
                imageUrl="/assets/google-play-badge@3x.png"
                alt="Jetzt bei Google Play"
                width="135px"
                height="40px"
              />
            </Link>
          </ButtonAreaCol>
          <ButtonAreaCol>
            <Link
              href="https://heycar.page.link/vyJw"
              onClick={onAppStoreLinkClick}
              target="_blank"
              rel="noopener noreferrer"
            >
              <LazyImage
                imageUrl="/assets/app-store-badge@3x.png"
                alt="Laden im App Store"
                width="120px"
                height="40px"
              />
            </Link>
          </ButtonAreaCol>
        </ButtonArea>
      </Info>
    </InnerContainer>
  </Container>
);

MobileAppFooter.displayName = 'MobileappFooter';

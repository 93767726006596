/* eslint-disable react/jsx-props-no-spreading */
import { memo } from 'react';
import PropTypes from 'prop-types';

import { TilePill } from 'app/shared/ui';
import {
  CartileInfosPillsListSt,
  CartileInfosPillsReducedNoteSt,
  PromotionPillSt,
} from 'app/shared/modules/Cartile/CartileUIComponents/CartileInfosPills/CartileInfosPills.css';
import { getFuelConsumptionWithUnit } from 'app/marketplace/vehicle/utils';
import { MarkdownContainer } from 'app/marketplace/contentful/shared/MarkdownContainer/MarkdownContainer';
import { formatKm, formatNumber } from 'app/shared/utils/formatNumber';

const performanceUnitsIntl = {
  ps: 'PS',
  kw: 'kW',
};

const CartileInfosPillsCmp = ({
  km,
  fuel,
  date,
  gears,
  isD2C,
  isLoading,
  performance,
  isNewVehicle,
  emissionPerKm,
  performanceUnit,
  hasConsumptionPills,
  reducedValuePercentage,
  fuelConsumptionCombined,
  promotionPills,
  dealerFinancing,
  fullWidthPulls = false,
}) => {
  return (
    <>
      <PromotionPillSt>
        {promotionPills?.map((promotionPill) => {
          return (
            <MarkdownContainer
              key={promotionPill?.campaignId}
              content={promotionPill?.pillText}
              data-campaign-element="clp-pill-text"
              data-campaign-id={promotionPill?.campaignId}
            />
          );
        })}
      </PromotionPillSt>
      <CartileInfosPillsListSt as="ul">
        {reducedValuePercentage && !(promotionPills?.length > 0) && (
          <TilePill
            as="li"
            bg="oldFerrari"
            color="championshipWhite"
            fontWeight="500"
            isLoading={isLoading}
          >
            {reducedValuePercentage}
            &nbsp; reduziert <sup>a</sup>
          </TilePill>
        )}

        {isD2C && (
          <TilePill
            as="li"
            bg="sunbeanDark"
            color="tarmacGrey"
            fontWeight="bold"
            isLoading={isLoading}
            data-testid="CartileUI-Components-CartileInfosPills-Online-Kauf"
          >
            Online-Kauf
          </TilePill>
        )}

        {date && !isNewVehicle && (
          <TilePill as="li" isLoading={isLoading}>
            EZ {date}
          </TilePill>
        )}

        {performance > 0 && (
          <TilePill as="li" isLoading={isLoading}>
            {formatNumber(performance)}
            &nbsp;
            {performanceUnitsIntl[performanceUnit] ?? performanceUnit}
          </TilePill>
        )}

        {fuel && (
          <TilePill as="li" isLoading={isLoading}>
            {fuel}
          </TilePill>
        )}

        {gears && (
          <TilePill as="li" isLoading={isLoading}>
            {gears}
          </TilePill>
        )}

        {km >= 0 && (
          <TilePill as="li" isLoading={isLoading}>
            {formatKm(km)}
          </TilePill>
        )}
        {hasConsumptionPills && (
          <>
            {!!fuelConsumptionCombined && (
              <TilePill as="li" isLoading={isLoading}>
                {getFuelConsumptionWithUnit(
                  fuelConsumptionCombined,
                  fuel,
                  'stars',
                )}
              </TilePill>
            )}
            {!!emissionPerKm && (
              <TilePill as="li" isLoading={isLoading}>
                {emissionPerKm} g CO<sub>2</sub>/km (komb.)**
              </TilePill>
            )}
          </>
        )}
      </CartileInfosPillsListSt>
      {!fullWidthPulls ? (
        <CartileInfosPillsReducedNoteSt>
          {reducedValuePercentage && (
            <>
              <sup>a</sup> Rabatt ist im dargestellten Preis bereits enthalten.
            </>
          )}
        </CartileInfosPillsReducedNoteSt>
      ) : null}
    </>
  );
};

CartileInfosPillsCmp.propTypes = {
  fuel: PropTypes.string,
  isLoading: PropTypes.bool,
  km: PropTypes.number.isRequired,
  isD2C: PropTypes.bool.isRequired,
  date: PropTypes.string.isRequired,
  gears: PropTypes.string.isRequired,
  isNewVehicle: PropTypes.bool.isRequired,
  performance: PropTypes.number.isRequired,
  reducedValuePercentage: PropTypes.string,
  emissionPerKm: PropTypes.number.isRequired,
  performanceUnit: PropTypes.string.isRequired,
  hasConsumptionPills: PropTypes.bool.isRequired,
  fuelConsumptionCombined: PropTypes.number.isRequired,
  promotionPills: PropTypes.array,
  dealerFinancing: PropTypes.string,
  fullWidthPulls: PropTypes.bool,
  isDealerCashEligible: PropTypes.bool,
  hasDealerCashCampaign: PropTypes.bool,
};

CartileInfosPillsCmp.defaultProps = {
  fuel: '',
  isLoading: false,
  reducedValuePercentage: null,
  promotionPills: [],
  dealerFinancing: 'false',
  fullWidthPulls: false,
  isDealerCashEligible: false,
  hasDealerCashCampaign: false,
};

export const CartileInfosPills = memo(CartileInfosPillsCmp);

import { Children, cloneElement, useCallback } from 'react';
import PropTypes from 'prop-types';

import { Text1 } from 'app/shared/ui';
import { noop } from 'app/shared/utils/utils';
import { Overlay } from 'app/shared/components/Overlay/Overlay';
import { useStyledTheme } from 'app/shared/hooks/useStyledTheme';

import { withOverlay } from '../../OverlayRoot/withOverlay';

import {
  ModalBody,
  ModalHeader,
  ModalContent,
  ModalHeadline,
  ModalContainer,
  ModalCloseIcon,
  ModalCloseButton,
  BackButtonWrapperMobile,
  ModalSuccessNotificationSt,
  ModalSuccessIconSt,
} from './Modal.css';

const Modal = ({
  md,
  lg,
  xl,
  title,
  successMessage,
  children,
  headline,
  ariaLabel,
  closeOverlay,
  onBeforeClose,
  onAfterClose,
  maxHeight,
  backButton,
  showHeadlineOnMobile,
  showSuccessMessage,
  clickAndCollect,
  ...props
}) => {
  const closeModal = useCallback(() => {
    onBeforeClose();
    closeOverlay();
    onAfterClose();
  }, [onBeforeClose, closeOverlay, onAfterClose]);
  const { colors } = useStyledTheme();

  return (
    <ModalContainer aria-label={ariaLabel}>
      <Overlay show onClose={closeModal} useWhiteBg />
      <ModalContent width={[1, md, lg, xl]} maxHeight={maxHeight}>
        {showSuccessMessage ? (
          <ModalHeader
            hasTitleOrBackButton={showSuccessMessage}
            showSuccessMessage={showSuccessMessage}
            style={{
              backgroundColor: showSuccessMessage ? colors.mintSuperLight : '',
              position: clickAndCollect ? 'sticky' : 'relative',
            }}
          >
            <ModalSuccessNotificationSt m={['auto']}>
              <ModalSuccessIconSt icon="check" />
              <Text1
                as="div"
                fontSize={[12, 14]}
                lineHeight="normal"
                className="noticationText"
              >
                {successMessage}
              </Text1>
            </ModalSuccessNotificationSt>
            <ModalCloseButton aria-label="Close modal" onClick={closeModal}>
              <ModalCloseIcon className="if-icon-close" />
            </ModalCloseButton>
          </ModalHeader>
        ) : (
          <ModalHeader
            hasTitleOrBackButton={title || backButton}
            showSuccessMessage={showSuccessMessage}
            style={{
              position: clickAndCollect ? 'sticky' : 'relative',
            }}
          >
            <span data-testid="title">{title}</span>
            <span>{backButton}</span>
            <ModalCloseButton aria-label="Close modal" onClick={closeModal}>
              <ModalCloseIcon className="if-icon-close" />
            </ModalCloseButton>
          </ModalHeader>
        )}

        <ModalBody id="modal-body" {...props}>
          {backButton && (
            <BackButtonWrapperMobile pb={[3]}>
              {backButton}
            </BackButtonWrapperMobile>
          )}

          {headline && (
            <ModalHeadline
              useAnchor
              mb={32}
              textAlign="center"
              showHeadlineOnMobile={showHeadlineOnMobile}
              data-testid="headline"
            >
              {headline}
            </ModalHeadline>
          )}
          {Children.map(children, (child) =>
            cloneElement(child, {
              handleCloseModal: closeModal,
            }),
          )}
        </ModalBody>
      </ModalContent>
    </ModalContainer>
  );
};

Modal.propTypes = {
  md: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  lg: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  xl: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  title: PropTypes.node,
  headline: PropTypes.node,
  onBeforeClose: PropTypes.func,
  onAfterClose: PropTypes.func,
  children: PropTypes.any.isRequired,
  ariaLabel: PropTypes.string.isRequired,
  closeOverlay: PropTypes.func.isRequired,
  maxHeight: PropTypes.string,
  backButton: PropTypes.node,
  showHeadlineOnMobile: PropTypes.bool,
  successMessage: PropTypes.node,
  showSuccessMessage: PropTypes.bool,
  clickAndCollect: PropTypes.bool,
};

Modal.defaultProps = {
  md: 560,
  lg: 555,
  xl: 696,
  title: '',
  headline: '',
  onBeforeClose: noop,
  onAfterClose: noop,
  maxHeight: undefined,
  backButton: '',
  showHeadlineOnMobile: false,
  showSuccessMessage: false,
  successMessage: '',
  clickAndCollect: false,
};

export { Modal };
export const ConnectedModal = withOverlay(Modal);

/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-call */

import styled, { css } from 'styled-components';
import { Flex } from '@rebass/grid';

import { media } from '../../../variables/media';

export const NavFlyoutBackButtonWrapperSt = styled(Flex)`
  background-color: ${(props) => props.theme.colors.brandPrimaryLightest};
  color: ${(props) => props.theme.colors.brandSecondaryLight};
  cursor: pointer;
  ${media.lg(css`
    display: none;
  `)};
`;

export const NavFlyoutBackButtonLabelSt = styled.span`
  margin-left: 12px;
`;

import { IMAGE_SERVICE_URL } from 'app/config';
import { CartileImage } from 'app/shared/modules/Cartile/CartileUIComponents/CartileImages/CartileImage/CartileImage';
import { CartileImageLoading } from 'app/shared/modules/Cartile/CartileUIComponents/CartileImages/CartileImageLoading/CartileImageLoading';
import { CartileImageUnavailable } from 'app/shared/modules/Cartile/CartileUIComponents/CartileImages/CartileImageUnavailable/CartileImageUnavailable';

const WIDTH = 480;
const QUALITY = 90;
const DEFAULT_IMAGE_SRC = '/assets/specials/new-in.jpg';

export const createImageSource = ({ images, categories, make, model }) => {
  const hasImages = images.length > 0;
  const hasCategories = categories.length > 0;
  let image = '';

  if (hasImages) {
    const imageUrl = images[0].url;

    image = `${IMAGE_SERVICE_URL}/unsafe/${WIDTH}x/filters:quality(${QUALITY}):no_upscale()/${imageUrl}`;
  } else if (!hasImages && hasCategories) {
    image = `/assets/specials/${categories[0]}.svg`;
  }

  return {
    image,
    hasImages,
    alt: `${make.displayName} ${model.displayName} ${model.trim}`.trim(),
  };
};

export const CartileImageDefault = () => {
  return (
    <img
      src={DEFAULT_IMAGE_SRC}
      alt="Neues Auto"
      width="288px"
      height="194px"
    />
  );
};

export const getDataForCartileImage = ({
  isLazy,
  vehicle,
  isLoading,
  isHorizontal,
  isUnavailable,
  handleToggleFavourites,
  fetchpriority,
}) => {
  if (isLoading) {
    return {
      CartileImageContainerChildren: CartileImageLoading,
      CartileImageContainerChildrenProps: {},
    };
  }
  if (isUnavailable) {
    return {
      CartileImageContainerChildren: CartileImageUnavailable,
      CartileImageContainerChildrenProps: {
        isHorizontal,
        handleToggleFavourites,
      },
    };
  }

  const { image, alt, hasImages } = createImageSource(vehicle);
  if (hasImages) {
    return {
      CartileImageContainerChildren: CartileImage,
      CartileImageContainerChildrenProps: {
        image,
        alt,
        isLazy,
        fetchpriority,
      },
    };
  }

  return {
    CartileImageContainerChildren: CartileImageDefault,
    CartileImageContainerChildrenProps: {},
  };
};

import styled from 'styled-components';

const getBorderColor = ({ disabled, hasError, hasFocus, theme }) => {
  if (disabled) return theme.colors.gray;
  if (hasError) return theme.colors.brandDanger;
  if (hasFocus) return theme.colors.brandPrimary;
  return theme.colors.grayDark;
};

const getTextColor = ({ disabled, theme }) => {
  if (theme.rebranding) {
    return disabled ? theme.colors.grayLight : theme.colors.tarmacGrey;
  }
  return disabled ? theme.colors.gray : theme.colors.brandSecondary;
};

export const WrapperSt = styled.div`
  position: relative;
  margin-bottom: ${({ hasAddonText }) => (hasAddonText ? 12 : 16)}px;
  &::after {
    position: absolute;
    top: -25%;
    left: 9px;
    height: 30%;
    content: '${(props) => props.label || ''}';
    color: ${({ theme }) => theme.colors.grayDark};
    background-color: ${({ label, theme }) =>
      label ? theme.colors.white : theme.colors.transparent};
    letter-spacing: 0.5px;
    padding: 0 4px;
    font-size: 12px;
  }
`;

export const DropDownSt = styled.select`
  width: ${({ width }) => width};
  height: ${({ height }) => height};
  padding-right: 45px;
  padding-left: 15px;
  border: 1px solid ${getBorderColor};
  font-size: 14px;
  color: ${getTextColor};
  border-radius: 4px;
  background-color: ${({ theme }) => theme.colors.white};
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-image: ${({ disabled }) =>
    disabled
      ? 'url(/assets/select-arrow-disabled.png)'
      : 'url(/assets/select-arrow.png)'};
  background-position: calc(100% - 15px) 50%;
  background-repeat: no-repeat;
  background-size: 16px 8px;

  &::-ms-expand {
    display: none;
  }

  &::-ms-value {
    background: none; /* remove blue background on IE10/IE11 when selected */
  }
`;

export const MessageSt = styled.div`
  position: relative;
  width: 100%;
  padding-right: 12px;
`;

const TextSt = styled.span`
  height: 16px;
  font-size: 12px;
  line-height: 1.33;
`;

export const HelperTextSt = styled(TextSt)`
  padding-left: 12px;
  color: ${({ theme }) => theme.colors.grayDark};
`;

export const ErrorTextSt = styled(TextSt)`
  padding-left: 12px;
  color: ${({ theme }) => theme.colors.brandDanger};

  & > i {
    position: absolute;
    top: 12px;
    right: 0;
    display: inline-block;
    font-size: 14px;
  }
`;

import { apiCall, defaultHeader } from 'app/actions';
import {
  getCalculation,
  getCalculatorData,
} from 'app/shared/modules/FDL/CalculatorV3/redux/selector';
import { trackAction } from 'app/shared/utils/tracking';
import { getTrackingSettings } from 'app/shared/modules/FDL/utils';
import { checkFeatureFlagHasToken } from 'app/selectors';

import { prepareParameters } from './helpers/prepareParameters';
import {
  API_ERROR,
  API_LOADING,
  CHANGE_HAPPENED,
  RESET_IS_CHANGED,
  SET_DATA,
  SET_SELECTED_VALUE,
  UNSET_CALCULATION_FOR_VEHICLE,
  UPDATE_CALCULATIONS,
  SHOW_RESET_MESSAGE,
} from './constants';
import { getInitialVehicle } from './helpers/getInitialVehicle';

const trackingSettings = getTrackingSettings();

export const loading = () => ({ type: API_LOADING });

export const setErrorMessage = (message) => ({ type: API_ERROR, message });

export const setValue = (item, value, isUserInteraction = false) => ({
  type: SET_SELECTED_VALUE,
  item,
  value,
  isUserInteraction,
});
export const setCalculationChangedMessage = (value) => ({
  type: SHOW_RESET_MESSAGE,
  value,
});

/**
 * Applies the full calculator data
 * @param payload (Calculator API Response)
 * @param isUserInteraction Who wants to set the calculator date. User or Component?
 * @returns {{payload: *, type: string, isUserInteraction: boolean}}
 */
export const setData = (payload, isUserInteraction = false) => ({
  type: SET_DATA,
  payload,
  isUserInteraction,
});

// this is to fix an edge case where the 'financingCampaignId' and/or 'leasingCampaignId'
// is not included in specificData because of missing calculations on the BE side.
// Therefore it was requested that we manually include financingCampaignId in specificData for financing requests (the default)
// and 'leasingCampaignId' when requesting leasing calculations.
// for leasing we have to take the correct campaignId from vehicle.leasingPrecalc.specificData use it for both campaignId and leasingCampaignId
// But in case either 'financingCampaignId' or 'leasingCampaignId' were already included then we do nothing.
// also in case campaignId is not included then do nothing (for classifieds)
/* istanbul ignore next */
const campaignIdsTempFix = (apiRequest, vehicle) => {
  let isDefaultCalculationChanged = false;
  // creating a deep copy of apiRequest
  const newApiRequest = JSON.parse(JSON.stringify(apiRequest));
  // check for valid paramaters, we are checking before calling for the lesing calculation
  if (
    newApiRequest?.parameters &&
    newApiRequest?.parameters[0]?.value.toLowerCase().includes('leasing')
  ) {
    const parameters = newApiRequest?.parameters;
    // modify duration,mileage params before search if matches
    const updatedItems = parameters.map((parameter) => {
      const currentObj = vehicle?.leasingPrecalc?.inputs.find(
        (precal) => precal.id === parameter.id,
      );
      if (parameter.id === 'duration' || parameter.id === 'mileage') {
        // check if duration and mileage possible with leasingPrecalc
        const checkIfValidDuration = currentObj?.options.some(
          (obj) => obj.value === parameter.value,
        );
        const obj = currentObj?.options?.find((item) => item.selected);
        if (!checkIfValidDuration && obj) {
          parameter.value = obj.value;
          parameter.label = obj.label;
          isDefaultCalculationChanged = true;
        }
      }
      return parameter;
    });
    newApiRequest.parameters = updatedItems;
  }
  if (
    (!newApiRequest?.specificData?.campaignId &&
      !vehicle?.leasingPrecalc?.specificData?.campaignId) ||
    Boolean(newApiRequest?.specificData?.financingCampaignId) ||
    Boolean(newApiRequest?.specificData?.leasingCampaignId)
  ) {
    return { newApiRequest, isDefaultCalculationChanged };
  }

  if (newApiRequest?.parameters[0]?.value.toLowerCase().includes('leasing')) {
    newApiRequest.specificData.campaignId =
      vehicle?.leasingPrecalc?.specificData?.campaignId || '';

    newApiRequest.specificData.leasingCampaignId =
      vehicle?.leasingPrecalc?.specificData?.campaignId || '';

    return { newApiRequest, isDefaultCalculationChanged };
  }

  newApiRequest.specificData.financingCampaignId =
    newApiRequest?.specificData?.campaignId || '';
  return { newApiRequest, isDefaultCalculationChanged };
};

export const getData = (payload, isUserInteraction) => (dispatch, getState) => {
  dispatch(loading());
  const state = getState();
  const apiRequest = prepareParameters(getCalculatorData(state));

  const vehicle = getInitialVehicle(state, payload);

  // temporary fix requested by BE, will be removed later
  const {
    newApiRequest: apiRequestWithTempFix,
    isDefaultCalculationChanged,
  } = campaignIdsTempFix(apiRequest, vehicle);

  if (vehicle.live) {
    const isfinancingCommonFlagOn = checkFeatureFlagHasToken(
      state,
      282,
      'enabled',
    );
    return dispatch(
      apiCall(
        `/financing/${vehicle.id}/calculation/`,
        {
          method: 'POST',
          body: JSON.stringify(apiRequestWithTempFix),
          headers: {
            ...defaultHeader.headers,
            'X-Heycar-Tenant': 'de',
          },
        },
        true,
        isfinancingCommonFlagOn,
      ),
    )
      .then((response) => {
        if (isUserInteraction) {
          trackAction(
            trackingSettings.name,
            {
              category: trackingSettings.properties.category,
              label: 'user_get_newfinancialcalculation',
              value: response.rate,
            },
            trackingSettings.additionalProperties,
          );
        }

        dispatch(setCalculationChangedMessage(isDefaultCalculationChanged));

        return dispatch(setData(response, isUserInteraction));
      })
      .catch((error) => {
        return dispatch(setErrorMessage(error.message));
      });
  }
};

export const getDataBeforeCall = (callbackFn) => (dispatch, getState) => {
  const {
    fdlCalculator: { isChanged },
  } = getState();
  if (!isChanged) {
    return dispatch(getData(null, true)).then(() => {
      return callbackFn();
    });
  }
  return callbackFn();
};

/**
 * Update Calculation data for specific Vehicle
 *
 * @param {object} vehicle - Vehicle Info
 * @param {object} fdlCalculator - Calculation Info
 * @returns {function(*, *): void} Dispatch Action
 */
export function updateCalculation(vehicle, fdlCalculator) {
  const { id, price } = vehicle;
  return (dispatch, getState) => {
    const state = getState();
    const calculation = getCalculation(state, id) || {};
    const newCalculation = {
      ...calculation,
      result: fdlCalculator.result,
      request: prepareParameters(fdlCalculator),
      bid:
        vehicle.financingPrecalc?.searchFilters?.captiveId?.toLowerCase() || '',
      id,
      rId: fdlCalculator.requestId,
      currentPrice: price,
    };

    dispatch({
      type: UPDATE_CALCULATIONS,
      payload: newCalculation,
    });
  };
}

export function syncCalculationChanges() {
  return (dispatch, getState) => {
    const state = getState();

    if (state.fdlCalculator.isChanged) {
      dispatch(updateCalculation(state.vehicle.vehicle, state.fdlCalculator));
    }
  };
}

/**
 * Remove Calculation data for specific Vehicle
 *
 * @param {int} id - vehicle id (listingId)
 * @returns {{payload: {id: *}, type: string}} Redux Action
 */
export const unsetCalculationForVehicle = ({ id }) => {
  return {
    type: UNSET_CALCULATION_FOR_VEHICLE,
    payload: {
      id,
    },
  };
};

export function triggerFormChange() {
  return { type: CHANGE_HAPPENED };
}

export function resetIsChanged() {
  return { type: RESET_IS_CHANGED };
}

import styled, { css } from 'styled-components';

interface ILogoSt {
  x: number;
  y: number;
  size: number;
  scaleTo: number;
  adjustPosition: boolean;
  image: string;
  isVisible: boolean;
}

export const LogoSt = styled.div<ILogoSt>`
  background-position: ${({ x }) => x}px ${({ y }) => y}px;
  ${({ size }) => css`
    width: ${size}px;
    height: ${size}px;
  `};
  ${({ isVisible, image }) =>
    isVisible &&
    css`
      background-image: url('${image}');
    `}
  ${({ scaleTo, size, adjustPosition }) => {
    const scale = scaleTo / size;
    const translate = -((size - scaleTo) / 2);

    return `transform: scale3d(${scale}, ${scale}, 1) ${
      adjustPosition ? `translate3d(${translate}px, ${translate}px, 0)` : ''
    }`;
  }};
`;

export const WrapperSt = styled.div``;

export const MakesLogoImageSt = styled.img``;

import { makeLazy } from 'app/shared/components/LazyWrapper/LazyWrapper';

export const LazySaveSearchTipModal = makeLazy({
  getComponent: /* istanbul ignore next */ () => {
    return import(
      './SaveSearchTipModal' /* webpackChunkName: "SaveSearchTipModal" */
    );
  },
  exportName: 'SaveSearchTipModal',
  spinner: null,
});

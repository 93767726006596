import { memo } from 'react';
import PropTypes from 'prop-types';

import { CartileContentFooterContainerSt } from 'app/shared/modules/Cartile/CartileTemplates/CartileContentContainer/CartileContentFooter/CartileContentFooter.css';

const CartileContentFooterCmp = ({ children }) => {
  return (
    <CartileContentFooterContainerSt>
      {children}
    </CartileContentFooterContainerSt>
  );
};

CartileContentFooterCmp.propTypes = {
  children: PropTypes.node.isRequired,
};

export const CartileContentFooter = memo(CartileContentFooterCmp);

/* istanbul ignore file */
/* eslint-disable react/jsx-props-no-spreading */
import {
  GridProps,
  SpaceProps,
  ResponsiveValue,
  ThemeValue,
  RequiredTheme,
} from 'styled-system';

import { GridItemSt } from './index.css';

type ThemeType = RequiredTheme;
type TVal = ThemeValue<'space', ThemeType>;

export interface IExtendedGridProps extends SpaceProps, GridProps {
  columns?: ResponsiveValue<TVal, ThemeType>;
  columnStart?: ResponsiveValue<TVal, ThemeType>;
  children: React.ReactNode;
}

export const GridInnerBox = ({
  gridRow,
  columns,
  columnStart,
  children,
  ...styledProps
}: IExtendedGridProps): JSX.Element => (
  <GridItemSt
    gridRow={gridRow}
    columns={columns}
    columnStart={columnStart}
    {...styledProps}
  >
    {children}
  </GridItemSt>
);

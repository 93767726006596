import PropTypes from 'prop-types';

import { color } from 'app/shared/styles_js/variables';

export const CampaignDataShape = PropTypes.shape({
  primaryCampaign: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.oneOf([null]),
  ]),
  matchingCampaigns: PropTypes.array,
  primaryCampaignMarker: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.oneOf([null]),
  ]),
});

const vehicleMarker = {
  id: PropTypes.string,
  pdpCategory: PropTypes.string,
  type: PropTypes.string,
};

export const VehicleMarkerShape = PropTypes.shape(vehicleMarker);

export const VehicleShape = PropTypes.shape({
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  categories: PropTypes.arrayOf(PropTypes.string).isRequired,
  dataSource: PropTypes.string,
  make: PropTypes.shape({
    displayName: PropTypes.string,
  }).isRequired,
  model: PropTypes.shape({
    displayName: PropTypes.string,
    source: PropTypes.string,
  }).isRequired,
  dealer: PropTypes.shape({
    name: PropTypes.string,
    location: PropTypes.shape({
      city: PropTypes.string,
      zip: PropTypes.string,
    }),
  }).isRequired,
  price: PropTypes.number.isRequired,
  qualityCertificate: PropTypes.string,
  markers: PropTypes.arrayOf(VehicleMarkerShape),
});

export const locationShape = PropTypes.shape({
  action: PropTypes.string,
  hash: PropTypes.string,
  key: PropTypes.string,
  pathname: PropTypes.string,
  query: PropTypes.objectOf(PropTypes.string),
  search: PropTypes.string,
  state: PropTypes.string,
});

export const breakpointShape = PropTypes.oneOf(['sm', 'md', 'lg', 'xl']);
export const deviceTypeShape = PropTypes.oneOf(['mobile', 'tablet', 'desktop']);

export const colorsShape = PropTypes.oneOf(Object.keys(color));

export const flagShape = PropTypes.shape({
  flagToken: PropTypes.string.isRequired,
});

export const backdropsShape = PropTypes.oneOf([
  'championshipWhite',
  'heycarMint',
  'darkBlue',
  'micaBlue',
  'sunbeanLighter',
  'greySuperLight',
  'mintSuperLight',
  'sunbeanSuperLight',
  'sunbeanLighter',
]);

export const gamAdSizesShape = PropTypes.shape({
  sm: PropTypes.shape({
    width: PropTypes.number,
    height: PropTypes.number,
  }),
  md: PropTypes.shape({
    width: PropTypes.number,
    height: PropTypes.number,
  }),
  lg: PropTypes.shape({
    width: PropTypes.number,
    height: PropTypes.number,
  }),
  xl: PropTypes.shape({
    width: PropTypes.number,
    height: PropTypes.number,
  }),
});

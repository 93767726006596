import * as ActionTypes from 'app/marketplace/filter/constants';
import * as AppActionTypes from 'app/constants';
import { apiCall } from 'app/actions';
import {
  getFilterObject,
  getQueryFromFilters,
} from 'app/marketplace/filter/utils';

export const overrideFilters = (filters) => ({
  type: ActionTypes.OVERRIDE_FILTERS,
  payload: filters,
});

export const setFilters = (filters) => {
  return { type: ActionTypes.SET_FILTERS, payload: filters };
};

export const resetFilters = () => {
  return { type: ActionTypes.RESET_FILTERS };
};

export const resetSingleFilter = (filter) => {
  return { type: ActionTypes.RESET_SINGLE_FILTER, payload: filter };
};

export const resetFilterCategory = (categories) => {
  return { type: ActionTypes.RESET_FILTER_CATEGORY, payload: categories };
};

export const resetFiltersExceptCategory = (categories = []) => {
  return {
    type: ActionTypes.RESET_FILTERS_EXCEPT_CATEGORY,
    payload: categories,
  };
};

export const resetMakeModelVarianteFilter = (filter, idx) => ({
  type: ActionTypes.RESET_MAKEMODELVARIANTE_FILTER,
  payload: { filter, idx },
});

export const removeMultiModelFilter = (filter) => ({
  type: ActionTypes.UPDATE_MULTIMODEL_FILTER,
  payload: filter,
});

export const removeMultiTrimFilter = (filter) => ({
  type: ActionTypes.UPDATE_MULTITRIM_FILTER,
  payload: filter,
});

export const updatePossibleModels = (payload) => {
  return { type: ActionTypes.GET_MODELS_SUCCESS, payload };
};

export const updateAllMakes = (payload) => {
  return { type: ActionTypes.GET_ALL_MAKES_SUCCESS, payload };
};

export const updatePossibleMakes = (payload) => {
  return { type: ActionTypes.GET_MAKES_SUCCESS, payload };
};

export const updateAllUsedCarSeal = (payload) => {
  return { type: ActionTypes.GET_ALL_USEDCARSEAL_SUCCESS, payload };
};

export const updateFilters = (payload) => {
  return { type: ActionTypes.UPDATE_FILTERS, payload };
};

export const getMakes = () => {
  return (dispatch, getState) => {
    const filters = { ...getState().filter };

    filters.make = [];
    filters.model = [];
    const url = `/search/count?${encodeURI(
      getQueryFromFilters(filters, true),
    )}`;

    return dispatch(apiCall(url))
      .then((payload) => {
        dispatch({ type: ActionTypes.GET_MAKES_SUCCESS, payload });
      })
      .catch((error) => {
        dispatch({ type: ActionTypes.ERROR, error });
        dispatch({ type: AppActionTypes.SET_SHORT_CIRCUIT, payload: true });
      });
  };
};

export const getModels = (makeId) => {
  return (dispatch, getState) => {
    const filters = { ...getState().filter };

    filters.make = [
      getFilterObject({
        category: 'make',
        value: makeId,
      }),
    ];
    filters.model = [];
    const url = `/search/count?${encodeURI(
      getQueryFromFilters(filters, true),
    )}`;

    return dispatch(apiCall(url))
      .then((payload) => {
        dispatch({ type: ActionTypes.GET_MODELS_SUCCESS, payload });
      })
      .catch((error) => {
        dispatch({ type: ActionTypes.ERROR, error });
        dispatch({ type: AppActionTypes.SET_SHORT_CIRCUIT, payload: true });
      });
  };
};

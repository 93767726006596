/* istanbul ignore file */
import styled from 'styled-components';

import { Button } from 'app/shared/ui';

export const CallButtonSt = styled(Button)`
  box-sizing: border-box;

  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 8px 0px;
  margin-top: 16px;
  min-height: 32px;
  border: 1px solid #164ca3;
  border-radius: 4px;
  height: 32px;
  background: #ffffff;
  color: #052962;
  flex: none;
  order: 3;
  align-self: stretch;
  flex-grow: 0;
`;

import { PureComponent } from 'react';
import PropTypes from 'prop-types';

import { PopoverSt, itemHeight } from './SearchInputPopoverRenderer.css';

const KEY_UP = 38;
const KEY_DOWN = 40;
const KEY_ENTER = 13;

export class SearchInputPopoverRenderer extends PureComponent {
  constructor(props) {
    super(props);

    this.popoverReference = null;
    this.onNavigate = this.onNavigate.bind(this);
  }

  componentDidMount() {
    document.addEventListener('keydown', this.onNavigate);
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.onNavigate);
  }

  onNavigate(event) {
    const {
      onSelect,
      onFocus,
      focused,
      items,
      show,
      currentInputValue,
      selectFirstOnEnter,
    } = this.props;

    if (!show) {
      return false;
    }

    let index = focused;

    if (event.keyCode === KEY_DOWN) {
      event.preventDefault();
      index += 1;

      if (items[index]) {
        onFocus(index);
        this.popoverReference.scrollTop =
          (index ? index - 1 : index) * itemHeight;
      }
    } else if (event.keyCode === KEY_UP) {
      event.preventDefault();
      index -= 1;

      if (items[index]) {
        onFocus(index);
        this.popoverReference.scrollTop =
          (index ? index - 2 : index) * itemHeight;
      }
    } else if (event.keyCode === KEY_ENTER) {
      event.preventDefault();

      if (items[index]) {
        onSelect(items[focused], focused, event);
      } else if (selectFirstOnEnter) {
        onSelect(items[0], -1, event);
      } else {
        // select the value the user put in
        onSelect(
          { value: currentInputValue, label: currentInputValue },
          -1,
          event,
        );
      }
    }
  }

  render() {
    const {
      show,
      items,
      itemRenderer,
      onSelect,
      inputValueExtractor,
      focused,
      maxDisplayedItems,
      extraStyles,
      currentInputValue,
      hasError,
    } = this.props;
    const Items = itemRenderer;

    return (
      <PopoverSt
        isShown={(show && items.length) || hasError}
        hasError={hasError}
        maxDisplayedItems={maxDisplayedItems}
        extraStyles={extraStyles}
        ref={(e) => {
          this.popoverReference = e;
        }}
      >
        {hasError ? (
          'Ups, da ist etwas schief gelaufen. Bitte versuche es erneut.'
        ) : (
          <Items
            currentInputValue={currentInputValue}
            itemObjects={items}
            onSelect={onSelect}
            inputValueExtractor={inputValueExtractor}
            focused={focused}
          />
        )}
      </PopoverSt>
    );
  }
}

SearchInputPopoverRenderer.propTypes = {
  show: PropTypes.bool,
  items: PropTypes.arrayOf(PropTypes.object),
  itemRenderer: PropTypes.func.isRequired,
  onSelect: PropTypes.func.isRequired,
  onFocus: PropTypes.func.isRequired,
  focused: PropTypes.number,
  inputValueExtractor: PropTypes.func.isRequired,
  maxDisplayedItems: PropTypes.number,
  extraStyles: PropTypes.array,
  currentInputValue: PropTypes.string,
  selectFirstOnEnter: PropTypes.bool,
  hasError: PropTypes.bool,
};

SearchInputPopoverRenderer.defaultProps = {
  items: [],
  maxDisplayedItems: 8,
  extraStyles: [],
  currentInputValue: '',
  hasError: false,
};

/* istanbul ignore file */
import { gptSource } from 'server/third-party-scripts/scripts';

/**
 * GAM banners have a fixed width and height. That makes these banners look unresponsive.
 * Since we can not access to content of iframe element directly with the CSS, this is the
 * solution to make these banners responsive.
 *
 * @param {string} adUnitId The id of the ad unit
 */
const scaleGamBanner = (adUnitId) => {
  const adIframeElem = document?.querySelector(`div#${adUnitId} iframe`);
  const googleImageDivElem = adIframeElem?.contentDocument?.querySelector(
    'div#google_image_div',
  );
  if (googleImageDivElem?.style) googleImageDivElem.style.inset = 0;
  const iframeImgElem = googleImageDivElem?.querySelector('img');
  if (iframeImgElem?.style) {
    iframeImgElem.style.width = '100%';
    iframeImgElem.style.height = '100%';
  }
};
const scaleGamBannerCache = {};
export const cachedScaleGamBanner = (adUnitId) => {
  if (scaleGamBannerCache[adUnitId]) {
    return scaleGamBannerCache[adUnitId];
  }

  // eslint-disable-next-line no-return-assign
  return (scaleGamBannerCache[adUnitId] = scaleGamBanner(adUnitId));
};

export const appendGptScriptsToHead = async () => {
  if (typeof window !== 'undefined' && window?.document?.head) {
    try {
      // gpt source script
      const gptSourceScript = document.createElement('script');
      gptSourceScript.async = 'true';
      gptSourceScript.src = gptSource;
      document.head.appendChild(gptSourceScript);

      // googletag script
      const googletagScript = document.createElement('script');
      googletagScript.type = 'text/javascript';
      googletagScript.text = `
        window.googletag = window.googletag || { cmd: [] };
        googletag.cmd.push(function() {
          googletag.pubads().collapseEmptyDivs();
          googletag.pubads().enableSingleRequest();
          googletag.enableServices();
        });
      `;
      document.head.appendChild(googletagScript);

      // Just for the safety, to make sure gpt doesn't fail to server GAM banners
      await new Promise((resolve) => setTimeout(resolve, 500));
    } catch (error) {
      return null;
    }
  }

  return null;
};

import { CallModal } from 'app/marketplace/contactDealer/Call/CallModal/CallModal';
import { CallbackModal } from 'app/marketplace/contactDealer/Callback/CallbackModal/CallbackModal';
import { MailModal } from 'app/marketplace/contactDealer/Mail/MailModal/MailModal';
import { Sidebar } from 'app/marketplace/vehicle/Vehicle/Sidebar';
import { formatNumber } from 'app/shared/utils/formatNumber';
import {
  getAdditionalVehicleTrackingParams,
  trackAction,
} from 'app/shared/utils/tracking';
import { hasPrecalculatedFinance } from 'app/shared/utils/vehicle';

/**
 * @typedef {import('app/types/entities/Vehicle.types').IVehicle} IVehicle
 */

/**
 *
 * @param {IVehicle} vehicle
 * @param {{event: string, payload: Record<string, any>}[]} actions
 */
export const trackDealerContact = (vehicle, actions) => {
  actions.forEach((action) => {
    const defaultPayload = { category: null };
    const { event, payload = defaultPayload } = action;
    const {
      category,
      label,
      description,
      eventId,
      item,
      event_label,
      value,
    } = payload;

    trackAction(
      event,
      {
        category,
        label,
        description,
        value,
      },
      {
        sessionId: eventId,
        eventId,
        event_label,
        item,
        ...getAdditionalVehicleTrackingParams(vehicle),
      },
    );
  });
};

/**
 *
 * @param {{vehicle: IVehicle, location: {query: {dataSource: string}}}} properties
 * @param {string} target
 */
export const trackVehicleRendered = (properties, target) => {
  const { vehicle, location } = properties;
  const isD2CVehicle =
    vehicle.dataSource === 'GWC_D2C' ||
    location?.query?.dataSource === 'GWC_D2C';
  const captiveId = vehicle.financingPrecalc?.searchFilters?.captiveId;

  trackAction(
    isD2CVehicle ? 'd2c_pdp_loaded' : 'vehicle_rendered',
    {
      category: target,
      label: `${vehicle.id}`,
      value: vehicle.reducedPrice
        ? `${vehicle.price} reduced_price`
        : `${vehicle.price}`,
      description: isD2CVehicle
        ? 'D2C vehicle page is loaded'
        : 'vehicle is rendered to a user',
      nonInteraction: 1,
    },
    {
      nonInteraction: 1,
      content_ids: [`${vehicle.id}`],
      content_type: 'vehicle',
      isAvailable: vehicle.live,
      product_id: `${vehicle.id}`,
      makeId: `${vehicle.make.id}`,
      modelId: `${vehicle.model.id}`,
      vehicleCategory: `${vehicle.categories.join(', ')}`,
      price: `${vehicle.price}`,
      currency: 'EUR',
      listingId: `${vehicle.id}`,
      dcc:
        vehicle.showDcc /* istanbul ignore next */ &&
        !hasPrecalculatedFinance(vehicle),
      ...(captiveId && { captiveId }),
    },
  );
};

/**
 *
 * @param {{vehicle: IVehicle, location: {query: {dataSource: string}}}} properties
 * @param {string} target
 */
export const trackQuickViewVehicleRendered = (properties, target) => {
  const { vehicle, location } = properties;
  const isD2CVehicle =
    vehicle.dataSource === 'GWC_D2C' ||
    location?.query?.dataSource === 'GWC_D2C';
  const captiveId = vehicle.financingPrecalc?.searchFilters?.captiveId;

  trackAction(
    isD2CVehicle ? 'quickview_d2c_pdp_loaded' : 'quickview_vehicle_rendered',
    {
      category: target,
      label: `${vehicle.id}`,
      value: vehicle.reducedPrice
        ? `${vehicle.price} reduced_price`
        : `${vehicle.price}`,
      description: isD2CVehicle
        ? 'D2C QuickView vehicle page is loaded'
        : 'QuickView vehicle is rendered to a user',
      nonInteraction: 1,
    },
    {
      nonInteraction: 1,
      content_ids: [`${vehicle.id}`],
      content_type: 'vehicle',
      product_id: `${vehicle.id}`,
      makeId: `${vehicle.make.id}`,
      modelId: `${vehicle.model.id}`,
      vehicleCategory: `${vehicle.categories.join(', ')}`,
      price: `${vehicle.price}`,
      currency: 'EUR',
      listingId: `${vehicle.id}`,
      ...(captiveId && { captiveId }),
    },
  );
};

/**
 *
 * @param {boolean} autoTriggered
 * @param {import('app/types/entities/Vehicle.types').IVehicle} vehicle
 * @param {string} category
 * @param {string=} [eventLabel='']
 * @param {string=} source
 * @param {import('app/shared/modules/Campaign/Campaign.types').TCampaignTrackingArgs=} {campaignTrackingArgs | []}
 */
export const triggerTrackAction = (
  autoTriggered,
  vehicle,
  category = 'PDP',
  eventLabel,
  source = '',
  campaignTrackingArgs,
) => {
  let value = '';

  let useSourceAsEvent = false;

  if (source === 'pdp-fixed-header') {
    value = 'header';
  } else if (source === 'pdp-cta-block') {
    value = 'cta_block';
  } else if (source.includes('d2c')) {
    useSourceAsEvent = true;
  }

  const getEvent = () => {
    if (useSourceAsEvent) return source;
    if (autoTriggered) return 'open_contact_dealer_side_bar';
    return 'contact-dealer_button_click';
  };

  let label = `${vehicle.id}`;
  if (
    campaignTrackingArgs &&
    Array.isArray(campaignTrackingArgs) &&
    campaignTrackingArgs.length > 1
  ) {
    ({ label, value } = campaignTrackingArgs[1]);
  }
  trackDealerContact(vehicle, [
    {
      event: getEvent(),
      payload: {
        category,
        label,
        description:
          'user filled the form and really wants to contact the dealer',
        event_label: eventLabel,
        value,
      },
    },
  ]);
};

/**
 * @param {string} markDownToClean
 * @returns {string}
 */
export const cleanUpMarkdown = (markDownToClean) =>
  markDownToClean
    .replace(/(?:https?|ftp):\/\/[\n\S]+/gi, '')
    .replace(/&amp,?;?/g, '&')
    .replace(/&lt,?;?/g, '<')
    .replace(/&gt,?;?/g, '>')
    .replace(/&quot,?;?/g, '"')
    .replace(/&#039,?;?/g, "'") // eslint-disable-line quotes
    .replace(/\\/, ' \\ ')
    .replace(/\n-\s+/, '\n- ')
    .replace(/\s?\/\s?/, '')
    .replace(/(\*+)\s*(.*?)\s*(\*+)/, '$1$2$3')
    .replace(/:\s+\*/g, ':\\\\ *')
    .replace(/\|\|/g, '')
    .split('\\')
    .join('\n')
    .replace(/(?:www)[\n\S]+/gi, '')
    .replace(/\s?\[.*?\]\s?/gi, '')
    .replace(/----/g, '\n')
    .replace(/\*{4,}/g, '')
    .replace(/[?=]/g, '€')
    .substring(0, 7000)
    .trim();

/**
 * Modifies a vehicle before saved to the store and after it's received from
 * the backend.
 *
 * @param {IVehicle|{}} vehicle  The vehicle received from the backend
 */
export const sanitizeVehicle = (vehicle = {}) => {
  // leaving this function in place in case we need to sanitize data in the future
  return vehicle;
};

/**
 *
 * Define which menu functions to run per provided lead action
 * @param {object} param0
 * @param {string} param0.target
 * @param {Record<string, any>} param0.location
 * @param {Function} param0.openOverlay
 * @param {Function} param0.closeOverlay
 * @param {string} param0.callDealerEventId
 * @param {string|null=} param0.calculationId
 * @param {object} param0.vehicle A vehicle object
 * @param {Function} param0.signupForNewsletterWithConsent
 * @param {string} param0.action The lead creation action type
 * @param {boolean=} param0.shouldCallMLT trigger the action to fill out MLT store
 * @param {boolean} param0.autoTriggered If opening the sidebar with a query param
 * @param {TCampaignData|false} param0.campaignData [LEAD] Campaign data
 * @param {Record<string,any>|null} param0.additionalContentProps Sidebar/Modal component extra props
 * @param {Function} param0.handleMenuAction The function generated by this function
 * @param {Function} param0.triggerTracking A function to trigger contact-dealer tracking action
 * @param {boolean=} param0.shouldShowCampaignInfoModal Indicates it should open the campaign info modal (before the lead form)
 */
export const defineMenuAction = ({
  target,
  action,
  vehicle,
  location,
  openOverlay,
  closeOverlay,
  autoTriggered,
  triggerTracking,
  handleMenuAction,
  callDealerEventId,
  additionalContentProps,
  signupForNewsletterWithConsent,
  oneClickLeads,
  isFlag248OneClickLeadsShow,
  campaignData,
  calculationId, // TODO: Check this on PDP's VehiclePDP.tsx
  shouldCallMLT = false,
  shouldShowCampaignInfoModal = false,
  screenType,
}) => {
  const openModal = () => {
    const modals = {
      mail: MailModal,
      requestVideo: MailModal,
      call: CallModal,
      callback: CallbackModal,
    };

    const actionModal = modals[action];
    /* istanbul ignore next */
    if (!actionModal) return;
    openOverlay(actionModal, {
      props: {
        target,
        location,
        vehicle,
        isRequestVideo: action === 'requestVideo',
        openOverlayWithAction: handleMenuAction,
        loadMLT: shouldCallMLT,
        campaignData,
        isFlag248OneClickLeadsShow,
        oneClickLeads,
        shouldShowCampaignInfoModal,
        screenType,
        ...additionalContentProps,
      },
    });
  };

  const openSidebar = () => {
    openOverlay(Sidebar, {
      props: {
        target,
        action,
        vehicle,
        location,
        closeOverlay,
        calculationId,
        handleMenuAction,
        openOverlayWithAction: handleMenuAction,
        callDealerEventId,
        signupForNewsletterWithConsent,
        loadMLT: shouldCallMLT,
        ...additionalContentProps,
      },
    });
  };

  closeOverlay();

  switch (action) {
    case 'mail':
    case 'requestVideo':
      openModal();
      if (additionalContentProps?.eventLabel === 'dealer_section_pdp') {
        triggerTracking(
          autoTriggered,
          vehicle,
          target,
          additionalContentProps.eventLabel,
        );
      }
      break;
    case 'call':
    case 'callback':
      openModal();
      break;
    default:
      if (action === 'contactDealerChoice')
        triggerTracking(autoTriggered, vehicle, target);
      openSidebar();
  }
};

/**
 * @param {IVehicle} vehicle
 * @param {string} target
 */
export function onClickPrint(vehicle, target) {
  trackAction(
    'clicked_print_button',
    {
      category: target,
      label: `${vehicle.id}`,
      value: `${vehicle.price}`,
      description: 'user clicks print button on pdp',
    },
    {
      content_ids: [vehicle.id],
      content_type: 'vehicle',
      makeId: `${vehicle.make.id}`,
      modelId: `${vehicle.model.id}`,
      vehicleCategory: `${vehicle.categories.join(', ')}`,
      price: `${vehicle.price}`,
      listingId: `${vehicle.id}`,
      dealerId: `${vehicle.dealer.id}`,
    },
  );
  if (window.print) {
    window.print();
  }
}

export const checkVehicleReservedStatus = (vehicle) => {
  if (vehicle?.available) return vehicle?.available !== 'AVAILABLE';
  return false;
};

export const checkCarAvailability = (vehicle) => {
  return (
    !checkVehicleReservedStatus(vehicle) &&
    vehicle?.live &&
    vehicle?.price > 0 &&
    !['GWC', 'GWC_D2C'].includes(vehicle.dataSource)
  );
};

/**
 * @param {number} value
 * @param {string} fuelType
 * @param {string} combined
 * @returns {string}
 */
export const getFuelConsumptionWithUnit = (
  value,
  fuelType,
  combined,
  plugInHybridElectric = false,
) => {
  const combinedText = combined === 'stars' ? 'km (komb.)**' : 'km (komb.)';
  const isEletric = fuelType === 'electric' || fuelType === 'Elektro';

  if (plugInHybridElectric)
    return `${formatNumber(value)} kWh/100${combined ? combinedText : 'km'}`;

  return `${formatNumber(value)} ${isEletric ? 'kWh' : 'l'}/100${
    combined ? combinedText : 'km'
  }`;
};

/**
 * Generate a PDP route without sidebar parameters
 * @param {IVehicle} vehicle
 * @returns {string}
 */
export const getPdpRoute = (vehicle) =>
  `/vehicle/${vehicle?.id}?dealerId=${vehicle?.dealer?.id}`;

export const changeScrolltoTopButtonPosition = (
  scrollToTopBtn,
  leadCTAs,
  leadCTAsMobileBottom,
  fdlApplyButton,
  bmwCaptiveButton,
  isSticky,
) => {
  if (scrollToTopBtn && scrollToTopBtn.style) {
    scrollToTopBtn.style = 'bottom:16px;margin-right:13px;';

    if (!isSticky && leadCTAs && !fdlApplyButton) {
      scrollToTopBtn.style = 'bottom:16px;margin-right:13px;';
    }

    if (isSticky && leadCTAs && !fdlApplyButton) {
      scrollToTopBtn.style = 'bottom:85px;margin-right:13px;';
    }

    if (leadCTAs && fdlApplyButton) {
      scrollToTopBtn.style = 'bottom:124px;margin-right:13px;';
    }

    if (leadCTAs && fdlApplyButton && bmwCaptiveButton) {
      scrollToTopBtn.style = 'bottom:99px;margin-right:13px;';
    }

    if (leadCTAsMobileBottom) {
      scrollToTopBtn.style = 'bottom:78px;margin-right:13px;';
    }
  }
};

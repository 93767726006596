import React from 'react';
import { Box } from '@rebass/grid';

import { Text } from 'app/shared/ui';
import { ShowMore } from 'app/shared/components/ShowMore/ShowMore';

type DisclaimerProps = {
  disclaimer: JSX.Element | string;
  hasShowMoreFeature: boolean;
};

export const Disclaimer = ({
  disclaimer,
  hasShowMoreFeature,
}: DisclaimerProps): JSX.Element => {
  return (
    <Box mb={12}>
      {hasShowMoreFeature ? (
        <ShowMore lines={3} lineHeight={11} legalText onWhite showAll={false}>
          {({ textMoreLess }: { textMoreLess: string }) => (
            <Text
              lineHeight="11px"
              as="p"
              fontSize="8px"
              color="grayDark"
              m={0}
            >
              {disclaimer}
              {textMoreLess}
            </Text>
          )}
        </ShowMore>
      ) : (
        <Text lineHeight="11px" as="p" fontSize="8px" color="grayDark" m={0}>
          {disclaimer}
        </Text>
      )}
    </Box>
  );
};

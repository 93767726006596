import { createSelector } from 'reselect';
import _get from 'lodash.get';

export const contentfulSelector = (state) => {
  return state.contentful;
};

export const pathSelector = (state, props) => {
  return props.location.pathname;
};

export const contentfulLoadingSelector = createSelector(
  contentfulSelector,
  (contentful) => contentful.contentfulLoading,
);

export const contentfulNewsCategoryLoadingSelector = createSelector(
  contentfulSelector,
  (contentful) => contentful.newsByCategoryLoading,
);

export const magazineCategoriesSelector = createSelector(
  contentfulSelector,
  (contentful) => contentful.magazineCategories,
);

export const featuredArticlesSelector = createSelector(
  contentfulSelector,
  (contentful) => contentful.featuredArticles,
);

export const latestArticlesSelector = createSelector(
  contentfulSelector,
  (contentful) => contentful.latestArticles,
);

export const selectedNewsCategorySelector = createSelector(
  contentfulSelector,
  (contentful) => contentful.selectedNewsCategory,
);

export const newsByCategorySelector = createSelector(
  contentfulSelector,
  selectedNewsCategorySelector,
  (contentful, selectedCategory) => {
    return contentful.newsByCategory[selectedCategory];
  },
);

export const categorySelector = createSelector(pathSelector, (path) =>
  path.replace('/magazine/category/', ''),
);

export const articlesByCategorySelector = createSelector(
  contentfulSelector,
  categorySelector,
  (contentful, category) => contentful.articlesByCategory[category],
);

export const articlesByCategoryGroupsSelector = createSelector(
  articlesByCategorySelector,
  categorySelector,
  (articlesByCategory) => {
    const error = articlesByCategory && articlesByCategory.error;
    let articlesByCategoryGroupOne;
    let articlesByCategoryGroupTwo;
    let articlesByCategoryGroupThree;

    if (articlesByCategory && articlesByCategory.length > 0) {
      articlesByCategoryGroupOne = articlesByCategory.slice(0, 2);
      articlesByCategoryGroupTwo = articlesByCategory.slice(2, 8);
      articlesByCategoryGroupThree = articlesByCategory.slice(
        8,
        articlesByCategory.length,
      );
    }

    return {
      articlesByCategoryGroupOne,
      articlesByCategoryGroupTwo,
      articlesByCategoryGroupThree,
      error,
    };
  },
);

export const entrySelector = createSelector(
  contentfulSelector,
  pathSelector,
  (contentful, path) => {
    const article = contentful[path] && contentful[path][0];

    if (!article) {
      // will selct error object if there is no article array
      return contentful[path];
    }

    return article;
  },
);

export const relatedArticlesSelector = createSelector(
  contentfulSelector,
  (contentful) => contentful.relatedArticles,
);

export const articleCategorySelector = createSelector(
  entrySelector,
  (article) => _get(article, 'fields.articleCategory.fields.categoryNameSlug'),
);

export const relatedArticlesByCategorySelector = createSelector(
  relatedArticlesSelector,
  articleCategorySelector,
  (relatedArticles, category) => {
    return relatedArticles[category];
  },
);

export const homeSeoTextSelector = createSelector(
  contentfulSelector,
  (contentful) => contentful.homeSeoText,
);

export const homeTeaserCardLinkSelector = createSelector(
  contentfulSelector,
  (contentful) => contentful.homeTeaserCardLink,
);

export const seoLinksSelector = createSelector(
  contentfulSelector,
  (contentful) => contentful.seoLinks,
);

export const warrantySealDataSelector = createSelector(
  contentfulSelector,
  (contentful) => contentful.warrantySealData,
);

export const contentfulCampaignsSelector = createSelector(
  contentfulSelector,
  (contentful) => contentful.campaigns,
);

export const contentfulHomeSeoTextsSelector = createSelector(
  contentfulSelector,
  (contentful) => contentful.homeSeoTexts,
);

export const contentfulElectroArticlesSelector = createSelector(
  contentfulSelector,
  (contentful) => contentful.electricalArticles,
);

import styled, { css, keyframes } from 'styled-components';

import { media } from '../variables/media';

const transition = '150ms cubic-bezier(0.4, 0, 0.2, 1)'; // copied from material.io

const getValueColor = ({ disabled, theme: { rebranding, colors } }) => {
  if (disabled)
    return /* istanbul ignore next */ rebranding
      ? colors.grayLight
      : colors.grayDarker;
  return /* istanbul ignore next */ rebranding
    ? colors.tarmacGrey
    : colors.brandSecondary;
};

const getLabelColor = ({
  disabled,
  hasError,
  hasFocus,
  hasValue,
  hasFixedLabel,
  theme: { rebranding, colors },
}) => {
  if (disabled)
    return /* istanbul ignore next */ rebranding
      ? colors.grayLight
      : colors.gray;
  if (hasError)
    return /* istanbul ignore next */ rebranding
      ? colors.ferrariDark
      : colors.brandDanger;
  if (hasFocus) return colors.brandPrimary;
  if (hasValue || hasFixedLabel) return colors.grayDark;
  return /* istanbul ignore next */ rebranding
    ? colors.tarmacGrey
    : colors.brandSecondary;
};

const getBorderColor = ({
  disabled,
  hasError,
  hasFocus,
  theme: { rebranding, colors },
}) => {
  if (disabled)
    return /* istanbul ignore next */ rebranding
      ? colors.grayLight
      : colors.gray;
  if (hasError)
    return /* istanbul ignore next */ rebranding
      ? colors.ferrariDark
      : colors.brandDanger;
  if (hasFocus) return colors.brandPrimary;
  return colors.grayDark;
};

const getPaddingLeft = (props) => {
  // Each character (using our fonts) occupies 9.5 pixels in the screen;
  const prefixLengthInPx =
    props.prefix && props.prefix.replace(/\s/g, '').length * 9.5;

  if (props.isPhonePrefix) return prefixLengthInPx + 37;
  if (props.hasPrefix) return prefixLengthInPx + 24;
  return 12;
};

const slideDown = keyframes`
  from {
    margin-top: -16px;
    color: transparent;
  }

  to {
    margin-top: 0;
    color: ${({ theme }) => theme.colors.brandDanger};
  }
`;

export const InputBoxSt = styled.div`
  position: relative;
  padding-top: 6px;
  border: 0.1px solid transparent; /* margin collapse workaround */
  line-height: 2; /* overrides body as it changes based on resolution and we don't */
`;

export const InputSt = styled.input`
  display: block;
  width: 100%;
  max-width: 100%;
  height: ${(props) => (!props.rows ? '48px' : 'auto')}; /* IE11 */
  min-height: 48px;
  padding: 8px 12px;
  padding-right: ${(props) => props.hasSuffix && '30px'};
  padding-left: ${getPaddingLeft}px;
  border: none;
  border-radius: 4px;
  background: none;
  resize: ${({ resize }) => resize || 'none'};

  appearance: none; /* fixes iOS inner shadow issue */

  font-size: 14px;
  text-align: ${(props) => props.isRightAligned && 'right'};
  color: ${getValueColor};
  caret-color: ${
    /* istanbul ignore next */ ({ theme: { rebranding, colors } }) =>
      rebranding ? colors.tarmacGrey : colors.brandSecondary
  };
  transition: border ${transition};

  ${({ hasPrefix }) =>
    hasPrefix &&
    css`
      ${media.lg(css`
        padding: 9px 9px;
        padding-left: ${getPaddingLeft}px;
      `)};
      ${media.xl(css`
        padding: 9px 5px;
        padding-left: 32px;
      `)};
    `}

  &:focus ~ fieldset {
    border: 1px solid ${getBorderColor};
  }

  &::placeholder {
    color: ${
      /* istanbul ignore next */ ({ theme: { rebranding, colors } }) =>
        rebranding ? colors.grayLight : colors.grayDark
    };
  }

  &:active {
    background-color: transparent; /* IE11 */
    background-color: unset; /* unset firefox defaults */
  }

  &:disabled {
    background-color: transparent; /* IE11 */
    background-color: unset; /* unset firefox defaults */
    opacity: 1; /* iOS specific fix */
    -webkit-text-fill-color: currentcolor; /* iOS specific fix */
  }

  &:-moz-ui-invalid {
    box-shadow: unset; /* unset firefox defaults */
  }

  &[type='number']::-webkit-inner-spin-button,
  &[type='number']::-webkit-outer-spin-button {
    margin: 0;
    -webkit-appearance: none;
  }

  &[type='number'] {
    -moz-appearance: textfield;
  }

  input::-ms-clear,
  input::-ms-reveal {
    display: none;
  }

  &::-webkit-credentials-auto-fill-button {
    margin-right: 32px; /* fix safari password icon overlap */
  }
  /* fix autofill styles for unfocused form inputs (full form autocomplete use case) */
  &:-webkit-autofill:not(:focus) {
    ~ label {
      font-size: 12px;
      transform: translate3d(8px, -6px, 0);
      color: ${({ theme }) => theme.colors.grayDarker};
    }

    ~ fieldset > legend {
      max-width: none;
      padding: 0 4px;
    }
  }
  /* fix hidden e-mail field value for logged in users on Safari(v14) */
  @media not all and (min-resolution: 0.001dpcm) {
    @supports (-webkit-appearance: none) {
      ${({ disabled }) =>
        disabled &&
        css`
          &:disabled {
            color: ${({ theme: { colors } }) => colors.grayDarker};
          }
        `}
    }
  }
`;

export const LabelSt = styled.label`
  position: ${({ isLabelBelowInput }) =>
    isLabelBelowInput ? 'static' : 'absolute'};
  top: 0;
  right: 0;
  left: 0;
  padding: 0 4px;
  letter-spacing: 0.5px;
  pointer-events: none;
  transform: translate3d(8px, 16px, 0);
  transition: transform ${transition};
  will-change: transform, color, font-size;
  font-size: 14px;
  color: ${getLabelColor};

  ${(props) =>
    props.hasPrefix &&
    css`
      transform: translate3d(${getPaddingLeft(props)}px, 16px, 0);
    `}

  ${(props) =>
    (props.hasFocus || props.hasValue || props.hasFixedLabel) &&
    css`
      font-size: 12px;
      transform: translate3d(8px, -6px, 0);
    `}
  /* ok, this looks weird, but we need to overlay the parents border
    without applying a background color as this messes with the
    background color chrome sets when autofilling data
  */
  &::after {
    position: absolute;
    z-index: -1;
    top: 0;
    right: 0;
    left: 0;
    height: 14px;
    content: '';
  }

  &:empty {
    padding: 0;
  }
`;

const AddonSt = styled.span`
  position: absolute;
  top: 16px;
  font-size: 14px;
  color: ${
    /* istanbul ignore next */ ({ theme: { rebranding, colors } }) =>
      rebranding && colors.tarmacGrey
  };
`;

export const PrefixSt = styled(AddonSt)`
  ${(props) =>
    props.isPhonePrefix
      ? css`
          color: ${({ theme: { rebranding, colors } }) =>
            /* istanbul ignore next */ rebranding
              ? colors.tarmacGrey
              : colors.grayDark};
          left: 12px;

          &::after {
            position: absolute;
            content: '|';
            margin: 0 12px;
          }
        `
      : css`
          left: 12px;
        `}

  ${media.xl(css`
    left: 6px;
  `)}
`;

export const SuffixSt = styled(AddonSt)`
  right: 12px;
`;

export const HelperTextSt = styled.div`
  padding-left: 12px;
  font-size: 12px;
  line-height: 1.6;
  color: ${({ theme }) => theme.colors.grayDark};
  ${({ rightAlign }) => rightAlign && 'text-align: right;'}
`;

export const ErrorTextSt = styled.div`
  padding-left: 12px;
  font-size: 12px;
  line-height: 1.6;
  color: ${
    /* istanbul ignore next */ ({ theme: { rebranding, colors } }) =>
      rebranding ? colors.ferrariDark : colors.brandDanger
  };
  animation: ${slideDown} 0.3s ease-in-out;
`;

export const TrailingIconSt = styled.span`
  position: absolute;
  top: 6px;
  right: 0;
  display: flex;
  height: 48px;
  align-items: center;
  padding: 0 12px; /* provide more clickable area */
  cursor: pointer;
  color: ${
    /* istanbul ignore next */ ({ theme: { rebranding, colors } }) =>
      rebranding && colors.tarmacGrey
  };
`;

/*
 * Fieldset used for material floating labels
 */
export const FieldsetSt = styled.fieldset`
  position: absolute;
  top: 5px;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  min-height: 48px;
  padding: 0 0 0 7px;
  border: 1px solid ${getBorderColor};
  margin: 0;
  background: none;
  border-radius: 4px;
  pointer-events: none;
  transition: border-color ${transition};

  &:disabled {
    border-color: ${({ theme }) => theme.colors.gray};
    color: ${({ theme }) => theme.colors.gray};
  }
`;

export const Legend = styled.legend`
  position: relative;
  display: block;
  width: auto;
  max-width: ${({ isFocused }) => (isFocused ? 'auto' : '0.01px')};
  height: 2px;
  padding: ${({ isFocused }) => (isFocused ? '0 4px' : '0')};
  letter-spacing: 0.5px;
  line-height: 0;
  color: transparent;
  font-size: 12px;
  transition: opacity ${transition};

  user-select: none;
`;

export const OuterBoxSt = styled.div`
  position: relative;
  margin-bottom: ${({ hasAddonText }) => (hasAddonText ? 12 : 16)}px;

  ${({ size }) =>
    size === 'small' &&
    css`
      ${InputSt}, ${FieldsetSt} {
        height: 40px;
        min-height: 40px;
      }
      ${AddonSt} , ${SuffixSt}, ${PrefixSt} {
        top: 12px;
        right: 8px;
        pointer-events: none;
      }
    `}
`;

/**
 * Hidden input only "visually" for using it as a mask to make disabling autofill work.
 * Ref. 1: https://snook.ca/archives/html_and_css/hiding-content-for-accessibility
 * Ref. 2: https://css-tricks.com/places-its-tempting-to-use-display-none-but-dont/
 */
export const InputMask = styled.input`
  position: absolute !important;
  z-index: -1;
  top: -9999px !important;
  left: -9999px !important;
  overflow: hidden;
  width: 0;
  height: 0;
  margin: -1px;
  clip: rect(0, 0, 0, 0);
`;

import * as ActionTypes from './constants';

const defaultState = () => {
  return {
    loadingNextComponent: false,
  };
};

export const pageTemplate = (state = defaultState(), action) => {
  const { type, payload } = action;

  if (type === ActionTypes.IS_LOADING_NEXT) {
    return Object.assign({}, state, {
      loadingNextComponent: payload,
    });
  }

  return state;
};

import styled, { css } from 'styled-components';
import { Box } from '@rebass/grid';

import { generateContainerWidth } from '../../utils/generateContainerWidth';
import { media } from '../variables/media';
import { grid } from '../variables/spacings';
import { TBreakpoint } from '../variables/index.types';

export interface IGridContainerProps {
  hasDefaultWidth?: boolean;
  fluidBreakpoints?: TBreakpoint[];
}

export const GridContainerSt = styled(Box)<IGridContainerProps>`
  display: grid;
  margin-right: auto;
  margin-left: auto;
  /* web-app's default container's max-width + margins */
  ${({ hasDefaultWidth, fluidBreakpoints = [] }) =>
    hasDefaultWidth && generateContainerWidth(fluidBreakpoints)}

  ${media.md(css`
    grid-template-columns: repeat(12, 1fr);
    column-gap: ${grid.gutter.md * 2}px;
  `)}
`;

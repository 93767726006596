import { useEffect, useMemo, useState } from 'react';

import { formatNumber } from 'app/shared/utils/formatNumber';
import { getFilterObject } from 'app/marketplace/filter/utils';

const category = 'make';

const preferredMakesShort = [
  'vw',
  'mercedes-benz',
  'audi',
  'bmw',
  'renault',
  'nissan',
];

const preferredMakesLong = [
  { label: 'Volkswagen', value: 'vw' },
  { label: 'Mercedes-Benz', value: 'mercedes-benz' },
  { label: 'Audi', value: 'audi' },
  { label: 'BMW', value: 'bmw' },
  { label: 'Renault', value: 'renault' },
  { label: 'Nissan', value: 'nissan' },
  { label: '------------', value: '-', disabled: true },
];

export const useMakeFilter = ({
  hasFilters,
  makeFilter,
  modelFilter,
  possibleMakes,
  onChangeFilter,
  handleTracking,
  hasHighlightedMakesList,
  isFlag274DynamicFiltersEnabled = false,
}) => {
  const [selectedMake, setSelectedMake] = useState('all');

  useEffect(() => {
    if (makeFilter.length > 0) {
      setSelectedMake(makeFilter[0].value);
    } else {
      setSelectedMake('all');
    }
  }, [makeFilter]);

  const makesOptions = useMemo(() => {
    if (hasHighlightedMakesList) {
      const filteredMakesOptions = possibleMakes
        .map(
          /* istanbul ignore next */ (item) => {
            const formattedItemCount = item.count != null && item.count !== '' 
                ? ` (${formatNumber(item.count)})`
                : ' (0)';
            return {
              label: `${item.displayName}${
                isFlag274DynamicFiltersEnabled
                    ? formattedItemCount
                    : ''
              }`,
              value: item.key,
            };
          },
        )
        .filter(
          /* istanbul ignore next */
          (x) => !preferredMakesShort.includes(x.value),
        );
      const updatedFilterPanelPreferredMakesList = isFlag274DynamicFiltersEnabled
        ? preferredMakesLong.map((item) => {
            const count = possibleMakes.find(
              (possibleMake) => possibleMake.key === item.value,
            )?.count;
            const formattedCount = count != null && count !== ''
              ? formatNumber(count)
              : '0';
            return {
              ...item,
              label: `${item.label}${!item.disabled ? ` (${formattedCount})` : ''}`,
              disabled: formattedCount === '0' ? true : false,
            };
          })
        : preferredMakesLong;

      if (!hasFilters || (modelFilter && modelFilter.length > 0)) {
        filteredMakesOptions.unshift(
          { label: 'Marke', value: 'all' },
          ...updatedFilterPanelPreferredMakesList,
        );
      } else {
        filteredMakesOptions.unshift(
          { label: 'Alle Marken', value: 'all' },
          ...updatedFilterPanelPreferredMakesList,
        );
      }
      if (
        selectedMake &&
        makeFilter[0] &&
        !filteredMakesOptions.some((item) => item.value === selectedMake)
      ) {
        /* istanbul ignore next */
        filteredMakesOptions.splice(1, 0, {
          label: makeFilter[0].label,
          value: selectedMake,
        });
      }
      return filteredMakesOptions;
    }
    const filteredMakesOptions = possibleMakes.map(
      /* istanbul ignore next */ (item) => {
        const listingsCount =
          item.count != null && item.count !== ''
            ? formatNumber(item.count)
            : '0';

        return {
          label: isFlag274DynamicFiltersEnabled
            ? `${item.displayName}${listingsCount}`
            : item.displayName,
          value: item.key,
        };
      },
    );

    if (!hasFilters || (modelFilter && modelFilter.length > 0)) {
      filteredMakesOptions.unshift({ label: 'Marke', value: 'all' });
    } else {
      filteredMakesOptions.unshift({ label: 'Alle Marken', value: 'all' });
    }
    if (
      selectedMake &&
      makeFilter[0] &&
      !filteredMakesOptions.some((item) => item.value === selectedMake)
    ) {
      filteredMakesOptions.splice(1, 0, {
        label: makeFilter[0].label,
        value: selectedMake,
      });
    }
    return filteredMakesOptions;
  }, [possibleMakes, hasFilters]);

  const handleSelectedMake = (e) => {
    const { value } = e.currentTarget;
    const idx = e.nativeEvent.target.selectedIndex;
    const label = e.nativeEvent.target?.[idx]?.text;

    setSelectedMake(value);
    if (handleTracking) {
      handleTracking(category);
    }
    const makeFilterObject = [];
    const modelFilterObject = [];
    const trimFilterObject = [];

    if (value !== 'all') {
      makeFilterObject.push(
        getFilterObject({
          label,
          value,
          category,
        }),
      );
      modelFilterObject.push(
        getFilterObject({
          label: '',
          value: `${value}_all`,
          category: 'model',
        }),
      );
      trimFilterObject.push(
        getFilterObject({
          label: '',
          value: `${value}_all_all`,
          category: 'trim',
        }),
      );
    }
    return onChangeFilter(
      {
        make: makeFilterObject,
        model: modelFilterObject,
        trim: trimFilterObject,
      },
      true,
      true,
      false,
    );
  };
  return [selectedMake, makesOptions, handleSelectedMake];
};

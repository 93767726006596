import { createSelector } from 'reselect';

import { contentfulCampaignsSelector } from 'app/marketplace/contentful/selectors';
import { getCampaignData } from 'app/shared/modules/Campaign/utils';
import { isDealerCashVehicle } from 'app/shared/utils/vehicle';

/* istanbul ignore next */
const isDealerCashEligibleSelector = (state: TRootState) => {
  /* istanbul ignore next */
  return isDealerCashVehicle(
    state.vehicle?.vehicle?.id
      ? state.vehicle.vehicle
      : state.checkoutVehicle?.vehicle,
  );
};

export const markersSelector = (state: TRootState) => {
  return state.vehicle?.vehicle?.id
    ? state.vehicle.vehicle.markers
    : /* istanbul ignore next */ state.checkoutVehicle?.vehicle?.markers;
};

export const selectCampaignData = createSelector(
  contentfulCampaignsSelector,
  markersSelector,
  isDealerCashEligibleSelector,
  getCampaignData,
);

import { DealerProfileActionTypes } from './constants';
import { getDealerProfileData } from './selectors';

/**
 * @typedef {Object} GeoCoordinates
 * @property {number} lon
 * @property {number} lat
 */

/**
 * @typedef {Object} Location
 * @property {string} city
 * @property {string} country
 * @property {GeoCoordinates} geoCoordinates
 * @property {string} houseNumber
 * @property {string} street
 * @property {string} streetName
 * @property {string} zip
 */

/**
 * @typedef {Object} LatestContractSignature
 * @property {Date} createdAt
 * @property {string} id
 */

/**
 * @typedef {Object} Network
 * @property {boolean} active
 * @property {Date} createdAt
 * @property {boolean} deleted
 * @property {string} guaranteeType
 * @property {string} id
 * @property {Date} lastUpdatedAt
 * @property {'ALL'|'PHONE'|'EMAIL'|'NO_LEADS'} leadPlan
 * @property {string} name
 */

/**
 * @typedef {Object} OpeningTime
 * @property {string} [openFrom]
 * @property {number} dayOfWeek
 * @property {string} [openTo]
 */

/**
 * @typedef {Object} Dealer
 * @property {boolean} active
 * @property {string} agentName
 * @property {Date} createdAt
 * @property {boolean} deleted
 * @property {number} easybillCustomerID
 * @property {string} fdlConditions
 * @property {string} id
 * @property {string} imprint
 * @property {Date} lastUpdatedAt
 * @property {LatestContractSignature} latestContractSignature
 * @property {string} leadFormat
 * @property {'ALL'|'PHONE'|'EMAIL'|'NO_LEADS'} leadPlan
 * @property {Location} location
 * @property {string} name
 * @property {Network} network
 * @property {OpeningTime[]} openingTimes
 * @property {string} profilePageUrl
 * @property {boolean} receivePhoneLead
 * @property {boolean} receiveVideoLead
 * @property {string} shortId
 * @property {boolean} testDealer
 * @property {string} tsp
 */

/**
 * @typedef {Object} ProfileData
 * @property {Object[]} vehicles    - array of vehicles
 * @property {string} mobileId      - identify for dealer
 * @property {Dealer} dealer        - dealer information
 * @property {number} totalElements - number of vehicles in api response
 */

/**
 * @typedef {Object} DealerProfileState
 * @property {boolean} loading
 * @property {any} httpError
 * @property {ProfileData} profileData
 */

/**
 * @returns {DealerProfileState}
 */
export const defaultState = () => ({
  loading: true,
  httpError: null,
  profileData: getDealerProfileData(),
});

/**
 * @param {DealerProfileState} state
 * @param {{type: string, [extraProps: string]?: any}} action
 * @returns {DealerProfileState}
 */
export const dealerProfile = (state = defaultState(), action) => {
  switch (action.type) {
    case DealerProfileActionTypes.LOADING:
      return {
        ...state,
        loading: true,
      };
    case DealerProfileActionTypes.SUCCESS:
      return {
        ...state,
        loading: false,
        profileData: getDealerProfileData(action.payload),
      };
    case DealerProfileActionTypes.FAILURE:
      return {
        ...state,
        loading: false,
        httpError: action.payload,
      };
    default:
      return state;
  }
};

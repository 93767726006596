import {
  ORDER_LEASING_ADD_VALUES,
  ORDER_POST,
  ORDER_POST_ERROR,
  ORDER_POST_SUCCESS,
  ORDER_REDIRECT_URL_POST,
  ORDER_REDIRECT_URL_POST_ERROR,
  ORDER_REDIRECT_URL_POST_SUCCESS,
} from '../../constants';

export const initialState = {
  orderId: undefined,
  redirectUrl: undefined,
  isRedirectLoading: false,
  isLoading: false,
  consent: {
    marketingConsent: false,
    tuvConsent: false,
    legalConsent: false,
  },
  error: undefined,
  emailErrorData: undefined,
  isOrderError: false,
};

export function leasing(state = initialState, action) {
  const { type, payload, error } = action;

  switch (type) {
    case ORDER_POST:
      return {
        ...state,
        isLoading: true,
        error: undefined,
      };
    case ORDER_POST_SUCCESS:
      return { ...state, ...payload, isLoading: false };
    case ORDER_POST_ERROR:
      return {
        ...state,
        isLoading: false,
        error: { ...payload, isOrderError: true },
        emailErrorData: { ...error, isOrderError: true },
      };
    case ORDER_REDIRECT_URL_POST:
      return { ...state, isRedirectLoading: true, error: undefined };
    case ORDER_REDIRECT_URL_POST_SUCCESS:
      return { ...state, ...payload, isRedirectLoading: false };
    case ORDER_REDIRECT_URL_POST_ERROR:
      return {
        ...state,
        isRedirectLoading: false,
        error: { ...payload, isRedirectUrlError: true },
      };
    case ORDER_LEASING_ADD_VALUES:
      return { ...state, ...payload };
    default:
      return state;
  }
}

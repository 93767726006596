type NumberStyle = 'short' | 'long' | 'dynamic';

function getOptions(
  formatStyle: NumberStyle,
  defaults?: Intl.NumberFormatOptions,
): Intl.NumberFormatOptions {
  const options: Intl.NumberFormatOptions = defaults ?? {};

  if (formatStyle === 'short') {
    options.maximumFractionDigits = 0;
  }

  if (formatStyle === 'long') {
    options.minimumFractionDigits = 2;
  }

  if (formatStyle === 'dynamic') {
    options.maximumFractionDigits = 2;
  }

  return options;
}

/**
 * short – never show cents
 *   123    -> "123 €"
 *   123.4  -> "123 €"
 *   123.45 -> "123 €"
 * long – always show cents
 *   123    -> "123,00 €"
 *   123.4  -> "123,40 €"
 *   123.45 -> "123,45 €"
 * dynamic – only show cents if there are some
 *   123    -> "123 €"
 *   123.4  -> "123,40 €"
 *   123.45 -> "123,45 €"
 */
export function formatCurrency(
  value: number | null | undefined,
  style: NumberStyle = 'dynamic',
): string {
  if (value == null) {
    return '–\u00A0€'; // NOTE be aware that the function returns this string if no `value` is passed
  }

  const options = getOptions(style, {
    style: 'currency',
    currency: 'EUR',
    minimumFractionDigits: 0,
  });

  if (style === 'dynamic' && value % 1 !== 0) {
    options.minimumFractionDigits = 2;
  }

  return new Intl.NumberFormat('de-DE', options).format(value);
}

/**
 * short – never show fraction
 *   123     -> "123"
 *   123.4   -> "123"
 *   123.45  -> "123"
 *   123.456 -> "123"
 * long – show full fraction
 *   123     -> "123"
 *   123.4   -> "123,4"
 *   123.45  -> "123,45"
 *   123.456 -> "123,456"
 * dynamic – only show fraction if there is some but max 2
 *   123     -> "123"
 *   123.4   -> "123,4"
 *   123.45  -> "123,45"
 *   123.456 -> "123,45"
 */
export function formatNumber(
  value: number,
  style: NumberStyle = 'dynamic',
): string {
  const options = getOptions(style);
  return new Intl.NumberFormat('de-DE', options).format(value);
}

/**
 * for styles see `formatNumber`
 */
export function formatPercent(
  value: number,
  style: NumberStyle = 'dynamic',
): string {
  const options = getOptions(style, {
    style: 'percent',
    maximumFractionDigits: 5, // default is `2`, which might be too short
  });
  return new Intl.NumberFormat('de-DE', options).format(value / 100);
}

/**
 * for styles see `formatNumber`
 */
export function formatKm(value: number, style?: NumberStyle): string {
  // the following errs in Node, dunno why
  // const options = getOptions(value, style);
  // options.style = 'unit';
  // options.unit = 'kilometer';
  // return new Intl.NumberFormat('de-DE', options).format(value);
  return `${formatNumber(value, style)}\u00A0km`;
}

import { Box } from '@rebass/grid';
import styled, { css } from 'styled-components';

import { Image } from 'app/shared/ui';

interface IYoutubeEmbedWrapperSt {
  bgImage: string;
}

export const YoutubeEmbedWrapperSt = styled(Box)`
  overflow: hidden;
  padding-bottom: 56.25%;
  position: relative;
  height: 0;
  > iframe,
  img {
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    position: absolute;
  }
`;

export const PlayIconSt = styled.img`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

export const ImageWrapperSt = styled(Box)<IYoutubeEmbedWrapperSt>`
  overflow: hidden;
  padding-bottom: 56.25%;
  position: relative;
  height: 0;
  background-size: 100%;
  background-position: 50% 50%;
  ${({ bgImage }) =>
    css`
      background-image: url('${bgImage}');
    `}
`;

export const VideoThumbSt = styled(Image)`
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  position: absolute;
`;

import { createTypes } from 'app/shared/utils/createTypes';

export const FAVS_PER_PAGE = 21;

export const ActionTypes = createTypes([
  'GET_FAVOURITE_IDS_',
  'GET_FAVOURITE_VEHICLES_',
  'GET_FAVOURITE_IDS_LOCAL_',
  'TOGGLE_SAVED_VEHICLE_',
  'RESET_LOCAL_FAVOURITES',
  'TOGGLE_LOCAL_FAVOURITE',
  'TOGGLE_DELETED_VEHICLE_',
  'SYNC_VEHICLE_',
  'SHOW_FAV_LOGIN_POPUP',
  'HIDE_FAV_LOGIN_POPUP',
  'SUBSCRIBE_TO_FAVOURITES_',
  'GET_SUBSCRIPTION_STATUS_',
  'RESET_WATCH_PRICE_EMAIL_',
  'UNSUBSCRIBE_TO_FAVOURITE_',
]);

import { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { withTheme } from 'styled-components';

import { Logo } from '../Logo/Logo';

import { TitleSt, LogoSt, TitleTextSt } from './Title.css';

export class TitleCmp extends PureComponent {
  render() {
    const { className, title, to, isHome, isStuck, onClick } = this.props;

    return (
      <TitleSt
        flexDirection={['column', 'row']}
        onClick={onClick}
        className={className}
        isHome={isHome}
        isStuck={isStuck}
        mr={title ? [-28, 0] : 0}
      >
        <LogoSt to={to} aria-label="heycar logo">
          <Logo />
        </LogoSt>

        {title && (
          <TitleTextSt ml={[0, 8]} alignItems="center">
            {title}
          </TitleTextSt>
        )}
      </TitleSt>
    );
  }
}

TitleCmp.propTypes = {
  className: PropTypes.string,
  isHome: PropTypes.bool,
  isStuck: PropTypes.bool,
  onClick: PropTypes.func,
  title: PropTypes.any,
  to: PropTypes.string,
  theme: PropTypes.object,
};

TitleCmp.defaultProps = {
  className: '',
  title: null,
  to: '/',
  isHome: false,
  isStuck: false,
  onClick: () => {},
  theme: undefined,
};

export const Title = withTheme(TitleCmp);

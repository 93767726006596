/* istanbul ignore file */
import styled from 'styled-components';
import { Flex } from '@rebass/grid';

import { CampaignPill } from 'app/shared/modules/Campaign/CampaignStyles.css';

export const CartileInfosPillsListSt = styled(Flex)`
  min-height: 48px;
  flex-wrap: wrap;
  align-items: center;
  padding-left: 0;
  margin: 0;
  list-style: none;

  > li {
    display: inline-flex;
    align-items: center;
    letter-spacing: 0.104ch;

    .fonts-loaded &,
    .fonts-observer-fail & {
      letter-spacing: 0;
    }
  }
`;

export const CartileInfosPillsReducedNoteSt = styled.div`
  color: ${({ theme: { colors } }) => colors.tarmacGrey};
  font-size: 10px;
  font-weight: 500;
  width: 100%;
  line-height: normal;

  &:hover {
    color: ${({ theme: { colors } }) => colors.tarmacGrey};
  }
`;

export const PromotionPillSt = styled.div`
  ${CampaignPill}
`;

import { useInView } from 'react-intersection-observer';

import { IndeterminateLoadingSpinner } from 'app/shared/ui';
import { mapContentfulMultipleImagesFilePath } from 'app/shared/utils/utils';
import { ResponsiveImageLoader } from 'app/marketplace/shared/components/ResponsiveImageLoader/ResponsiveImageLoader';
import {
  ResponsiveImageSt,
  LoaderWrapper,
} from 'app/marketplace/contentful/shared/ResponsiveImage/ResponsiveImage.css';
import { responsiveImageShape } from 'app/marketplace/contentful/spaces/seo/propTypes';

export const ResponsiveImage = ({
  title,
  small: sm,
  medium: md,
  large: lg,
  xLarge: xl,
  breakpoint,
  isLazy,
  useWebp,
}) => {
  const [ref, inView] = useInView({ triggerOnce: true, rootMargin: '200px' });
  const loadedSources = mapContentfulMultipleImagesFilePath({ sm, md, lg, xl });
  // eslint-disable-next-line no-nested-ternary
  const showLoader = isLazy ? !inView : false;

  return (
    <ResponsiveImageSt ref={ref}>
      {showLoader ? (
        <LoaderWrapper>
          <IndeterminateLoadingSpinner />
        </LoaderWrapper>
      ) : (
        <ResponsiveImageLoader
          breakpoint={breakpoint}
          alt={title}
          sources={loadedSources}
          useWebp={useWebp}
        />
      )}
    </ResponsiveImageSt>
  );
};

ResponsiveImage.propTypes = responsiveImageShape;

ResponsiveImage.defaultProps = {
  title: '',
  small: [],
  medium: [],
  large: [],
  xLarge: [],
  breakpoint: 'sm',
  isLazy: false,
  useWebp: false,
};

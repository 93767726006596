/* istanbul ignore file */
import { connect } from 'react-redux';

import { getFeatureFlagByFlagID } from 'app/selectors';
import { getCookie } from 'app/shared/utils/utils';
import { makeLazy } from 'app/shared/components/LazyWrapper/LazyWrapper';

const LazySlideInBannersController = makeLazy({
  getComponent: /* istanbul ignore next */ () => {
    return import(
      './SlideInBannersController.js' /* webpackChunkName: "SlideInBannersController" */
    );
  },
  exportName: 'SlideInBannersController',
  spinner: null,
});

const mapStateToProps = (state) => {
  const areCookiesAccepted = state?.cookieDashboard?.preferences?.functional;

  return {
    showSlideIn: state.showSlideIn?.show,
    breakpoint: state?.globalEvents?.breakpoint,
    crmSlideInFormSubmitted: state?.slideIn?.submitted,
    hasSignedUpForNewsletter: state?.user?.hasSignedUpForNewsletter,
    slideInDismissedCookie:
      getCookie('slideIn', areCookiesAccepted) === 'dismissed',
    slideInBannersFlag172: getFeatureFlagByFlagID(state, 172)?.flagToken,
    slideInBannersFlag169: getFeatureFlagByFlagID(state, 169)?.flagToken,
  };
};

export const ConnectedSlideInBannersController = connect(mapStateToProps)(
  LazySlideInBannersController,
);

import { IPixelCount, TBreakpointValues } from './index.types';

export const marginTopUnderSearchBar: IPixelCount = {
  sm: 72,
  md: 96,
};

export const zIndexModal = 1050;
export const slightlyAbove = 10;
export const stickyButton = 1040;

export const zIndex: IPixelCount = {
  navbar: 1030,
  modal: zIndexModal,
  overlay: zIndexModal,
  sidebar: zIndexModal,
  cookieBanner: 1060,
  homeBenefitsTitle: slightlyAbove,
  sliderLabel: 3,
  sliderLabelLine: 4,
  sliderLabelText: 5,
  stickyButton,
  notificationBar: stickyButton,
  switchButton: 3,
  switchLabel: 4,
  footer: 12,
  moreLikeThis: slightlyAbove,
  floatingCTAs: 10,
  slideIn: 10,
  pdpUnavailableOverlay: 5,
};

export const height: IPixelCount = {
  topNavBar: 64,
  topBanner: 32,
};

export const magazine: {
  [key: string]: string;
} = {
  psm: '16px',
  pmd: '16px',
};

const margin: TBreakpointValues = {
  sm: 24, // default
  md: 28,
  lg: 36,
  xl: 56,
};

const gutter: TBreakpointValues = {
  sm: 8, // default
  md: 16,
  lg: 16,
  xl: 16,
};

export const grid = {
  columns: 12,
  margin,
  gutter,
};

export const columnCount = {
  sm: {
    column: 8,
    margin: 16,
  },
  md: {
    column: 30,
    margin: 32,
  },
  lg: {
    column: 50,
    margin: 32,
  },
  xl: {
    column: 68,
    margin: 32,
  },
};

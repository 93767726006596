import { memo } from 'react';
import PropTypes from 'prop-types';

import { CartileLoadingStyle } from 'app/shared/modules/Cartile/CartileUIComponents/CartileUIComponents.css';
import {
  CartileDistanceLocationTextSt,
  CartileDistanceLocationIconSt,
  CartileDistanceLocationContainerSt,
} from 'app/shared/modules/Cartile/CartileUIComponents/CartileLocation/CartileDistanceLocation/CartileDistanceLocation.css';

const CartileDistanceLocationCmp = ({ city, distance, isLoading }) => (
  <CartileDistanceLocationContainerSt css={[isLoading && CartileLoadingStyle]}>
    <CartileDistanceLocationIconSt
      as="i"
      mr="4px"
      className="if-icon-location"
    />
    <CartileDistanceLocationTextSt as="b" fontSize={['10px', '12px']}>
      {distance} km entfernt
    </CartileDistanceLocationTextSt>
    <CartileDistanceLocationTextSt fontSize={['10px', '12px']}>
      in {city}
    </CartileDistanceLocationTextSt>
  </CartileDistanceLocationContainerSt>
);

CartileDistanceLocationCmp.propTypes = {
  isLoading: PropTypes.bool,
  city: PropTypes.string.isRequired,
  distance: PropTypes.number.isRequired,
};

CartileDistanceLocationCmp.defaultProps = {
  isLoading: false,
};

export const CartileDistanceLocation = memo(CartileDistanceLocationCmp);

import styled, { css } from 'styled-components';
import { Flex, Box } from '@rebass/grid';

import { media } from '../../../variables/media';
import { GridContainer } from '../../../GridContainer';

export const NavFlyoutContainerSt = styled(Flex).attrs(() => ({
  flexWrap: ['nowrap', 'nowrap', 'wrap', 'nowrap'],
  flexDirection: ['column', 'column', 'row', 'column'],
}))`
  position: fixed;
  z-index: 1032;
  top: 0px;
  width: 100%;
  padding: 0;
  margin: 0;
  background-color: ${({ theme }) => theme.colors.white};
  box-shadow: 0 15px 15px 0 rgba(0, 0, 0, 0.15);
  overflow-y: scroll;
  overflow-x: hidden;
  /* opacity: 0; */
  transform: translateX(110vw);
  transition: transform 400ms ease-out 0s;

  ${media.lg(css`
    position: absolute;
    z-index: -2;
    left: 0;
    top: 64px;
    height: auto;
    max-height: 0;
    min-height: 0;
    opacity: 0;
    overflow-y: auto;
    transition: opacity 400ms ease-out 0s;
    will-change: opacity;
    transform: none;
  `)}

  .innerFlyoutContainer {
    position: fixed;
    z-index: 1;
    flex-wrap: nowrap;

    ${media.lg(css`
      z-index: -1;
    `)}
  }
`;

export const BoxItemCategorySt = styled(Box).attrs(() => ({
  as: 'li',
}))`
  position: relative;
`;

export const GridContainerSt = styled(GridContainer)`
  @media all and (max-width: 1024px) {
    max-width: calc(100% - 48px);
  }

  width: 100%;
`;

export const FlexSt = styled(Flex).attrs(() => ({
  as: 'ul',
}))`
  grid-column: 1 / span 12;
  padding-left: 0;
  list-style: none;
`;

import React from 'react';

import { Text } from 'app/shared/ui';

export const Suffix = ({
  searchCategory,
}: {
  searchCategory: string;
}): JSX.Element => {
  switch (searchCategory) {
    case 'monthlyRateRange':
    case 'leasingRateRange':
      return (
        <Text>
          <Text>€</Text>
          <Text fontSize="8px" fontWeight="bold">
            /Monat*
          </Text>
        </Text>
      );
    case 'priceRange':
    default:
      return <Text>€</Text>;
  }
};

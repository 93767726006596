import * as ActionTypes from './constants';

const defaultState = () => {
  return {
    breakpoint: 'sm',
    isTopNavBarHidden: false,
    isScrollDown: true,
    isTopNavbarStuck: false,
    isAdBlockerDetected: false,
  };
};

export function globalEvents(state = defaultState(), action) {
  const { type, payload } = action;
  switch (type) {
    case ActionTypes.SET_BREAKPOINT:
      return { ...state, breakpoint: payload?.breakpoint };
    case ActionTypes.TOGGLE_TOPNAVBAR:
      return { ...state, isTopNavBarHidden: payload?.isTopNavBarHidden };
    case ActionTypes.TOGGLE_SCROLL_DIRECTION:
      return { ...state, isScrollDown: payload?.isScrollDown };
    case ActionTypes.TOGGLE_IS_STUCK:
      return { ...state, isTopNavbarStuck: payload?.isTopNavbarStuck };
    case ActionTypes.SET_IS_AD_BLOCKER_DETECTED:
      return { ...state, isAdBlockerDetected: payload?.isAdBlockerDetected };
    default:
      return state;
  }
}

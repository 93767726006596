/* instanbul ignore file */
import styled, { css } from 'styled-components';

import { color, media } from 'app/shared/styles_js/variables';

export const CardSt = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-shadow: ${({ $showBoxShadow }) =>
    $showBoxShadow ? '0 0 10px 0 rgba(0, 0, 0, 0.15)' : 'none'};
  border-radius: 4px;
  overflow: hidden;
  transition: transform 0.2s;

  &:hover {
    cursor: pointer;
    transform: scale(1.02);
  }
`;

export const CardImageSt = styled.img`
  width: 100%;
  height: auto;
  border-radius: ${({ $hideLink, $isCarTile }) =>
    $hideLink || $isCarTile ? '4px' : '4px 4px 0 0'};
`;

export const CardTextSt = styled.div`
  display: flex;
  height: 108px;
  align-items: center;
  justify-content: center;
  padding: 8px 16px;
  color: ${color.brandSecondary};
  text-align: center;

  ${media.md(css`
    height: 100px;
    padding: 8px;
  `)}
`;

export const CardLabelSt = styled.a`
  display: block;
  padding-bottom: 18px;
  font-weight: 500;
  text-decoration: underline;
  text-align: center;
  line-height: 1.5;
  color: ${({ theme: { colors } }) => colors.mintDark};
  transition: 'color 200ms ease-in-out';

  &:hover {
    text-decoration: underline;
  }
`;

import { ISearchResponse } from 'app/types/apis/search.types';

export enum SearchActionTypes {
  /**
   * @description gets the total listings count from search response's "totalElements"
   */
  GET_TOTAL_LISTINGS_COUNT = 'SEARCH/GET_TOTAL_LISTINGS_COUNT',
}

export type TSearchActions = {
  type: SearchActionTypes.GET_TOTAL_LISTINGS_COUNT;
  payload: ISearchResponse;
};

const pagesWithVatDisclaimer = [
  '/$', // home page
  '/gebrauchtwagen', // CLP
  '/fahrzeugvergleich', // Comparison
  '/magazine', // Magazine
  '/haendler', // Dealer and profile page.
  '/favourites', // Favourite page
];
export const pagesWithVatRegex = new RegExp(
  `^(${pagesWithVatDisclaimer.join('|')})`,
);

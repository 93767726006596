import { connect } from 'react-redux';

import { sendForm } from 'app/marketplace/crm/actions';

import SignupForm from './SignupForm';

function mapStateToProps() {
  return {};
}

export const ConnectedSignupForm = connect(mapStateToProps, { sendForm })(
  SignupForm,
);

import * as ActionTypes from './constants';

export const getOverlayDefaultState = overrides => {
  return {
    //  we could add more types of components here later if really necessary (activePopoverComponent) for example
    activeOverlayComponent: null,
    ...overrides,
  };
};

/**
 * @type {{Function}} - an object of functions that are called based on the action type
 */
const resolvers = {
  [ActionTypes.OPEN_OVERLAY]: (state, payload) => {
    return {
      ...state,
      activeOverlayComponent: payload,
    };
  },
  [ActionTypes.CLOSE_OVERLAY]: state => {
    return {
      ...state,
      activeOverlayComponent: null,
    };
  },
  [ActionTypes.UPDATE_TITLE]: (state, payload) => {
    return {
      ...state,
      activeOverlayComponent: {
        ...state.activeOverlayComponent,
        config: {
          ...state.activeOverlayComponent.config,
          props: {
            ...state.activeOverlayComponent.config.props,
            title: payload,
          },
        },
      },
    };
  },
};

/**
 * Overlay reducer
 * @param {Object} state - the default state or the current state
 * @param {Object} action - the action
 * @param {String} action.type - the type of the action (see constants.js)
 * @param {*} action.payload - the payload of the action
 * @returns {Object} - the new state
 */
export const overlay = (
  state = getOverlayDefaultState(),
  { type, payload } = {},
) => {
  if (resolvers[type]) {
    return resolvers[type](state, payload);
  }

  return state;
};

import React from 'react';
import PropTypes from 'prop-types';

import { linear, geometric, logarithmic } from './algorithms';
import {
  ValueSt,
  ValuesSt,
  SliderSt,
  RheostatSt,
  HistogramSt,
  SliderBodySt,
  HistogramItemSt,
  HistogramInnerSt,
} from './Slider.css';

export class Slider extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      values: props.values,
    };
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    this.setState({ values: newProps.values });
  }

  updateValue = ({ values }) => {
    this.setState({ values });
    this.props.onDrag(values);
  };

  onChange = (sliderData) => {
    this.props.onChange(sliderData);
  };

  getAlgorithm = () => {
    const { algorithm, snapPoints, granular } = this.props;

    if (algorithm === 'geometric') {
      return geometric(granular ? null : snapPoints);
    }
    if (algorithm === 'logarithmic') {
      return logarithmic(granular ? null : snapPoints);
    }

    return linear(granular ? null : snapPoints);
  };

  convertHistogramValuesToRelative = (values) => {
    const maxValue = values.slice().sort((a, b) => b - a)[0];

    return values.map((value) => {
      return value / maxValue;
    });
  };

  getHistogramItems = (values) => {
    const relativeValues = this.convertHistogramValuesToRelative(values);

    return (
      <HistogramInnerSt itemCount={relativeValues.length}>
        {relativeValues.map((relativeValue, index) => {
          return (
            <HistogramItemSt
              key={index}
              height={relativeValue}
              itemCount={relativeValues.length}
            />
          );
        })}
      </HistogramInnerSt>
    );
  };

  render() {
    const {
      dataQa,
      disabled,
      showValue,
      className,
      delimiter,
      formatValue,
      onSliderDragEnd,
      histogramValues,
    } = this.props;

    const { values } = this.state;

    const uniqueValues = [...new Set(values)];
    const algorithm = this.getAlgorithm();

    if (delimiter) {
      uniqueValues.splice(1, 0, { isDelimiter: true, delimiter });
    }

    return (
      <SliderSt data-qa={dataQa} className={className}>
        {showValue === true && (
          <ValuesSt
            isSingleValue={uniqueValues.length === 1} // ATM this prop has not effect on the styles (legacy prop)
            histogramActive={histogramValues.length}
          >
            {uniqueValues.length > 0 &&
              uniqueValues.map((value, i) => {
                const { isDelimiter, delimiter } = value;

                if (isDelimiter) {
                  return (
                    <ValueSt key="delimiter" isDelimiter>
                      {delimiter}
                    </ValueSt>
                  );
                }

                return (
                  <ValueSt key={i}>
                    <span>{formatValue(value)}</span>
                  </ValueSt>
                );
              })}
          </ValuesSt>
        )}
        <SliderBodySt>
          {histogramValues.length > 0 && (
            <HistogramSt itemCount={histogramValues.length}>
              {this.getHistogramItems(histogramValues)}
            </HistogramSt>
          )}
          <RheostatSt
            {...this.props}
            algorithm={algorithm}
            onSliderDragEnd={onSliderDragEnd}
            onValuesUpdated={this.updateValue}
            onChange={this.onChange}
            values={values}
            disabled={disabled}
          />
        </SliderBodySt>
      </SliderSt>
    );
  }
}

Slider.propTypes = {
  algorithm: PropTypes.oneOf(['linear', 'logarithmic', 'geometric']),
  delimiter: PropTypes.any,
  max: PropTypes.any.isRequired,
  min: PropTypes.any.isRequired,
  values: PropTypes.array,
  showValue: PropTypes.bool,
  formatValue: PropTypes.func,
  onChange: PropTypes.func,
  onSliderDragEnd: PropTypes.func,
  onDrag: PropTypes.func,
  snapPoints: PropTypes.arrayOf(PropTypes.number),
  snap: PropTypes.bool,
  disabled: PropTypes.bool,
  dataQa: PropTypes.string,
  granular: PropTypes.bool,
  histogramValues: PropTypes.arrayOf(PropTypes.number),
  className: PropTypes.string,
};

Slider.defaultProps = {
  algorithm: null,
  delimiter: null,
  values: [],
  showValue: false,
  formatValue: (value) => value,
  disabled: false,
  onChange: () => {},
  onSliderDragEnd: () => {},
  onDrag: () => {},
  dataQa: '',
  granular: false,
  histogramValues: [],
};

import styled, { css } from 'styled-components';
import { Heading4 } from 'app/shared/ui';

import { media, color } from '../../../../shared/styles_js/variables';

export const ListSt = styled.ul`
  padding-left: 0;
  list-style-type: none;
`;

/* TODO: text? */
export const ListItemSt = styled.li`
  display: flex;
  align-items: center;
  margin-bottom: 16px;
  color: ${color.brandSecondary};

  ${media.md(css`
    margin-bottom: 40px;
  `)};
`;

export const ListItemIconSt = (props) => (
  <Heading4 {...props} mr={[16, 20, 32]} />
);

import 'isomorphic-fetch';
import { checkForError } from '../../shared/utils/checkForError';
import * as Config from '../../config';
import { getStore } from '../../../store';

export const fetchApi = async (url, options) => {
  const defaultOptions = {
    method: 'POST',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'X-Tracking-Guid': getStore().getState().tracking.guid,
    },
  };

  if (CLIENT) {
    defaultOptions.mode = 'cors';
  }

  return fetch(`${Config.DE_API_URL}${url}`, { ...defaultOptions, ...options })
    .then(checkForError)
    .then(response => {
      return response.text();
    })
    .then(response => {
      try {
        return JSON.parse(response);
      } catch (e) {
        return null;
      }
    })
    .catch(error => {
      return Promise.reject(error);
    });
};

const sendPremiumForm = ({ data }) => {
  const options = {
    body: JSON.stringify(data),
  };

  return fetchApi('/leads/active-leads', options);
};

export const premiumService = {
  sendPremiumForm,
};

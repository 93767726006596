import { css } from 'styled-components';

type TCampaignPill = {
  lineHeight?: string;
  fontSize?: string;
  fontWeight?: number;
};

export const CampaignPill = css<TCampaignPill>`
  display: inline-flex;
  flex-wrap: wrap;
  div > *,
  div {
    display: inline-flex;
    flex-wrap: wrap;
  }
  p,
  span {
    font-weight: ${({ fontWeight = 500 }) => fontWeight} !important;
    font-size: ${({ fontSize = '12px' }) => fontSize} !important;
    line-height: 24px;
    padding: 0px 12px !important;
    // @ts-ignore
    background: ${({ theme: { colors } }) => colors.mustangYellow};
    border-radius: 16px;
    display: inline-block;
    margin: 0 8px 4px 0;
    height: 24px;
  }
`;

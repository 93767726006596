import { makeLazy } from 'app/shared/components/LazyWrapper/LazyWrapper';

export const LazyAuthModal = makeLazy({
  getComponent: /* istanbul ignore next */ () => {
    return import(
      './ConnectedAuthModal' /* webpackChunkName: "ConnectedAuthModal" */
    );
  },
  exportName: 'ConnectedAuthModal',
  spinner: <></>,
});

import React, { memo } from 'react';
import { Flex } from '@rebass/grid';

import { NavFlyoutBackButton } from '../NavFlyoutBackButton/NavFlyoutBackButton';
import { NavItemCategory } from '../NavItem/components/NavItemCategory/NavItemCategory';
import { isItemCategory } from '../NavItem/NavItem.types';
import {
  NavItemCategoryWrapperSt,
  NavStyledHr,
} from '../NavItem/components/NavItemCategory/NavItemCategory.css';
import { NavFlyoutBrands } from '../NavFlyoutBrands/NavFlyoutBrands';
import { NavFlyoutFooter } from '../NavFlyoutFooter/NavFlyoutFooter';

import { INavFlyoutContainerProps } from './NavFlyoutContainer.types';
import {
  NavFlyoutContainerSt,
  BoxItemCategorySt,
  GridContainerSt,
  FlexSt,
} from './NavFlyoutContainer.css';

export const NavFlyoutContainer = memo(
  ({
    setTracking,
    handleBackButtonClick,
    mobileBackButtonLabel,
    itemCategories = [],
    selectOnClick,
    setIsOpen,
    closeMenu,
    fullWidthCategory,
    brandsInfo,
    useCustomChildren,
    customChildren,
    footercontent,
  }: INavFlyoutContainerProps): JSX.Element => (
    <NavFlyoutContainerSt className="innerFlyoutContainer">
      {/* navigation back button, only for mobile */}
      <NavFlyoutBackButton
        onClick={() => {
          setTracking('backButton');
          handleBackButtonClick(selectOnClick, setIsOpen);
        }}
      >
        {mobileBackButtonLabel}
      </NavFlyoutBackButton>

      <GridContainerSt hasDefaultWidth>
        <FlexSt
          flexWrap="wrap"
          flexDirection={['column', 'row', 'row']}
          px={useCustomChildren ? 0 : ['28px', '28px', 0]}
          pb={useCustomChildren ? 0 : ['32px', '32px', 0]}
          pt={[0, '12px', '40px']}
        >
          {itemCategories &&
            itemCategories.map((item, index) =>
              isItemCategory(item) ? (
                <BoxItemCategorySt
                  width={['100%', 1 / 3, '25%']}
                  // eslint-disable-next-line react/no-array-index-key
                  key={`item-${index}`}
                >
                  <NavItemCategory
                    item={item}
                    index={index}
                    setSelectedItem={selectOnClick}
                    setTracking={setTracking}
                    closeMenu={closeMenu}
                  />
                  <>{index + 1 < itemCategories.length && <NavStyledHr />}</>
                </BoxItemCategorySt>
              ) : (
                <NavItemCategoryWrapperSt
                  key={`item-${index}`}
                  width={['100%', '50%', '25%']}
                  py={['12px', '12px', 0]}
                  onClick={() => selectOnClick('')}
                >
                  {item}
                </NavItemCategoryWrapperSt>
              ),
            )}
          {fullWidthCategory && (
            <Flex
              justifyContent="center"
              alignItems="center"
              width="100%"
              pt={['12px', '20px', '32px']}
              pb={[0, 0, '40px']}
            >
              {fullWidthCategory}
            </Flex>
          )}
          {useCustomChildren && customChildren}
        </FlexSt>
      </GridContainerSt>
      {brandsInfo && (
        <NavFlyoutBrands
          brandsInfo={brandsInfo}
          setTracking={setTracking}
          closeMenu={closeMenu}
        />
      )}
      {footercontent && <NavFlyoutFooter>{footercontent}</NavFlyoutFooter>}
    </NavFlyoutContainerSt>
  ),
);

NavFlyoutContainer.displayName = 'NavFlyoutContainer';

import { TBreakpoint } from 'app/types/style.types';

export enum ConfigStatus {
  ON = 'ON',
  OFF = 'OFF',
}

export enum Channel {
  PUSH = 'PUSH',
  EMAIL = 'EMAIL',
}

export enum DeviceType {
  DESKTOP = 'DESKTOP',
}

export enum SaveSearchDashboardPageSize {
  mobile = 10,
  tablet = 12,
  desktop = 12,
}

export type SavedSearchDeleteId = {
  id: string;
};

export interface SearchUserProfile {
  id: string;
  guid: string;
  email: string;
  createdAt: string;
  confirmedAt: string;
  emailHash: string;
  confirmed: boolean;
  lastUpdatedAt: string;
}

export enum Interval {
  DAILY = 'DAILY',
  HOURLY = 'HOURLY',
}

export interface SavedSearchNotificationConfigs {
  id: string;
  channel: Channel;
  createdAt?: string;
  interval?: Interval;
  lastUpdatedAt?: string;
  configStatus: ConfigStatus;
}

export interface PaginationProps {
  pageSize: number;
  pageNumber: number;
  totalPages: number;
  totalElements: number;
}

export interface savedSearchesData {
  id: string;
  createdAt: string;
  lastSeenAt: string;
  searchName: string;
  searchQuery: string;
  lastUpdatedAt: string;
  searchLabels: string[];
  searchUserProfile: SearchUserProfile;
  totalVehiclesCountInSavedSearches: number;
  totalVehiclesCountInSavedSearchesSinceLastSeenAt: number;
  savedSearchNotificationConfigs: SavedSearchNotificationConfigs[];
}
export interface PageableSort {
  unsorted: boolean;
  sorted: boolean;
}

export interface pageableAPIResponse extends PaginationProps {
  sort: PageableSort;
  offset: number;
  paged: boolean;
  unpaged: boolean;
}

export interface savedSearchAPIResponse {
  pagination: pageableAPIResponse;
  totalPages: number;
  totalElements: number;
  savedSearches: savedSearchesData[];
}

export interface ICreateSavedSearchFormData {
  searchQuery: string;
  searchLabels: string[];
  deviceId: null;
  searchName: string;
  email: string;
  channel: Channel;
  configStatus: ConfigStatus;
  deviceType: DeviceType;
}

export enum SaveSearchActionTypes {
  SAVE_SEARCH_PENDING = 'SAVEDSEARCH/SAVE_SEARCH_PENDING',
  SAVE_SEARCH_FETCH_SEARCHES_SUCCESS = 'SAVEDSEARCH/SAVE_SEARCH_FETCH_SEARCHES_SUCCESS',
  SAVE_SEARCH_FETCH_SEARCHES_FAILED = 'SAVEDSEARCH/SAVE_SEARCH_FETCH_SEARCHES_FAILED',
  SAVE_SEARCH_POST_SEARCH_SUCCESS = 'SAVEDSEARCH/SAVE_SEARCH_POST_SEARCH_SUCCESS',
  SAVE_SEARCH_POST_SEARCH_FAILED = 'SAVEDSEARCH/SAVE_SEARCH_POST_SEARCH_FAILED',
  SAVE_SEARCH_UPDATE_SEARCH_SUCCESS = 'SAVEDSEARCH/SAVE_SEARCH_UPDATE_SEARCH_SUCCESS',
  SAVE_SEARCH_UPDATE_SEARCH_FAILED = 'SAVEDSEARCH/SAVE_SEARCH_UPDATE_SEARCH_FAILED',
  SAVE_SEARCH_DELETE_SEARCH_SUCCESS = 'SAVEDSEARCH/SAVE_SEARCH_DELETE_SEARCH_SUCCESS',
  SAVE_SEARCH_DELETE_SEARCH_FAILED = 'SAVEDSEARCH/SAVE_SEARCH_DELETE_SEARCH_FAILED',
  SAVE_SEARCH_UPDATE_NOTIFICATION_STATUS_SUCCESS = 'SAVEDSEARCH/SAVE_SEARCH_UPDATE_NOTIFICATION_STATUS_SUCCESS',
  SAVE_SEARCH_UPDATE_NOTIFICATION_STATUS_FAILED = 'SAVEDSEARCH/SAVE_SEARCH_UPDATE_NOTIFICATION_STATUS_FAILED',
  SAVE_SEARCH_ACCOUNT_REGISTERED_SUCCESS = 'SAVEDSEARCH/SAVE_SEARCH_ACCOUNT_REGISTERED_SUCCESS',
  SAVE_SEARCH_ACCOUNT_REGISTERED_FAILURE = 'SAVEDSEARCH/SAVE_SEARCH_ACCOUNT_REGISTERED_FAILURE',
}

export type UpdateAction =
  | {
      type: typeof SaveSearchActionTypes.SAVE_SEARCH_UPDATE_SEARCH_SUCCESS;
      payload: savedSearchesData;
    }
  | {
      type: typeof SaveSearchActionTypes.SAVE_SEARCH_UPDATE_SEARCH_FAILED;
      error: string;
    };
export type DeleteAction =
  | {
      type: typeof SaveSearchActionTypes.SAVE_SEARCH_DELETE_SEARCH_SUCCESS;
      payload: SavedSearchDeleteId;
    }
  | {
      type: typeof SaveSearchActionTypes.SAVE_SEARCH_DELETE_SEARCH_FAILED;
      error: string;
    };
export type CreateSaveSearchAction =
  | {
      type: typeof SaveSearchActionTypes.SAVE_SEARCH_POST_SEARCH_SUCCESS;
      payload: savedSearchesData;
    }
  | {
      type: typeof SaveSearchActionTypes.SAVE_SEARCH_POST_SEARCH_FAILED;
      error: string;
    };

export type CheckUserIsRegisteredAction =
  | {
      type: typeof SaveSearchActionTypes.SAVE_SEARCH_ACCOUNT_REGISTERED_SUCCESS;
      payload: boolean;
    }
  | {
      type: SaveSearchActionTypes.SAVE_SEARCH_ACCOUNT_REGISTERED_FAILURE;
      error: string;
    };

export interface SaveSearchDashboardProps {
  userEmail: string;
  isLoading: boolean;
  deviceType: string;
  isLoggedIn: boolean;
  hasOverlay: boolean;
  breakpoint: TBreakpoint;
  openOverlay: () => void;
  closeOverlay: () => void;
  pagination: PaginationProps;
  savedSearches: savedSearchesData[] | [];
  showNotification: (element: JSX.Element) => void;
  deleteSavedSearch: (id: string) => Promise<DeleteAction>;
  getSavedSearches: (email: string, size: number, page?: number) => void;
  updateSaveSearchNotificationStatus: (
    obj: SavedSearchNotificationConfigs,
  ) => {
    error?: string;
    type: string;
    payload?: SavedSearchNotificationConfigs;
  };
  updateSavedSearch: (
    searchId: string,
    param?: string,
    method?: string,
  ) => Promise<UpdateAction>;
  location: { pathname: string; search: string };
}

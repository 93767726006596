import { Route } from 'react-router';

import { getAsyncRouteComponentLoader } from 'app/shared/utils/utils';
import { ConnectedPageTemplate } from 'app/shared/modules/pageTemplate/PageTemplate/ConnectedPageTemplate';

export const FavouritesRoutes = (
  <Route
    name="favourites"
    component={(props) => (
      <ConnectedPageTemplate target="Favourites" {...props} />
    )}
  >
    <Route
      path="/favourites"
      getComponent={getAsyncRouteComponentLoader('ConnectedFavourites', () =>
        import('./Favourites/ConnectedFavourites'),
      )}
    />
  </Route>
);

/* eslint-disable promise/always-return */
import { push } from 'react-router-redux';

import { sanitizeVehicle } from 'app/marketplace/vehicle/utils';
import {
  selectCurrentVehicleIndex,
  selectCurrentVehicleNumber,
  selectCurrentPage,
} from 'app/marketplace/vehicle/selectors';
import { PAGINATION_SIZE } from 'app/config';
import { apiCall } from 'app/actions';
import { trackAction } from 'app/shared/utils/tracking';
import { sendForm } from 'app/marketplace/crm/actions';

import * as AppActionTypes from '../../constants';
import { getContactDealerPhoneInfo } from '../contactDealer/actions';
import { getVehicles } from '../search/actions';
import { overrideFilters } from '../filter/actions';
import { getFilterObject } from '../filter/utils';
import { trackCallButton } from '../contactDealer/Call/tracking';

import * as ActionTypes from './constants';

export const getVehicle = (id) => {
  return (dispatch, getState) => {
    dispatch({ type: ActionTypes.GET_VEHICLE_REQUEST });

    const isIdWrong =
      id && typeof id === 'string' && id.substring(0, 8) === 'manifest';

    const vehicle = getState().search
      ? // eslint-disable-next-line no-shadow
        getState().search.vehicles.filter((vehicle) => vehicle.id === id)
      : [];
    const url = `/search?id=${id}`;

    if (
      vehicle.length === 0 &&
      getState().vehicle &&
      getState().vehicle.vehicle.id === id
    ) {
      vehicle.push(getState().vehicle.vehicle);
    }

    // mobileId field is always present in a listing but it is not being fetch on the reducedCLP object.
    // Checking for this let us know if we need to make a call to get the complete vehicle object
    const isreducedVehicleObj = vehicle[0] && vehicle[0].mobileId === undefined;

    if (vehicle.length > 0 && !isIdWrong && !isreducedVehicleObj) {
      return dispatch({
        type: ActionTypes.GET_VEHICLE_SUCCESS,
        payload: { content: vehicle.map(sanitizeVehicle) },
      });
    }

    return dispatch(apiCall(url))
      .then((payload) => {
        if (payload.content.length === 0) {
          dispatch({
            type: ActionTypes.GET_VEHICLE_FAILURE,
            error: 'empty content',
          });
        } else {
          dispatch({
            type: ActionTypes.GET_VEHICLE_SUCCESS,
            payload: {
              content: payload.content.map(sanitizeVehicle),
            },
          });
        }
      })
      .catch((error) => {
        dispatch({ type: ActionTypes.GET_VEHICLE_FAILURE, error });
        dispatch({ type: AppActionTypes.SET_SHORT_CIRCUIT, payload: true });
      });
  };
};

export const resetVehicle = () => {
  return { type: ActionTypes.RESET_VEHICLE };
};

/**
 * Take the previously stored search url and navigates back.
 *
 * Get page from stored url
 * Override page filter with the given value
 * and get vehicles for the given page.
 * Then navigate back to the stored search url.
 *
 * @returns {Promise} When getVehicles and history.push have been dispatched
 */
export const goToSearch = () => (dispatch, getState) => {
  // Get page from stored url
  const prevSearchUrl =
    getState().vehicle.prevSearchUrl !== null &&
    getState().vehicle.prevSearchUrl.split('?');
  const matches = prevSearchUrl[1] && prevSearchUrl[1].match(/page=(\d)/);
  let page = 0;

  if (matches) {
    page = parseInt(matches[1], 10);
  }

  // Override page filter with the given value
  dispatch(
    overrideFilters({
      page: [getFilterObject({ category: 'page', value: page })],
    }),
  );

  // Get vehicles for the given page.
  return dispatch(getVehicles()).then(() => {
    // Then navigate back to the stored search url.
    dispatch(push(getState().vehicle.prevSearchUrl));
  });
};

export const saveSearchUrl = (url) => (dispatch) => {
  let searchUrl = null;

  if (url && url.includes('gebrauchtwagen')) {
    searchUrl = url;
  } else {
    searchUrl = '/gebrauchtwagen';
  }

  dispatch({
    type: ActionTypes.SET_SEARCH_URL,
    payload: {
      searchUrl,
    },
  });
};

export const navigateVehicle = (id) => {
  return push(`/vehicle/${id}`);
};

/**
 * Load vehicles with a new page filter
 * if current index is the first or last place
 * @returns {Promise} new vehicle Id
 */
export const getMoreVehicles = () => (dispatch, getState) => {
  const { resultsVehicleCount } = getState().search;
  const vehicleNr = selectCurrentVehicleNumber(getState());
  let index = selectCurrentVehicleIndex(getState());
  let page = selectCurrentPage(getState());

  // We can find more vehicles if
  // first index of vehicles and we're above page 0
  if (index === 0 && page > 0) {
    index = PAGINATION_SIZE - 1;
    page -= 1;
    // if last index of vehicles AND current vehicle is not the last one in total
  } /* istanbul ignore next */ else if (
    index === PAGINATION_SIZE - 1 &&
    vehicleNr < resultsVehicleCount
  ) {
    index = 0;
    page += 1;
  }

  dispatch(
    overrideFilters({
      page: [getFilterObject({ category: 'page', value: page })],
    }),
  );
  return dispatch(getVehicles()).then(() => {
    return getState().search.vehicles[index].id;
  });
};

/**
 * Navigate to vehicle with next (dir=1) or previous (dir=-1) index
 * @param {number} dir direction
 * @return {undefined}
 */
export const navigateRelative = (dir) => (dispatch, getState) => {
  const currentIndex = selectCurrentVehicleIndex(getState());
  const nextVehicle = getState().search.vehicles[currentIndex + dir];

  if (nextVehicle) {
    return dispatch(navigateVehicle(nextVehicle.id));
  }
  return dispatch(getMoreVehicles()).then((id) =>
    dispatch(navigateVehicle(id)),
  );
};

export const trackNavigation = (dir, event) => {
  trackAction(
    'pdp_navigation',
    {
      category: 'PDP',
      description: 'user navigates on pdp',
    },
    {
      input: event,
      dir: dir === 1 ? 'next' : 'previous',
    },
  );
};

export const navigateRelativeByClick = (dir) => {
  trackNavigation(dir, 'click');
  return navigateRelative(dir);
};

export const navigateRelativeByKeyboard = (dir) => {
  trackNavigation(dir, 'keyboard');
  return navigateRelative(dir);
};

// eslint-disable-next-line consistent-return
export const signupForNewsletterWithConsent = () => (dispatch, getState) => {
  const { user, contactDealer } = getState();

  if (
    !user.hasSignedUpForNewsletter &&
    user.hasEmailConsent &&
    contactDealer.form.email
  ) {
    return dispatch(
      sendForm({
        email: contactDealer.form.email,
      }),
    ).then(() => {
      trackAction('mail_form_newsletter_signup', {
        description: 'user successfully signed up for the newsletter',
      });
    });
  }
};

/**
 * @typedef {(action: string, autoTriggered?: boolean, additionalContentProps?: object) => void} OpenSidebar
 * @typedef {import('redux').Dispatch} Dispatch
 */

/**
 * Function to initiate a call to dealer with an options top open sidebar or directly placing the call.
 * @param {Object}      param0
 * @param {string}      param0.callDealerEventId to keep track of the event
 * @param {boolean}     param0.callThroughSidebar to open the sidebar and show phone number or to make a phone number request
 * @param {OpenSidebar} param0.openSidebar to open the sidebar
 * @param {object}      param0.vehicle to get the current vehicle
 * @param {string}      param0.eventLabel used to give the source of the event for tracking
 * @param {string}     [param0.target="PDP"] current site target
 *
 * @returns {(dispatch: Dispatch) => void|Promise<void>}
 */
export const initiateCall = ({
  callDealerEventId,
  callThroughSidebar,
  openSidebar,
  vehicle,
  eventLabel,
  target = 'PDP',
  source,
}) => (dispatch) => {
  if (!vehicle.live) return;

  if (callThroughSidebar) {
    openSidebar('call', false, { eventLabel, source, callDealerEventId });
  } else {
    trackCallButton(vehicle, callDealerEventId, target, true, eventLabel);
    dispatch(
      getContactDealerPhoneInfo(
        vehicle,
        callDealerEventId,
        eventLabel,
        target,
        openSidebar,
        true,
      ),
    );
  }
};

export const getAdditionalListingsById = (id, maxAmountOfExtraListings = 3) => {
  return (dispatch) => {
    dispatch({ type: ActionTypes.GET_ADDITIONAL_LISTINGS_REQUEST });

    const url = `/search/oneclicklead?listingId=${id}&maxAmountOfExtraListings=${maxAmountOfExtraListings}&version=v2`;

    return dispatch(apiCall(url))
      .then((payload) => {
        const { automatedListings, selectableListings, pills } = payload;
        if (automatedListings && selectableListings) {
          dispatch({
            type: ActionTypes.GET_ADDITIONAL_LISTINGS_SUCCESS,
            payload: {
              automatedListings,
              selectableListings,
              pills: pills || [],
              condition: 1,
            },
          });
        } else {
          dispatch({
            type: ActionTypes.GET_ADDITIONAL_LISTINGS_FAILURE,
            error: 'no matching listings found',
          });
        }
      })
      .catch((error) => {
        dispatch({ type: ActionTypes.GET_ADDITIONAL_LISTINGS_FAILURE, error });
      });
  };
};

export const getVehicleReservationStatus = (id) => {
  return (dispatch) => {
    dispatch({ type: ActionTypes.GET_VEHICLE_RESERVATION_STATUS_REQUEST });

    const url = `/reservation/vehicle/${id}/`;

    return dispatch(apiCall(url, {}, true, true))
      .then((payload) => {
        const { available } = payload;
        dispatch({
          type: ActionTypes.GET_VEHICLE_RESERVATION_STATUS_SUCCESS,
          payload: {
            available,
          },
        });
      })
      .catch((error) => {
        dispatch({
          type: ActionTypes.GET_VEHICLE_RESERVATION_STATUS_FAILURE,
          error,
        });
      });
  };
};

import { memo } from 'react';
import PropTypes from 'prop-types';
import { browserHistory } from 'react-router';

import {
  CartileMtlPriceCalculatorLinkSt,
  CartileMtlPriceCalculatorLinkTextSt,
} from 'app/shared/modules/Cartile/CartileUIComponents/CartilePrice/CartileMtlPriceCalculatorLink/CartileMtlPriceCalculatorLink.css';
import { CartileLoadingStyle } from 'app/shared/modules/Cartile/CartileUIComponents/CartileUIComponents.css';

const CartileMtlPriceCalculatorLinkCmp = ({
  text,
  linkTo,
  fontsize,
  isLoading,
  handleTracking,
}) => {
  const handleOnClick = (e) => {
    e.preventDefault();
    e.stopPropagation();
    handleTracking();
    browserHistory.push(linkTo);
  };
  return (
    <CartileMtlPriceCalculatorLinkSt onClick={handleOnClick}>
      <CartileMtlPriceCalculatorLinkTextSt
        fontsize={fontsize}
        css={[isLoading && CartileLoadingStyle]}
      >
        {text}
      </CartileMtlPriceCalculatorLinkTextSt>
    </CartileMtlPriceCalculatorLinkSt>
  );
};

CartileMtlPriceCalculatorLinkCmp.propTypes = {
  isLoading: PropTypes.bool,
  text: PropTypes.object.isRequired,
  linkTo: PropTypes.string.isRequired,
  fontsize: PropTypes.string.isRequired,
  handleTracking: PropTypes.func.isRequired,
};

CartileMtlPriceCalculatorLinkCmp.defaultProps = {
  isLoading: false,
};
export const CartileMtlPriceCalculatorLink = memo(
  CartileMtlPriceCalculatorLinkCmp,
);

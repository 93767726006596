import styled from 'styled-components';
import { Text } from 'app/shared/ui';
import { Flex } from '@rebass/grid';

export const CartileD2CLocationContainerSt = styled(Flex).attrs(() => ({
  alignItems: 'flex-end',
}))`
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 16px;
  margin-right: 10px;
`;

export const CartileD2CLocationDistanceTextSt = styled(Flex).attrs(() => ({
  alignItems: 'center',
}))`
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 16px;
`;

export const CartileD2CLocationTextSt = styled(Text)`
  vertical-align: bottom;
`;

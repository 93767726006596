import { apiCall } from 'app/actions';

import {
  FEATURE_FLAGS_ERROR,
  GET_FEATURE_FLAGS,
  FEATURE_FLAGS_GETUSERGROUP,
  FEATURE_FLAGS_INITIALISED,
} from './constants';

const apiUrl = '/toggle/evaluation/batch';
const featureFlags = require('./featureFlags.json');

export function featureFlagsInitialisedAction() {
  return dispatch => {
    return dispatch({
      type: FEATURE_FLAGS_INITIALISED,
      payload: true,
    });
  };
}

export const parseFlags = (data, flagsToOverride = {}) => {
  const evaluatedFeatureFlags =
    data.evaluationResults &&
    data.evaluationResults.map(res => {
      return {
        flagID: res.flagID || 0,
        flagToken: res.variantKey || null,
        attachment: res.variantAttachment || {},
      };
    });

  // Add or update overrides
  Object.entries(flagsToOverride).forEach(([flagID, flagToken]) => {
    const existingFlagIndex = evaluatedFeatureFlags.findIndex(
      f => f.flagID === parseInt(flagID, 10),
    );

    if (existingFlagIndex !== -1) {
      // Update existing value
      evaluatedFeatureFlags[existingFlagIndex].flagToken = flagToken;
    } else {
      // Push new value
      evaluatedFeatureFlags.push({
        flagID: parseInt(flagID, 10),
        flagToken,
        attachment: {},
      });
    }
  });

  return {
    type: GET_FEATURE_FLAGS,
    payload: evaluatedFeatureFlags,
  };
};

/**
 * Send Error Action for Feature Flag
 *
 * @export
 * @param {string|object} err Error Data
 * @returns {object} Redux Action
 */
export function sendFeatureFlagError(err) {
  return { type: FEATURE_FLAGS_ERROR, payload: err };
}

/**
 * Get Feature Flags
 *
 * @export
 * @param {number|string} [userId] User ID
 * @param {string|null} groupId Group ID
 * @param {object} flagsToOverride
 * @returns {*} Action for Redux
 */
export function getFeatureFlags(userId, groupId = null, flagsToOverride = {}) {
  if (!userId) {
    return sendFeatureFlagError('no-userid');
  }

  /* istanbul ignore next */
  const userAgent = CLIENT && window?.navigator?.userAgent;
  /* istanbul ignore next */
  const url = CLIENT && window?.location?.href;
  /* istanbul ignore next */
  const referrer = CLIENT && document?.referrer;

  const entity = {
    entityID: userId,
    entityType: 'user',
    entityContext: {
      user: userId,
    },
  };

  if (groupId) {
    entity.entityContext.groupId = groupId;
  }

  /* istanbul ignore next */
  if (referrer) {
    entity.entityContext.referrer = referrer;
  }
  /* istanbul ignore next */
  if (userAgent) {
    entity.entityContext.userAgent = userAgent;
  }
  /* istanbul ignore next */
  if (url) {
    entity.entityContext.url = url;
  }

  const entities = [entity];
  const options = {
    method: 'POST',
    body: JSON.stringify({
      entities,
      flagIDs: featureFlags.flags,
    }),
  };

  return dispatch => {
    return dispatch(apiCall(apiUrl, options))
      .then(data => dispatch(parseFlags(data, flagsToOverride)))
      .catch(error => dispatch(sendFeatureFlagError(error)));
  };
}

/**
 * User Based Data
 *
 * @export
 * @param {number|string} testId Test ID
 * @param {number|string} userId User ID
 * @returns {object} Action for Redux
 */
export function getUserGroup(testId, userId) {
  if (!testId) {
    return sendFeatureFlagError('no-testid');
  }

  if (!userId) {
    return sendFeatureFlagError('no-userid');
  }

  const testApiUrl = '/tracking/getUserGroup';
  const options = {
    method: 'POST',
    body: JSON.stringify({
      testId,
      userId,
    }),
  };

  return dispatch => {
    return dispatch(apiCall(testApiUrl, options, false))
      .then(data =>
        dispatch({ type: FEATURE_FLAGS_GETUSERGROUP, payload: data.userGroup }),
      )
      .catch(error => dispatch(sendFeatureFlagError(error)));
  };
}
/**
 *
 * @param {object} queryParams  React Router location.query object
 */
export const filterFlagParams = queryParams => {
  return Object.entries(queryParams)
    .filter(queryParam => {
      return queryParam[0].indexOf('flag_') > -1;
    })
    .reduce((acc, [paramName, paramValue]) => {
      acc[paramName.replace('flag_', '')] = paramValue;
      return acc;
    }, {});
};

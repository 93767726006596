import { IMAGE_SERVICE_URL, BASE_URL } from 'app/config.js';
import { trackAction } from 'app/shared/utils/tracking';
import { IVehicleImage } from 'app/types/entities/Vehicle.types';
import { INormalizedImage } from 'app/marketplace/vehicle/ImageGallery/ImageGallery.types';

export const defaultImageModels = {
  banner: {
    no_banner: 0,
    banner: 1,
  },
  position: {
    other: 0,
    side: 0,
    back: 0,
    front: 1,
    interior: 0,
  },
};

export const imageUrlHandler = (size: string, url: string): string =>
  `${IMAGE_SERVICE_URL as string}/unsafe/${size}/filters:quality(90)/${url}`;

export const extractYTVideoId = (videoUrl = ''): string => {
  const regExp = /^.*(youtu\.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
  const match = regExp.exec(videoUrl);
  if (match && match[2].length === 11) {
    return match[2];
  }
  return '';
};
export const createVideoLink = ({
  videoId = '',
  alt = '',
}: {
  videoId: string;
  alt: string;
}): INormalizedImage => ({
  url: '',
  id: '12334566758',
  original: `https://img.youtube.com/vi/${videoId}/sddefault.jpg`,
  embedUrl: `https://www.youtube.com/embed/${videoId}?showinfo=0&controls=1`,
  alt,
});

export const createCalculatorImage = (
  backgroundImage: Pick<IVehicleImage, 'id' | 'url'>,
): Pick<IVehicleImage, 'id' | 'url'> => ({
  ...backgroundImage,
  id: 'fallback_image_for_calculator',
});

export const centerPadding: (
  deviceType: string,
  tabletOrientation: boolean,
) => string = (deviceType, isTabletPortraitViewport) => {
  if (deviceType === 'tablet' && isTabletPortraitViewport) {
    return '200px 0 0';
  }
  if (deviceType === 'tablet' && !isTabletPortraitViewport) {
    return '130px 0 0';
  }
  if (deviceType === 'desktop') {
    return '60px 0 0';
  }
  return '4px 0 0';
};

export const getFallbackImages = (
  images: IVehicleImage[] | [],
  showCTAImage = true,
): IVehicleImage[] => {
  const imagesLength = images.length;
  const fallBackImages = [...images];
  const fallbackImage = (idx: number): IVehicleImage => ({
    contentType: 'image/jpeg',
    id: `fallback_image_${idx}`,
    state: 'CREATED',
    url: `${BASE_URL}/assets/vehicle/gallery/car_fallback_image.jpg`,
    models: {
      banner: {
        no_banner: 0,
        banner: 1,
      },
      position: {
        other: 0,
        side: 0,
        back: 0,
        front: 1,
        interior: 0,
      },
    },
  });
  const ctaBackgroundImage = (): IVehicleImage => ({
    contentType: 'image/jpeg',
    id: 'fallback_image_background_CTA',
    state: 'CREATED',
    url:
      `${BASE_URL}/assets/vehicle/gallery/car_fallback_blank_for_cta.jpg`,
    models: {
      banner: {
        no_banner: 0,
        banner: 1,
      },
      position: {
        other: 0,
        side: 0,
        back: 0,
        front: 1,
        interior: 0,
      },
    },
  });

  const pushImage = (idx: number): void => {
    const image = showCTAImage ? ctaBackgroundImage() : fallbackImage(idx);
    fallBackImages.push(image);
  };

  switch (imagesLength) {
    case 0:
      fallBackImages.push(fallbackImage(0));
      pushImage(1);
      fallBackImages.push(fallbackImage(2));
      return fallBackImages;
      break;
    case 1:
      fallBackImages.push(fallbackImage(1));
      pushImage(2);
      return fallBackImages;
      break;
    case 2:
      pushImage(3);
      return fallBackImages;
      break;
    default:
      return fallBackImages;
  }
};

export const getFallbackPromoImages = (
  images: IVehicleImage[] | [],
  showTUF: boolean = false,
  showBboni: boolean = false
): IVehicleImage[] => {
  const ctaBackgroundImage = (): IVehicleImage => ({
    contentType: 'image/jpeg',
    id: 'fallback_image_tuf_CTA',
    state: 'CREATED',
    url:
      `${BASE_URL}/assets/vehicle/gallery/car_fallback_blank_for_cta.jpg`,
    models: {
      banner: {
        no_banner: 0,
        banner: 1,
      },
      position: {
        other: 0,
        side: 0,
        back: 0,
        front: 1,
        interior: 0,
      },
    },
  });
  const modifiedImages = [...images];

  // istanbul ignore next
  if (showTUF) {
    // add new element on 15th position
    modifiedImages.splice(
      modifiedImages.length > 15 ? 14 : modifiedImages.length,
      0,
      ctaBackgroundImage(),
    );
  }

  if (showBboni) {
    modifiedImages.splice(
      modifiedImages.length > 8 ? 8 : modifiedImages.length, 
      0, 
      { ...ctaBackgroundImage(), id: 'fallback_image_bboni' }
    );
  }

  return modifiedImages;
};

/* ----- Tracking Events ----- */

export const trackImageGalleryFullScreen = (
  target: string,
  isOpen: boolean,
): void => {
  trackAction('vehicle_carousel__fullscreen_click', {
    category: `${target}`,
    label: isOpen ? 'closing' : 'opening',
    description: 'user clicks on vehicle carousel fullscreen button',
  });
};

export const trackImageGalleryArrowClick = (
  target: string,
  vehicleId: string,
  index: number,
): void => {
  trackAction(
    'vehicle_carousel_click',
    {
      category: `${target}`,
      label: `${vehicleId}`,
      value: `${index}`,
      description: 'user clicks on vehicle carousel',
    },
    {
      content_ids: [`${vehicleId}`],
      content_type: 'vehicle',
    },
  );
};

export const trackImageGalleryPlayVideo = (): void => {
  trackAction('image_carousel_play_button_click', {
    category: 'PDP',
    label: 'video_play_icon_clicked',
    description: 'user click the video play icon on PDP image carousel',
  });
};

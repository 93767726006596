import { Text1, Text2, Icon, Link } from 'app/shared/ui';
import { trackAction } from 'app/shared/utils/tracking';

import { NavContactSt } from './NavContact.css';
import { INavContact } from './NavContact.types';

export const NavContact = ({ checkoutType }: INavContact): JSX.Element => {
  const onPhoneClick = () => {
    let eventLabel = 'checkout';

    if (checkoutType) {
      if (checkoutType === 'cash') eventLabel = 'd2c_cash';
      if (checkoutType === 'dealer-cash') eventLabel = 'dealer_cash';
      if (checkoutType === 'finance') eventLabel = 'd2c_finance';
    }

    trackAction('contact_phone_checkout_header_click', {
      label: eventLabel,
      description: 'user clicks on contact number on navbar in checkout page',
    });
  };
  return (
    <NavContactSt>
      <Text1 className="help-text">Brauchst du Hilfe?</Text1>
      <Icon icon="call" iconSize="xsmall" />
      <Link
        onClick={onPhoneClick}
        useRouter={false}
        href="tel:+493030809241"
        className="tel"
      >
        <Text2>+49 30 30809241</Text2>
      </Link>
    </NavContactSt>
  );
};

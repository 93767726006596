/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-return */
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { getFavoriteIds } from 'app/marketplace/favourites/selectors';
import {
  budgetRangeSwitchFilterSelector,
  performanceRangeValueFilterSelector,
} from 'app/marketplace/filter/selectors';
import { deviceSelector } from 'app/selectors';
import { getReducedCars } from 'app/marketplace/search/actions';
import { toggleFavourite } from 'app/marketplace/favourites/actions';
import {
  getReducedPriceVehicles,
  getReducedPriceVehiclesLoadingSelector,
} from 'app/marketplace/search/selectors.js';
import { breakpointSelector } from 'app/shared/modules/globalEvents/selectors';
import { makeLazy } from 'app/shared/components/LazyWrapper/LazyWrapper';

/* load this component at client side as it has no seo impact */
export const LazyReducedPriceCars = makeLazy({
  getComponent: /* istanbul ignore next */ () => {
    return import(
      'app/marketplace/home/ReducedPriceCars/ReducedPriceCars' /* webpackChunkName: "ReducedPriceCars" */
    );
  },
  exportName: 'ReducedPriceCars',
  spinner: null,
});

const mapStateToProps = createStructuredSelector({
  favouriteIds: getFavoriteIds,
  isLoading: getReducedPriceVehiclesLoadingSelector,
  breakpoint: breakpointSelector,
  reducedPriceVehicles: getReducedPriceVehicles,
  budgetRangeValue: budgetRangeSwitchFilterSelector,
  performanceUnit: performanceRangeValueFilterSelector,
  currentLocation: (state) => state.geolocation.currentLocation,
  deviceType: (state) => deviceSelector(state),
});

export const ConnectedReducedPriceCars = connect(mapStateToProps, {
  getReducedCars,
  toggleFavourite,
})(LazyReducedPriceCars);

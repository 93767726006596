import { PureComponent } from 'react';
import PropTypes from 'prop-types';

export class OverlayRoot extends PureComponent {
  render() {
    const { activeComponent, closeOverlay } = this.props;

    // early return because there is no reason to show anything when the overlay is not open.
    if (!activeComponent) {
      return null;
    }

    const Component = activeComponent.component;
    const componentProps =
      activeComponent.config && activeComponent.config.props;
    return Component ? (
      <Component {...componentProps} closeOverlay={closeOverlay} />
    ) : null;
  }
}

OverlayRoot.propTypes = {
  activeComponent: PropTypes.shape({
    // refine these
    component: PropTypes.any,
    config: PropTypes.object
  }),
  closeOverlay: PropTypes.func.isRequired
};

import { ConnectedModal } from 'app/shared/modules/overlay/defaultOverlays/modal/Modal';
import { LazyCallback } from 'app/marketplace/contactDealer/Callback/LazyCallback';

export const CallbackModal = ({ ...props }) => (
  <ConnectedModal
    ariaLabel="callback lead modal"
    title="Händler anrufen"
    headline="Bitte gib hier deine Kontaktdaten ein"
  >
    <LazyCallback {...props} isModal />
  </ConnectedModal>
);

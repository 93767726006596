import { getUrlParamsFromObj } from 'app/marketplace/search/utils';
import { normalizeVehiclePayload } from 'app/shared/utils/vehicle';

import * as ActionTypes from './constants';
import { SearchActionTypes } from './redux/types';

export const defaultState = () => {
  return {
    pending: 0,
    vehicleCount: null,
    resultsVehicleCount: 0,
    vehicleCountPending: 0,
    vehicles: [],
    lastSearchUrl: '',
    error: null,
    makes: [],
    models: [],
    suggestions: null,
    activeView: 'grid',
    lastSearchVehicles: [],
    lastSearchFilters: {},
    lastSearchVehiclesPending: true,
    isSidebarFiltersPreOpen: false,
    vehicleSuggestions: {
      error: undefined,
      isLoading: false,
      vehicles: [],
    },
    d2cVehicles: {
      error: null,
      isLoading: false,
      vehicles: [],
      url: null,
    },
    reducedPriceVehicles: {
      error: null,
      isLoading: true,
      vehicles: [],
    },
    showVehicleCount: false,
    priceRange: { min: 0, max: 0 },
  };
};

const decrementPending = (state) => {
  return state.pending > 0 ? state.pending - 1 : 0;
};

export function search(state = defaultState(), action) {
  const { type, payload, error, query } = action;
  let vehicles = [];

  if (type === ActionTypes.HIDE_VEHICLE_COUNT) {
    return {
      ...state,
      showVehicleCount: false,
    };
  }
  if (type === ActionTypes.SHOW_VEHICLE_COUNT) {
    return {
      ...state,
      showVehicleCount: true,
    };
  }
  // prepare vehicle list if required
  if (type === ActionTypes.SEARCH_GET_VEHICLES && payload.content) {
    vehicles = normalizeVehiclePayload(payload);
  }

  if (type === ActionTypes.SEARCH_REQUEST_PENDING) {
    return {
      ...state,
      pending: state.pending + 1,
    };
  }

  if (type === ActionTypes.SEARCH_REQUEST_CANCELLED) {
    return { ...state, pending: decrementPending(state) };
  }
  if (type === ActionTypes.SEARCH_GET_VEHICLE_COUNT_PENDING) {
    return {
      ...state,
      vehicleCountPending: state.vehicleCountPending + 1,
    };
  }

  if (type === ActionTypes.SEARCH_GET_VEHICLE_COUNT_CANCELLED) {
    return {
      ...state,
      vehicleCountPending:
        state.vehicleCountPending > 0 ? state.vehicleCountPending - 1 : 0,
    };
  }
  if (type === ActionTypes.SEARCH_GET_VEHICLES) {
    return {
      ...state,
      vehicles,
      lastSearchUrl: query,
      resultsVehicleCount: payload.totalElements,
      pending: decrementPending(state),
      error: null,
    };
  }
  if (type === SearchActionTypes.GET_TOTAL_LISTINGS_COUNT) {
    return {
      ...state,
      vehicleCount: payload.totalElements,
      vehicleCountPending:
        state.vehicleCountPending > 0 ? state.vehicleCountPending - 1 : 0,
      error: null,
    };
  }
  if (type === ActionTypes.SEARCH_REQUEST_ERROR) {
    return {
      ...state,
      error,
      pending: decrementPending(state),
    };
  }
  if (type === ActionTypes.SEARCH_RESET_LISTING) {
    return {
      ...state,
      vehicles: [],
      vehicleCount: 0,
      pending: decrementPending(state),
      error: null,
    };
  }

  if (type === ActionTypes.SEARCH_GET_LAST_SEARCH_PENDING) {
    return { ...state, lastSearchVehiclesPending: true };
  }
  if (type === ActionTypes.SEARCH_GET_LAST_SEARCH_SUCCESS) {
    const lastSearchVehicles = normalizeVehiclePayload(payload).slice(0, 6);
    const lastSearchFilters = payload.searchFilter;
    const lastSearchUrl = getUrlParamsFromObj(payload.searchFilter);

    return {
      ...state,
      lastSearchVehicles,
      lastSearchFilters,
      lastSearchUrl,
      lastSearchVehiclesPending: false,
    };
  }

  if (type === ActionTypes.SEARCH_GET_LAST_SEARCH_FAILURE) {
    return {
      ...state,
      error,
      lastSearchVehiclesPending: false,
    };
  }

  if (type === ActionTypes.SEARCH_GET_REDUCEDPRICE_CARS_PENDING) {
    return {
      ...state,
      reducedPriceVehicles: {
        ...state.reducedPriceVehicles,
        error: undefined,
        isLoading: true,
      },
    };
  }
  if (type === ActionTypes.SEARCH_GET_REDUCEDPRICE_CARS_SUCCESS) {
    return {
      ...state,
      reducedPriceVehicles: {
        ...state.reducedPriceVehicles,
        vehicles: normalizeVehiclePayload(payload),
        error: undefined,
        isLoading: false,
      },
    };
  }
  if (type === ActionTypes.SEARCH_GET_REDUCEDPRICE_CARS_FAILURE) {
    return {
      ...state,
      reducedPriceVehicles: {
        ...state.reducedPriceVehicles,
        error,
        isLoading: true,
      },
    };
  }
  if (type === ActionTypes.OPEN_SIDEBAR_FILTERS) {
    return {
      ...state,
      isSidebarFiltersPreOpen: true,
    };
  }
  if (type === ActionTypes.CLOSE_SIDEBAR_FILTERS) {
    return {
      ...state,
      isSidebarFiltersPreOpen: false,
    };
  }

  if (type === ActionTypes.SEARCH_GET_VEHICLE_SUGGESTIONS_PENDING) {
    return {
      ...state,
      vehicleSuggestions: {
        ...state.vehicleSuggestions,
        error: undefined,
        isLoading: true,
      },
    };
  }

  if (type === ActionTypes.SEARCH_GET_VEHICLE_SUGGESTIONS_SUCCESS) {
    return {
      ...state,
      vehicleSuggestions: {
        ...state.vehicleSuggestions,
        vehicles: normalizeVehiclePayload(payload),
        error: undefined,
        isLoading: false,
      },
    };
  }

  if (type === ActionTypes.SEARCH_GET_VEHICLE_SUGGESTIONS_FAILURE) {
    return {
      ...state,
      vehicleSuggestions: {
        ...state.vehicleSuggestions,
        error,
        isLoading: false,
      },
    };
  }

  if (type === ActionTypes.SEARCH_GET_VEHICLES_D2C_PENDING) {
    return {
      ...state,
      d2cVehicles: {
        ...state.d2cVehicles,
        error: undefined,
        isLoading: true,
      },
    };
  }

  if (type === ActionTypes.SEARCH_GET_VEHICLES_D2C_SUCCESS) {
    return {
      ...state,
      d2cVehicles: {
        ...state.d2cVehicles,
        url: payload.url,
        vehicles: normalizeVehiclePayload(payload),
        error: undefined,
        isLoading: false,
      },
    };
  }

  if (type === ActionTypes.SEARCH_GET_VEHICLES_D2C_FAILURE) {
    return {
      ...state,
      d2cVehicles: {
        ...state.d2cVehicles,
        error,
        isLoading: false,
      },
    };
  }

  if (type === ActionTypes.GET_PRICE_RANGE) {
    const {
      aggregations: { price },
    } = payload;
    return {
      ...state,
      priceRange: {
        min: price[0]?.min,
        max: price[price?.length - 1]?.max,
      },
    };
  }

  return state;
}

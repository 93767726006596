import { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { IndeterminateLoadingSpinner } from 'app/shared/ui';

import { ButtonSt, LinkSt } from 'app/shared/components/Button/Button.css';

export class Button extends PureComponent {
  render() {
    const {
      bStyle,
      bTheme,
      children,
      disabled,
      isLoading,
      active,
      dataQa,
      customClasses,
      className,
      tabIndex,
      preventDefaultEvent,
      secondary,
      tertiaryLight,
      tertiaryDark,
      fullSize,
      width,
      isLink,
      href,
      target,
      type,
      ariaLabel,
    } = this.props;
    let { onClick } = this.props;
    let classNames = ['button'];

    if (isLoading) {
      classNames.push('loading');
    }

    if (active) {
      classNames.push('active');
    }

    if (customClasses) {
      classNames = classNames.concat(customClasses);
    }

    if (className) {
      classNames.push(className);
    }

    if (preventDefaultEvent) {
      const onC = onClick;

      onClick = (e) => {
        e.preventDefault();
        onC(e);
      };
    }

    let kind;

    if (
      tertiaryLight ||
      (bStyle.includes('tertiary') && bTheme.includes('light'))
    ) {
      kind = 'tertiaryLight';
    } else if (
      tertiaryDark ||
      (bStyle.includes('tertiary') && bTheme.includes('dark'))
    ) {
      kind = 'tertiaryDark';
    } else if (secondary || bStyle.includes('secondary')) {
      kind = 'secondary';
    } else {
      kind = 'primary';
    }

    const props = {
      className: classNames.join(' '),
      onClick,
      'data-qa': dataQa,
      disabled: disabled || isLoading,
      tabIndex,
      kind,
      width,
      type,
      'aria-label': ariaLabel,
    };
    return isLink ? (
      <LinkSt {...props} to={href} target={target}>
        {isLoading ? (
          <IndeterminateLoadingSpinner type="secondary" />
        ) : (
          children
        )}
      </LinkSt>
    ) : (
      <ButtonSt {...props} fullSize={fullSize}>
        {isLoading ? (
          <IndeterminateLoadingSpinner type="secondary" />
        ) : (
          children
        )}
      </ButtonSt>
    );
  }
}

Button.propTypes = {
  bStyle: PropTypes.oneOf(['primary', 'secondary', 'tertiary', 'home']),
  bTheme: PropTypes.oneOf(['light', 'dark']),
  width: PropTypes.string,
  onClick: PropTypes.func,
  children: PropTypes.any,
  disabled: PropTypes.bool,
  isLoading: PropTypes.bool,
  dataQa: PropTypes.string,
  active: PropTypes.bool,
  preventDefaultEvent: PropTypes.bool,
  customClasses: PropTypes.array,
  className: PropTypes.string,
  tabIndex: PropTypes.number,
  secondary: PropTypes.bool,
  tertiaryLight: PropTypes.bool,
  tertiaryDark: PropTypes.bool,
  fullSize: PropTypes.bool,
  isLink: PropTypes.bool,
  href: PropTypes.string,
  target: PropTypes.string,
  type: PropTypes.string,
  ariaLabel: PropTypes.string,
};

Button.defaultProps = {
  bStyle: 'primary',
  bTheme: 'light',
  active: false,
  preventDefaultEvent: false,
  tabIndex: 0,
  fullSize: false,
  isLink: false,
  href: '#',
  /* In order to prevent refreshing the page when the redirection occurs in the same tab, the target should be falsy.
   * Check reference on https://github.com/ReactTraining/react-router/blob/v3.2.1/modules/Link.js#L83-L86
   */
  target: undefined,
  type: undefined,
  ariaLabel: undefined,
};

import _get from 'lodash.get';

import magazineCategoriesMock from '../../../../fixtures/contentfulMocks/magazineCategories.json';

import { ActionTypes } from './constants';

const defaultState = () => {
  return {
    contentfulLoading: true,
    jobsPending: false,
    entry: {},
    relatedArticles: {},
    articlesByCategory: {},
    jobs: {},
    modals: {},
    featuredArticles: [],
    latestArticles: [],
    newsByCategory: [],
    newsByCategoryLoading: false,
    selectedNewsCategory: null,
    magazineCategories: [],
    usps: {},
    campaignHero: {},
    campaignHeroReqDone: false,
    warrantySealData: [],
    campaigns: [],
    d2cFAQs: {},
    d2cExtras: {},
  };
};

export function contentful(state = defaultState(), action) {
  const { type, payload } = action;

  switch (type) {
    case ActionTypes.CONTENTFUL_ENTRY_STARTED:
    case ActionTypes.CONTENTFUL_ARTICLES_BY_CATEGORY_STARTED:
    case ActionTypes.CONTENTFUL_RELATED_ARTICLES_STARTED:
    case ActionTypes.CONTENTFUL_FEATURED_ARTICLES_STARTED:
    case ActionTypes.CONTENTFUL_ARTICLES_BY_LATEST_STARTED:
    case ActionTypes.CONTENTFUL_MAGAZINE_CATEGORIES_STARTED:
    case ActionTypes.CONTENTFUL_GET_LIST_OF_MAKE_PAGES_FROM_SEO_SPACE_STARTED:
    case ActionTypes.GET_CAMPAIGN_HERO_STARTED:
    case ActionTypes.GET_USPS_STARTED:
    case ActionTypes.CONTENTFUL_GET_JOBS_STARTED: {
      return {
        ...state,
        contentfulLoading: true,
      };
    }

    case ActionTypes.CONTENTFUL_NEWS_BY_CATEGORY_STARTED: {
      return {
        ...state,
        newsByCategoryLoading: true,
        selectedNewsCategory: payload.featuredCategory,
      };
    }

    case ActionTypes.CONTENTFUL_GET_LIST_OF_MAKE_PAGES_FROM_SEO_SPACE_COMPLETED: {
      return {
        ...state,
        contentfulLoading: false,
        makes: payload.makes,
      };
    }
    case ActionTypes.CONTENTFUL_GET_LIST_OF_MAKE_PAGES_FROM_SEO_SPACE_FAILED: {
      return {
        ...state,
        contentfulLoading: false,
        makes: {
          error: payload.error,
        },
      };
    }

    case ActionTypes.CONTENTFUL_ENTRY_COMPLETED: {
      return {
        ...state,
        contentfulLoading: false,
        [payload.identifier]: payload.entry,
      };
    }
    case ActionTypes.CONTENTFUL_ENTRY_FAILED: {
      return {
        ...state,
        contentfulLoading: false,
        [payload.identifier]: {
          error: payload.error,
        },
      };
    }

    case ActionTypes.CONTENTFUL_RELATED_ARTICLES_COMPLETED: {
      return {
        ...state,
        contentfulLoading: false,
        relatedArticles: {
          ...state.relatedArticles,
          [payload.identifier]: payload.entry,
        },
      };
    }
    case ActionTypes.CONTENTFUL_RELATED_ARTICLES_FAILED: {
      return {
        ...state,
        contentfulLoading: false,
        relatedArticles: {
          ...state.relatedArticles,
          [payload.identifier]: {
            error: payload.error,
          },
        },
      };
    }

    case ActionTypes.CONTENTFUL_ARTICLES_BY_CATEGORY_COMPLETED: {
      return {
        ...state,
        contentfulLoading: false,
        articlesByCategory: {
          ...state.articlesByCategory,
          [payload.identifier]: payload.entry,
        },
      };
    }
    case ActionTypes.CONTENTFUL_ARTICLES_BY_CATEGORY_FAILED: {
      return {
        ...state,
        contentfulLoading: false,
        articlesByCategory: {
          ...state.articlesByCategory,
          [payload.identifier]: {
            error: payload.error,
          },
        },
      };
    }

    case ActionTypes.CONTENTFUL_FEATURED_ARTICLES_COMPLETED: {
      return {
        ...state,
        contentfulLoading: false,
        featuredArticles: payload,
      };
    }

    case ActionTypes.CONTENTFUL_ARTICLES_BY_LATEST_COMPLETED: {
      return {
        ...state,
        contentfulLoading: false,
        latestArticles: payload,
      };
    }
    case ActionTypes.CONTENTFUL_ARTICLES_BY_LATEST_FAILED: {
      return {
        ...state,
        contentfulLoading: false,
        latestArticles: payload,
      };
    }

    case ActionTypes.CONTENTFUL_NEWS_BY_CATEGORY_COMPLETED: {
      return {
        ...state,
        newsByCategoryLoading: false,
        newsByCategory: {
          ...state.newsByCategory,
          [payload.featuredCategory]: payload.entry,
        },
      };
    }
    case ActionTypes.CONTENTFUL_NEWS_BY_CATEGORY_FAILED: {
      return {
        ...state,
        newsByCategoryLoading: false,
        newsByCategory: {
          ...state.newsByCategory,
          [payload.featuredCategory]: {
            error: payload.error,
          },
        },
      };
    }

    case ActionTypes.CONTENTFUL_MAGAZINE_CATEGORIES_COMPLETED: {
      return {
        ...state,
        contentfulLoading: false,
        magazineCategories: payload.entry,
      };
    }
    case ActionTypes.CONTENTFUL_MAGAZINE_CATEGORIES_FAILED: {
      return {
        ...state,
        contentfulLoading: false,
        magazineCategories: magazineCategoriesMock,
      };
    }

    case ActionTypes.CONTENTFUL_GET_JOBS_COMPLETED: {
      return {
        ...state,
        jobsPending: false,
        jobs: payload,
        contentfulLoading: false,
      };
    }
    case ActionTypes.CONTENTFUL_GET_JOBS_FAILED: {
      return {
        ...state,
        jobsPending: false,
        error: payload.error,
        contentfulLoading: false,
      };
    }

    case ActionTypes.CONTENTFUL_ADD_MODAL: {
      return {
        ...state,
        modals: {
          ...state.modals,
          [payload.modalId]: false,
        },
      };
    }
    case ActionTypes.CONTENTFUL_REMOVE_MODAL: {
      const modals = {
        ...state.modals,
      };
      delete modals[payload.modalId];

      return {
        ...state,
        modals,
      };
    }
    case ActionTypes.CONTENTFUL_TRIGGER_MODAL: {
      if (
        state.modals[payload.modalId] === null ||
        typeof state.modals[payload.modalId] === 'undefined'
      ) {
        return state;
      }

      return {
        ...state,
        modals: {
          ...state.modals,
          [payload.modalId]: !state.modals[payload.modalId],
        },
      };
    }

    case ActionTypes.GET_CAMPAIGN_HERO_COMPLETED:
      return {
        ...state,
        campaignHero: payload,
        campaignHeroReqDone: true,
        contentfulLoading: false,
      };

    case ActionTypes.GET_CAMPAIGN_HERO_FAILED:
      return {
        ...state,
        campaignHero: {
          error: payload,
        },
        campaignHeroReqDone: true,
        contentfulLoading: false,
      };

    case ActionTypes.GET_USPS_COMPLETED:
      return {
        ...state,
        usps: payload,
        contentfulLoading: false,
      };

    case ActionTypes.GET_USPS_FAILED:
      return {
        ...state,
        usps: {
          error: payload,
        },
        contentfulLoading: false,
      };

    case ActionTypes.FINISH_LOADING:
      return {
        ...state,
        contentfulLoading: false,
      };

    case ActionTypes.SUCCESS_WARRANTYSEALDATA_REQUEST: {
      return {
        ...state,
        warrantySealData: payload,
      };
    }

    case ActionTypes.FAILURE_WARRANTYSEALDATA_REQUEST: {
      return {
        ...state,
        warrantySealData: [{ error: true, ...payload }],
      };
    }

    case ActionTypes.GET_FAQ:
      return { ...state, contentfulLoading: true };
    case ActionTypes.GET_FAQ_SUCCESS:
      return {
        ...state,
        contentfulLoading: false,
        d2cFAQs: {
          ...state.d2cFAQs,
          [payload.sys.id]: payload,
        },
      };

    case ActionTypes.GET_D2C_EXTRAS:
      return { ...state, contentfulLoading: true };
    case ActionTypes.GET_D2C_EXTRAS_SUCCESS:
      return {
        ...state,
        contentfulLoading: false,
        d2cExtras: {
          ...state.d2cExtras,
          [payload.sys.id]: payload,
        },
      };

    default:
      return state;
  }
}

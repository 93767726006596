export const TRADEIN_SET_VEHICLE_PDP = 'TRADEIN/SET/VEHICLE_PDP';
export const TRADEIN_RUN = 'TRADEIN/RUN';
export const TRADEIN_RESET = 'TRADEIN/RESET';
export const TRADEIN_SET_VEHICLE = 'TRADEIN/SET/VEHICLE';
export const TRADEIN_SET_STATUS = 'TRADEIN/SET/STATUS';
export const TRADEIN_SCREEN_NEXT = 'TRADEIN/SCREEN/NEXT';
export const TRADEIN_SCREEN_CHANGE = 'TRADEIN/SCREEN/CHANGE';
export const TRADEIN_STEP_PREV = 'TRADEIN/STEP/PREV';
export const TRADEIN_STEP_NEXT = 'TRADEIN/STEP/NEXT';
export const TRADEIN_NEXT = 'TRADEIN/NEXT';
export const TRADEIN_SET_VEHICLE_STATUS = 'TRADEIN/SET/VEHICLE_STATUS';
export const TRADEIN_UPDATE_STEP = 'TRADEIN/UPDATE/STEP';
export const TRADEIN_SET_SCREEN_NAMES = 'TRADEIN/SET/SCREEN_NAMES';
export const TRADEIN_USER_SURVEY = 'TRADEIN/SET/USER_SURVEY';
export const TRADEIN_RESET_STEP = 'TRADEIN/RESET/STEP';

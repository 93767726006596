/* istanbul ignore file */
import { connect } from 'react-redux';

import {
  getSpecialOffers,
  getSpecialOffersClpBanner,
} from 'app/marketplace/specialOffers/actions';
import { checkFeatureFlagHasToken } from 'app/selectors';
import {
  getClpBanner,
  getSpecialOfferSelector,
} from 'app/marketplace/specialOffers/selectors';
import { breakpointSelector } from 'app/shared/modules/globalEvents/selectors';

import { SpecialOffersBanner } from './SpecialOffersBanner';

function mapStateToProps(state) {
  return {
    breakpoint: breakpointSelector(state),
    clpBanner: getClpBanner(state),
    specialOffer: getSpecialOfferSelector(state),
    location: state.routing.locationBeforeTransitions,
    showCLPBannerInDifferentPosition:
      checkFeatureFlagHasToken(state, 261, 'show_variant') &&
      checkFeatureFlagHasToken(state, 256, 'show_variant'),
  };
}

export const ConnectedSpecialOffersBanner = connect(mapStateToProps, {
  getSpecialOffers,
  getSpecialOffersClpBanner,
})(SpecialOffersBanner);

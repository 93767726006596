import { routerMiddleware } from 'react-router-redux';
import { applyMiddleware, createStore, compose } from 'redux';
import { persistStore } from 'redux-persist';
import thunk from 'redux-thunk';
import createSagaMiddleware from 'redux-saga';

import { getAppReducer } from './app/reducer';
import { rootSaga } from './app/sagas';

const enhancer = ({ history, sagaMiddleware }) => {
  const routingMiddleware = routerMiddleware(history);
  const functions = [thunk, routingMiddleware];

  if (sagaMiddleware) {
    functions.push(sagaMiddleware);
  }
  const composeEnhancers =
    typeof window === 'object' &&
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ &&
    DEVELOPMENT
      ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
          trace: true,
          traceLimit: 25,
        })
      : compose;

  return composeEnhancers(applyMiddleware(...functions));
};

export function configureStore({
  hasUserConsent = true,
  preloadedState = {},
  history,
  storage,
  callback,
}) {
  let sagaMiddleware = null;
  if (CLIENT) {
    sagaMiddleware = createSagaMiddleware();
  }
  const store = createStore(
    getAppReducer(storage, hasUserConsent),
    preloadedState,
    enhancer({ history, sagaMiddleware }),
  );
  const persistor = persistStore(store, {}, callback);

  /*
   currently we only want to run sagas on the client because there is no way for us to tell when all sagas have 
   'finished' running on the server which can lead to unexpected behaviour
   */
  if (CLIENT && sagaMiddleware) {
    sagaMiddleware.run(rootSaga);
  }

  return { persistor, store };
}

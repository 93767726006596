import { connect } from 'react-redux';

import { getCampaigns } from 'app/marketplace/contentful/actions';
import { contentfulCampaignsSelector } from 'app/marketplace/contentful/selectors';

import { PrintableVoucherCode } from './PrintableVoucherCode';
import {WEB_APP_URL} from "app/config";

PrintableVoucherCode.loadAction = (_, props) => {
  const {
    location: { query },
  } = props;

  let isPreviewContentful = false;
  if (query?.isPreviewContentful === null) isPreviewContentful = true;

  return (dispatch) => {
    return Promise.all([
      dispatch(
        getCampaigns({
          isPreviewContentful,
          base: WEB_APP_URL
        }),
      ),
    ]);
  };
};

function mapStateToProps(state) {
  return {
    campaigns: contentfulCampaignsSelector(state),
  };
}

export const ConnectedPrintableVoucherCode = connect(mapStateToProps)(
  PrintableVoucherCode,
);

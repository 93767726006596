import { PAGINATION_SIZE } from 'app/config';

export const getSearchParamsFromVehicleSelected = (
  make,
  model,
  price,
  location,
  categories,
  radius,
) => {
  const { lat, lon } = location;
  const pricePercent = price * 0.15;
  const minPriceRange = Math.round((price - pricePercent) / 1000) * 1000;
  const maxPriceRange = Math.round((price + pricePercent) / 1000) * 1000;
  const priceRange = `${minPriceRange}-${maxPriceRange}`;
  const category = categories.join();

  return {
    firstSearchUrl: `/search?reducedContent=true&size=${PAGINATION_SIZE}&make=${make}&model=${make}_${model}&priceRange=${priceRange}&lat=${lat}&lon=${lon}&radius=${radius}km`,
    secondSearchUrl: `/search?reducedContent=true&size=${PAGINATION_SIZE}&category=${category}&priceRange=${priceRange}&lat=${lat}&lon=${lon}&radius=${radius}km`,
  };
};

// ranked by popularity on heycar, see https://heycar.atlassian.net/wiki/spaces/PET/pages/312770601/Popular+Email+Providers
export const DOMAINS = [
  'gmail.com',
  'googlemail.com',
  'gmx.de',
  'gmx.net',
  'web.de',
  'hotmail.de',
  'hotmail.com',
  't-online.de',
  'yahoo.de',
  'yahoo.com',
  'ymail.com',
  'icloud.com',
  'freenet.de',
  'outlook.de',
  'outlook.com',
  'aol.com',
  'aol.de',
  'arcor.de',
  'online.de',
  'mail.de',
  'me.com',
  'kabelmail.de',
  'msn.com',
  'protonmail.com',
  'pm.me',
  'live.de',
  'live.com',
  'mac.com',
  'facebook.com',
  'zoho.com',
  'mail.com',
  'yandex.com',
  'hey.car',
];

export const getSuggestion = emailAddress => {
  const partialDomain = emailAddress.split('@')[1];
  if (!partialDomain || partialDomain.length === 0) return ''; // only suggest after __First character
  const matchedDomain = DOMAINS.find(d => d.startsWith(partialDomain)) || '';
  return matchedDomain.substr(partialDomain.length);
};

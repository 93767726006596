/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { BrandsMenuItems } from '../../MenuItemsList';

export const Brands = {
  moreBrandsUrl: {
    label: 'Alle Marken',
    url: '/gebrauchtwagen',
  },
  label: 'Unsere Marken',
  brands: BrandsMenuItems,
};

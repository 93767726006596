/* istanbul ignore file */
// TODO: test this file
import { trackAction } from 'app/shared/utils/tracking';

import { TRACKING_LIST } from '../../tracking/list';

export const getCartilePriceTracking = ({
  id,
  target,
  isD2CVehicle,
  isId3Vehicle,
}) => {
  if (isD2CVehicle) {
    return () => {
      trackAction('d2c_monthly_rate_link_click', {
        category: target,
        value: `${id}`,
        description: `user clicks on the monthly rate of a d2c car in the ${target}`,
      });
    };
  }
  if (isId3Vehicle) {
    return () => {
      trackAction('id3_monthly_rate_link_click', {
        category: target,
        value: `${id}`,
        description: `user clicks on the monthly rate of the id3push card in the ${target}`,
      });
    };
  }
  return () => {
    trackAction(TRACKING_LIST.FINANCING.LINKS.MONTHLY_RATE_BUTTON, {
      category: target,
      label: 'listingId',
      value: `${id}`,
      description: `user clicks on the monthly rate of a car in the ${target}`,
    });
  };
};

import styled, { css } from 'styled-components';

import { media } from 'app/shared/styles_js/variables';
import { GridContainer, Heading4 } from 'app/shared/ui';

export const SliderTrustPilotWidgetSt = styled.div`
  padding: 40px 0;
  background-color: ${({ theme: { colors } }) => colors.sunbeanSuperLight};

  ${media.md(css`
    padding: 72px 0;
  `)};
`;

export const SliderTrustPilotWidgetInnerContainer = styled(GridContainer).attrs(
  () => ({
    hasDefaultWidth: true,
  }),
)`
  ${media.md(css`
    grid-template-columns: repeat(1, 1fr);
  `)}
`;

export const HeadlineSt = styled(Heading4).attrs(
  /* istanbul ignore next */ () => ({
    fontSize: [24, 28, 32],
    lineHeight: [1.67, 1.5],
    fontWeight: 800,
  }),
)`
  width: 100%;
  margin-bottom: 40px;
  grid-column: 1 / -1;

  ${media.md(css`
    .grid-container:not(:first-of-type) & {
      margin: 32px 0 56px;
    }
  `)};

  ${media.xl(css`
    margin-bottom: 56px;
  `)}
`;

import styled, { css } from 'styled-components';
import { Heading4, ContainerSt } from 'app/shared/ui';

import { media, grid, evergreenOnly } from 'app/shared/styles_js/variables';

export const HomeSEOTextSt = styled(ContainerSt)`
  display: flex;
  flex-direction: column;
  margin-top: 40px;
  margin-bottom: 40px;
  ${media.md(
    css`
      flex-direction: row;
      margin-top: 72px;
      margin-bottom: 72px;
    `,
  )}
  ${media.lg(
    css`
      margin-top: 80px;
      margin-bottom: 80px;
    `,
  )}
  ${media.xl(
    css`
      margin-top: 72px;
      margin-bottom: 72px;
    `,
  )}
`;

export const TitleSt = styled(Heading4).attrs(
  /* istanbul ignore next */ () => ({
    fontSize: [24, 28, 32],
    lineHeight: [1.67, 1.5],
    fontWeight: 800,
  }),
)`
  ${media.md(css`
    grid-column: 1 / span 4;
    width: 25%;
    padding-right: ${grid.gutter.md}px;

    ${evergreenOnly(css`
      width: auto;
      padding-right: 0;
    `)};
  `)};
`;

export const TextSt = styled.div`
  width: 100%;
  padding-top: 10px;
  grid-column: 5 / span 8;

  a {
    color: ${({ theme: { colors } }) => colors.mintDark};
    text-decoration: underline;
    transition: color 200ms ease-in-out;

    &:hover,
    &:focus,
    &:active {
      color: ${({ theme: { colors } }) => colors.brandPrimary};
      text-decoration: underline;
    }
  }
`;

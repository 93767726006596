export const RESET = 'RESET';
export const RESET_PHONE_INFO = 'RESET_PHONE_INFO';
export const RESET_LEAD_FORM = 'RESET_LEAD_FORM';
export const RESET_CALLBACK = 'RESET_CALLBACK';
export const RESET_MAIL_VIDEO = 'RESET_MAIL_VIDEO';
export const RESET_VIDEO_APPOINTMENT = 'RESET_VIDEO_APPOINTMENT';

export const GET_PHONE_INFO_PENDING = 'GET_PHONE_INFO_PENDING';
export const GET_PHONE_INFO_SUCCESS = 'GET_PHONE_INFO_SUCCESS';
export const GET_PHONE_INFO_FAILURE = 'GET_PHONE_INFO_FAILURE';

export const SAVE_MESSAGE = 'SAVE_MESSAGE';
export const UNSET_MESSAGE_FOR_VEHICLE = 'UNSET_MESSAGE_FOR_VEHICLE';

export const STORE_LEAD_FORM = 'CONTACT_DEALER/STORE_LEAD_FORM';
export const VOUCHER_POST_LEAD = 'VOUCHER_POST_LEAD';

export const ADD_SOLD_OUT_VEHICLE_ID = 'ADD_SOLD_OUT_VEHICLE_ID';
export const RESET_SOLD_OUT_VEHICLE_IDS = 'RESET_SOLD_OUT_VEHICLE_IDS';

export const REMOVE_CONTACT_DETAILS_PENDING = 'REMOVE_CONTACT_DETAILS_PENDING';
export const REMOVE_CONTACT_DETAILS_SUCCESS = 'REMOVE_CONTACT_DETAILS_SUCCESS';

export const SUBMIT_MAIL_VIDEO_PENDING = 'SUBMIT_MAIL_VIDEO_PENDING';
export const SUBMIT_AUTOBILD_PENDING = 'SUBMIT_AUTOBILD_PENDING';
export const SUBMIT_MAIL_VIDEO_SUCCESS = 'SUBMIT_MAIL_VIDEO_SUCCESS';
export const SUBMIT_MAIL_VIDEO_FAILURE = 'SUBMIT_MAIL_VIDEO_FAILURE';
export const SUBMIT_MAIL_VIDEO_SUCCESS_ONE_CLICK_LEAD =
  'SUBMIT_MAIL_VIDEO_SUCCESS_ONE_CLICK_LEAD';
export const SUBMIT_MAIL_VIDEO_SUCCESS_ONE_CLICK_LEAD_SUCCESS =
  'SUBMIT_MAIL_VIDEO_SUCCESS_ONE_CLICK_LEAD_SUCCESS';
export const SUBMIT_MAIL_VIDEO_SUCCESS_ONE_CLICK_LEAD_FAILURE =
  'SUBMIT_MAIL_VIDEO_SUCCESS_ONE_CLICK_LEAD_FAILURE';

export const SUBMIT_CALLBACK_PENDING = 'SUBMIT_CALLBACK_PENDING';
export const SUBMIT_CALLBACK_SUCCESS = 'SUBMIT_CALLBACK_SUCCESS';
export const SUBMIT_CALLBACK_FAILURE = 'SUBMIT_CALLBACK_FAILURE';

export const SUBMIT_CALLBACK_ONE_CLICK_LEAD_PENDING =
  'SUBMIT_CALLBACK_ONE_CLICK_LEAD_PENDING';
export const SUBMIT_CALLBACK_ONE_CLICK_LEAD_SUCCESS =
  'SUBMIT_CALLBACK_ONE_CLICK_LEAD_SUCCESS';
export const SUBMIT_CALLBACK_ONE_CLICK_LEAD_FAILURE =
  'SUBMIT_CALLBACK_ONE_CLICK_LEAD_FAILURE';

export const SUBMIT_SELECTABLE_ONE_CLICK_LEADS_PENDING =
  'SUBMIT_SELECTABLE_ONE_CLICK_LEADS_PENDING';
export const SUBMIT_SELECTABLE_ONE_CLICK_LEADS_SUCCESS =
  'SUBMIT_SELECTABLE_ONE_CLICK_LEADS_SUCCESS';

export const SUBMIT_CALLBACK_SELECTABLE_ONE_CLICK_LEADS_PENDING =
  'SUBMIT_CALLBACK_SELECTABLE_ONE_CLICK_LEADS_PENDING';
export const SUBMIT_CALLBACK_SELECTABLE_ONE_CLICK_LEADS_SUCCESS =
  'SUBMIT_CALLBACK_SELECTABLE_ONE_CLICK_LEADS_SUCCESS';

export const SUBMIT_VIDEO_APPOINTMENT_PENDING =
  'SUBMIT_VIDEO_APPOINTMENT_PENDING';
export const SUBMIT_VIDEO_APPOINTMENT_SUCCESS =
  'SUBMIT_VIDEO_APPOINTMENT_SUCCESS';
export const SUBMIT_VIDEO_APPOINTMENT_FAILURE =
  'SUBMIT_VIDEO_APPOINTMENT_FAILURE';

export const SET_RECAPTCHA_CHALLENGE_ID = 'SET_RECAPTCHA_CHALLENGE_ID';

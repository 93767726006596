import { call, put, select, takeEvery } from 'redux-saga/effects';

import * as Config from 'app/config';
import { updateProfileSaga } from 'app/marketplace/accounts/sagas';
import { getCommonDataForFormSubmission } from 'app/marketplace/contactDealer/actions';
import * as ActionTypes from 'app/marketplace/contactDealer/constants';
import { storeLeadForm } from 'app/marketplace/contactDealer/sagas';
import {
  LEAD_SERVICE_EXCEPTIONS,
  LEAD_SOURCE,
} from 'app/marketplace/contactDealer/utils';
import { PREMIUM_POST_LEAD } from 'app/marketplace/premium/actions';
import { UNSET_CALCULATION_FOR_VEHICLE } from 'app/shared/modules/FDL/CalculatorV3/redux/constants';
import { getCalculation } from 'app/shared/modules/FDL/CalculatorV3/redux/selector';
import { isFinanceEligible } from 'app/shared/modules/FDL/utils';
import { apiCallWithRecaptchaSaga } from 'app/shared/modules/recaptcha/utils/apiCallWithRecaptcha';
import { trackIdentify } from 'app/shared/utils/tracking';

export const getSelectableOclRequestAccordingToLeadType = (state, payload) => {
  const { form, leadId } = state.activeLeadManagement || {};

  const selectedleads = payload?.oneClickLeads.map(
    (listing) => listing.vehicle,
  );

  if (payload?.type === 'email') {
    return {
      ...form,
      phone: form?.phoneNumber,
      oneClickParentLeadId: leadId,
      oneClickExtraListingIds: selectedleads.map((listing) => listing.id),
      oneClickLeadVersion: 'v2',
    };
  }

  return {
    ...form,
    phone: form?.phoneNumber,
    leadSource: LEAD_SOURCE,
    type: 'callback',
    vehicleListingId: form?.vehicleListingId,
    oneClickParentLeadId: leadId,
    oneClickExtraListingIds: selectedleads.map((listing) => listing.id),
    eventId: form?.eventId,
    oneClickLeadVersion: 'v2',
  };
};

export const getAutomatedOclRequestAccordingToLeadType = (state, payload) => {
  if (payload?.form?.type === 'email') {
    return {
      ...payload.form,
      phone: payload.form?.phoneNumber,
      oneClickParentLeadId: payload.leadId,
      oneClickExtraListingIds: payload?.oneClickLeads.map(
        (listing) => listing.id,
      ),
      oneClickLeadVersion: 'v1',
    };
  }

  return {
    ...payload.form,
    phone: payload.phone,
    leadSource: LEAD_SOURCE,
    type: 'callback',
    vehicleListingId: state?.vehicle?.vehicle?.id,
    oneClickParentLeadId: payload?.leadId,
    oneClickExtraListingIds:
      payload?.oneClickLeads &&
      payload?.oneClickLeads.length > 0 &&
      payload?.oneClickLeads.map((listing) => listing.id),
    eventId: payload.eventId,
    oneClickLeadVersion: 'v1',
  };
};

/**
 * Posts data to backend in order to create either an email or a video lead.
 * @param {object} payload: contains the form data, vehicle id and optionally url
 */
// eslint-disable-next-line consistent-return
export function* submitMailOrVideoLead({ payload }) {
  const { form, vehicleId, url = '/leads/customerEntry' } = payload;
  const {
    firstName,
    lastName,
    email,
    phone,
    type,
    tradeInValuationId,
    oneClickLeadsConsent,
  } = form;

  const state = yield select();

  try {
    const response = yield call(
      apiCallWithRecaptchaSaga,
      `${Config.COMMON_API_URL}${url}`,
      `${type}_lead_submit`,
      { body: JSON.stringify(form) },
    );
    const { customerId } = response;
    if (type === 'email') {
      trackIdentify(customerId, email);
    }
    const premiumPayload = {
      leadId: response.leadId,
      phoneNumber: phone,
      email,
      firstName,
      lastName,
      oneClickLeadsConsent: oneClickLeadsConsent ?? null,
      ...(tradeInValuationId && { tradeInValuationId }),
      form,
    };

    if (form?.oneClickLeadsConsent) {
      const oneClickLeads =
        state?.vehicle?.oneClickLeads?.automatedListings &&
        state?.vehicle?.oneClickLeads?.automatedListings.length > 0
          ? state?.vehicle?.oneClickLeads?.automatedListings
          : [];

      const oneClickLeadsPayload = {
        form,
        leadId: response.leadId,
        isSelectable: false,
        oneClickLeads,
      };

      yield put({
        type: ActionTypes.SUBMIT_MAIL_VIDEO_SUCCESS_ONE_CLICK_LEAD,
        payload: oneClickLeadsPayload,
      });
    }
    /* istanbul ignore next */
    yield put({
      type: ActionTypes.VOUCHER_POST_LEAD,
      payload: response?.campaignInfos,
    });
    yield put({ type: PREMIUM_POST_LEAD, payload: premiumPayload });

    yield put({ type: ActionTypes.SUBMIT_MAIL_VIDEO_SUCCESS });

    yield put({
      type: ActionTypes.UNSET_MESSAGE_FOR_VEHICLE,
      payload: { id: vehicleId, leadType: type },
    });
    yield put({
      type: UNSET_CALCULATION_FOR_VEHICLE,
      payload: {
        id: vehicleId,
      },
    });
    return response;
  } catch (error) {
    yield put({
      type: ActionTypes.SUBMIT_MAIL_VIDEO_FAILURE,
      payload: { error },
    });
    if (error.message?.includes(LEAD_SERVICE_EXCEPTIONS.CAR_SOLD_OUT)) {
      yield put({
        type: ActionTypes.ADD_SOLD_OUT_VEHICLE_ID,
        payload: vehicleId,
      });
    }
  }
}

export function* submitOneClickLeads({ payload }) {
  const url = '/leads/customerEntry/oneClickLeads';

  const state = yield select();

  const oneClickLeadRequest = payload?.isSelectable
    ? getSelectableOclRequestAccordingToLeadType(state, payload)
    : getAutomatedOclRequestAccordingToLeadType(state, payload);

  try {
    const response = yield call(
      apiCallWithRecaptchaSaga,
      `${Config.COMMON_API_URL}${url}`,
      'one_click_leads_submit',
      { body: JSON.stringify(oneClickLeadRequest) },
    );
    const actionTypeSelectable =
      payload.form.type === 'email'
        ? ActionTypes.SUBMIT_SELECTABLE_ONE_CLICK_LEADS_SUCCESS
        : ActionTypes.SUBMIT_CALLBACK_SELECTABLE_ONE_CLICK_LEADS_SUCCESS;

    const actionTypeNotSelectable =
      payload.form.type === 'email'
        ? ActionTypes.SUBMIT_MAIL_VIDEO_SUCCESS_ONE_CLICK_LEAD_SUCCESS
        : ActionTypes.SUBMIT_CALLBACK_ONE_CLICK_LEAD_SUCCESS;

    yield put({
      type: payload?.isSelectable
        ? actionTypeSelectable
        : actionTypeNotSelectable,
    });

    return response;
  } catch (error) {
    yield put({
      type: ActionTypes.SUBMIT_MAIL_VIDEO_SUCCESS_ONE_CLICK_LEAD_FAILURE,
      payload: { error },
    });
    if (error.message?.includes(LEAD_SERVICE_EXCEPTIONS.CAR_SOLD_OUT)) {
      yield put({
        type: ActionTypes.ADD_SOLD_OUT_VEHICLE_ID,
        payload: payload.form.vehicleListingId,
      });
    }
  }
}
/**
 * Prepare data to create either an email or a video lead.
 * @param {object} payload: contains the form data, vehicle object, token and extra props
 */
export function* prepareMailOrVideoLead({ payload }) {
  const { formObj, vehicle, token, extras } = payload;

  yield call(storeLeadForm, formObj);
  if (token) {
    yield call(updateProfileSaga, formObj);
  }

  const state = yield select();
  const {
    message,
    tracking,
    valuationId,
    captiveId,
    utmId,
  } = getCommonDataForFormSubmission(state, vehicle);
  const leadType = extras.type || 'email';

  const form = {
    ...extras,
    type: leadType,
    message: extras.message || message,
    leadSource: LEAD_SOURCE,
    trackingId: tracking.guid,
    vehicleListingId: vehicle.id,
    ...(captiveId && { fdlConditions: captiveId }),
    ...(utmId && { price: vehicle.price, utmId }),
    ...(leadType === 'email' &&
      valuationId && { tradeInValuationId: valuationId }),
  };

  const calculation = getCalculation(state, vehicle.id);

  if (calculation && isFinanceEligible(captiveId)) {
    form.interestedInFinancing = true;
    form.financingCalculationId = calculation.rId;
  }
  return yield call(submitMailOrVideoLead, {
    payload: { form, vehicleId: vehicle.id },
  });
}

/* --------- Watchers --------- */
/* Watches the action in order to prepare the form for submission */
export function* watchSubmitMailOrVideo() {
  yield takeEvery(
    [ActionTypes.SUBMIT_MAIL_VIDEO_PENDING],
    prepareMailOrVideoLead,
  );
}
/* Watches the action in order to perform the form submission directly */
export function* watchSubmitAutobild() {
  yield takeEvery([ActionTypes.SUBMIT_AUTOBILD_PENDING], submitMailOrVideoLead);
}

/* Watches the action in order to send one click leads information */
export function* watchSuccessfullMailOrVideoSubmit() {
  yield takeEvery(
    [
      ActionTypes.SUBMIT_MAIL_VIDEO_SUCCESS_ONE_CLICK_LEAD,
      ActionTypes.SUBMIT_SELECTABLE_ONE_CLICK_LEADS_PENDING,
      ActionTypes.SUBMIT_CALLBACK_ONE_CLICK_LEAD_SUCCESS,
      ActionTypes.SUBMIT_CALLBACK_SELECTABLE_ONE_CLICK_LEADS_PENDING,
    ],
    submitOneClickLeads,
  );
}

import { IAggregations } from 'app/types/apis/search.types';

import { TSearchFiltersActions, SearchFiltersActionTypes } from './types';

export const updateListingsCountOnFilters = (
  payload: IAggregations,
): TSearchFiltersActions => ({
  type: SearchFiltersActionTypes.UPDATE_LISTINGS_COUNT,
  payload,
});

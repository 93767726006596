import styled from 'styled-components';
import { color } from 'app/shared/styles_js/variables';

export const Icon = styled.i`
  color: ${props =>
    props.color ||
    (props.themeName === 'brandPrimary'
      ? color.brandSecondary
      : color.brandPrimary)};
  margin-right: 12px;
  font-size: 18px;
  margin-top: 3px;
`;

export const Text = styled.span`
  font-size: 16px;
  line-height: 1.5;
  color: ${props =>
    props.themeName === 'white' || props.themeName === 'grayLightest'
      ? color.brandSecondary
      : color.white};
`;

export const Container = styled.span`
  display: flex;
  align-items: flex-start;
`;

/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable react/prop-types */
import React from 'react';

import { colors } from '../../../variables/colors';

import {
  BrandsContainer,
  LabelSt,
  LinkSt,
  GridContainerSt,
} from './NavFlyoutBrands.css';
import { BrandImageLink } from './components/BrandImageLink/BrandImageLink';
import { INavFlyoutBrandsProps } from './NavFlyoutBrands.types';

export const NavFlyoutBrands = ({
  brandsInfo: {
    label,
    moreBrandsUrl: { url, label: urlLabel, useRouter = true },
    brands,
  },
  setTracking,
  closeMenu,
}: INavFlyoutBrandsProps): JSX.Element => {
  return (
    <GridContainerSt hasDefaultWidth>
      <LabelSt>{label}</LabelSt>
      <BrandsContainer my={['8px', '8px', 0]}>
        {brands.slice(0, 10).map((brand) => (
          <BrandImageLink
            setTracking={setTracking}
            brand={brand}
            key={brand.id}
            closeMenu={closeMenu}
          />
        ))}
      </BrandsContainer>
      <LinkSt
        color={colors.brandPrimaryDark}
        hoverColor={colors.brandPrimary}
        to={url}
        useRouter={useRouter}
        onClick={(e) => {
          e.persist();
          setTracking('allMake');
          closeMenu(false);
        }}
      >
        {urlLabel}
      </LinkSt>
    </GridContainerSt>
  );
};

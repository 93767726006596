/* istanbul ignore file */
import styled from 'styled-components';

import { height, zIndex } from 'app/shared/styles_js/variables';

interface IElementDefaultProps {
  width?: number | string;
  height?: number | string;
}

interface IBannerProps extends IElementDefaultProps {
  isTopNavBarHidden: boolean;
}

const { topNavBar } = height;

export const BannerSt = styled.div<IBannerProps>`
  position: fixed;
  z-index: ${zIndex.navbar};
  display: flex;
  width: 100%;
  height: 40px;
  align-items: center;
  justify-content: center;
  transition: transform 0.3s linear;
  background-color: rgb(192, 230, 255);
  text-align: center;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.15);
  transform: ${({ isTopNavBarHidden }) =>
    isTopNavBarHidden
      ? `translate3d(0, ${topNavBar}px, 0)`
      : 'translate3d(0, 0, 0)'};
`;

export const BannerWrapperSt = styled.div`
  /* istanbul ignore next */
  ${({ isCarAvailable }: { isCarAvailable: boolean }) =>
    !isCarAvailable && { opacity: 0.5, pointerEvents: 'none' }};
  width: 100%;
`;

export const HiddenButtonSt = styled.button<IElementDefaultProps>`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1;
  border: none;
  background: transparent;
  color: transparent;
  width: ${({ width }) => width};
  height: ${({ height: imgHeight }) => imgHeight};
  padding: 0;
  margin-left: auto;
  margin-right: auto;
  cursor: pointer;
`;

export const ImgWrapperSt = styled.div<IElementDefaultProps>`
  width: ${({ width }) => width};
  height: ${({ height: imgHeight }) => imgHeight};
  margin: 0 auto;

  img:not([src]),
  img[src=''] {
    display: none !important;
  }
`;

export const getVehicleCountSelector = (state) => state.search.vehicleCount;

export const getVehicleLoadingSelector = (state) => state.search.pending > 0;

export const selectVehicleCountLoading = (state) =>
  state.search.vehicleCountPending > 0;

export const getVehicleSelector = (state) => state.search.vehicles;

export const getVehicleResultsCountSelector = (state) =>
  state.search.resultsVehicleCount;

export const isSidebarPreOpenSelector = (state) =>
  state.search.isSidebarFiltersPreOpen;

export const getShowVehicleCount = (state) => state.search.showVehicleCount;

export const getLoadingStateSelector = (state) => state.search.pending > 0;

export const getLastSearchUrlSelector = (state) => state.search.lastSearchUrl;

export const lastSearchUrlSelector = (state) => state.search.lastSearchUrl;

export const getD2CVehicleSelector = (state) =>
  state.search.d2cVehicles.vehicles;

export const getReducedPriceVehicles = (state) =>
  state.search.reducedPriceVehicles.vehicles;

export const getReducedPriceVehiclesLoadingSelector = (state) =>
  state.search.reducedPriceVehicles.isLoading;

export const getPriceRangeSelector = (state) => state.search.priceRange;

import { MLT_OPTIONS } from 'app/marketplace/moreLikeThisListings/MoreLikeThisListings/MoreLikeThisListings';
import * as ActionTypes from 'app/marketplace/moreLikeThisListings/constants';
import { getNormalizedVehicle } from 'app/shared/utils/vehicle';

export const defaultMoreLikeThisState = () => {
  return {
    lastSearch: MLT_OPTIONS.default,
    pending: false,
    moreLikeThisVehicles: [],
    moreLikeThisVehiclesCarousel: {},
    error: null,
  };
};

export function moreLikeThisListings(
  state = defaultMoreLikeThisState(),
  action,
) {
  const { type, payload, error, lastSearch } = action;

  if (type === ActionTypes.MORE_LIKE_THIS_PENDING) {
    return {
      ...state,
      pending: true,
      error: null,
    };
  }

  if (type === ActionTypes.GET_MORE_LIKE_THIS_VEHICLES) {
    return {
      ...state,
      moreLikeThisVehicles: payload.map(getNormalizedVehicle),
      pending: false,
      error: null,
    };
  }
  if (type === ActionTypes.GET_MORE_LIKE_THIS_VEHICLES_SUCCESS) {
    return {
      ...state,
      lastSearch,
      moreLikeThisVehiclesCarousel: {
        ...state.moreLikeThisVehiclesCarousel,
        [lastSearch]: payload.map(getNormalizedVehicle),
      },
      pending: false,
      error: null,
    };
  }
  if (type === ActionTypes.GET_MORE_LIKE_THIS_VEHICLES_FAIL) {
    return {
      ...state,
      lastSearch,
      pending: false,
      error,
    };
  }
  if (type === ActionTypes.SWITCH_LAST_SEARCH) {
    return {
      ...state,
      lastSearch,
    };
  }
  if (type === ActionTypes.RESET_MORE_LIKE_THIS) {
    return {
      ...state,
      moreLikeThisVehiclesCarousel: {},
    };
  }
  return state;
}

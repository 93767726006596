import {
  getHumanReadableTextOfCaptive,
  getHumanReadableTextOfFinancingProduct,
} from 'app/shared/modules/FDL/utils/mappers';
import { notFiltersThatCountAsFilters } from 'app/marketplace/filter/config';
import { getFilterObject } from 'app/marketplace/filter/utils';
import {
  formatCurrency,
  formatKm,
  formatNumber,
  formatPercent,
} from 'app/shared/utils/formatNumber';

const getRangeLabel = (
  values,
  formatFunction = (value) => value,
  separator = '-',
) => {
  if (values.length <= 1 || values[0] === values[1]) {
    return formatFunction(values[0]);
  }

  return `${formatFunction(values[0])} ${separator} ${formatFunction(
    values[1],
  )}`;
};

export const getFiltersLabels = ({
  activeFilters,
  currentLocation,
  activeFiltersWithLabel,
}) => {
  const searchLabels = [];
  let filtersforLabels;
  if (currentLocation && !activeFilters.zip) {
    const { place, zip: zipCode } = currentLocation;
    const zip =
      activeFilters?.lat?.length > 0 &&
      activeFilters?.lon?.length > 0 &&
      !activeFilters.zip
        ? [
            getFilterObject({
              category: 'zip',
              label: `${zipCode}, ${place}`,
              value: '',
            }),
          ]
        : [
            getFilterObject({
              category: 'zip',
              label: '',
              value: '',
            }),
          ];
    filtersforLabels = { ...activeFilters, zip };
  } else {
    filtersforLabels = activeFilters;
  }

  // eslint-disable-next-line no-restricted-syntax
  for (const key in filtersforLabels) {
    if (!notFiltersThatCountAsFilters.includes(key)) {
      const labels = filtersforLabels[key]
        .filter(
          // if updated it will need to be updated also in PillsList file (app/shared/modules/PillsList/PillsList.js)
          (item, index, array) /* istanbul ignore next */ =>
            // Filter which its category is not 'markerId'
            item.category !== 'markerId' ||
            // Filter just one from all which its category is 'markerId' (to avoid multiple/dublicate 'Neuwagen-Angebote' pills)
            array.findIndex(
              (arrayItem) => arrayItem.category === 'markerId',
            ) === index,
        )
        .map(({ category, value, label }) => {
          const rangeValues = typeof value === 'string' ? value.split('-') : [];

          switch (category) {
            case 'priceRange':
            case 'monthlyRateRange':
            case 'leasingRateRange':
              return getRangeLabel(rangeValues, formatCurrency, 'bis');

            case 'mileageRange':
              return getRangeLabel(rangeValues, formatKm);

            case 'performanceRange':
              // eslint-disable-next-line no-shadow
              return getRangeLabel(rangeValues, (value) => {
                const KW_TO_HP = 1.35962;
                const isHP =
                  activeFilters.performanceRangeSwitch[0]?.value === 'ps';
                const powerValue = Math.round(value * (isHP ? KW_TO_HP : 1));
                const unitLabel = isHP ? 'PS' : 'kW';
                const isMinValueUntached = rangeValues[0] === '40';
                const shouldAddPlus =
                  isMinValueUntached &&
                  ((isHP && powerValue === 500) ||
                    (!isHP && powerValue === 368));
                return `${powerValue}${shouldAddPlus ? '+' : ''} ${unitLabel}`;
              });

            case 'consumptionRange':
              return getRangeLabel(
                rangeValues,
                (number) => `${formatNumber(number)} l/100km`,
              );

            case 'yearRange':
              return getRangeLabel(rangeValues);

            case 'radius':
              return `${label && label.toUpperCase()} Umkreis`;

            case 'seats':
              return `${label} Sitzplätze`;

            case 'interiorColor':
              return `Interieurfarbe: ${label}`;

            case 'bodyColor':
              return `Außenfarbe: ${label}`;

            case 'doors':
              return `${getRangeLabel(
                rangeValues,
                (number) => `${formatNumber(number)}`,
                'bis',
              )} Türen`;

            case 'campaign':
            case 'effInterestLeasingRateRange':
              return 'Aktionsangebote';

            case 'effInterestRateRange':
              return getRangeLabel(rangeValues, formatPercent);

            case 'trim':
              if (value.endsWith('_all')) {
                return null;
              }
              if (label === 'other') {
                return 'Andere';
              }
              return label;

            case 'model':
              if (value.endsWith('_all')) {
                return null;
              }
              return label;

            case 'financingProduct':
              return getHumanReadableTextOfFinancingProduct(label);

            case 'captive':
              return getHumanReadableTextOfCaptive(label);

            case 'dealer':
              return activeFiltersWithLabel && activeFiltersWithLabel.length > 0
                ? activeFiltersWithLabel[0].label
                : null;

            default:
              return label;
          }
        });
      if (labels.length > 0) {
        searchLabels.push(labels.filter(Boolean));
      }
    }
  }

  return searchLabels.length > 0
    ? searchLabels.reduce((acc, label) => acc.concat(label, []))
    : searchLabels;
};

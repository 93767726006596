import { OverlayRoot } from './OverlayRoot';
import { closeOverlay, openOverlay } from '../actions';
import { connect } from 'react-redux';

function mapStateToProps(state) {
  return {
    activeComponent: state.overlay.activeOverlayComponent
  };
}

export const ConnectedOverlayRoot = connect(
  mapStateToProps,
  {
    openOverlay,
    closeOverlay
  }
)(OverlayRoot);

import React from 'react';
import PropTypes from 'prop-types';

import {
  ToggleContainer,
  ToggleStyle,
  ToggleLabel,
  ToggleButton,
} from './ToggleSwitch.css';

/**
 * @description Dual side toggle switch button that accepts
 * labels for its on/off states.
 */
const ToggleSwitchComp = props => {
  const { labelLeft, labelRight, side, ...styledProps } = props;

  const labels = labelLeft && labelRight && (
    <>
      <ToggleLabel side={side}>{labelLeft}</ToggleLabel>
      <ToggleLabel side={side}>{labelRight}</ToggleLabel>
    </>
  );

  return (
    <ToggleContainer>
      <ToggleStyle
        {...styledProps}
        side={side}
        labelLeft={labelLeft}
        labelRight={labelRight}
      >
        {labels}
      </ToggleStyle>

      <ToggleButton side={side} />
    </ToggleContainer>
  );
};

ToggleSwitchComp.propTypes = {
  labelLeft: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  labelRight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  side: PropTypes.bool,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
};

ToggleSwitchComp.defaultProps = {
  labelLeft: '',
  labelRight: '',
  side: false,
  disabled: false,
  onClick: () => {},
};

export const ToggleSwitch = React.memo(ToggleSwitchComp);
ToggleSwitch.displayName = 'ToggleSwitch';

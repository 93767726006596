import { bodyColorFilters } from 'app/marketplace/filter/mappings';

import { conditions, conditionValues } from './constants';

/**
 * Function used to fill out default props
 */
export const noop = () => {};

/**
 * Returns condition value by key
 * @param {string} key
 */
export function getConditionValue(key) {
  if (!key) return null;
  const conditionIndex = conditions.indexOf(key.toLowerCase());
  if (conditionIndex <= -1) return null;
  return conditionValues[conditionIndex];
}

/**
 * Get mail message of tradein
 * @param {object} valuatedVehicle valuated vehicle parameters
 */
export function getMailMessageContent(valuatedVehicle) {
  if (!valuatedVehicle) return null;
  const {
    make,
    model,
    registrationDate,
    mileage,
    engine,
    vehicleCondition,
    fin,
    color,
  } = valuatedVehicle;
  const { label: colorLabel } =
    bodyColorFilters.find((x) => x.value === color) || {};

  return `
Das folgende Fahrzeug möchte ich gerne in Zahlung geben:

- ${make} ${model}
- EZ: ${registrationDate}, ${mileage} km, ${engine}
- Farbe: ${colorLabel}
- Zustand: ${vehicleCondition}
${fin ? `- FIN: ${fin}` : ''}`;
}

/**
 * Get mail message of tradein with welcome text
 * @param {object} valuatedVehicle
 */
export function getMailMessage(valuatedVehicle) {
  const tradeinDetails = getMailMessageContent(valuatedVehicle);

  return `Sehr geehrte Damen und Herren, 

ich interessiere mich für dieses Fahrzeug und würde mich über ein Finanzierungsangebot freuen.
${tradeinDetails}
Bitte kontaktieren Sie mich. 

Mit freundlichen Grüßen`;
}

export const formatDate = (input) => {
  const datePart = input.split('-');
  const year = datePart[0];
  const month = datePart[1];
  return `${month}/${year}`;
};

/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react-perf/jsx-no-new-function-as-prop */
import { Route } from 'react-router';

import { getAsyncRouteComponentLoader } from 'app/shared/utils/utils';
import { ConnectedPageTemplate } from 'app/shared/modules/pageTemplate/PageTemplate/ConnectedPageTemplate';

import { DPP_BASE_SLUGS } from './constants';

export const DealerProfileRoutes = (
  <Route>
    <Route
      name={`${DPP_BASE_SLUGS.DPP}`}
      component={(props) => <ConnectedPageTemplate target="DDP" {...props} />}
    >
      <Route
        exact
        path={`/${DPP_BASE_SLUGS.DPP}/:dealerLocation/:profileUrlSlug`}
        getComponent={getAsyncRouteComponentLoader(
          'ConnectedDealerProfileHandler',
          () => import('./DealerProfileHandler/ConnectedDealerProfileHandler'),
        )}
      />
    </Route>
  </Route>
);

import { IGAMConfig } from 'app/shared/modules/Campaign/GAM/GamBanner/GamBanner.types';

const bannerSizes = {
  sm: [{ width: 296, height: 240 }],
  md: [{ width: 402, height: 492 }],
  lg: [{ width: 492, height: 456 }],
  xl: [{ width: 700, height: 456 }],
};

const bannerArea = {
  sm: { width: 296, height: 240 },
  md: { width: 402, height: 492 },
  lg: { width: 492, height: 456 },
  xl: { width: 700, height: 456 },
};

export const HP_STAGE_BANNER: IGAMConfig = {
  adUnitId: 'div-gam-hp-stage-banner',
  adUnitPath: '/22376835783/hp-stage-banner',
  sizes: bannerSizes,
  bannerArea,
  trackingData: {
    clickEvent: {
      eventName: 'home_page_stage_banner_click',
      placement: 'HP',
      variantFlagId: 235,
    },
    impressionEvent: {
      category: 'home page',
      label: 'Home page stage banner loaded',
    },
  },
};

/* eslint-disable @typescript-eslint/no-unsafe-return */
import styled from 'styled-components';
import {
  space,
  color,
  width,
  fontSize,
  fontWeight,
  textAlign,
  lineHeight,
  fontStyle,
  WidthProps,
  LineHeightProps,
  FontStyleProps,
  FontSizeProps,
  FontWeightProps,
  SpaceProps,
  TextAlignProps,
  TextColorProps,
} from 'styled-system';

export interface ITextProps
  extends WidthProps,
    TextColorProps,
    LineHeightProps,
    FontStyleProps,
    FontSizeProps,
    FontWeightProps,
    SpaceProps,
    TextAlignProps {}

const Text = styled.span<ITextProps>`
  ${space}
  ${width}
  ${color}
  ${fontSize}
  ${fontWeight}
  ${textAlign}
  ${lineHeight}
  ${fontStyle}
  ${(props) => props.css};
`;

Text.displayName = 'Text';

export { Text };

import styled, { css } from 'styled-components';
import { Flex } from '@rebass/grid';

import { Link, ContainerSt } from 'app/shared/ui';
import {
  grid,
  color,
  media,
  zIndex,
  evergreenOnly,
} from 'app/shared/styles_js/variables';
import { SEOList } from 'app/shared/styles_js/text.css';

const commonStyles = css`
  color: #fff;
  font-weight: 100;
`;

export const FooterSt = styled.footer`
  position: relative;
  z-index: ${zIndex.footer}; /* Here to make the sticky block in the PDP hide behind this */
  background-color: ${({ theme: { colors } }) => colors.brandSecondaryLight};
  ${commonStyles};

  padding-top: 64px;
  padding-bottom: 104px;
  ${media.md(
    css`
      padding-bottom: 64px;
    `,
  )};
  ${media.xl(
    css`
      padding-bottom: 48px;
    `,
  )};
`;

export const FooterListSt = styled(SEOList)`
  ${media.md(css`
    min-width: 23%;
    padding-right: ${grid.gutter.md}px;

    ${evergreenOnly(css`
      width: auto;
      min-width: auto;
      padding-right: 0;
    `)}
  `)};

  & > a {
    ${commonStyles};

    :hover {
      font-weight: unset;
      text-decoration: none;
    }
  }
`;

export const FooterListWideSt = styled(FooterListSt)`
  ${media.md(css`
    grid-column-end: span 2;
    width: 44%;
  `)};
  ${media.lg(css`
    grid-column-end: span 1;
    width: ${100 / 3}%;
  `)};

  ${evergreenOnly(
    css`
      width: auto;
    `,
  )};
`;

export const FooterCopySt = styled.div`
  font-size: 12px;
  grid-column: 1 / -1;
  line-height: 2;
  width: 100%;
  margin-top: 64px;
`;

export const SocialMediaSt = styled.div`
  background-color: ${color.brandSecondaryLight};
  height: 120px;
  width: 100%;
`;

export const SocialMediaIconsSt = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  height: 100%;
  width: 100%;
  margin-left: auto;
  margin-right: auto;

  ${media.md(
    css`
      height: 100%;
      width: 450px;
    `,
  )}
  ${media.lg(
    css`
      height: 100%;
      width: 550px;
    `,
  )}
  ${media.xl(
    css`
      height: 100%;
      width: 560px;
    `,
  )}

  > a {
    all: unset;
    cursor: pointer;
  }
`;

export const LinkSt = styled(Link).attrs(() => ({
  textDecoration: 'none',
  fontWeight: 'medium',
  className: 'footer-link',
}))`
  margin-bottom: 5px;
  ${media.md(css`
    margin-bottom: 0;
  `)}

  ${({ theme: { colors } }) =>
    css`
      &.footer-link {
        transition: color 200ms ease-in-out;
        &:hover,
        &:active,
        &:focus {
          color: ${colors.heycarMint};
        }
      }
    `}
`;

export const ChipSealContainerSt = styled(ContainerSt)`
  margin-top: 32px;
  ${media.md(css`
    display: grid;
    grid-template-columns: 200px 3fr;
    grid-column-gap: 32px;
    align-items: center;
    margin-top: 16px;
  `)}
`;

export const FooterSocialIconsContainerSt = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 250px;
  gap: 2rem;
`;

export const SocialLinkSt = styled(Link)`
  && {
    text-decoration: none;
    color: white;

    && {
      &:hover,
      &:focus,
      &:active {
        color: white;
        text-decoration: none;
      }
    }
  }

  i {
    font-size: 24px;
  }
`;

export const SocialTrustpilotRowSt = styled(Flex).attrs(() => ({
  flexDirection: ['column', 'row'],
  justifyContent: ['center', 'space-between'],
  alignItems: ['center', 'flex-start'],
}))``;

export const TrustpilotContainerSt = styled(Flex).attrs(() => ({
  alignItems: ['center', 'flex-end'],
  mt: ['32px', 0],
  ml: ['0', '325px', '0', '0'],
}))`

.horizontal-trustbox {
  width: 850px;
  
  & > iframe {
    margin-left: 0;
  }
}

  @media screen and (max-width: 767px) {
    iframe {
      margin-left: 0px;
    }
  }

  @media (min-width: 768px) and (max-width: 1023px) {
    iframe {
      margin-left: 85px;
    }
  }

  @media (min-width: 1024px) and (max-width: 1280px) {
    iframe {
      margin-left: 210px;
    }
  }

  @media screen and (min-width: 1280px) {
    iframe {
      margin-left: 400px;
    }
  }
`;

import PropTypes from 'prop-types';

import { createTypes } from 'app/shared/utils/createTypes';

export const ActionTypes = createTypes([
  'CONTENTFUL_ADD_MODAL',
  'CONTENTFUL_ARTICLES_BY_CATEGORY_',
  'CONTENTFUL_ARTICLES_BY_LATEST_',
  'CONTENTFUL_ENTRY_',
  'CONTENTFUL_FEATURED_ARTICLES_',
  'CONTENTFUL_GET_JOBS_',
  'CONTENTFUL_MAGAZINE_CATEGORIES_',
  'CONTENTFUL_NEWS_BY_CATEGORY_',
  'CONTENTFUL_RELATED_ARTICLES_',
  'CONTENTFUL_REMOVE_MODAL',
  'CONTENTFUL_TRIGGER_MODAL',
  'CONTENTFUL_TRIGGER_MODAL_OUTSIDE',
  'CONTENTFUL_GET_LIST_OF_MAKE_PAGES_FROM_SEO_SPACE_',
  'FAILURE_WARRANTYSEALDATA_REQUEST',
  'FINISH_LOADING',
  'GET_CAMPAIGN_HERO_',
  'GET_USPS_',
  'SUCCESS_WARRANTYSEALDATA_REQUEST',
  'GET_FAQ',
  'GET_FAQ_SUCCESS',
  'GET_FAQ_ERROR',
  'GET_D2C_EXTRAS',
  'GET_D2C_EXTRAS_SUCCESS',
  'GET_D2C_EXTRAS_ERROR',
]);

export const FileShape = PropTypes.shape({
  fields: PropTypes.shape({
    path: PropTypes.shape({
      url: PropTypes.string.isRequired,
    }),
  }),
});

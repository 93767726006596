import { TRACKING_LIST } from 'app/shared/modules/tracking/list';
import {
  getMonthlyPriceRangeSnapPoints,
  getPriceRangeSnapPoints,
} from 'app/marketplace/filter/mappings';

export const PRICE_MARGIN = 10;

export const FILTERS = {
  priceRange: [],
  priceMargin: [],
  monthlyRateRange: [],
  leasingRateRange: [],
};

export const PURCHASING_OPTION = {
  max: 120000,
  min: 0,
  floor: 1000,
  snapPoints: getPriceRangeSnapPoints(),
  searchCategory: 'priceRange',
  isMonthly: false,
  showPopularRates: false,
  hasShowMoreFeature: false,
  trackingActionName: TRACKING_LIST.FINANCING.FULL_PRICE_FILTER,
  disclaimer: '',
  sliderTitle: 'Preis bis',
};

export const FINANCING_OPTION = {
  max: 2000,
  min: 10,
  floor: 100,
  snapPoints: getMonthlyPriceRangeSnapPoints(10, 1000, 2000),
  searchCategory: 'monthlyRateRange',
  isMonthly: true,
  showPopularRates: true,
  hasShowMoreFeature: true,
  trackingActionName: TRACKING_LIST.FINANCING.MONTHLY_RATE_FILTER,
  disclaimer:
    '*Basierend auf einer beispielhaften und unverbindlichen Kalkulation mit 48 Monaten Laufzeit, 15.000km jährl. Laufleistung und Anzahlung in Höhe von 25% des Fahrzeugpreises. Zusätzlich zu den Monatsraten kann ggf. noch eine Schlussrate anfallen, deren Höhe vom jeweiligen Fahrzeug abhängt.',
  sliderTitle: 'Rate bis',
};

export const LEASING_OPTION = {
  max: 2000,
  min: 10,
  floor: 100,
  snapPoints: getMonthlyPriceRangeSnapPoints(10, 1000, 2000),
  searchCategory: 'leasingRateRange',
  isMonthly: true,
  showPopularRates: true,
  hasShowMoreFeature: false,
  trackingActionName: TRACKING_LIST.FINANCING.LEASING_RATE_FILTER,
  disclaimer:
    '*Die Höhe der monatlichen Leasingrate ist abhängig von Sonderzahlung, jährlicher Fahrleistung und Laufzeit sowie ggf. ausgewählten Dienstleistungen.',
  sliderTitle: 'Rate bis',
};

import { useTheme, DefaultTheme } from 'styled-components';

import {
  IColorsList,
  TOutdatedColor,
} from '../ui/components/variables/index.types';

/**
 * @description
 * small wrapper for `useTheme`
 * that provides a safe fallback
 * in the case that `theme` is undefined
 */
interface HeycarTheme extends DefaultTheme {
  rebranding?: boolean;
  colors: Omit<IColorsList, TOutdatedColor>;
}
export const useStyledTheme: () => HeycarTheme = () => useTheme() || {};

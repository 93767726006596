import { Box, Flex } from '@rebass/grid';
import { PropTypes } from 'prop-types';
import { Text, Text2, Container } from 'app/shared/ui';

import { CtaButtonSt } from './Notification.css';
import { CloseButton } from './components/CloseButton';

/**
 * Shows a fullWidth banner with a call to action button and close button
 */
export const ActionNotification = ({
  title,
  message,
  buttonText,
  onClickCtaButton,
  onClickClose,
  className,
}) => (
  <Box
    py={[24, null, 32]}
    bg="brandSecondary"
    aria-live="assertive"
    aria-atomic="true"
    className={className}
  >
    <Container>
      <Flex color="white" width={1} alignItems="center" flexWrap="wrap">
        <Box
          flex="1 0"
          pr={[32, null, 48]}
          width={['calc(100% - 16px)', 'auto']}
        >
          <Text as="header" fontWeight="bold" lineHeight="normal">
            {title}
          </Text>
          {message && (
            <Text2 as="p" mt={16} mb="0">
              {message}
            </Text2>
          )}
        </Box>
        {buttonText && (
          <Box
            ml={[null, 'auto']}
            mt={[16, 0]}
            width={[1, 'auto']}
            order={[2, 1]}
          >
            <CtaButtonSt onClick={onClickCtaButton}>{buttonText}</CtaButtonSt>
          </Box>
        )}
        <CloseButton
          ml={[null, 32, 48]}
          order={[1, 2]}
          alignSelf={['flex-start', 'center']}
          onClick={onClickClose}
        />
      </Flex>
    </Container>
  </Box>
);

ActionNotification.propTypes = {
  title: PropTypes.PropTypes.oneOfType([PropTypes.node, PropTypes.element])
    .isRequired,
  message: PropTypes.string.isRequired,
  buttonText: PropTypes.string.isRequired,
  onClickCtaButton: PropTypes.func.isRequired,
  onClickClose: PropTypes.func,
  className: PropTypes.string,
};

ActionNotification.defaultProps = {
  onClickClose: () => {},
  className: null,
};

import {
  SUBMIT_ENTRIES_REQUEST,
  SUCCESS_ENTRIES_REQUEST,
  FAILURE_ENTRIES_REQUEST
} from './actions';

import { SET_PREVIOUS_LOCATION } from '../../../../constants';

export const defaultState = () => ({
  pending: false,
  error: null,
  hasUserSeen: false,
  entries: []
});

export function propositionBar(
  state = defaultState(),
  { error, payload, type }
) {
  if (type === SUBMIT_ENTRIES_REQUEST) {
    state = Object.assign({}, state, { pending: true });
  } else if (type === SUCCESS_ENTRIES_REQUEST) {
    state = Object.assign({}, state, { entries: payload, pending: false });
  } else if (type === FAILURE_ENTRIES_REQUEST) {
    state = Object.assign({}, state, { error, pending: false });
  } else if (
    type === SET_PREVIOUS_LOCATION &&
    payload.includes('gebrauchtwagen')
  ) {
    state = Object.assign({}, state, { hasUserSeen: true });
  }

  return state;
}

import isequal from 'lodash.isequal';

import * as ActionTypes from './constants';
import { getFilterObject } from './utils';
import { FilterMappings } from './mappings';
import {
  checkBoxFilters,
  radioFilters,
  intRangeFilters,
  floatRangeFilters,
} from './config';

export const defaultState = () => {
  return {
    priceRange: [],
    monthlyRateRange: [],
    leasingRateRange: [],
    priceMargin: [],
    effInterestRateRange: [],
    budgetRangeSwitch: [
      getFilterObject({ category: 'budgetRangeSwitch', value: 'full' }), // not sent to the API
    ],
    yearRange: [],
    mileageRange: [],
    consumptionRange: [],
    performanceRange: [],
    performanceRangeSwitch: [
      getFilterObject({ category: 'performanceRangeSwitch', value: 'ps' }), // not sent to the API
    ],
    category: [],
    bodyColor: [],
    interiorColor: [],
    maxOwners: [],
    interiorType: [],
    gearBox: [],
    singleDayRegistration: [],
    fuelType: [],
    markerId: [],
    emissionClass: [],
    make: [],
    model: [],
    trim: [],
    sortBy: [],
    page: [getFilterObject({ category: 'page', value: 0 })],
    possibleMakes: [],
    allMakes: [],
    possibleModels: [],
    dataSource: [],
    productOffering: [],
    // use to map usecarseal displayname from agregation
    allUsedCarSeal: [],
    error: null,
    lat: [],
    lon: [],
    radius: [],
    dealer: [],
    feature: [],
    q: [],
    fullQuery: [],
    lifeStyle: [],
    headerCLP: [],
    // used to define the algorithm used when searching
    seed: [],
    seats: [],
    doors: [],
    vehicleCondition: [],
    specialOffers: [],
    // special offers filter start
    campaign: [],
    // special offers filter end
    captive: [],
    financingProduct: [],
    hasDcc: [],
    airbags: [],
    climatisation: [],
    unusedCar: [],
    hasLeasingRate: [],
    boniCheck: [],
  };
};

/*
  ----------------- Util functions that are ONLY used inside of the reducer -----------------
 */
const isNewCheckboxFilter = (filters, newFilter) => {
  return filters.filter((f) => f.uuid === newFilter.uuid).length === 0;
};

const removeCheckboxFilter = (filters, newFilter) => {
  return filters.filter((f) => f.uuid !== newFilter.uuid);
};

const getCheckboxFilter = (state, newFilter) => {
  const { category } = newFilter;
  let newFilters = state[category].slice(0);

  if (newFilter.value === null) {
    newFilters = [];
  } else if (isNewCheckboxFilter(newFilters, newFilter)) {
    newFilters.push(newFilter);
  } else {
    newFilters = removeCheckboxFilter(newFilters, newFilter);
  }

  const newState = {};

  newState[category] = newFilters;

  if (category === 'model' && newFilter.value === null) {
    const modelFilter = state.model.slice(0);

    newState.model = modelFilter.filter(
      (model) => model.parent !== newFilter.parent,
    );
  }

  newState.page = [getFilterObject({ category: 'page', value: 0 })];

  return newState;
};

const getRangeFilter = (state, newFilter) => {
  const { category } = newFilter;
  let newFilters = state[category].slice(0);

  if (newFilter.value === null) {
    newFilters = [];
  } else {
    newFilters = [newFilter];
  }

  const newState = {};

  newState[category] = newFilters;

  newState.page = [getFilterObject({ category: 'page', value: 0 })];

  return newState;
};

const getRadioFilter = (state, newFilter) => {
  const { category } = newFilter;
  let newFilters = [];

  if (newFilter.value === null) {
    newFilters = [];
  } else {
    newFilters = [newFilter];
  }

  const newState = {};

  newState[category] = newFilters;

  if (newFilter.category !== 'page') {
    newState.page = [getFilterObject({ category: 'page', value: 0 })];
  }
  return newState;
};

const checkFilterObject = (filter) => {
  const mandatoryKeys = Object.keys(getFilterObject({}));
  const filterKeys = Object.keys(filter);

  if (mandatoryKeys.toString() !== filterKeys.toString()) {
    throw new Error(
      `this filter has the wrong format: ${JSON.stringify(filter)}`,
    );
  }
};

export const createFiltersFromArray = (
  filters,
  state,
  filterByCategories = {
    checkBox: checkBoxFilters,
    range: [...intRangeFilters, ...floatRangeFilters],
    radio: radioFilters,
  },
) => {
  const newFilters = [];
  filters.forEach((filter) => {
    /** will be removed in production due to dead code elimination */
    if (DEVELOPMENT) {
      checkFilterObject(filter);
    }

    // filter exists
    const filterExists = newFilters.filter((f) => {
      return Object.keys(f).indexOf(filter.category) > -1;
    });
    if (filterExists.length > 0) {
      newFilters.forEach((f) => {
        if (f[filter.category]) {
          f[filter.category].push(filter);
        }
      });
    } else if (filterByCategories.checkBox.indexOf(filter.category) > -1) {
      newFilters.push(getCheckboxFilter(state, filter));
    } else if (filterByCategories.range.indexOf(filter.category) > -1) {
      newFilters.push(getRangeFilter(state, filter));
    } else if (filterByCategories.radio.indexOf(filter.category) > -1) {
      newFilters.push(getRadioFilter(state, filter));
    }
  });
  return newFilters;
};

const addAllFilters = (filters) => {
  const newFilters = {};

  filters.forEach((filter) => {
    if (newFilters[filter.category]) {
      newFilters[filter.category].push(filter);
    } else {
      newFilters[filter.category] = [filter];
    }
  });
  return newFilters;
};

const processSearchQueryParameter = (searchFilter, state, count, fullQuery) => {
  if (typeof searchFilter.q === 'string' && searchFilter.q.trim()) {
    return {
      ...state,
      q: [
        getFilterObject({
          category: 'q',
          value: count ? searchFilter.q.trim() : fullQuery,
        }),
      ],
    };
  }
  return { ...state, q: [] };
};

export const updateFiltersFromSearch = (payload, state) => {
  const count = payload.totalElements;
  const fullQuery =
    (state.fullQuery && state.fullQuery[0] && state.fullQuery[0].value) || '';
  const { aggregations } = payload;
  const { searchFilter } = payload;
  let newState = state;
  // extract the "q" parameter/filter
  newState = processSearchQueryParameter(searchFilter, state, count, fullQuery);
  delete searchFilter.q;

  const categories = Object.keys(searchFilter);

  categories.forEach((category) => {
    const searchFilterValues = `${searchFilter[category]}`.split(',');
    const newFilters = [];

    searchFilterValues.forEach((searchFilterValue) => {
      const aggregation =
        aggregations[category] &&
        aggregations[category].find((agg) => agg.key === searchFilterValue);

      if (aggregation) {
        const mappingCategory = FilterMappings[category];

        if (category !== 'model' && category !== 'make') {
          const rawFilterObject = {
            category,
            value: aggregation.key,
            label: `${
              mappingCategory
                ? mappingCategory[aggregation.key]
                : aggregation.displayName
            }`,
          };
          newFilters.push(getFilterObject(rawFilterObject));
        }

        if (category === 'make') {
          if (searchFilterValues.length >= 1) {
            const makeAggregation = aggregations.make.find(
              (agg) => aggregation.key === agg.key,
            );

            newFilters.push(
              getFilterObject({
                category: 'make',
                value: makeAggregation.key,
                label: makeAggregation.displayName,
              }),
              getFilterObject({
                category: 'model',
                value: `${makeAggregation.key}_all`,
                label: '',
              }),
              getFilterObject({
                category: 'trim',
                value: `${makeAggregation.key}_all_all`,
                label: '',
              }),
            );
          }
        }

        if (category === 'model') {
          if (searchFilterValues.length >= 1) {
            const makeAggregation = aggregations.make.find(
              (agg) => aggregation.make === agg.key,
            );

            newFilters.push(
              getFilterObject({
                category: 'make',
                value: makeAggregation.key,
                label: makeAggregation.displayName,
              }),
              getFilterObject({
                category: 'model',
                value: `${makeAggregation.key}_${aggregation.key}`,
                label: aggregation.displayName,
              }),
              getFilterObject({
                category: 'trim',
                value: `${makeAggregation.key}_${aggregation.key}_all`,
                label: '',
              }),
            );
          }
        }
      }
    });
    if (newFilters.length > 0) {
      newState = Object.assign(newState, addAllFilters(newFilters));
    }
  });

  return newState;
};

const removeSingleFilter = (payload, state) => {
  const { category, value } = payload;

  state[category] = state[category].filter((filter) => filter.value !== value);

  return state;
};

export const getResetState = (state) => {
  const newState = defaultState();

  newState.possibleModels = state.possibleModels;
  newState.possibleMakes = state.possibleMakes;
  newState.allMakes = state.allMakes;
  newState.sortBy = state.sortBy[0]?.value === 'distance' ? [] : state.sortBy;
  return newState;
};

export const removeModelAndTrimFiltersFromMake = (filter, idx, state) => {
  const newTrimState = [...state.trim];
  const newModelState = [...state.model];
  newTrimState.splice(idx, 1);
  newModelState.splice(idx, 1);
  return [newModelState, newTrimState];
};

export const updateModelandTrimFiltersFromModel = (filter, state) => {
  const [make] = filter.value.split('_');

  const newModelObject = getFilterObject({
    category: 'model',
    direction: null,
    label: 'all',
    operator: 'and',
    parent: null,
    uuid: 'model:all',
    value: `${make}_all`,
  });
  const newTrimObject = getFilterObject({
    category: 'trim',
    direction: null,
    label: 'all',
    operator: 'and',
    parent: null,
    uuid: 'trim:all',
    value: `${make}_all_all`,
  });

  let hasCleanedUpModel = 0;
  const newModelFilter = [...state.model].map((model) => {
    if (model.value === filter.value) {
      hasCleanedUpModel += 1;
      return hasCleanedUpModel > 1 ? model : newModelObject;
    }
    return model;
  });

  let hasCleanedUpTrim = 0;
  const newTrimFilter = [...state.trim].map((trim) => {
    if (trim.value.startsWith(filter.value)) {
      hasCleanedUpTrim += 1;
      return hasCleanedUpTrim > 1 ? trim : newTrimObject;
    }
    return trim;
  });
  return [newModelFilter, newTrimFilter];
};

export const updateTrimFilters = (filter, state) => {
  const [make, model] = filter.value.split('_');
  const newTrimObject = getFilterObject({
    category: 'trim',
    direction: null,
    label: 'all',
    operator: 'and',
    parent: null,
    uuid: 'trim:all',
    value: `${make}_${model}_all`,
  });

  let hasCleanedUpTrimFilter = 0;
  const newTrimFilter = [...state].map((trim) => {
    if (trim.value === filter.value) {
      hasCleanedUpTrimFilter += 1;
      return hasCleanedUpTrimFilter > 1 ? trim : newTrimObject;
    }
    return trim;
  });
  return newTrimFilter;
};

/*
  ----------------- the actual (parameterized) reducer, used for dealer and clp -----------------
 */
export const getFilterReducerForType = (actionTypes = ActionTypes) => {
  return function filter(state = defaultState(), action) {
    const { type, payload, error } = action;

    if (type === actionTypes.OVERRIDE_FILTERS) {
      payload.possibleModels = state.possibleModels;
      payload.possibleMakes = state.possibleMakes;
      payload.allMakes = state.allMakes;
      payload.allUsedCarSeal = state.allUsedCarSeal;
      payload.performanceRangeSwitch = state.performanceRangeSwitch;

      return { ...state, ...payload };
    }
    if (type === actionTypes.SET_FILTERS) {
      const filters = Array.isArray(payload) ? payload : [payload];
      const newFilters = createFiltersFromArray(filters, state);
      return Object.assign({}, state, ...newFilters);
    }
    if (type === actionTypes.RESET_FILTERS) {
      const newState = getResetState(state);

      return { ...newState };
    }
    if (type === actionTypes.RESET_SINGLE_FILTER) {
      const newState = removeSingleFilter(payload, state);

      return { ...state, ...newState };
    }
    if (type === actionTypes.RESET_FILTER_CATEGORY) {
      const newState = { ...state };
      payload.forEach((category) => {
        // applies exceptional value for budgetRangeSwitch
        if (category === 'budgetRangeSwitch') {
          newState[category] = defaultState().budgetRangeSwitch;
        } else {
          newState[category] = [];
        }
      });
      return { ...state, ...newState };
    }
    if (type === actionTypes.RESET_FILTERS_EXCEPT_CATEGORY) {
      const newState = [];

      payload.forEach((category) => {
        newState[category] = state[category];
      });
      return { ...state, ...defaultState(), ...newState };
    }
    if (type === actionTypes.GET_MAKES_SUCCESS) {
      const possibleMakes = payload.aggregations.make.sort(
        /* istanbul ignore next */ (a, b) => {
          if (a.displayName.toUpperCase() > b.displayName.toUpperCase()) {
            return 1;
          }
          if (b.displayName.toUpperCase() > a.displayName.toUpperCase()) {
            return -1;
          }
          return 0;
        },
      );
      return {
        ...state,
        possibleMakes,
        error: null,
      };
    }
    if (type === actionTypes.RESET_MAKEMODELVARIANTE_FILTER) {
      const { filter, idx } = payload;
      const [newModelState, newTrimState] = removeModelAndTrimFiltersFromMake(
        filter,
        idx,
        state,
      );
      const newMakeState = [...state.make];
      newMakeState.splice(idx, 1);
      return {
        ...state,
        make: newMakeState,
        model: newModelState,
        page: [getFilterObject({ category: 'page', value: 0 })],
        trim: newTrimState,
      };
    }
    if (type === actionTypes.UPDATE_MULTIMODEL_FILTER) {
      const [
        newModelFilter,
        newTrimFilter,
      ] = updateModelandTrimFiltersFromModel(payload, state);
      return {
        ...state,
        model: newModelFilter,
        page: [getFilterObject({ category: 'page', value: 0 })],
        trim: newTrimFilter,
      };
    }
    if (type === actionTypes.UPDATE_MULTITRIM_FILTER) {
      const newTrimFilter = updateTrimFilters(payload, state.trim);
      return {
        ...state,
        page: [getFilterObject({ category: 'page', value: 0 })],
        trim: newTrimFilter,
      };
    }
    if (type === actionTypes.GET_ALL_USEDCARSEAL_SUCCESS) {
      if (state.allUsedCarSeal.length === 0) {
        return {
          ...state,
          allUsedCarSeal: payload.aggregations.usedCarSeal,
          error: null,
        };
      }
      return { ...state };
    }
    if (type === actionTypes.GET_ALL_MAKES_SUCCESS) {
      if (state.allMakes.length === 0) {
        return { ...state, allMakes: payload.aggregations.make, error: null };
      }
      return { ...state };
    }
    if (type === actionTypes.GET_MODELS_SUCCESS) {
      return {
        ...state,
        possibleModels: payload.aggregations.model,
        error: null,
      };
    }
    if (type === actionTypes.SET_COORDS_SUCCESS) {
      const { lat, lon } = payload;

      return { ...state, lat, lon };
    }
    if (type === actionTypes.ERROR) {
      return { ...state, error };
    }
    if (type === actionTypes.UPDATE_FILTERS) {
      const newFilters = updateFiltersFromSearch(payload, state);

      if (payload.totalElements && !isequal(newFilters, state)) {
        return { ...state, ...newFilters };
      }
    }

    return state;
  };
};

import { RESET_SUBMIT } from 'app/marketplace/SlideInBanners/CrmBanner/redux/constants';

import { DISMISS_POPUP, FORM_SUBMITTED } from './constants';

export const slideInBannerReducer = (
  state = { show: true, submitted: false },
  action,
) => {
  switch (action.type) {
    case DISMISS_POPUP:
      return {
        ...state,
        show: false,
      };
    case FORM_SUBMITTED:
      return {
        ...state,
        submitted: true,
      };
    case RESET_SUBMIT:
      return {
        ...state,
        submitted: false,
      };
    default:
      return state;
  }
};

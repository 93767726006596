import { memo } from 'react';
import PropTypes from 'prop-types';

import { CartileLoadingStyle } from 'app/shared/modules/Cartile/CartileUIComponents/CartileUIComponents.css';
import { CartileTotalPrice } from 'app/shared/modules/Cartile/CartileUIComponents/CartilePrice/CartileTotalPrice/CartileTotalPrice';
import { CartilePriceContainersSt } from 'app/shared/modules/Cartile/CartileUIComponents/CartilePrice/CartilePriceContainers/CartilePriceContainers.css';
import { CartileMtlPriceCalculatorLink } from 'app/shared/modules/Cartile/CartileUIComponents/CartilePrice/CartileMtlPriceCalculatorLink/CartileMtlPriceCalculatorLink';

const CartilePriceDefaultCmp = ({
  text,
  price,
  linkTo,
  isLoading,
  vatReclaimable,
  handleTracking,
  showLinkToPDPFinancingSection,
  hideDccVehicle,
}) => (
  <CartilePriceContainersSt
    isLoading={isLoading}
    css={[isLoading && CartileLoadingStyle]}
  >
    <CartileTotalPrice
      size="large"
      price={price}
      discount={false}
      vatReclaimable={vatReclaimable}
    />
    {showLinkToPDPFinancingSection && !hideDccVehicle && (
      <CartileMtlPriceCalculatorLink
        text={text}
        linkTo={linkTo}
        fontsize="12px"
        handleTracking={handleTracking}
      />
    )}
  </CartilePriceContainersSt>
);

CartilePriceDefaultCmp.propTypes = {
  isLoading: PropTypes.bool,
  text: PropTypes.object.isRequired,
  price: PropTypes.number.isRequired,
  linkTo: PropTypes.string.isRequired,
  vatReclaimable: PropTypes.bool.isRequired,
  handleTracking: PropTypes.func.isRequired,
  showLinkToPDPFinancingSection: PropTypes.bool.isRequired,
  hideDccVehicle: PropTypes.bool,
};

CartilePriceDefaultCmp.defaultProps = {
  isLoading: false,
  hideDccVehicle: false,
};

export const CartilePriceDefault = memo(CartilePriceDefaultCmp);

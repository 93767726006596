/* eslint-disable jsx-a11y/alt-text */
import { useCallback, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { noop } from 'app/shared/utils/utils';

export const ResponsiveImageSt = styled.div`
  text-align: center;

  img {
    width: 100%;
    height: auto;
  }
`;

export const setSrcSet = (sources, breakpoint) =>
  sources[breakpoint].reduce((accumulator, source, index) => {
    if (index !== 0)
      return `${accumulator || ''}${accumulator ? ', ' : ''}${source} ${
        index + 1
      }x`;
    return accumulator;
  }, null);

/* TODO: could create a `useBreakpoint` hook instead of prop passing */
/**
 * @typedef {import('app/types/style.types').TBreakpoint} TBreakpoint
 *
 * Responsive Image/Picture loader supports single or array of source based on pixel density
 * @param {object}                                       props
 * @param {string|string[]|Record<TBreakpoint, string>}  props.sources - a single image source or a source set [1x, 2x and 3x] in order: ;
 * @param {TBreakpoint}                                  props.breakpoint
 * @param {() => void}                                   props.onLoad - callback for when image loads
 * @param {boolean}                                      [props.useWebp]
 * @param {string}                                       [props.defaultImage]
 * @param {string}                                       [props.alt]
 * @param {string}                                       [props.fetchpriority]
 */
export const ResponsiveImageLoader = ({
  sources,
  breakpoint,
  onLoad,
  useWebp,
  ...imgAttr
}) => {
  const imgRef = useRef();
  const [imageLoaded, setImageLoaded] = useState(false);

  // if the image is done loading,
  // call the onLoad callback early
  useEffect(() => {
    if (imgRef.current?.complete) {
      onLoad();
      setImageLoaded(true);
    }
  }, []);

  const handleOnLoad = useCallback(() => {
    onLoad();
    setImageLoaded(true);
  }, [onLoad]);

  const isArray = Array.isArray(sources[breakpoint]);
  const src = isArray ? sources[breakpoint][0] : sources[breakpoint];
  const srcSet = isArray ? setSrcSet(sources, breakpoint) : undefined;

  // eslint-disable-next-line react/no-multi-comp
  const renderImg = () => (
    <img
      ref={imgRef}
      onLoad={imageLoaded ? noop : handleOnLoad}
      src={src}
      srcSet={srcSet}
      width="296px"
      height="145px"
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...imgAttr}
    />
  );

  return (
    <ResponsiveImageSt>
      {useWebp ? (
        <picture>
          <source srcSet={`${src}?fm=webp`} type="image/webp" />
          {renderImg()}
        </picture>
      ) : (
        renderImg()
      )}
    </ResponsiveImageSt>
  );
};

const imgSrcShape = PropTypes.oneOfType([
  PropTypes.string,
  PropTypes.arrayOf(PropTypes.string),
]);

ResponsiveImageLoader.propTypes = {
  breakpoint: PropTypes.string,
  /** An images by breakpoint mapping */
  sources: PropTypes.shape({
    sm: imgSrcShape,
    md: imgSrcShape,
    lg: imgSrcShape,
    xl: imgSrcShape,
  }),
  onLoad: PropTypes.func,
  useWebp: PropTypes.bool,
};

ResponsiveImageLoader.defaultProps = {
  breakpoint: 'sm',
  sources: { sm: '', md: '', lg: '', xl: '' },
  onLoad: noop,
  useWebp: false,
};

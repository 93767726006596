import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';

import {
  WrapperSt,
  MessageSt,
  DropDownSt,
  ErrorTextSt,
  HelperTextSt,
} from './Select.css';

const noop = () => {};

export const Select = (props) => {
  const {
    autoComplete,
    name,
    className,
    onChange,
    onBlur,
    onFocus,
    options,
    value,
    width,
    height,
    errorText,
    selectRef,
    helperText,
    tabIndex,
    disabled,
    setFieldTouched,
    label,
  } = props;
  const [hasFocus, setHasFocus] = useState(false);

  /**
   * Handling value change as well as focusing logic
   */
  const handleChange = useCallback(() => {
    setHasFocus(false);
    setFieldTouched(name, true);
  }, [hasFocus]);

  /**
   * Rendering additional texts to the input wrapper, such as error
   * messages or helper texts
   */
  const renderAddonText = useCallback(() => {
    if (errorText) {
      return (
        <MessageSt>
          <ErrorTextSt>{errorText}</ErrorTextSt>
        </MessageSt>
      );
    }

    if (helperText) {
      return (
        <MessageSt>
          <HelperTextSt>{helperText}</HelperTextSt>
        </MessageSt>
      );
    }
    return null;
  }, [errorText, helperText]);

  return (
    <WrapperSt
      className={className}
      onChange={handleChange}
      onBlur={onBlur}
      onFocus={onFocus}
      hasAddonText={errorText || helperText}
      label={label}
    >
      <DropDownSt
        disabled={disabled}
        ref={selectRef}
        autoComplete={autoComplete}
        name={name}
        onChange={onChange}
        value={value}
        width={width}
        height={height}
        hasError={errorText}
        tabIndex={tabIndex}
      >
        {options.map((item, idx) => {
          return (
            <option
              key={`${idx}-${item.value}`}
              value={item.value}
              disabled={item.disabled}
            >
              {item.label}
            </option>
          );
        })}
      </DropDownSt>
      {renderAddonText()}
    </WrapperSt>
  );
};

Select.propTypes = {
  autoComplete: PropTypes.string,
  name: PropTypes.string,
  className: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.any.isRequired,
    }),
  ).isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  width: PropTypes.string,
  height: PropTypes.string,
  tabIndex: PropTypes.number,
  helperText: PropTypes.string,
  errorText: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  selectRef: PropTypes.object,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
  setFieldTouched: PropTypes.func,
  disabled: PropTypes.bool,
  label: PropTypes.string,
};

Select.defaultProps = {
  autoComplete: '',
  className: '',
  name: '',
  value: '',
  width: 'auto',
  height: '48px',
  helperText: '',
  errorText: '',
  disabled: false,
  selectRef: undefined,
  tabIndex: 0,
  onChange: noop,
  onBlur: noop,
  onFocus: noop,
  setFieldTouched: noop,
  label: '',
};

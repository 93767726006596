import { memo } from 'react';
import PropTypes from 'prop-types';

import {
  CartileContainerSt,
  CartileContainerWrapperSt,
} from 'app/shared/modules/Cartile/CartileTemplates/CartileContainer/CartileContainer.css';
import { CartileImageContainer } from 'app/shared/modules/Cartile/CartileTemplates/CartileImageContainer/CartileImageContainer';
import { CartileContentContainer } from 'app/shared/modules/Cartile/CartileTemplates/CartileContentContainer/CartileContentContainer';
import { CartileAdditionalInfosContainer } from 'app/shared/modules/Cartile/CartileTemplates/CartileAdditionalInfosContainer/CartileAdditionalInfosContainer';
import { CARTILE_PADDING } from 'app/shared/modules/Cartile/CartileTemplates/CartileTemplates.constant';
import { CartileLinkContainer } from 'app/shared/modules/Cartile/CartileTemplates/CartileLinkContainer/CartileLinkContainer';

const CartileContainerCmp = ({
  width,
  index,
  target,
  linkTo,
  isHorizontal,
  isHighlighted,
  isUnavailable,
  hasRemoveIcon,
  handleTracking,
  backgroundColor,
  paddingHorizontal,
  cartileLinkContainer,
  cartileImageContainer,
  cartileContentContainer,
  cartileAdditionalInfosContainer,
  isPushVehicle,
  className,
}) => {
  const {
    CartileLinkContainerChildren,
    CartileLinkContainerChildrenProps,
  } = cartileLinkContainer;
  const {
    CartileImageContainerChildren,
    CartileImageContainerChildrenProps,
  } = cartileImageContainer;

  const {
    CartileAdditionalInfosChildren,
    CartileAdditionalInfosChildrenProps,
  } = cartileAdditionalInfosContainer;

  return (
    <CartileContainerWrapperSt
      width={width}
      paddingHorizontal={paddingHorizontal}
      isHorizontal={isHorizontal}
      {...(index === 1 ? { id: `carTile${index + 1}` } : {})}
    >
      <CartileLinkContainer
        isUnavailable={isUnavailable}
        hasRemoveIcon={hasRemoveIcon}
      >
        <CartileLinkContainerChildren {...CartileLinkContainerChildrenProps} />
      </CartileLinkContainer>

      <CartileContainerSt
        isHorizontal={isHorizontal}
        isHighlighted={isHighlighted}
        isUnavailable={isUnavailable}
        backgroundColor={isPushVehicle ? 'white' : backgroundColor}
        to={linkTo}
        target={target}
        onClick={handleTracking}
        aria-label="Auto-Detail Link"
        data-testid="shared-components-cartileWide-cartileWideContainer-link"
      >
        <CartileImageContainer
          isHorizontal={isHorizontal}
          isUnavailable={isUnavailable}
        >
          <CartileImageContainerChildren
            {...CartileImageContainerChildrenProps}
          />
        </CartileImageContainer>

        <CartileContentContainer
          isHorizontal={isHorizontal}
          isUnavailable={isUnavailable}
          cartileContentContainer={cartileContentContainer}
          className={className}
        />
      </CartileContainerSt>
      <CartileAdditionalInfosContainer
        isHorizontal={isHorizontal}
        isUnavailable={isUnavailable}
      >
        {CartileAdditionalInfosChildren && (
          <CartileAdditionalInfosChildren
            {...CartileAdditionalInfosChildrenProps}
          />
        )}
      </CartileAdditionalInfosContainer>
    </CartileContainerWrapperSt>
  );
};

CartileContainerCmp.propTypes = {
  width: PropTypes.array,
  index: PropTypes.number.isRequired,
  target: PropTypes.string,
  isHorizontal: PropTypes.bool,
  isHighlighted: PropTypes.bool,
  isUnavailable: PropTypes.bool,
  hasRemoveIcon: PropTypes.bool,
  backgroundColor: PropTypes.string,
  paddingHorizontal: PropTypes.number,
  linkTo: PropTypes.string.isRequired,
  handleTracking: PropTypes.func.isRequired,
  cartileLinkContainer: PropTypes.objectOf(PropTypes.any.isRequired).isRequired,
  cartileImageContainer: PropTypes.objectOf(PropTypes.any.isRequired)
    .isRequired,
  cartileContentContainer: PropTypes.objectOf(PropTypes.any.isRequired)
    .isRequired,
  cartileAdditionalInfosContainer: PropTypes.objectOf(PropTypes.any),
  isPushVehicle: PropTypes.bool,
};

CartileContainerCmp.defaultProps = {
  width: [1, 1 / 2, 1 / 3],
  target: 'CLP',
  isHorizontal: false,
  isUnavailable: false,
  hasRemoveIcon: false,
  isHighlighted: false,
  backgroundColor: 'inherit',
  paddingHorizontal: CARTILE_PADDING,
  cartileAdditionalInfosContainer: {
    CartileAdditionalInfosChildren: null,
    CartileAdditionalInfosChildrenProps: {},
  },
  isPushVehicle: false,

  className: PropTypes.string.isRequired,
};

export const CartileContainer = memo(CartileContainerCmp);

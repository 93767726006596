import { FilterMappings } from 'app/marketplace/filter/mappings';
import {
  getMultipleOfferPills,
  hasMultipleOfferCampaign,
} from 'app/shared/modules/Campaign/CampaignMultipleOffer/util';
import { CartileInfosPills } from 'app/shared/modules/Cartile/CartileUIComponents/CartileInfosPills/CartileInfosPills';
import { getPromotionPills } from 'app/shared/utils/getPromotionPills';
import { getFormattedVehicleDate, getPS } from 'app/shared/utils/vehicle';

export const getDataForCartileContentInfos = ({
  vehicle,
  isLoading,
  performanceUnit,
  campaigns = [],
  fullWidthPulls = false,
  isDealerCashEligible = false,
}) => {
  const {
    newCar,
    mileage,
    gearBox,
    fuelType,
    dataSource,
    emissionPerKm,
    singleDayRegistration,
    firstRegistrationDate,
    reducedPricePercentage,
    fuelConsumptionCombined,
  } = vehicle;
  const isNewVehicle = newCar && !singleDayRegistration;
  const hasConsumptionPills = fuelConsumptionCombined > 0 || emissionPerKm > 0;
  const km = parseInt(mileage, 10);
  const fuel = FilterMappings.fuelType[fuelType];
  const gears = FilterMappings.gearBox[gearBox];
  const date = getFormattedVehicleDate(firstRegistrationDate, true, '/', true);
  const performance =
    performanceUnit === 'ps' ? getPS(vehicle.performance) : vehicle.performance;
  const reducedValuePercentage = reducedPricePercentage
    ? `${Math.round(reducedPricePercentage)}%`
    : null;
  const isD2C = dataSource === 'GWC_D2C' || dataSource === 'INSTAMOTION';

  const promotionPills =
    getMultipleOfferPills(campaigns, vehicle) ||
    getPromotionPills(campaigns, vehicle);

  /* istanbul ignore next */
  const { dealerFinancing } = vehicle?.financingPrecalc?.searchFilters || {};

  let hasDealerCashCampaign = false;
  if (Array.isArray(campaigns) && campaigns.length > 0) {
    hasDealerCashCampaign = campaigns?.some(
      (campaign) => campaign?.fields?.id === '2211_dc_1000',
    );
  }

  return {
    CartileContentInfosChildren: CartileInfosPills,
    CartileContentInfosChildrenProps: {
      km,
      fuel,
      date,
      gears,
      isD2C,
      isLoading,
      performance,
      isNewVehicle,
      emissionPerKm,
      performanceUnit,
      hasConsumptionPills,
      reducedValuePercentage,
      fuelConsumptionCombined,
      promotionPills,
      dealerFinancing,
      fullWidthPulls,
      hasDealerCashCampaign,
      isDealerCashEligible,
    },
  };
};

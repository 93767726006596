export interface ICurrentLocation {
  lat: number;
  lon: number;
  place: string;
  zip: string;
}

type TGeoLocationError =
  | { locationNotFound: boolean }
  | { locationDeniedByUser: boolean }
  | string
  | null;

export type TGeoLocation = {
  currentLocation: ICurrentLocation | Record<string, never>;
  error: TGeoLocationError;
  locationPending: boolean;
  locationSuggestions: ICurrentLocation[] | never[];
};

export enum GeoLocationActionsTypes {
  RESET_LOCATION_SUGGESTION = 'GEOLOCATION/RESET_LOCATION_SUGGESTION',
}

export type TResetSuggestionsAction = {
  type: GeoLocationActionsTypes.RESET_LOCATION_SUGGESTION;
};

/* eslint-disable react-perf/jsx-no-new-function-as-prop */
import React, { useEffect, useRef, useState, KeyboardEvent } from 'react';
import { Flex } from '@rebass/grid';

import { InputBox } from '../InputBox/InputBox';
import { Icon } from '../Icon/Icon';

import { TInputSearchProps } from './InputSearch.types';
import { InputWrapperSt } from './InputSearch.css';
import { SuggestionsPrefixSt } from './Suggestions.css';
import { Suggestions } from './Suggestions';

export const InputSearch = ({
  className,
  onChange,
  onFocus,
  onBlur,
  onClick,
  value,
  name,
  tabIndex,
  disabled = false,
  isRequired = false,
  errorText,
  compact = false,
  placeholder,
  width,
  suggestions,
  onSelect,
  icon = 'search',
  prefixComponent,
  showDropdownPrefix = false,
  noResultsVariant = 'suggestions',
  dropdownPrefixTexts = { noFound: '', otherSuggetions: '' },
}: TInputSearchProps): JSX.Element => {
  const [isFocused, setIsFocused] = useState<boolean>(false);
  const [focusedSuggestion, setFocusedSuggestion] = useState<number | null>(
    null,
  );
  const inputRef = useRef<HTMLInputElement>(null);
  const suggestionsRef = useRef<HTMLDivElement>(null);
  const { noFound, otherSuggetions } = dropdownPrefixTexts;
  const DefaultPrefixComp = (
    <SuggestionsPrefixSt>
      <li>{noFound}</li>
      <li className="divider" />
      <li>{otherSuggetions}</li>
    </SuggestionsPrefixSt>
  );
  const prefixComp = prefixComponent || DefaultPrefixComp;
  const showDropdown = Boolean(isFocused && suggestions.length);

  // in variant==='suggestions' the dropdown will prepend info elements
  // wich are not keyboard focusable, we need to filter those out
  const getButtonsFromCollection = () => {
    const elems: HTMLCollection | undefined = suggestionsRef?.current?.children;
    /*
    Next Line originally causing TS error: 
    Type 'HTMLCollection' must have a '[Symbol.iterator]()' method that returns an iterator.ts(2488)
    Needed to add: "DOM.Iterable" to tsconfig file: "lib": ["dom", "esnext", "DOM.Iterable"],
    */
    const elemsArr = elems ? [...elems] : [];
    const buttons = elemsArr.filter((elem: Element) => {
      return elem.tagName === 'BUTTON';
    });

    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return buttons;
  };

  useEffect(() => {
    // reset focusedSuggestion since we're showing different data set
    setFocusedSuggestion(null);
  }, [showDropdownPrefix]);

  const handleFocus = () => {
    setIsFocused(true);
    /* istanbul ignore next */
    if (onFocus) onFocus();
  };

  const handleChange = (event: KeyboardEvent) => {
    setFocusedSuggestion(null);
    onChange(event);
  };

  const handleKeyDown = (event: KeyboardEvent<HTMLElement>) => {
    if (!isFocused) setIsFocused(true);
    const buttons = getButtonsFromCollection();

    if (event.key === 'ArrowUp') {
      if (focusedSuggestion) {
        const index = focusedSuggestion - 1;
        if (suggestionsRef && suggestionsRef.current) {
          // eslint-disable-next-line prettier/prettier
          const elem = (buttons[index] as HTMLElement)
          if (elem) elem.focus();
          setFocusedSuggestion(index);
        }
      }
    }

    if (event.key === 'ArrowDown') {
      if (focusedSuggestion === null) {
        const elem = buttons[0] as HTMLElement;
        if (elem) elem.focus();
        setFocusedSuggestion(0);
      } else if (focusedSuggestion < suggestions.length - 1) {
        const elem = buttons[focusedSuggestion + 1] as HTMLElement;
        if (elem) elem.focus();
        setFocusedSuggestion(focusedSuggestion + 1);
      }
    }

    if (event.key === 'Enter') {
      event.preventDefault();
      const newSuggestion = suggestions[focusedSuggestion as number];
      if (newSuggestion) {
        onSelect(newSuggestion);
        setIsFocused(false);
      }
    }
  };

  const handleClickOutside = (event: MouseEvent) => {
    /* istanbul ignore next */
    if (inputRef && !inputRef?.current?.contains(event.target as Node | null)) {
      setIsFocused(false);
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  });

  return (
    <InputWrapperSt
      className={className}
      width={width}
      isEmpty={!value}
      ref={inputRef}
      onKeyDown={handleKeyDown}
    >
      <InputBox
        disableAutofill
        onChange={handleChange}
        onFocus={handleFocus}
        onBlur={onBlur}
        value={value?.label}
        name={name}
        autoComplete="off"
        placeholder={placeholder}
        tabIndex={tabIndex}
        disabled={disabled}
        compact={compact}
        isRequired={isRequired}
        errorText={errorText}
        suffix={
          <Flex
            bg="actionBlue"
            alignItems="center"
            justifyContent="center"
            className="search-icon-button"
          >
            <Icon
              icon={icon}
              iconSize="small"
              color="white"
              onClick={onClick}
            />
          </Flex>
        }
      />

      {showDropdown && (
        <Suggestions
          suggestionsRef={suggestionsRef}
          items={suggestions}
          prefixCmp={prefixComp}
          showDropdownPrefix={showDropdownPrefix}
          variant={noResultsVariant}
          onSelect={(suggestion, index) => {
            setIsFocused(false);
            setFocusedSuggestion(index);
            onSelect(suggestion);
          }}
          focusedIndex={focusedSuggestion}
        />
      )}
    </InputWrapperSt>
  );
};

import PropTypes from 'prop-types';

export const MenuTitle = ({ action }) => {
  switch (action) {
    case 'call':
      return 'Händler anrufen';
    case 'requestVideo':
      return 'Video-Anfrage';
    case 'callback':
      return 'Rückruf anfordern';
    case 'contactDealerChoice':
      return 'Fahrzeug anfragen';
    default:
      return null;
  }
};

MenuTitle.propTypes = {
  action: PropTypes.string.isRequired,
};

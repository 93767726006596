import styled from 'styled-components';
import { ContainerSt } from 'app/shared/ui';

export const SeoFooterLinksWrapperSt = styled.article`
  background: white;
  margin: 40px 0;
`;

interface ISeoFooterLinksGridSt {
  grid: boolean;
  md: number;
}
export const SeoFooterLinksGridSt = styled(
  ContainerSt,
)<ISeoFooterLinksGridSt>``;

export const SeoFooterLinksGroupSt = styled.ul`
  padding: 0;
  list-style: none;
  margin-bottom: 24px;
  && a {
    text-decoration: none;
    font-weight: normal;
    font-size: 16px;
    line-height: 2;
  }
`;

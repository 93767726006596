/* eslint-disable react-perf/jsx-no-new-function-as-prop */
import { memo } from 'react';
import PropTypes from 'prop-types';
import { Box } from '@rebass/grid';

import { Heading6, Image, IndeterminateLoadingSpinner } from 'app/shared/ui';
import { FinancingOptionsFilter } from 'app/marketplace/filter/Filters/FinancingOptionsFilter/FinancingOptionsFilter';
import {
  ButtonSt,
  MakeSelectSt,
  ModelSelectSt,
  MakeModelWrapperSt,
  FilterPanelContainerSt,
  FilterPanelInputWrapperSt,
  ContainerSt,
  TabStWithBg,
} from 'app/marketplace/home/Stage/FilterPanel/FilterPanel.css';
import {
  handleTracking,
  trackResultClick,
  trackFinancingOptionFilter,
  trackPriceChangeFilter,
  trackSearchSaleTabChange,
} from 'app/marketplace/home/Stage/FilterPanel/trackings';
import { isAFilterSelected } from 'app/marketplace/filter/utils';
import { useMakeFilter } from 'app/marketplace/filter/FiltersHooks/useMakeFilter';
import { useModelFilter } from 'app/marketplace/filter/FiltersHooks/useModelFilter';
import { FilterPanelFooter } from 'app/marketplace/home/Stage/FilterPanelFooter/FilterPanelFooter';
import { TabbedContent } from 'app/shared/components/TabbedContent/TabbedContent';
import { ContentBox } from 'app/shared/components/TabbedContent/ContentBox';
import { Carsale24EntryPointPanel } from 'app/marketplace/home/Stage/FilterPanel/Carsale24EntryPointPanel/Carsale24EntryPointPanel';
import { FilterPanelLocationFilter } from 'app/marketplace/home/Stage/FilterPanelLocationFilter/FilterPanelLocationFilter';
import { formatNumber } from 'app/shared/utils/formatNumber';

const FilterPanelCmp = ({
  filters,
  isLoading,
  deviceType,
  vehicleCount,
  resetLocation,
  locationError,
  onChangeFilter,
  currentLocation,
  locationPending,
  showVehicleCount,
  resetSuggestions,
  openSidebarFilters,
  getLocationFromUser,
  locationSuggestions,
  getLocationFromCoords,
  getLocationSuggestions,
  changeUrlfromLandingPage,
  setCurrentLocationFromObject,
}) => {
  const hasFilters = isAFilterSelected(filters, true);

  const [selectedMake, makesOptions, handleSelectedMake] = useMakeFilter({
    onChangeFilter,
    handleTracking,
    makeFilter: filters.make,
    modelFilter: filters.model,
    hasHighlightedMakesList: true,
    possibleMakes: filters.possibleMakes,
  });
  const [selectedModel, modelsOptions, handleSelectedModel] = useModelFilter({
    onChangeFilter,
    makeFilter: filters.make,
    modelFilter: filters.model,
    possibleMakes: filters.possibleMakes,
    handleTracking,
  });

  const queryCoords = {
    lat: filters.lat[0]?.value,
    lon: filters.lon[0]?.value,
  };

  const vehicleCountButtonText = showVehicleCount ? (
    `${isLoading ? '' : formatNumber(vehicleCount)} ${
      vehicleCount === 1 ? 'Auto' : 'Autos'
    } anzeigen`
  ) : (
    <IndeterminateLoadingSpinner type="secondary" size="s" />
  );

  const handleGoToClp = () => {
    trackResultClick();
    changeUrlfromLandingPage('/gebrauchtwagen');
  };

  // eslint-disable-next-line react/no-multi-comp
  const renderSearchPanel = () => {
    return (
      <FilterPanelContainerSt pt={[8, 8, 8]}>
        <FilterPanelInputWrapperSt>
          <Heading6
            as="h1"
            fontSize={[18, 16, 20]}
            lineHeigh={[1.33, 1.5, 1.6]}
          >
            Finde gebrauchte Autos mit Garantie.
          </Heading6>
          <MakeModelWrapperSt>
            <MakeSelectSt
              width="100%"
              value={selectedMake}
              options={makesOptions}
              onChange={handleSelectedMake}
            />
            <ModelSelectSt
              width="100%"
              value={selectedModel}
              options={modelsOptions}
              disabled={
                selectedMake === 'all' ||
                isLoading ||
                (modelsOptions.length === 1 &&
                  modelsOptions[0].label === 'Alle Modelle')
              }
              onChange={handleSelectedModel}
            />
          </MakeModelWrapperSt>

          <FilterPanelLocationFilter
            resetLocation={resetLocation}
            locationError={locationError}
            handleTracking={handleTracking}
            onChangeFilter={onChangeFilter}
            currentLocation={currentLocation}
            locationPending={locationPending}
            resetSuggestions={resetSuggestions}
            getLocationFromUser={getLocationFromUser}
            locationSuggestions={locationSuggestions}
            getLocationSuggestions={getLocationSuggestions}
            setCurrentLocationFromObject={setCurrentLocationFromObject}
          />
          <Box width={1} mt={12}>
            <FinancingOptionsFilter
              onChangeFilter={onChangeFilter}
              showSliderWithInput={false}
              trackPriceChange={trackPriceChangeFilter}
              trackFinancingOptionChange={trackFinancingOptionFilter}
            />
          </Box>
          <ButtonSt
            variant="primary"
            onClick={handleGoToClp}
            isLoading={isLoading}
            disabled={vehicleCount === 0}
            data-testid="marketplace-home-stage-filterpanel-goToClpBtn"
          >
            <span>
              {hasFilters ? vehicleCountButtonText : 'Alle Treffer anzeigen'}
            </span>
          </ButtonSt>

          <FilterPanelFooter
            openSidebarFilters={openSidebarFilters}
            changeUrlfromLandingPage={changeUrlfromLandingPage}
            getLocationFromCoords={getLocationFromCoords}
            locationCoords={queryCoords}
            deviceType={deviceType}
            origin="homepage"
          />
        </FilterPanelInputWrapperSt>
      </FilterPanelContainerSt>
    );
  };

  return (
    <ContainerSt>
      <TabbedContent
        TabComponent={(props) => <TabStWithBg {...props} />}
        activeIndex={0}
        onClickTab={(e, index) =>
          /* istanbul ignore next */ trackSearchSaleTabChange(e, index)
        }
      >
        <ContentBox
          title={
            <>
              <Image
                src="/client/assets/tradein/carsale24/icon-magnifier.svg"
                alt="search-icon"
                mr={12}
                fontSize={16}
              />
              Suchen
            </>
          }
          hasNoContent
        >
          {renderSearchPanel()}
        </ContentBox>
        <ContentBox
          title={
            <>
              <Image
                src="/client/assets/tradein/carsale24/icon-tradein.svg"
                alt="tradein-icon"
                mr={12}
                fontSize={16}
              />
              Verkaufen
            </>
          }
          hasNoContent
        >
          <Carsale24EntryPointPanel />
        </ContentBox>
      </TabbedContent>
    </ContainerSt>
  );
};

FilterPanelCmp.propTypes = {
  filters: PropTypes.object.isRequired,
  isLoading: PropTypes.bool.isRequired,
  deviceType: PropTypes.string.isRequired,
  resetLocation: PropTypes.func.isRequired,
  vehicleCount: PropTypes.number.isRequired,
  onChangeFilter: PropTypes.func.isRequired,
  locationPending: PropTypes.bool.isRequired,
  resetSuggestions: PropTypes.func.isRequired,
  showVehicleCount: PropTypes.bool.isRequired,
  currentLocation: PropTypes.object.isRequired,
  openSidebarFilters: PropTypes.func.isRequired,
  getLocationFromUser: PropTypes.func.isRequired,
  locationSuggestions: PropTypes.array.isRequired,
  getLocationFromCoords: PropTypes.func.isRequired,
  getLocationSuggestions: PropTypes.func.isRequired,
  changeUrlfromLandingPage: PropTypes.func.isRequired,
  setCurrentLocationFromObject: PropTypes.func.isRequired,
  locationError: PropTypes.object,
};

FilterPanelCmp.defaultProps = {
  locationError: null,
};

export const FilterPanel = memo(FilterPanelCmp);

import { variables } from 'app/shared/ui';

export const {
  media,
  color,
  colorV2,
  colorRgb,
  getRgba,
  getColor,
  iconSizes,
  fontSizes,
  grid,
  breakpoints,
  marginTopUnderSearchBar,
  fontWeights,
  webkitOnly,
  supports,
  evergreenOnly,
  chromeOnly,
  styledIf,
  zIndex,
  height,
  is,
  isNot,
  isOr,
  isSomeNot,
  magazine,
} = variables;

import styled, { css } from 'styled-components';
import { variables } from 'app/shared/ui';

const {
  variables: { media },
} = variables;

export const LoadingAnimationContainerSt = styled.div`
  position: relative;
  width: 104px;
  height: 62px;
  background: url('/assets/loader/page-loader_320.gif') no-repeat;
  background-size: 100%;
  display: inline-block;
  ${media.md(css`
    background: url('/assets/loader/page-loader_768.gif') no-repeat;
    width: 128px;
    height: 76px;
  `)};
  ${media.lg(css`
    background: url('/assets/loader/page-loader_1024.gif') no-repeat;
    width: 140px;
    height: 83px;
  `)};
  ${media.xl(css`
    background: url('/assets/loader/page-loader.gif') no-repeat;
    width: 192px;
    height: 114px;
  `)};
`;

/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/prop-types */

import React, { useState, useEffect, memo } from 'react';

import { NavFlyoutContainer } from '../NavFlyoutContainer/NavFlyoutContainer';

import { NavItemLabel } from './components/NavItemLabel/NavItemLabel';
import { INavItemProps, isUserItem } from './NavItem.types';
import { handleOnClick, handleBackButtonClick } from './utils';
import { BoxSt } from './NavItem.css';

export const NavItem = memo(
  ({
    renderType,
    mobileBackButtonLabel,
    selectedItem,
    selectOnClick,
    target,
    useRouter = false,
    item,
    item: {
      label,
      itemId,
      itemCategories,
      fullWidthCategory,
      url,
      brandsInfo,
      isVisibleOnDesktop = true,
      footercontent,
    },
    setTracking,
    closeMenu,
    ariaRole,
    useCustomChildren = false,
    customChildren,
    menuHoverCallback,
  }: INavItemProps): JSX.Element => {
    const [isOpen, setIsOpen] = useState(selectedItem === itemId);

    useEffect(() => {
      setIsOpen(selectedItem === itemId);
    }, [selectedItem]);

    if (!isVisibleOnDesktop) {
      return <></>;
    }

    return (
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      <BoxSt
        isFullHeight={isUserItem(item)}
        width={['100%', '100%', 'auto']}
        as="li"
        role="tab"
        aria-labelledby={ariaRole}
        id={ariaRole}
        tabindex="-1"
        className={
          selectedItem === itemId &&
          (Boolean(itemCategories) || useCustomChildren)
            ? 'active'
            : ''
        }
      >
        <NavItemLabel
          to={url}
          setSelectedItem={selectOnClick}
          setTracking={setTracking}
          useCustomChildren={useCustomChildren}
          onClick={() => {
            setTracking('main', itemId);
            handleOnClick({
              id: itemId,
              selectedItem,
              selectOnClick,
              setIsOpen,
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              userAction: !itemCategories ? item.userAction : undefined,
            });
          }}
          id={itemId}
          isOpen={isOpen}
          useRouter={useRouter}
          target={target}
          hasItemCategories={Boolean(itemCategories)}
          renderType={renderType}
          label={label}
          menuHoverCallback={menuHoverCallback}
        >
          {(itemCategories || customChildren) && (
            <NavFlyoutContainer
              setTracking={setTracking}
              handleBackButtonClick={handleBackButtonClick}
              mobileBackButtonLabel={mobileBackButtonLabel}
              itemCategories={itemCategories}
              selectOnClick={selectOnClick}
              setIsOpen={setIsOpen}
              closeMenu={closeMenu}
              fullWidthCategory={fullWidthCategory}
              brandsInfo={brandsInfo}
              useCustomChildren
              customChildren={customChildren}
              footercontent={footercontent}
            />
          )}
        </NavItemLabel>
      </BoxSt>
    );
  },
);

NavItem.displayName = 'NavItem';

/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */

/* istanbul ignore file */
import { Flex, Box } from '@rebass/grid';
import styled, { css } from 'styled-components';

import { Heading3, variables, Link } from 'app/shared/ui';
import { FlexCol } from 'app/marketplace/shared/components/Layouts/FlexCol';
import { Col } from 'app/marketplace/shared/components/Col/Col';
import { AccordionItem } from 'app/shared/components/Accordion/AccordionItem/AccordionItem';
import {
  ArrowSt,
  BodySt,
  ContentSt,
  HeaderSt,
  TitleSt,
} from 'app/shared/components/Accordion/AccordionItem/AccordionItem.css';
import {
  P1ResponsiveSt,
  P1FixedSt,
} from 'app/shared/components/Typo/TextTemplates';

const {
  variables: { color, media },
} = variables;

export const DetailsSt = styled.div`
  margin-bottom: 104px;

  ${media.md(css`
    margin-bottom: 32px;
  `)}
`;

export const DetailSt = styled(AccordionItem)`
  & ${TitleSt} {
    font-size: 24px !important;

    ${media.md(css`
      font-size: 28px !important;
    `)};

    ${media.lg(css`
      font-size: 32px !important;
    `)};
  }
  & > ${HeaderSt} {
    max-height: none;
    border-bottom: ${(props) => props.open && '0'};
    border-color: ${
      /* istanbul ignore next */ ({ theme: { colors } }) => colors.grayLight
    };
    ${(props) =>
      props.noPaddingBottom
        ? css`
            padding: 16px 24px 0 24px;
          `
        : css`
            padding: 16px 24px;
          `};

    ${media.md(css`
      font-size: 16px;
      padding: 16px 0;
    `)};

    & > ${TitleSt} {
      ${P1ResponsiveSt};
    }

    & > ${ArrowSt} {
      top: 20px;
      right: 24px;

      ${media.md(css`
        right: 0;
      `)};
    }
  }

  & ${ContentSt} {
    padding-top: ${(props) => (props.noPaddingBottom ? 0 : '8px')};
    margin: 0 8px;
    ${({ isFullWidth }) =>
      isFullWidth &&
      css`
        padding-right: 0;
        margin-right: -4px;
      `};

    ${media.md(css`
      margin: 0;
    `)}

    ${({ withoutSpace }) =>
      withoutSpace &&
      css`
        padding-right: 0;
        padding-left: 0;
        margin-right: 0;
        margin-left: 0;
      `}
  }

  & > ${BodySt} {
    border-bottom: ${(props) => props.open && '0'};
  }

  ${({ isAccordionFullWidth }) =>
    isAccordionFullWidth &&
    css`
      background-color: ${
        /* istanbul ignore next */ ({ theme: { colors } }) =>
          colors.sunbeanLighter
      };

      ${media.md(css`
        background-color: inherit;
        position: sticky;
        margin-top: 32px;
        z-index: 1;

        & > ${BodySt} {
          overflow: inherit;
        }

        & > ${HeaderSt} {
          margin-bottom: 0;
          padding-top: 40px;
          padding-bottom: 0;
        }
      `)}

      ${media.md(
        css`
          position: relative;
        `,
        true,
      )}
    `};
`;

export const DetailsVehicleListSt = styled(Col).attrs(() => ({
  tagName: 'ul',
}))`
  font-size: 13px;
  line-height: 24px;
  list-style: none;

  ${media.md(css`
    ${P1FixedSt};
  `)};

  & > li {
    position: relative;
  }

  & > li::before {
    border-radius: 50%;
    content: '';
    height: 4px;
    width: 4px;
    position: absolute;
    top: 10px;
    left: -10px;

    ${media.md(css`
      top: 14px;
    `)};
  }
`;

export const ShareAndPrintDesktopSt = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  margin: 40px 24px;

  div:first-child {
    padding-right: 15px;
    border-right: 1px solid ${color.grayLight};
    cursor: pointer;

    i {
      margin-left: 8px;
      font-size: 16px;
    }
  }

  div:first-child:hover {
    color: ${color.brandPrimary};
  }

  div:last-child {
    padding: 0 16px;

    span {
      font-size: 16px;
    }
  }
`;

export const CTAContainerSt = styled(FlexCol)`
  position: relative;
  width: auto;
  padding: 12px 0;

  & > span {
    span {
      color: ${({ theme: { colors } }) => colors.tarmacGrey};
    }
  }
`;

export const LabelSpanSt = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 16px;

  & > i {
    font-size: 24px;
  }
`;

export const FlexSt = styled(Box).attrs((props) => ({
  as: 'ul',
  flexWrap: 'wrap',
  pt: 16,
  pr: 16,
  pl: 0,
  ...props,
}))`
  margin: 0 0;
  list-style: none;
  columns: 1 auto;
  ${media.md(css`
    columns: 2 auto;
  `)}
  li {
    /* fix: safari issue of applying margin-bottom
    at the bottom of a column to the next one's top
    https://stackoverflow.com/a/44671098/5339853
    */
    display: inline-block;
    width: 100%;
    /* fix end */
    font-size: 16px;
    line-height: 2;
  }
`;

export const ErrorMessageSt = styled(Flex).attrs(() => ({
  justifyContent: 'center',
  alignItems: 'center',
}))`
  margin: 48px 0 16px;

  & > span {
    font-size: 16px;
    color: ${color.graySubtle};
  }
`;

export const HeaderTitleSt = styled(Heading3).attrs(
  /* istanbul ignore next */ () => ({
    fontSize: [24, 28, 32],
    lineHeight: [1.67, 1.5],
  }),
)`
  padding-bottom: 32px;
  color: ${
    /* istanbul ignore next */ ({ theme: { colors } }) => colors.tarmacGrey
  };

  font-weight: 800;

  ${media.md(css`
    padding-bottom: 40px;
  `)}

  ${media.lg(css`
    padding-bottom: 48px;
  `)}
`;

export const CampaignsDisclaimerSt = styled.div`
  color: ${({ theme: { colors } }) => colors.gray};
  font-size: 10px;
  font-weight: normal;
  line-height: 16px;

  span {
    display: block;
  }
`;

export const InstamotionGarantieBanner = styled(Flex)`
  background-color: ${({ theme: { colors } }) => colors.mustangLighter};
  padding: 32px 30px;
  font-weight: 400;
  flex-wrap: wrap;
  justify-content: center;
  margin: 32px 24px;
  box-shadow: 0px 0px 12px rgba(29, 29, 29, 0.16);
  border-radius: 0px 0px 4px 4px;
  text-align: center;
  font-size: 14px;
  line-height: 24px;

  img {
    width: 200px;
    margin-bottom: 25px;
  }

  span {
    display: block;
  }

  ${media.md(css`
    flex-wrap: nowrap;
    padding: 16px 48px;
    justify-content: normal;
    margin: 0;
    text-align: left;
    line-height: 32px;
    box-shadow: none;
    border-radius: none;

    span {
      display: inline-block;
    }

    img {
      width: 250px;
      margin-right: 48px;
      margin-top: auto;
      margin-bottom: auto;
    }
  `)}
`;

export const InstamotionGarantieLink = styled(Link)`
  display: block;
  margin-top: 12px;

  ${media.md(css`
    display: inline-block;
    margin-top: 0;
  `)}
`;

import isEqual from 'lodash.isequal';

import { logger } from '../../utils/logger';

import * as ActionTypes from './constants';

/**
 * This action creator should be used to open an overlay with a specific component as content
 * @param {Function|Class} component - the component that should be displayed
 * @param {Object} config - the config that should be used to display this component.
 * @returns {Function} - an action creator
 */
export const openOverlay = (component, config = { props: {} }) => (
  dispatch,
  getState,
) => {
  const activeComponent =
    getState().overlay && getState().overlay.activeOverlayComponent;
  const nextComponent = { component, config };

  if (isEqual(activeComponent, nextComponent)) {
    logger.warn(
      '[OVERLAY] you are trying to open an overlay that is already open',
      nextComponent,
    );
    return;
  }
  if (activeComponent) {
    const {
      config: { isAutoOpen },
    } = activeComponent;

    if (!isAutoOpen && nextComponent.config.isAutoOpen) {
      logger.warn(
        '[OVERLAY] will not open modal because the currently open one has not been opened automatically, while the next one should be opened automatically',
      );
      return;
    }
    if (!isAutoOpen && !nextComponent.config.isAutoOpen) {
      logger.warn(
        '[OVERLAY] Replacing existing overlay content, please make sure you close any open overlays before opening a new one',
      );
    }
  }

  dispatch({
    type: ActionTypes.OPEN_OVERLAY,
    payload: nextComponent,
  });
};

/**
 * Used to close the currently open overlay, components that are displayed will be unmounted.
 * @returns {Function} - an action creator
 */
export const closeOverlay = () => (dispatch, getState) => {
  const activeComponent =
    getState().overlay && getState().overlay.activeOverlayComponent;

  if (!activeComponent) {
    logger.warn('[OVERLAY] Cannot close overlay, no overlay is open!');
    return;
  }
  dispatch({
    type: ActionTypes.CLOSE_OVERLAY,
  });
};

/**
 * Used to update the title of the overlay
 * @param {String} title - the new title for the opened overlay.
 * @returns {Function} - an action creator
 */
export const updateOverlayTitle = title => (dispatch, getState) => {
  const activeComponent =
    getState().overlay && getState().overlay.activeOverlayComponent;

  if (!activeComponent) {
    logger.warn('[OVERLAY] Cannot update overlay title, no overlay is open!');
    return;
  }
  dispatch({
    type: ActionTypes.UPDATE_TITLE,
    payload: title,
  });
};

import PropTypes from 'prop-types';

import { FilterMappings } from './mappings';
import { defaultState } from './reducer';
import {
  apiFilters,
  notFiltersThatCountAsFilters,
  filtersUrlExcluded,
  intRangeFilters,
  floatRangeFilters,
  marketingParamsWhitelist,
} from './config';

const safeSeparator = '__';

/**
 * to keep a consistent filter object, use this function to create a filter
 * @param {Object} option structure to describe the filter
 * @returns {{category, value, uuid: string, label: string, operator: string, parent: null, direction: null}} return consistent data structure
 */

export const getFilterObject = (option) => ({
  category: option.category,
  value: option.value,
  uuid: `${option.category}:${option.value}`,
  label: option.label || `${option.value}`,
  operator: 'and',
  parent: option.parent || null,
  direction: option.direction || null,
});

export const getGenericFilterAsArray = (
  filterCategory,
  includeLabelAndParent,
  attachOperator = true,
) => {
  return filterCategory.map((filter) => {
    if (filter.value === undefined || filter.value === null) {
      return '';
    }

    let operator = '';

    if (attachOperator) {
      operator = filter.operator === 'or' ? '|' : ',';
    }

    if (includeLabelAndParent && filter.parent) {
      return `${filter.value}${safeSeparator}${filter.label}${safeSeparator}${filter.parent}${operator}`;
    }
    return `${filter.value}${operator}`;
  });
};

/**
 * creates a url query segment for a generic filter
 * @param {Array} filterCategory the category of the filter reducer e.g. makes:[object, object]
 * @param {String} key key to display in the url (should be the same as the corresponding key in the reducer)
 * @param {Boolean} includeLabelAndParent will generate a format to represent value, label and parent in the url
 * @returns {String} returns a key=value string
 */
const getGenericFilterValue = (filterCategory, key, includeLabelAndParent) => {
  const values = getGenericFilterAsArray(filterCategory, includeLabelAndParent);
  let value = values.join('');

  value = value.substr(0, value.length - 1);
  return value === '' ? '' : `${key}=${value}`;
};

const getRangeFilterValuesAsArray = (filterCategory, parsingFunc) => {
  const values = [];

  filterCategory.forEach((filter) => {
    const filterValues = filter.value.split('-');
    let value;

    if (filterValues.length === 1) {
      const from = parsingFunc.call(null, filterValues[0]);

      value = `${from}`;
    } else {
      const from = parsingFunc.call(null, filterValues[0]);
      const to = parsingFunc.call(null, filterValues[1]);

      value = `${from}-${to}`;
    }

    values.push(value);
  });

  return values;
};

/**
 * creates a url query segment for a range filter
 * @param {Array} filterCategory the category of the filter reducer e.g. makes:[object, object]
 * @param {String} key key to display in the url (should be the same as the corresponding key in the reducer)
 * @returns {String} returns a key=value string
 */
const getRangeFilterValue = (filterCategory, key, parsingFunc) => {
  const values = getRangeFilterValuesAsArray(filterCategory, parsingFunc);

  const result = values.join('');

  return `${key}=${result}`;
};

/**
 * returns the sortBy/sortDirection query
 * @param {Array} filterCategory the category of the filter reducer e.g. makes:[object, object]
 * @returns {String} returns a key=value string
 */
const getSort = (filterCategory) => {
  return `sortBy=${filterCategory[0].value}&sortDirection=${filterCategory[0].direction}`;
};

/**
 * returns the whole query string from the filter reducer
 * @param {Object} filters filter reducer object
 * @param {Boolean} isApiQuery indicates if this is a query for the backend API or for the address bar and removes certain Filters
 * @param {Boolean} includeLabelAndParent indicates if the label and parent relationship should be included,
 * @param {Set<string>} customExcludes: Set of Filters that should be stripped out of the result
 * @returns {String} returns query string
 */
export const getQueryFromFilters = (
  filters,
  isApiQuery = false,
  includeLabelAndParent = false,
  customExcludes = new Set([]),
) => {
  const parameters = [];
  const filterSet = new Set(Object.keys(filters));
  const apiIncludes = apiFilters;

  const urlExcludes = new Set(filtersUrlExcluded);
  const mappingSet = new Set([...Object.keys(FilterMappings), ...apiIncludes]);
  let commonSet;

  if (isApiQuery) {
    commonSet = new Set([...mappingSet].filter((item) => filterSet.has(item)));
  } else {
    commonSet = new Set(
      [...filterSet].filter((item) => !urlExcludes.has(item)),
    );
  }

  if (customExcludes.size > 0) {
    commonSet = [...commonSet].filter((item) => !customExcludes.has(item));
  }

  commonSet.forEach((item) => {
    // if there is no filter set for a category, don't add it to the url
    if (filters[item].length === 0) {
      return;
    }
    // if we have a page filter with value 0, ignore it
    if (item === 'page' && filters[item][0].value === 0) {
      return;
    }

    let value;

    if (intRangeFilters.indexOf(item) > -1) {
      value = getRangeFilterValue(filters[item], item, (val) =>
        parseInt(val, 10),
      );
    } else if (floatRangeFilters.indexOf(item) > -1) {
      value = getRangeFilterValue(filters[item], item, (val) =>
        parseFloat(val, 10),
      );
    } else if (item === 'sortBy') {
      value = getSort(filters[item]);
    } else {
      value = getGenericFilterValue(filters[item], item, includeLabelAndParent);
    }

    if (value) {
      parameters.push(value);
    }
  });

  return parameters.join('&');
};

export const getSaveSearchQueryFromFilters = (filters) => {
  const excludedFilters = new Set(['page']);

  return getQueryFromFilters(filters, true, false, excludedFilters);
};

/**
 * parses a string into an array of generic filter object
 * @param {String} category the filter category e.g. make
 * @param {String} value the url query part that should be parsed
 * @param {Object} aggregations the aggregations from the search/count responses
 * @param {Boolean} by passing true the value can be encoded in case of special characters
 * @returns {Array} returns an array of filter objects
 */
export const getGenericFilterObject = (
  category,
  value,
  aggregations = {},
  shouldEncodeValue = false,
) => {
  const hasValue = value !== null && value !== undefined;
  const values = hasValue ? `${value}`.split(/[|,]/) : [];
  const filterObjects = [];
  const max = values.length;
  if (category !== 'dataSource' && category !== 'productOffering') {
    for (let i = 0; i < max; i += 1) {
      const filterValues = values[i].split(safeSeparator);
      let value = filterValues[0];
      let trimOrModelValue;
      const isModelOrTrim = category === 'model' || category === 'trim';

      if (value.includes('_') && isModelOrTrim) {
        const cleanedUpValue = value.split('_');
        if (cleanedUpValue[cleanedUpValue.length - 1] !== 'all') {
          trimOrModelValue = cleanedUpValue[cleanedUpValue.length - 1];
        }
      }
      trimOrModelValue = trimOrModelValue || value;
      if (category === 'page') {
        value = value ? parseInt(value, 10) : 0;
      }
      let label = '';
      label = FilterMappings[category]
        ? FilterMappings[category][value]
        : `${value}`;
      if (Object.keys(aggregations).length > 0) {
        const categoryAggregations = aggregations[category];
        const valueTocheck = isModelOrTrim ? trimOrModelValue : value;
        const matchingAggregation =
          categoryAggregations &&
          categoryAggregations.find(
            (aggregationItem) => aggregationItem.key === valueTocheck,
          );
        if (matchingAggregation) {
          label = matchingAggregation.displayName;
        } else {
          label = trimOrModelValue;
        }
        if (
          matchingAggregation &&
          category === 'model' &&
          !label.includes('_')
        ) {
          value = `${matchingAggregation.make}_${matchingAggregation.key}`;
        }
      }

      const operator = FilterMappings[category]
        ? FilterMappings[category].operator
        : 'and';

      /**
       * filterValues contains parent information or label
       */
      if (filterValues.length > 1) {
        filterObjects.push(
          getFilterObject({
            category,
            value: shouldEncodeValue ? encodeURIComponent(value) : value,
            label: filterValues[1],
            uuid: `${category}:${value}`,
            operator,
            parent: filterValues[2],
          }),
        );
      } else {
        filterObjects.push(
          getFilterObject({
            category,
            value: shouldEncodeValue ? encodeURIComponent(value) : value,
            label,
            uuid: `${category}:${value}`,
            operator,
            parent: null,
          }),
        );
      }
    }
  }
  // additional handling for dataSource
  if (category === 'dataSource' || category === 'productOffering') {
    let label = '';
    label = FilterMappings[category]
      ? FilterMappings[category][value]
      : `${value}`;
    const operator = FilterMappings[category]
      ? FilterMappings[category].operator
      : 'and';
    filterObjects.push(
      getFilterObject({
        category,
        value: shouldEncodeValue ? encodeURIComponent(value) : value,
        label,
        uuid: `${category}:${value}`,
        operator,
        parent: null,
      }),
    );
  }

  return filterObjects;
};

/**
 * parses a string into an array of range filter object
 * @param {String} category the filter category e.g. make
 * @param {String} value the url query part that should be parsed
 * @returns {Array} returns an array of filter objects
 */
const getRangeFilterObject = (category, value) => {
  const values = value.split('-');
  const filterObjects = [];

  if (values.length > 1) {
    const from = parseInt(values[0], 10);
    const to = parseInt(values[1], 10);

    filterObjects.push(
      getFilterObject({
        category,
        value: `${from}-${to}`,
        label: '',
        uuid: `${category}:${from}-${to}`,
        parent: null,
        operator: 'and',
      }),
    );
  } else {
    const from = parseInt(values[0], 10);

    filterObjects.push(
      getFilterObject({
        category,
        value: `${from}`,
        label: '',
        uuid: `${category}:${from}`,
        parent: null,
        operator: 'and',
      }),
    );
  }

  return filterObjects;
};

/**
 * returns an array of sort filter with mapped directions
 * @param {String} category the filter category e.g. make
 * @param {String} value the url query part that should be parsed
 * @param {Object} filterObject the whole filter reducer object
 * @returns {Array} returns an array of sort filter with mapped directions
 */
const getSortObject = (category, value, filterObject) => {
  let filter = {};

  if (category === 'sortDirection') {
    filter = {
      direction: value,
    };
  } else {
    filter = getFilterObject({
      category,
      value,
      direction: null,
      uuid: `${category}:${value}`,
      parent: null,
      operator: 'and',
      label: '',
    });
  }

  if (filterObject.sortBy.length === 0) {
    return [filter];
  }
  return [{ ...filterObject.sortBy[0], ...filter }];
};

/**
 * returns upper case if the label has more than 3 characters; if less than or equal to 3, returns proper case
 * @param {String} label to be displayed
 * @returns {string} returns cased label
 */
export const getProperCaseLabel = (label) => {
  if (label.length <= 3) {
    return label;
  }
  return label.replace(/-/g, ' ').replace(/\b\w/u, (u) => u.toUpperCase());
};

/**
 * parses the url query and returns a filled filterObject with the same structure of the filter reducer
 * @param {Object} params object from react-router
 * @param {Object} query object from react-router
 * @param {Object} aggregations object from our filter model
 * @returns {Object} returns a filled filterObject with the same structure of the filter reducer
 */
export const getFiltersFromUri = (params, query, aggregations = {}) => {
  const filterObject = defaultState();
  const filters = { ...params, ...query };
  const productOfferingvalue =
    'DE_DEALER_CASH,DE_D2C_CASH,DE_INSTAMOTION_CASH,DE_DEALER_FINANCING_AC,DE_D2C_FINANCING_AC';

  if (filters.make === 'auto-online-kaufen') {
    filters.make = null;
    filters.productOffering = productOfferingvalue;
  }

  Object.keys(filters).forEach((category) => {
    let filterCategoryObject = {};
    const value = filters[category];

    if (
      [
        'priceRange',
        'monthlyRateRange',
        'yearRange',
        'mileageRange',
        'performanceRange',
      ].indexOf(category) > -1
    ) {
      filterCategoryObject = getRangeFilterObject(category, value);
    } else if (category === 'sortBy') {
      filterCategoryObject = getSortObject(category, value, filterObject);
    } else if (category === 'sortDirection') {
      filterCategoryObject = getSortObject(category, value, filterObject);
      category = 'sortBy';
    } else if (category === 'trim') {
      filterCategoryObject = getGenericFilterObject(
        category,
        value,
        aggregations,
        true,
      );
    } else if (
      category === 'make' ||
      category === 'model' ||
      category === 'usedCarSeal'
    ) {
      // this condition should be removed once we have all aggregations with correct displayNames/labels
      filterCategoryObject = getGenericFilterObject(
        category,
        value,
        aggregations,
      );
    } else if (
      category === 'productOffering' &&
      filterObject.productOffering.length === 0
    ) {
      // this condition should be removed once we have all aggregations with correct displayNames/labels
      filterCategoryObject = getGenericFilterObject(
        category,
        value,
        aggregations,
      );
    } else {
      filterCategoryObject = getGenericFilterObject(category, value);
    }
    if (
      filterObject[category] ||
      marketingParamsWhitelist.indexOf(category) > -1
    ) {
      filterObject[category] = filterCategoryObject;
    }
  });

  // Switch to budgetRangeSwitch monthly if necessary
  if (
    filterObject.monthlyRateRange.length > 0 &&
    filterObject.priceRange.length === 0
  ) {
    filterObject.budgetRangeSwitch = [
      getFilterObject({
        category: 'budgetRangeSwitch',
        value: 'month',
      }),
    ];
  }

  return filterObject;
};

/**
 * Returns true if a filter has been selected.
 * This should return true if a pill is shown in the search bar, and false otherwise.
 *
 * @param {Object} filterState filter reducer object
 * @returns {Boolean} returns true if a filter is selected
 */
export const isAFilterSelected = (
  filterState = {},
  shouldBlackListWithMarkerId,
) => {
  const notFiltersThatCountAsFiltersWithoutMarkerId = notFiltersThatCountAsFilters.filter(
    (item) => item !== 'markerId',
  );
  const blackList = shouldBlackListWithMarkerId
    ? notFiltersThatCountAsFiltersWithoutMarkerId
    : notFiltersThatCountAsFilters;
  const keys = Object.keys(filterState);
  const max = keys.length;
  const filterIsNotBlacklisted = (filter) => blackList.indexOf(filter) === -1;
  const filterIsSelected = (filter) => filterState[filter].length > 0;

  for (let i = 0; i < max; i += 1) {
    const key = keys[i];
    if (filterIsNotBlacklisted(key)) {
      if (filterIsSelected(key)) {
        return true;
      }
    }
  }

  return false;
};

export const isSelected = (haystack, currentOption) => {
  // In case currentOption is an array (e.g. for Neuwagen-Angebote filter on CLP)
  if (Array.isArray(currentOption.value)) {
    return (
      haystack.filter((item) => currentOption.value.includes(item.value))
        .length > 0
    );
  }

  if (
    haystack?.category === 'dataSource' ||
    haystack?.category === 'productOffering'
  ) {
    return (
      haystack.filter((item) => item.value.includes(currentOption.value))
        .length > 0
    );
  }

  return (
    haystack.filter((item) => item.value === currentOption.value).length > 0
  );
};

export const getFilterPosition = (haystack, currentOption) => {
  let position = -1;

  haystack.forEach((item, index) => {
    if (item.value === currentOption.value) {
      position = index;
    }
  });

  return position;
};

export const getFilterLabel = (category, value) =>
  (FilterMappings[category] && FilterMappings[category][value]) || null;

export const getLocationString = (zip, place) => {
  if (zip && place) {
    return `${zip}, ${place}`;
  }
  if (zip && !place) {
    return `${zip}`;
  }
  return '';
};

export const FilterShape = PropTypes.shape({
  category: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([
    PropTypes.oneOf([null]),
    PropTypes.string,
    PropTypes.number,
  ]).isRequired,
  uuid: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  operator: PropTypes.string.isRequired,
  parent: PropTypes.oneOfType([PropTypes.string]),
});

export const FilterStateShape = PropTypes.shape({
  category: PropTypes.arrayOf(FilterShape).isRequired,
  priceRange: PropTypes.arrayOf(FilterShape).isRequired,
  yearRange: PropTypes.arrayOf(FilterShape).isRequired,
  mileageRange: PropTypes.arrayOf(FilterShape).isRequired,
  bodyColor: PropTypes.arrayOf(FilterShape).isRequired,
  interiorColor: PropTypes.arrayOf(FilterShape).isRequired,
  maxOwners: PropTypes.arrayOf(FilterShape).isRequired,
  interiorType: PropTypes.arrayOf(FilterShape).isRequired,
  gearBox: PropTypes.arrayOf(FilterShape).isRequired,
  vehicleCondition: PropTypes.arrayOf(FilterShape).isRequired,
  fuelType: PropTypes.arrayOf(FilterShape).isRequired,
  emissionClass: PropTypes.arrayOf(FilterShape).isRequired,
  make: PropTypes.arrayOf(FilterShape).isRequired,
  model: PropTypes.arrayOf(FilterShape).isRequired,
  sortBy: PropTypes.arrayOf(FilterShape).isRequired,
  page: PropTypes.arrayOf(FilterShape).isRequired,
  lat: PropTypes.arrayOf(FilterShape).isRequired,
  lon: PropTypes.arrayOf(FilterShape).isRequired,
  radius: PropTypes.arrayOf(FilterShape).isRequired,
  performanceRange: PropTypes.arrayOf(FilterShape).isRequired,
  airbags: PropTypes.arrayOf(FilterShape).isRequired,
  climatisation: PropTypes.arrayOf(FilterShape).isRequired,
});

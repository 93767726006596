/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import styled, { css } from 'styled-components';

import { media } from '../variables/media';
import { Button } from '../Button/Button';

export const ScrollTopButtonSt = styled(Button)`
  position: fixed;
  z-index: 33;
  bottom: ${({ source }) => (source === 'CLP' ? '65px' : '100px')};
  right: ${({ isShown }) => (isShown ? '0' : '-100px')};
  width: 44px;
  height: 44px;
  margin-right: 20px;
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 50%;
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.2);
  transition: right 0.5s ease;
  outline: 0 none;
  pointer-events: ${({ isShown }) => (isShown ? 'auto' : 'none')};
  ${media.lg(css`
    width: 64px;
    height: 64px;
  `)}
  &:focus,
  &:active,
  &:hover {
    outline: 0 none;
    color: white;
    background-color: ${({ theme }) => theme.colors.grayLightest};
  }
  i {
    font-size: 16px;
    color: ${({ theme }) => theme.colors.brandSecondaryLight};
    ${media.lg(css`
      font-size: 24px;
    `)}
  }
`;

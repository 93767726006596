import { PureComponent } from 'react';
import PropTypes from 'prop-types';

import { InputBox } from 'app/shared/ui';

export class DefaultInputRenderer extends PureComponent {
  render() {
    return (
      <>
        <InputBox {...this.props} />
        {this.props.children}
      </>
    );
  }
}

DefaultInputRenderer.propTypes = {
  children: PropTypes.any,
};

import { memo } from 'react';
import PropTypes from 'prop-types';

export const CartileVatOptionsCmp = ({ isVatReclaimable }) => {
  return <>inkl. MwSt.{isVatReclaimable ? '' : ' - differenzbesteuert'}</>;
};

CartileVatOptionsCmp.propTypes = {
  isVatReclaimable: PropTypes.bool,
};
CartileVatOptionsCmp.defaultProps = {
  isVatReclaimable: true,
};

export const CartileVatOptions = memo(CartileVatOptionsCmp);

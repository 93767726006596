import styled, { css } from 'styled-components';
import { Box } from '@rebass/grid';

import { color, media, fontWeights } from '../../../styles_js/variables';

export const HeaderSt = styled.div`
  position: relative;
  overflow: hidden;
  cursor: pointer;
  font-size: 14px;
  line-height: 1.4;
  padding: 16px;

  ${(props) =>
    props.noMaxHeight === false &&
    css`
      max-height: 52px;
    `};

  ${(props) =>
    props.isFull &&
    css`
      border-bottom: 1px solid ${color.grayLight};
      ${media.md(
        css`
          padding-top: 23px;
          padding-bottom: 23px;
        `,
      )};
    `}

  ${(props) =>
    !props.isFull &&
    css`
      border-bottom: ${!props.isOpen && `1px solid ${props.theme.colors.gray}`};
    `}

  ${media.lg(css`
    max-height: 64px;
    font-size: 16px;
    padding: 20px 24px 20px 16px;
  `)}

  ${(props) =>
    props.disabled &&
    css`
      color: ${props.theme.colors.gray};
      cursor: initial;
    `}
`;

export const TitleSt = styled.p`
  font-weight: ${(props) => props.selected && fontWeights.bold};
  margin-top: 0;
  margin-bottom: 0;
  ${(props) =>
    props.isFull &&
    css`
      padding-right: 56px;
    `};

  color: ${({ theme: { colors } }) => colors.tarmacGrey};
`;

const getArrowColor = ({ disabled, theme: { colors } }) => {
  if (disabled) {
    return colors.gray;
  }
  return colors.micaBlue;
};

export const ArrowSt = styled.i`
  color: ${/* istanbul ignore next */ getArrowColor};
  position: absolute;
  top: 20px;
  right: 24px;

  ${media.lg(
    css`
      top: 24px;
    `,
  )};

  ${(props) =>
    props.noMaxHeight &&
    css`
      position: absolute;
      top: 26px;
      right: 24px;
    `};

  &:hover {
    color: ${({ theme: { colors } }) => colors.actionBlue} !important;
  }
`;

export const BodySt = styled.div`
  overflow: ${(props) =>
    props.isOpen && props.isOverflowVisible ? 'visible' : 'hidden'};
  max-height: ${(props) => (props.isOpen ? '9999px' : 0)};
  opacity: ${(props) => (props.isOpen ? 1 : 0)};
  transition: opacity 0.3s ease-in;

  ${
    /* istanbul ignore next */ ({ theme: { colors }, isOpen }) =>
      isOpen &&
      css`
        border-bottom: 1px solid ${colors.grayLight};
      `
  };

  ${(props) =>
    props.hasNoBorder &&
    css`
      border-bottom: none;
    `};

  ${(props) =>
    props.hasFixedHeight &&
    props.isOpen &&
    css`
      overflow-y: scroll;
      max-height: 320px;
    `}
`;

export const ContentSt = styled.div`
  margin: 20px;
  font-size: 14px;
  padding-right: 16px;
  padding-bottom: 24px;
  padding-left: 16px;
  ${(props) =>
    props.isCategory &&
    css`
      margin-top: 0;
      margin-bottom: 32px;
    `};
  ${(props) =>
    props.excludeContentMargin &&
    css`
      margin: 0;
    `};
  ${(props) =>
    props.excludeContentPadding &&
    css`
      padding: 0;
    `};

  ${(props) =>
    props.isFull &&
    css`
      border: 0;
      padding-top: 32px;
      padding-bottom: 32px;
      ${media.md(
        css`
          padding-left: 0;
          padding-right: 0;
        `,
      )};
    `};

  .checkbox {
    ${media.xl(css`
      font-size: 14px;
    `)};
  }
`;

export const BackgroundSt = styled(Box).attrs((props) => ({
  width: [null, '100vw', 'calc(100vw + 50%)'],
  ...props,
}))`
  ${media.md(css`
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    height: calc(100% - 32px);
    background-color: ${({ theme: { colors } }) => colors.sunbeanLighter};
    box-shadow: rgba(0, 0, 0, 0.15) 0px 0px 8px 0px;
    clip-path: inset(-16px 0);
  `)}
`;

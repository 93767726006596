import styled from 'styled-components';
import { Flex } from '@rebass/grid';

import { color } from 'app/shared/styles_js/variables';

export const CartileImageLoadingContainerSt = styled(Flex).attrs(() => ({
  alignItems: 'center',
  justifyContent: 'center',
}))`
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 100%;
  background-color: ${color.grayLight};
  padding-bottom: 75%;
  border-radius: 4px 4px 0 0;

  .cartile-image-spinner {
    position: absolute;
    top: 50%;
    left: 50%;
    margin: 0;
    transform: translate(-50%, -50%);
  }
`;

import React, { ImgHTMLAttributes } from 'react';
import {
  BorderRadiusProps,
  MaxHeightProps,
  MaxWidthProps,
  SizeProps,
  SpaceProps,
} from 'styled-system';

import { ImageSt } from './Image.css';

export const Image = ({ src, alt = '', ...rest }: IImageProps): JSX.Element => (
  <ImageSt src={src} alt={alt} {...rest} />
);

interface IImageProps
  extends SpaceProps,
    BorderRadiusProps,
    MaxWidthProps,
    MaxHeightProps,
    SizeProps,
    ImgHTMLAttributes<HTMLImageElement> {}

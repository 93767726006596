/* eslint-disable no-unexpected-multiline */
/* istanbul ignore file */
import styled, { css } from 'styled-components';

import { media } from 'app/shared/styles_js/variables';

export const ScrollSnapCarouselSt = styled.div`
  position: relative;
`;

export const HeaderSt = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;
`;

export const CarouselContainerSt = styled.div<{ $itemsGap: string }>`
  display: flex;
  overflow-x: auto;
  overflow-y: hidden;
  scroll-snap-type: x mandatory;
  scroll-behavior: smooth;
  gap: ${({ $itemsGap }) => `${$itemsGap}px`};
  -webkit-overflow-scrolling: touch; /* Important for iOS devices */
  /* hide scrollbar but allow scrolling */
  -ms-overflow-style: none; /* for IE, Edge */
  scrollbar-width: none; /* for Firefox */
  &::-webkit-scrollbar {
    /* for Chrome, Safari, and Opera */
    display: none;
  }
  /* mask-image: linear-gradient(90deg, #ecf7ff 90%, rgba(236, 247, 255, 0) 100%); */
  /* Rest tiles to the edges of the device on mobile */
  @media (max-width: 767px) {
    --edge-gap: 24px;
    padding: 0 var(--edge-gap);
    margin: 0 calc(-1 * var(--edge-gap));
  }

  ${media.md(
    css`
      /* To be able to scale up child items */
      --scale-gap: 5px;
      padding: var(--scale-gap);
      margin-left: calc(-1 * var(--scale-gap));
      margin-right: calc(-1 * var(--scale-gap));
      scroll-padding: 0 var(--scale-gap);

      /* To fix the smooth scroll issue on Safari */
      @media not all and (min-resolution: 0.001dpcm) {
        @supports (-webkit-appearance: none) {
          scroll-snap-type: unset;
        }
      }
    `,
  )};
`;

export const CarouselItem = styled.div`
  flex-shrink: 0;
  scroll-snap-align: center;

  ${media.md(
    css`
      scroll-snap-align: start;
    `,
  )};
`;

export const NavButtonsSt = styled.div<{
  isTargetSelectableOneClickLeads: boolean;
  oneClickLeadsCount: number;
}>`
  display: flex;
  align-items: center;
  justify-content: center;

  ${({ isTargetSelectableOneClickLeads, oneClickLeadsCount }) =>
    isTargetSelectableOneClickLeads &&
    css`
      background-color: red;
      margin-bottom: -60px;
      z-index: 1;
      box-shadow: rgb(0 0 0 / 8%) 0px 0px 4px 0px;
      margin-top: 10px;

      #carouselSelectableOneClickLeads {
        padding: 0px;
        margin: 0px;
      }

      ${media.sm(
        css`
          margin-right: -9%;
        `,
      )};

      ${media.md(
        css`
          margin-right: -1%;
        `,
      )};

      .carousel-arrow {
        width: 48px;
        height: 48px;
      }

      ${oneClickLeadsCount <= 2 &&
      css`
        ${media.md(
          css`
            .carousel-arrow {
              cursor: not-allowed;

              i {
                color: var(--gray);
              }
            }
          `,
        )};
      `}

      ${oneClickLeadsCount === 1 &&
      css`
        ${media.sm(
          css`
            .carousel-arrow {
              cursor: not-allowed;

              i {
                color: var(--gray);
              }
            }
          `,
        )};
      `}
    `}
`;

// eslint-disable-next-line react/prop-types, @typescript-eslint/explicit-module-boundary-types
export const CustomNavArrow = ({
  direction,
  ...rest
}: {
  direction: string;
}) => (
  <>
    {/* eslint-disable-next-line react/jsx-props-no-spreading */}
    <div role="button" {...rest}>
      <i className={`if-icon-arrow-${direction}`} />
    </div>
  </>
);

export const NavArrowSt = styled(CustomNavArrow)<{
  $disabled: boolean;
  className: string;
  onClick: () => void;
}>`
  width: 64px;
  height: 64px;
  background: white;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  i {
    color: ${({ theme: { colors } }) => colors.actionBlue};
    font-size: 24px;
  }

  ${({ $disabled }) =>
    $disabled &&
    css`
      cursor: not-allowed;

      i {
        color: ${({ theme: { colors } }) => colors.gray};
      }
    `}
`;

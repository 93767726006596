/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { footerContent } from 'app/marketplace/shared/NewMarketplaceTopNavBar/NewMarketplaceTopNavBar.constants';

import { CarSearchItems } from '../../MenuItemsList';
import { Brands } from '../Brands/Brands';

export const CarSearchItem = (isMobileDevice = false) => {
  return {
    useRouter: false,
    label: 'Fahrzeugsuche',
    itemId: 'CarSearchItem',
    itemCategories: CarSearchItems,
    brandsInfo: Brands,
    footercontent: footerContent(isMobileDevice),
  };
};

import { useCallback } from 'react';
import PropTypes from 'prop-types';

import { LabelSt, ButtonSt, ContainerSt } from './CustomPriceButtons.css';

export const defaultRanges = ['0-200', '200-300', '300-400', '400-500'];

/**
 * @memberof BudgetFilter
 * @description Buttons with set custom price ranges
 * @export
 * @param {*} { filterValue, onButtonClick }
 * @returns {JSX.Element}
 */
export const CustomPriceButtons = ({ filterValue, onButtonClick }) => {
  const handleClick = useCallback((value) => () => onButtonClick(value));
  return (
    <>
      <LabelSt>Beliebte Raten</LabelSt>
      <ContainerSt>
        {defaultRanges.map((value) => {
          let realRangeValue = filterValue;
          // eslint-disable-next-line prefer-destructuring
          if (filterValue === '10-200') realRangeValue = defaultRanges[0]; // Min filter cannot be 0, so it's set to 10 instead

          return (
            <ButtonSt
              active={realRangeValue === value}
              onClick={handleClick(value)}
              key={value}
              data-testid="customPrice"
            >
              {`${value}`.replace('-', ' € - ')} €
            </ButtonSt>
          );
        })}
      </ContainerSt>
    </>
  );
};

CustomPriceButtons.propTypes = {
  filterValue: PropTypes.string.isRequired,
  onButtonClick: PropTypes.func.isRequired,
};

import { put, select, debounce } from 'redux-saga/effects';

import { getVehicleCount } from 'app/marketplace/search/actions';
import {
  SET_FILTERS,
  RESET_FILTERS,
  UPDATE_FILTERS,
  OVERRIDE_FILTERS,
  RESET_SINGLE_FILTER,
  RESET_FILTER_CATEGORY,
  UPDATE_MULTITRIM_FILTER,
  UPDATE_MULTIMODEL_FILTER,
  RESET_FILTERS_EXCEPT_CATEGORY,
  RESET_MAKEMODELVARIANTE_FILTER,
} from 'app/marketplace/filter/constants';
import { RESET_CURRENT_LOCATION } from 'app/marketplace/geolocation/constants';

/**
 * All filter actions that should trigger an update of the url
 * @type {*[]}
 */
const filterActionsToWatch = [
  SET_FILTERS,
  RESET_FILTERS,
  UPDATE_FILTERS,
  OVERRIDE_FILTERS,
  RESET_SINGLE_FILTER,
  RESET_FILTER_CATEGORY,
  RESET_CURRENT_LOCATION,
  UPDATE_MULTITRIM_FILTER,
  UPDATE_MULTIMODEL_FILTER,
  RESET_FILTERS_EXCEPT_CATEGORY,
  RESET_MAKEMODELVARIANTE_FILTER,
];

/**
 * currently this only handles one functionality which is: get the vehicle count when the Filters changed
 * @return {IterableIterator<*|*>}
 */
export function* handleFilterChanges(action) {
  // some singular filter changes we want to ignore
  const filtersToIgnore = ['q'];
  if (filtersToIgnore.includes(action?.payload?.category)) {
    return;
  }

  /*
    we only want to actually update the url, when we have already entered the mobile filter page
   */
  /* istanbul ignore next */
  const { pathname } = yield select(state =>
    state.routing.locationBeforeTransitions
      ? state.routing.locationBeforeTransitions
      : {},
  );
  /* istanbul ignore next */
  if (
    (pathname && pathname.includes('/mobileFilter')) ||
    pathname.includes('/detailsuche')
  ) {
    yield put(getVehicleCount());
  }
}

/**
 * We listen to all actions that change the Filters and in turn should get the vehicle count
 * @return {IterableIterator<ForkEffect | *>}
 */
export function* mobileFilterSaga() {
  yield debounce(20, filterActionsToWatch, handleFilterChanges);
}

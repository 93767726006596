import { apiCall } from 'app/actions';
import { LOCATION_PAGINATION_SIZE } from 'app/config';
import { getFilterObject } from 'app/marketplace/filter/utils';
import * as ActionTypes from 'app/marketplace/geolocation/constants';
import * as FilterActionTypes from 'app/marketplace/filter/constants';
import { DEFAULT_RADIUS } from 'app/marketplace/filter/Filters/Filter.constant';

import { GeoLocationActionsTypes } from './types';

const getPosition = (options) => {
  return new Promise((resolve, reject) =>
    navigator.geolocation.getCurrentPosition(resolve, reject, options),
  );
};

const geoOptions = {
  timeout: 1000 * 5,
  enableHighAccuracy: false,
  maximumAge: 1000 * 60 * 60 * 2,
};

export const setCurrentLocation = (term) => {
  return (dispatch) => {
    const url = `/location/search?q=${term}&size=${LOCATION_PAGINATION_SIZE}`;

    dispatch({
      type: ActionTypes.SET_CURRENT_LOCATION_PENDING,
    });

    return dispatch(apiCall(url))
      .then((payload) => {
        const { content } = payload;
        const [item] = content;

        if (item) {
          const {
            location: { lat, lon },
            placeName: place,
            postcode: zip,
          } = item;

          dispatch({
            type: ActionTypes.SET_CURRENT_LOCATION_SUCCESS,
            payload: { lat, lon, place, zip },
          });
        } else {
          dispatch({
            error: { locationNotFound: true },
            type: ActionTypes.SET_CURRENT_LOCATION_ERROR,
          });
        }
      })
      .catch((error) => {
        dispatch({
          error,
          type: ActionTypes.SET_CURRENT_LOCATION_ERROR,
        });
      });
  };
};

export const setCurrentLocationFromObject = (locationObject) => {
  return {
    type: ActionTypes.SET_CURRENT_LOCATION_SUCCESS,
    payload: locationObject,
  };
};

export const getLocationSuggestions = (
  term,
  pageSize = LOCATION_PAGINATION_SIZE,
) => {
  return (dispatch) => {
    const url = `/location/search?q=${term}&size=${pageSize}`;

    dispatch({
      type: ActionTypes.GET_LOCATION_SUGGESTIONS_PENDING,
    });

    return dispatch(apiCall(url))
      .then((payload) => {
        const { totalElements, content } = payload;
        if (totalElements > 0) {
          const normalizedContent = content.map((item) => {
            const {
              location: { lat, lon },
              placeName: place,
              postcode: zip,
            } = item;

            return { lat, lon, place, zip };
          });

          return dispatch({
            type: ActionTypes.GET_LOCATION_SUGGESTIONS_SUCCESS,
            payload: normalizedContent,
          });
        }
        return dispatch({
          type: ActionTypes.GET_LOCATION_SUGGESTIONS_ERROR,
          error: { locationNotFound: true },
        });
      })
      .catch((error) => {
        return dispatch({
          error,
          type: ActionTypes.GET_LOCATION_SUGGESTIONS_ERROR,
        });
      });
  };
};

export const resetLocation = () => {
  return {
    type: ActionTypes.RESET_CURRENT_LOCATION,
  };
};

export const resetSuggestions = () => {
  return {
    type: GeoLocationActionsTypes.RESET_LOCATION_SUGGESTION,
  };
};

export const updateLocationFromGoogle = (gLocationId) => {
  return (dispatch, getState) => {
    const url = `/location/lookup/gaCriteria/${gLocationId}`;

    /**
     * The corresponding success action is set in the filter reducer (see below)
     */
    dispatch({ type: FilterActionTypes.SET_COORDS_PENDING });

    return dispatch(apiCall(url))
      .then((payload) => {
        const {
          location: { lat, lon },
          placeName: place,
          postcode: zip,
        } = payload;
        const radius = `${DEFAULT_RADIUS}km`;

        /**
         * Update the geolocation reducer here
         */
        dispatch({
          type: ActionTypes.SET_CURRENT_LOCATION_SUCCESS,
          payload: { lat, lon, place, zip },
        });

        /**
         * Update the filter reducer here
         */
        dispatch({
          type: FilterActionTypes.OVERRIDE_FILTERS,
          payload: {
            lat: [getFilterObject({ category: 'lat', value: lat })],
            lon: [getFilterObject({ category: 'lon', value: lon })],
            radius: [getFilterObject({ category: 'radius', value: radius })],
          },
        });

        return Promise.resolve();
      })
      .catch((error) => {
        dispatch({ type: ActionTypes.SET_CURRENT_LOCATION_ERROR, error });
        return Promise.resolve();
      });
  };
};

export const updateLocationFromCoords = () => {
  return (dispatch, getState) => {
    const { filter } = getState();
    let { lat, lon } = filter;

    if (lat.length > 0 && lon.length > 0) {
      lat = lat[0].value;
      lon = lon[0].value;
      const url = `/location/lookup/coordinates/${lat}/${lon}`;

      return dispatch(apiCall(url))
        .then((payload) => {
          const [first] = payload;
          const {
            location: { lat, lon },
            placeName: place,
            postcode: zip,
          } = first;

          /**
           * Update the geolocation reducer here
           */
          dispatch({
            type: ActionTypes.SET_CURRENT_LOCATION_SUCCESS,
            payload: { lat, lon, place, zip },
          });
          return Promise.resolve();
        })
        .catch((error) => {
          dispatch({
            type: ActionTypes.RESET_CURRENT_LOCATION,
          });
          dispatch({
            type: ActionTypes.SET_CURRENT_LOCATION_ERROR,
            error,
          });
          return Promise.resolve();
        });
    }
    return Promise.resolve();
  };
};
export const getLocationFromCoords = (lat, lon) => {
  // TODO unify this function with updateLocationFromCoords
  return async (dispatch) => {
    const url = `/location/lookup/coordinates/${lat}/${lon}`;
    try {
      const payload = await dispatch(apiCall(url));
      const [first] = payload;
      const {
        location: { lat: _lat, lon: _long },
        postcode: zip,
        placeName: place,
      } = first;

      // update the  geolocation reducer
      dispatch({
        type: ActionTypes.SET_CURRENT_LOCATION_SUCCESS,
        payload: { lat: _lat, lon: _long, zip, place },
      });

      return Promise.resolve();
    } catch (error) {
      dispatch({
        type: ActionTypes.RESET_CURRENT_LOCATION,
      });
      dispatch({
        type: ActionTypes.SET_CURRENT_LOCATION_ERROR,
        error,
      });
      return Promise.reject(new Error('LOCATION_API_FAILED'));
    }
  };
};

export const getLocationFromUser = () => {
  return async (dispatch) => {
    dispatch({
      type: ActionTypes.GET_LOCATION_SUGGESTIONS_PENDING,
    });
    try {
      const position = await getPosition(geoOptions);
      return dispatch(
        getLocationFromCoords(
          position.coords.latitude,
          position.coords.longitude,
        ),
      );
    } catch (error) {
      // Avoid dispatching an action for CLP as it will rerender the page which is not needed when the location is denied
      dispatch({
        type: ActionTypes.SET_USER_LOCATION_DENIED,
        error: { locationDeniedByUser: true },
      });
      return Promise.reject(new Error('LOCATION_DENIED'));
    }
  };
};

export const updateLocationFromUri = (query) => {
  return (dispatch) => {
    const { gLocationId, lat, long } = query;

    if (gLocationId && !lat && !long) {
      return dispatch(updateLocationFromGoogle(gLocationId));
    }
    return dispatch(updateLocationFromCoords());
  };
};

export const updateLocationFromUrl = (query) => {
  return (dispatch) => {
    const { lat, lon } = query;
    if (lat && lon) {
      return dispatch(getLocationFromCoords(lat, lon));
    }
  };
};

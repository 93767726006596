import styled, { css } from 'styled-components';

import { media } from '../variables/media';

import { THiddenContainer } from './types';
import { getCSSDisplayValue } from './utils';

export const HiddenContainer = styled.div<THiddenContainer>`
  ${({ sm, md, lg, xl }) => css`
    ${media.sm(getCSSDisplayValue(sm))};
    ${media.md(getCSSDisplayValue(md))};
    ${media.lg(getCSSDisplayValue(lg))};
    ${media.xl(getCSSDisplayValue(xl))};
  `}
`;

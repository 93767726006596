export const defaultOrganisationSchema = {
  '@context': 'https://schema.org',
  '@type': 'Organization',
  url: 'https://hey.car',
  brand: 'heycar',
  address: {
    '@type': 'PostalAddress',
    addressLocality: 'Berlin',
    addressCountry: 'DE',
    postalCode: '13353',
    streetAddress: 'Müllerstraße 153',
  },
  logo: 'https://hey.car/heycar_color.jpg',
  sameAs: [
    'https://www.facebook.com/heycar.official/',
    'https://twitter.com/heycar_official',
    'https://www.linkedin.com/company/heycar/',
    'https://www.youtube.com/channel/UCMFkEo9GkQjLH7jAYPw7doA',
    'https://www.crunchbase.com/organization/heycar',
  ],
};

export const defaultActionSchema = {
  '@context': 'https://schema.org',
  '@type': 'WebSite',
  url: 'https://hey.car',
  potentialAction: {
    '@type': 'SearchAction',
    target: 'https://hey.car/gebrauchtwagen?page=0&q={search_term_string}',
    'query-input': 'required name=search_term_string',
  },
};

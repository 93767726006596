import * as ActionTypes from './constants';
import { cookieTypes, defaultCookieConsentState } from './utils';

/**
 * Note:
 *
 * please do not change this reducer without
 * contacting dealer/pricing team
 *
 * `web-app-dealer` shares this cookie dashboard state
 */
export const defaultState = () => defaultCookieConsentState();

export function cookieDashboard(state = defaultState(), action) {
  const { type, payload } = action;

  if (type === ActionTypes.MIGRATE_COOKIE_DASHBOARD) {
    if (!state.cookieStatus) return { ...state };

    const migratedState = {
      ...state,
      preferences: {
        ...state.preferences,
        analytical:
          state.cookieStatus.chat && state.cookieStatus.contactDetails,
        marketing: !!state.cookieStatus.marketing,
      },
    };

    delete migratedState.cookieStatus;

    return { ...migratedState };
  }

  if (type === ActionTypes.SET_HAS_SAVED_COOKIE_SETTINGS) {
    return {
      ...state,
      hasSavedCookieSettings: true,
    };
  }

  if (type === ActionTypes.SET_AGREE_TO_ALL) {
    const preferences = { ...state.preferences };

    cookieTypes.forEach((cookieType) => {
      preferences[cookieType] = true;
    });

    return {
      ...state,
      preferences,
    };
  }

  if (type === ActionTypes.SET_REJECT_ALL) {
    const preferences = { ...state.preferences };

    cookieTypes.forEach((cookieType) => {
      preferences[cookieType] = false;
    });

    return {
      ...state,
      preferences,
    };
  }

  if (type === ActionTypes.UPDATE_COOKIE_STATUS) {
    const preferences = { ...state.preferences };

    cookieTypes.forEach((cookieType) => {
      if (Object.hasOwnProperty.call(payload, cookieType)) {
        preferences[cookieType] = payload[cookieType];
      }
    });

    return {
      ...state,
      preferences,
    };
  }

  return state;
}

import { getNormalizedVehicle } from 'app/shared/utils/vehicle';

import {
  VEHICLE_GET,
  VEHICLE_GET_SUCCESS,
  VEHICLE_GET_ERROR,
  VEHICLE_REMOVE,
} from '../../constants';

export const initialState = {
  vehicle: getNormalizedVehicle({}),
  isLoading: false,
  hasError: false,
};

export function vehicle(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case VEHICLE_GET:
      return { ...state, isLoading: true, hasError: false };
    case VEHICLE_GET_SUCCESS:
      return {
        ...state,
        isLoading: false,
        vehicle: getNormalizedVehicle(payload.content[0]),
      };
    case VEHICLE_GET_ERROR:
      return { ...state, isLoading: false, hasError: true };
    case VEHICLE_REMOVE:
      return initialState;
    default:
      return state;
  }
}

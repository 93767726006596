import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { IndeterminateLoadingSpinner } from 'app/shared/ui';

import { logger } from 'app/shared/utils/logger';
import { noop } from 'app/shared/utils/utils';

import { LazyLoadingSpinnerSt } from './LazyWrapper.css';

const defaultSpinner = (
  <LazyLoadingSpinnerSt>
    <IndeterminateLoadingSpinner />
  </LazyLoadingSpinnerSt>
);

export const makeLazy = ({
  getComponent,
  exportName,
  spinner = defaultSpinner,
}) => {
  class LazyWrapper extends PureComponent {
    constructor(props) {
      super(props);
      this.doSetState = true;
      this.state = {
        Component: null,
      };
    }

    componentDidMount() {
      const { onLoadingStart, onLoadingEnd, onError } = this.props;

      onLoadingStart();

      if (this.doSetState) {
        getComponent()
          .then((module) => {
            if (this.doSetState) {
              this.setState({ Component: module[exportName] });
              onLoadingEnd(module);
            }
          })
          .catch((error) => {
            onError(error, this.props);
            logger.error(`[LazyWrapper] - ${error}`);
          });
      }
    }

    componentWillUnmount() {
      this.doSetState = false;
    }

    render() {
      const { Component } = this.state;

      if (!Component) {
        return spinner;
      }

      return <Component {...this.props} />;
    }
  }

  LazyWrapper.propTypes = {
    onLoadingStart: PropTypes.func,
    onLoadingEnd: PropTypes.func,
    onError: PropTypes.func,
  };

  LazyWrapper.defaultProps = {
    onLoadingStart: noop,
    onLoadingEnd: noop,
    onError: noop,
  };

  return LazyWrapper;
};

import { all, debounce, fork, put, select } from 'redux-saga/effects';

import {
  SET_FILTERS,
  RESET_FILTERS,
  UPDATE_FILTERS,
  OVERRIDE_FILTERS,
  RESET_SINGLE_FILTER,
} from 'app/marketplace/filter/constants';
import { RESET_CURRENT_LOCATION } from 'app/marketplace/geolocation/constants';
import { getVehicleCount } from 'app/marketplace/search/actions';

const VALID_D2C_PAGES_URL = '/auto-online-bestellen' || '/online-kauf';

const VALID_CLICK_AND_COLLECT_PAGE_URL = '/click-und-collect';
const FILTERPANEL_URL = {
  home: '/',
  location: '/auto/gebrauchtwagen/',
  d2c: VALID_D2C_PAGES_URL,
  clickAndCollect: VALID_CLICK_AND_COLLECT_PAGE_URL,
};

const filterActionsToWatch = [
  SET_FILTERS,
  RESET_FILTERS,
  UPDATE_FILTERS,
  OVERRIDE_FILTERS,
  RESET_SINGLE_FILTER,
  RESET_CURRENT_LOCATION,
];

/**
 * Listen to onChangeFilter and call vehicleCount API when user interact with the filters
 * */
export function* handleUpdateFiltersOnHomePage() {
  const { pathname } = yield select((state) =>
    /* istanbul ignore next */
    state.routing.locationBeforeTransitions
      ? state.routing.locationBeforeTransitions
      : {},
  );

  if (
    pathname === FILTERPANEL_URL.home ||
    pathname.startsWith(FILTERPANEL_URL.d2c) ||
    pathname.startsWith(FILTERPANEL_URL.location) ||
    pathname.startsWith(FILTERPANEL_URL.clickAndCollect)
  ) {
    yield put(getVehicleCount());
  }
}

export function* updateFiltersOnHomePage() {
  yield debounce(20, filterActionsToWatch, handleUpdateFiltersOnHomePage);
}

export function* filterPanelSagas() {
  yield all([fork(updateFiltersOnHomePage)]);
}

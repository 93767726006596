import { trackAction } from 'app/shared/utils/tracking';

export const isNewListing = (createdAt: string): boolean => {
  const fiveDaysBeforeDate = new Date();
  fiveDaysBeforeDate.setDate(new Date().getDate() - 5);
  return new Date(createdAt) > fiveDaysBeforeDate;
};

export const trackCallCtaOnCLP = (): void => {
  trackAction('call-start_button_click_clp', {
    category: 'CLP',
    label: 'call_cta_clp',
    description: 'user clicks call cta on CLP',
  });
};

import { Fragment, memo } from 'react';
import PropTypes from 'prop-types';
import _isEmpty from 'lodash.isempty';
import LazyLoad from 'react-lazyload';

import { Link } from 'app/shared/ui';
import { getTextWithEllipsis } from 'app/marketplace/contentful/utils';
import { DateAndCategory } from 'app/marketplace/contentful/spaces/magazine/entries/DateAndCategory/DateAndCategory';
import { useStyledTheme } from 'app/shared/hooks/useStyledTheme';

import {
  LinkSt,
  TopImageSt,
  ArticleTitleSt,
  ArticleWrapperSt,
  ArticlePreviewTextSt,
  ArticlePreviewContentSt,
  MIN_IMAGE_HEIGHT,
} from './ArticlePreview.css';

export const ArticlePreviewCmp = ({
  entry: { fields = {} } = {},
  noPadding,
  articleType,
  getArticlesFromStore,
  categoryNameSlug,
  withTextsLimit,
  noDescription,
  lazy,
}) => {
  const { colors } = useStyledTheme();
  if (_isEmpty(fields)) {
    return null;
  }
  const {
    previewText,
    urlSlug,
    topArticleImage,
    title,
    articleCategory,
  } = fields;

  const categoryNameSlugFallback = articleCategory?.fields?.categoryNameSlug;
  let imageUrl;

  /* The following tests are really only so that the nested element can be tested */
  const ImageWrapper = lazy ? LazyLoad : Fragment;
  const imageWrapperProps = lazy
    ? { height: MIN_IMAGE_HEIGHT, offset: 100 }
    : {};

  if (!topArticleImage || !topArticleImage.fields) {
    imageUrl = '/assets/magazine_article_fallback.png';
  } else {
    const {
      file: { url: _imageUrl },
    } = topArticleImage.fields;
    imageUrl = `${_imageUrl}?fm=jpg&fl=progressive&w=640`;
  }

  if (!previewText || !title) {
    return null;
  }

  const articleRoute = `/magazine/${urlSlug}`;
  const handleGetArticlesFromStore = () => {
    getArticlesFromStore(
      articleType,
      urlSlug,
      categoryNameSlug || categoryNameSlugFallback,
    );
  };

  return (
    <ArticleWrapperSt>
      <Link to={articleRoute} title={title}>
        <ImageWrapper {...imageWrapperProps}>
          <TopImageSt
            src={imageUrl}
            alt={title}
            title={title}
            onClick={handleGetArticlesFromStore}
          />
        </ImageWrapper>
      </Link>

      <ArticlePreviewContentSt noPadding={noPadding}>
        <DateAndCategory {...fields} activeLink />
        <ArticleTitleSt>
          {withTextsLimit ? getTextWithEllipsis(title, 55) : title}
        </ArticleTitleSt>
        {!noDescription && (
          <ArticlePreviewTextSt noDescription>
            {withTextsLimit
              ? getTextWithEllipsis(previewText, 120)
              : previewText}
          </ArticlePreviewTextSt>
        )}
        <LinkSt
          to={articleRoute}
          color={colors.mintDark}
          hovercolor={colors.brandPrimary}
          onClick={() =>
            getArticlesFromStore(articleType, urlSlug, categoryNameSlug)
          }
        >
          Weiterlesen
        </LinkSt>
      </ArticlePreviewContentSt>
    </ArticleWrapperSt>
  );
};

ArticlePreviewCmp.displayName = 'ArticlePreview';

ArticlePreviewCmp.propTypes = {
  entry: PropTypes.object.isRequired,
  articleType: PropTypes.string.isRequired,
  getArticlesFromStore: PropTypes.func.isRequired,
  categoryNameSlug: PropTypes.string,
  noPadding: PropTypes.bool,
  withTextsLimit: PropTypes.bool,
  noDescription: PropTypes.bool,
  lazy: PropTypes.bool,
};

ArticlePreviewCmp.defaultProps = {
  noPadding: false,
  categoryNameSlug: '',
  withTextsLimit: false,
  noDescription: false,
  lazy: true,
};

export const ArticlePreview = memo(ArticlePreviewCmp);

import transform from 'lodash.transform';
import pick from 'lodash.pick';

export const getSelectedOptions = (options) =>
  options.find(({ selected }) => selected);

export const getValueFromSelectedItem = ({ type, options, value, label }) => {
  switch (type) {
    case 'singleSelect':
      return transform(
        pick(getSelectedOptions(options), ['value', 'genericLabel']),
        (result, value, key) => {
          if (key === 'genericLabel') {
            result.label = value;
          } else {
            result[key] = value;
          }
        },
        {},
      );

    case 'singleSlider': {
      const selectedOption = getSelectedOptions(options);
      return { value: selectedOption?.value, label: selectedOption?.label };
    }

    case 'numberInput':
      return { value, label };

    case 'multiSelect':
      return options
        .filter(({ selected }) => selected)
        .map((option) => ({ value: option.value }))
        .reduce(
          (result, data) => ({
            value: result.value ? `${result.value},${data.value}` : data.value,
          }),
          { value: null },
        );

    default:
      return {};
  }
};

import { combineReducers } from 'redux';

import { info } from './info';
import { finance } from './finance';
import { leasing } from './leasing';
import { cash } from './cash';
import { callbackTime } from './callbackTime';

export const checkoutOrderInfo = combineReducers({
  finance,
  leasing,
  info,
  cash,
  callbackTime,
});

import { Flex } from '@rebass/grid';
import styled, { css } from 'styled-components';
import { Carousel, ContainerSt, Heading3 } from 'app/shared/ui';

import { color, zIndex, media } from 'app/shared/styles_js/variables';
import { CartileContainerWrapperSt } from 'app/shared/modules/Cartile/CartileTemplates/CartileContainer/CartileContainer.css';
import { CartileVerticalContentContainerSt } from 'app/shared/modules/Cartile/CartileTemplates/CartileContentContainer/CartileContentContainer.css';

export const MoreLikeThisSt = styled.section`
  position: relative;
  z-index: ${zIndex.moreLikeThis};
  width: 100%;
  height: auto;
  min-height: 636px;
  padding-top: ${
    /* istanbul ignore next */ ({ smallTopSpacing }) =>
      smallTopSpacing ? '24px' : '48px'
  };
  padding-bottom: 24px;
  background-color: ${
    /* istanbul ignore next */ ({ vehicleDataSource, theme: { colors } }) =>
      vehicleDataSource === 'electric'
        ? colors.brandPrimaryLightest
        : colors.sunbeanSuperLight
  };
  ${media.md(
    css`
      min-height: 636px;
      padding-top: ${
        /* istanbul ignore next */ ({ smallTopSpacing }) =>
          smallTopSpacing ? '24px' : '56px'
      };
    `,
  )};
  ${media.xl(
    css`
      min-height: 710px;
      padding-top: ${
        /* istanbul ignore next */ ({ smallTopSpacing }) =>
          smallTopSpacing ? '24px' : '64px'
      };
    `,
  )};
`;

export const ListingWrapperSt = styled(ContainerSt)`
  position: relative;
  height: auto;

  ${media.md(
    css`
      height: 100%;
    `,
  )};

  &:first-child {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }

  & .slick-list {
    margin-right: 0;
    margin-left: -32px;
  }
`;

export const HeaderSt = styled(Flex).attrs(
  /* istanbul ignore next */ ({ isMobile }) => ({
    width: 1,
    justifyContent: 'flex-start',
    flexDirection: /* istanbul ignore next */ isMobile ? 'column' : 'row',
  }),
)``;

export const TitleSt = styled(Heading3).attrs(
  /* istanbul ignore next */ () => ({
    fontSize: [24, 32, 32],
    lineHeight: [1.67, 1.5],
  }),
)`
  position: relative;
  margin-bottom: 30px;
  color: ${
    /* istanbul ignore next */ ({ theme: { colors } }) => colors.tarmacGrey
  };
  font-weight: 800;
  ${media.md(css`
    height: 54px;
    margin-bottom: 40px;
  `)};
  ${media.xl(
    css`
      margin-bottom: 30px;
    `,
  )};
`;

export const CarouselSt = styled(Carousel)`
  & ${CartileVerticalContentContainerSt} {
    background-color: ${color.white};
  }

  & .slick-list {
    margin-right: 0;
    margin-left: 0;

    ${media.md(css`
      margin-right: -16px;
      margin-left: -16px;
    `)}

    ${({ hasOneResult, isMobile }) =>
      /* istanbul ignore next */
      hasOneResult &&
      isMobile &&
      css`
        display: flex;
        justify-content: center;
        width: 100%;
      `};
  }

  & .slick-track {
    ${({ isResultsLessThanSlidesToShow, isMobile }) =>
      /* istanbul ignore next */
      isResultsLessThanSlidesToShow &&
      !isMobile &&
      css`
        width: 100% !important;
      `};

    & ${CartileContainerWrapperSt} {
      padding-right: 8px;
      padding-left: 8px;

      ${media.md(
        css`
          padding-right: 16px;
          padding-left: 16px;
        `,
      )};
    }
  }

  .slick-arrow.slick-prev,
  .slick-arrow.slick-next {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .slick-arrow.slick-prev > i,
  .slick-arrow.slick-next > i {
    font-size: 24px;
    margin-top: 0;
  }

  & .slick-slide {
    ${({ isResultsLessThanSlidesToShow }) => {
      /* istanbul ignore next */
      return (
        isResultsLessThanSlidesToShow &&
        css`
          width: 100% !important;
          ${media.md(
            css`
              width: 50% !important;
            `,
          )};
          ${media.lg(
            css`
              width: 33.3333% !important;
            `,
          )};
        `
      );
    }};
  }
`;

export const LoadingContainerSt = styled.div`
  display: flex;
  width: 100%;
  min-height: 696px;
  align-items: center;
  justify-content: center;

  ${media.md(
    css`
      height: 100%;
      min-height: auto;
    `,
  )}
`;

/* istanbul ignore next */
export const CustomNavArrow = ({
  currentSlide,
  slideCount,
  direction,
  className,
  trackAction,
  ...rest
}) => (
  <div className={`${className}`} onClick={() => trackAction(direction)}>
    <i className={`if-icon-arrow-${direction}-long`} {...rest} />
  </div>
);

export const NavArrowSt = styled(CustomNavArrow)`
  top: -100px !important;
  width: 64px;
  height: 64px;
  background: white;

  ${media.lg(
    css`
      top: -90px !important;
    `,
  )}

  i {
    color: ${({ theme: { colors } }) => colors.brandSecondaryLighter};
    font-size: 35px;
    margin-top: 15px;
    text-align: center;
  }
  &.slick-disabled {
    i {
      color: ${({ theme: { colors } }) => colors.gray};
    }
  }
  &.slick-arrow {
    top: -110px;
  }
  &.slick-arrow.slick-prev {
    right: 64px;
  }
`;

export const ErrorSt = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  text-align: center;
  color: ${color.graySubtle};
  pointer-events: none;
`;

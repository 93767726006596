import { ConnectedModal } from 'app/shared/modules/overlay/defaultOverlays/modal/Modal';
import { LazyCall } from 'app/marketplace/contactDealer/Call/LazyCall';

export const CallModal = ({ ...props }) => {
  return (
    <ConnectedModal ariaLabel="call lead modal" title="Händler anrufen">
      <LazyCall {...props} isModal />
    </ConnectedModal>
  );
};

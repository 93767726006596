import styled, { css } from 'styled-components';
import { Flex } from '@rebass/grid';

import { Text } from 'app/shared/ui';

export const CartileTotalPriceContainerSt = styled(Flex).attrs(() => ({
  alignItems: 'baseline',
}))`
  ${() =>
    css`
      justify-content: right;
    `}
  flex: 0 0 auto;
  color: ${({ theme, discount }) =>
    discount ? theme.colors.oldFerrari : null};
`;

export const CartileTotalPriceTextSt = styled(Text).attrs(({ size }) => ({
  as: 'div',
  fontWeight: 'bold',
  lineHeight: 'normal',
  fontSize: size === 'small' ? [10, 14] : [14, 16],
}))`
  display: inline-block;
  padding-left: 4px;
`;

export const CartileTotalPriceVATTextSt = styled(Text).attrs(({ size }) => ({
  as: 'div',
  lineHeight: 'normal',
  fontSize: size === 'small' ? 10 : 12,
}))`
  ${() =>
    css`
      display: flex;
      justify-content: right;
    `}

  padding-left: 4px;
  color: ${({ theme, discount }) =>
    discount ? theme.colors.oldFerrari : null};
`;

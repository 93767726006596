import { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { setTags, captureException } from '@sentry/react';

import { Link } from 'app/shared/ui';

import { BASE_URL, IMAGE_SERVICE_URL } from '../../../config';
import { trackAction } from '../../../shared/utils/tracking';

import { IconList } from './IconList/IconList';
import {
  BenefitsSectionSt,
  ContentSt,
  GuaranteeBenefitsSt,
  GuaranteeInfoButtonSt,
  ImageSectionSt,
  ImageSt,
  TitleSt,
  Heading5St,
} from './GuaranteeBenefits.css';

export class GuaranteeBenefits extends PureComponent {
  constructor(props) {
    super(props);

    this.getImageUrl = this.getImageUrl.bind(this);
  }

  getImageUrl(baseUrl) {
    const { breakpoint } = this.props;
    const assetLink = '/assets/home/home-car-seal.jpg';
    let width = 0;

    switch (breakpoint) {
      case 'sm':
        width = 640;
        break;
      case 'md':
        width = 1440;
        break;
      default:
        width = 1440;
    }

    if (baseUrl.indexOf('localhost') > -1) {
      return `${assetLink}`;
    }

    return `${IMAGE_SERVICE_URL}/unsafe/${width}x/filters:quality(90)/${baseUrl}${assetLink}`;
  }

  render() {
    return (
      <GuaranteeBenefitsSt>
        <TitleSt as="h2">Fast-wie-Neuwagen</TitleSt>
        <ContentSt>
          <ImageSectionSt>
            <ImageSt
              imageUrl={this.getImageUrl(BASE_URL)}
              alt="chip seal"
              width="320px"
              height="152px"
              handleError={(error) => {
                setTags({
                  environment: PRODUCTION ? 'production' : 'staging',
                });
                captureException(error);
              }}
            />
          </ImageSectionSt>

          <BenefitsSectionSt>
            <IconList icon="if-icon-check">
              <Heading5St fontWeight="medium">
                Jedes Auto mit Garantie
              </Heading5St>
              <Heading5St fontWeight="medium">Umfassend geprüft</Heading5St>
              <Heading5St fontWeight="medium">
                Von ausgewählten Händlern
              </Heading5St>
            </IconList>

            <Link
              to="/garantie"
              textDecoration="none"
              onClick={() =>
                trackAction('homepage_guarantee-link_button_click', {
                  category: 'home',
                  description:
                    'user clicks on the guarantee link on the homepage',
                })
              }
            >
              <GuaranteeInfoButtonSt>
                Über unser Qualitätsversprechen
              </GuaranteeInfoButtonSt>
            </Link>
          </BenefitsSectionSt>
        </ContentSt>
      </GuaranteeBenefitsSt>
    );
  }
}

GuaranteeBenefits.propTypes = {
  breakpoint: PropTypes.string,
};

GuaranteeBenefits.defaultProps = {
  breakpoint: 'sm',
};

/* istanbul ignore file */
import { browserHistory } from 'react-router';

import { getConfig } from 'app/shared/modules/FDL/tradein/redux/selectors';

import { apiCall } from '../../../../../actions';
import { ajaxCallWIthToken } from '../../../../../marketplace/accounts/actions';
import { getAccessToken } from '../../../../../marketplace/accounts/selectors';

import * as types from './types';

/**
 * Gets PDP's vehicle data from the page where trade-in was triggered
 *
 * @param {Object} vehicle
 */
export const addVehicleData = PDPvehicle => {
  return {
    type: types.TRADEIN_SET_VEHICLE_PDP,
    PDPvehicle,
  };
};

/**
 * Register the screens with the order
 *
 * @param {string[]} screens
 */
export const registerScreens = screens => ({
  type: types.TRADEIN_SET_SCREEN_NAMES,
  screens,
});

/**
 * Run saga actions for the specific screen
 *
 * @param {*} screen // Screen Index
 * @param {*} payload
 */
export const run = payload => ({
  type: types.TRADEIN_RUN,
  payload,
});

/**
 * Update step payload
 *
 * @param {Object} payload
 */
export const updateStep = payload => ({
  type: types.TRADEIN_UPDATE_STEP,
  payload,
});

/**
 * Resets tradein flow
 *
 * @param {*} screen // Screen Index
 * @param {*} payload
 */
export const reset = () => ({
  type: types.TRADEIN_RESET,
});

export const resetStep = () => ({
  type: types.TRADEIN_RESET_STEP,
});

/**
 * Reset and prepare the data for step make
 * @returns {function(*, *): Promise<void>}
 */
export const startFromAllMakes = () => async (dispatch, getState) => {
  const initialConfig = getConfig(getState());
  await dispatch(reset());
  await dispatch(
    run({
      config: initialConfig,
      request: { filters: [] },
    }),
  );
};

/**
 * Set the current vehicle
 */
export const setVehicle = vehicle => ({
  type: types.TRADEIN_SET_VEHICLE,
  vehicle,
});

/**
 *
 * @param {boolean} status // true => IsReady, false => isPending
 */
export const setStatus = status => ({
  type: types.TRADEIN_SET_STATUS,
  status,
});

/**
 * Increase the current screen value by 1
 */
export const nextScreen = (data = {}) => ({
  type: types.TRADEIN_SCREEN_NEXT,
  data,
});

/**
 * Redirect to success page as a last step in the valuation flow
 */
export const redirectToSuccessPage = () =>
  browserHistory.push('/tradein/success');

/**
 * Go to the specify screen
 */
export const changeScreen = (screenIndex, data = {}) => ({
  type: types.TRADEIN_SCREEN_CHANGE,
  screenIndex,
  data,
});

/**
 * Increase the step value by 1 and set the next step config with value
 *
 * @param {*} value
 */
export const nextStep = (data = {}) => ({
  type: types.TRADEIN_STEP_NEXT,
  data,
});

/**
 * Decrease the current step value by 1
 */
export const prevStep = () => ({
  type: types.TRADEIN_STEP_PREV,
});

export const searchVehicle = request => {
  const url = '/trade-in/search-vehicle/';
  const data = {
    method: 'POST',
    body: JSON.stringify(request),
  };
  return apiCall(url, data);
};

export const valuateVehicle = request => {
  const url = '/trade-in/valuate-vehicle/';
  const data = {
    method: 'POST',
    body: JSON.stringify(request),
  };
  return apiCall(url, data);
};

export const claimValuation = valuateVehicleId => {
  return (dispatch, getState) => {
    const url = `/trade-in/valuate-vehicle/${valuateVehicleId}/claim`;
    const state = getState();
    const isUserLoggedIn = getAccessToken(state);
    if (isUserLoggedIn) {
      return dispatch(ajaxCallWIthToken(url, { method: 'PUT' }, true));
    }
    return Promise.resolve();
  };
};

export const getValuateVehicleById = id => {
  const url = `/trade-in/valuate-vehicle/${id}`;
  const data = {
    method: 'GET',
  };
  return apiCall(url, data);
};

// get and store vehicle detail from the API
export const getValuateVehicleDetailById = id => {
  return dispatch => {
    const url = `/trade-in/valuate-vehicle/${id}`;
    const data = {
      method: 'GET',
    };
    return dispatch(apiCall(url, data)).then(result => {
      dispatch({
        type: types.TRADEIN_SET_VEHICLE_STATUS,
        data: result,
      });
      return result;
    });
  };
};

// set and stores vehicle details
export const setValuateVehicleDetail = data => ({
  type: types.TRADEIN_SET_VEHICLE_STATUS,
  data,
});

// defines if user survey should be displayed or not
export const setSurveyDisplayStatus = status => ({
  type: types.TRADEIN_USER_SURVEY,
  status,
});

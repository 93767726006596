import { call, takeEvery, put, select } from 'redux-saga/effects';

import * as Config from 'app/config';
import * as ActionTypes from 'app/marketplace/contactDealer/constants';
import { storeLeadForm } from 'app/marketplace/contactDealer/sagas';
import { apiCallWithRecaptchaSaga } from 'app/shared/modules/recaptcha/utils/apiCallWithRecaptcha';
import { RECAPTCHA_SITE_TOKEN_V2 } from 'app/shared/modules/recaptcha/constants';
import {
  CALLBACK_LEAD_ENDPOINT,
  LEAD_SERVICE_EXCEPTIONS,
} from 'app/marketplace/contactDealer/utils';
import { deviceSelector } from 'app/selectors';
import {
  trackCallbackSubmitFailRecaptcha,
  trackCallbackSubmitRecaptchaV2,
} from 'app/marketplace/contactDealer/Callback/tracking';
import { PREMIUM_POST_LEAD } from 'app/marketplace/premium/actions';

//  istanbul ignore next
export const enableSubmitCTABack = (vehicleId) => {
  const callbackLeadCTA = document.querySelector('#callbackLeadCTA');
  callbackLeadCTA.removeAttribute('disabled');
  trackCallbackSubmitRecaptchaV2(vehicleId);
};
//  istanbul ignore next
export const disableSubmitCTA = () => {
  const callbackLeadCTA = document.querySelector('#callbackLeadCTA');
  callbackLeadCTA.setAttribute('disabled', 'true');
};

/**
 * Renders the reCAPTCHA V2 ("I'm not a robot") checkbox
 */
export function* renderRecaptchaV2(vehicleId) {
  const deviceType = yield select(deviceSelector);
  const widgetId = yield call(
    [window.grecaptcha, 'render'],
    'recaptcha_challenge_callback',
    {
      sitekey: RECAPTCHA_SITE_TOKEN_V2,
      size: deviceType === 'desktop' ? 'normal' : 'compact',
      callback: () => enableSubmitCTABack(vehicleId),
      'expired-callback': disableSubmitCTA,
    },
  );
  yield put({
    type: ActionTypes.SET_RECAPTCHA_CHALLENGE_ID,
    payload: widgetId,
  });
}

/**
 * Posts data to backend in order to create a callback lead.
 * @param {object} payload: contains the form data and request body options
 */
export function* submitCallbackLead({ payload }) {
  const {
    email,
    eventId,
    firstName,
    lastName,
    marketingConsent,
    oneClickLeadsConsent,
    phoneNumber,
    vehicleListingId,
  } = payload;
  const form = {
    email,
    eventId,
    firstName,
    lastName,
    marketingConsent,
    oneClickLeadsConsent,
    phoneNumber,
    vehicleListingId,
  };
  yield call(storeLeadForm, form);

  try {
    const state = yield select();
    const { recaptchaWidgetId } = state.contactDealer;
    const isRecaptchaV2 = recaptchaWidgetId !== null;

    const response = yield call(
      apiCallWithRecaptchaSaga,
      `${Config.COMMON_API_URL}${CALLBACK_LEAD_ENDPOINT}`,
      'callback_lead_submit',
      {
        body: JSON.stringify(payload),
      },
      isRecaptchaV2,
    );

    const payloadCallback = {
      response,
      eventId: payload.eventId,
      phone: form.phoneNumber,
      oneClickLeadsConsent,
    };

    const premiumPayload = {
      form,
      leadId: response.leadId,
      phoneNumber: form.phoneNumber,
      firstName: form.firstName,
      lastName: form.lastName,
    };

    yield put({ type: PREMIUM_POST_LEAD, payload: premiumPayload });

    if (form?.oneClickLeadsConsent) {
      const oneClickLeads =
        state?.vehicle?.oneClickLeads?.automatedListings &&
        state?.vehicle?.oneClickLeads?.automatedListings.length > 0
          ? state?.vehicle?.oneClickLeads?.automatedListings
          : [];

      const oneClickLeadsPayload = {
        form,
        leadId: response.leadId,
        isSelectable: false,
        oneClickLeads,
        phone: form.phoneNumber,
        oneClickLeadsConsent,
      };

      yield put({
        type: ActionTypes.SUBMIT_CALLBACK_ONE_CLICK_LEAD_SUCCESS,
        payload: oneClickLeadsPayload,
      });
    }

    yield put({
      type: ActionTypes.SUBMIT_CALLBACK_SUCCESS,
      payload: payloadCallback,
    });
  } catch (error) {
    let apiError = error;
    if (error.message?.includes(LEAD_SERVICE_EXCEPTIONS.LOW_SCORE_RECAPTCHA)) {
      trackCallbackSubmitFailRecaptcha(vehicleListingId);
      yield call(renderRecaptchaV2, vehicleListingId);
      apiError = null;
    }
    yield put({
      type: ActionTypes.SUBMIT_CALLBACK_FAILURE,
      payload: { error: apiError },
    });
  }
}

/* Watches the action in order to perform the form submission */
export function* watchSubmitCallback() {
  yield takeEvery([ActionTypes.SUBMIT_CALLBACK_PENDING], submitCallbackLead);
}

import { Route } from 'react-router';

import { ConnectedPageTemplate } from 'app/shared/modules/pageTemplate/PageTemplate/ConnectedPageTemplate';

/* Homepage with AuthModal open routes */
const HOME_AUTH_MODAL_ROUTES = ['login', 'signup'];

export const LoginRoutes = (
  <Route
    name="accounts"
    component={({ routes, ...props }) => {
      const routeName = routes[routes.length - 1].name;
      const isAuthModalRoute = HOME_AUTH_MODAL_ROUTES.includes(routeName);
      return (
        <ConnectedPageTemplate
          target="accountPages"
          isHome={isAuthModalRoute}
          accountRoute={routeName}
          {...props}
        />
      );
    }}
  />
);

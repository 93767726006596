import styled, { css } from 'styled-components';
import { Box, Flex } from '@rebass/grid';

import { Heading5, Icon } from 'app/shared/ui';
import { media, zIndex, iconSizes } from 'app/shared/styles_js/variables';

export const ModalContainer = styled(Box)`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: ${zIndex.modal + 4};
  margin: 0;
  overflow: hidden;

  ${media.md(css`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: initial;
  `)}
`;

export const ModalFooter = styled(Box)`
  position: sticky;
  bottom: 0px;
  background: white;
  text-align: center;
  z-index: 1;
  height: 100px;
  padding-top: 20px;
`;

export const ModalHeader = styled(Box).attrs(
  ({ theme: { colors } }, ...otherProps) => ({
    width: 1,
    pr: 0,
    bg: colors.white,
    color: [colors.tarmacGrey],
    ...otherProps,
  }),
)`
  top: 0;
  z-index: 1;
  display: flex;
  justify-content: ${(props) =>
    props.hasTitleOrBackButton ? 'space-between' : 'flex-end'};
  align-items: center;
  height: 64px;
  font-weight: ${(props) => (props.showSuccessMessage ? 'normal' : 'bold')};
  padding-left: ${(props) => (props.showSuccessMessage ? '0' : '24px')};

  & > span:nth-of-type(2) {
    display: none;
  }

  & > span:first-of-type {
    max-width: 250px;
  }

  ${media.md(css`
    height: 64px;
    & > span:first-of-type {
      display: none;
    }
    & > span:nth-of-type(2) {
      display: flex;
    }
  `)};
  ${media.lg(css`
    height: 64px;
  `)};
  ${media.xl(css`
    height: 64px;
  `)};
`;

export const ModalCloseButton = styled(Box).attrs(
  ({ theme: { colors } }, ...otherProps) => ({
    as: 'button',
    type: 'button',
    bg: colors.actionBlue,
    ...otherProps,
  }),
)`
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  line-height: 1;
  padding: 0;
  height: 64px;
  width: 64px;
  cursor: pointer;

  &:hover {
    background-color: ${({ theme: { colors } }) => colors.micaBlue};
  }
`;

export const ModalCloseIcon = styled(Box).attrs(
  ({ theme: { colors }, ...otherProps }) => ({
    as: 'i',
    color: colors.white,
    ...otherProps,
  }),
)`
  font-size: ${iconSizes.xsmall}px;
`;

export const ModalContent = styled(Box)`
  position: relative;
  z-index: ${zIndex.modal + 1};
  background-color: #fff;
  padding: 0;
  height: 100%;

  ${media.md(css`
    overflow-y: auto;
    overflow-x: hidden;
    height: auto;
    max-height: 92vh;
  `)}

  ${({ maxHeight }) =>
    maxHeight &&
    media.md(css`
      max-height: ${maxHeight};
    `)}
`;

export const ModalBody = styled(Box).attrs((props) => ({
  p: [24, 48, 48, 64],
  pt: [0, 0, 0, 0],
  pb: [
    16,
    props?.visibleCampaign !== 'SHOW_ALL' &&
    !props?.isCheckoutPage &&
    props?.showCampaignInfoModal === true &&
    !props?.isMultipleOfferCampaign
      ? 118
      : 48,

    props?.visibleCampaign !== 'SHOW_ALL' &&
    !props?.isCheckoutPage &&
    props?.showCampaignInfoModal === true &&
    !props?.isMultipleOfferCampaign
      ? 118
      : 48,
    props?.visibleCampaign !== 'SHOW_ALL' &&
    !props?.isCheckoutPage &&
    props?.showCampaignInfoModal === true &&
    !props?.isMultipleOfferCampaign
      ? 118
      : 64,
  ],
  ...props,
}))`
  height: ${(props) =>
    props?.visibleCampaign !== 'SHOW_ALL' &&
    !props?.isCheckoutPage &&
    props?.showCampaignInfoModal === true &&
    !props?.isMultipleOfferCampaign
      ? 'calc(100% - 167px)'
      : 'calc(100% - 64px)'};
  -webkit-overflow-scrolling: touch;
  overflow-y: auto;
  overflow-x: hidden;
  color: ${({ theme: { colors } }) => colors.tarmacGrey};

  ${media.md(css`
    overflow-y: hidden;
    height: auto;
  `)}
`;

export const ModalHeadline = styled(Heading5)`
  display: ${({ showHeadlineOnMobile }) =>
    showHeadlineOnMobile ? 'block' : 'none'};

  ${media.md(css`
    display: block;
  `)}
`;

export const BackButtonWrapperMobile = styled(Box)`
  display: flex;

  ${media.md(css`
    display: none;
  `)}
`;

export const ModalSuccessNotificationSt = styled(Flex).attrs(
  /* istanbul ignore next */ ({ theme: { colors } }) => ({
    flexDirection: 'row',
    pl: [0, 3, 5],
    backgroundColor: colors.mintSuperLight,
  }),
)`
  width: fit-content;
  div {
    color: ${({ theme: { colors } }) => colors.brandPrimaryDark};
  }

  @media (max-width: 767px) {
    display: flex;
    width: auto;
    justify-content: center;
    .noticationText {
      width: 70%;
    }
  }
`;

export const ModalSuccessIconSt = styled(Icon).attrs(
  /* istanbul ignore next */ ({ theme: { colors } }) => ({
    fontSize: 'small',
    mr: 8,
    mt: '4px',
    color: colors.brandPrimaryDark,
  }),
)``;

import styled, { css } from 'styled-components';
import { Flex } from '@rebass/grid';

import {
  color as colorVars,
  media,
  zIndex,
} from 'app/shared/styles_js/variables';

export const ModalHeaderSt = styled(Flex)`
  #modal-header-title {
    display: inline-block;
  }
  ${media.md(css`
    #modal-header-title {
      display: none;
    }
  `)}
`;

export const ModalSt = styled.div`
  z-index: ${zIndex.modal};
  display: ${(props) => (props.isOpen ? 'flex' : 'none')};
  width: 100%;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  ${(props) =>
    props.isOpen &&
    css`
      flex-direction: column;
      align-items: center;
      justify-content: center;
    `};
`;

export const ModalContentSt = styled.div`
  position: relative;
  z-index: ${zIndex.modal + 1};
  background-color: #fff;
  overflow-x: hidden;
  overflow-y: auto;
  ${({ narrow }) =>
    !narrow &&
    css`
      padding: 24px;
    `}
  height: 100vh;

  ${media.md(css`
    ${({ narrow }) =>
      !narrow &&
      css`
        padding: 28px;
      `};
    ${(props) =>
      css`
        height: auto;
        width: ${props.sizeMd};
      `};
  `)};

  ${media.lg(
    ({ narrow }) =>
      !narrow &&
      css`
        padding: 36px;
      `,
  )}

  ${media.xl(css`
    ${(props) =>
      css`
        width: ${props.sizeXl};
      `};
    ${({ narrow }) =>
      !narrow &&
      css`
        padding-right: 56px;
        padding-left: 56px;
      `};
  `)};
`;

export const LargeModalContentSt = styled(ModalContentSt)`
  ${media.md(css`
    min-width: 732px;
    max-width: 732px;
    height: auto;
    padding: 0;
  `)};
`;

export const IconCloseSt = styled.i`
  cursor: pointer;
  font-size: ${({ iconSize }) => iconSize}px;
  position: absolute;
  top: 24px;
  right: 24px;
  color: ${({ color }) => colorVars[color] || color};
`;

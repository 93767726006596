import { getEnvironmentValue } from './shared/utils/utils';

const isClient = typeof CLIENT !== 'undefined' && CLIENT;

export const SSR_TIMEOUT = 10; // seconds
export const PAGINATION_SIZE = 15;
export const MOBILE_CARTILE_PAGINATION_SIZE = 24;
export const LOCATION_PAGINATION_SIZE = 1;
export const CHAT_DELAY_TIMEOUT = 60000;
export const SESSION_TIME = 1000 * 60 * 30; // 30 minutes session time, can be configured to come from env variables
export const CLP_PAGE_URL = '/gebrauchtwagen';
export const RECAPTCHA_SITE_TOKEN = getEnvironmentValue(
  'RECAPTCHA_SITE_TOKEN',
  '6LdUZboZAAAAAFFLrS4JOqWrK99E81dN2QAMcNtM',
);
export const CDN_URL = getEnvironmentValue('CDN_URL', 'https://cdn.hey.car');
export const DATADOG_HOST = getEnvironmentValue('DATADOG_HOST', null);
export const APP_NAME = getEnvironmentValue('APP_NAME', 'web-app');

export const LOG_LEVEL = getEnvironmentValue('LOG_LEVEL', 'error');
export const SENTRY_VERSION = getEnvironmentValue('SENTRY_VERSION', '');
export const SENTRY_URL = getEnvironmentValue('SENTRY_URL', '');
export const DEFAULT_LOCALE = getEnvironmentValue('DEFAULT_LOCALE', 'de');
export const SEGMENT_WRITE_KEY = getEnvironmentValue(
  'SEGMENT_WRITE_KEY',
  'e1qNrDsAvRrhX5On398tRriHwGyAm378',
);
export const BASE_URL = getEnvironmentValue(
  'BASE_URL',
  'https://staging.hey.car',
);
export const IMAGE_SERVICE_URL = getEnvironmentValue(
  'IMAGE_SERVICE_URL',
  'https://img.hey.car',
);
export const AB_TEST_GUID = getEnvironmentValue(
  'AB_TEST_GUID',
  'b16c4c90bda2bf28799c635aba7d323a',
);

export const KEYCLOAK_URL = getEnvironmentValue(
  'KEYCLOAK_URL',
  'https://iam.de.staging.group-mobility-trader.de',
);
/**
 *  The value for API_URL variable for all environments and variants (Client & Server)
 *  |       -        |     |           CLIENT            |        SERVER               |              SOURCE                                          |
 *  | -------------- | --- | :-------------------------- | :-------------------------: | -----------------------------------------------------------: |
 *  | localhost      |     | https://api.staging.hey.car | https://api.staging.hey.car | local env                                                    |
 *  | PR Environment |     | https://api.staging.hey.car | https://api.staging.hey.car | helm (Group Staging cluster)                                 |
 *  | Staging        |     | https://api.staging.hey.car | http://search-service       | KOPS config (Staging cluster) / helm (Group Staging cluster) |
 *  | Production     |     | https://api.hey.car         | http://search-service       | KOPS config (Production cluster) / helm (Group Prod cluster) |
 *
 *  If there is a requirement to test the feature against staging api in the PR environment,
 *  then please uncomment the code below followed by commenting the next one. This will override the configuration
 *  coming from gh-webhook.
 *  You will also need to change the test file for this file (comment and uncomment the lines there).
 */

// export const DE_API_URL = isClient
//   ? 'https://api.staging.hey.car'
//   : 'http://search-service';

// NOTE: API_URL is not namespaced here with DE_ in order to use the API_URL environment variable exposed at the server level
export const DE_API_URL =
  isClient || BASE_URL.indexOf('localhost') !== -1
    ? getEnvironmentValue('API_URL', 'https://api.staging.hey.car')
    : getEnvironmentValue('SERVER_API_URL', 'http://search-service');

export const COMMON_API_URL = getEnvironmentValue(
  'COMMON_API_URL',
  'https://api.common.staging.group-mobility-trader.de',
);

export const CHECKOUT_API_URL = getEnvironmentValue(
  'CHECKOUT_API_URL',
  'https://api.de.staging.group-mobility-trader.de',
);

export const SEO_API_URL = getEnvironmentValue(
  'SEO_API_URL',
  'https://api.de.staging.group-mobility-trader.de',
);

export const WEB_APP_URL = getEnvironmentValue(
  'WEB_APP_URL',
  'http://localhost:3000',
);
export const AUTH_USER = getEnvironmentValue('AUTH_USER', null);
export const AUTH_PASS = getEnvironmentValue('AUTH_PASS', null);

/*
 * Contentful variables
 */
export const CONTENTFUL_HEYCAR = getEnvironmentValue(
  'CF_SPACE_DE',
  'car0nghph155',
);
export const CONTENTFUL_MAGAZINE = getEnvironmentValue(
  'CF_SPACE_MAGAZINE',
  'x7mjp9itmnlu',
);
export const CONTENTFUL_LOCATION_PAGES = getEnvironmentValue(
  'CF_SPACE_LOCATION_PAGES',
  'mir1bbxgf13k',
);
export const CONTENTFUL_SEO_PAGES = getEnvironmentValue(
  'CF_SPACE_SEO_PAGES',
  'snrrn07dvb31',
);
export const CONTENTFUL_ENVIRONMENT = getEnvironmentValue(
  'CONTENTFUL_ENVIRONMENT',
  'master',
);

export const KICKBOX_API_URL = getEnvironmentValue(
  'KICKBOX_API_URL',
  'https://api.kickbox.com/v2/verify',
);

export const KICKBOX_API_KEY = getEnvironmentValue(
  'KICKBOX_API_TOKEN',
  'live_d93c1652e1a66152952fff2153fa2716040de30d03f0666040b72ed51050035d',
);

export const SERVICE_WORKERS_ENABLED = getEnvironmentValue(
  'SERVICE_WORKERS_ENABLED',
  false,
);

// Define list of pages to render the Slide-in banner on mobile and desktop
export const PAGES_WITH_SLIDE_IN_BANNER_MOBILE = getEnvironmentValue(
  'PAGES_WITH_SLIDE_IN_BANNER_MOBILE',
  '/vehicle,/gebrauchtwagen',
);
export const PAGES_WITH_SLIDE_IN_BANNER_DESKTOP = getEnvironmentValue(
  'PAGES_WITH_SLIDE_IN_BANNER_DESKTOP',
  '/vehicle,/gebrauchtwagen',
);

export const DELAY_SLIDE_IN_BANNER_MOBILE = getEnvironmentValue(
  'DELAY_SLIDE_IN_BANNER_MOBILE',
  15000,
);
// Define list of pages to render the User Survey banner on mobile and desktop
export const PAGES_WITH_USER_SURVEY_BANNER_MOBILE = getEnvironmentValue(
  'PAGES_WITH_USER_SURVEY_BANNER_MOBILE',
  '/vehicle,/gebrauchtwagen',
);
export const PAGES_WITH_USER_SURVEY_BANNER_DESKTOP = getEnvironmentValue(
  'PAGES_WITH_USER_SURVEY_BANNER_DESKTOP',
  '/vehicle,/gebrauchtwagen',
);

// Define list of pages to render the Sweepstakes banner on desktop only
export const PAGES_WITH_SWEEPSTAKES_BANNER_DESKTOP = getEnvironmentValue(
  'PAGES_WITH_SWEEPSTAKES_BANNER_DESKTOP',
  '/vehicle,/gebrauchtwagen',
);

export const APPSFLYER_WEB_KEY = getEnvironmentValue(
  'APPSFLYER_WEB_KEY',
  '180f2df8-7802-4ef8-a1ae-756cb7c56a02',
);

const COMMERCE_PUBLIC_FILES_BUCKET = getEnvironmentValue(
  "COMMERCE_PUBLIC_FILES_S3BUCKET",
  "de-prod-commerce-public-files"
)

const AWS_REGION = getEnvironmentValue(
  "AWS_REGION",
  "eu-central-1"
)

export const COMMERCE_PUBLIC_FILES_URI = `https://${COMMERCE_PUBLIC_FILES_BUCKET}.s3.${AWS_REGION}.amazonaws.com`

/**
 * List of environmental variables that should also be available at the client side
 */
export const variablesAvailableOnClientSide = {
  API_URL: DE_API_URL,
  BASE_URL,
  CDN_URL,
  COMMON_API_URL,
  CHECKOUT_API_URL,
  SEO_API_URL,
  CONTENTFUL_ENVIRONMENT,
  DE_API_URL,
  DELAY_SLIDE_IN_BANNER_MOBILE,
  IMAGE_SERVICE_URL,
  KEYCLOAK_URL,
  PAGES_WITH_SLIDE_IN_BANNER_DESKTOP,
  PAGES_WITH_SLIDE_IN_BANNER_MOBILE,
  PAGES_WITH_SWEEPSTAKES_BANNER_DESKTOP,
  PAGES_WITH_USER_SURVEY_BANNER_DESKTOP,
  PAGES_WITH_USER_SURVEY_BANNER_MOBILE,
  SENTRY_VERSION,
  SENTRY_URL,
};

/**
 * Define script tags for the environmental variables that should be available on
 * client side
 * @param {Object<string>} variables The list of key, values for the variables
 * @returns {string}
 */
export const defineEnvVarScripts = (variables) => {
  return Object.keys(variables)
    .map(
      (key) =>
        `<script type="application/javascript">window.${key}=${JSON.stringify(
          variables[key],
        )}</script>`,
    )
    .join('');
};

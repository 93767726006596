import { memo } from 'react';
import PropTypes from 'prop-types';
// polyfill for IE
import 'intersection-observer';
import { useInView } from 'react-intersection-observer';

import {
  CartileImageSt,
  CartileImageWrapperSt,
} from 'app/shared/modules/Cartile/CartileUIComponents/CartileImages/CartileImage/CartileImage.css';
import { CartileImageLoading } from 'app/shared/modules/Cartile/CartileUIComponents/CartileImages/CartileImageLoading/CartileImageLoading';

const CartileImageCmp = ({ alt, isLazy, image, fetchpriority }) => {
  const [ref, inView] = useInView({
    triggerOnce: true,
    rootMargin: '200px',
  });
  const src = !isLazy || (isLazy && inView) ? image : '';
  return (
    <CartileImageWrapperSt ref={ref}>
      {src ? (
        <CartileImageSt
          src={src}
          alt={alt}
          width="288px"
          height="194px"
          fetchpriority={fetchpriority}
        />
      ) : (
        <CartileImageLoading />
      )}
    </CartileImageWrapperSt>
  );
};

CartileImageCmp.propTypes = {
  isLazy: PropTypes.bool,
  alt: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
  fetchpriority: PropTypes.string,
};

CartileImageCmp.defaultProps = {
  isLazy: true,
  fetchpriority: 'auto',
};

export const CartileImage = memo(CartileImageCmp);

import React from 'react';
import PropTypes from 'prop-types';

import { formatNumber } from 'app/shared/utils/formatNumber';

import { linear, geometric, logarithmic } from './algorithms';
import {
  ValueSt,
  ValuesSt,
  SliderSt,
  RheostatSt,
  SeparatorSt,
  HistogramSt,
  SliderBodySt,
  InputBoxWrapperSt,
  InputBoxMinSt,
  InputBoxMaxSt,
  InputWrapperSt,
  HistogramItemSt,
  HistogramInnerSt,
} from './SliderWithInput.css';

export class SliderWithInput extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      values: props.values,
      inputMin: props.values[0],
      inputMax: props.values[1],
    };
  }

  componentDidMount() {
    const [minVal, maxVal] = this.state.values;
    this.setState({ inputMin: minVal, inputMax: maxVal });
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    this.setState({
      values: newProps.values,
      inputMin: newProps.values[0],
      inputMax: newProps.values[1],
    });
  }

  updateValue = ({ values }) => {
    document.activeElement.blur();
    this.setState({ values, inputMin: values[0], inputMax: values[1] });
    this.props.onDrag(values);
  };

  onChange = (sliderData) => {
    this.props.onChange(sliderData);
  };

  handleInputChange = (e) => {
    const { name, value } = e.currentTarget;
    const trimVal = this.trimValue(value);
    this.setState({ [name]: Number(trimVal) });
  };

  handleOnBlur = (e) => {
    const { min, max } = this.props;
    const { name } = e.currentTarget;
    const value = Number(this.trimValue(e.currentTarget.value));

    const newValues = [...this.state.values];
    if (name === 'inputMin') {
      if (value >= newValues[1]) {
        const adjustedMinValue = newValues[1] - 500;
        newValues[0] = adjustedMinValue < min ? min : adjustedMinValue;
      } else if (value < min) {
        newValues[0] = min;
      } else newValues[0] = value;
      this.setState({ inputMin: newValues[0] });
    } else {
      if (value <= newValues[0]) {
        const adjustedMaxValue = newValues[0] + 500;
        newValues[1] = adjustedMaxValue > max ? max : adjustedMaxValue;
      } else if (value > max) {
        newValues[1] = max;
      } else newValues[1] = value;
      this.setState({ inputMax: newValues[1] });
    }
    this.setState({ values: newValues });
    this.props.onBlur([...newValues]);
  };

  getAlgorithm = () => {
    const { algorithm, snapPoints, granular } = this.props;

    if (algorithm === 'geometric') {
      return geometric(granular ? null : snapPoints);
    }
    if (algorithm === 'logarithmic') {
      return logarithmic(granular ? null : snapPoints);
    }

    return linear(granular ? null : snapPoints);
  };

  convertHistogramValuesToRelative = (values) => {
    const maxValue = values.slice().sort((a, b) => b - a)[0];

    return values.map((value) => {
      return value / maxValue;
    });
  };

  getHistogramItems = (values) => {
    const relativeValues = this.convertHistogramValuesToRelative(values);

    return (
      <HistogramInnerSt itemCount={relativeValues.length}>
        {relativeValues.map((relativeValue, index) => {
          return (
            <HistogramItemSt
              key={index}
              height={relativeValue}
              itemCount={relativeValues.length}
            />
          );
        })}
      </HistogramInnerSt>
    );
  };

  trimValue = (val) =>
    typeof val === 'string' ? val.replace(/\.|,/g, '') : val;

  render() {
    const {
      dataQa,
      prefix,
      suffix,
      onFocus,
      disabled,
      showValue,
      className,
      delimiter,
      formatValue,
      sliderWidth,
      inputPosition,
      onSliderDragEnd,
      histogramValues,
    } = this.props;

    const { values, inputMin, inputMax } = this.state;

    const uniqueValues = [...new Set(values)];
    const algorithm = this.getAlgorithm();

    if (delimiter) {
      uniqueValues.splice(1, 0, { isDelimiter: true, delimiter });
    }
    const inputMinLocal = Number.isFinite(inputMin)
      ? formatNumber(inputMin)
      : inputMin;
    const inputMaxLocal = Number.isFinite(inputMax)
      ? formatNumber(inputMax)
      : inputMax;

    const hasOnlyOneInput = values.length < 2;

    return (
      <SliderSt
        data-qa={dataQa}
        className={className}
        sliderWidth={sliderWidth}
        hasOnlyOneInput={hasOnlyOneInput}
        inputPosition={inputPosition}
      >
        {showValue === true && (
          <ValuesSt
            isSingleValue={uniqueValues.length === 1} // ATM this prop has not effect on the styles (legacy prop)
            histogramActive={histogramValues.length}
          >
            {uniqueValues.length > 0 &&
              uniqueValues.map((value, i) => {
                const { isDelimiter, delimiter } = value;

                if (isDelimiter) {
                  return (
                    <ValueSt key="delimiter" isDelimiter>
                      {delimiter}
                    </ValueSt>
                  );
                }

                return <ValueSt key={i}>{formatValue(value)}</ValueSt>;
              })}
          </ValuesSt>
        )}
        <SliderBodySt>
          {histogramValues.length > 0 && (
            <HistogramSt itemCount={histogramValues.length}>
              {this.getHistogramItems(histogramValues)}
            </HistogramSt>
          )}
          <RheostatSt
            {...this.props}
            algorithm={algorithm}
            onSliderDragEnd={onSliderDragEnd}
            onValuesUpdated={this.updateValue}
            onChange={this.onChange}
            values={values}
            disabled={disabled}
          />
        </SliderBodySt>
        <InputWrapperSt
          hasOnlyOneInput={hasOnlyOneInput}
          inputPosition={inputPosition}
        >
          <InputBoxWrapperSt hasOnlyOneInput={hasOnlyOneInput}>
            <InputBoxMinSt
              size="small"
              compact
              hasValue
              inputMode="numeric"
              name="inputMin"
              prefix={prefix}
              suffix={suffix}
              value={inputMinLocal}
              blurOnEnter
              onFocus={onFocus}
              onChange={this.handleInputChange}
              onBlur={this.handleOnBlur}
              hasOnlyOneInput={hasOnlyOneInput}
              inputPosition={inputPosition}
            />
          </InputBoxWrapperSt>
          {!hasOnlyOneInput && (
            <>
              <SeparatorSt>bis</SeparatorSt>
              <InputBoxWrapperSt>
                <InputBoxMaxSt
                  size="small"
                  compact
                  hasValue
                  inputMode="numeric"
                  name="inputMax"
                  prefix={prefix}
                  suffix={suffix}
                  value={inputMaxLocal}
                  blurOnEnter
                  onFocus={onFocus}
                  onChange={this.handleInputChange}
                  onBlur={this.handleOnBlur}
                />
              </InputBoxWrapperSt>
            </>
          )}
        </InputWrapperSt>
      </SliderSt>
    );
  }
}

SliderWithInput.propTypes = {
  algorithm: PropTypes.oneOf(['linear', 'logarithmic', 'geometric']),
  delimiter: PropTypes.any,
  max: PropTypes.any.isRequired,
  min: PropTypes.any.isRequired,
  values: PropTypes.array,
  showValue: PropTypes.bool,
  formatValue: PropTypes.func,
  onChange: PropTypes.func,
  onSliderDragEnd: PropTypes.func,
  onDrag: PropTypes.func,
  snapPoints: PropTypes.arrayOf(PropTypes.number),
  snap: PropTypes.bool,
  disabled: PropTypes.bool,
  dataQa: PropTypes.string,
  granular: PropTypes.bool,
  histogramValues: PropTypes.arrayOf(PropTypes.number),
  className: PropTypes.string,
  prefix: PropTypes.any,
  suffix: PropTypes.any,
  sliderWidth: PropTypes.number,
  inputsWidth: PropTypes.number,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
  inputPosition: PropTypes.oneOf(['right', 'down']),
};

SliderWithInput.defaultProps = {
  algorithm: null,
  delimiter: null,
  values: [],
  showValue: false,
  formatValue: (value) => value,
  disabled: false,
  onChange: () => {},
  onSliderDragEnd: () => {},
  onDrag: () => {},
  dataQa: '',
  granular: false,
  histogramValues: [],
  prefix: null,
  suffix: null,
  sliderWidth: null,
  inputsWidth: null,
  onBlur: () => {},
  onFocus: () => {},
  inputPosition: 'down',
};

import theme from 'styled-theming';

import { variables } from '../variables';

const { color, colorV2 } = variables;

export const buttons = {
  primary: {
    default: color.brandPrimary,
    hover: color.brandPrimaryLight,
    active: color.brandPrimaryDark,
    disabled: color.brandPrimaryLighter,
    color: 'white',
    hoverColor: 'white',
    activeColor: 'white',
    borderColor: 'transparent',
    disabledColor: '',
    disabledBorderColor: '',
  },
  secondary: {
    default: color.brandSecondaryLight,
    hover: color.brandSecondaryLighter,
    active: color.brandSecondary,
    disabled: color.grayLight,
    color: 'white',
    hoverColor: 'white',
    activeColor: 'white',
    borderColor: 'transparent',
    disabledColor: 'white',
  },
  tertiary: {
    default: 'white',
    hover: 'white',
    active: 'white',
    disabled: color.grayLight,
    color: color.brandSecondary,
    hoverColor: color.brandPrimary,
    activeColor: color.brandSecondary,
    borderColor: color.gray,
    disabledColor: 'white',
    disabledBorderColor: color.grayLight,
  },
};

import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import {
  toggleFavourite,
  getFavouriteVehicleIds,
} from 'app/marketplace/favourites/actions';
import {
  getFavoriteIds,
  showFavLoginPopupSelector,
} from 'app/marketplace/favourites/selectors';
import {
  budgetRangeSwitchFilterSelector,
  performanceRangeValueFilterSelector,
} from 'app/marketplace/filter/selectors';
import {
  getVehicleSelector,
  lastSearchUrlSelector,
  getVehicleLoadingSelector,
} from 'app/marketplace/search/selectors';
import { getLastSearch } from 'app/marketplace/search/actions';
import { LastSearch } from 'app/marketplace/home/LastSearch/LastSearch';
import { breakpointSelector } from 'app/shared/modules/globalEvents/selectors';

const mapStateToProps = createStructuredSelector({
  favouriteIds: getFavoriteIds,
  vehicles: getVehicleSelector,
  breakpoint: breakpointSelector,
  isLoading: getVehicleLoadingSelector,
  lastSearchUrl: lastSearchUrlSelector,
  showFavLoginPopup: showFavLoginPopupSelector,
  budgetRangeValue: budgetRangeSwitchFilterSelector,
  performanceUnit: performanceRangeValueFilterSelector,
});

export const ConnectedLastSearch = connect(mapStateToProps, {
  getLastSearch,
  toggleFavourite,
  getFavouriteVehicleIds,
})(LastSearch);

import styled, { css } from 'styled-components';
import { Link, Text } from 'app/shared/ui';

import { color, media } from 'app/shared/styles_js/variables';
import { ArticleContainerSt } from 'app/marketplace/contentful/spaces/magazine/entries/SharedComponentsSt/SharedComponentsSt.css';

const LINE_CLAMP = 2;
export const MIN_IMAGE_HEIGHT = '120px';

export const ArticleTitleSt = styled(Text).attrs((props) => ({
  as: 'h2',
  fontSize: [20, 20, 24],
  lineHeight: [1.6, 1.6, 1.67],
  ...props,
}))`
  position: relative;
  display: block;
  overflow: hidden;

  @-moz-document url-prefix() {
    &::before {
      position: absolute;
      right: 0;
      bottom: 0;
      width: 1.8rem;
      padding-left: 0.2rem;
      margin-left: 0.5rem;
      background: white;
      content: '...';
    }

    &::after {
      position: absolute;
      z-index: 1;
      width: 100%;
      height: 50px;
      content: '';
      background: white;
    }
  }
  ${media.sm(css`
    max-height: ${(props) =>
      props.lineHeight[0] * props.fontSize[0] * LINE_CLAMP}px;
  `)};
  ${media.lg(css`
    max-height: ${(props) =>
      props.lineHeight[2] * props.fontSize[2] * LINE_CLAMP}px;
  `)};
  /* stylelint-disable-next-line */
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: ${LINE_CLAMP};
  text-overflow: ellipsis;
`;

export const TopImageSt = styled.img`
  display: block;
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  background: ${color.grayLight};
  cursor: pointer;

  & :hover {
    opacity: 0.8;
  }
`;

export const ArticlePreviewContentSt = styled(ArticleContainerSt)`
  padding: ${(props) => (props.noPadding ? 0 : '0 24px')};
  ${media.md(css`
    width: 100%;
    padding: 0;
  `)};
`;
export const LinkSt = styled(Link)`
  padding-top: 8px;
  font-size: 16px;
  line-height: 2;
  transition: color 200ms ease-in-out;
`;

export const ArticlePreviewTextSt = styled.p`
  display: none;
  margin-bottom: ${(props) => (props.noDescription ? '0' : 'unset')};

  ${media.md(css`
    display: block;
  `)};
`;

export const ArticleWrapperSt = styled.div`
  margin-bottom: 40px;
`;

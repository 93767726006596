export const getSoftCheckDataFromFDL = (state) => {
  return {
    installment: state?.fdlCalculator?.result.price.value || null,
    termOfContract: state?.fdlCalculator?.searchFilters.duration || null,
  };
};

export const getCaptiveBank = (state) =>
  state.vehicle.vehicle?.financingPrecalc?.searchFilters?.captiveId;

export const getCalculations = (state) => state.fdlCalculator.calculations;

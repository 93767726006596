import { css } from 'styled-components';

import { TResponsiveCss, TBreakpoint } from './index.types';
import { breakpoints } from './breakpoints';

const breakpointKeys = Object.keys(breakpoints) as TBreakpoint[];

export const media = breakpointKeys.reduce((accumulator, label) => {
  accumulator[label] = (styles, isIE = false) =>
    css`
      ${isIE
        ? css`
            @media all and (min-width: ${breakpoints[
                label
              ]}px) and (-ms-high-contrast: none) {
              ${styles};
            }
          `
        : css`
            @media (min-width: ${breakpoints[label]}px) {
              ${styles};
            }
          `};
    `;

  return accumulator;
}, {} as Partial<TResponsiveCss>) as TResponsiveCss;

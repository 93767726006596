/* istanbul ignore file */
import { Flex } from '@rebass/grid';
import styled from 'styled-components';

import { IInputWrapperProps } from './InputSearch.types';

export const InputWrapperSt = styled(Flex)`
  position: relative;

  .search-icon-button {
    position: relative;
    top: -11px;
    right: -11px;
    width: 49px;
    min-height: 49px !important;
    border-radius: 4px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  & > div {
    width: 100%;
  }

  input:focus + label {
    display: none;
    transform: none;
  }

  ${({ isEmpty }: IInputWrapperProps) =>
    !isEmpty &&
    `input + label {
    display: none;
    transform: none;
  }`}

  input,
  fieldset {
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
  }

  legend {
    display: none;
  }
`;

import { call, put, select } from 'redux-saga/effects';

import * as Config from 'app/config';
import { checkForError } from 'app/shared/utils/checkForError';
import * as ActionTypes from 'app/marketplace/contactDealer/constants';

import { RECAPTCHA_SITE_TOKEN_V3 } from '../constants';

import { checkRecaptchaReady } from './checkRecaptchaReady';

/* istanbul ignore next */
export const trackingGUIDSelector = (state) => state?.tracking?.guid;

export const fetchForm = (
  trackingGUID,
  apiUrl,
  recaptchaToken,
  extraOptions = {},
) => {
  const options = {
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'X-Tracking-Guid': trackingGUID,
      'Accept-Language': 'de',
      'X-Heycar-Tenant': 'DE',
      'Recaptcha-Token': recaptchaToken,
    },
    method: 'POST',
    ...extraOptions,
  };

  if (CLIENT) {
    options.mode = 'cors';
  }

  return fetch(apiUrl, options)
    .then(checkForError)
    .then((response) => response.text())
    .then((response) => {
      return response ? JSON.parse(response) : {};
    });
};

/**
 * Fetches the data to the backend using reCAPTCHA solution.
 * @param {string} url: specific action's url to be used
 * @param {string} action: specific action being performed - for report purpose
 * @param {object} options: custom request options
 */
export const apiCallWithRecaptcha = (url, action, options = {}) => async (
  dispatch,
  getState,
) => {
  await checkRecaptchaReady();
  const recaptchaToken = await window.grecaptcha.execute(
    RECAPTCHA_SITE_TOKEN_V3,
    {
      action,
    },
  );

  const trackingGUID = trackingGUIDSelector(getState());
  return fetchForm(trackingGUID, url, recaptchaToken, options);
};

/**
 * Fetches the data to the backend using reCAPTCHA solution [sagas version].
 * @param {string} url: specific action's url to be used
 * @param {string} action: specific action being performed - for report purpose
 * @param {object} options: custom request options
 * @param {boolean} isVersion2: is reCAPTCHA V2?
 */
export function* apiCallWithRecaptchaSaga(
  url,
  action,
  options = {},
  isVersion2 = false,
) {
  const trackingGUID = yield select(trackingGUIDSelector);
  let recaptchaToken = '';

  if (isVersion2) {
    recaptchaToken = yield call([window.grecaptcha, 'getResponse']);
    //  Resetting reCAPTCHA V2 right after usage
    yield put({
      type: ActionTypes.SET_RECAPTCHA_CHALLENGE_ID,
      payload: null,
    });
  } else {
    yield call(checkRecaptchaReady);
    recaptchaToken = yield call(
      [window.grecaptcha, 'execute'],
      RECAPTCHA_SITE_TOKEN_V3,
      { action },
    );
  }

  return yield call(fetchForm, trackingGUID, url, recaptchaToken, options);
}

export const GET_VEHICLE_REQUEST = 'GET_VEHICLE_REQUEST';
export const GET_VEHICLE_SUCCESS = 'GET_VEHICLE_SUCCESS';
export const GET_VEHICLE_FAILURE = 'GET_VEHICLE_FAILURE';
export const RESET_VEHICLE = 'RESET_VEHICLE';
export const SET_SEARCH_URL = 'VEHICLE/SET_SEARCH_URL';

export const MORE_LIKE_THIS_PENDING = 'MORE_LIKE_THIS_PENDING';
export const GET_MORE_LIKE_THIS_VEHICLES = 'GET_MORE_LIKE_THIS_VEHICLES';

export const GET_ADDITIONAL_LISTINGS_REQUEST =
  'GET_ADDITIONAL_LISTINGS_REQUEST';
export const GET_ADDITIONAL_LISTINGS_SUCCESS =
  'GET_ADDITIONAL_LISTINGS_SUCCESS';
export const GET_ADDITIONAL_LISTINGS_FAILURE =
  'GET_ADDITIONAL_LISTINGS_FAILURE';

export const GET_VEHICLE_RESERVATION_STATUS_REQUEST =
  'GET_VEHICLE_RESERVATION_STATUS_REQUEST';
export const GET_VEHICLE_RESERVATION_STATUS_SUCCESS =
  'GET_VEHICLE_RESERVATION_STATUS_SUCCESS';
export const GET_VEHICLE_RESERVATION_STATUS_FAILURE =
  'GET_VEHICLE_RESERVATION_STATUS_FAILURE';

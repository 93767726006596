import { breakpoints, grid } from 'app/shared/styles_js/variables';

/**
 * @description
 * utilities
 */
export const isNumeric = num =>
  typeof num !== 'symbol' &&
  typeof num !== 'object' &&
  !Number.isNaN(num) &&
  !Number.isNaN(parseFloat(num));

export const isColVal = num =>
  isNumeric(num) && num >= 0 && num <= grid.columns;

/**
 * @param {Number} num
 */
export const colPercent = num => `${(num / grid.columns) * 100}%`;

/**
 * @param {'sm'|'md'|'lg'|'xl'[]} fluidBreakpoints
 */
export const generateContainerCss = (fluidBreakpoints = []) => {
  let cssString = '';
  Object.keys(breakpoints).forEach(bkp => {
    let maxWidth;
    const fluidMaxWidth = '100%';

    if (bkp === 'sm') {
      maxWidth = `calc(100% - ${grid.margin.sm * 2}px)`;
      /* the overflow-x is here we might need to convert
      /* to a bootstrap-style container with horizontal padding */
      if (fluidBreakpoints.includes(bkp)) {
        cssString += `
          max-width: ${fluidMaxWidth};
          overflow-x: hidden;
        `;
      } else {
        cssString += `max-width: ${maxWidth};`;
      }
    } else {
      maxWidth = `${breakpoints[bkp] - grid.margin[bkp] * 2}px`;
      const mq = `@media all and (min-width: ${breakpoints[bkp]}px)`;
      if (fluidBreakpoints.includes(bkp)) {
        cssString += `${mq} {
          max-width: ${fluidMaxWidth};
          overflow-x: hidden;
        }`;
      } else {
        cssString += `${mq} {
          max-width: ${maxWidth};
          overflow-x: visible;
        }`;
      }
    }
  });

  return cssString;
};

import styled, { css } from 'styled-components';
import { Flex } from '@rebass/grid';

import { media } from 'app/shared/styles_js/variables';

export const ListingsContainerSt = styled(Flex)`
  margin-top: 48px;
  margin-right: 24px;
  margin-left: 24px;

  ${media.md(css`
    margin-top: 72px;
    margin-right: -16px;
    margin-left: -16px;
  `)};
`;

import styled, { css } from 'styled-components';

import { LinkButton, variables } from 'app/shared/ui';

const {
  variables: { media },
} = variables;

export const BenifitSt = styled.ul`
  list-style: number;
  padding-left: 24px;
  margin-bottom: 24px;
  > li {
    font-size: 14px;
    line-height: 2;
    margin-bottom: 8px;
  }
`;

export const LinkButtonSt = styled(LinkButton)`
  width: 100%;
  margin: 0;

  ${media.md(css`
    width: 265px;
  `)}
`;

import { getContent } from 'app/marketplace/contentful/utils';

export const getZIndex = (pathname, showFavouriteTip, showSaSeDashboardTip) => {
  if (showFavouriteTip || showSaSeDashboardTip) {
    return 1051;
  }

  if (
    (pathname && pathname.includes('gebrauchtwagen')) ||
    (pathname && pathname.includes('vehicle')) ||
    (pathname && pathname.includes('favourites')) ||
    (pathname && pathname.includes('searches'))
  ) {
    return 1031;
  }

  return 1000;
};

export const getPdpCampaignTopBanner = (content) => {
  try {
    const responsiveImageContent = getContent(content, 'responsiveImage');
    const trackingDataContent = getContent(content, 'trackingData');

    return {
      responsiveImage: responsiveImageContent?.fields,
      trackingData: trackingDataContent?.fields,
    };
  } catch (error) {
    /* istanbul ignore next */
    return {
      responsiveImage: null,
      trackingData: null,
    };
  }
};

export const getIsHiddenNavPage = (pathname = '') => {
  return (
    pathname.startsWith('/checkout') ||
    pathname.startsWith('/cash-checkout') ||
    pathname.includes('damage-report')
  );
};

import styled, { css } from 'styled-components';
import { LinkButton } from 'app/shared/ui';

import { media } from 'app/shared/styles_js/variables';
import { ContainerSt } from 'app/shared/ui';

export const LastSearchSt = styled.div`
  position: relative;
  overflow: hidden;
  margin-top: 48px;
  margin-bottom: 72px;

  ${media.md(
    css`
      margin: 56px 0;
    `,
  )};

  &::before,
  &::after {
    position: absolute;
    z-index: 10;
    top: 0;
    bottom: 0;
    width: 24px;
    content: '';

    ${media.md(
      css`
        width: 0;
      `,
    )};
  }
`;

export const ButtonContainerSt = styled(ContainerSt)`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const CartileContainerSt = styled(ContainerSt)`
  display: block;
  width: 100%;
  margin: 0 auto;

  ${media.md(css`
    display: flex;
    flex-wrap: wrap;
    & > div:nth-child(odd) {
      padding-left: 0;
    }
    & > div:nth-child(even) {
      padding-right: 0;
    }
  `)};

  ${media.lg(css`
    & > div:first-child {
      box-sizing: border-box;
      padding-left: 0;
      padding-right: calc(64px / 3);
    }
    & > div:nth-child(2) {
      padding-right: calc(64px / 6);
      padding-left: calc(64px / 6);
    }
    & > div:last-child {
      box-sizing: border-box;
      padding-right: 0;
      padding-left: calc(64px / 3);
    }
  `)};
`;

export const ShowMoreSt = styled(LinkButton)`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 48px;
  margin: 24px auto 0;

  ${media.md(
    css`
      padding: 0 40px;
    `,
  )};
`;

interface IFilterObject {
  category: string;
  value: string;
  uuid: string;
  label: string;
  operator: string;
  parent?: string | null;
  direction?: string | null;
}

export interface IselectedFinancingOption {
  max: number;
  min: number;
  floor: number;
  snapPoints: number[];
  showPopularRates: boolean;
  searchCategory: string;
  isMonthly: boolean;
  trackingActionName: string;
  disclaimer: JSX.Element | string;
  sliderTitle: JSX.Element | string;
  hasShowMoreFeature: boolean;
}

export type TFinanceFilterProps = {
  showSliderWithInput: boolean;
  onChangeFilter: (
    filters: {
      priceRange: IFilterObject[];
      monthlyRateRange: IFilterObject[];
      leasingRateRange: IFilterObject[];
      budgetRangeSwitch: IFilterObject[];
    },
    override: boolean,
    setFilters: boolean,
  ) => void;
  trackPriceChange: (
    searchCategory: string,
    selectedPrice: string | null,
  ) => void;
  trackFinancingOptionChange: (trackingActionName: string) => void;

  filter?: IFilterObject;
  local?: string;
};

export type TFinanceSliderProps = {
  withInput: boolean;
  selectedPrice: string;
  selectedFinancingOption: IselectedFinancingOption;
  onDrag: (value: number[]) => void;
  onChange: ({ values }: { values: number[] }) => void;
  onCustomPriceClick: (value: string) => void;
};

export enum EFinancingCategorySearch {
  priceRange = 0,
  monthlyRateRange = 1,
  leasingRateRange = 2,
}

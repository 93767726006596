import { memo } from 'react';

import { CartileImageUnavailableContainerSt } from 'app/shared/modules/Cartile/CartileUIComponents/CartileImages/CartileImageUnavailable/CartileImageUnavailable.css';

const CartileImageUnavailableCmp = () => (
  <CartileImageUnavailableContainerSt>
    <img src="/assets/icon-car-sold.svg" alt="Auto nicht verfugbar" />
    Leider nicht mehr verfügbar
  </CartileImageUnavailableContainerSt>
);

CartileImageUnavailableCmp.propTypes = {};

export const CartileImageUnavailable = memo(CartileImageUnavailableCmp);

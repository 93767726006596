import { PureComponent } from 'react';
import PropTypes from 'prop-types';
import debounce from 'lodash.debounce';
import ismatch from 'lodash.ismatch';

import { DefaultPopoverRenderer } from './DefaultPopoverRenderer/DefaultPopoverRenderer';
import { DefaultItemRenderer } from './DefaultItemRenderer/DefaultItemRenderer';
import { DefaultInputRenderer } from './DefaultInputRenderer/DefaultInputRenderer';
import { InputSelectSt } from './InputSelect.css';

// TODO add accessibility properties
export class InputSelect extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      hasPopover: false,
      inputValue: props.value ? props.inputValueExtractor(props.value) : '',
      valueObject:
        typeof props.value === 'object'
          ? props.value
          : props.itemCreator(props.value),
      currentItems: props.items,
      wasEdited: false,
      focused: -1,
    };

    this.wasBlurredOnSelect = false;

    // necessary to prevent re-rendering of existing popoverRenderer/itemRenderer instances (allow animations)
    this.popoverRenderer = null;
    this.itemRenderer = null;

    this.hasCustomItem = false;

    this.showPopover = this.showPopover.bind(this);
    this.hidePopover = this.hidePopover.bind(this);
    this.getItemRenderer = this.getItemRenderer.bind(this);
    this.getPopoverRenderer = this.getPopoverRenderer.bind(this);
    this.getInputRenderer = this.getInputRenderer.bind(this);
    this.onSelect = this.onSelect.bind(this);
    this.onChange = this.onChange.bind(this);
    this.getItems = this.getItems.bind(this);
    this.onFocus = this.onFocus.bind(this);
    this.onInputFocus = this.onInputFocus.bind(this);
    this.onInputBlur = this.onInputBlur.bind(this);
    this.debouncedOnchange = props.debounceTime
      ? debounce(props.onChange, props.debounceTime)
      : props.onChange;
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    let newState = {};

    if (
      !ismatch(this.props.value, nextProps.value) ||
      (nextProps.value !== this.props.value && !nextProps.value)
    ) {
      newState = Object.assign(newState, {
        inputValue: nextProps.value
          ? nextProps.inputValueExtractor(nextProps.value)
          : '',
        valueObject: this.props.value,
      });
    }
    if (nextProps.items !== this.props.items) {
      newState = Object.assign(newState, {
        currentItems: nextProps.items,
      });
      this.hasCustomItem = false;
    }

    this.setState(newState);
  }

  showPopover() {
    this.setState({
      hasPopover: true,
    });
  }

  hidePopover() {
    if (this.state.hasPopover) {
      this.setState({
        hasPopover: false,
      });
    }
  }

  onSelect(item, index, event) {
    const { onSelect, inputValueExtractor, blurOnSelect } = this.props;

    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }
    if (index !== -1) {
      this.setState({
        inputValue: inputValueExtractor(item),
        valueObject: item,
        focused: -1,
      });
    }
    if (blurOnSelect) {
      this.wasBlurredOnSelect = true;
      this.hidePopover();
    }

    return onSelect && onSelect(item, index, event);
  }

  getItemRenderer() {
    const { itemRenderer, popoverRenderer, itemRendererProps } = this.props;

    // if the a popoverrenderer is passed as prop, we assume the handling of itemrendererprops is taken care of by it
    if (popoverRenderer !== DefaultPopoverRenderer) {
      return itemRenderer;
    }
    if (this.itemRenderer) {
      return this.itemRenderer;
    }
    const ItemRenderer = itemRenderer;

    this.itemRenderer = props => (
      <ItemRenderer {...itemRendererProps} {...props} />
    );
    return this.itemRenderer;
  }

  getPopoverRenderer() {
    const { popoverRenderer, popoverRendererProps } = this.props;

    if (this.popoverRenderer) {
      return this.popoverRenderer;
    }
    const PopoverRenderer = popoverRenderer;

    this.popoverRenderer = props => (
      <PopoverRenderer {...popoverRendererProps} {...props} />
    );
    return this.popoverRenderer;
  }

  getInputRenderer() {
    const { inputRenderer, inputRendererProps } = this.props;

    if (this.inputRenderer) {
      return this.inputRenderer;
    }
    const InputRenderer = inputRenderer;

    this.inputRenderer = props => (
      <InputRenderer {...inputRendererProps} {...props} />
    );
    return this.inputRenderer;
  }

  onChange(e) {
    const { itemCreator, canCreate, minInputLength } = this.props;
    const { hasPopover } = this.state;
    const inputValue = e.target.value;
    const newState = {
      inputValue,
      wasEdited: true,
    };

    // when user start typing again show popover
    if (!hasPopover) {
      this.showPopover();
    }

    if (canCreate) {
      newState.valueObject = itemCreator(inputValue);
    } else {
      newState.valueObject = null;
    }
    this.setState(newState);

    return (
      inputValue.length >= minInputLength &&
      this.debouncedOnchange &&
      this.debouncedOnchange(inputValue)
    );
  }

  onFocus(index) {
    this.setState({
      focused: index,
    });
  }

  onInputFocus(e) {
    const { onInputFocus } = this.props;

    this.showPopover();

    return onInputFocus && onInputFocus(e);
  }

  onInputBlur(e) {
    const { onInputBlur, canCreate, selectOnBlur, itemCreator } = this.props;

    this.hidePopover();
    if (canCreate && selectOnBlur && this.state.inputValue) {
      !this.wasBlurredOnSelect &&
        this.onSelect(itemCreator(this.state.inputValue), -1, e);
      this.wasBlurredOnSelect = false;
    }

    return onInputBlur && onInputBlur(e);
  }

  getItems() {
    const { inputValue, currentItems, wasEdited } = this.state;
    const {
      itemSearch,
      canCreate,
      itemCreator,
      inputValueExtractor,
    } = this.props;
    const searchedItems = itemSearch
      ? itemSearch(currentItems, inputValue)
      : currentItems;

    if (!inputValue || !wasEdited) {
      return currentItems;
    }

    const currentItemsFilteredByInputValue = currentItems.filter(item => {
      const extractedInputValue = inputValueExtractor(item);

      return extractedInputValue === inputValue;
    });

    if (canCreate && currentItemsFilteredByInputValue.length === 0) {
      if (this.hasCustomItem) {
        searchedItems.splice(0, 1, itemCreator(inputValue));
      } else {
        this.hasCustomItem = true;
        searchedItems.unshift(itemCreator(inputValue));
      }
    }

    return searchedItems;
  }

  render() {
    const { hasPopover, inputValue, focused } = this.state;
    const {
      className,
      placeholder,
      isRequired,
      inputValueExtractor,
      disabled,
      extraInputClasses,
      inputRendererProps,
      popoverRendererProps,
      helperText,
      label,
      theme,
      useTransparentBg
    } = this.props;
    const Popover = this.getPopoverRenderer();
    const InputCmp = this.getInputRenderer();

    return (
      <InputSelectSt className={className} theme={theme} useTransparentBg={useTransparentBg}>
        <InputCmp
          inputClasses={extraInputClasses}
          value={inputValue}
          onChange={this.onChange}
          onFocus={this.onInputFocus}
          onBlur={this.onInputBlur}
          placeholder={placeholder}
          label={label}
          isRequired={isRequired}
          disabled={disabled}
          helperText={helperText}
          hasFocus={hasPopover}
          {...inputRendererProps}
        >
          <Popover
            show={hasPopover}
            itemRenderer={this.getItemRenderer()}
            items={this.getItems()}
            onSelect={this.onSelect}
            onFocus={this.onFocus}
            focused={focused}
            inputValueExtractor={inputValueExtractor}
            currentInputValue={inputValue}
            {...popoverRendererProps}
          />
        </InputCmp>
      </InputSelectSt>
    );
  }
}

InputSelect.propTypes = {
  className: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node,
    PropTypes.object,
  ]),
  placeholder: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  disabled: PropTypes.bool,
  isRequired: PropTypes.bool,
  items: PropTypes.arrayOf(PropTypes.object),
  // component classname or function
  itemRenderer: PropTypes.func,
  itemRendererProps: PropTypes.object,
  helperText: PropTypes.any,
  // component classname or function
  popoverRenderer: PropTypes.func,
  popoverRendererProps: PropTypes.object,
  canCreate: PropTypes.bool,
  itemCreator: PropTypes.func,
  itemSearch: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
  inputValueExtractor: PropTypes.func,
  onSelect: PropTypes.func.isRequired,
  onChange: PropTypes.func,
  onInputFocus: PropTypes.func,
  onInputBlur: PropTypes.func,
  debounceTime: PropTypes.number,
  extraInputClasses: PropTypes.arrayOf(PropTypes.string),
  inputRenderer: PropTypes.any,
  inputRendererProps: PropTypes.object,
  minInputLength: PropTypes.number,
  blurOnSelect: PropTypes.bool,
  selectOnBlur: PropTypes.bool,
  errorText: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
};

InputSelect.defaultProps = {
  className: '',
  placeholder: '',
  disabled: false,
  canCreate: false,
  isRequired: false,
  selectOnBlur: false,
  items: [],
  popoverRenderer: DefaultPopoverRenderer,
  itemRenderer: DefaultItemRenderer,
  inputRenderer: DefaultInputRenderer,
  inputRendererProps: {},
  popoverRendererProps: {},
  itemRendererProps: {},
  helperText: null,
  itemSearch: (items, value) => {
    return items.filter(
      item => item.label.toLowerCase().indexOf(value.toLowerCase()) > -1,
    );
  },
  itemCreator: val => {
    return { value: val, label: val };
  },
  inputValueExtractor: val => {
    if (typeof val === 'string') {
      return val;
    }
    return val.label ? val.label : val.value;
  },
  extraInputClasses: [],
  debounceTime: 1,
  minInputLength: 0,
  onChange: () => {},
  blurOnSelect: true,
  value: null,
};

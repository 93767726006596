/* eslint-disable react/jsx-props-no-spreading, react/no-multi-comp */
/* istanbul ignore file */
import React from 'react';

import { Link } from 'app/shared/ui';
import { ConnectedGamBanner } from 'app/shared/modules/Campaign/GAM/GamBanner/ConnectedGamBanner';
import { ResponsiveImageLoader } from 'app/marketplace/shared/components/ResponsiveImageLoader/ResponsiveImageLoader';
import { GAM_AD_UNITS } from 'app/shared/modules/Campaign/GAM/ad-units';
import { useSentryTags } from 'app/shared/hooks/useSentryTags';

import { StageBannerSt } from './StageBanner.css';
import { getContentfulStageBannerSources as getCfSources } from './constants/contentful';
import { IStageBannerProps } from './StageBanner.types';

const { HP_STAGE_BANNER } = GAM_AD_UNITS;
const defaultImage = '/assets/home/stage-filter-panel.webp';

export const StageBanner = ({
  onLoadImages,
  breakpoint,
  hasSingleImage,
  responsiveImage,
  imageUrl = '',
  stageBannerLink,
  handleStageBannerClick,
  title,
}: IStageBannerProps): JSX.Element => {
  useSentryTags({ GROWTH_DE: true });
  const ContentfulBanner = (
    <Link to={stageBannerLink} title={title} onClick={handleStageBannerClick}>
      <ResponsiveImageLoader
        breakpoint={breakpoint}
        onLoad={onLoadImages}
        sources={getCfSources({
          hasSingleImage,
          imageUrl,
          responsiveImage,
          defaultImage,
        })}
        alt={title}
        fetchpriority="high"
      />
    </Link>
  );

  return (
    <StageBannerSt>
      <ConnectedGamBanner
        adUnitId={HP_STAGE_BANNER.adUnitId}
        adUnitPath={HP_STAGE_BANNER.adUnitPath}
        sizes={HP_STAGE_BANNER.sizes}
        bannerArea={HP_STAGE_BANNER.bannerArea}
        fallbackComponent={ContentfulBanner}
        trackingData={HP_STAGE_BANNER.trackingData}
        renderWhenViewable={false}
        collectDurationMetric
      />
    </StageBannerSt>
  );
};

import React from 'react';
import PropTypes from 'prop-types';

import {
  ContainerSt,
  mdContainerStyles,
  lgContainerStyles,
  xlContainerStyles,
} from './Container.css';

const Container = props => {
  const { children, className, tagName } = props;
  const CustomTag = tagName || 'div';

  return (
    <ContainerSt className={className}>
      <CustomTag>{children}</CustomTag>
    </ContainerSt>
  );
};

Container.propTypes = {
  children: PropTypes.any,
  className: PropTypes.string,
  tagName: PropTypes.string,
};

// NOTE: This component is extended by Pagination; thus the export
export {
  Container,
  ContainerSt,
  mdContainerStyles,
  lgContainerStyles,
  xlContainerStyles,
};

import { Route } from 'react-router';

export const DownTimeRoutes = (
  <Route name="downtime">
    <Route
      path="/temp-unavailable"
      getComponent={(loc, cb) =>
        import('./DownTime/ConnectedDownTime').then(c =>
          cb(null, c.ConnectedDownTime)
        )
      }
    />
  </Route>
);

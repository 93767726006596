import { apiCall } from 'app/actions';
import {
  Channel,
  DeviceType,
  SaveSearchActionTypes,
} from 'app/marketplace/saveSearch/saveSearch.types';
import { toggleConfigStatus } from 'app/marketplace/saveSearch/reducer';
import { getVehicleCount } from 'app/marketplace/search/actions';
import {
  getFilterObject,
  getFiltersFromUri,
} from 'app/marketplace/filter/utils';
import { getObjFromUrlParams } from 'app/marketplace/search/utils';
import { getFiltersLabels } from 'app/marketplace/saveSearch/utils';
import { trackIdentify } from 'app/shared/utils/tracking';
import { setUserCreated } from 'app/shared/modules/user/actions';

/**
 * Checks if the user is registered or not
 *
 * @param {string} userEmail
 * @returns {object} promise
 */
export const checkUserIsRegistered = (userEmail) => {
  return async (dispatch) => {
    const url = `/profiles/${userEmail}/isRegistered`;
    const options = {
      method: 'GET',
    };
    try {
      const payload = await dispatch(apiCall(url, options, true, true));
      dispatch({
        type: SaveSearchActionTypes.SAVE_SEARCH_ACCOUNT_REGISTERED_SUCCESS,
        payload,
      });
    } catch (error) {
      dispatch({
        type: SaveSearchActionTypes.SAVE_SEARCH_ACCOUNT_REGISTERED_FAILURE,
        error: error.message,
      });
    }
  };
};

/**
 * Create a SaveSearch for a user
 *
 * @param {object} formData
 * @returns {object} promise
 */

export const createSavedSearch = (formData) => {
  return async (dispatch, getState) => {
    dispatch({
      type: SaveSearchActionTypes.SAVE_SEARCH_PENDING,
    });
    const url = '/saved_searches';
    const options = {
      method: 'POST',
      body: JSON.stringify(formData),
    };
    try {
      const payload = await dispatch(apiCall(url, options, true, true));
      const {
        searchUserProfile: { email },
        userId,
      } = payload;

      trackIdentify(userId, email);
      return dispatch({
        type: SaveSearchActionTypes.SAVE_SEARCH_POST_SEARCH_SUCCESS,
        payload,
      });
    } catch (error) {
      return dispatch({
        type: SaveSearchActionTypes.SAVE_SEARCH_POST_SEARCH_FAILED,
        error: error.message,
      });
    }
  };
};

/**
 * Update a SaveSearch for a user
 *
 * @param {object} notificationObj
 * @returns {object} promise
 */

export const updateSaveSearchNotificationStatus = (notificationObj) => {
  return async (dispatch) => {
    const body = {
      channel: Channel.EMAIL,
      configStatus: toggleConfigStatus(notificationObj.configStatus),
    };
    const url = `/saved_searches/saved_search_notification_configs/${notificationObj.id}`;
    const options = {
      method: 'PUT',
      body: JSON.stringify(body),
    };
    try {
      const payload = await dispatch(apiCall(url, options, true, true));
      return dispatch({
        type:
          SaveSearchActionTypes.SAVE_SEARCH_UPDATE_NOTIFICATION_STATUS_SUCCESS,
        payload,
      });
    } catch (error) {
      return dispatch({
        type:
          SaveSearchActionTypes.SAVE_SEARCH_UPDATE_NOTIFICATION_STATUS_FAILED,
        error: error.message,
      });
    }
  };
};

/**
 * Update a SaveSearch for a user
 *
 * @param {string} searchId
 * @param {string} param
 * @param {string} method
 * @returns {object} promise
 */

export const updateSavedSearch = (searchId, param, method) => {
  return async (dispatch) => {
    const urlParams = !method ? `${searchId}?${param}` : `${searchId}`;
    const url = `/saved_searches/${urlParams}`;
    const options = {
      method: !method ? 'PUT' : 'PATCH',
    };
    try {
      const payload = await dispatch(apiCall(url, options, true, true));
      return dispatch({
        type: SaveSearchActionTypes.SAVE_SEARCH_UPDATE_SEARCH_SUCCESS,
        payload,
      });
    } catch (error) {
      return dispatch({
        type: SaveSearchActionTypes.SAVE_SEARCH_UPDATE_SEARCH_FAILED,
        error: error.message,
      });
    }
  };
};

/**
 * Delete a SaveSearch for a user
 *
 * @param {string} searchId
 * @returns {object} promise
 */

export const deleteSavedSearch = (searchId) => {
  return async (dispatch) => {
    const url = `/saved_searches/${searchId}`;
    const options = {
      method: 'DELETE',
    };
    try {
      await dispatch(apiCall(url, options, true, true));
      return dispatch({
        type: SaveSearchActionTypes.SAVE_SEARCH_DELETE_SEARCH_SUCCESS,
        payload: { id: searchId },
      });
    } catch (error) {
      return dispatch({
        type: SaveSearchActionTypes.SAVE_SEARCH_DELETE_SEARCH_FAILED,
        error: error.message,
      });
    }
  };
};

export const getCityAndZipCodeLabels = (lat, lon, activeFilters) => {
  return async (dispatch) => {
    // if there is a lat and lon filter in the searchQuery then we add a zip filter object to the filters
    if (lat && lon) {
      const url = `/location/lookup/coordinates/${lat}/${lon}`;
      try {
        const payload = await dispatch(apiCall(url));
        const [first] = payload;
        const { postcode, placeName } = first;
        const zip = [
          getFilterObject({
            category: 'zip',
            label: `${postcode} ${placeName}`,
            value: '',
          }),
        ];
        return {
          ...activeFilters,
          zip,
        };
      } catch (error) {
        return dispatch({
          type: SaveSearchActionTypes.SAVE_SEARCH_FETCH_SEARCHES_FAILED,
          error: 'location API failed',
        });
      }
    }
    // If not we just return the activeFilters
    return activeFilters;
  };
};

/* istanbul ignore next */
export const getAllLabels = (listOfSaSeWithoutSearchLabels, aggregation) =>
  listOfSaSeWithoutSearchLabels.map((activeFilters) =>
    getFiltersLabels({ activeFilters }),
  );

// This will first check if the aggregation is already set in the filter reducer
/* istanbul ignore next */
export const getMissingSearchLabels = (listOfSaSeWithoutSearchLabels) => {
  return async (dispatch, getState) => {
    const {
      allMakes,
      possibleMakes,
      possibleModels,
      possibleVariantes,
    } = getState().filter;
    // Otherwise it call COUNT to get the aggregation
    if (
      allMakes?.length === 0 ||
      possibleMakes?.length === 0 ||
      possibleModels?.length === 0 ||
      possibleVariantes?.length === 0
    ) {
      await dispatch(getVehicleCount());
    }
    const aggregation = {
      make: getState().filter.possibleMakes,
      model: getState().filter.possibleModels,
      usedCarSeal: getState().filter.allUsedCarSeal,
    };

    // We build the filters object from the searchQuery of the SaSe
    const searchesActiveFilters = listOfSaSeWithoutSearchLabels.map((search) =>
      getFiltersFromUri(
        {},
        getObjFromUrlParams(search.searchQuery),
        aggregation,
      ),
    );
    // We then need to get the location labels
    const searchesActiveFiltersWithZip = await Promise.all(
      searchesActiveFilters.map(async (filterCategory) => {
        return dispatch(
          getCityAndZipCodeLabels(
            filterCategory.lat[0]?.value,
            filterCategory.lon[0]?.value,
            filterCategory,
          ),
        );
      }),
    );
    // Now that we have all the filters and there labels we can enter the utils function to build the searchLabels list.
    const labels = getAllLabels(searchesActiveFiltersWithZip, aggregation);
    // Now we need to call the PUT endpoint to update the SaSe with the new searchLabels value
    await Promise.all(
      listOfSaSeWithoutSearchLabels.map(async (SaSe, idx) => {
        const param = `searchLabels=${labels[idx].join()}`;
        return dispatch(updateSavedSearch(SaSe.id, param));
      }),
    );
    // Finally we the updated SaSe to render the page
    return listOfSaSeWithoutSearchLabels.map((savedSearch, idx) => ({
      ...savedSearch,
      searchLabels: labels[idx],
    }));
  };
};

/**
 * Get all searches for a user
 *
 * @param {string} email
 * @param {number} size
 * @param {number} page
 * @returns {*} promise
 */

export const getSavedSearches = (email, size, page = 0) => {
  return async (dispatch) => {
    dispatch({
      type: SaveSearchActionTypes.SAVE_SEARCH_PENDING,
    });
    if (email) {
      const url = `/saved_searches?deviceId=${email}&deviceType=${DeviceType.DESKTOP}&size=${size}&page=${page}`;
      const options = {
        method: 'GET',
      };
      try {
        const {
          totalPages,
          totalElements,
          pageable: pagination,
          content: savedSearches,
        } = await dispatch(apiCall(url, options, true, true));

        /* TEMPORARY HOOK TO ADD SEARCH LABELS TO OLD SAVED SEARCHES => SHOULD BE REMOVE AFTER 6 MONTHS (OCTOBER 20th 2021) */

        // Check if there is some SaSe without searchLabels
        const savedSearchesWithNoLabel = savedSearches.filter(
          (search) => !search.searchLabels,
        );
        // If yes we enter the fallback to retrieve them, if not we skip this part and return A SUCCESS action
        /* istanbul ignore next */
        if (savedSearchesWithNoLabel.length > 0) {
          try {
            const SavedSearchesWithNewLabels = await dispatch(
              getMissingSearchLabels(savedSearchesWithNoLabel),
            );
            const updatedSavedSearches = [
              ...SavedSearchesWithNewLabels,
              ...savedSearches.filter((search) => search.searchLabels),
            ].sort((x, y) => new Date(y.createdAt) - new Date(x.createdAt));
            return dispatch({
              type: SaveSearchActionTypes.SAVE_SEARCH_FETCH_SEARCHES_SUCCESS,
              payload: {
                savedSearches: updatedSavedSearches,
                pagination,
                totalPages,
                totalElements,
              },
            });
          } catch (error) {
            return dispatch({
              type: SaveSearchActionTypes.SAVE_SEARCH_FETCH_SEARCHES_SUCCESS,
              payload: {
                totalPages,
                pagination,
                savedSearches,
                totalElements,
              },
            });
          }
        }

        /* THIS END HERE */

        return dispatch({
          type: SaveSearchActionTypes.SAVE_SEARCH_FETCH_SEARCHES_SUCCESS,
          payload: {
            totalPages,
            pagination,
            savedSearches,
            totalElements,
          },
        });
      } catch (error) {
        return dispatch({
          type: SaveSearchActionTypes.SAVE_SEARCH_FETCH_SEARCHES_FAILED,
          error: error.message,
        });
      }
    }
    return dispatch({
      type: SaveSearchActionTypes.SAVE_SEARCH_FETCH_SEARCHES_FAILED,
      error: 'not a login user (no email found)',
    });
  };
};

import styled, { css } from 'styled-components';
import { Link } from 'app/shared/ui';

import { color } from 'app/shared/styles_js/variables';

const primary = css`
  background-color: ${(props) =>
    props.disabled ? color.brandPrimaryLighter : color.brandPrimary};
  color: #fff;

  ${(props) =>
    !props.disabled &&
    css`
      &.active,
      &:hover,
      &:focus {
        background-color: ${color.brandPrimaryLight};
        color: #fff;
      }
    `};
`;

const secondary = css`
  background-color: ${(props) =>
    props.disabled ? color.grayLight : color.brandSecondary};
  color: #fff;

  ${(props) =>
    !props.disabled &&
    css`
      &.active,
      &:hover,
      &:focus {
        background-color: ${color.brandSecondaryLight};
        color: #fff;
      }
    `};
`;

const tertiaryLight = css`
  background-color: transparent;
  border-color: ${color.gray};
  color: ${(props) => (props.disabled ? color.grayDark : color.brandSecondary)};

  ${(props) =>
    !props.disabled &&
    css`
      &.active,
      &:hover,
      &:focus {
        color: ${color.brandPrimary};
      }
    `};

  .sk-fading-circle > .sk-circle::before {
    background-color: ${color.brandSecondary};
  }
`;

const tertiaryDark = css`
  background-color: transparent;
  border-color: ${(props) => (props.disabled ? '#4d4d4d' : '#fff')};
  color: ${(props) => (props.disabled ? '#4d4d4d' : '#fff')};

  &.active,
  &:hover,
  &:focus {
    color: ${color.brandPrimary};
  }
`;

export const ButtonSt = styled.button`
  position: relative;
  display: inline-block;
  overflow: hidden;
  min-width: ${(props) => (props.fullSize ? '100%' : '120px')};
  height: 48px;
  padding: 0;
  border: 1px solid transparent;
  margin-bottom: 0;
  border-radius: 4px;
  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
  letter-spacing: 0.5px;
  text-align: center;
  transform: translate3d(0, 0, 0);
  vertical-align: middle;
  white-space: nowrap;

  ${(props) => props.kind === 'primary' && primary}
  ${(props) => props.kind === 'secondary' && secondary}
  ${(props) => props.kind === 'tertiaryLight' && tertiaryLight}
  ${(props) => props.kind === 'tertiaryDark' && tertiaryDark}

  &::after {
    display: block;
    background: radial-gradient(circle, #000 10%, transparent 10.01%) no-repeat
      50%;
    content: '';
    opacity: 0;
    pointer-events: none;
    transform: scale(10, 10);
    transition: all 0.5s;
    transition-property: transform, opacity;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
  }

  &:active::after {
    opacity: 0.2;
    transform: scale(0, 0);
    transition: none;
  }

  & > .sk-fading-circle {
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -16px;
    margin-left: -16px;
  }
`;

export const LinkSt = styled(Link).attrs(() => ({
  fontWeight: 'medium',
  textDecoration: 'none',
}))`
  position: relative;
  display: flex;
  overflow: hidden;
  min-width: ${(props) => (props.fullSize ? '100%' : '120px')};
  height: 48px;
  align-items: center;
  justify-content: center;
  border: 1px solid transparent;
  margin-bottom: 0;
  border-radius: 4px;
  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
  letter-spacing: 0.5px;
  text-align: center;
  transform: translate3d(0, 0, 0);
  vertical-align: middle;
  white-space: nowrap;

  ${(props) => props.kind === 'primary' && primary}
  ${(props) => props.kind === 'secondary' && secondary}
  ${(props) => props.kind === 'tertiaryLight' && tertiaryLight}
  ${(props) => props.kind === 'tertiaryDark' && tertiaryDark}

  &::after {
    display: block;
    background: radial-gradient(circle, #000 10%, transparent 10.01%) no-repeat
      50%;
    content: '';
    opacity: 0;
    pointer-events: none;
    transform: scale(10, 10);
    transition: all 0.5s;
    transition-property: transform, opacity;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
  }

  &:active::after {
    opacity: 0.2;
    transform: scale(0, 0);
    transition: none;
  }

  & > .sk-fading-circle {
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -16px;
    margin-left: -16px;
  }
`;

import React from 'react';
import PropTypes from 'prop-types';

import { iconSizes } from '../variables/fonts';

import {
  CheckboxSt,
  ChildrenSt,
  ErrorIconSt,
  IconContainerSt,
  IconSt,
} from './Checkbox.css';

const noop = /* istanbul ignore next */ () => {};

export class Checkbox extends React.PureComponent {
  onChange = (e) => {
    e.stopPropagation();
    // Look for <a> elements in the event path to prevent onchange method
    const { path } = e.nativeEvent;
    const slice = path && path.slice(0, path.indexOf(e.currentTarget));
    const targetIsLink = slice && slice.some((node) => node.nodeName === 'A');

    if (!this.props.disabled && !targetIsLink) {
      this.props.onChange(e);
    }
  };

  isOnHold = () => {
    const { onHold, checked } = this.props;

    if (onHold) {
      return 'mixed-selection';
    }
    return checked ? 'active' : 'inactive';
  };

  render() {
    const {
      checked,
      disabled,
      children,
      direction,
      isRadio,
      hasError,
      tabIndex,
      dataQa,
      className,
      textSize,
      size,
      verticalPosition,
      enableClickOnText,
    } = this.props;
    const iconShape = isRadio ? 'radiobutton' : 'checkbox';
    const isRight = direction !== 'left';
    const errorIcon = (
      <ErrorIconSt className="if-icon-attention" isRight={isRight} />
    );
    const icon = (
      <IconContainerSt isRight={isRight} size={size}>
        <IconSt
          size={size}
          disabled={disabled}
          isError={hasError}
          isChecked={checked}
          isRight={isRight}
          tabIndex={tabIndex}
          onClick={this.onChange}
          onKeyPress={this.onChange}
          className={`if-icon-${iconShape}-${this.isOnHold()}`}
        />
        {hasError ? errorIcon : null}
      </IconContainerSt>
    );

    let position = verticalPosition;
    if (verticalPosition === 'top') {
      position = 'flex-start';
    } else if (verticalPosition === 'bottom') {
      position = 'flex-end';
    }

    return (
      <CheckboxSt
        className={className}
        isRight={isRight}
        textSize={textSize}
        isChecked={checked}
        disabled={disabled}
        onClick={enableClickOnText ? this.onChange : noop}
        data-qa={dataQa}
        enableClickOnText={enableClickOnText}
        verticalPosition={position}
      >
        {icon}
        <ChildrenSt disabled={disabled} iconSize={size}>
          {children}
        </ChildrenSt>
      </CheckboxSt>
    );
  }
}

Checkbox.propTypes = {
  className: PropTypes.string,
  disabled: PropTypes.bool,
  checked: PropTypes.bool,
  children: PropTypes.any,
  onChange: PropTypes.func,
  direction: PropTypes.string,
  isRadio: PropTypes.bool,
  hasError: PropTypes.bool,
  tabIndex: PropTypes.number,
  dataQa: PropTypes.string,
  enableClickOnText: PropTypes.bool,
  textSize: PropTypes.number,
  size: PropTypes.oneOf([iconSizes.xsmall, iconSizes.medium]), // TODO: update this naming to iconSize
  verticalPosition: PropTypes.oneOf(['top', 'center', 'bottom']),
  onHold: PropTypes.bool,
};

Checkbox.defaultProps = {
  disabled: false,
  checked: false,
  direction: 'right',
  isRadio: false,
  hasError: false,
  tabIndex: 0,
  textSize: 14,
  size: 24,
  enableClickOnText: true,
  verticalPosition: 'center',
  onHold: false,
  onChange: () => {},
  dataQa: '',
  className: '',
  children: undefined,
};

import { createSelector } from 'reselect';

export const carComparisonSelector = state => state.carComparison;

export const vehiclesInCarComparisonSelector = createSelector(
  carComparisonSelector,
  carComparison =>
    carComparison.vehicles.map(vehicle => ({
      ...vehicle,
      taxi: vehicle.taxi || Boolean(vehicle.rentalVehicle),
    })),
);

export const vehiclesIDInCarComparisonSelector = createSelector(
  carComparisonSelector,
  carComparison =>
    carComparison && carComparison.vehicles.map(vehicle => vehicle.id),
);

export const getVehicleCountInCarComparisonSelector = createSelector(
  carComparisonSelector,
  carComparison => carComparison && carComparison.vehicles.length,
);

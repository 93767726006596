import { IGAMConfig } from 'app/shared/modules/Campaign/GAM/GamBanner/GamBanner.types';

const dimensions = { width: 340, height: 350 };

export const bannerSizes = {
  sm: [{ ...dimensions }],
  md: [{ ...dimensions }],
  lg: [{ ...dimensions }],
  xl: [{ ...dimensions }],
};

export const bannerArea = {
  sm: { ...dimensions },
  md: { ...dimensions },
  lg: { ...dimensions },
  xl: { ...dimensions },
};

const getSpecialOffersPageTiles = (_: number, index: number): IGAMConfig => ({
  adUnitId: `div-gam-special-offers-page-tile-${index + 1}`,
  adUnitPath: `/22376835783/special-offers-page-tile-${index + 1}`,
  sizes: bannerSizes,
  bannerArea,
  trackingData: {
    clickEvent: {
      eventName: `offers_page_car_tile${index + 1}_click`,
      placement: 'OFFERS',
    },
    impressionEvent: {
      category: 'Offers page',
      label: `deals and special car tile ${index + 1} on offers page loaded`,
    },
  },
});

export const SPECIAL_OFFERS_PAGE_TILES = [...new Array(8).keys()].map(
  getSpecialOffersPageTiles,
);

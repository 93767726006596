import { css } from 'styled-components';

import { colors, colorsRgb } from './colors';

export const styledIf = (method, condition) => (...names) => (...args) => (
  props,
) =>
  names[method]((name) => Boolean(props[name]) === condition) && css(...args);

export const is = styledIf('every', true);
export const isNot = styledIf('every', false);
export const isOr = styledIf('some', true);
export const isSomeNot = styledIf('some', false);

const rgbaCache = {};

/**
 * @typedef {import('./index.types').TGetRgba} TGetRgba
 */

/** @type {TGetRgba} getRgba */
export const getRgba = (colorToGet, alpha = 1) => {
  const rgbColor = colorsRgb[colorToGet] || colorToGet;
  const cacheKey = `${rgbColor}${alpha}`;
  if (!rgbaCache[cacheKey]) {
    rgbaCache[cacheKey] = rgbColor
      .replace(/b/, 'ba')
      .replace(/\)/, `, ${alpha})`);
  }
  return rgbaCache[cacheKey];
};

export const getColor = (themeName) => {
  return themeName === 'brandPrimary' ||
    themeName === 'brandSecondary' ||
    themeName === 'brandSecondaryLight'
    ? colors.white
    : colors.brandSecondary;
};

export const supports = (attr, value, cls) => css`
  @supports (${attr}: ${value}) {
    ${cls};
  }
`;

export const webkitOnly = (attrs) => css`
  @media screen and (-webkit-min-device-pixel-ratio: 0) {
    ${attrs};
  }
`;

export const chromeOnly = (attrs) => css`
  @media screen and (-webkit-min-device-pixel-ratio: 0) and (min-resolution: 0.001dpcm) {
    ${attrs};
  }
`;

export const evergreenOnly = (cls) => supports('display', 'grid', cls);

import { connect } from 'react-redux';

import { toggleWizardPopup } from '../actions';

import { FDLBanner } from './FDLBanner';

/**
 * @memberof Home.js
 * @description Homepage's financing banner (Tipps zur Autofinanzierung).
 *
 * Informs the user about the financing possibility and links to the
 * financing guide page and to the FDLWizard (financing wizard).
 */
export const ConnectedFDLBanner = connect(
  null,
  {
    toggleWizardPopup,
  },
)(FDLBanner);

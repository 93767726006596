import { memo } from 'react';
import PropTypes from 'prop-types';
import { browserHistory } from 'react-router';

import {
  CartileD2CMtlPriceLinkSt,
  CartileD2CMtlPriceTextSt,
  CartileD2CMtlPriceValueSt,
} from 'app/shared/modules/Cartile/CartileUIComponents/CartilePrice/CartileD2CMtlPrice/CartileD2CMtlPrice.css';
import { CartileLoadingStyle } from 'app/shared/modules/Cartile/CartileUIComponents/CartileUIComponents.css';
import { formatCurrency } from 'app/shared/utils/formatNumber';

const CartileD2CMtlPriceCmp = ({
  linkTo,
  isLoading,
  monthlyRate,
  handleTracking,
  fontSize,
}) => {
  const handleOnClick = (e) => {
    e.preventDefault();
    e.stopPropagation();
    handleTracking();
    browserHistory.push(linkTo);
  };

  return (
    <CartileD2CMtlPriceLinkSt
      onClick={handleOnClick}
      data-testid="Cartile-CartileUIComponents-CartileD2CMtlPrice-CartileMtlPriceLink"
    >
      <CartileD2CMtlPriceTextSt
        css={[isLoading && CartileLoadingStyle]}
        fontSize={fontSize}
      >
        z.B.{' '}
        <CartileD2CMtlPriceValueSt>
          {formatCurrency(monthlyRate)}
        </CartileD2CMtlPriceValueSt>{' '}
        mtl.*
      </CartileD2CMtlPriceTextSt>
    </CartileD2CMtlPriceLinkSt>
  );
};

CartileD2CMtlPriceCmp.propTypes = {
  isLoading: PropTypes.bool,
  linkTo: PropTypes.string.isRequired,
  monthlyRate: PropTypes.number.isRequired,
  handleTracking: PropTypes.func.isRequired,
  fontSize: PropTypes.string,
  target: PropTypes.string,
};

CartileD2CMtlPriceCmp.defaultProps = {
  isLoading: false,
  fontSize: undefined,
  target: '_self',
};
export const CartileD2CMtlPrice = memo(CartileD2CMtlPriceCmp);

import { useCallback, useEffect, useState } from 'react';
import _isEmpty from 'lodash.isempty';

import {
  CATEGORY,
  DEFAULT_RADIUS,
  createDefaultRadiusFilter,
} from 'app/marketplace/filter/Filters/Filter.constant';
import { getFilterObject } from 'app/marketplace/filter/utils';
import { ICurrentLocation } from 'app/marketplace/geolocation/types';

import { FiltersModel } from '../filters.type';

import { IUseLocationFilter } from './useLocationFilter.types';

export const useLocationFilter = ({
  dropDownRef,
  radiusFilter,
  resetLocation,
  handleTracking,
  onChangeFilter,
  currentLocation,
  resetSuggestions,
  getLocationSuggestions,
  setCurrentLocationFromObject,
}: IUseLocationFilter): {
  handleOnChange: (value: HTMLInputElement['value']) => void;
  handleOnSelect: (location: ICurrentLocation) => void;
  handleResetLocation: (e?: React.FormEvent<HTMLInputElement>) => void;
  locationTextInput: ICurrentLocation['place'];
} => {
  const { place = '' } = currentLocation;
  const [locationTextInput, setLocationTextInput] = useState(place);

  useEffect(() => {
    if (!_isEmpty(currentLocation)) {
      setLocationTextInput(place);
    } else {
      setLocationTextInput('');
    }
  }, [currentLocation]);

  const onLocationUpdate = (location: ICurrentLocation): void => {
    setCurrentLocationFromObject(location);

    const lat = getFilterObject({
      category: CATEGORY.lat,
      value: location.lat,
    });
    const lon = getFilterObject({
      category: CATEGORY.lon,
      value: location.lon,
    });
    const defaultRadiusValue = DEFAULT_RADIUS;
    const radius = (_isEmpty(radiusFilter)
      ? createDefaultRadiusFilter(defaultRadiusValue)
      : radiusFilter) as FiltersModel;

    onChangeFilter([lat, lon, radius]);
  };

  const handleOnSelect = (location: ICurrentLocation): void => {
    if (location?.place) {
      setLocationTextInput(location.place);
      onLocationUpdate(location);
    }
    /* istanbul ignore next */
    if (dropDownRef?.current) {
      /* istanbul ignore next */
      dropDownRef.current.blur();
    }
  };

  const handleResetLocation = (e?: React.FormEvent<HTMLInputElement>): void => {
    const value = e?.currentTarget?.value || '';
    if (handleTracking) {
      handleTracking(CATEGORY.location);
    }

    if (value.length === 0) {
      resetLocation();
      onChangeFilter([
        getFilterObject({ category: CATEGORY.lat, value: null }),
        getFilterObject({ category: CATEGORY.lon, value: null }),
        getFilterObject({
          category: CATEGORY.radius,
          value: null,
        }),
      ]);
    }
  };

  const handleOnChange: (
    value: HTMLInputElement['value'],
  ) => void = useCallback((value) => {
    if (value.length === 0) {
      handleResetLocation();
    }
    if (value.length >= 0) {
      setLocationTextInput(value || '');
      if (value === '') {
        if (resetSuggestions) {
          resetSuggestions();
        }
      } else {
        getLocationSuggestions(value, 5);
      }
    }
  }, []);

  return {
    handleOnChange,
    handleOnSelect,
    locationTextInput,
    handleResetLocation,
  };
};

import {
  MAX_RADIUS,
  MIN_RADIUS,
} from 'app/marketplace/filter/Filters/Filter.constant';

export const FilterMappings = {
  category: {
    kleinwagen: 'Kleinwagen',
    kompaktklasse: 'Kompakt',
    limousine: 'Limousine',
    kombi: 'Kombi',
    van: 'Van',
    suv: 'SUV',
    cabrio: 'Cabrio',
    sportscar: 'Sportwagen',
    camper: 'Camper',
    bus: 'Bus',
    motorhome: 'Wohnmobil oder -wagen',
    truckOver7500: 'Lkw über 7,5 t',
    othercar: 'Andere',
    transportUtility: 'Transporter / Nutzfahrzeug',
  },
  effInterestRateRange: {
    '1-399': '0% - 3,99 % eff. JZ',
  },
  maxOwners: {
    1: 'max. 1 Fahrzeughalter',
    2: 'max. 2 Fahrzeughalter',
  },
  gearBox: {
    automatic_gear: 'Automatik',
    direct_gear: 'Automatik',
    manual_gear: 'Schaltgetriebe',
    semiautomatic_gear: 'Halbautomatik',
  },
  accidentDamaged: {
    true: 'Unfallfrei',
  },
  specialOffers: {
    reducedPrice: 'Preisreduziert',
  },
  dataSource: {
    'GWC_D2C,VTP': 'Online-Leasing',
    GWC_D2C: null,
    INSTAMOTION: null,
  },
  productOffering: {
    DE_DEALER_CASH: 'Online-Kauf',
    DE_D2C_CASH: 'Online-Kauf',
    DE_INSTAMOTION_CASH: 'Online-Kauf',
    'DE_DEALER_CASH,DE_D2C_CASH,DE_INSTAMOTION_CASH,DE_DEALER_FINANCING_AC,DE_D2C_FINANCING_AC,DE_INSTAMOTION_FINANCING':
      'Online-Kauf',
    'DE_D2C_FINANCING_AC,DE_DEALER_FINANCING_AC': 'Online-Finanzierung',
    DE_D2C_LEASING: 'Online-Leasing',
    DE_DEALER_LEASING: 'Online-Leasing',
  },
  singleDayRegistration: {
    true: 'Nur Tageszulassungen',
  },
  boniCheck: {
    true: 'Sofort-Bonitätsprüfung',
  },
  unusedCar: {
    true: 'Neuwagen-Angebote',
  },
  fuelType: {
    diesel: 'Diesel',
    electric: 'Elektro',
    ethanol: 'Ethanol',
    gas: 'Gas',
    hybrid: 'Hybrid - Benzin',
    hybrid_all: 'Plug-in Hybrid',
    hybrid_diesel: 'Hybrid - Diesel',
    hydrogenium: 'Wasserstoff',
    lpg: 'Autogas - LPG',
    other: 'Andere',
    petrol: 'Benzin',
  },
  emissionClass: {
    euro6c: 'EURO 6C',
    euro6d: 'EURO 6D',
    euro6d_temp: 'EURO 6D Temp',
    euro6: 'EURO 6',
    euro5: 'EURO 5',
    euro4: 'EURO 4',
  },
  bodyColor: {
    black: 'schwarz',
    white: 'weiss',
    grey: 'grau',
    beige: 'creme',
    brown: 'braun',
    red: 'rot',
    green: 'grün',
    blue: 'blau',
    purple: 'violet',
    gold: 'gold',
    orange: 'orange',
    silver: 'silber',
    yellow: 'gelb',
  },
  interiorColor: {
    black: 'schwarz',
    grey: 'grau',
    beige: 'beige',
    brown: 'braun',
    other: 'sonstige',
  },
  interiorType: {
    fabric: 'Stoff',
    leather: 'Leder',
    partial_leather: 'Teilleder',
    velour: 'Velour',
    alcantara: 'Alcantara',
    other: 'Andere',
  },
  sortBy: {
    recommended: 'heycar Ergebnisse',
    createdAt: 'Neueste Inserate',
    price: 'Preis',
    'make,model': 'Marke & Modell',
    mileage: 'Kilometerstand',
    firstRegistrationDate: 'Erstzulassung',
  },

  /*
        this is an empty object, we only need the dealer filter as key to enable dealer to be a generic filter
        without an actual UI representation of this filter
     */
  dealer: {},
  feature: {
    allWheelDrive: 'Allradantrieb',
    lightAlloyWheels: 'Alufelgen',
    trailerCoupling: 'Anhängerkupplung',
    adaptedForDisabled: 'Behindertengerecht',
    bluetooth: 'Bluetooth',
    cdPlayer: 'CD Spieler',
    roofRack: 'Dachreling',
    parkingAssistantCamera: 'Einparkhilfe Kamera',
    parkingAssistantRearSensors: 'Einparkhilfe Sensoren hinten',
    parkingAssistantFrontSensors: 'Einparkhilfe Sensoren vorne',
    parkingAssistantSelfSteeringSystem: 'Einparkhilfe selbstlenkend',
    electricExteriorMirrors: 'Elektrische Seitenspiegel',
    electricAdjustableSeats: 'Elektrische Sitzeinstellung',
    electricWindows: 'Elektrische Fensterheber',
    speakerphone: 'Freisprecheinrichtung',
    headUpDisplay: 'Head-up-Display',
    childSeatAttachment: 'ISOFIX',
    curveLights: 'Kurvenlicht',
    ledDaylightLights: 'LED-Tagfahrlicht',
    ledLights: 'LED-Scheinwerfer',
    lightSensor: 'Lichtsensor',
    airSuspension: 'Luftfederung',
    mp3Interface: 'MP3 Schnittstelle',
    multiFunctionSteeringWheel: 'Multifunktionslenkrad',
    navigationSystem: 'Navigationssystem',
    frontFogLights: 'Nebelscheinwerfer',
    panoramaRoof: 'Panoramadach',
    rainSensor: 'Regensensor',
    sunroof: 'Schiebedach',
    slidingDoor: 'Schiebetür',
    powerSteering: 'Servolenkung',
    seatHeating: 'Sitzheizung',
    skiBag: 'Skisack',
    performanceHandlingSystem: 'Sportfahrwerk',
    sportPackage: 'Sportpaket',
    sportSeats: 'Sportsitze',
    auxiliaryHeating: 'Standheizung',
    daytimeRunningLights: 'Tagfahrlicht',
    cruiseControl: 'Tempomat',
    immobiliser: 'Wegfahrsperre',
    xenonHeadlights: 'Xenonscheinwerfer',
    centralLockingSystem: 'Zentralverriegelung',
    heatedBackSeats: 'Sitzheizung hinten',
    ambientLight: 'Ambiente-Beleuchtung',
    startStopSystem: 'Start-Stop-Automatik',
    dualZoneAutomaticClimateControl: '2-Zonen-Klimaautomatik',
    electricTrunkDoor: 'Elektronische Heckklappe',
    electricallyAdjustableBackSeats: 'Elektronische Sitzeinstellung (hinten)',
    heatedSteeringWheel: 'Beheizbares Lenkrad',
    leatherSteeringWheel: 'Lederlenkrad',
    luggageCompartmentSeparation: 'Gepäckraumabtrennung',
    lumbarSupport: 'Lordosenstütze',
    paddleShiftGearbox: 'Schaltwippen',
    quadrupleZoneAutomaticClimateControl: '4-Zonen-Klimaautomatik',
    seatMassageSystem: 'Massagesitz',
    slidingDoorLeft: 'Schiebetür links',
    slidingDoorRight: 'Schiebetür rechts',
    slidingDoorsBothSides: 'Schiebetür beidseitig',
    smartphoneWirelessCharging: 'Induktionsladen für Smartphones',
    smokersPackage: 'Raucherpaket',
    soundSystem: 'Soundsystem',
    threesixtyParkingCamera: '360°-Kamera',
    tripleZoneAutomaticClimateControl: '3-Zonen-Klimaautomatik',
    usb: 'USB',
    ventilatedSeats: 'Sitzbelüftung',
    virtualCockpit: 'Volldigitales Kombiinstrument',
    voiceControlSystem: 'Sprachsteuerung',
    wlan: 'WLAN',
    abs: 'ABS',
    esp: 'ESP',
    adaptiveCruiseControl: 'Abstandstempomat',
    alarmSystem: 'Alarmanlage',
    allSeasonTires: 'Ganzjahresreifen',
    automaticInteriorMirror: 'Innenspiegel automatisch abbl.',
    biXenonLights: 'Bi-Xenon Scheinwerfer',
    deadAngleAssistant: 'Totwinkel-Assistent',
    demonstrationModel: 'Vorführwagen',
    emergencyBrakeAssist: 'Notbremsassistent',
    emergencyCallSystem: 'Notrufsystem',
    fatigueWarningSystem: 'Müdigkeitswarner',
    flatTireRepairKit: 'Pannenkit',
    frontPassengerIsofix: 'ISOFIX Beifahrer',
    headlightWasher: 'Scheinwerferreinigungsanlage',
    heatedWindshield: 'Beheizbare Frontscheibe',
    highBeamAssistant: 'Fernlichtassistent',
    hillStartAssist: 'Anfahrassistent',
    keylessGoSystem: 'Schlüssellose Zentralverriegelung',
    laneAssist: 'Spurhalteassistent',
    laserLights: 'Laserlicht Scheinwerfer',
    matrixLedSpotlights: 'Blendfreies Fernlicht',
    nightViewAssistant: 'Nachtsicht-Assistent',
    normalSpareTire: 'Reserverad',
    snowTires: 'Winterreifen',
    speedLimitSystem: 'Geschwindigkeitsbegrenzungsanlage',
    summerTires: 'Sommerreifen',
    temporarySpareTire: 'Notrad',
    tirePressureControlSystem: 'Reifendruckkontrollsystem',
    tractionControlSystem: 'Traktionskontrolle',
    trafficSignRecognitionSystem: 'Verkehrszeichenerkennung',
    winterPackage: 'Winterpaket',
    bordcomputer: 'Bordcomputer',
    tv: 'TV',
    tuner: 'Radio',
    androidAuto: 'Android Auto',
    appleCarplay: 'Apple Carplay',
    dabRadio: 'DAB Radio',
    musicStreaming: 'Musikstreaming integriert',
    touchscreen: 'Touchscreen',
    boughtElectricBattery: 'E-Fahrzeug Batterie (gekauft)',
    centralArmrest: 'Mittelarmlehne',
    companyCar: 'Firmenfahrzeug',
    dieselParticulateFilter: 'Dieselpartikelfilter',
    fixedTrailerHitch: 'Anhängerkupplung (starr)',
    foldingPassengerSeat: 'Umklappbarer Beifahrersitz',
    missingElectricBattery: 'E-Fahrzeug Batterie (fehlt)',
    rangeExtender: 'Range-Extender',
    removableTrailerHitch: 'Anhängerkupplung abnehmbar',
    rentedElectricBattery: 'E-Fahrzeug Batterie (gemietet)',
    retractableTrailerHitch: 'Anhängerkupplung schwenkbar',
    steelWheels: 'Stahlräder',
  },
  vehicleCondition: {
    oneYearOld: 'Jahreswagen',
    demonstrationModel: 'Vorführwagen',
    accidentFree: 'Unfallfrei',
    serviceBook: 'Scheckheftgepflegt',
    newCar: 'Neu auf heycar',
  },
  usedCarSeal: {
    1: 'Volkswagen Das WeltAuto',
    2: 'Audi Gebrauchtwagen :plus',
    5: 'Volkswagen TradePort',
    6: 'Mercedes Junge Sterne',
    13: 'Porsche Approved',
    14: 'Seat Das WeltAuto',
    15: 'Volvo Selekt',
    22: 'Hyundai Promise',
    41: 'Nissan Intelligent Choice',
    43: 'ŠKODA Plus',
  },
  airbags: {
    driver_airbag: 'Fahrer-Airbag',
    front_airbags: 'Front-Airbags',
    front_and_side_airbags: 'Front- und Seiten-Airbags',
    front_and_side_and_more_airbags: 'Front-, Seiten- und weitere Airbags',
  },
  climatisation: {
    none: 'Beliebig',
    manual_climatisation: 'Klimaanlage',
    automatic_climatisation: 'Klimaautomatik',
  },
};

export const categoryFilters = [
  {
    label: 'Limousine',
    value: 'limousine',
    tooltip: 'A4, Passat, 3er, C-Klasse',
    category: 'category',
  },
  {
    label: 'SUV',
    value: 'suv',
    tooltip: 'Audi Q-Serie, VW Touareg',
    category: 'category',
  },
  {
    label: 'Kombi',
    value: 'kombi',
    tooltip: 'A4 Avant, Passat Variant, Octavia Kombi',
    category: 'category',
  },
  {
    label: 'Cabrio',
    value: 'cabrio',
    tooltip: 'Beetle Cabriolet, TT Cabrio',
    category: 'category',
  },
  {
    label: 'Kleinwagen',
    value: 'kleinwagen',
    tooltip: 'A1, Polo, Ibiza, Citigo',
    category: 'category',
  },
  {
    label: 'Kompakt',
    value: 'kompaktklasse',
    tooltip: 'A3, Golf, Octavia',
    category: 'category',
  },
  {
    label: 'Van',
    value: 'van',
    tooltip: 'Touran, Sharan, Seat Alhambra',
    category: 'category',
  },
  {
    label: 'Transporter / Nutzfahrzeug',
    value: 'transportUtility',
    tooltip: 'Transporter, Caddy, Crafter, Vito',
    category: 'category',
  },
  {
    label: 'Sportwagen',
    value: 'sportscar',
    tooltip: '',
    category: 'category',
  },
];

export const RADIUS_VALUES = [
  { label: '200km', value: 200 },
  { label: '150km', value: 150 },
  { label: '100km', value: 100 },
  { label: '75km', value: 75 },
  { label: '50km', value: 50 },
  { label: '20km', value: 20 },
];

export const maxOwnersFilters = [
  {
    label: 'max. 1 Fahrzeughalter',
    value: '1',
  },
  {
    label: 'max. 2 Fahrzeughalter',
    value: '2',
  },
];

export const gearBoxFilters = [
  {
    label: 'Schaltgetriebe',
    value: 'manual_gear',
  },
  {
    label: 'Halbautomatik',
    value: 'semiautomatic_gear',
  },
  {
    label: 'Automatik',
    value: 'automatic_gear',
  },
];

export const interiorTypeFilters = [
  {
    label: 'Leder',
    value: 'leather',
  },
  {
    label: 'Teilleder',
    value: 'partial_leather',
  },
  {
    label: 'Stoff',
    value: 'fabric',
  },
  {
    label: 'Velour',
    value: 'velour',
  },
  {
    label: 'Alcantara',
    value: 'alcantara',
  },
  {
    label: 'Andere',
    value: 'other',
  },
];

export const vehicleConditionFilters = [
  { label: 'Unfallfrei', value: 'accidentFree' },
  {
    label: 'Jahreswagen',
    value: 'oneYearOld',
  },
  { label: 'Vorführwagen', value: 'demonstrationModel' },
  { label: 'Scheckheftgepflegt', value: 'serviceBook' },
  { label: 'Neu auf heycar', value: 'newCar' },
];

export const specialOffersFilters = (filterType) => {
  const currentFilter =
    filterType[0]?.value ||
    'DE_DEALER_CASH,DE_D2C_CASH,DE_INSTAMOTION_CASH,DE_DEALER_FINANCING_AC,DE_D2C_FINANCING_AC,DE_INSTAMOTION_FINANCING';

  return [
    {
      label: 'Preisreduziert',
      value: 'reducedPrice',
      category: 'specialOffers',
    },
    {
      label: 'Online-Kauf',
      value: currentFilter,
      category: 'productOffering',
    },
    {
      label: 'Neuwagen-Angebote',
      value: 'true',
      category: 'unusedCar',
    },
  ];
};

export const SpecialOnlineKaufFilter = (filterType) => {
  const currentFilter =
    filterType[0]?.value ||
    'DE_DEALER_CASH,DE_D2C_CASH,DE_INSTAMOTION_CASH,DE_DEALER_FINANCING_AC,DE_D2C_FINANCING_AC,DE_INSTAMOTION_FINANCING';

  return [
    {
      label: 'Online-Kauf',
      value: currentFilter,
      category: 'productOffering',
    },
  ];
};

export const singleDayRegistrationFilters = [
  {
    label: 'Nur Tageszulassungen',
    value: 'true',
  },
];

export const fuelTypeFilters = [
  {
    label: 'Benzin',
    value: 'petrol',
  },
  {
    label: 'Diesel',
    value: 'diesel',
  },
  {
    label: 'Gas',
    value: 'gas',
  },
  {
    label: 'Elektro',
    value: 'electric',
  },
  {
    label: 'Hybrid - Benzin',
    value: 'hybrid',
  },
  {
    label: 'Hybrid - Diesel',
    value: 'hybrid_diesel',
  },
  {
    label: 'Andere',
    value: 'other',
  },
];

export const emissionClassFilters = [
  {
    label: 'EURO 6C',
    value: 'euro6c',
  },
  {
    label: 'EURO 6D',
    value: 'euro6d',
  },
  {
    label: 'EURO 6D Temp',
    value: 'euro6d_temp',
  },
  {
    label: 'EURO 6',
    value: 'euro6',
  },
  {
    label: 'EURO 5',
    value: 'euro5',
  },
  {
    label: 'EURO 4',
    value: 'euro4',
  },
];

export const bodyColorFilters = [
  {
    label: 'schwarz',
    value: 'black',
    hex: '#000',
    tooltip: 'schwarz',
    category: 'bodyColor',
  },
  {
    label: 'grau',
    value: 'grey',
    hex: '#9b9b9b',
    tooltip: 'grau',
    category: 'bodyColor',
  },
  {
    label: 'silber',
    value: 'silver',
    hex: '#d8d8d8',
    tooltip: 'silber',
    category: 'bodyColor',
  },
  {
    label: 'weiss',
    value: 'white',
    hex: '#fff',
    tooltip: 'weiss',
    category: 'bodyColor',
  },
  {
    label: 'blau',
    value: 'blue',
    hex: '#11317e',
    tooltip: 'blau',
    category: 'bodyColor',
  },
  {
    label: 'grün',
    value: 'green',
    hex: '#007249',
    tooltip: 'grün',
    category: 'bodyColor',
  },
  {
    label: 'rot',
    value: 'red',
    hex: '#c8011a',
    tooltip: 'rot',
    category: 'bodyColor',
  },
  {
    label: 'orange',
    value: 'orange',
    hex: '#ef5a10',
    tooltip: 'orange',
    category: 'bodyColor',
  },
  {
    label: 'gelb',
    value: 'yellow',
    hex: '#ffd939',
    tooltip: 'gelb',
    category: 'bodyColor',
  },
  {
    label: 'violet',
    value: 'purple',
    hex: '#592e87',
    tooltip: 'violet',
    category: 'bodyColor',
  },
  {
    label: 'braun',
    value: 'brown',
    hex: '#6f452a',
    tooltip: 'braun',
    category: 'bodyColor',
  },
  {
    label: 'creme',
    value: 'beige',
    hex: '#f8ead3',
    tooltip: 'creme',
    category: 'bodyColor',
  },
];

export const usedCarSealFilters = [
  {
    label: 'Volkswagen Das WeltAuto',
    value: '1',
  },
  {
    label: 'Audi Gebrauchtwagen :plus',
    value: '2',
  },
  {
    label: 'Volkswagen TradePort',
    value: '5',
  },
  {
    label: 'Mercedes Junge Sterne',
    value: '6',
  },
  {
    label: 'Porsche Approved',
    value: '13',
  },
  {
    label: 'Seat Das WeltAuto',
    value: '14',
  },
  {
    label: 'Volvo Selekt',
    value: '15',
  },
  {
    label: 'Hyundai Promise',
    value: '22',
  },
  {
    label: 'Nissan Intelligent Choice',
    value: '41',
  },
  {
    label: 'ŠKODA Plus',
    value: '43',
  },
];

export const airbagsFilters = [
  {
    label: 'Fahrer-Airbag',
    value: 'driver_airbag',
  },
  {
    label: 'Front-Airbags',
    value: 'front_airbags',
  },
  {
    label: 'Front- und Seiten-Airbags',
    value: 'front_and_side_airbags',
  },
  {
    label: 'Front-, Seiten- und weitere Airbags',
    value: 'front_and_side_and_more_airbags',
  },
];

export const climatisationFilters = [
  {
    label: 'Beliebig',
    value: 'none',
  },
  {
    label: 'Klimaanlage',
    value: 'manual_climatisation',
  },
  {
    label: 'Klimaautomatik',
    value: 'automatic_climatisation',
  },
];

export const interiorColorFilters = [
  {
    label: 'schwarz',
    value: 'black',
    hex: '#000',
    tooltip: 'schwarz',
    category: 'interiorColor',
  },
  {
    label: 'grau',
    value: 'grey',
    hex: '#9b9b9b',
    tooltip: 'grau',
    category: 'interiorColor',
  },
  {
    label: 'beige',
    value: 'beige',
    hex: '#f8ead3',
    tooltip: 'beige',
    category: 'interiorColor',
  },
  {
    label: 'braun',
    value: 'brown',
    hex: '#6f452a',
    tooltip: 'braun',
    category: 'interiorColor',
  },
  {
    label: 'sonstige',
    value: 'other',
    image: '/assets/filter/otherInteriorColor.svg',
    selectedImage: '/assets/filter/otherInteriorColorSelected.svg',
    tooltip: 'sonstige',
    category: 'interiorColor',
  },
];

export const featureFilters = [
  { value: 'allWheelDrive', label: 'Allradantrieb' },
  { value: 'lightAlloyWheels', label: 'Alufelgen' },
  { value: 'trailerCoupling', label: 'Anhängerkupplung' },
  { value: 'adaptedForDisabled', label: 'Behindertengerecht' },
  { value: 'bluetooth', label: 'Bluetooth' },
  { value: 'bordcomputer', label: 'Bordcomputer' },
  { value: 'cdPlayer', label: 'CD Spieler' },
  { value: 'roofRack', label: 'Dachreling' },
  { value: 'parkingAssistantCamera', label: 'Einparkhilfe Kamera' },
  {
    value: 'parkingAssistantRearSensors',
    label: 'Einparkhilfe Sensoren hinten',
  },
  {
    value: 'parkingAssistantFrontSensors',
    label: 'Einparkhilfe Sensoren vorne',
  },
  {
    value: 'parkingAssistantSelfSteeringSystem',
    label: 'Einparkhilfe selbstlenkend',
  },
  { value: 'electricExteriorMirrors', label: 'Elektrische Seitenspiegel' },
  { value: 'electricAdjustableSeats', label: 'Elektrische Sitzeinstellung' },
  { value: 'electricWindows', label: 'Elektrische Fensterheber' },
  { value: 'speakerphone', label: 'Freisprecheinrichtung' },
  { value: 'headUpDisplay', label: 'Head-up-Display' },
  { value: 'childSeatAttachment', label: 'ISOFIX' },
  { value: 'curveLights', label: 'Kurvenlicht' },
  { value: 'manualClimatisation', label: 'Klimaanlage' },
  { value: 'automaticClimatisation', label: 'Klimaautomatik' },
  { value: 'ledDaylightLights', label: 'LED-Tagfahrlicht' },
  { value: 'ledLights', label: 'LED-Scheinwerfer' },
  { value: 'lightSensor', label: 'Lichtsensor' },
  { value: 'airSuspension', label: 'Luftfederung' },
  { value: 'mp3Interface', label: 'MP3 Schnittstelle' },
  { value: 'multiFunctionSteeringWheel', label: 'Multifunktionslenkrad' },
  { value: 'navigationSystem', label: 'Navigationssystem' },
  { value: 'frontFogLights', label: 'Nebelscheinwerfer' },
  { value: 'panoramaRoof', label: 'Panoramadach' },
  { value: 'rainSensor', label: 'Regensensor' },
  { value: 'sunroof', label: 'Schiebedach' },
  { value: 'slidingDoor', label: 'Schiebetür' },
  { value: 'powerSteering', label: 'Servolenkung' },
  { value: 'seatHeating', label: 'Sitzheizung' },
  { value: 'skiBag', label: 'Skisack' },
  { value: 'performanceHandlingSystem', label: 'Sportfahrwerk' },
  { value: 'sportPackage', label: 'Sportpaket' },
  { value: 'sportSeats', label: 'Sportsitze' },
  { value: 'auxiliaryHeating', label: 'Standheizung' },
  { value: 'daytimeRunningLights', label: 'Tagfahrlicht' },
  { value: 'cruiseControl', label: 'Tempomat' },
  { value: 'immobiliser', label: 'Wegfahrsperre' },
  { value: 'xenonHeadlights', label: 'Xenonscheinwerfer' },
  { value: 'centralLockingSystem', label: 'Zentralverriegelung' },
];

export const seatsFilters = [
  { label: '2', value: '2' },
  { label: '3', value: '3' },
  { label: '4', value: '4' },
  { label: '5', value: '5' },
  { label: '6', value: '6' },
  { label: '7', value: '7' },
  { label: '8', value: '8' },
  { label: '9', value: '9' },
];

export const doorsFilters = [
  { label: '2 bis 3', value: '2-3' },
  { label: '4 bis 5', value: '4-5' },
  { label: '6 bis 7', value: '6-7' },
];

export const monthlyLeasingRateOptions = [
  {
    label: '80 – 250€ / Monat',
    labelShort: '80 – 250€',
    value: '80-250',
  },
  {
    label: '250 – 400€ / Monat',
    labelShort: '250 – 400€',
    value: '250-400',
  },
  {
    label: '400 – 550€ / Monat',
    labelShort: '400 – 550€',
    value: '400-550',
  },
  {
    label: 'ab 550€ / Monat',
    labelShort: 'ab 550€',
    value: '550',
  },
];

//  When adding a new item, make sure that the alphabetical order (by label) is maintained
export const featureComfortFilters = [
  { value: 'dualZoneAutomaticClimateControl', label: '2-Zonen-Klimaautomatik' },
  {
    value: 'tripleZoneAutomaticClimateControl',
    label: '3-Zonen-Klimaautomatik',
  },
  { value: 'threesixtyParkingCamera', label: '360°-Kamera' },
  {
    value: 'quadrupleZoneAutomaticClimateControl',
    label: '4-Zonen-Klimaautomatik',
  },
  { value: 'allWheelDrive', label: 'Allradantrieb' },
  { value: 'ambientLight', label: 'Ambiente-Beleuchtung' },
  { value: 'heatedSteeringWheel', label: 'Beheizbares Lenkrad' },
  { value: 'roofRack', label: 'Dachreling' },
  { value: 'parkingAssistantCamera', label: 'Einparkhilfe Kamera' },
  {
    value: 'parkingAssistantSelfSteeringSystem',
    label: 'Einparkhilfe selbstlenkendes System',
  },
  {
    value: 'parkingAssistantRearSensors',
    label: 'Einparkhilfe Sensoren hinten',
  },
  {
    value: 'parkingAssistantFrontSensors',
    label: 'Einparkhilfe Sensoren vorne',
  },
  { value: 'electricWindows', label: 'Elektrische Fensterheber' },
  { value: 'electricExteriorMirrors', label: 'Elektrische Seitenspiegel' },
  { value: 'electricAdjustableSeats', label: 'Elektrische Sitzeinstellung' },
  { value: 'electricTrunkDoor', label: 'Elektronische Heckklappe' },
  {
    value: 'electricallyAdjustableBackSeats',
    label: 'Elektronische Sitzeinstellung (hinten)',
  },
  { value: 'luggageCompartmentSeparation', label: 'Gepäckraumabtrennung' },
  { value: 'headUpDisplay', label: 'Head-up-Display' },
  {
    value: 'smartphoneWirelessCharging',
    label: 'Induktionsladen für Smartphones',
  },
  { value: 'leatherSteeringWheel', label: 'Lederlenkrad' },
  { value: 'lightSensor', label: 'Lichtsensor' },
  { value: 'lumbarSupport', label: 'Lordosenstütze' },
  { value: 'airSuspension', label: 'Luftfederung' },
  { value: 'seatMassageSystem', label: 'Massagesitz' },
  { value: 'panoramaRoof', label: 'Panoramadach' },
  { value: 'smokersPackage', label: 'Raucherpaket' },
  { value: 'rainSensor', label: 'Regensensor' },
  { value: 'paddleShiftGearbox', label: 'Schaltwippen' },
  { value: 'sunroof', label: 'Schiebedach' },
  { value: 'slidingDoor', label: 'Schiebetür' },
  { value: 'slidingDoorsBothSides', label: 'Schiebetür beidseitig' },
  { value: 'slidingDoorLeft', label: 'Schiebetür links' },
  { value: 'slidingDoorRight', label: 'Schiebetür rechts' },
  { value: 'powerSteering', label: 'Servolenkung' },
  { value: 'ventilatedSeats', label: 'Sitzbelüftung' },
  { value: 'seatHeating', label: 'Sitzheizung' },
  { value: 'heatedBackSeats', label: 'Sitzheizung hinten' },
  { value: 'soundSystem', label: 'Soundsystem' },
  { value: 'voiceControlSystem', label: 'Sprachsteuerung' },
  { value: 'auxiliaryHeating', label: 'Standheizung' },
  { value: 'startStopSystem', label: 'Start-Stop-Automatik' },
  { value: 'cruiseControl', label: 'Tempomat' },
  { value: 'usb', label: 'USB' },
  { value: 'virtualCockpit', label: 'Volldigitales Kombiinstrument' },
  { value: 'wlan', label: 'WLAN' },
  { value: 'centralLockingSystem', label: 'Zentralverriegelung' },
];

//  When adding a new item, make sure that the alphabetical order (by label) is maintained
export const featureSafetyFilters = [
  { value: 'abs', label: 'ABS' },
  { value: 'adaptiveCruiseControl', label: 'Abstandstempomat' },
  { value: 'alarmSystem', label: 'Alarmanlage' },
  { value: 'hillStartAssist', label: 'Anfahrassistent' },
  { value: 'heatedWindshield', label: 'Beheizbare Frontscheibe' },
  { value: 'adaptedForDisabled', label: 'Behindertengerecht' },
  { value: 'biXenonLights', label: 'Bi-Xenon Scheinwerfer' },
  { value: 'matrixLedSpotlights', label: 'Blendfreies Fernlicht' },
  { value: 'esp', label: 'ESP' },
  { value: 'highBeamAssistant', label: 'Fernlichtassistent' },
  { value: 'allSeasonTires', label: 'Ganzjahresreifen' },
  { value: 'speedLimitSystem', label: 'Geschwindigkeitsbegrenzungsanlage' },
  { value: 'automaticInteriorMirror', label: 'Innenspiegel automatisch abbl.' },
  { value: 'childSeatAttachment', label: 'ISOFIX' },
  { value: 'frontPassengerIsofix', label: 'ISOFIX Beifahrer' },
  { value: 'curveLights', label: 'Kurvenlicht' },
  { value: 'laserLights', label: 'Laserlicht Scheinwerfer' },
  { value: 'ledLights', label: 'LED-Scheinwerfer' },
  { value: 'ledDaylightLights', label: 'LED-Tagfahrlicht' },
  { value: 'fatigueWarningSystem', label: 'Müdigkeitswarner' },
  { value: 'nightViewAssistant', label: 'Nachtsicht-Assistent' },
  { value: 'frontFogLights', label: 'Nebelscheinwerfer' },
  { value: 'emergencyBrakeAssist', label: 'Notbremsassistent' },
  { value: 'temporarySpareTire', label: 'Notrad' },
  { value: 'emergencyCallSystem', label: 'Notrufsystem' },
  { value: 'flatTireRepairKit', label: 'Pannenkit' },
  { value: 'tirePressureControlSystem', label: 'Reifendruckkontrollsystem' },
  { value: 'normalSpareTire', label: 'Reserverad' },
  { value: 'headlightWasher', label: 'Scheinwerferreinigungsanlage' },
  { value: 'keylessGoSystem', label: 'Schlüssellose Zentralverriegelung' },
  { value: 'summerTires', label: 'Sommerreifen' },
  { value: 'laneAssist', label: 'Spurhalteassistent' },
  { value: 'daytimeRunningLights', label: 'Tagfahrlicht' },
  { value: 'deadAngleAssistant', label: 'Totwinkel-Assistent' },
  { value: 'tractionControlSystem', label: 'Traktionskontrolle' },
  { value: 'trafficSignRecognitionSystem', label: 'Verkehrszeichenerkennung' },
  { value: 'demonstrationModel', label: 'Vorführwagen' },
  { value: 'immobiliser', label: 'Wegfahrsperre' },
  { value: 'winterPackage', label: 'Winterpaket' },
  { value: 'snowTires', label: 'Winterreifen' },
  { value: 'xenonHeadlights', label: 'Xenonscheinwerfer' },
];

//  When adding a new item, make sure that the alphabetical order (by label) is maintained
export const featureMultimediaFilters = [
  { value: 'androidAuto', label: 'Android Auto' },
  { value: 'appleCarplay', label: 'Apple Carplay' },
  { value: 'bluetooth', label: 'Bluetooth' },
  { value: 'bordcomputer', label: 'Bordcomputer' },
  { value: 'cdPlayer', label: 'CD' },
  { value: 'dabRadio', label: 'DAB Radio' },
  { value: 'speakerphone', label: 'Freisprechanlage' },
  { value: 'mp3Interface', label: 'MP3' },
  { value: 'multiFunctionSteeringWheel', label: 'Multifunktionslenkrad' },
  { value: 'musicStreaming', label: 'Musikstreaming integriert' },
  { value: 'navigationSystem', label: 'Navigationssystem' },
  { value: 'tuner', label: 'Radio' },
  { value: 'touchscreen', label: 'Touchscreen' },
  { value: 'tv', label: 'TV' },
];

//  When adding a new item, make sure that the alphabetical order (by label) is maintained
export const featureExtrasFilters = [
  { value: 'lightAlloyWheels', label: 'Alufelgen' },
  { value: 'trailerCoupling', label: 'Anhängerkupplung' },
  { value: 'fixedTrailerHitch', label: 'Anhängerkupplung (starr)' },
  { value: 'removableTrailerHitch', label: 'Anhängerkupplung abnehmbar' },
  { value: 'retractableTrailerHitch', label: 'Anhängerkupplung schwenkbar' },
  { value: 'missingElectricBattery', label: 'E-Fahrzeug Batterie (fehlt)' },
  { value: 'boughtElectricBattery', label: 'E-Fahrzeug Batterie (gekauft)' },
  { value: 'rentedElectricBattery', label: 'E-Fahrzeug Batterie (gemietet)' },
  { value: 'companyCar', label: 'Firmenfahrzeug' },
  { value: 'centralArmrest', label: 'Mittelarmlehne' },
  { value: 'dieselParticulateFilter', label: 'Partikelfilter' },
  { value: 'rangeExtender', label: 'Range-Extender' },
  { value: 'skiBag', label: 'Skisack' },
  { value: 'performanceHandlingSystem', label: 'Sportfahrwerk' },
  { value: 'sportPackage', label: 'Sportpaket' },
  { value: 'sportSeats', label: 'Sportsitze' },
  { value: 'steelWheels', label: 'Stahlräder' },
  { value: 'foldingPassengerSeat', label: 'Umklappbarer Beifahrersitz' },
];

const mileageSnapPoints = [];

export const getLocationRadiusRangeSnapPoints = (
  min = MIN_RADIUS,
  max = MAX_RADIUS,
  divider = MAX_RADIUS,
) => {
  const snapPoints = [];

  for (let i = min; i <= max; i += i >= divider ? 50 : 1) {
    snapPoints.push(i);
  }

  return snapPoints;
};

export const getPriceRangeSnapPoints = (min = 0, mid = 30000, max = 120000) => {
  const snapPoints = [];
  const step = 1000;

  for (let i = min; i <= max; i += i >= mid ? step : step / 2) {
    snapPoints.push(i);
  }

  return snapPoints;
};

export const getMonthlyPriceRangeSnapPoints = (
  min = 0,
  mid = 500,
  max = 1000,
) => {
  const snapPoints = [];
  const firstSnapPoint = 100;
  const interval = 100;

  /* Sets the value interval when the slider bullets are dragged.
   * From the slider's middle, different intervals are applied when
   * dragging left or right
   */

  /* eslint-disable no-nested-ternary */
  for (
    let i = min;
    i <= max;
    i += i < firstSnapPoint ? interval : i < mid ? interval / 4 : interval / 2
  ) {
    snapPoints.push(i);
  }

  return snapPoints;
};

export const getMileageRangeSnapPoints = () => {
  if (mileageSnapPoints.length > 0) {
    return mileageSnapPoints;
  }

  for (let i = 0; i <= 30000; i += 1000) {
    mileageSnapPoints.push(i);
  }

  for (let i = 32000; i <= 80000; i += 2000) {
    mileageSnapPoints.push(i);
  }

  for (let i = 85000; i <= 150000; i += 5000) {
    mileageSnapPoints.push(i);
  }

  return mileageSnapPoints;
};

import {
  getAdditionalVehicleTrackingParams,
  trackAction,
} from 'app/shared/utils/tracking';
import { createGUID } from 'app/shared/utils/utils';
import { tvTrackingPixelName } from 'app/marketplace/cookieDashboard/utils';
import { getAverageWebVitalsScores } from 'app/shared/modules/Campaign/utils';

import {
  generateAdditionalTrackingParams,
  LEAD_CREATION_EVENTS,
} from '../trackingUtils';

export const tvTrackingCallback = (vehicle) => {
  if (
    Array.isArray(window._adftrack) &&
    typeof window.loadTvTracking === 'function' &&
    typeof window.loadTvTrackingPixel === 'function'
  ) {
    window._adftrack.push({
      pm: 1230013,
      divider: encodeURIComponent('|'),
      pagename: encodeURIComponent('XM_HeyCar - Rückruf anfordern'),
      order: {
        itms: [
          {
            productname: vehicle.id,
            productsales: vehicle.price,
          },
        ],
      },
    });

    window.loadTvTracking();
    window.loadTvTrackingPixel(tvTrackingPixelName.callback);
  }
};

export const trackCallbackButton = (
  vehicle,
  callDealerEventId,
  target = 'PDP',
  eventLabel,
  oneClickLeadsCheckboxVisible = null,
) => {
  const payload = {
    category: target,
    description: 'user clicks callback button',
  };

  trackAction(
    'open_callback_modal',
    {
      ...payload,
      label: vehicle.id,
    },
    {
      vehicleId: vehicle.id,
      datetime: new Date().toISOString(),
      event_label: eventLabel,
      oneClickLeadsCheckboxVisible,
    },
  );
  trackAction(
    'marketing_contact_dealer',
    {
      ...payload,
      label: `callback:${vehicle.id}`,
    },
    {
      sessionId: callDealerEventId,
      eventId: callDealerEventId,
      item: [
        {
          product_id: `${vehicle.id}`,
          price: `${vehicle.price}`,
          value: `${vehicle.price}`,
          quantity: 1,
        },
      ],
      event_label: eventLabel,
      ...getAdditionalVehicleTrackingParams(vehicle),
    },
  );
};

export const trackCallbackSubmit = (
  target,
  vehicle,
  eventId,
  eventLabel,
  oneClickLeadsConsent = false,
  userFormData = null,
) => {
  const oneClickLeadsSelected = String(oneClickLeadsConsent);
  const webVitals = getAverageWebVitalsScores();
  trackAction(
    LEAD_CREATION_EVENTS.CLICKED_SUBMIT_CALLBACK,
    {
      category: `${target}`,
      label: `${vehicle?.id}`,
      eventId: createGUID(),
      order_id: createGUID(),
      description: 'user clicks on "Rückruf anfordern" on phone screen',
    },
    generateAdditionalTrackingParams(vehicle, {
      eventId,
      event_label: eventLabel,
      oneClickLeadsSelected,
      ...(webVitals && { webVitals }),
    }),
    false,
    userFormData,
  );

  tvTrackingCallback(vehicle);
};

export const trackCallbackSubmitFailRecaptcha = (vehicleId) => {
  trackAction('submit_callback_failure_recaptcha_low_score', {
    category: 'PDP',
    label: vehicleId,
    eventId: createGUID(),
    order_id: createGUID(),
    description: 'callback submission fails due to reCAPTCHA low score',
  });
};

export const trackCallbackSubmitRecaptchaV2 = (vehicleId) => {
  trackAction('interacted_recaptcha_challenge_callback', {
    category: 'PDP',
    label: vehicleId,
    eventId: createGUID(),
    order_id: createGUID(),
    description: 'user solves reCAPTCHA challenge',
  });
};

import { grid } from './variables';

export default `

/* ------------------------------------ *\
    MEDIA QUERIES AND BREAKPOINTS
 ------------------------------------ */

 .row {
    margin-right: ${grid.gutter.sm * -1}px;
    margin-left: ${grid.gutter.sm * -1}px;

  @media (min-width: 768px) {
    margin-right: ${grid.gutter.md * -1}px;
    margin-left: ${grid.gutter.md * -1}px;
  }

  &::before,
  &::after {
    display: table;
    content: " ";
  }

  &::after {
    clear: both;
  }
}

.row-reset {

  // Remove row pseudo classes to fix a flex order bug in Safari. For rows with display flex.
  &::before,
  &::after {
    display: none;
  }
}


.col {
  position: relative;
  display: block;
  width: 100%;
  min-height: 1px;
  padding-right: ${grid.gutter.sm}px;
  padding-left: ${grid.gutter.sm}px;
  float: left;
  /*
    These are the same value as in the previous breakpoint,
    comment in if the values of the following breakpoints change
  */ }
  @media (min-width: 768px) {
    .col {
      padding-right: ${grid.gutter.md}px;
      padding-left: ${grid.gutter.md}px; } }

@media (min-width: 320px) {
  .sm-1 {
    width: 8.33333%; }
  .sm-offset-1 {
    margin-left: 8.33333%; } }

@media (min-width: 320px) {
  .sm-2 {
    width: 16.66667%; }
  .sm-offset-2 {
    margin-left: 16.66667%; } }

@media (min-width: 320px) {
  .sm-3 {
    width: 25%; }
  .sm-offset-3 {
    margin-left: 25%; } }

@media (min-width: 320px) {
  .sm-4 {
    width: 33.33333%; }
  .sm-offset-4 {
    margin-left: 33.33333%; } }

@media (min-width: 320px) {
  .sm-5 {
    width: 41.66667%; }
  .sm-offset-5 {
    margin-left: 41.66667%; } }

@media (min-width: 320px) {
  .sm-6 {
    width: 50%; }
  .sm-offset-6 {
    margin-left: 50%; } }

@media (min-width: 320px) {
  .sm-7 {
    width: 58.33333%; }
  .sm-offset-7 {
    margin-left: 58.33333%; } }

@media (min-width: 320px) {
  .sm-8 {
    width: 66.66667%; }
  .sm-offset-8 {
    margin-left: 66.66667%; } }

@media (min-width: 320px) {
  .sm-9 {
    width: 75%; }
  .sm-offset-9 {
    margin-left: 75%; } }

@media (min-width: 320px) {
  .sm-10 {
    width: 83.33333%; }
  .sm-offset-10 {
    margin-left: 83.33333%; } }

@media (min-width: 320px) {
  .sm-11 {
    width: 91.66667%; }
  .sm-offset-11 {
    margin-left: 91.66667%; } }

@media (min-width: 320px) {
  .sm-12 {
    width: 100%; }
  .sm-offset-12 {
    margin-left: 100%; } }

@media (min-width: 768px) {
  .md-1 {
    width: 8.33333%; }
  .md-offset-1 {
    margin-left: 8.33333%; } }

@media (min-width: 768px) {
  .md-2 {
    width: 16.66667%; }
  .md-offset-2 {
    margin-left: 16.66667%; } }

@media (min-width: 768px) {
  .md-3 {
    width: 25%; }
  .md-offset-3 {
    margin-left: 25%; } }

@media (min-width: 768px) {
  .md-4 {
    width: 33.33333%; }
  .md-offset-4 {
    margin-left: 33.33333%; } }

@media (min-width: 768px) {
  .md-5 {
    width: 41.66667%; }
  .md-offset-5 {
    margin-left: 41.66667%; } }

@media (min-width: 768px) {
  .md-6 {
    width: 50%; }
  .md-offset-6 {
    margin-left: 50%; } }

@media (min-width: 768px) {
  .md-7 {
    width: 58.33333%; }
  .md-offset-7 {
    margin-left: 58.33333%; } }

@media (min-width: 768px) {
  .md-8 {
    width: 66.66667%; }
  .md-offset-8 {
    margin-left: 66.66667%; } }

@media (min-width: 768px) {
  .md-9 {
    width: 75%; }
  .md-offset-9 {
    margin-left: 75%; } }

@media (min-width: 768px) {
  .md-10 {
    width: 83.33333%; }
  .md-offset-10 {
    margin-left: 83.33333%; } }

@media (min-width: 768px) {
  .md-11 {
    width: 91.66667%; }
  .md-offset-11 {
    margin-left: 91.66667%; } }

@media (min-width: 768px) {
  .md-12 {
    width: 100%; }
  .md-offset-12 {
    margin-left: 100%; } }

@media (min-width: 1024px) {
  .lg-1 {
    width: 8.33333%; }
  .lg-offset-1 {
    margin-left: 8.33333%; } }

@media (min-width: 1024px) {
  .lg-2 {
    width: 16.66667%; }
  .lg-offset-2 {
    margin-left: 16.66667%; } }

@media (min-width: 1024px) {
  .lg-3 {
    width: 25%; }
  .lg-offset-3 {
    margin-left: 25%; } }

@media (min-width: 1024px) {
  .lg-4 {
    width: 33.33333%; }
  .lg-offset-4 {
    margin-left: 33.33333%; } }

@media (min-width: 1024px) {
  .lg-5 {
    width: 41.66667%; }
  .lg-offset-5 {
    margin-left: 41.66667%; } }

@media (min-width: 1024px) {
  .lg-6 {
    width: 50%; }
  .lg-offset-6 {
    margin-left: 50%; } }

@media (min-width: 1024px) {
  .lg-7 {
    width: 58.33333%; }
  .lg-offset-7 {
    margin-left: 58.33333%; } }

@media (min-width: 1024px) {
  .lg-8 {
    width: 66.66667%; }
  .lg-offset-8 {
    margin-left: 66.66667%; } }

@media (min-width: 1024px) {
  .lg-9 {
    width: 75%; }
  .lg-offset-9 {
    margin-left: 75%; } }

@media (min-width: 1024px) {
  .lg-10 {
    width: 83.33333%; }
  .lg-offset-10 {
    margin-left: 83.33333%; } }

@media (min-width: 1024px) {
  .lg-11 {
    width: 91.66667%; }
  .lg-offset-11 {
    margin-left: 91.66667%; } }

@media (min-width: 1024px) {
  .lg-12 {
    width: 100%; }
  .lg-offset-12 {
    margin-left: 100%; } }

@media (min-width: 1280px) {
  .xl-1 {
    width: 8.33333%; }
  .xl-offset-1 {
    margin-left: 8.33333%; } }

@media (min-width: 1280px) {
  .xl-2 {
    width: 16.66667%; }
  .xl-offset-2 {
    margin-left: 16.66667%; } }

@media (min-width: 1280px) {
  .xl-3 {
    width: 25%; }
  .xl-offset-3 {
    margin-left: 25%; } }

@media (min-width: 1280px) {
  .xl-4 {
    width: 33.33333%; }
  .xl-offset-4 {
    margin-left: 33.33333%; } }

@media (min-width: 1280px) {
  .xl-5 {
    width: 41.66667%; }
  .xl-offset-5 {
    margin-left: 41.66667%; } }

@media (min-width: 1280px) {
  .xl-6 {
    width: 50%; }
  .xl-offset-6 {
    margin-left: 50%; } }

@media (min-width: 1280px) {
  .xl-7 {
    width: 58.33333%; }
  .xl-offset-7 {
    margin-left: 58.33333%; } }

@media (min-width: 1280px) {
  .xl-8 {
    width: 66.66667%; }
  .xl-offset-8 {
    margin-left: 66.66667%; } }

@media (min-width: 1280px) {
  .xl-9 {
    width: 75%; }
  .xl-offset-9 {
    margin-left: 75%; } }

@media (min-width: 1280px) {
  .xl-10 {
    width: 83.33333%; }
  .xl-offset-10 {
    margin-left: 83.33333%; } }

@media (min-width: 1280px) {
  .xl-11 {
    width: 91.66667%; }
  .xl-offset-11 {
    margin-left: 91.66667%; } }

@media (min-width: 1280px) {
  .xl-12 {
    width: 100%; }
  .xl-offset-12 {
    margin-left: 100%; } }
`;

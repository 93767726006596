/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/prop-types */
import React from 'react';

import { RouterLinkSt } from './Link.css';
import { ILinkProps } from './Link.types';

export const Link = ({
  children,
  title,
  color = 'brandPrimaryDark',
  hoverColor = 'brandPrimary',
  textDecoration = 'underline',
  fontWeight = 'bold',
  useRouter = true,
  to = '',
  href = '',
  ...rest
}: ILinkProps): JSX.Element => {
  if (!useRouter) {
    return (
      <RouterLinkSt
        as="a"
        href={to || href}
        hoverColor={hoverColor}
        title={title}
        color={color}
        fontWeight={fontWeight}
        useRouter={useRouter}
        textDecoration={textDecoration}
        {...rest}
      >
        {children}
      </RouterLinkSt>
    );
  }

  return (
    <RouterLinkSt
      to={to}
      hoverColor={hoverColor}
      title={title}
      color={color}
      fontWeight={fontWeight}
      useRouter={useRouter}
      textDecoration={textDecoration}
      {...rest}
    >
      {children}
    </RouterLinkSt>
  );
};

import { takeEvery, all, fork, put, select, call } from 'redux-saga/effects';

import * as ContactDealerActionTypes from 'app/marketplace/contactDealer/constants';
import { ActionTypes as AccountActionTypes } from 'app/marketplace/accounts/constants';
import { UPDATE_COOKIE_STATUS } from 'app/marketplace/cookieDashboard/constants';
import { watchSubmitCallback } from 'app/marketplace/contactDealer/Callback/sagas';
import { watchGetPhoneNumber } from 'app/marketplace/contactDealer/Call/sagas';
import {
  watchSubmitAutobild,
  watchSubmitMailOrVideo,
  watchSuccessfullMailOrVideoSubmit,
} from 'app/marketplace/contactDealer/Mail/sagas';

/* istanbul ignore next */
export const leadInfo = (state) => state.contactDealer;
/* istanbul ignore next */
export const functionalCookiesPreference = (state) =>
  state.cookieDashboard.preferences.functional;
/**
 * Stores lead form data in redux for future use.
 * @param {Object} form: user info data
 */
export function* storeLeadForm(form) {
  const hasAgreedToStore = yield select(functionalCookiesPreference);
  if (hasAgreedToStore) {
    yield put({
      type: ContactDealerActionTypes.STORE_LEAD_FORM,
      payload: form,
    });
  }
}
/**
 * Resets user information in case they change their cookies preferences.
 * @param {Object} dispatchedAction: contains action name and payload
 */
export function* resetContactDetails({ action, payload }) {
  if (action !== UPDATE_COOKIE_STATUS || payload.contactDetails === false) {
    yield put({ type: ContactDealerActionTypes.RESET_LEAD_FORM });
  }
}

/*
 * Resets contactDealer store slice and stores values in contactDetails cookie
 * reducer.
 */
export function* migrateContactDetails() {
  const {
    firstName,
    lastName,
    email,
    phoneNumber,
    hasTradeInCar,
    brandModel,
    kilometer,
    registration,
  } = yield select(leadInfo);
  yield call(storeLeadForm, {
    ...(firstName && { firstName }),
    ...(lastName && { lastName }),
    ...(email && { email }),
    ...(phoneNumber && { phoneNumber }),
    ...(hasTradeInCar && { hasTradeInCar }),
    ...(brandModel && { brandModel }),
    ...(kilometer && { kilometer }),
    ...(registration && { registration }),
  });
  yield put({ type: ContactDealerActionTypes.REMOVE_CONTACT_DETAILS_SUCCESS });
}

/* --------- Watchers --------- */
export function* watchAuthCookieChanges() {
  yield takeEvery(
    [
      AccountActionTypes.LOGOUT,
      AccountActionTypes.LOGIN_COMPLETED,
      UPDATE_COOKIE_STATUS,
    ],
    resetContactDetails,
  );
}

export function* contactDealerSagas() {
  // istanbul ignore next
  yield all([
    fork(watchAuthCookieChanges),
    fork(watchSubmitCallback),
    fork(watchGetPhoneNumber),
    fork(watchSubmitMailOrVideo),
    fork(watchSubmitAutobild),
    fork(watchSuccessfullMailOrVideoSubmit),
  ]);
}

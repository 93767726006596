/*
 * Make the store globally accessible
 */
export const getStore = () => {
  if (typeof CLIENT !== 'undefined' && typeof window !== 'undefined') {
    return window.store;
  }

  if (typeof SERVER !== 'undefined') {
    return global.store;
  }

  /* eslint-disable-next-line */
  console.error(
    'WARNING: tried to access store globally, but not sure if i"m client or server!'
  );
  return global.store;
};

// the client-side store is set on the window object in client/index.js
export const setServerStore = store => {
  global.store = store;
};

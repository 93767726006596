import { Route } from 'react-router';

import { getAsyncRouteComponentLoader } from 'app/shared/utils/utils';

import { ConnectedPrintableVoucherCode } from './ConnectedPrintableVoucherCode';

export const CampaignPrintableRoutes = (
  <Route
    name="CampaignPrintable"
    component={(props) => <ConnectedPrintableVoucherCode {...props} />}
  >
    <Route
      path="/voucher"
      getComponent={getAsyncRouteComponentLoader(
        'ConnectedPrintableVoucherCode',
        () => import('./ConnectedPrintableVoucherCode'),
      )}
    />
  </Route>
);

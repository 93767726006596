import { Box } from '@rebass/grid';
import styled, { css } from 'styled-components';

interface ILogoSt {
  x: number;
  y: number;
  size: number;
  image: string;
  isVisible: boolean;
}

export const BoxSt = styled(Box)`
  height: 56px;
  &[data-tooltip] {
    position: relative;
    cursor: pointer;
  }
  &[data-tooltip]:before,
  &[data-tooltip]:after {
    line-height: 1;
    font-size: 12px;
    pointer-events: none;
    position: absolute;
    box-sizing: border-box;
    display: none;
    opacity: 0;
  }
  &[data-tooltip]:before {
    content: '';
    border: 5px solid transparent;
    z-index: 100;
  }
  &[data-tooltip]:after {
    content: attr(data-tooltip);
    text-align: center;
    min-width: 100px;
    max-width: 140px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding: 12px 18px;
    border-radius: 4px;
    background: ${({ theme }) => theme.colors.brandSecondary};
    color: ${({ theme }) => theme.colors.white};
    z-index: 99;
  }
  &[data-tooltip]:hover:before,
  &[data-tooltip]:hover:after {
    display: block;
    opacity: 1;
  }
  &[data-tooltip]:not([data-flow])::before,
  &[data-tooltip][data-flow='top']::before {
    bottom: 100%;
    border-bottom-width: 0;
    border-top-color: ${({ theme }) => theme.colors.brandSecondary};
  }
  &[data-tooltip]:not([data-flow])::after,
  &[data-tooltip][data-flow='top']::after {
    bottom: calc(100% + 5px);
  }
  &[data-tooltip]:not([data-flow])::before,
  &[tooltip]:not([data-flow])::after,
  &[data-tooltip][data-flow='top']::before,
  &[data-tooltip][data-flow='top']::after {
    left: 50%;
    -webkit-transform: translate(-50%, -10px);
    transform: translate(-50%, -10px);
  }
  &[data-tooltip='']::after,
  &[data-tooltip='']::before {
    display: none !important;
  }
`;

export const LogoSt = styled.div<ILogoSt>`
  background-position: ${({ x }) => x}px ${({ y }) => y}px;
  ${({ size }) => css`
    width: ${size}px;
    height: ${size}px;
  `};
  ${({ isVisible, image }) =>
    isVisible &&
    css`
      background-image: url('${image}');
      transform: scale3d(0.5, 0.5, 1) translate3d(-56px, -56px, 0px);
    `}
`;

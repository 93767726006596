import { fork, all, takeEvery, put, select } from 'redux-saga/effects';

import { closeOverlay } from 'app/shared/modules/overlay/actions';
import { isSidebarPreOpenSelector } from 'app/marketplace/search/selectors';

export const selectItWasNotReplaceAction = ({ routing }) => {
  return routing.locationBeforeTransitions.action !== 'REPLACE';
};

export const selectHasOpenedOverlay = ({ overlay }) =>
  !!overlay.activeOverlayComponent;

//  When the user goes back on a page, the overlay should be closed
export function* handleCloseOverlay() {
  const hasOpenedOverlay = yield select(selectHasOpenedOverlay);
  const shouldFilterSidebarPreOpen = yield select(isSidebarPreOpenSelector);
  const itWasNotReplaceAction = yield select(selectItWasNotReplaceAction);

  if (
    hasOpenedOverlay &&
    !shouldFilterSidebarPreOpen &&
    itWasNotReplaceAction
  ) {
    yield put(closeOverlay());
  }
}
function* closeTheOverlay() {
  yield takeEvery('@@router/LOCATION_CHANGE', handleCloseOverlay);
}

export function* overlayRules() {
  yield all([fork(closeTheOverlay)]);
}

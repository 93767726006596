import styled, { css } from 'styled-components';
import { Flex } from '@rebass/grid';

import { Link } from '../../../Link/Link';
import { Text } from '../../../Typo/Typo';
import { media } from '../../../variables/media';
import { GridContainer } from '../../../GridContainer';

export const BrandsContainer = styled(Flex)`
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  grid-column: 1 / span 12;

  ${media.sm(css`
    justify-content: center;
  `)}

  @media (min-width: 321px) and (max-width: 360px) {
    justify-content: space-around;

    div:nth-child(10),
    div:nth-child(20) {
      margin-right: 0;
    }
  }

  ${media.md(css`
    div:nth-child(18) {
      margin-right: 0;
    }
    div:nth-child(10) {
      margin-right: 14px;
    }
    div:nth-child(n + 20) {
      display: none;
    }
  `)}

  ${media.lg(css`
    justify-content: center;
    div:nth-child(14) {
      margin-right: 0;
    }
    div:nth-child(n + 16) {
      display: none;
    }

    div:nth-child(10),
    div:nth-child(20),
    div:nth-child(18) {
      margin-right: 24px;
    }

    grid-column: 3 / span 7;
  `)}

  ${media.xl(css`
    div:nth-child(10),
    div:nth-child(14),
    div:nth-child(18) {
      margin-right: 16px;
    }

    div:nth-child(n + 16) {
      display: block;
    }

    grid-column: 3 / span 8;
  `)}
`;

export const LabelSt = styled(Text)`
  display: flex;
  align-items: center;
  justify-content: center;
  grid-column: 1 / span 12;
  font-weight: 800;

  ${media.lg(css`
    justify-content: flex-start;
    grid-column: 1 / span 2;
  `)}
`;

export const LinkSt = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: center;
  grid-column: 1 / span 12;

  ${media.lg(css`
    justify-content: flex-end;
    grid-column: 10 / span 3;
  `)}

  ${media.xl(css`
    justify-content: flex-end;
    grid-column: 11 / span 2;
  `)}
`;

export const GridContainerSt = styled(GridContainer)`
  position: relative;
  z-index: 1;
  width: 100%;
  padding: 24px 28px;
  border-top: 1px solid ${({ theme }) => theme.colors.grayLight};

  @media all and (max-width: 1024px) {
    max-width: calc(100% - 48px);
  }

  ${media.lg(css`
    padding: 24px 0;
    -webkit-box-shadow: 0px -9px 22px 22px ${({ theme }) => theme.colors.white};
    box-shadow: 0px -9px 22px 22px ${({ theme }) => theme.colors.white};
  `)}
`;

import styled from 'styled-components';

export const CartileImageWrapperSt = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  overflow: hidden;
  border-radius: inherit;
`;

export const CartileImageSt = styled.img`
  display: block;
  width: 100%;
  height: auto;
  padding-bottom: 0;
  margin: 0 auto;
`;

import styled, { css } from 'styled-components';
import { Button, Heading4 } from 'app/shared/ui';

import { media, zIndex } from 'app/shared/styles_js/variables';
import { SpecialOfferTile } from 'app/marketplace/specialOffers/SpecialOfferTile/SpecialOfferTile';
import { SpecialOfferTeaserCard } from 'app/marketplace/specialOffers/SpecialOfferTeaserCard/SpecialOfferTeaserCard';

export const SpecialOffersOuterContainerSt = styled.div`
  padding-top: 48px;
  padding-bottom: 72px;
  margin-top: 40px;
  background-color: ${({ theme: { colors } }) => colors.sunbeanSuperLight};

  ${media.md(
    css`
      margin-top: 0;
      padding-top: 56px;
      padding-bottom: 56px;
    `,
  )};
`;

export const SpecialOffersContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-wrap: nowrap;

  & > a {
    flex: 1;

    ${media.md(css`
      display: flex;
      flex: 0 0 calc(25% - 32px);
      max-width: 340px;
      max-height: 350px;
      margin: 0 16px;
    `)};
  }

  ${media.md(css`
    flex-direction: row;
    margin: 0 -15px;
  `)};
`;

export const SpecialOfferTileSt = styled(SpecialOfferTile)`
  margin-bottom: 34px;

  ${media.md(css`
    margin: 0 15px;
  `)}
`;

export const SpecialOfferTeaserCardSt = styled(SpecialOfferTeaserCard)`
  height: 100%;
  margin-bottom: 34px;

  ${media.md(css`
    flex: 1 1 0%;
    margin-bottom: 0;
  `)}
`;

export const TitleSt = styled(Heading4).attrs(() => ({
  fontSize: [24, 28, 32],
  lineHeight: [1.67, 1.5],
  fontWeight: 800,
  mb: [24, null, null, 32],
}))`
  z-index: ${zIndex.homeBenefitsTitle};
`;

export const ButtonSt = styled(Button).attrs({
  variant: 'primary',
})`
  display: block;
  width: 100%;
  margin: auto;

  ${media.md(css`
    width: auto;
    margin-top: 39px;
  `)}
`;

import PropTypes from 'prop-types';

import {
  getCookiesContainingKey,
  getCookiesNotContainingKey,
  deleteCookie,
} from 'app/shared/utils/utils';
import {
  DAT_SEARCH_TOKEN,
  VALUATED_VEHICLE_DETAIL,
} from 'app/shared/modules/FDL/tradein/redux/sagas';
import { resetSubmit } from 'app/marketplace/SlideInBanners/CrmBanner/redux/actions';

export const tvTrackingPixelName = {
  global: 'XM_heycar%20Pixel',
  call: 'XM_HeyCar%20-%20Anrufen',
  email: 'XM_HeyCar%20-%20E-Mail',
  callback: 'XM_HeyCar%20-%20Rueckruf%20anfordern',
};

export const settingsType = {
  essential: 'essential',
  functional: 'functional',
  analytical: 'analytical',
  marketing: 'marketing',
};

export const cookieTypes = Object.keys(settingsType).filter(
  (setting) => setting !== settingsType.essential,
);

export const defaultCookieConsentState = () => {
  let cookieDashboard = [];
  let cookieDashboardObj;
  let preferences = {};
  let hasSavedCookieSettings = false;
  if (typeof document !== 'undefined') {
    cookieDashboard = getCookiesContainingKey(
      document.cookie,
      'persist%3AcookieDashboard',
    );
  }

  if (cookieDashboard.length > 0) {
    cookieDashboardObj = JSON.parse(
      decodeURIComponent(cookieDashboard[0]['persist%3AcookieDashboard']),
    );
  }

  if (
    cookieDashboardObj?.preferences &&
    cookieDashboardObj?.hasSavedCookieSettings
  ) {
    preferences = JSON.parse(
      decodeURIComponent(cookieDashboardObj.preferences),
    );
    hasSavedCookieSettings = cookieDashboardObj.hasSavedCookieSettings;

    return {
      preferences,
      hasSavedCookieSettings,
    };
  }

  const defaultState = cookieTypes.reduce(
    (obj, key) => Object.assign(obj, { [key]: false }),
    {},
  );

  return {
    hasSavedCookieSettings: false,
    preferences: { ...defaultState, essential: true },
  };
};

const cookiePropTypes = cookieTypes.reduce(
  (obj, key) => Object.assign(obj, { [key]: PropTypes.bool.isRequired }),
  {},
);

export const CookieConsentShape = PropTypes.shape(cookiePropTypes);

const removeCookies = (cookieObjectArray) => {
  cookieObjectArray.map((cookieObject) => {
    const key = Object.keys(cookieObject)[0];

    window.document.cookie = `${key}=; expires=Thu, 01 Jan 1970 00:00:01 GMT;'`;
    return true;
  });
};

const removeAllNonHeyCarCookies = () => {
  const removableCookies = getCookiesNotContainingKey(
    document.cookie,
    'persist',
    false,
  );

  removeCookies(removableCookies);
};

const updateCallback = {
  essential: {
    on() {
      if (typeof window.loadSegment === 'function' && !window.segmentLoaded) {
        window.loadSegment();
        window.segmentLoaded = true;
      }
    },
    off() {},
  },
  functional: {
    on() {},
    off() {
      // Delete tradein cookies
      deleteCookie(DAT_SEARCH_TOKEN, '/');
      deleteCookie(VALUATED_VEHICLE_DETAIL, '/');
      // Delete Slide-in cookies on uncheck
      // eslint-disable-next-line no-undef
      store.dispatch(resetSubmit());
      deleteCookie('slideIn');
    },
  },
  analytical: {
    on() {},
    off() {},
  },
  marketing: {
    on() {
      if (
        typeof window.loadTvTracking === 'function' &&
        !window.tvTrackingLoaded
      ) {
        window.loadTvTracking();
        window.tvTrackingLoaded = true;
      }
      if (
        typeof window.loadTvTrackingPixel === 'function' &&
        !window.tvTrackingPixelLoaded
      ) {
        window.loadTvTrackingPixel(tvTrackingPixelName.global);
        window.tvTrackingPixelLoaded = true;
      }
    },
    off() {
      removeAllNonHeyCarCookies();
    },
  },
};

export const handleCookiesForCookieState = (cookiePreferences) => {
  const isPartnerRoute = window?.location.pathname.indexOf('/partners') !== -1;

  // eslint-disable-next-line no-restricted-syntax
  for (const cookieType in cookiePreferences) {
    if (Object.hasOwnProperty.call(updateCallback, cookieType)) {
      if (cookiePreferences[cookieType] && !isPartnerRoute) {
        updateCallback[cookieType].on();
      } else {
        updateCallback[cookieType].off();
      }
    }
  }
};

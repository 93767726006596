/* eslint-disable react/jsx-no-undef */
import { memo, useState } from 'react';

import { extractYTVideoId } from 'app/marketplace/vehicle/ImageGallery/utils';
import { trackAction } from 'app/shared/utils/tracking';

import {
  ImageWrapperSt,
  PlayIconSt,
  YoutubeEmbedWrapperSt,
} from './YoutubeEmbed.css';

const YoutubeEmbedComp = ({
  videoUrl,
  trackingCategory = 'home',
}: {
  videoUrl: string;
  trackingCategory?: string;
}): JSX.Element => {
  const [play, setPlay] = useState(false);
  const embedId = extractYTVideoId(videoUrl);
  const thumbImage = `https://img.youtube.com/vi/${embedId}/sddefault.jpg`;

  const handleOnClick = () => {
    setPlay(true);
    trackAction('video_start_click', {
      category: trackingCategory,
      label: 'clicked on video play button',
    });
  };

  if (!embedId) {
    return <ImageWrapperSt bgImage="/assets/home/video-fallback@2x.jpg" />;
  }

  return play ? (
    <YoutubeEmbedWrapperSt>
      <iframe
        width="853"
        height="480"
        src={`https://www.youtube.com/embed/${embedId}?autoplay=1&rel=0`}
        frameBorder="0"
        allow="autoplay; encrypted-media;"
        allowFullScreen
        title="youtube video"
      />
    </YoutubeEmbedWrapperSt>
  ) : (
    <ImageWrapperSt onClick={handleOnClick} bgImage={thumbImage}>
      <PlayIconSt src="/assets/icon_play.png" alt="Abspiel-Symbol" />
    </ImageWrapperSt>
  );
};

export const YoutubeEmbed = memo(YoutubeEmbedComp);

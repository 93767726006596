/* istanbul ignore file */
import styled from 'styled-components';
import { Button, Heading4 } from 'app/shared/ui';

import { zIndex } from 'app/shared/styles_js/variables';

export const GridContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  gap: 16px;
  overflow: hidden;
`;

export const ButtonSt = styled(Button).attrs({
  variant: 'primary',
})`
  display: block;
  width: 100%;
  margin-top: 32px;
`;

export const TitleSt = styled(Heading4).attrs(() => ({
  as: 'h2',
  fontSize: [24, 28, 32],
  lineHeight: [1.67, 1.5],
  fontWeight: 800,
  mb: [24, 0, 0, 0],
}))`
  z-index: ${zIndex.homeBenefitsTitle};
`;

import styled, { css } from 'styled-components';

import { ContainerSt } from 'app/shared/ui';
import { media } from 'app/shared/styles_js/variables';

export const OnlineKaufTeaserSt = styled(ContainerSt)`
  margin-top: 32px;
  ${media.md(css`
    margin-top: 56px;
    margin-bottom: 56px;
  `)}
`;

import styled, { keyframes } from 'styled-components';
import { Button, variables } from 'app/shared/ui';

const { color } = variables;

const fadeIn = keyframes`
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
`;

export const LabelSt = styled.div`
  margin-top: 24px;
  color: ${color.grayDark};
`;

export const ContainerSt = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 8px;
  margin-bottom: 24px;

  animation: ${fadeIn} 400ms ease-in-out;
`;

export const ButtonSt = styled(Button).attrs(() => ({ unstyled: true }))`
  outline: 0;
  background: ${color.grayLightest};
  text-align: center;
  font-size: 10px;
  flex-basis: calc(50% - 4px);
  height: 32px;
  min-width: auto;
  margin-bottom: 8px;
  color: ${
    /* istanbul ignore next */ ({ active }) => active && color.brandPrimary
  };

  &:hover {
    color: ${color.brandPrimary};
  }

  &:focus {
    color: ${color.brandPrimary};
    outline: 0;
  }
`;

import { memo, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash.isempty';
import { useInView } from 'react-intersection-observer';

import { IndeterminateLoadingSpinner } from 'app/shared/ui';
import {
  ErrorSt,
  TitleSt,
  HeaderSt,
  CarouselSt,
  NavArrowSt,
  MoreLikeThisSt,
  ListingWrapperSt,
  LoadingContainerSt,
} from 'app/marketplace/moreLikeThisListings/MoreLikeThisListingsCarousel/MoreLikeThisListingsCarousel.css';
import { isFavourite } from 'app/shared/utils/vehicle';
import { trackAction } from 'app/shared/utils/tracking';
import { isMobileViewport, noop } from 'app/shared/utils/utils';
import { GetCartileData } from 'app/shared/modules/Cartile/CartileWebApp';
import { MLT_OPTIONS } from 'app/marketplace/moreLikeThisListings/MoreLikeThisListings/MoreLikeThisListings';

const defaultVehicleListSizeMap = {
  sm: 1,
  md: 2,
  lg: 3,
  xl: 3,
};

export const handleTrackingUserViewMLT = (
  value,
  category = 'PDP',
  isCommerceMLT,
) => {
  trackAction('recommendations_viewed', {
    value,
    category,
    description: 'User scroll to view the MLT section',
    label: isCommerceMLT ? 'commerce_leasing_make_model' : '',
  });
};

export const MoreLikeThisListingsCarouselCmp = ({
  error,
  isLoading,
  vehicleId,
  breakpoint,
  favouriteIds,
  toggleFavourite,
  performanceUnit,
  currentLocation,
  budgetRangeValue,
  vehicleDataSource,
  moreLikeThisListings,
  loadMoreLikeThisCarousel,
}) => {
  const [ref, inView] = useInView({ triggerOnce: true, threshold: 0.3 });
  const [refCommerce, inViewCommerce] = useInView({
    triggerOnce: true,
    threshold: 0.3,
  });
  const [vehicles, setVehicles] = useState([]);
  const isMobile = isMobileViewport(breakpoint);
  const slidesToShow = defaultVehicleListSizeMap[breakpoint];
  const isResultsLessThanSlidesToShow = vehicles?.length <= slidesToShow;
  const isD2CVehicle =
    vehicleDataSource === 'GWC_D2C' || vehicleDataSource === 'INSTAMOTION';
  const showCommerceRecommendations = vehicleDataSource === 'commerce';

  const getCurrentSearch = () => {
    let search = '';
    switch (vehicleDataSource) {
      case 'GWC_D2C':
        search = MLT_OPTIONS.d2c;
        break;
      case 'INSTAMOTION':
        search = MLT_OPTIONS.instamotion;
        break;
      case 'commerce':
        search = MLT_OPTIONS.commerce;
        break;
      case 'electric':
        search = MLT_OPTIONS.electric;
        break;
      default:
        search = MLT_OPTIONS.default;
    }
    return search;
  };

  const handleScrollClickTrackAction = (direction) => {
    const commerceString = showCommerceRecommendations ? 'commerce' : '';

    trackAction(`pdp_mlt_carousel_click_scroll_${direction}`, {
      category: 'PDP',
      label: `scroll ${direction} on ${commerceString} mlt carousel clicked`,
      value: direction,
      description: `user clicked scroll ${direction} on the ${commerceString} mlt pdp carousel`,
    });
  };

  useEffect(() => {
    if (inView && !isD2CVehicle) {
      const listIds =
        Array.isArray(vehicles) && vehicles.map((vehicle) => vehicle.id);
      handleTrackingUserViewMLT(listIds);
    }
  }, [inView]);

  useEffect(() => {
    if (inViewCommerce && showCommerceRecommendations) {
      const listIds =
        Array.isArray(vehicles) && vehicles.map((vehicle) => vehicle.id);
      handleTrackingUserViewMLT(listIds, 'PDP', true);
    }
  }, [inViewCommerce]);

  useEffect(() => {
    setVehicles(moreLikeThisListings[getCurrentSearch()] || []);
  }, [moreLikeThisListings]);

  // load MLT in initial load
  useEffect(() => {
    if (vehicleId) {
      loadMoreLikeThisCarousel(vehicleId, getCurrentSearch());
    } else {
      // Using vehicleId zero means to load for all vehicles
      loadMoreLikeThisCarousel(0, getCurrentSearch());
    }
  }, []);

  const additionalTrackingProperties = {
    event: 'pdp_mlt_carousel_cartile_click',
    description: 'user click on a car tile on the mlt PDP carousel',
  };

  const getCarousel = () => (
    <CarouselSt
      isMobile={isMobile}
      hasOneResult={vehicles.length === 1}
      slidesToShow={
        isResultsLessThanSlidesToShow ? vehicles.length : slidesToShow
      }
      slidesToScroll={slidesToShow}
      isCarouselReady
      centerMode={isMobile}
      centerPadding={isMobile ? '16px' : '12px'}
      showArrows={!isMobile}
      prevArrow={
        <NavArrowSt
          direction="left"
          trackAction={handleScrollClickTrackAction}
        />
      }
      nextArrow={
        <NavArrowSt
          direction="right"
          trackAction={handleScrollClickTrackAction}
        />
      }
      isResultsLessThanSlidesToShow={isResultsLessThanSlidesToShow}
      lazyLoad
    >
      {vehicles.map((vehicle, index) => {
        const { CartileContainer, props } = GetCartileData({
          index,
          vehicle,
          isLoading,
          breakpoint,
          width: [1],
          target: 'MLT',
          isLazy: false,
          performanceUnit,
          budgetRangeValue,
          toggleFavourite,
          isHorizontal: false,
          additionalTrackingProperties,
          isFavourite: isFavourite(favouriteIds, vehicle.id),
          currentLocation: isEmpty(currentLocation) ? null : currentLocation,
        });
        return (
          <div key={vehicle.id}>
            <CartileContainer {...props} />
          </div>
        );
      })}
    </CarouselSt>
  );

  // eslint-disable-next-line react/no-multi-comp
  const getSpinner = () => (
    <LoadingContainerSt>
      <IndeterminateLoadingSpinner />
    </LoadingContainerSt>
  );

  // eslint-disable-next-line react/no-multi-comp
  const getTitle = () => {
    if (showCommerceRecommendations) {
      return 'Online-Kauf';
    }

    if (vehicleDataSource === 'electric') {
      return 'Ausgewählte E-Autos auf heycar';
    }

    return 'Weitere ähnliche Fahrzeuge';
  };
  return (
    !(showCommerceRecommendations && isEmpty(vehicles)) && (
      <MoreLikeThisSt
        id={`mlt${vehicleDataSource}`}
        vehicleDataSource={vehicleDataSource}
        ref={showCommerceRecommendations ? refCommerce : ref}
        smallTopSpacing={showCommerceRecommendations}
      >
        <ListingWrapperSt>
          <HeaderSt isMobile={isMobile}>
            <TitleSt>{getTitle()}</TitleSt>
          </HeaderSt>
          {(isLoading || isEmpty(vehicles)) &&
            !error &&
            !isMobile &&
            getSpinner()}
          {!isLoading &&
            !isEmpty(vehicles) &&
            !isMobile &&
            getCarousel({
              vehicles,
              isLoading,
              performanceUnit,
              currentLocation,
              budgetRangeValue,
              additionalTrackingProperties,
            })}
          {error && isEmpty(vehicles) && !isMobile && (
            <ErrorSt>
              Dieser Händler hat derzeit keine weiteren Fahrzeuge im Angebot.
            </ErrorSt>
          )}
        </ListingWrapperSt>
        {(isLoading || isEmpty(vehicles)) && !error && isMobile && getSpinner()}
        {!isLoading &&
          !isEmpty(vehicles) &&
          isMobile &&
          getCarousel({
            vehicles,
            isLoading,
            performanceUnit,
            currentLocation,
            budgetRangeValue,
            additionalTrackingProperties,
          })}
        {error && isEmpty(vehicles) && isMobile && (
          <ErrorSt>
            Dieser Händler hat derzeit keine weiteren Fahrzeuge im Angebot.
          </ErrorSt>
        )}
      </MoreLikeThisSt>
    )
  );
};

MoreLikeThisListingsCarouselCmp.propTypes = {
  error: PropTypes.string,
  isLoading: PropTypes.bool,
  favouriteIds: PropTypes.array,
  toggleFavourite: PropTypes.func,
  vehicleId: PropTypes.string.isRequired,
  breakpoint: PropTypes.string.isRequired,
  currentLocation: PropTypes.object.isRequired,
  performanceUnit: PropTypes.string.isRequired,
  budgetRangeValue: PropTypes.string.isRequired,
  vehicleDataSource: PropTypes.string.isRequired,
  moreLikeThisListings: PropTypes.object.isRequired,
  loadMoreLikeThisCarousel: PropTypes.func.isRequired,
};

MoreLikeThisListingsCarouselCmp.defaultProps = {
  error: '',
  isLoading: false,
  favouriteIds: [],
  toggleFavourite: noop,
};

export const MoreLikeThisListingsCarousel = memo(
  MoreLikeThisListingsCarouselCmp,
);

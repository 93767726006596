/* eslint-disable react/jsx-props-no-spreading */
/* istanbul ignore file */
import { ContainerSt } from 'app/shared/ui';

import ScrollSnapCarousel from 'app/marketplace/specialOffers/ScrollSnapCarousel/ScrollSnapCarousel';
import { PromotionTile } from 'app/marketplace/shared/components/PromotionTile/PromotionTile';
import { GridContainer } from 'app/marketplace/specialOffers/SpecialOffersHome/SpecialOffersHomeNew.css';
import { TBreakpoint } from 'app/types/style.types';
import { promotionTiles } from 'app/marketplace/contentful/pages/SpecialOffersPage/SpecialOffersContainer/promotionTiles';
import { trackAction } from 'app/shared/utils/tracking';

import {
  SpecialOffersNavigationSt,
  TitleSt,
} from './SpecialOffersNavigation.css';

export const itemsToShow = {
  md: 3,
  lg: 4,
  xl: 5,
};

export const itemsToScroll = {
  sm: 1,
  md: itemsToShow.md,
  lg: itemsToShow.lg,
  xl: itemsToShow.xl,
};

export const gapPerBreakpoint = {
  sm: 17.75,
  md: 24,
  lg: 18,
  xl: 24,
};

export default function SpecialOffersNavigation({
  breakpoint,
}: {
  breakpoint: TBreakpoint;
}): JSX.Element {
  const isMobile = breakpoint === 'sm';

  const handleNextArrowClick = () =>
    trackAction('deals_menu_new_car_tile_right_arrow_click');

  const handlePreviousArrowClick = () =>
    trackAction('deals_menu_new_car_tile_left_arrow_click');

  if (isMobile) {
    return (
      <SpecialOffersNavigationSt>
        <TitleSt>Deals und Specials bei heycar</TitleSt>
        <GridContainer>
          {promotionTiles.map(({ id, trackingData, ...rest }) => (
            <PromotionTile
              key={`promotionTile_${id}`}
              {...rest}
              trackingData={{
                name: `deals_and_specials_car_tile${id}_click`,
                value: trackingData.value,
                label: 'Deals and specials on home page',
                additionalProperties: {
                  banner_source: 'new_car_tile_design_test',
                  page: 'deals_menu_hover',
                },
              }}
              useRouter={false}
              tileDimension="calc((100vw - 42px - 24px) / 2)"
            />
          ))}
        </GridContainer>
      </SpecialOffersNavigationSt>
    );
  }

  const showArrows = promotionTiles.length > itemsToShow[breakpoint];
  const tileDimension = 204;

  return (
    <SpecialOffersNavigationSt>
      <ContainerSt>
        <ScrollSnapCarousel
          itemsGap={gapPerBreakpoint[breakpoint]}
          itemsToScroll={itemsToScroll[breakpoint]}
          itemsWidth={tileDimension}
          showArrows={showArrows}
          title={<TitleSt>Deals und Specials bei heycar</TitleSt>}
          nextArrowClickCallback={handleNextArrowClick}
          prevArrowClickCallback={handlePreviousArrowClick}
        >
          {promotionTiles.map(({ id, trackingData, ...rest }) => (
            <PromotionTile
              key={`promotionTile_${id}`}
              {...rest}
              trackingData={{
                name: `deals_and_specials_car_tile${id}_click`,
                value: trackingData.value,
                label: 'Deals and specials on home page',
                additionalProperties: {
                  banner_source: 'new_car_tile_design_test',
                  page: 'deals_menu_hover',
                },
              }}
              useRouter={false}
              tileDimension={`${tileDimension}px`}
            />
          ))}
        </ScrollSnapCarousel>
      </ContainerSt>
    </SpecialOffersNavigationSt>
  );
}

/**
 * formats dates in a format like '23. Dezember 2022'
 */
export function formatDate(
  value: string | Date,
  options?: Intl.DateTimeFormatOptions,
): string {
  if (!value) return '';

  return new Intl.DateTimeFormat('de-DE', {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
    ...options,
  }).format(new Date(value));
}

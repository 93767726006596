import { defaultState } from '../../reducer';

import {
  API_LOADING,
  API_ERROR,
  SET_DATA,
  SET_SELECTED_VALUE,
  UNSET_CALCULATION_FOR_VEHICLE,
  CHANGE_HAPPENED,
  UPDATE_CALCULATIONS,
  RESET_IS_CHANGED,
  SHOW_RESET_MESSAGE,
} from './constants';

/**
 * Financing calculator's redux state
 */
export const reducer = (state = defaultState, action = {}) => {
  switch (action.type) {
    // Puts calculator on loading state to get values from API
    case API_LOADING:
      return { ...state, loading: true, error: false, errorMsg: null };

    case API_ERROR:
      return {
        ...state,
        loading: false,
        error: true,
        errorMsg: action.message,
      };

    case SHOW_RESET_MESSAGE:
      return {
        ...state,
        loading: false,
        error: false,
        errorMsg: null,
        isResetToPrecalc: action.value,
      };

    // Updates calculator's selected/altered option/value to make new API request
    case SET_SELECTED_VALUE:
      return {
        ...state,
        isChanged: !!action?.isUserInteraction,
        sections: state.sections.map((section) => ({
          ...section,
          components: section.components.map((component) => {
            if (component.id === action.item.id) {
              switch (component.type) {
                case 'numberInput':
                  return { ...component, value: action.value };
                case 'singleSlider':
                case 'singleSelect':
                  return {
                    ...component,
                    options: component.options.map((option) => {
                      if (option.value === action.value.toString()) {
                        return { ...option, selected: true };
                      }
                      return { ...option, selected: false };
                    }),
                  };
                case 'multiSelect':
                  return {
                    ...component,
                    options: component.options.map((option) => {
                      if (action.value.includes(option.value)) {
                        return { ...option, selected: true };
                      }
                      return { ...option, selected: false };
                    }),
                  };
                default:
                  return component;
              }
            }
            return component;
          }),
        })),
      };

    // Sets initial values or updates calculator values from API response
    // If the user has interacted with the calculator, isChange is applied to true to use it to send track actions or prevent the unnecessary API calls
    case SET_DATA:
      return {
        ...state,
        ...action.payload,
        isChanged: !!action?.isUserInteraction,
        selectedOption: action?.payload?.searchFilters?.financingProduct,
        loading: false,
        error: false,
        errorMsg: null,
      };

    case UPDATE_CALCULATIONS:
      return {
        ...state,
        calculations: [
          ...state.calculations.filter((item) => item.id !== action.payload.id),
          action.payload,
        ],
      };

    case UNSET_CALCULATION_FOR_VEHICLE:
      return {
        ...state,
        calculations: state.calculations.filter(
          (calculation) => calculation.id !== action.payload.id,
        ),
      };

    case CHANGE_HAPPENED:
      return { ...state, isChanged: true };

    case RESET_IS_CHANGED:
      return { ...state, isChanged: false };

    default:
      return state;
  }
};

/* istanbul ignore file */
import styled, { css } from 'styled-components';
import { Flex } from '@rebass/grid';

import { media } from 'app/shared/styles_js/variables';

export const NavContactSt = styled(Flex)`
  z-index: 1;
  height: 100%;
  align-items: center;
  justify-content: flex-end;
  grid-column: 10 / span 3;
  color: ${({ theme: { colors } }) => colors.brandSecondaryLight};

  ${media.md(css`
    grid-column: 8 / span 8;
  `)};

  .help-text {
    color: inherit;
    margin-right: 1rem;
    ${media.sm(
      css`
        display: none;
      `,
    )}
    ${media.md(
      css`
        display: block;
      `,
    )}
  }

  .tel {
    margin-left: 0.25rem;
    color: inherit;
  }
`;

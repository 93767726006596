/* eslint-disable @typescript-eslint/no-unsafe-call */
import React from 'react';

import { NavItemCategoryProps } from '../../NavItem.types';

import { NavItemCategoryTitle } from './components/NavItemCategoryTitle/NavItemCategoryTitle';
import { NavItemCategoryElement } from './components/NavItemCategoryElement/NavItemCategoryElement';
import { NavItemCategoryWrapperSt } from './NavItemCategory.css';

export const NavItemCategory = ({
  item,
  index,
  setSelectedItem,
  setTracking,
  closeMenu,
}: NavItemCategoryProps): JSX.Element => {
  const { title, elements } = item;

  return (
    <NavItemCategoryWrapperSt
      py={['16px', '12px', 0]}
      mb={[0, 0, '40px']}
      key={`item-${index}`}
    >
      <NavItemCategoryTitle>{title}</NavItemCategoryTitle>
      {elements.map(
        (
          { url: elementUrl, label: elementLabel, useRouter = false, onClick },
          elementIndex,
        ) => (
          <NavItemCategoryElement
            key={`element-${elementIndex}`}
            setSelectedItem={setSelectedItem}
            to={elementUrl}
            useRouter={useRouter}
            elementLabel={elementLabel}
            closeMenu={closeMenu}
            setTracking={setTracking}
            onClick={onClick}
          >
            {elementLabel}
          </NavItemCategoryElement>
        ),
      )}
    </NavItemCategoryWrapperSt>
  );
};

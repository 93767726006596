import PropTypes from 'prop-types';

export const imageFieldShape = PropTypes.shape({
  fields: PropTypes.shape({
    file: PropTypes.shape({ url: PropTypes.string }),
  }),
});

export const trackingDataShape = PropTypes.shape({
  fields: PropTypes.shape({
    name: PropTypes.string,
    label: PropTypes.string,
    category: PropTypes.string,
    description: PropTypes.string,
  }),
});

export const trackingDataDefaultProps = {
  fields: {
    name: '',
    label: '',
    category: '',
    description: '',
  },
};

const contentShape = {
  data: PropTypes.shape({ uri: PropTypes.string }),
  marks: PropTypes.array,
  value: PropTypes.string,
  nodeType: PropTypes.string,
};
contentShape.content = PropTypes.arrayOf(PropTypes.shape(contentShape));

export const richTextShape = PropTypes.shape({
  content: PropTypes.arrayOf(
    PropTypes.shape({
      nodeType: PropTypes.string,
      content: contentShape.content,
    }),
  ),
});

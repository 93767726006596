/* istanbul ignore file */
/* eslint-disable react/no-danger */
import {
  TileCarImageSt,
  TileContainerSt,
  TileTitleSt,
  TileSubtitleSt,
} from 'app/marketplace/shared/components/PromotionTile/PromotionTile.css';
import { IPromotionTileProps } from 'app/marketplace/shared/components/PromotionTile/Types/Promotion.types';
import { trackAction } from 'app/shared/utils/tracking';

export const PromotionTile = ({
  clickHandler,
  title,
  titleColor = 'inherit',
  titleMarkBackground = 'transparent',
  titleMarkColor = 'inherit',
  href,
  imageUrl,
  subtitle,
  subtitleColor = titleColor,
  subtitleMarkBackground = 'transparent',
  subtitleMarkColor = 'inherit',
  tileBackground = 'transparent',
  tileDimension = '142px',
  trackingData = {},
  ...restProps
}: IPromotionTileProps): JSX.Element => {
  const { name, label, value, additionalProperties } = trackingData;

  // To avoid any line break for the words that include dashes
  // See: https://www.htmlsymbols.xyz/unicode/U+2060
  const finalTitle = title.replace(/-/g, '-\u2060');
  const finalSubtitle = subtitle.replace(/-/g, '-\u2060');

  return (
    <TileContainerSt
      to={href}
      onClick={() => {
        if (name)
          trackAction(name, { label, value }, { ...additionalProperties });
        if (clickHandler) clickHandler();
      }}
      $tileBackground={tileBackground}
      $tileDimension={tileDimension}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...restProps}
    >
      <TileTitleSt
        $titleColor={titleColor}
        $titleMarkColor={titleMarkColor}
        $titleMarkBackground={titleMarkBackground}
        $tileDimension={tileDimension}
      >
        <p dangerouslySetInnerHTML={{ __html: finalTitle }} />
      </TileTitleSt>
      <TileCarImageSt>
        <img
          src={imageUrl}
          alt={finalTitle}
          width="184px"
          height="82px"
          loading="lazy"
        />
      </TileCarImageSt>
      <TileSubtitleSt
        $subtitleColor={subtitleColor}
        $subtitleMarkColor={subtitleMarkColor}
        $subtitleMarkBackground={subtitleMarkBackground}
        $tileDimension={tileDimension}
      >
        <p
          dangerouslySetInnerHTML={{
            __html: finalSubtitle,
          }}
        />
      </TileSubtitleSt>
    </TileContainerSt>
  );
};

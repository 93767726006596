/* istanbul ignore file */
import { useState, useEffect } from 'react';

export const useCustomTargetingByFilters = (
  selectedFilters: {
    category: string;
    direction?: string;
    label: string;
    operator: string;
    parent?: string;
    uuid: string;
    value: string;
  }[],
): {
  customTargeting: { [key: string]: string | string[] };
  markerIds: string;
} => {
  const [customTargeting, setCustomTargeting] = useState({});
  const [markerIds, setMarkerIds] = useState('');

  useEffect(() => {
    if (Array.isArray(selectedFilters) && selectedFilters.length > 0) {
      const purgedCustomTargeting = selectedFilters.reduce(
        (acc, { category, value }) => {
          if (acc[category] !== undefined) {
            if (!Array.isArray(acc[category])) {
              acc[category] = [acc[category]];
            }

            // eslint-disable-next-line @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-member-access
            acc[category].push(value);

            return { ...acc };
          }

          return { ...acc, [category]: value };
        },
        {},
      );

      setCustomTargeting(purgedCustomTargeting);

      const markerIdFilterValues = selectedFilters
        .filter((filter) => filter.category === 'markerId')
        .map((selectedFilter) => selectedFilter.value)
        .join(',');

      setMarkerIds(markerIdFilterValues);
    } else {
      setCustomTargeting({});
      setMarkerIds('');
    }
  }, [JSON.stringify(selectedFilters)]);

  return {
    customTargeting,
    markerIds,
  };
};

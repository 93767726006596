import { memo } from 'react';
import PropTypes from 'prop-types';

import { CartileLinkContainerSt } from 'app/shared/modules/Cartile/CartileTemplates/CartileLinkContainer/CartileLinkContainer.css';

const CartileLinkContainerCmp = ({
  children,
  isUnavailable,
  hasRemoveIcon,
}) => {
  if (isUnavailable || hasRemoveIcon) {
    return (
      <CartileLinkContainerSt isUnavailable={isUnavailable}>
        {children}
      </CartileLinkContainerSt>
    );
  }
  return null;
};

CartileLinkContainerCmp.propTypes = {
  children: PropTypes.node,
  isUnavailable: PropTypes.bool,
  hasRemoveIcon: PropTypes.bool,
};

CartileLinkContainerCmp.defaultProps = {
  children: null,
  isUnavailable: false,
  hasRemoveIcon: false,
};

export const CartileLinkContainer = memo(CartileLinkContainerCmp);

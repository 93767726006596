/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { connect } from 'react-redux';

// eslint-disable-next-line import/no-extraneous-dependencies
import {
  ICampaign,
  TCampaignData,
} from 'app/shared/modules/Campaign/Campaign.types';
import { getFavoriteIds } from 'app/marketplace/favourites/selectors';
import { contentfulCampaignsSelector } from 'app/marketplace/contentful/selectors';
import { IVehicle } from 'app/types/entities/Vehicle.types';
import { TBreakpoint } from 'app/types/style.types';

import { breakpointSelector } from '../../globalEvents/selectors';
import { selectCampaignData } from '../../Campaign/selectors';

import { PageTemplate } from './PageTemplate';

export interface IStateProps {
  isLoading: boolean;
  hasOverlay: boolean;
  favouriteIds: string[];
  campaigns: ICampaign[];
  vehicle: IVehicle;
  breakpoint: TBreakpoint;
  campaignData: TCampaignData;
}

const mapStateToProps = (state: TRootState): IStateProps => {
  return {
    favouriteIds: getFavoriteIds(state),
    campaigns: contentfulCampaignsSelector(state),
    campaignData: selectCampaignData(state),
    isLoading: state.pageTemplate.loadingNextComponent,
    hasOverlay: !!state.overlay.activeOverlayComponent,
    vehicle: state.vehicle.vehicle,
    breakpoint: breakpointSelector(state),
  };
};

export const ConnectedPageTemplate = connect<IStateProps>(
  mapStateToProps,
  {},
)(PageTemplate);

/* eslint-disable promise/always-return,consistent-return */
import { apiCall } from 'app/actions';
import { isFavourite, isCarInComparison } from 'app/shared/utils/vehicle';
import { getAccessToken } from 'app/marketplace/accounts/selectors';
import { ajaxCallWIthToken } from 'app/marketplace/accounts/actions';
import { fetchApi } from 'app/marketplace/accounts/service';
import { showNotificationIndicator } from 'app/shared/modules/user/actions';
import {
  getFavoriteIds,
  getLocalFavoriteIds,
} from 'app/marketplace/favourites/selectors';
import {
  ActionTypes,
  FAVS_PER_PAGE,
} from 'app/marketplace/favourites/constants';
import {
  addCarToComparison,
  removeCarFromComparison,
} from 'app/marketplace/carComparison/actions';
import { vehiclesIDInCarComparisonSelector } from 'app/marketplace/carComparison/selectors';
// ___________________________________________________________
// |                                                          |
// |     Get the Favourite IDs to show status in Cartile      |
// |__________________________________________________________|

export const getFavouriteVehicleIds = () => {
  return (dispatch, getState) => {
    const state = getState();
    const isUserLoggedIn = getAccessToken(state);
    if (isUserLoggedIn) {
      const url = '/profiles/me/favourites';

      dispatch({ type: ActionTypes.GET_FAVOURITE_IDS_STARTED });

      return dispatch(ajaxCallWIthToken(url, { method: 'GET' }, true, true))
        .then((payload) => {
          return dispatch({
            type: ActionTypes.GET_FAVOURITE_IDS_COMPLETED,
            payload,
          });
        })
        .catch((error) => {
          return dispatch({
            type: ActionTypes.GET_FAVOURITE_IDS_FAILED,
            error,
          });
        });
    }
    return Promise.resolve();
  };
};

// ___________________________________________________________
// |                                                          |
// |     Get the Vehicles when landing on Favourite page      |
// |__________________________________________________________|

export const getFavouriteVehicles = (pageNumber = 0) => {
  return (dispatch, getState) => {
    const state = getState();
    const localFavouriteIds = getLocalFavoriteIds(state);
    const favouriteIds = getFavoriteIds(state);

    dispatch({ type: ActionTypes.GET_FAVOURITE_VEHICLES_STARTED });

    if (localFavouriteIds.length === 0 && favouriteIds.length === 0) {
      dispatch({
        type: ActionTypes.GET_FAVOURITE_VEHICLES_COMPLETED,
        payload: { content: [] },
      });
      return Promise.resolve();
    }

    const totalPages = Math.ceil(favouriteIds.length / FAVS_PER_PAGE);
    const startIndex = pageNumber * FAVS_PER_PAGE;
    const endIndex = startIndex + FAVS_PER_PAGE;
    let ids = favouriteIds.slice(startIndex, endIndex);
    // extract the id from favouriteIds if its an object or simply use the incoming string
    ids = ids.map((id) =>
      typeof id === 'object' ? /* istanbul ignore next */ id?.id : id,
    );
    const url = `/search?id=${ids}`;

    const aggregateById = (accumulator, item) => {
      accumulator[item.id] = item;
      return accumulator;
    };

    return dispatch(apiCall(url))
      .then((result) => {
        const contentMap = result.content.reduce(aggregateById, {});
        const content = ids.map(
          (id) =>
            contentMap[id] || /* istanbul ignore next */ {
              id,
              state: 'REJECTED',
              live: false,
            },
        ); // put result in correct order

        // Reset car comparison to get the updated favourites
        dispatch({
          type: 'RESET_CAR_COMPARISON',
        });
        const vehiclesForComparison = content.filter((vehicle) => vehicle.live);
        dispatch(addCarToComparison(vehiclesForComparison));

        return dispatch({
          type: ActionTypes.GET_FAVOURITE_VEHICLES_COMPLETED,
          payload: { content, totalPages, pageable: { pageNumber } },
        });
      })
      .catch((error) => {
        return dispatch({
          type: ActionTypes.GET_FAVOURITE_VEHICLES_FAILED,
          error,
        });
      });
  };
};

export const subscribeToFavourites = (subscriptionEmail, vehicleId = null) => {
  return async (dispatch, getState) => {
    const favouriteIds = vehicleId ? [vehicleId] : getFavoriteIds(getState());
    const url = '/listings-subscriptions/subscribe';
    const options = {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      method: 'POST',
      body: JSON.stringify({
        emailId: subscriptionEmail,
        subscriptionType: 'PRICE_DROP',
        notificationChannel: 'EMAIL',
        listingIds: favouriteIds,
      }),
    };
    try {
      const { userProfileId } = await fetchApi(url, options, true);
      return dispatch({
        type: ActionTypes.SUBSCRIBE_TO_FAVOURITES_COMPLETED,
        payload: { subscriptionEmail, userProfileId },
      });
    } catch (error) {
      dispatch({
        type: ActionTypes.SUBSCRIBE_TO_FAVOURITES_FAILED,
        error,
      });
      return Promise.reject(new Error(error));
    }
  };
};

export const unsubscribeToFavourite = (
  subscriptionUserProfileId,
  vehicleId,
) => {
  return async (dispatch) => {
    const params = `?userProfileId=${subscriptionUserProfileId}&listingIds=${vehicleId}`;
    const url = `/listings-subscriptions/unsubscribe/partial${params}`;
    const options = {
      method: 'POST',
    };

    try {
      await fetchApi(url, options, true);
      return dispatch({
        type: ActionTypes.UNSUBSCRIBE_TO_FAVOURITE_COMPLETED,
        payload: { subscriptionUserProfileId },
      });
    } catch (error) {
      dispatch({
        type: ActionTypes.UNSUBSCRIBE_TO_FAVOURITE_FAILED,
        error,
      });
      return Promise.reject(new Error(error));
    }
  };
};

export const handleSubcriptionWatchprice = (userProfileId, isUnSubscribe) => {
  return async (dispatch) => {
    const subUrl = `${isUnSubscribe ? 'unsubscribe' : 'activate'}`;
    const url = `/listings-subscriptions/${subUrl}/${userProfileId}`;
    const options = {
      method: 'POST',
    };
    try {
      await fetchApi(url, options, true);
      // Reset email to show the banner again on favourites page
      if (isUnSubscribe) {
        dispatch({
          type: ActionTypes.RESET_WATCH_PRICE_EMAIL_COMPLETED,
        });
      }
      return Promise.resolve({ message: 'success' });
    } catch (error) {
      return Promise.reject(new Error(error));
    }
  };
};

export const toggleFavourite = (vehicle) => {
  const vehicleId = vehicle.id;

  return (dispatch, getState) => {
    const state = getState();
    const isUserLoggedIn = getAccessToken(state);
    const isVehicleFavourite = isFavourite(getFavoriteIds(state), vehicleId);
    const comparisonIds = vehiclesIDInCarComparisonSelector(state);
    const isInComparison = isCarInComparison(comparisonIds, vehicleId);
    dispatch({
      type: ActionTypes.TOGGLE_SAVED_VEHICLE_STARTED,
      payload: { vehicle },
    });
    const { subscriptionEmail, subscriptionUserProfileId } = state.favourites;

    if (isUserLoggedIn) {
      const url = '/profiles/me/favourites';
      let options;

      if (!isVehicleFavourite) {
        // add favourite
        options = {
          method: 'POST',
          body: JSON.stringify([vehicleId]),
        };

        return dispatch(ajaxCallWIthToken(url, options, true, true))
          .then(() => {
            dispatch({
              type: ActionTypes.TOGGLE_SAVED_VEHICLE_COMPLETED,
              payload: { vehicleId: [vehicleId] },
            });
            dispatch(addCarToComparison([vehicle]));
            dispatch(showNotificationIndicator());
            if (subscriptionEmail) {
              dispatch(subscribeToFavourites(subscriptionEmail, vehicleId));
            }

            setTimeout(() => {
              dispatch({ type: 'RESET_CURRENT_FAV_VEHICLE' });
            }, 800);
          })
          .catch((error) => {
            dispatch({ type: ActionTypes.TOGGLE_SAVED_VEHICLE_FAILED, error });
          });
      }
      // delete favourite
      options = {
        method: 'DELETE',
        body: JSON.stringify(vehicleId),
      };

      return dispatch(ajaxCallWIthToken(url, options, true, true))
        .then(() => {
          dispatch({
            type: ActionTypes.TOGGLE_DELETED_VEHICLE_COMPLETED,
            payload: { vehicleId },
          });
          if (isInComparison) {
            dispatch(removeCarFromComparison({ id: vehicleId }));
          }
          if (subscriptionUserProfileId) {
            dispatch(
              unsubscribeToFavourite(subscriptionUserProfileId, vehicleId),
            );
          }
        })
        .catch((error) => {
          dispatch({ type: ActionTypes.TOGGLE_DELETED_VEHICLE_FAILED, error });
        });
    }
    if (isVehicleFavourite) {
      dispatch({
        type: ActionTypes.TOGGLE_DELETED_VEHICLE_COMPLETED,
        payload: { vehicleId },
      });
      if (isInComparison) {
        dispatch(removeCarFromComparison({ id: vehicleId }));
      }
      if (subscriptionUserProfileId) {
        dispatch(unsubscribeToFavourite(subscriptionUserProfileId, vehicleId));
      }
    } else {
      dispatch({
        type: ActionTypes.TOGGLE_SAVED_VEHICLE_COMPLETED,
        payload: { vehicleId: [vehicleId] },
      });
      setTimeout(() => {
        dispatch({ type: 'RESET_CURRENT_FAV_VEHICLE' });
      }, 800);
      dispatch(addCarToComparison([vehicle]));
      if (subscriptionEmail) {
        dispatch(subscribeToFavourites(subscriptionEmail, vehicleId));
      }
    }
    if (state.favourites.showFavLoginPopup) {
      dispatch({
        type: ActionTypes.SHOW_FAV_LOGIN_POPUP,
        payload: { flag: false },
      });
    }
    return Promise.resolve();
  };
};

export const bulkImportFavorites = (localFavouriteIds) => {
  return (dispatch) => {
    const url = '/profiles/me/favourites';
    const options = {
      method: 'POST',
      body: JSON.stringify(localFavouriteIds),
    };

    return dispatch(ajaxCallWIthToken(url, options, true, true))
      .then(() => {
        dispatch({
          type: ActionTypes.TOGGLE_SAVED_VEHICLE_COMPLETED,
          payload: { vehicleId: localFavouriteIds, isSync: true },
        });
        dispatch({ type: ActionTypes.RESET_LOCAL_FAVOURITES });
        dispatch({
          type: ActionTypes.SYNC_VEHICLE_COMPLETED,
        });
      })
      .catch((error) => {
        dispatch({ type: ActionTypes.TOGGLE_SAVED_VEHICLE_FAILED, error });
        dispatch({
          type: ActionTypes.SYNC_VEHICLE_FAILED,
        });
      });
  };
};

export const syncFavourites = () => {
  return (dispatch, getState) => {
    const state = getState();
    const favouriteIds = getFavoriteIds(state);
    const localFavourites = getLocalFavoriteIds(state);
    const isUserLoggedIn = getAccessToken(state);
    const localFavouriteIds = localFavourites.map(
      (favourite) => favourite.vehicleListingId,
    );
    if (isUserLoggedIn) {
      dispatch({
        type: ActionTypes.SYNC_VEHICLE_STARTED,
      });
      return dispatch(
        bulkImportFavorites(favouriteIds.concat(localFavouriteIds)),
      );
    }
    return getFavouriteVehicleIds();
  };
};

export const handlePageChange = (change) => {
  return (dispatch) => {
    window.scrollTo(0, 0);
    return dispatch(getFavouriteVehicles(change - 1));
  };
};

export const getSubscriptionStatus = () => {
  return async (dispatch, getState) => {
    const state = getState().favourites;
    const { subscriptionEmail } = state;

    const params = '&subscriptionType=PRICE_DROP&notificationChannel=EMAIL';
    const url = `/listings-subscriptions/fetch?emailId=${subscriptionEmail}${params}`;
    const options = {
      method: 'GET',
    };
    try {
      const response = await fetchApi(url, options, true);
      return dispatch({
        type: ActionTypes.GET_SUBSCRIPTION_STATUS_COMPLETED,
        payload: {
          subscriptionLastUpdatedAt: response.lastUpdatedAt,
          subscriptionStatus: response.subscriptionStatus,
        },
      });
    } catch (error) {
      return dispatch({
        type: ActionTypes.GET_SUBSCRIPTION_STATUS_FAILED,
        error,
      });
    }
  };
};

export const hideFavouriteTip = () => (dispatch) => {
  dispatch({
    type: ActionTypes.HIDE_FAV_LOGIN_POPUP,
    payload: { flag: false },
  });
};

import { Link, Heading4 } from 'app/shared/ui';
import { memo } from 'react';

import {
  SeoFooterLinksWrapperSt,
  SeoFooterLinksGridSt,
  SeoFooterLinksGroupSt,
} from 'app/shared/components/SeoFooterLinks/SeoFooterLinks.css';
import { seoFooterLinks } from 'app/shared/components/SeoFooterLinks/constants';
import { createGUID } from 'app/shared/utils/utils';

const SeoFooterLinksCmp = (): JSX.Element => {
  return (
    <SeoFooterLinksWrapperSt>
      <SeoFooterLinksGridSt grid md={4}>
        {seoFooterLinks.map(({ id, heading, items }) => (
          <SeoFooterLinksGroupSt key={id}>
            <li>
              <Heading4 fontSize={16} mb={10}>
                {heading}
              </Heading4>
            </li>
            {items.map(({ url, label }) => (
              <li key={createGUID()}>
                <Link
                  to={url}
                  color="micaBlue"
                  hoverColor="actionBlue"
                  title={label}
                  onClick={() => {
                    window.scrollTo(0, 0);
                  }}
                >
                  {label}
                </Link>
              </li>
            ))}
          </SeoFooterLinksGroupSt>
        ))}
      </SeoFooterLinksGridSt>
    </SeoFooterLinksWrapperSt>
  );
};

export const SeoFooterLinks = memo(SeoFooterLinksCmp);

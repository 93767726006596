const map = {
  // Online bestellen -> Marken
  Audi: 'online_audi',
  Volkswagen: 'online_volkswagen',
  Mercedes: 'online_merecedes',
  'Alle Marken': 'online_all_makes',

  // Online bestellen -> Zahlungsarten
  'Alle Fahrzeuge anzeigen': 'online_all_vehicles',
  'Fahrzeuge zum online kaufen': 'online_kaufen',
  'Fahrzeuge zum online finanzieren': 'online_finanzieren',
  'Fahrzeuge zum online leasen': 'online_leasen',

  // Online bestellen -> Wissenswertes
  'Warum online bestellen?': 'online_why',
  'Wie funktioniert es?': 'online_how',
  'heycar Qualitätsstandard': 'online_quality',
  FAQs: 'online_faq',
  'Ratgeber zum Auto finanzieren': 'online_support',
  Finanzierungsangebote: 'topnavigation_clickcollect_finance',
  Leasingangebote: 'topnavigation_selectclickcollect_leasing',
  'Was ist Click & Collect?': 'topnavigation_clickcollect_lp',
};

export const trackingMapping = (name: string): string => map[name] ?? name; // eslint-disable-line @typescript-eslint/no-unsafe-return

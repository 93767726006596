import { abortableClientSeoApiCall } from 'app/actions';
import { isAbortError } from 'app/shared/utils/utils';
import * as ActionTypes from 'app/marketplace/seo/constants';
import { getQueryFromFilters } from 'app/marketplace/filter/utils';
import { cleanUpModelsUrlParams } from 'app/marketplace/seo/utils';

let seoAbortInstance;
export const getSEOData = () => {
  return (dispatch, getState) => {
    /**
     * TODO: this utils function (cleanUpModelsUrlParams) take care of cleaning up the model params,
     *       this need to stay until the SEO service is updated to accept the new params with make prefix
     *       so the SEO content at the bottom of the CLP will continue to be fetch normally.
     *       Please remove after.
     */
    const url = `/seo/information?${cleanUpModelsUrlParams(
      encodeURI(getQueryFromFilters(getState().filter, true)),
    )}`;
    // if abort instance exists:
    // - abort the in flight api call
    // - clear abort instance
    if (typeof seoAbortInstance?.abort === 'function') {
      seoAbortInstance.abort();
      seoAbortInstance = undefined;
    }
    dispatch({ type: ActionTypes.GET_METADATA_REQUEST });

    // dispatch api call and set promise + abort controller instance
    const { promise, abortController } = dispatch(
      abortableClientSeoApiCall(url, {
        headers: {
          'X-Heycar-Tenant': 'DE',
          'X-Heycar-Language': 'de',
        },
      }),
    );
    seoAbortInstance = abortController;

    return promise
      .then((payload) => {
        dispatch({ type: ActionTypes.GET_METADATA_SUCCESS, payload });
      })
      .catch((error) => {
        if (isAbortError(error)) {
          dispatch({ type: ActionTypes.GET_METADATA_CANCELLED });
        } else {
          dispatch({ type: ActionTypes.GET_METADATA_FAILURE });
        }
      });
  };
};

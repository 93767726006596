import { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Box } from '@rebass/grid';

export class Col extends PureComponent {
  render() {
    const {
      sm,
      md,
      lg,
      xl,
      smOffset,
      mdOffset,
      lgOffset,
      xlOffset,
      phone,
      tabletPortrait,
      tabletLandscape,
      smallDesktop,
      offsetPhone,
      offsetTabletPortrait,
      offsetTabletLandscape,
      offsetSmallDesktop,
      children,
      extraClasses,
      tagName = 'div',
      className,
      extraCss,
      ...restProps
    } = this.props;
    const classNames = ['col'];

    if (phone || sm) {
      classNames.push(`sm-${phone || sm}`);
    }
    if (tabletPortrait || md) {
      classNames.push(`md-${tabletPortrait || md}`);
    }
    if (tabletLandscape || lg) {
      classNames.push(`lg-${tabletLandscape || lg}`);
    }
    if (smallDesktop || xl) {
      classNames.push(`xl-${smallDesktop || xl}`);
    }

    /**
     * Offset
     */
    if (offsetPhone || smOffset) {
      classNames.push(`sm-offset-${offsetPhone || smOffset}`);
    }
    if (offsetTabletPortrait || mdOffset) {
      classNames.push(`md-offset-${offsetTabletPortrait || mdOffset}`);
    }
    if (offsetTabletLandscape || lgOffset) {
      classNames.push(`lg-offset-${offsetTabletLandscape || lgOffset}`);
    }
    if (offsetSmallDesktop || xlOffset) {
      classNames.push(`xl-offset-${offsetSmallDesktop || xlOffset}`);
    }

    if (extraClasses) {
      classNames.push(extraClasses);
    }

    if (className) {
      classNames.push(className);
    }

    return (
      <Box
        as={tagName}
        className={classNames.join(' ')}
        css={extraCss}
        {...restProps}
      >
        {children}
      </Box>
    );
  }
}

Col.propTypes = {
  children: PropTypes.any,
  phone: PropTypes.number,
  tabletPortrait: PropTypes.number,
  tabletLandscape: PropTypes.number,
  smallDesktop: PropTypes.number,
  offsetPhone: PropTypes.number,
  offsetTabletPortrait: PropTypes.number,
  offsetTabletLandscape: PropTypes.number,
  offsetSmallDesktop: PropTypes.number,
  sm: PropTypes.number,
  md: PropTypes.number,
  lg: PropTypes.number,
  xl: PropTypes.number,
  smOffset: PropTypes.number,
  mdOffset: PropTypes.number,
  lgOffset: PropTypes.number,
  xlOffset: PropTypes.number,
  extraClasses: PropTypes.string,
  tagName: PropTypes.string,
  className: PropTypes.string,
  extraCss: PropTypes.any
};

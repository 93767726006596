import styled, { css } from 'styled-components';
import { Flex } from '@rebass/grid';

import { media } from 'app/shared/styles_js/variables';

export const ContainerSt = styled(Flex).attrs(() => ({
  flexDirection: 'column',
}))`
  width: 100%;
`;

export const ModalWrapperSt = styled(ContainerSt)`
  ${media.lg(css`
    width: 50%;
  `)}
`;

import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import * as Router from 'react-router';

import { getRoutes } from 'app/routes';
import { sentryOptions } from 'app/shared/sentry/initialize';

const CLP_PATH = 'gebrauchtwagen';
const PDP_PATH = 'vehicle';
const SEO_PAGES_PATH = 'auto';
const AUTOABO_PATH = 'autoabo';
const MAGAZINE_PATH = 'magazine';
const DEALER_PROFILE_PATH = 'haendler';

export const initiateSentrySDK = (): void => {
  const { isProduction, options } = sentryOptions;
  const browserOptions = options as Sentry.BrowserOptions;

  Sentry.init({
    ...browserOptions,
    beforeSend: (event) => {
      // Drop all events if query string includes `fbclid` string, otherwise let it through
      // See: https://stackoverflow.com/q/64497012/9705287
      // See: https://github.com/getsentry/sentry-javascript/issues/1811
      if (window?.location?.search?.indexOf('fbclid') !== -1) return null;
      return event;
    },
    integrations: [
      new Integrations.BrowserTracing({
        // React Router v3 integration
        routingInstrumentation: Sentry.reactRouterV3Instrumentation(
          Router.browserHistory,
          Router.createRoutes(getRoutes),
          Router.match,
        ),
      }),
    ],
    // Only track CLP, PDP, SEO_PAGES, MAGAZINE
    tracesSampler: (samplingContext) => {
      const pathName = samplingContext.location?.pathname.substring(1); // delete / from path
      if (isProduction && typeof pathName === 'string') {
        if (
          !pathName || // Home page
          pathName.startsWith(CLP_PATH) ||
          pathName.startsWith(PDP_PATH) ||
          pathName.startsWith(DEALER_PROFILE_PATH) ||
          (pathName.startsWith(SEO_PAGES_PATH) && !pathName.startsWith(AUTOABO_PATH)) ||
          pathName.startsWith(MAGAZINE_PATH)
        ) {
          return 0.05;
        }
      }

      return 0;
    },
  });
};

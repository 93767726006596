import { ImageWithInfo } from 'app/shared/ui';
import { YoutubeEmbed } from 'app/shared/components/YoutubeEmbed/YoutubeEmbed';
import { OnlineKaufTeaserDescription } from 'app/marketplace/home/OnlineKaufTeaser/components/OnlineKaufTeaserDescription/OnlineKaufTeaserDescription';

import { OnlineKaufTeaserSt } from './OnlineKaufTeaser.css';

export const OnlineKaufTeaser = (): JSX.Element => {
  const videoUrl =
    'https://www.youtube.com/watch?v=5NqsCVXv2h4&ab_channel=heycarDeutschland';
  // static props for imagewithinfor section
  const props = {
    bgColor: 'mustangLighter' as TColor,
    leftSection: <YoutubeEmbed videoUrl={videoUrl} />,
    rightSection: <OnlineKaufTeaserDescription />,
    isReverse: true,
    leftWidth: 48,
  };
  return (
    <OnlineKaufTeaserSt>
      <ImageWithInfo {...props} />
    </OnlineKaufTeaserSt>
  );
};

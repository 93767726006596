import { memo } from 'react';

import { IndeterminateLoadingSpinner } from 'app/shared/ui';
import { CartileImageLoadingContainerSt } from 'app/shared/modules/Cartile/CartileUIComponents/CartileImages/CartileImageLoading/CartileImageLoading.css';

const CartileImageLoadingCmp = () => (
  <CartileImageLoadingContainerSt>
    <IndeterminateLoadingSpinner className="cartile-image-spinner" />
  </CartileImageLoadingContainerSt>
);

export const CartileImageLoading = memo(CartileImageLoadingCmp);

/* eslint-disable react/jsx-props-no-spreading */
import { memo, useState } from 'react';
import { useInView } from 'react-intersection-observer';

import { ErrorBoundary } from 'app/shared/components/ErrorBoundary/ErrorBoundary';
import GamBanner from 'app/shared/modules/Campaign/GAM/GamBanner/GamBanner';
import LoadingSpinner from 'app/shared/modules/Campaign/GAM/GamBanner/LoadingSpinner';
import { useHasMounted } from 'app/shared/hooks/useHasMounted';
import { useInitialMount } from 'app/shared/hooks/useInitialMount';
import { Wrapper } from 'app/shared/modules/Campaign/GAM/GamBanner/GamBanner.css';
import { TGamBannerProps } from 'app/shared/modules/Campaign/GAM/GamBanner/GamBanner.types';

const GamBannerWrapper = (props: TGamBannerProps): JSX.Element => {
  const {
    bannerArea,
    isScalable,
    isBlocked,
    fallbackComponent,
    renderWhenViewable,
    collectDurationMetric,
    useSmallSpinner,
  } = props;
  const [ref, inView] = useInView({ triggerOnce: true });
  const isInitialMount = useInitialMount();
  const hasMounted = useHasMounted();
  const [renderingStartTime, setRenderingStartTime] = useState<number>(0);
  if (isBlocked) return <>{fallbackComponent}</>;

  const renderComponent = renderWhenViewable ? inView : true;
  const isAvailableToCollectDurationMetric =
    collectDurationMetric && // if it's enabled to collect duration metric
    !isBlocked && // if it shouldn't render the fallback
    /**
     * To prevent `performance is not defined` issue on initial mount for the server side, which is not needed actually
     * See: https://sentry.io/share/issue/6040effe2e4c4a69bca7e672171050e1/
     * See: https://stackoverflow.com/q/46436943/9705287
     */
    typeof window !== 'undefined' &&
    /**
     * For the browser compatibility
     * See: https://developer.mozilla.org/en-US/docs/Web/API/Performance/now#browser_compatibility
     */
    !!performance &&
    typeof performance?.now === 'function';

  // Only set the `renderingStartTime` on the initial mount of the component
  if (isInitialMount && !hasMounted && isAvailableToCollectDurationMetric) {
    const initialRenderingStartTime = performance.now();
    setRenderingStartTime(initialRenderingStartTime);
  }

  return (
    <ErrorBoundary fallbackComponent={fallbackComponent}>
      <Wrapper ref={ref} $bannerArea={bannerArea} $isScalable={isScalable}>
        {renderComponent && hasMounted ? (
          <GamBanner
            {...(isAvailableToCollectDurationMetric && {
              isAvailableToCollectDurationMetric,
              renderingStartTime,
            })}
            {...props}
          />
        ) : (
          <LoadingSpinner useSmallSpinner={useSmallSpinner} />
        )}
      </Wrapper>
    </ErrorBoundary>
  );
};

GamBannerWrapper.defaultProps = {
  isScalable: true,
  fallbackComponent: null,
  renderWhenViewable: true,
  collectDurationMetric: false,
};

export default memo(GamBannerWrapper);

import PropTypes from 'prop-types';

import { CampaignDataShape } from 'app/shared/utils/propTypes';
import { checkCarAvailability } from 'app/marketplace/vehicle/utils';
import { CampaignTopBanner } from 'app/marketplace/shared/TopBanner/CampaignTopBanner/CampaignTopBanner.tsx';

export const TopBanner = (props) => {
  const {
    location,
    isTopNavBarHidden,
    vehicle,
    campaignData,
    breakpoint,
    isPdpCampaignTopBannerAvailable,
    setIsPdpCampaignTopBannerAvailable,
    isAdBlockerDetected,
    closeOverlay,
    openOverlay,
    signupForNewsletterWithConsent,
    isFlag248OneClickLeadsShow,
    automatedListings,
  } = props;

  const isCarAvailable = checkCarAvailability(vehicle);
  const { query } = location;
  const isD2CVehicle =
    vehicle?.dataSource === 'GWC_D2C' ||
    query?.dataSource === 'GWC_D2C' ||
    vehicle?.dataSource === 'INSTAMOTION' ||
    query?.dataSource === 'INSTAMOTION';
  if (isD2CVehicle) return null;

  return (
    <CampaignTopBanner
      isTopNavBarHidden={isTopNavBarHidden}
      campaignData={campaignData}
      breakpoint={breakpoint}
      isCarAvailable={isCarAvailable}
      isPdpCampaignTopBannerAvailable={isPdpCampaignTopBannerAvailable}
      setIsPdpCampaignTopBannerAvailable={setIsPdpCampaignTopBannerAvailable}
      isAdBlockerDetected={isAdBlockerDetected}
      vehicle={vehicle}
      closeOverlay={closeOverlay}
      openOverlay={openOverlay}
      signupForNewsletterWithConsent={signupForNewsletterWithConsent}
      isFlag248OneClickLeadsShow={isFlag248OneClickLeadsShow}
      automatedListings={automatedListings}
      location={location}
    />
  );
};

TopBanner.propTypes = {
  isTopNavBarHidden: PropTypes.bool.isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string,
    query: PropTypes.string,
  }),
  vehicle: PropTypes.object,
  campaignData: CampaignDataShape,
  breakpoint: PropTypes.string,
  isPdpCampaignTopBannerAvailable: PropTypes.bool.isRequired,
  setIsPdpCampaignTopBannerAvailable: PropTypes.func.isRequired,
  isAdBlockerDetected: PropTypes.bool.isRequired,
  closeOverlay: PropTypes.func.isRequired,
  openOverlay: PropTypes.func.isRequired,
  signupForNewsletterWithConsent: PropTypes.bool.isRequired,
  isFlag248OneClickLeadsShow: PropTypes.bool.isRequired,
  automatedListings: PropTypes.any.isRequired,
};

TopBanner.defaultProps = {
  breakpoint: 'sm',
  location: { pathname: null, query: null },
  vehicle: null,
  campaignData: {
    primaryCampaign: null,
    matchingCampaigns: [],
    primaryCampaignMarker: null,
  },
};

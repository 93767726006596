import styled from 'styled-components';

import { color, fontWeights } from '../../styles_js/variables';

export const ItemSt = styled.div`
  display: flex;
  width: 100%;
  min-height: 56px;
  align-items: center;
  justify-content: space-between;
  padding: 16px 0 16px 24px;
  border-bottom: 1px solid ${color.grayLight};
  background-color: ${props =>
    props.isFocused ? color.brandPrimaryLightest : color.grayLightest};
  cursor: pointer;
  font-weight: ${fontWeights.regular};
  line-height: 1.5;

  &:hover {
    background-color: ${color.brandPrimaryLightest};
  }
`;

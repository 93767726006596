import styled, { css } from 'styled-components';
import { color, zIndex } from '../../../styles_js/variables';

export const PopoverSt = styled.div`
  position: absolute;
  z-index: -100;
  top: 42px;
  overflow: hidden;
  width: 100%;
  max-height: 200px;
  border-right: 1px solid ${color.grayLight};
  border-bottom: 1px solid ${color.grayLight};
  border-left: 1px solid ${color.grayLight};
  opacity: 0;
  overflow-y: scroll;
  transition: opacity 0.2s ease;

  ${props =>
    props.show &&
    css`
      z-index: ${zIndex.navbar};
      opacity: 1;
    `};
`;

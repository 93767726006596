import styled from 'styled-components';

import { Text4 } from 'app/shared/ui';

const getTextColor = ({ theme: { colors } }) => {
  return colors.gray;
};

export const DisclaimerMsgSt = styled(Text4).attrs(() => ({ as: 'div' }))`
  margin: 0 auto;
  color: ${getTextColor};

  & > div {
    margin-bottom: 10px;
    p {
      font-size: inherit;
      line-height: 1.6;
      padding: 0;
    }
  }

  a {
    color: ${({ theme: { colors } }) => colors.grayDark};
    text-decoration: 'underline';

    &:hover,
    &:focus {
      color: ${({ theme: { colors } }) => colors.grayLight};
      transition: 'color 200ms ease-in-out';
      text-decoration: 'underline';
    }
  }
`;

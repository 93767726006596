import React from 'react';

import { SpinnerWrapperSt } from './IndeterminateLoadingSpinner.css';
import { IIndeterminateLoadingSpinner } from './IndeterminateLoadingSpinner.types';

export const widthHeight: Record<string, number> = {
  s: 20,
  m: 30,
  l: 40,
};
export const strokeWidth: Record<string, number> = {
  s: 2,
  m: 3,
  l: 4,
};

export const IndeterminateLoadingSpinner = ({
  type = 'primary',
  size = 'l',
  ...rest
}: IIndeterminateLoadingSpinner): JSX.Element => {
  return (
    <SpinnerWrapperSt size={size} type={type} {...rest}>
      <svg
        className="spinner"
        viewBox={`0 0 ${widthHeight[size]} ${widthHeight[size]}`}
      >
        <circle
          className="path"
          cx={widthHeight[size] / 2}
          cy={widthHeight[size] / 2}
          r={strokeWidth[size] * 4}
          fill="none"
          strokeWidth={strokeWidth[size]}
        />
      </svg>
    </SpinnerWrapperSt>
  );
};

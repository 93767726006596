import {
  CALCULATION_GET,
  CALCULATION_GET_SUCCESS,
  CALCULATION_GET_ERROR,
  CALCULATION_RESET,
} from '../../constants';

export const initialState = {
  requestId: undefined,
  error: undefined,
};

export function calculation(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case CALCULATION_GET:
      return { ...state, error: undefined };
    case CALCULATION_GET_SUCCESS:
      return { ...state, ...payload };
    case CALCULATION_GET_ERROR:
      return { ...state, error: payload };
    case CALCULATION_RESET:
      return initialState;
    default:
      return state;
  }
}

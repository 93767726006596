/* eslint-disable react-perf/jsx-no-new-object-as-prop,react/display-name,react/no-multi-comp */
import PropTypes from 'prop-types';
import { memo } from 'react';
import { Box } from '@rebass/grid';
import Markdown from 'markdown-to-jsx';

import { CampaignsDisclaimerSt } from 'app/marketplace/vehicle/D2C/D2CPdp/components/Details/Details.css';
import { MarkdownContainer } from 'app/marketplace/contentful/shared/MarkdownContainer/MarkdownContainer';
import {
  VehicleMarkerShape,
  CampaignDataShape,
} from 'app/shared/utils/propTypes.js';

import { DisclaimerMsgSt } from './DisclaimerMsg.css';
import { CONTENTFUL_DISCLAIMER } from './constants';

/* istanbul ignore next */
const LinkCmp = ({ children, ...props }) => <a {...props}>{children}</a>;

const getSortedCampaigns = (
  campaigns,
  vehicleMarkers,
  isPDP,
  isDealerCashEligible,
) => {
  try {
    if (!campaigns || !Array.isArray(campaigns)) {
      return [];
    }
    let data = [...campaigns];
    if (isPDP && vehicleMarkers && Array.isArray(vehicleMarkers)) {
      data = vehicleMarkers.reduce((acc, item) => {
        const result = campaigns?.filter((val) => {
          return val?.fields?.id === item?.campaign?.id;
        });
        acc.push(...result);
        return acc;
      }, []);

      if (isDealerCashEligible) {
        const result = campaigns?.find((val) => {
          return val?.fields?.id === '2211_dc_1000';
        });
        data.push(result);
      }
    }
    const fields = data.map((item) => item?.fields);
    return fields.sort((a, b) => a?.order - b?.order);
  } catch (error) {
    /* istanbul ignore next */
    return [];
  }
};

const Disclaimer = ({
  pathname,
  search,
  campaigns,
  vehicleMarkers,
  isVatReclaimableMessage,
  isOneClickLeadSuccess = false,
  d2c,
  campaignData,
  isInstamotionVehicle,
  isDealerCashEligible,
  fdlDisclaimers,
  isLeasingPayment,
}) => {
  const isD2CFilterSelected = search?.includes('dataSource=GWC_D2C');
  const isHome = pathname === '/';
  const isCLP = pathname === '/gebrauchtwagen';
  const isAngebote = pathname === '/angebote';
  const isPDP = pathname?.includes('vehicle');
  const isCheckout = pathname?.includes('checkout');

  const getDisclaimerName = () => {
    if (isHome) return CONTENTFUL_DISCLAIMER.HP;
    if (isCLP) return CONTENTFUL_DISCLAIMER.CLP;
    if (isAngebote) return CONTENTFUL_DISCLAIMER.ANGEBOTE;
    if (isPDP) return CONTENTFUL_DISCLAIMER.PDP;
    return '';
  };

  const getDataCampaignElement = () => {
    if (isHome) return 'hp-disclaimers';
    if (isCLP) return 'clp-disclaimers';
    if (isAngebote) return 'angebote-disclaimers';
    if (isPDP) return 'pdp-disclaimers';
    return '';
  };

  const renderDisclaimers = (symbol, disclaimers) => (
    <DisclaimerMsgSt>
      {disclaimers.map((disclaimer, idx) => (
        <div key={disclaimer}>
          {idx === 0 && <sup>{symbol}</sup>} {disclaimer}
        </div>
      ))}
    </DisclaimerMsgSt>
  );

  if (isCheckout && fdlDisclaimers) {
    if (Array.isArray(fdlDisclaimers)) {
      return (
        <Box mt={4}>
          {renderDisclaimers(isLeasingPayment ? 'B' : 'A', fdlDisclaimers)}
        </Box>
      );
    }

    if (fdlDisclaimers.leasing || fdlDisclaimers.financing) {
      return (
        <Box mt={4}>
          {fdlDisclaimers.financing &&
            renderDisclaimers('A', fdlDisclaimers.financing)}
          {fdlDisclaimers.leasing &&
            renderDisclaimers('B', fdlDisclaimers.leasing)}
        </Box>
      );
    }
  }

  return (
    <Box mt={isOneClickLeadSuccess ? [4, 0] : 4}>
      <DisclaimerMsgSt isAngebote={isAngebote}>
        {getSortedCampaigns(
          campaigns,
          vehicleMarkers,
          isPDP,
          isDealerCashEligible,
        ).map((campaign) => {
          const content = campaign?.[getDisclaimerName()];
          return content && !isD2CFilterSelected ? (
            <MarkdownContainer
              key={campaign?.order}
              content={content}
              data-campaign-id={campaign?.id}
              data-campaign-element={getDataCampaignElement()}
              linkTarget="_blank"
            />
          ) : null;
        })}

        {!isAngebote && (
          <div>
            ** Weitere Informationen zum offiziellen Kraftstoffverbrauch und den
            offiziellen spezifischen CO<sub>2</sub> -Emissionen neuer
            Personenkraftwagen können dem &lsquo;Leitfaden über den
            Kraftstoffverbrauch, die CO<sub>2</sub> -Emissionen und den
            Stromverbrauch neuer Personenkraftwagen&rsquo; entnommen werden, der
            an allen Verkaufsstellen und bei der Deutschen Automobil Treuhand
            GmbH unter{' '}
            <a
              href="http://www.dat.de"
              target="_blank"
              rel="noopener noreferrer"
            >
              www.dat.de
            </a>{' '}
            unentgeltlich erhältlich ist.
          </div>
        )}
        {((!isAngebote && (isPDP || isVatReclaimableMessage)) ||
          isCheckout) && (
          <div id="app.vatReclaimableMessage">
            *** Dieser Pkw unterliegt der Differenzbesteuerung gem. § 25 a UStG.
            Die MwSt. wird in der Rechnung nicht ausgewiesen.
          </div>
        )}
        {d2c && !isInstamotionVehicle && (
          <CampaignsDisclaimerSt>
            {campaignData?.matchingCampaigns?.map((campaign) => (
              <>
                <Markdown
                  key={campaign.id}
                  options={{
                    overrides: {
                      a: {
                        component: LinkCmp,
                        props: {
                          target: '_blank',
                        },
                      },
                    },
                  }}
                >
                  {campaign?.pdpDisclaimers || /* istanbul ignore next */ ''}
                </Markdown>
                <br />
              </>
            ))}
            <span>
              <sup>d</sup> Du hast dich für ein Gebrauchtfahrzeug entschieden.
              Wir hoffen, dass das Fahrzeug deinen Wünschen und Vorstellungen
              entspricht. Sollte dies ausnahmsweise nicht der Fall sein, sorgt
              die Vehicle Trading International (VTI) GmbH dafür, dass der
              Vertrag über das Gebrauchtfahrzeug aufgehoben wird. Dieses
              Rücknahmeversprechen besteht unabhängig davon, ob du dieses
              Gebrauchtfahrzeug online bei der VTI GmbH kaufst, bei der VW Bank
              GmbH finanzierst oder VW Leasing GmbH least. Das
              Rücknahmeversprechen der VTI GmbH besteht auch unabhängig von
              deinen sonstigen Rechten aus einem etwaigen Leasing- oder
              Finanzierungsvertrag. Voraussetzung für die Inanspruchnahme des
              Rücknahmeversprechens ist, dass du der VTI GmbH deinen Wunsch
              innerhalb von 22 Tagen nach Übergabe des Fahrzeuges mitteilst, z.
              B. per E-Mail an heycarverkauf@vwfs.com, du mit dem Fahrzeug nicht
              mehr als 200 km zurückgelegt hast und das Fahrzeug keine Schäden
              aufweist, die nach Übergabe an dich entstanden sind. Machst du von
              dem Rücknahmeversprechen der VTI GmbH Gebrauch, ist das Fahrzeug
              innerhalb von 14 Tagen an die VTI GmbH oder den von der VTI GmbH
              beauftragten Spediteur zu übergeben, sind der VTI GmbH oder dem
              beauftragten Spediteur bei der Abholung sämtliche
              Fahrzeugschlüssel, eventuell mitgeliefertes Zubehör (z. B. einen
              zweiten Reifensatz) und alle Fahrzeugdokumente sowie die
              Nummernschilder des Fahrzeuges auszuhändigen.
            </span>
          </CampaignsDisclaimerSt>
        )}
      </DisclaimerMsgSt>
    </Box>
  );
};

export const DisclaimerMsg = memo(Disclaimer);

Disclaimer.propTypes = {
  pathname: PropTypes.string,
  search: PropTypes.string,
  campaigns: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  vehicleMarkers: PropTypes.arrayOf(VehicleMarkerShape),
  fdlDisclaimers: PropTypes.array,
  isVatReclaimableMessage: PropTypes.bool,
  isOneClickLeadSuccess: PropTypes.bool,
  d2c: PropTypes.bool,
  campaignData: CampaignDataShape,
  isInstamotionVehicle: PropTypes.bool,
  isDealerCashEligible: PropTypes.bool,
  isLeasingPayment: PropTypes.bool,
};
Disclaimer.defaultProps = {
  pathname: undefined,
  search: undefined,
  campaigns: [],
  fdlDisclaimers: [],
  vehicleMarkers: [],
  isVatReclaimableMessage: false,
  isOneClickLeadSuccess: false,
  d2c: false,
  campaignData: {
    primaryCampaign: null,
    matchingCampaigns: [],
    primaryCampaignMarker: null,
  },
  isInstamotionVehicle: false,
  isDealerCashEligible: false,
  isLeasingPayment: false,
};

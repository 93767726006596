import { makeLazy } from 'app/shared/components/LazyWrapper/LazyWrapper';

export const LazyFinancingChoice = makeLazy({
  getComponent: /* istanbul ignore next */ () => {
    return import(
      'app/marketplace/contactDealer/FinancingChoice/ConnectedFinancingChoice' /* webpackChunkName: "FinancingChoice" */
    );
  },
  exportName: 'ConnectedFinancingChoice',
});

/* eslint-disable react/prop-types,react/require-default-props,no-shadow,promise/catch-or-return,promise/always-return */
import { PureComponent } from 'react';
import PropTypes from 'prop-types';
import 'url-polyfill';
import { browserHistory } from 'react-router';
import { connect } from 'react-redux';
import { ThemeProvider } from 'styled-components';
import { withProfiler } from '@sentry/react';

import { withErrorBoundary } from 'app/shared/components/ErrorBoundary/ErrorBoundary';
import { migrateCookieDashboard } from 'app/marketplace/cookieDashboard/actions';
import { localStore } from 'app/shared/utils/storageFactory';
import { getRgba } from 'app/shared/styles_js/variables';
import { storeWebVitalsMetrics } from 'app/shared/modules/Campaign/utils';

import {
  setTrackingVersion,
  trackConnectionQuality,
  trackPage,
  trackTimeToInteraction,
} from './shared/utils/tracking';
import { runEasterEgg } from './easter-egg';
import { ConnectedGlobalEventHandlers } from './shared/modules/globalEvents/GlobalEventHandlers/ConnectedGlobalEventHandlers';
import { updatePreviousLocation, setTheme } from './actions';
import {
  getFeatureFlags,
  filterFlagParams,
  featureFlagsInitialisedAction,
} from './shared/modules/flagr/actions';
import { AppSt, GlobalStyle } from './App.css';
import { handleCookiesForCookieState } from './marketplace/cookieDashboard/utils';
import { createGUID, trackingFlagAction } from './shared/utils/utils';
import {
  setUserState,
  removeLeadVehicleLists,
} from './shared/modules/user/actions';
import { ConnectedOverlayRoot } from './shared/modules/overlay/OverlayRoot/ConnectedOverlayRoot';
import { refreshToken as refreshTokenMarketplace } from './marketplace/accounts/actions';
import { migrateContactDetails } from './marketplace/contactDealer/actions';
import { isExternalPathname } from './shared/utils/isExternalPathname';

class App extends PureComponent {
  constructor(props) {
    super(props);
    const { setUserState: setUserStateAction, location } = props;

    setUserStateAction();
    setTrackingVersion();

    // Avoid our heycar hiring log on partners websites.
    if (isExternalPathname(location?.pathname)) {
      runEasterEgg();
    }
  }

  componentDidMount() {
    const {
      shortCircuit,
      guid,
      areFeatureFlagsInitialised,
      refreshToken,
      featureFlags,
      location: { query, pathname },
      migrateContactDetails: migrateStoredContactDetails,
      migrateCookieDashboard,
      cookiePreferences,
      removeLeadVehicleLists: removeVehicleListsFromCookieStore,
    } = this.props;

    this.overrideFlags = filterFlagParams(query); // Store flags for the next page

    if (shortCircuit) {
      browserHistory.push('/temp-unavailable');
    }

    trackConnectionQuality();
    trackTimeToInteraction();
    setTimeout(trackPage);
    /*
     * delay due to window.analytics is not available immediatly after the app load
     * Will track every time the page loads
     */
    /*
     * Move lead contact details to new cookie reducer
     */
    migrateStoredContactDetails();
    /*
     * migrateCookieDashboard();
     *
     * As of the date 30th May, the redux store for cookies will be modified. To ensure that
     * this is backward compatible, we are keeping the old store object for 4 weeks.
     *
     * The new store properties will have the values copied over from the old ones.
     */
    migrateCookieDashboard();

    /**
     * This is a temporary measure to stop bloating the cookie store, which has limited size.
     * Existing arrays will be deleted and new storing actions will point to local storage state instead (state.contactDealer)
     */
    removeVehicleListsFromCookieStore();

    const hasSomeCookiesEnabled = Object.keys(cookiePreferences).reduce(
      (_, curr) => {
        return cookiePreferences[curr];
      },
      false,
    );

    if (hasSomeCookiesEnabled) {
      handleCookiesForCookieState(cookiePreferences);
    }

    /* block Flagr request from mobile app and partner iframe routes */
    if (
      guid &&
      areFeatureFlagsInitialised &&
      featureFlags &&
      !isExternalPathname(pathname)
    ) {
      trackingFlagAction({ payload: featureFlags });
    }

    /*
     * partners (i.e. Autobild) have no login feature and blocked access to
     * localstorage on incognito mode, which throws an exception and crash
     * the iframe.
     */
    if (!isExternalPathname(pathname) && localStore.getItem('showLogin')) {
      refreshToken();
    }

    window._WAS_RENDERED_ON_SERVER_ = false;

    storeWebVitalsMetrics();
  }

  componentDidUpdate(prevProps) {
    const {
      shortCircuit,
      guid,
      createdAt,
      getFeatureFlags: getFeatureFlagsAction,
      featureFlagsInitialisedAction,
      location,
      cookiePreferences,
    } = this.props;
    if (shortCircuit && shortCircuit !== prevProps.shortCircuit) {
      browserHistory.push('/temp-unavailable');
    }

    trackPage();

    /* block Flagr request from mobile app and partner iframe routes */
    if (!isExternalPathname(location.pathname) && guid !== prevProps.guid) {
      getFeatureFlagsAction(guid, null, this.overrideFlags).then(
        ({ payload }) => {
          trackingFlagAction({ payload });
          featureFlagsInitialisedAction();
        },
      );
    }
    if (
      location.pathname !== prevProps.location.pathname ||
      location.search !== prevProps.location.search
    ) {
      prevProps.updatePreviousLocation(
        `${prevProps.location.pathname}${prevProps.location.search}`,
      );
    }

    if (cookiePreferences !== prevProps.cookiePreferences) {
      let hasSomeCookiesDisabled = false;

      if (
        !cookiePreferences.functional &&
        cookiePreferences.functional !== prevProps.cookiePreferences.functional
      ) {
        hasSomeCookiesDisabled = true;
      }
      if (
        !cookiePreferences.analytical &&
        cookiePreferences.analytical !== prevProps.cookiePreferences.analytical
      ) {
        hasSomeCookiesDisabled = true;
      }
      if (
        !cookiePreferences.marketing &&
        cookiePreferences.marketing !== prevProps.cookiePreferences.marketing
      ) {
        hasSomeCookiesDisabled = true;
      }

      handleCookiesForCookieState(cookiePreferences);
      if (hasSomeCookiesDisabled) {
        setTimeout(() => {
          window.location.reload();
        }, 1500);
      }
    }
  }

  render() {
    const { children, theme } = this.props;
    const appTheme = { ...theme, getRgba };
    return (
      <ThemeProvider theme={appTheme}>
        <>
          <AppSt>
            <ConnectedOverlayRoot />
            <ConnectedGlobalEventHandlers />
            {children}
          </AppSt>
          <GlobalStyle />
        </>
      </ThemeProvider>
    );
  }
}

App.propTypes = {
  // functions
  featureFlagsInitialisedAction: PropTypes.func,
  getFeatureFlags: PropTypes.func,
  refreshToken: PropTypes.func,
  setUserState: PropTypes.func.isRequired,
  updatePreviousLocation: PropTypes.func,
  migrateContactDetails: PropTypes.func.isRequired,

  // properties
  children: PropTypes.node,
  createdAt: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  guid: PropTypes.string,
  location: PropTypes.object,
  shortCircuit: PropTypes.bool,
};

export const ConnectedAppWithErrorBoundary = withErrorBoundary(
  connect(
    (state) => {
      const { app, cookieDashboard, tracking, user } = state;
      return {
        guid: tracking.guid,
        cookiePreferences: cookieDashboard.preferences,
        shortCircuit: app.shortCircuit,
        featureFlags: app.featureFlags,
        areFeatureFlagsInitialised: app.areFeatureFlagsInitialised,
        createdAt: user.createdAt,
        theme: app.theme,
      };
    },
    {
      updatePreviousLocation,
      featureFlagsInitialisedAction,
      getFeatureFlags,
      setUserState,
      refreshToken: refreshTokenMarketplace,
      migrateContactDetails,
      migrateCookieDashboard,
      removeLeadVehicleLists,
    },
  )(withProfiler(App)),
);

ConnectedAppWithErrorBoundary.loadAction = ({ tracking }, { location }) => {
  //  The next line is a workaround for the E2E tests. It won't affect the web-app
  //  because it always has tracking.guid in the state (coming from the cookies).
  const trackingGUID = tracking.guid || createGUID();

  return (dispatch) => {
    dispatch(setTheme());
    return dispatch(
      getFeatureFlags(trackingGUID, null, filterFlagParams(location.query)),
    ).then(() => dispatch(featureFlagsInitialisedAction()));
  };
};

ConnectedAppWithErrorBoundary.displayName = 'MainApp';

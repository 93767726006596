/* eslint-disable react/jsx-props-no-spreading, react/no-multi-comp */
import PropTypes from 'prop-types';
import { browserHistory } from 'react-router';
import LazyLoad from 'react-lazyload';

import { Link } from 'app/shared/ui';
import { trackAction } from 'app/shared/utils/tracking';
import {
  SpecialOfferResponsiveWrapperSt,
  UnderlinedTextSt,
  SpecialOfferTileSt,
  SpecialOfferTileTextSt,
  SpecialOfferTileImageSt,
  SpecialOfferDescriptionSt,
  SpecialOfferBannerSt,
  LinkButtonSt,
  MIN_IMAGE_HEIGHT,
} from 'app/marketplace/specialOffers/SpecialOfferTile/SpecialOfferTile.css';
import { breakpointShape } from 'app/shared/utils/propTypes';
import { trackingDataShape } from 'app/marketplace/contentful/propTypes';

import { useResponsiveImage } from './useResponsiveImage';

const CLP_BASE_URL = '/gebrauchtwagen';

export const SpecialOfferTile = ({
  cardImage,
  cardImageTrackingData,
  title,
  description,
  filterUrl,
  className,
  target,
  linkText,
  resetLocation,
  responsiveImage,
  responsiveImageTrackingData,
  breakpoint,
  useShadowForHeroImage,
}) => {
  /**
   * anyResponsiveImagesPresent - true if any media url strings are present for small, medium, large, xLarge
   * media: - object of urls by size (small, medium, large, xLarge)
   * title - title text for responsive image
   *
   * @constant
   * @type {{anyResponsiveImagesPresent: boolean, media: {small: string, medium: string, large: string, xLarge: string}, title: string}}
   */
  const {
    anyResponsiveImagesPresent,
    media,
    title: responsiveImageTitle,
  } = useResponsiveImage(responsiveImage?.fields);

  const targetUrl = `${CLP_BASE_URL}?${filterUrl}`;

  // eslint-disable-next-line react-perf/jsx-no-new-function-as-prop
  const trackClick = ({ field }) => (e) => {
    e.preventDefault();
    resetLocation();
    const { name, ...rest } = field?.fields || {};
    trackAction(name, rest);
    trackAction('special_offer_tile_clicked', {
      category: target,
      label: title,
      value: filterUrl,
    });

    setTimeout(() => {
      browserHistory.push(targetUrl);
    }, 30); // to counter sagas debounce ( 20ms )
  };
  const imageUrl = cardImage?.fields?.file?.url;

  const imageWrapperProps = { height: MIN_IMAGE_HEIGHT, offset: 100 };

  if (!imageUrl && !anyResponsiveImagesPresent) {
    return null;
  }

  const { small, medium, large, xLarge } = media || {};

  /**
   *
   * @param {object} param0
   * @param {string} param0.src
   * @param {string} param0.alt
   * @returns {JSX.Element}
   */
  // eslint-disable-next-line react/prop-types
  const smallCard = ({ src, alt, field }) => (
    <Link
      to={targetUrl}
      alt={title}
      textDecoration="none"
      onClick={trackClick({ field })}
    >
      <SpecialOfferTileSt
        className={className}
        $useShadowForHeroImage={useShadowForHeroImage}
      >
        <LazyLoad {...imageWrapperProps}>
          <SpecialOfferTileImageSt src={src} alt={alt} />
        </LazyLoad>
        {description && (
          <SpecialOfferTileTextSt>
            <SpecialOfferDescriptionSt>{description}</SpecialOfferDescriptionSt>
          </SpecialOfferTileTextSt>
        )}
        {linkText && <UnderlinedTextSt>{linkText}</UnderlinedTextSt>}
      </SpecialOfferTileSt>
    </Link>
  );

  // currently the data is client side rendered, which causes flickering on initial render
  // until we render this on the server, we should wait for client
  if (SERVER) {
    return <SpecialOfferBannerSt />;
  }

  // anyResponsiveImagesPresent: true if there is a small, medium, large, or xLarge image
  // TODO: add validations to the responsive image itself
  if (anyResponsiveImagesPresent) {
    if (breakpoint === 'sm' && small) {
      return smallCard({
        src: small,
        alt: responsiveImageTitle,
        field: responsiveImageTrackingData,
      });
    }

    return (
      <SpecialOfferResponsiveWrapperSt>
        <Link
          to={targetUrl}
          textDecoration="none"
          title={responsiveImageTitle}
          onClick={trackClick({
            field: responsiveImageTrackingData,
          })}
          target="_blank"
        >
          <SpecialOfferBannerSt
            title={responsiveImageTitle}
            mdImage={medium}
            lgImage={large}
            xlImage={xLarge}
          />
        </Link>
        {linkText && (
          <LinkButtonSt to={targetUrl} onClick={() => {}}>
            {linkText}
          </LinkButtonSt>
        )}
      </SpecialOfferResponsiveWrapperSt>
    );
  }

  // if card tile image is present
  if (imageUrl) {
    return smallCard({
      src: imageUrl,
      alt: title,
      field: cardImageTrackingData,
    });
  }

  // return placeholder if no data
  return <SpecialOfferBannerSt />;
};

SpecialOfferTile.propTypes = {
  className: PropTypes.string,
  target: PropTypes.string,
  cardImage: PropTypes.object,
  responsiveImage: PropTypes.object,
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  linkText: PropTypes.string,
  filterUrl: PropTypes.string.isRequired,
  resetLocation: PropTypes.func,
  breakpoint: breakpointShape.isRequired,
  cardImageTrackingData: trackingDataShape,
  responsiveImageTrackingData: trackingDataShape,
  useShadowForHeroImage: PropTypes.bool,
};

SpecialOfferTile.defaultProps = {
  description: null,
  className: '',
  target: 'contentful',
  resetLocation: () => {},
  linkText: '',
  cardImage: null,
  cardImageTrackingData: null,
  responsiveImage: null,
  responsiveImageTrackingData: null,
  useShadowForHeroImage: true,
};

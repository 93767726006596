export const SET_PREVIOUS_LOCATION = 'SET_PREVIOUS_LOCATION';
export const GET_FEATURE_FLAGS = 'GET_FEATURE_FLAGS';
export const FEATURE_FLAGS_ERROR = 'FEATURE_FLAGS_ERROR';
export const SET_ASYNC_STYLES_BUNDLE = 'SET_ASYNC_STYLES_BUNDLE';
export const SET_SHORT_CIRCUIT = 'SET_SHORT_CIRCUIT';
export const SET_ERROR_MESSAGE = 'SET_ERROR_MESSAGE';
export const SET_REDIRECT = 'SET_REDIRECT';
export const SET_THEME = 'SET_THEME';

export const USER_SUPPORT_PHONE_NUMBER = 'tel:+49030255585508';
export const CARSALE24_URL =
  'https://app.carsale24.com/de-de/heycar?a_aid=heycar';

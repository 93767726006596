import { pagesWithVatRegex } from 'app/shared/utils/regexPagesWithVat';

export const isShowVatDisclaimer = (
  pathname: string,
  isWithFavourites: boolean,
) => {
  // Hide disclaimer for favourite, car comparison and search page if there are no cartiles/vehicles
  if (isWithFavourites && pathname === '/favourites') {
    return false;
  }
  return pagesWithVatRegex.test(pathname);
};

export const getPaddingTopForPageTemplate = (url: string) => {
  switch (url) {
    case '/auto-online-bestellen':
      return '128px';
    case '/gebrauchsspuren-qualitaet':
      return '128px';
    case '/autokauf-online-so-funktionierts':
      return '128px';
    case '/click-und-collect':
      return '128px';
    default:
      return '0';
  }
};

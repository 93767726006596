import {
  PURCHASING_OPTION,
  FINANCING_OPTION,
  LEASING_OPTION,
} from './constants';
import { IselectedFinancingOption } from './Types';

export const getFinancingOption = (
  selectedOptionIndex: number,
): IselectedFinancingOption => {
  switch (selectedOptionIndex) {
    case 0:
    default:
      return PURCHASING_OPTION;
    case 1:
      return FINANCING_OPTION;
    case 2:
      return LEASING_OPTION;
  }
};

export const getSelectedPriceRange = (
  selectedPrice: string,
): number[] | null => {
  const value = selectedPrice?.split('-');
  if (value[0]) {
    const selectedPriceRange = value.map((num) => Number(num));
    return selectedPriceRange;
  }
  return null;
};

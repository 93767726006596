/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { footerContent } from 'app/marketplace/shared/NewMarketplaceTopNavBar/NewMarketplaceTopNavBar.constants';

import { PaymentMenuItems } from '../../MenuItemsList';

export const PaymentItem = (isMobileDevice = false) => {
  return {
    useRouter: false,
    label: 'Online Bestellung',
    itemId: 'marken',
    itemCategories: PaymentMenuItems(),
    footercontent: footerContent(isMobileDevice, true),
  };
};

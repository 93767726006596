import { memo } from 'react';
import PropTypes from 'prop-types';

import { CartileLoadingStyle } from 'app/shared/modules/Cartile/CartileUIComponents/CartileUIComponents.css';
import { CartileMtlPrice } from 'app/shared/modules/Cartile/CartileUIComponents/CartilePrice/CartileMtlPrice/CartileMtlPrice';
import { CartileTotalPrice } from 'app/shared/modules/Cartile/CartileUIComponents/CartilePrice/CartileTotalPrice/CartileTotalPrice';
import {
  CartilePriceContainersMtlTotalPriceReducedSt,
  CartilePriceContainersSt,
} from 'app/shared/modules/Cartile/CartileUIComponents/CartilePrice/CartilePriceContainers/CartilePriceContainers.css';
import { CartileMtlPriceCalculatorLink } from 'app/shared/modules/Cartile/CartileUIComponents/CartilePrice/CartileMtlPriceCalculatorLink/CartileMtlPriceCalculatorLink';

const CartilePriceMTLDefaultReducedCmp = ({
  text,
  price,
  linkTo,
  isLoading,
  monthlyRate,
  vatReclaimable,
  handleTracking,
  highestPrice,
  hideDccVehicle,
}) => (
  <CartilePriceContainersSt
    isLoading={isLoading}
    css={[isLoading && CartileLoadingStyle]}
  >
    {!hideDccVehicle &&
      (monthlyRate ? (
        <CartileMtlPrice
          linkTo={linkTo}
          fontsize="16px"
          monthlyRate={monthlyRate}
          handleTracking={handleTracking}
        />
      ) : (
        <CartileMtlPriceCalculatorLink
          text={text}
          linkTo={linkTo}
          fontsize="12px"
          handleTracking={handleTracking}
        />
      ))}
    <CartilePriceContainersMtlTotalPriceReducedSt>
      <CartileTotalPrice
        discount
        size="small"
        price={price}
        vatReclaimable={vatReclaimable}
        highestPrice={highestPrice}
      />
    </CartilePriceContainersMtlTotalPriceReducedSt>
  </CartilePriceContainersSt>
);

CartilePriceMTLDefaultReducedCmp.propTypes = {
  isLoading: PropTypes.bool,
  monthlyRate: PropTypes.number,
  highestPrice: PropTypes.number,
  text: PropTypes.object.isRequired,
  price: PropTypes.number.isRequired,
  linkTo: PropTypes.string.isRequired,
  vatReclaimable: PropTypes.bool.isRequired,
  handleTracking: PropTypes.func.isRequired,
};

CartilePriceMTLDefaultReducedCmp.defaultProps = {
  highestPrice: 0,
  isLoading: false,
  monthlyRate: null,
};

export const CartilePriceMTLDefaultReduced = memo(
  CartilePriceMTLDefaultReducedCmp,
);

/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-call */
import styled, { css } from 'styled-components';
import { Flex } from '@rebass/grid';

import { media } from '../../../../../variables/media';
import { Icon } from '../../../../../Icon/Icon';
import { Link } from '../../../../../Link/Link';
import { NavFlyoutContainerSt } from '../../../NavFlyoutContainer/NavFlyoutContainer.css';

import { TextStProps } from './NavItemLabel.types';

export const LabelSt = styled.span`
  position: relative;
`;

export const LinkSt = styled(Link)`
  position: relative;
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: space-between;
  padding: 18px;
  cursor: pointer;
  font-family: inherit;
  color: ${(props) => props.theme.colors.tarmacGrey};

  &:hover {
    color: ${(props) => props.theme.colors.tarmacGrey};
  }

  ${media.lg(css`
    width: auto;
    font-weight: normal;
    font-size: 14px;
    justify-content: center;
    color: ${(props) => props.theme.colors.brandSecondaryLight};

    &:hover {
      color: ${(props) => props.theme.colors.brandSecondaryLighter};
      text-shadow: 0 0 0.5px;
    }
  `)}
`;

export const TextSt = styled(Flex) <TextStProps>`
  height: 100%;
  padding: 18px;
  text-decoration: none;
  font-family: inherit;
  color: ${(props) => props.theme.colors.tarmacGrey};

  ${(props) =>
    media.lg(css`
      color: ${props.theme.colors.brandSecondaryLight};
      font-weight: normal;
      font-size: 14px;
      padding: 18px;

      &:hover {
        ${NavFlyoutContainerSt} {
          transition: opacity 250ms ease-in 0s;
          opacity:1;
          height: auto;
          max-height: calc(80vh - 64px);
          min-height: 400px;
          overflow: visible;
        }

        ${LabelSt} {
          color: ${props.theme.colors.brandSecondaryLighter};
          text-shadow: 0 0 0.5px;

          ${props.hasSubMenu &&
      css`
              &:after {
                content: '';
                border: 8px solid ${props.theme.colors.brandPrimary};
                border-bottom-color: ${props.theme.colors.white};
                position: absolute;
                bottom: -18px;
                left: 50%;
                margin-left: -8px;
              }
            `
      };
      }
    `)}
`;

export const UserIconSt = styled(Icon)`
  display: none;
  ${media.lg(css`
    display: block;
  `)}
`;

export const BoxSt = styled(Flex) <{ isOpen?: boolean }>`
  position: relative;
  cursor: pointer;
  line-height: 1;
  margin-left: 24px;
  height: 64px;

  ${LabelSt} {
    padding-bottom: 3px;
  }
`;

export const IconSt = styled(Icon)`
  ${media.lg(css`
    display: none;
  `)}
`;

import { SESSION_TIME } from 'app/config';

import * as ActionTypes from './constants';

export const setUserCreated = () => {
  return (dispatch, getState) => {
    const userCreatedAt = getState().user.createdAt;

    if (!userCreatedAt) {
      dispatch({
        type: ActionTypes.SET_USER_CREATED,
        payload: { timestamp: Date.now() },
      });
    }
  };
};

export const setUserState = () => {
  return (dispatch, getState) => {
    const userCreatedAt = getState().user.createdAt;
    let isReturningUser = false;

    if (userCreatedAt) {
      const currentTimeStamp = Date.now();

      if (userCreatedAt + SESSION_TIME < currentTimeStamp) {
        isReturningUser = true;
      }
    }

    dispatch({
      type: ActionTypes.SET_USER_STATE,
      payload: {
        isReturningUser,
      },
    });
  };
};

export const setSignedUpForNewsletter = (payload) => {
  return { type: ActionTypes.SET_SIGNED_UP_FOR_NEWSLETTER, payload };
};

export const closeProfileInfo = () => {
  return { type: ActionTypes.CLOSE_PROFILE_INFO };
};

export const showNotificationIndicator = () => {
  return { type: ActionTypes.SHOW_NOTIFICATION_INDICATOR };
};

export const hideNotificationIndicator = () => {
  return { type: ActionTypes.HIDE_NOTIFICATION_INDICATOR };
};

export const removeLeadVehicleLists = () => {
  return { type: ActionTypes.REMOVE_LEAD_VEHICLE_LISTS };
};

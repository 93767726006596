import React from 'react';
import animateScrollTo from 'animated-scroll-to';

import { useWindowScroll } from './hook/useWindowScroll';
import { ScrollTopButtonSt } from './ScrollToTopBtn.css';

interface ScrollToTopBtnProps {
  source?: string;
  trackEvent?: () => void;
}
export const ScrollToTopBtn = ({
  source,
  trackEvent,
}: ScrollToTopBtnProps): JSX.Element => {
  const showScrollTop = useWindowScroll(true);
  const handleClick = () => {
    if (trackEvent) {
      trackEvent();
    }
    // Ignoring this ts lint rule beacause the promise returned from library function here is not resolved.
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    animateScrollTo(0, {
      speed: 200,
      easing: t => {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-return
        return t;
      },
    });
  };

  return (
    <ScrollTopButtonSt
      variant="primary"
      isShown={showScrollTop}
      onClick={handleClick}
      source={source}
    >
      <i className="if-icon-arrow-up-short-short" />
    </ScrollTopButtonSt>
  );
};

ScrollToTopBtn.displayName = 'ScrollToTopBtn';

import styled, { css } from 'styled-components';

import { media, magazine } from 'app/shared/styles_js/variables';

export const TwoArticlesLayoutSt = styled.div`
  display: flex;
  flex-flow: row wrap;

  > div {
    width: 100%;
  }

  ${media.md(css`
    margin: 0 -16px;
    > div {
      width: 50%;
      padding: 0 16px;
    }
  `)};
`;

export const ThreeArticlesLayoutSt = styled.div`
  display: flex;
  flex-flow: row wrap;

  > div {
    width: 100%;
  }

  ${media.md(css`
    margin: 0 -16px;
    > div {
      width: 33.33%;
      padding: 0 16px;
    }
  `)};
`;

export const FourArticlesLayoutSt = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  margin: 0 16px;

  > div {
    width: 50%;
    padding: 0 8px;
  }

  ${media.md(css`
    justify-content: unset;
    margin: 0 -16px;
    > div {
      width: 50%;
      padding: 0 16px;
    }
  `)};
  ${media.lg(css`
    > div {
      width: 25%;
    }
  `)};
`;

// this is main article container and is used in every article component
export const ArticleContainerSt = styled.div`
  display: block;
  width: 100%;
  padding: ${magazine.psm} 24px;
  margin-right: auto;
  margin-left: auto;

  ${media.md(css`
    width: 93%;
    max-width: 832px;
    padding: ${magazine.pmd} 32px;
  `)};
`;

export const LoadingPageWrapperSt = styled.div`
  display: flex;
  width: 100%;
  height: 100vh;
  align-items: center;
  justify-content: center;
`;

export const LoadingSpinnerWrapperSt = styled(ArticleContainerSt)`
  display: flex;
  height: 80vh;
  align-items: center;
  justify-content: center;
`;

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { InputBox } from '../InputBox/InputBox';

import { getSeparatorPositions, addMaskToValue } from './utils';

export const InputBoxMasked = (props) => {
  const {
    placeholder,
    separators,
    name,
    id,
    className,
    inputType,
    autoFocus,
    blurOnEnter,
    checkValidation,
    dataQa,
    disabled,
    errorText,
    isRequired,
    isLabelBelowInput,
    isMobileDevice,
    hasError,
    helperText,
    label,
    onBlur,
    tabIndex,
    onChange,
    value,
  } = props;
  const [separatorsInfo, setSeparatorsInfo] = useState([]);
  const [key, setKey] = useState('');

  useEffect(() => {
    setSeparatorsInfo(getSeparatorPositions(placeholder, separators));
  }, []);

  const handleChange = (e) => {
    e.preventDefault();
    const updatedEvent = e;

    updatedEvent.target.value = addMaskToValue(
      separatorsInfo,
      e.target.value,
      separators,
      inputType,
      key,
    );

    checkValidation(updatedEvent);
    onChange(updatedEvent);
  };

  const handleBlur = (e) => {
    e.preventDefault();

    const updatedEvent = e;

    updatedEvent.target.value = addMaskToValue(
      separatorsInfo,
      e.target.value,
      separators,
      inputType,
    );

    checkValidation(updatedEvent);
    onBlur(updatedEvent);
  };
  const onKeyDown = ({ key }) => {
    setKey(key);
  };

  return (
    <InputBox
      onChange={handleChange}
      value={value}
      name={name}
      id={id}
      onKeyDown={onKeyDown}
      className={className}
      inputType={inputType}
      maxLength={placeholder.length}
      autoFocus={autoFocus}
      blurOnEnter={blurOnEnter}
      dataQa={dataQa}
      disabled={disabled}
      errorText={errorText}
      isRequired={isRequired}
      isLabelBelowInput={isLabelBelowInput}
      isMobileDevice={isMobileDevice}
      hasError={hasError}
      helperText={helperText}
      label={label}
      onBlur={handleBlur}
      tabIndex={tabIndex}
      placeholder={placeholder}
    />
  );
};

InputBoxMasked.defaultProps = {
  autoFocus: false,
  blurOnEnter: false,
  checkValidation: () => {},
  className: '',
  dataQa: '',
  disabled: false,
  errorText: '',
  id: '',
  inputType: 'text',
  isRequired: false,
  isLabelBelowInput: false,
  isMobileDevice: false,
  hasError: false,
  helperText: '',
  label: '',
  onBlur: () => {},
  tabIndex: 0,
};

InputBoxMasked.propTypes = {
  autoFocus: PropTypes.bool,
  blurOnEnter: PropTypes.bool,
  checkValidation: PropTypes.func,
  className: PropTypes.string,
  dataQa: PropTypes.string,
  value: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  errorText: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  hasError: PropTypes.bool,
  helperText: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  id: PropTypes.string,
  inputType: PropTypes.string,
  isLabelBelowInput: PropTypes.bool,
  isMobileDevice: PropTypes.bool,
  isRequired: PropTypes.bool,
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  onBlur: PropTypes.func,
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string.isRequired,
  tabIndex: PropTypes.number,
  separators: PropTypes.string.isRequired,
};

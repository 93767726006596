import { Flex } from '@rebass/grid';
import React from 'react';

import { LayoutChild, LayoutWrapperSt } from './TwoColumnContentLayout.css';
import { TwoColumnContentLayoutProps } from './TwoColumnContentLayout.types';

export const TwoColumnContentLayout = ({
  leftContent,
  rightContent,
  leftWidth,
  bg = 'brandPrimary',
}: TwoColumnContentLayoutProps): JSX.Element => {
  return (
    <LayoutWrapperSt
      flexDirection={['column', 'column', 'row']}
      padding={[3]}
      bg={bg}
    >
      <LayoutChild
        data-test-id="left-content"
        paddingRight={[0, 2]}
        leftWidth={leftWidth}
        paddingLeft={[0, 2]}
      >
        {leftContent}
      </LayoutChild>
      <Flex
        paddingRight={[0, 2]}
        flex={1}
        paddingLeft={[0, 2, 3, 4]}
        data-test-id="right-content"
      >
        {rightContent}
      </Flex>
    </LayoutWrapperSt>
  );
};

import { IndexRoute, Route } from 'react-router';

import { ConnectedHome as Home } from 'app/marketplace/home/Home/ConnectedHome';
import { ConnectedPageTemplate } from 'app/shared/modules/pageTemplate/PageTemplate/ConnectedPageTemplate';

export const HomeRoutes = (
  <Route
    name="home"
    component={(props) => (
      <ConnectedPageTemplate target="home" isHome {...props} />
    )}
  >
    <IndexRoute component={Home} />
    <Route path="/unsubscribe" component={Home} />
  </Route>
);

import styled, { css } from 'styled-components';

import { Heading6, LinkButton } from 'app/shared/ui';
import { color, media } from 'app/shared/styles_js/variables';

export const MIN_IMAGE_HEIGHT = '120px';

export const SpecialOfferTileSt = styled.div`
  box-shadow: ${({ $useShadowForHeroImage }) =>
    $useShadowForHeroImage && '0 0 10px 0 rgba(0, 0, 0, 0.15)'};
  border-radius: 4px;
  transition: transform 0.2s;
  background-color: ${color.white};

  &:hover {
    cursor: pointer;
    transform: scale(1.02);
  }
`;

export const SpecialOfferTileImageSt = styled.img`
  width: 100%;
  border-radius: 4px 4px 0 0;
`;

export const SpecialOfferTileTextSt = styled.div`
  height: 51px;
  padding: 8px 16px;
  color: ${color.brandSecondary};

  ${media.md(css`
    height: 53px;
    padding: 16px;
  `)}
  ${media.lg(css`
    padding-top: 8px;
  `)}
`;

export const SpecialOfferDescriptionSt = styled(Heading6)`
  font-size: 12px;
  text-align: center;
  line-height: 1.4;
  font-weight: 100;
`;

export const UnderlinedTextSt = styled.span`
  display: block;
  padding-bottom: 18px;
  text-decoration: underline;
  text-align: center;
  color: ${({ theme: { colors } }) => colors.mintDark};
  font-weight: ${({ theme: { fontWeights } }) => fontWeights.bold};
  transition: color 200ms ease-in-out;

  &:hover,
  &:focus {
    text-decoration: underline;
    color: ${({ theme: { colors } }) => colors.heycarMint};
  }
`;

export const SpecialOfferBannerSt = styled.div`
  width: 100%;
  height: 300px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;

  ${media.md(css`
    background-image: ${({ mdImage }) => `url(${mdImage})`};
  `)}

  ${media.lg(css`
    background-image: ${({ lgImage }) => `url(${lgImage})`};
  `)}

  ${media.xl(css`
    background-image: ${({ xlImage }) => `url(${xlImage})`};
  `)}
`;

export const LinkButtonSt = styled(LinkButton)`
  margin: 50px 0;
`;

export const SpecialOfferResponsiveWrapperSt = styled.div`
  width: 100%;
  text-align: center;
`;

import PropTypes from 'prop-types';

import { IndeterminateLoadingSpinner } from 'app/shared/ui';

import { ChildrenShape, noop } from '../../utils/utils';

import {
  ErrorTextSt,
  IconAfterSt,
  IconButtonSt,
  InputContainerSt,
  InputSt,
} from './InputWithButton.css';

export const InputWithButton = ({
  children,
  onButtonClick,
  formElementLabels,
  ...props
}) => (
  <>
    <InputContainerSt extraStyles={props.extraStyles(props)}>
      <InputSt
        {...props}
        id={formElementLabels.inputId}
        aria-label={formElementLabels.inputAriaLabel}
        ref={props.innerRef}
        autoComplete="off"
        data-testid={`shared-components-InputWithButton-${formElementLabels.inputId}`}
      />
      <IconButtonSt
        ariaLabel={formElementLabels.buttonAriaLabel}
        onClick={onButtonClick}
        disabled={props.disabled}
        data-testid={`shared-components-InputWithButton-IconButton-${formElementLabels.inputId}`}
      >
        {props.pending ? (
          <IndeterminateLoadingSpinner type="secondary" size="m" />
        ) : (
          <IconAfterSt className={props.iconClass} disabled={props.disabled} />
        )}
      </IconButtonSt>
      {children}
    </InputContainerSt>
    {props.errorText && <ErrorTextSt>{props.errorText}</ErrorTextSt>}
  </>
);

InputWithButton.propTypes = {
  pending: PropTypes.bool,
  disabled: PropTypes.bool,
  errorText: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  iconClass: PropTypes.string.isRequired,
  innerRef: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
  children: ChildrenShape,
  extraStyles: PropTypes.func,
  onButtonClick: PropTypes.func,
  formElementLabels: PropTypes.shape({
    inputId: PropTypes.string,
    inputAriaLabel: PropTypes.string,
    buttonAriaLabel: PropTypes.string,
  }),
};

InputWithButton.defaultProps = {
  pending: false,
  disabled: false,
  errorText: '',
  innerRef: {},
  formElementLabels: {},
  children: null,
  onButtonClick: noop,
  extraStyles: noop,
};

import styled, { css } from 'styled-components';

import { media } from '../../../../shared/styles_js/variables';

export const MarkdownContainerSt = styled.div`
  display: block;

  h2,
  h3 {
    text-align: center;
    word-wrap: break-word;
  }

  h2,
  h3,
  h4,
  h5,
  p {
    font-weight: 500;
  }

  h2 {
    font-size: 24px;
    line-height: 1.33;
  }

  h3 {
    font-size: 32px;
    line-height: 1.5;
  }

  h4,
  h5 {
    font-size: 24px;
    line-height: 1.33;
  }

  h6 {
    font-size: 16px;
    font-weight: 500;
    line-height: 2;
  }

  p,
  li {
    font-size: 16px;
    line-height: 2;
  }

  ol li {
    margin-bottom: 0.5rem;
  }

  a {
    color: $brand-primary;
    font-weight: 500;
  }

  p {
    padding-top: 32px;
    font-weight: 200;
  }

  strong {
    font-weight: 500;
  }

  ${media.md(css`
    h2 {
      font-size: 32px;
      line-height: 1;
    }

    h3 {
      font-size: 48px;
      line-height: 1.67;
    }
  `)}

  ${({ normalContentFontWeight }) =>
    /* istanbul ignore next */
    normalContentFontWeight &&
    `
    li, p {
      font-weight:normal
    }
  `}
`;

import { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Flex } from '@rebass/grid';

import { Link, Text2, Icon } from 'app/shared/ui';
import { LazyFinancingChoice } from 'app/marketplace/contactDealer/FinancingChoice/LazyFinancingChoice';
import { trackAction } from 'app/shared/utils/tracking';
import { ConnectedModal } from 'app/shared/modules/overlay/defaultOverlays/modal/Modal';
import { LazyMail } from 'app/marketplace/contactDealer/Mail/MailModal/LazyMail';
import { CampaignDataShape } from 'app/shared/utils/propTypes';
import { noop } from 'app/shared/utils/utils';
import { getMatchingCampaignsMultiOffers } from 'app/shared/modules/Campaign/CampaignMultipleOffer/util';

import { trackOnOpen } from './utils';

export const MailModal = ({
  target,
  location,
  campaignData,
  isRequestVideo,
  vehicle,
  eventLabel,
  open,
  showBackButton,
  oneClickLeads,
  isFlag248OneClickLeadsShow,
  shouldShowCampaignInfoModal,
  screenType,
  visibleCampaign,
  ...props
}) => {
  const [showCampaignInfoModal, setShowCampaignInfoModal] = useState(
    shouldShowCampaignInfoModal,
  );
  const hasCampaign = !!campaignData?.primaryCampaign;

  const isMultipleOfferCampaign = !!getMatchingCampaignsMultiOffers(
    campaignData?.matchingCampaigns,
  );

  useEffect(() => {
    let oneClickLeadsCheckboxVisible = null;
    if (isFlag248OneClickLeadsShow) {
      oneClickLeadsCheckboxVisible = oneClickLeads?.length !== 0;
    }

    // It shouldn't track the event if the campaign info modal pops up first via showCampaignInfoModal
    if (!showCampaignInfoModal && screenType !== 'campaign_info') {
      trackOnOpen({
        vehicle,
        hasCampaign: !isRequestVideo && hasCampaign,
        isRequestVideo,
        target,
        eventLabel,
        oneClickLeadsCheckboxVisible,
      });
    }
  }, []);

  useEffect(() => {
    setShowCampaignInfoModal(
      shouldShowCampaignInfoModal ||
        (screenType !== 'form' && isMultipleOfferCampaign),
    );
  }, [vehicle, location, isRequestVideo, shouldShowCampaignInfoModal]);

  const { matchingCampaigns } = campaignData;
  const hasMultiCampaign =
    matchingCampaigns &&
    Array.isArray(matchingCampaigns) &&
    matchingCampaigns.length > 1;

  const getTopBarTitle = useCallback(() => {
    /* istanbul ignore next */
    if (showCampaignInfoModal) {
      return hasMultiCampaign || isMultipleOfferCampaign
        ? 'Aktuelle Angebote'
        : 'Aktuelles Angebot';
    }
    if (isRequestVideo) return 'Video-Anfrage';
    return 'Nachricht an den Händler';
  }, [showCampaignInfoModal, isRequestVideo]);

  const getHeadline = useCallback(() => {
    if (
      visibleCampaign === 'SHOW_DEALERCASH_ONLY' ||
      (isMultipleOfferCampaign && showCampaignInfoModal)
    ) {
      return '';
    }
    /* istanbul ignore next */
    if (showCampaignInfoModal) {
      return hasMultiCampaign ? 'Aktuelle Angebote' : 'Aktuelles Angebot';
    }
    if (isRequestVideo) {
      return 'Deine Nachricht an den Händler zur Video-Anfrage';
    }
    return 'Nachricht an den Händler';
  }, [showCampaignInfoModal, isRequestVideo]);

  const isCheckoutPage = location?.pathname?.startsWith('/checkout');

  return (
    <ConnectedModal
      ariaLabel="email lead modal"
      title={getTopBarTitle()}
      headline={getHeadline()}
      backButton={
        showBackButton ? (
          // eslint-disable-next-line jsx-a11y/anchor-is-valid
          <Link
            id="finance_back_to_selection_button_leadrequest"
            color="micaBlue"
            hoverColor="actionBlue"
            textDecoration="none"
            fontWeight="normal"
            onClick={() => {
              open(LazyFinancingChoice, {
                props: {
                  vehicle,
                  open,
                  defaultSelection: 'leadrequest',
                },
              });
              trackAction('finance_back_to_selection', {
                category: 'PDP',
              });
            }}
          >
            <Text2 as={Flex} alignItems="center">
              <Icon icon="arrow-left-short" mr={2} />
              Zurück zur Auswahl
            </Text2>
          </Link>
        ) : null
      }
      visibleCampaign={props?.visibleCampaign}
      showCampaignInfoModal={showCampaignInfoModal}
      isCheckoutPage={isCheckoutPage}
      isMultipleOfferCampaign={isMultipleOfferCampaign}
    >
      <LazyMail
        showCampaignInfoModal={showCampaignInfoModal}
        campaignData={campaignData}
        setShowCampaignInfoModal={setShowCampaignInfoModal}
        vehicleObj={vehicle}
        eventLabel={eventLabel}
        target={target}
        isRequestVideo={isRequestVideo}
        visibleCampaign={visibleCampaign}
        {...props}
      />
    </ConnectedModal>
  );
};

MailModal.propTypes = {
  vehicle: PropTypes.shape({
    id: PropTypes.string.isRequired,
    categories: PropTypes.arrayOf(PropTypes.string),
    make: PropTypes.shape({
      displayName: PropTypes.string,
    }),
    model: PropTypes.shape({
      displayName: PropTypes.string,
      source: PropTypes.string,
    }),
    dealer: PropTypes.shape({
      name: PropTypes.string,
      location: PropTypes.shape({
        city: PropTypes.string,
        zip: PropTypes.string,
      }),
    }),
  }).isRequired,
  location: PropTypes.object,
  isRequestVideo: PropTypes.bool,
  campaignData: CampaignDataShape,
  target: PropTypes.string,
  eventLabel: PropTypes.string,
  open: PropTypes.func,
  showBackButton: PropTypes.bool,
  oneClickLeads: PropTypes.array,
  isFlag248OneClickLeadsShow: PropTypes.bool,
  shouldShowCampaignInfoModal: PropTypes.bool,
  visibleCampaign: PropTypes.string,
  screenType: PropTypes.string,
};

MailModal.defaultProps = {
  target: 'PDP',
  location: {},
  isRequestVideo: false,
  campaignData: {
    primaryCampaign: null,
    matchingCampaigns: [],
    primaryCampaignMarker: null,
  },
  eventLabel: undefined,
  open: noop,
  showBackButton: false,
  oneClickLeads: [],
  isFlag248OneClickLeadsShow: false,
  shouldShowCampaignInfoModal: false,
  visibleCampaign: '',
  screenType: 'campaign_info',
};

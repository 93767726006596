import { memo } from 'react';
import PropTypes from 'prop-types';

import {
  CartileVerticalContentContainerSt,
  CartileHorizontalContentContainerSt,
} from 'app/shared/modules/Cartile/CartileTemplates/CartileContentContainer/CartileContentContainer.css';
import { CartileContentInfos } from 'app/shared/modules/Cartile/CartileTemplates/CartileContentContainer/CartileContentInfos/CartileContentInfos';
import { CartileContentFooter } from 'app/shared/modules/Cartile/CartileTemplates/CartileContentContainer/CartileContentFooter/CartileContentFooter';
import { CartileContentHeader } from 'app/shared/modules/Cartile/CartileTemplates/CartileContentContainer/CartileContentHeader/CartileContentHeader';

const CartileContentContainerCmp = ({
  linkTo,
  target,
  isHorizontal,
  isUnavailable,
  handleTracking,
  cartileContentContainer,
  className,
}) => {
  const {
    CartileContentHeaderChildren,
    CartileContentHeaderChildrenProps,
    CartileContentInfosChildren,
    CartileContentInfosChildrenProps,
    CartileContentFooterLocationChildren,
    CartileContentFooterLocationChildrenProps,
    CartileContentFooterPriceChildren,
    CartileContentFooterPriceChildrenProps,
    CartileFooterCtaContainerChildren,
    CartileFooterCtaContainerChildrenProps,
  } = cartileContentContainer;

  return isHorizontal ? (
    <CartileHorizontalContentContainerSt isUnavailable={isUnavailable}>
      <CartileContentHeader className={className}>
        <CartileContentHeaderChildren {...CartileContentHeaderChildrenProps} />
      </CartileContentHeader>
      <CartileContentInfos isHorizontal={isHorizontal}>
        <CartileContentInfosChildren {...CartileContentInfosChildrenProps} />
      </CartileContentInfos>

      <CartileContentFooter>
        <CartileContentFooterLocationChildren
          {...CartileContentFooterLocationChildrenProps}
        />
        <CartileContentFooterPriceChildren
          {...CartileContentFooterPriceChildrenProps}
        />
      </CartileContentFooter>
    </CartileHorizontalContentContainerSt>
  ) : (
    <CartileVerticalContentContainerSt isUnavailable={isUnavailable}>
      <CartileContentHeader
        linkTo={linkTo}
        target={target}
        handleTracking={handleTracking}
      >
        <CartileContentHeaderChildren {...CartileContentHeaderChildrenProps} />
      </CartileContentHeader>
      <CartileContentInfos
        linkTo={linkTo}
        target={target}
        isHorizontal={isHorizontal}
        handleTracking={handleTracking}
      >
        <CartileContentInfosChildren {...CartileContentInfosChildrenProps} />
      </CartileContentInfos>
      <CartileContentFooter
        linkTo={linkTo}
        target={target}
        handleTracking={handleTracking}
      >
        <CartileContentFooterLocationChildren
          {...CartileContentFooterLocationChildrenProps}
        />
        <CartileContentFooterPriceChildren
          {...CartileContentFooterPriceChildrenProps}
        />
      </CartileContentFooter>
      <CartileFooterCtaContainerChildren
        {...CartileFooterCtaContainerChildrenProps}
      />
    </CartileVerticalContentContainerSt>
  );
};

CartileContentContainerCmp.propTypes = {
  target: PropTypes.string,
  isHorizontal: PropTypes.bool,
  isUnavailable: PropTypes.bool,
  linkTo: PropTypes.string.isRequired,
  handleTracking: PropTypes.func.isRequired,
  cartileContentContainer: PropTypes.objectOf(PropTypes.any.isRequired)
    .isRequired,

  className: PropTypes.string.isRequired,
};

CartileContentContainerCmp.defaultProps = {
  target: 'CLP',
  isHorizontal: false,
  isUnavailable: false,
};

export const CartileContentContainer = memo(CartileContentContainerCmp);

import { memo } from 'react';
import PropTypes from 'prop-types';
import { browserHistory } from 'react-router';

import {
  CartileMtlPriceLinkSt,
  CartileMtlPriceTextSt,
  CartileMtlPriceNumberSt,
} from 'app/shared/modules/Cartile/CartileUIComponents/CartilePrice/CartileMtlPrice/CartileMtlPrice.css';
import { CartileLoadingStyle } from 'app/shared/modules/Cartile/CartileUIComponents/CartileUIComponents.css';
import { formatCurrency } from 'app/shared/utils/formatNumber';

const CartileMtlPriceCmp = ({
  linkTo,
  fontsize,
  isLoading,
  monthlyRate,
  handleTracking,
}) => {
  const handleOnClick = (e) => {
    e.preventDefault();
    e.stopPropagation();
    handleTracking();
    browserHistory.push(linkTo);
  };
  const value = (
    <CartileMtlPriceNumberSt fontsize={fontsize}>
      {formatCurrency(monthlyRate)}
    </CartileMtlPriceNumberSt>
  );
  return (
    <CartileMtlPriceLinkSt
      onClick={handleOnClick}
      css={[isLoading && CartileLoadingStyle]}
    >
      <CartileMtlPriceTextSt>z.B. {value} /Monat*</CartileMtlPriceTextSt>
    </CartileMtlPriceLinkSt>
  );
};

CartileMtlPriceCmp.propTypes = {
  text: PropTypes.object,
  isLoading: PropTypes.bool,
  linkTo: PropTypes.string.isRequired,
  fontsize: PropTypes.string.isRequired,
  monthlyRate: PropTypes.number.isRequired,
  handleTracking: PropTypes.func.isRequired,
};

CartileMtlPriceCmp.defaultProps = {
  text: {},
  isLoading: false,
};

export const CartileMtlPrice = memo(CartileMtlPriceCmp);

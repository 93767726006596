import styled, { css } from 'styled-components';

import { Text } from '../../styles_js/base';

export { Text };

export const handleAnchorAlignment = (value) => {
  switch (value) {
    case 'left':
      return css`
        left: 0;
        right: auto;
      `;
    case 'center':
      return css`
        left: 0;
        right: 0;
        margin: 0 auto;
      `;
    case 'right':
      return css`
        left: auto;
        right: 0;
      `;
    default:
      return css`
        left: 0;
        right: auto;
      `;
  }
};

export const HeadingAnchorStyle = css`
  position: relative;
  padding-top: 8px;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    ${({ textAlign }) => handleAnchorAlignment(textAlign)};
    width: 2em;
    height: 4px;
    min-width: 32px;
    background-color: ${({ theme }) => theme.colors.brandPrimary};
  }
`;

const Heading = styled(Text)`
  ${(props) => props.useAnchor && HeadingAnchorStyle}
`;

export const Heading1 = styled(Heading).attrs((props) => ({
  as: 'h1',
  fontSize: [32, 40, 48, 64],
  lineHeight: [1.5, 1.4, 1.3, 1.375],
  ...props,
}))``;

Heading1.displayName = 'Heading1';

export const Heading2 = styled(Heading).attrs((props) => ({
  as: 'h2',
  fontSize: [32, null, 40, 48],
  lineHeight: [1.5, null, 1.4, 1.3],
  ...props,
}))``;

Heading2.displayName = 'Heading2';

export const Heading3 = styled(Heading).attrs((props) => ({
  as: 'h3',
  fontSize: [24, null, 32, 40],
  lineHeight: [1.67, null, 1.5, 1.4],
  ...props,
}))``;

Heading3.displayName = 'Heading3';

export const Heading4 = styled(Heading).attrs((props) => ({
  as: 'h4',
  fontSize: [20, 24, null, 32],
  lineHeight: [1.6, 1.67, null, 1.5],
  ...props,
}))``;

Heading4.displayName = 'Heading4';

export const Heading5 = styled(Heading).attrs((props) => ({
  as: 'h5',
  fontSize: [20, null, null, 24],
  lineHeight: [1.6, null, null, 1.67],
  ...props,
}))``;

Heading5.displayName = 'Heading5';

export const Heading6 = styled(Heading).attrs((props) => ({
  as: 'h6',
  fontSize: 20,
  lineHeight: 1.6,
  ...props,
}))``;

Heading6.displayName = 'Heading6';

export const Heading7 = styled(Heading).attrs((props) => ({
  as: 'h6',
  fontSize: [16, null, null, 20],
  lineHeight: 1.6,
  ...props,
}))``;

Heading7.displayName = 'Heading7';

export const Text1 = styled(Text).attrs((props) => ({
  as: 'p',
  fontSize: [14, 16],
  lineHeight: [1.71, 2],
  ...props,
}))``;

Text1.displayName = 'Text1';

export const Text2 = styled(Text).attrs((props) => ({
  as: 'span',
  fontSize: [12, 14],
  lineHeight: [1.66, 1.71],
  ...props,
}))``;

Text2.displayName = 'Text2';

export const Text3 = styled(Text).attrs((props) => ({
  as: 'span',
  fontSize: 12,
  lineHeight: 1.66,
  ...props,
}))``;

Text3.displayName = 'Text3';

export const Text4 = styled(Text).attrs((props) => ({
  as: 'span',
  fontSize: 10,
  lineHeight: 1.6,
  ...props,
}))``;

Text4.displayName = 'Text4';

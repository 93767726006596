export const SET_BREAKPOINT = 'SET_BREAKPOINT';
export const SET_SHOW_DISCLAIMER_FULL = 'SET_SHOW_DISCLAIMER_FULL';
export const TOGGLE_TOPNAVBAR = 'TOGGLE_TOPNAVBAR';
export const TOGGLE_SCROLL_DIRECTION = 'TOGGLE_SCROLL_DIRECTION';
export const TOGGLE_IS_STUCK = 'TOGGLE_IS_STUCK';
export const TOGGLE_HAS_BOX_SHADOW = 'TOGGLE_HAS_BOX_SHADOW';
export const TOGGLE_STATUSBAR_HAS_BOX_SHADOW =
  'TOGGLE_STATUSBAR_HAS_BOX_SHADOW';
export const MOUSE_EXIT_SCREEN = 'EXIT_INTENT/MOUSE_EXIT_SCREEN';
export const TOP = 'DIRECTION/TOP';
export const RIGHT = 'DIRECTION/RIGHT';
export const BOTTOM = 'DIRECTION/BOTTOM';
export const LEFT = 'DIRECTION/LEFT';
export const SET_IS_AD_BLOCKER_DETECTED = 'SET_IS_AD_BLOCKER_DETECTED';

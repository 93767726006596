/* istanbul ignore file */
/* eslint-disable react/jsx-props-no-spreading */
import { useEffect } from 'react';
import PropTypes from 'prop-types';

import { Link } from 'app/shared/ui';
import { ConnectedGamBanner } from 'app/shared/modules/Campaign/GAM/GamBanner/ConnectedGamBanner';
import { GAM_AD_UNITS } from 'app/shared/modules/Campaign/GAM/ad-units';
import { noop } from 'app/shared/utils/utils';
import { trackAction } from 'app/shared/utils/tracking';
import { breakpointShape } from 'app/shared/utils/propTypes';
import { useSentryTags } from 'app/shared/hooks/useSentryTags';
import { useCustomTargetingByFilters } from 'app/shared/modules/Campaign/GAM/hooks/useCustomTargetingByFilters.ts';

import {
  BannerContainerSt,
  SpecialOffersBannerSt,
} from './SpecialOffersBanner.css';

const { CLP_BANNER } = GAM_AD_UNITS;

export const SpecialOffersBanner = ({
  breakpoint,
  specialOffer,
  getSpecialOffers,
  getSpecialOffersClpBanner,
  clpBanner,
  location,
  selectedFilters,
  showCLPBannerInDifferentPosition,
  renderWhenViewable,
}) => {
  useSentryTags({ GROWTH_DE: true });
  const { customTargeting, markerIds } = useCustomTargetingByFilters(
    selectedFilters,
  );

  useEffect(() => {
    const isPreviewContentful = location?.query?.isPreviewContentful === null;

    if (!clpBanner) getSpecialOffersClpBanner(isPreviewContentful);
    if (!specialOffer) getSpecialOffers(isPreviewContentful);
  }, []);

  /*
   * Gets fields from the special offer first, and then from clp banner if there is no special offer
   */
  let fields = {};
  if (specialOffer?.fields?.xsBanner) {
    fields = specialOffer.fields;
  } else if (clpBanner?.fields?.xsBanner) {
    fields = clpBanner.fields;
  }
  const { xsBanner, mdBanner, lgBanner, xlBanner, link, trackingData } = fields;

  const bannerMap = {
    sm: xsBanner,
    md: mdBanner,
    lg: lgBanner,
    xl: xlBanner,
  };
  const imageFile = bannerMap[breakpoint]?.fields?.file;

  const imageAttrs = {
    src: imageFile?.url,
    alt: 'spezielle Angebote',
    width: imageFile?.details?.image?.width,
    height: imageFile?.details?.image?.height,
  };

  /* istanbul ignore next */
  const trackClick = () => {
    trackAction(
      trackingData?.fields.name || 'special_offers_clp_banner_cta_click',
      {
        category: trackingData?.fields.category || 'special_offers_banner',
        label:
          trackingData?.fields.label ||
          'cta in special offers banner in the clp',
        description:
          trackingData?.fields.description ||
          'user clicks on the cta in the clp special offers banner',
      },
    );
  };

  const ContentfulBanner = link ? (
    <Link
      to={link}
      title={imageAttrs.alt}
      onClick={trackClick}
      // to fix not found (404) issue for LPs. See: https://heycar.atlassian.net/browse/PROMO-3196
      useRouter={link.includes('gebrauchtwagen')}
    >
      <SpecialOffersBannerSt {...imageAttrs} />
    </Link>
  ) : (
    <SpecialOffersBannerSt {...imageAttrs} />
  );

  return (
    <BannerContainerSt
      showCLPBannerInDifferentPosition={showCLPBannerInDifferentPosition}
    >
      <ConnectedGamBanner
        adUnitId={CLP_BANNER.adUnitId}
        adUnitPath={CLP_BANNER.adUnitPath}
        sizes={CLP_BANNER.sizes}
        bannerArea={CLP_BANNER.bannerArea}
        {...(customTargeting && { targeting: customTargeting })}
        fallbackComponent={ContentfulBanner}
        trackingData={CLP_BANNER.trackingData}
        renderWhenViewable={renderWhenViewable}
        collectDurationMetric
        markerIds={markerIds}
      />
    </BannerContainerSt>
  );
};

SpecialOffersBanner.propTypes = {
  breakpoint: breakpointShape,
  /** The current Special Offer Banner */
  specialOffer: PropTypes.object,
  /** Action to get the current Special Offer Bannner */
  getSpecialOffers: PropTypes.func,
  /** Action to get the default CLP Special Offer Bannner */
  getSpecialOffersClpBanner: PropTypes.func,
  /** The Special Offers default CLP Banner */
  clpBanner: PropTypes.object,
  location: PropTypes.object,
  selectedFilters: PropTypes.array,
  showCLPBannerInDifferentPosition: PropTypes.bool,
  renderWhenViewable: PropTypes.bool,
  isD2CFilterSelected: PropTypes.bool,
};

SpecialOffersBanner.defaultProps = {
  breakpoint: 'sm',
  specialOffer: null,
  getSpecialOffersClpBanner: noop,
  getSpecialOffers: noop,
  clpBanner: null,
  location: {},
  selectedFilters: [],
  showCLPBannerInDifferentPosition: false,
  renderWhenViewable: false,
  isD2CFilterSelected: false,
};

export const CAPTIVES = {
  BMW: ['bmwb', 'bmwfs'],
  MERCEDES: ['mbb'],
  OEM: ['independent', 'oem'],
  PORSCHE: ['pfs'],
  RENAULT: ['rb'],
  NISSAN: ['nb'],
  VOLKSWAGEN: ['vwfs', 'aeb'],
};

export const TOGGLE_WIZARDPOPUP = 'FDL/TOGGLE_WIZARDPOPUP';

export const SET_SHOW_FIXED_BUTTON = 'FDL/SET_SHOW_FIXED_BUTTON';

export const FINANCING_OPTIONS = {
  PRIVATE_LEASING: 'privateLeasing',
  TWO_WAY_FINANCING: 'twoWayFinancing',
  THREE_WAY_FINANCING: 'threeWayFinancing',
  STANDARD_FINANCING: 'standardFinancing',
};

import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useInView } from 'react-intersection-observer';

import {
  TitleSt,
  MoreLikeThisSt,
  ListingsContainerSt,
  ListingsContainerModalSt,
} from 'app/marketplace/moreLikeThisListings/MoreLikeThisListings/MoreLikeThisListings.css';
import { noop } from 'app/shared/utils/utils';
import { handleTrackingUserViewMLT } from 'app/marketplace/moreLikeThisListings/MoreLikeThisListingsCarousel/MoreLikeThisListingsCarousel';

export const modalVehicleListSizeMap = {
  sm: 6,
  md: 6,
  lg: 6,
  xl: 6,
};

/*
Explaining the different MLT options
  -default (aroundYou): used in classifieds PDPs to show similar vehicles based on the location and other information (make, model, etc) of the current PDP.
  -d2c: used in D2C PDPs to show similar D2C vehicles based on information (make, model, etc) of the current PDP.
  -commerce: used in classifieds PDPs to show similar D2C vehicles based on information (make, model, etc) of the current PDP.
if no suggestions of D2C vehicles are available, there's a fallback to leasing vehicles.
*/
export const MLT_OPTIONS = {
  default: 'aroundYou',
  d2c: 'd2c',
  instamotion: 'instamotion',
  commerce: 'commerce',
  electric: 'electric',
};

export const MoreLikeThisListings = ({
  target,
  variant,
  onClick,
  isLoading,
  breakpoint,
  favouriteIds,
  hasHeadline,
  toggleFavourite,
  performanceUnit,
  budgetRangeValue,
  moreLikeThisListings,
  isOneClickLeadSuccess = false,
}) => {
  const [ref, inView] = useInView({ triggerOnce: true, threshold: 0.2 });

  const vehicles = moreLikeThisListings.slice(
    0,
    modalVehicleListSizeMap[breakpoint],
  );
  const ListingsComponent =
    variant === 'modal' ? ListingsContainerModalSt : ListingsContainerSt;

  useEffect(() => {
    if (inView) {
      const listIds = vehicles.map((vehicle) => vehicle.id);
      handleTrackingUserViewMLT(listIds, target);
    }
  }, [inView]);

  return (
    <MoreLikeThisSt
      id="mlt"
      ref={ref}
      variant={variant}
      onClick={onClick}
      style={{ paddingBottom: isOneClickLeadSuccess ? 0 : 48 }}
    >
      {vehicles.length > 0 && (
        <>
          <TitleSt variant={variant} hasHeadline={hasHeadline}>
            Lass dir diese Top-Angebote nicht entgehen:
          </TitleSt>

          <ListingsComponent
            target={target}
            variant={variant}
            lazyImages={false}
            vehicles={vehicles}
            isLoading={isLoading}
            breakpoint={breakpoint}
            favouriteIds={favouriteIds}
            vehicleCount={vehicles.length}
            toggleFavourite={toggleFavourite}
            performanceUnit={performanceUnit}
            budgetRangeValue={budgetRangeValue}
            vehiclesAmountMap={modalVehicleListSizeMap}
          />
        </>
      )}
    </MoreLikeThisSt>
  );
};

MoreLikeThisListings.propTypes = {
  target: PropTypes.string,
  breakpoint: PropTypes.string.isRequired,
  variant: PropTypes.oneOf(['default', 'sidebar', 'modal']),
  onClick: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
  favouriteIds: PropTypes.array,
  hasHeadline: PropTypes.bool,
  toggleFavourite: PropTypes.func,
  performanceUnit: PropTypes.string.isRequired,
  budgetRangeValue: PropTypes.string.isRequired,
  moreLikeThisListings: PropTypes.arrayOf(PropTypes.object),
  isOneClickLeadSuccess: PropTypes.bool,
};

MoreLikeThisListings.defaultProps = {
  target: 'PDP',
  favouriteIds: [],
  isLoading: false,
  variant: 'default',
  hasHeadline: true,
  toggleFavourite: noop,
  moreLikeThisListings: [],
  isOneClickLeadSuccess: false,
};

/* istanbul ignore file */
// TODO: Test this file
import { memo, useCallback, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';

import { CartileLoadingStyle } from 'app/shared/modules/Cartile/CartileUIComponents/CartileUIComponents.css';
import {
  CartileFinancingDisclaimerTextSt,
  CartileFinancingDisclaimerShowLessSt,
  CartileFinancingDisclaimerShowMoreSt,
  CartileFinancingDisclaimerContainerSt,
  CartileFinancingDisclaimerActionTextSt,
} from 'app/shared/modules/Cartile/CartileUIComponents/CartileAdditionalInfos/CartileFinancingDisclaimer/CartileFinancingDisclaimer.css';

const CartileFinancingDisclaimerCmp = ({
  isLoading,
  financingDisclaimer,
  isInitiallyExpanded,
}) => {
  const containerRef = useRef(null);
  const [showAllLines, setShowAllLines] = useState(false);
  const [hasShowMoreFeature, setHasShowMoreFeature] = useState(false);

  useEffect(() => {
    const lines = (containerRef?.current?.offsetHeight || 1) / 10;
    if (lines >= 3 && hasShowMoreFeature) {
      /* istanbul ignore next */
      setHasShowMoreFeature(true);
      /* istanbul ignore next */
      setShowAllLines(isInitiallyExpanded);
    }
  });

  const handleClick = useCallback(() => {
    /* istanbul ignore next */
    setShowAllLines(!showAllLines);
  }, [showAllLines]);

  return (
    <CartileFinancingDisclaimerContainerSt
      ref={containerRef}
      showAll={showAllLines || hasShowMoreFeature}
    >
      <CartileFinancingDisclaimerTextSt
        css={[isLoading && CartileLoadingStyle]}
      >
        <span>
          <sup>*</sup> {financingDisclaimer}
        </span>
        {!hasShowMoreFeature && showAllLines && (
          <CartileFinancingDisclaimerShowLessSt onClick={handleClick}>
            weniger anzeigen
          </CartileFinancingDisclaimerShowLessSt>
        )}
      </CartileFinancingDisclaimerTextSt>
      {!hasShowMoreFeature && !showAllLines && (
        <CartileFinancingDisclaimerShowMoreSt isLoading={isLoading}>
          <span>...&nbsp;</span>
          <CartileFinancingDisclaimerActionTextSt onClick={handleClick}>
            mehr anzeigen
          </CartileFinancingDisclaimerActionTextSt>
        </CartileFinancingDisclaimerShowMoreSt>
      )}
    </CartileFinancingDisclaimerContainerSt>
  );
};

CartileFinancingDisclaimerCmp.propTypes = {
  isLoading: PropTypes.bool,
  financingDisclaimer: PropTypes.node.isRequired,
  isInitiallyExpanded: PropTypes.bool.isRequired,
};

CartileFinancingDisclaimerCmp.defaultProps = {
  isLoading: false,
};

export const CartileFinancingDisclaimer = memo(CartileFinancingDisclaimerCmp);

import { css } from 'styled-components';
import { fontSizes, media } from '../../styles_js/variables';

// Default text styles as per definition
export const P1FixedSt = css`
  font-size: ${fontSizes.p};
  line-height: 2;
`;

export const P2FixedSt = css`
  font-size: ${fontSizes.p2};
  line-height: 1.71;
`;

export const P3FixedSt = css`
  font-size: ${fontSizes.p3};
  line-height: 1.66;
`;

export const P4FixedSt = css`
  font-size: ${fontSizes.p4};
  line-height: 1.6;
`;

// Responsive text styles based on definitions above
export const P1ResponsiveSt = css`
  ${P2FixedSt};
  ${media.md(P1FixedSt)};
`;

export const P2ResponsiveSt = css`
  ${P3FixedSt};
  ${media.md(P2FixedSt)};
`;

import { Route, Redirect } from 'react-router';

import { getAsyncRouteComponentLoader } from 'app/shared/utils/utils';
import { ConnectedPageTemplate } from 'app/shared/modules/pageTemplate/PageTemplate/ConnectedPageTemplate';

export const TradeInRoutes = (
  <Route
    name="tradein"
    component={(props) => <ConnectedPageTemplate target="tradein" {...props} />}
  >
    <Route
      path="/verkaufen"
      getComponent={getAsyncRouteComponentLoader('ConnectedCarsale24Page', () =>
        import('./pages/Carsale24/ConnectedCarsale24Page'),
      )}
    />
    <Redirect from="/tradein" to="/verkaufen" />
  </Route>
);

/* istanbul ignore file */
import { breakpoints } from 'app/shared/styles_js/variables';

function getValueForBreakpoint(value, index) {
  if (Array.isArray(value)) {
    if (value.length <= index) {
      return value[value.length - 1];
    }

    return value[index];
  }

  return value;
}

export function generateStyle(gridRow, columns, columnStart) {
  let style = '';

  Object.keys(breakpoints).forEach((breakpoint, index) => {
    const row = gridRow
      ? `grid-row: ${getValueForBreakpoint(gridRow, index)}`
      : '';

    const startColumn = columnStart?.length
      ? getValueForBreakpoint(columnStart, index)
      : 1;
    const endColumn = getValueForBreakpoint(columns, index);

    style += `@media all and (min-width: ${breakpoints[breakpoint]}px) {

      ${row};
      grid-column: ${startColumn} / span ${endColumn};
    }`;
  });

  return style;
}

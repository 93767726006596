/* istanbul ignore file */

import { connect } from 'react-redux';

import { hideNotificationIndicator } from 'app/shared/modules/user/actions';
import { getVehicleCount, changeUrl } from 'app/marketplace/search/actions';
import { openOverlay, closeOverlay } from 'app/shared/modules/overlay/actions';
import { deviceSelector, checkFeatureFlagHasToken } from 'app/selectors';
import {
  checkIsFavourite,
  getFavouriteVehiclesCountSelector,
} from 'app/marketplace/favourites/selectors';
import { getToken } from 'app/marketplace/accounts/selectors';
import { hideFavouriteTip } from 'app/marketplace/favourites/actions';
import { hideSaSeDashboardTip, logOut } from 'app/marketplace/accounts/actions';
import { breakpointSelector } from 'app/shared/modules/globalEvents/selectors';

import { NewMarketplaceTopNavbar } from './NewMarketplaceTopNavBar';

const mapStateToProps = (state) => {
  const { search, filter } = state;
  const { vehicleCount, vehicleCountPending } = search;
  const isFlag256ShowNewPromotionTiles = checkFeatureFlagHasToken(
    state,
    256,
    'show_variant',
  );

  return {
    vehicleCount,
    vehicle: state.vehicle.vehicle,
    activeFilters: filter,
    scrollDown: state.globalEvents.isScrollDown,
    vehicleCountLoading: vehicleCountPending > 0,
    isHidden: state.globalEvents.isTopNavBarHidden,
    isNotificationIndicatorVisible: state.user.isNotificationIndicatorVisible,
    favouritesCount: getFavouriteVehiclesCountSelector(state),
    isFavourite: checkIsFavourite(state),
    showFavouriteTip: state.favourites.showFavouriteTip,
    showSaSeDashboardTip: state.authMarketplace.showSaSeDashboardTip,
    isMobileDevice:
      deviceSelector(state) === 'mobile' ||
      (deviceSelector(state) === 'tablet' &&
        breakpointSelector(state) === 'md'),
    breakpoint: breakpointSelector(state),
    token: getToken(state),
    isFlag256ShowNewPromotionTiles,
    checkoutType: state?.checkoutOrderInfo?.info?.checkoutType,
  };
};
export const ConnectedNewMarketplaceTopNavBar = connect(mapStateToProps, {
  changeUrl,
  openOverlay,
  closeOverlay,
  getVehicleCount,
  hideFavouriteTip,
  hideSaSeDashboardTip,
  logoutAction: logOut,
  hideNotificationIndicator,
})(NewMarketplaceTopNavbar);

import { mapContentfulMultipleImagesFilePath } from 'app/shared/utils/utils';
import { TBreakpoint } from 'app/types/style.types';

import { IContentfulBannerSourceArgs } from '../StageBanner.types';

export const getContentfulStageBannerSources = ({
  hasSingleImage,
  imageUrl,
  responsiveImage,
  defaultImage,
}: IContentfulBannerSourceArgs): Record<TBreakpoint, string> => {
  if (hasSingleImage) {
    return { sm: imageUrl, md: imageUrl, lg: imageUrl, xl: imageUrl };
  }
  const { sm, md, lg, xl } = responsiveImage;
  return mapContentfulMultipleImagesFilePath({ sm, md, lg, xl }, defaultImage);
};

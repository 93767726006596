import { PureComponent } from 'react';
import PropTypes from 'prop-types';

import { Icon, Text, Container } from './TextWithIcon.css';

export class TextWithIcon extends PureComponent {
  render() {
    const { text, icon, children, themeName } = this.props;

    const sharedProps = { text, icon, themeName };

    return (
      <Container>
        <Icon className={icon} {...sharedProps} />
        <Text {...sharedProps}>{text || children}</Text>
      </Container>
    );
  }
}

TextWithIcon.propTypes = {
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  icon: PropTypes.string,
  themeName: PropTypes.oneOf([
    'brandPrimary',
    'brandSecondary',
    'white',
    'grayLightest',
  ]),
  children: PropTypes.any,
};

TextWithIcon.defaultProps = {
  icon: 'if-icon-check',
  themeName: 'white',
};

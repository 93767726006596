import { createSelector } from 'reselect';

export const favouritesSelector = state => state.favourites;
export const getLocalFavoriteIds = state => state.favourites.localFavouriteIds;
export const getLocalFavouriteCount = state =>
  state.favourites.localFavouriteIds.length;

// update this with flag token selector 83.
export const getFavoriteIds = createSelector(
  favouritesSelector,
  favourites => favourites.favouriteIds,
);

export const checkIsFavourite = createSelector(
  favouritesSelector,
  favourites => {
    const { favouriteIds, currentVehicleId } = favourites;
    return (
      (Array.isArray(favouriteIds) &&
        favouriteIds.filter(favouriteId => favouriteId === currentVehicleId)
          .length > 0) ||
      false
    );
  },
);
export const getFavouriteVehicles = createSelector(
  favouritesSelector,
  favourites => favourites.favouriteVehicles,
);

export const getFavouriteVehiclesCountSelector = createSelector(
  favouritesSelector,
  favourites => favourites.favouriteIds.length,
);

export const showFavLoginPopupSelector = createSelector(
  favouritesSelector,
  favourites => favourites.showFavLoginPopup,
);

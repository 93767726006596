export const GET_SPECIALOFFERS_REQUEST = 'GET_SPECIALOFFERS_REQUEST';
export const GET_SPECIALOFFERS_SUCCESS = 'GET_SPECIALOFFERS_SUCCESS';
export const GET_SPECIALOFFERS_FAILURE = 'GET_SPECIALOFFERS_FAILURE';
export const GET_SPECIALOFFERS_CLP_BANNER_REQUEST =
  'GET_SPECIALOFFERS_CLP_BANNER_REQUEST';
export const GET_SPECIALOFFERS_CLP_BANNER_SUCCESS =
  'GET_SPECIALOFFERS_CLP_BANNER_SUCCESS';
export const GET_SPECIALOFFERS_CLP_BANNER_FAILURE =
  'GET_SPECIALOFFERS_CLP_BANNER_FAILURE';
export const GET_SPECIALOFFERSHOME_REQUEST = 'GET_SPECIALOFFERSHOME_REQUEST';
export const GET_SPECIALOFFERSHOME_SUCCESS = 'GET_SPECIALOFFERSHOME_SUCCESS';
export const GET_SPECIALOFFERSHOME_FAILURE = 'GET_SPECIALOFFERSHOME_FAILURE';
export const GET_SPECIALOFFER_TEASER_REQUEST = 'GET_SPECIALOFFER_REQUEST';
export const GET_SPECIALOFFER_TEASER_SUCCESS = 'GET_SPECIALOFFER_SUCCESS';
export const GET_SPECIALOFFER_TEASER_FAILURE = 'GET_SPECIALOFFER_FAILURE';
export const SET_IS_PDP_CAMPAIGN_TOP_BANNER_AVAILABLE =
  'SET_IS_PDP_CAMPAIGN_TOP_BANNER_AVAILABLE';

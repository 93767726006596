import { memo } from 'react';
import PropTypes from 'prop-types';

import { LoadingAnimationContainerSt } from './LoadingAnimation.css';

const LoadingAnimationCmp = ({ isLoading }) => {
  return isLoading ? <LoadingAnimationContainerSt /> : null;
};

LoadingAnimationCmp.propTypes = {
  isLoading: PropTypes.bool,
};

LoadingAnimationCmp.defaultProps = {
  isLoading: false,
};

export const LoadingAnimation = memo(LoadingAnimationCmp);

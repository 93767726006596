import { CONTENTFUL_HEYCAR } from 'app/config';

import { getEntries } from '../../utils';

export const SUBMIT_ENTRIES_REQUEST = 'headerCLP/SUBMIT_ENTRIES_REQUEST';
export const SUCCESS_ENTRIES_REQUEST = 'headerCLP/SUCCESS_ENTRIES_REQUEST';
export const FAILURE_ENTRIES_REQUEST = 'headerCLP/FAILURE_ENTRIES_REQUEST';

// headerCLP from the active filters state
export const getQueryObj = ({ headerCLP }) => {
  const urlId = headerCLP[0] && headerCLP[0].value ? headerCLP[0].value : '';

  return {
    content_type: 'headerClp',
    'fields.urlId': urlId,
  };
};

export const getHeaderCLPEntries = (baseUrl) => (dispatch, getState) => {
  const queryObj = getQueryObj(getState().filter);

  dispatch({ type: SUBMIT_ENTRIES_REQUEST });

  return dispatch(getEntries(queryObj, CONTENTFUL_HEYCAR, baseUrl))
    .then((response) =>
      dispatch({ type: SUCCESS_ENTRIES_REQUEST, payload: response }),
    )
    .catch((error) => dispatch({ type: FAILURE_ENTRIES_REQUEST, error }));
};

export const getHeaderCLPbyId = (id) => (dispatch) => {
  const queryObj = {
    content_type: 'headerClp',
    'fields.urlId': id,
  };

  dispatch({ type: SUBMIT_ENTRIES_REQUEST });

  return dispatch(getEntries(queryObj, CONTENTFUL_HEYCAR))
    .then((response) =>
      dispatch({ type: SUCCESS_ENTRIES_REQUEST, payload: response }),
    )
    .catch((error) => dispatch({ type: FAILURE_ENTRIES_REQUEST, error }));
};

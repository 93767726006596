/**
 * @description redux action types
 */
export const DealerProfileActionTypes = {
  LOADING: 'DEALER_PROFILE_LOADING',
  SUCCESS: 'DEALER_PROFILE_SUCCESS',
  FAILURE: 'DEALER_PROFILE_FAILURE',
};

/**
 * @description used for building urls and routes
 */
export const DPP_BASE_SLUGS = {
  DPP: 'haendler',
  CLP: 'gebrauchtwagen',
  SEARCH_API: 'search',
};

/**
 * @description number of car tiles
 */
export const DPP_VEHICLE_COUNT = 6;

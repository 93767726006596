/* eslint-disable react/prop-types */
import React from 'react';

import { Text2 } from '../../../Typo/Typo';

import { FlexSt } from './NavFlyoutFooter.css';
import { INavFlyoutFooterProps } from './NavFlyoutFooter.types';

export const NavFlyoutFooter = ({
  children,
}: INavFlyoutFooterProps): JSX.Element => (
  <FlexSt
    width="100%"
    justifyContent="center"
    p={['24px', '24px', '16px']}
    backgroundColor="grayLightest"
    mt="auto"
  >
    <Text2 textAlign="center" margin="0">
      {children}
    </Text2>
  </FlexSt>
);

/* eslint-disable react/jsx-props-no-spreading */
import { memo, useEffect } from 'react';
import PropTypes from 'prop-types';
import { browserHistory } from 'react-router';

import { Heading4 } from 'app/shared/ui';
import { noop } from 'app/shared/utils/utils';

import { trackAction } from '../../../utils/tracking';
import { ConnectedFDLWizard as FDLWizard } from '../FDLWizard/ConnectedFDLWizard';
import { TRACKING_LIST } from '../../tracking/list';

import {
  Container,
  CustomButton,
  Info,
  Title,
  InnerContainer,
  ButtonArea,
  ButtonAreaCol,
} from './FDLBanner.css';

/**
 * @memberof ConnectedFDLBanner.js
 * @description Home<
 * Informs the user about the financing possibility, including links
 * to the financing guide page and to the FDLWizard (financing wizard).
 */
export const FDLBanner = memo(function FDLBanner(props) {
  const { themeName, url, toggleWizardPopup, location } = props;

  useEffect(() => {
    const { finWizard } = location?.query || {};

    // Open the Fin Wizard once if url contains the param: "?finWizard=true"
    if (finWizard) {
      delete location.query.finWizard;
      browserHistory.replace(location);

      toggleWizardPopup();
    }
  }, []);

  function onButtonClick() {
    trackAction(TRACKING_LIST.FINANCING.LINKS.FINANCING_GUIDE, {
      label: 'homepage',
    });
    browserHistory.push(url);
  }

  function onWizardButtonClick() {
    trackAction(TRACKING_LIST.FINANCING.WIZARD.ENTRY_POINT, {
      label: 'homepage',
    });

    toggleWizardPopup();
  }

  const buttonToWizardProps = {
    variant: 'secondary',
    onClick: onWizardButtonClick,
  };

  return (
    <Container {...props}>
      <InnerContainer {...props}>
        <Title {...props}>
          <img
            src="/assets/icon-car-blue.svg"
            alt="Tipps zur Autofinanzierung"
            width="184px"
            height="162px"
          />
        </Title>
        <Info>
          <Heading4
            mt={[40, 0]}
            mb={40}
            fontSize={[24, 28, 32]}
            lineHeight={[1.67, 1.5]}
            fontWeight={800}
            themeName={themeName}
          >
            <span>Tipps zur Autofinanzierung</span>
          </Heading4>
          <p>
            Wir bei heycar sind Experten zum Thema Autofinanzierung. Um dir
            einen Überblick der verschiedenen Finanzierungsmöglichkeiten zu
            verschaffen, haben wir einen Finanzierungsratgeber geschrieben. Er
            hilft dir dabei, die verschiedenen Finanzierungsprodukte zu
            verstehen und herauszufinden welches Produkt am besten zu dir passt.
          </p>
          <ButtonArea columnCount={2} noSplit autoWidth>
            <ButtonAreaCol>
              <CustomButton
                variant="primary"
                onClick={onButtonClick}
                isLink
                href={url}
              >
                <span>Zum Finanzierungsratgeber</span>
              </CustomButton>
            </ButtonAreaCol>
            <ButtonAreaCol>
              <CustomButton {...buttonToWizardProps}>
                <span>Welche Finanzierung passt zu dir?</span>
              </CustomButton>
            </ButtonAreaCol>
          </ButtonArea>
        </Info>
      </InnerContainer>
      <FDLWizard />
    </Container>
  );
});

FDLBanner.displayName = 'FDLBanner';

FDLBanner.defaultProps = {
  themeName: 'brandSecondaryLight',
  url: '/finanzierungsratgeber',
  location: {},
  toggleWizardPopup: noop,
};

FDLBanner.propTypes = {
  url: PropTypes.string,
  themeName: PropTypes.oneOf([
    'brandPrimary',
    'brandSecondary',
    'brandSecondaryLight',
    'white',
    'grayLightest',
  ]),
  toggleWizardPopup: PropTypes.func,
  location: PropTypes.object,
};

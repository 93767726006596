/* istanbul ignore file */

import {
  ADDRESS_INFO_UPDATE,
  USER_INFO_UPDATE,
  USER_INFO_PHONE_RESET,
  ORDER_INFO_GET_SUCCESS,
  ORDER_INFO_GET,
  ORDER_INFO_GET_ERROR,
  DEALER_SALES_OFFER_ACCEPT_PUT_SUCCESS,
  DEALER_SALES_OFFER_REJECT_PUT_SUCCESS,
  DEALER_SALES_OFFER_ACCEPT_PUT_ERROR,
  DEALER_SALES_OFFER_REJECT_PUT_ERROR,
  DEALER_SALES_OFFER_GET,
  DEALER_SALES_OFFER_GET_SUCCESS,
  DEALER_SALES_OFFER_GET_ERROR,
  DEALER_SALES_OFFER_ACCEPT_PUT,
  DEALER_SALES_OFFER_REJECT_PUT,
  CHECKOUT_TYPE_SET,
  CHECKOUT_STEP_SET,
  CHECKOUT_STEP_RESET,
} from '../../constants';

export const initialState = {
  user: {
    firstName: '',
    lastName: '',
    dateOfBirth: '',
    email: '',
    emailConfirmation: '',
    phone: '',
    phonePrefix: '',
  },
  address: {
    street: '',
    houseNumber: '',
    addressComplement: '',
    city: '',
    postalCode: '',
    country: 'DE',
  },
  order: {},
  salesOffer: {},
  checkoutType: '',
  checkoutStep: undefined,
};

export function info(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case USER_INFO_UPDATE:
      return {
        ...state,
        user: { ...state.user, ...payload },
      };
    case USER_INFO_PHONE_RESET:
      return {
        ...state,
        user: { ...state.user, phone: '', phonePrefix: '' },
      };
    case ADDRESS_INFO_UPDATE:
      return { ...state, address: { ...state.address, ...payload } };
    case ORDER_INFO_GET:
      return {
        ...state,
        order: { isLoading: true },
      };
    case ORDER_INFO_GET_SUCCESS:
      return {
        ...state,
        order: {
          ...state.order,
          ...payload,
          error: undefined,
          isLoading: false,
        },
      };
    case ORDER_INFO_GET_ERROR:
      return {
        ...state,
        order: { ...state.order, error: payload, isLoading: false },
      };
    case DEALER_SALES_OFFER_ACCEPT_PUT:
      return {
        ...state,
        salesOffer: {
          ...state.salesOffer,
          isLoading: true,
        },
      };
    case DEALER_SALES_OFFER_ACCEPT_PUT_SUCCESS:
      return {
        ...state,
        order: {
          ...state.order,
          ...payload,
          error: undefined,
          isLoading: false,
        },
        salesOffer: {
          ...state.salesOffer,
          dealerAcceptedOrRejectedOfferAt:
            payload.dealerAcceptedRejectedOfferAt,
          isLoading: false,
        },
      };
    case DEALER_SALES_OFFER_ACCEPT_PUT_ERROR:
      return {
        ...state,
        order: { ...state.order, error: payload, isLoading: false },
      };
    case DEALER_SALES_OFFER_REJECT_PUT:
      return {
        ...state,
        salesOffer: {
          ...state.salesOffer,
          isLoading: true,
        },
      };
    case DEALER_SALES_OFFER_REJECT_PUT_SUCCESS:
      return {
        ...state,
        order: {
          ...state.order,
          ...payload,
          error: undefined,
          isLoading: false,
        },
        salesOffer: {
          ...state.salesOffer,
          dealerAcceptedOrRejectedOfferAt:
            payload.dealerAcceptedRejectedOfferAt,
          isLoading: false,
        },
      };
    case DEALER_SALES_OFFER_REJECT_PUT_ERROR:
      return {
        ...state,
        order: { ...state.order, error: payload, isLoading: false },
      };

    case DEALER_SALES_OFFER_GET:
      return {
        ...state,
        salesOffer: { isLoading: true },
      };

    case DEALER_SALES_OFFER_GET_SUCCESS:
      return {
        ...state,
        salesOffer: {
          ...payload,
          error: undefined,
          isLoading: false,
        },
      };
    case DEALER_SALES_OFFER_GET_ERROR:
      return {
        ...state,
        salesOffer: { ...state.salesOffer, error: payload, isLoading: false },
      };
    case CHECKOUT_TYPE_SET:
      return {
        ...state,
        checkoutType: payload,
      };
    case CHECKOUT_STEP_SET:
      return { ...state, checkoutStep: payload };
    case CHECKOUT_STEP_RESET:
      return { ...state, checkoutStep: undefined };

    default:
      return state;
  }
}

import {
  PAYMENT_INFO_GET,
  PAYMENT_INFO_GET_ERROR,
  PAYMENT_INFO_GET_SUCCESS,
} from '../../constants';

export const initialState = {
  /**
   * Example:
   * {
   *   "bankAccountOwner": "Volkswagen Bank",
   *   "downPaymentValue": 6600.0,
   *   "iban": "DE91270200005040448291",
   *   "bic": "VOWADE2BXXX",
   *   "paymentReferenceNumber": "Anzahlung-WVWZZZ7NZKV005787-heycar- 2"
   * }
   * @type {Object}
   */
  details: {},
  error: undefined,
  isLoading: false,
};

export function paymentInfo(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case PAYMENT_INFO_GET:
      return { ...state, error: undefined, isLoading: true };
    case PAYMENT_INFO_GET_SUCCESS:
      return { ...state, details: payload, isLoading: false };
    case PAYMENT_INFO_GET_ERROR:
      return { ...state, error: payload, isLoading: false };
    default:
      return state;
  }
}

export const ActionTypes = {
  SHOW_NOTIFICATION: 'NOTIFICATIONS/SHOW_NOTIFICATION',
  HIDE_NOTIFICATION: 'NOTIFICATIONS/HIDE_NOTIFICATION',
  CLEAR_NOTIFICATION: 'NOTIFICATIONS/CLEAR_NOTIFICATION',
};

export const Durations = {
  SLIDE_IN: 400,
  SLIDE_OUT: 300,
  INFO_NOTIFICATION_DISPLAY: 4000,
};

import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { closeOverlay, openOverlay } from '../actions';

/**
 * Wrap a component to provide access to the opening and closing of overlays
 * @param {Function|Class} component - the component you want to provide with overlay access
 * @return {Function|Class} component with overlay functions provided by the props
 */
export const withOverlay = component => {
  /*
   * even though we have to define it again in the component file itself for some IDEs to stop complaining, this should
   * at least give us the validation warnings in the console
   */
  const propTypes = component.propTypes || {};

  component.propTypes = {
    ...propTypes,
    openOverlay: PropTypes.func.isRequired,
    closeOverlay: PropTypes.func.isRequired,
  };
  return connect(
    null, // do not subscribe to store updates
    {
      openOverlay,
      closeOverlay,
    },
  )(component);
};

import styled, { css } from 'styled-components';
import { Box } from '@rebass/grid';

import { media } from '../../../../../variables/media';

export const NavItemCategoryWrapperSt = styled(Box)`
  position: 'relative';
`;

export const NavStyledHr = styled.hr`
  width: 100vw;
  margin: 0 0 0 -50px;
  border: 1px solid ${(props) => props.theme.colors.grayLightest};

  ${media.md(css`
    display: none;
  `)};
`;

export const BoxSt = styled(Box)`
  padding-bottom: 24px;

  &:last-child {
    padding-bottom: 0;
  }
`;

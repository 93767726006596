import styled, { css } from 'styled-components';
import { Box } from '@rebass/grid';

import { zIndex } from '../variables/spacings';

export const ToggleContainer = styled.div`
  position: relative;
`;

export const ToggleStyle = styled(Box)`
  position: relative;
  display: flex;
  min-width: 80px;
  height: 40px;
  border: solid 1px;
  border-color: ${({ theme }) =>
    theme.rebranding /* istanbul ignore next */
      ? theme.colors.gray
      : theme.colors.grayTable};
  ${(props) =>
    !(props.labelLeft && props.labelRight) &&
    css`
      width: 100%;
    `};
  border-radius: 24px;
  text-align: center;
  cursor: pointer;
  user-select: none;
`;

export const ToggleLabel = styled.div`
  position: relative;
  z-index: ${zIndex.switchLabel};
  display: inline-flex;
  overflow: hidden;
  flex-basis: 50%;
  align-items: center;
  justify-content: center;

  padding-right: 14px;
  padding-left: 14px;
  white-space: nowrap;

  font-size: 14px;
  text-overflow: ellipsis;
  line-height: 1.71;
  font-weight: 200;

  &:nth-child(1) {
    ${({ side }) => !side && 'font-weight : 300'};
    color: ${({ theme: { rebranding, colors }, side }) => {
      if (rebranding) {
        /* istanbul ignore next */
        return !side ? colors.tarmacGrey : colors.brandSecondaryLight;
      }

      return colors.brandSecondary;
    }};
  }

  &:nth-child(2) {
    ${({ side }) => side && 'font-weight : 300'};
    color: ${({ theme: { rebranding, colors }, side }) => {
      if (rebranding) {
        /* istanbul ignore next */
        return side ? colors.tarmacGrey : colors.brandSecondaryLight;
      }

      return colors.brandSecondary;
    }};
  }
`;

export const ToggleButton = styled.div`
  position: absolute;
  z-index: ${zIndex.switchButton};
  top: 0;

  width: 50%;
  min-width: 40px;
  height: 40px;
  border: solid 1px ${({ theme }) => theme.colors.brandPrimary};
  border-radius: 24px;
  background-color: ${({ theme: { getRgba, colorsRgb, rebranding } }) =>
    rebranding /* istanbul ignore next */
      ? colorsRgb.mintSuperLight
      : getRgba(colorsRgb.brandPrimary, 0.05)};

  ${(props) =>
    props.side
      ? 'transition: all ease-in 140ms; transform: translateX(100%)'
      : 'transition: all ease-in 140ms; transform: translateX(0)'};
`;

import styled, { css } from 'styled-components';

import { ContainerSt } from 'app/shared/ui';

import { media } from '../../../shared/styles_js/variables';

export const TuvBannerSt = styled(ContainerSt)`
  background-color: ${({ theme: { colors } }) => colors.sunbeanLighter};
  display: flex;
  flex-direction: column;
  padding: 24px;
  width: 100%;

  ${media.lg(css`
    display: grid;
    padding: 32px 100px;
    grid-template-columns: minmax(150px, max-content) minmax(38%, 1fr) minmax(
        53%,
        1fr
      );
  `)}

  ${media.xl(css`
    padding: 32px 150px;
    grid-template-columns: minmax(150px, max-content) minmax(51%, 1fr) minmax(
        46%,
        1fr
      );
  `)}
`;

export const ImageSt = styled.img`
  align-self: flex-start;
  width: 168px;
  ${media.lg(css`
    align-self: auto;
  `)}
`;

export const TextSt = styled.span`
  font-weight: 400;
  font-size: 24px;
  line-height: 32px;
  width: 100%;
  padding-top: 16px;
  overflow: hidden;
  align-self: flex-start;

  ${media.lg(css`
    padding-left: 32px;
    padding-top: 0;
    align-self: auto;
  `)}
`;

export const TextDescriptionSt = styled.span`
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  width: 100%;
  padding-top: 16px;
  overflow: hidden;
  align-self: flex-start;

  ${media.lg(css`
    font-size: 16px;
    line-height: 24px;
    padding-left: 32px;
    padding-top: 0;
    align-self: auto;
  `)}
`;

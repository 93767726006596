/* istanbul ignore file */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import styled, { css } from 'styled-components';
import { Box } from '@rebass/grid';

import { media, grid } from 'app/shared/styles_js/variables';

import { generateStyle } from './utils';

import { IExtendedGridProps } from '.';

/**
 * When you add "display: grid" to a grid container,
 * only the direct children become grid items and
 * can then be placed on the grid that you have created.
 *
 * You can "nest" grids by making a grid item a grid container.
 * These grids however are independent of the parent grid and
 * of each other, meaning that they do not take their track
 * sizing from the parent grid. This makes it difficult to
 * line nested grid items up with the main grid.
 *
 * Let's keep an eye on "subgrid" browser support, then we
 * can update this component and simplify the usage of Grid system.
 *
 * Source: https://developer.mozilla.org/en-US/docs/Web/CSS/CSS_Grid_Layout/Subgrid
 */
export const GridItemSt = styled(Box)`
  ${media.md(css`
    column-gap: ${grid.gutter.md * 2}px;
  `)}

  ${({ gridRow, columns, columnStart }: IExtendedGridProps) =>
    generateStyle(gridRow, columns, columnStart)}
`;

import { Route } from 'react-router';

import { getAsyncRouteComponentLoader } from 'app/shared/utils/utils';

import { NativeRecaptcha } from './NativeRecaptcha/NativeRecaptcha';

export const NativeRoutes = (
  <Route name="NativeRecaptcha" component={NativeRecaptcha}>
    <Route
      path="/native/native-recaptcha"
      getComponent={getAsyncRouteComponentLoader('NativeRecaptcha', () =>
        import('./NativeRecaptcha/NativeRecaptcha'),
      )}
    />
  </Route>
);

import React, { ReactNode } from 'react';

import { colors } from '../../../../../../../variables/colors';

import {
  NavItemCategoryButton,
  NavItemCategoryLink,
} from './NavItemCategoryElement.css';

type NavItemCategoryElementProps = {
  to?: string;
  children: React.ReactNode;
  useRouter: boolean;
  setTracking: (type: string, id?: ReactNode) => void;
  elementLabel: React.ReactNode;
  closeMenu: (isClosed: boolean) => void;
  onClick?: () => void;
  setSelectedItem: (itemId: string) => void;
};

export const NavItemCategoryElement = ({
  to,
  children,
  useRouter = false,
  onClick,
  closeMenu,
  setTracking,
  elementLabel,
  setSelectedItem,
}: NavItemCategoryElementProps): JSX.Element => {
  const handleOnClick = () => {
    setTracking('child', elementLabel);
    closeMenu(false);
    setSelectedItem('');
    // eslint-disable-next-line no-unused-expressions
    onClick?.();
  };
  if (to) {
    return (
      <NavItemCategoryLink
        to={to}
        useRouter={useRouter}
        color={colors.tarmacGrey}
        hoverColor={colors.brandPrimaryDark}
        className="list-menu"
        onClick={(e)=> {
          e.persist();
          handleOnClick()
        }}
      >
        {children}
      </NavItemCategoryLink>
    );
  }
  return <NavItemCategoryButton onClick={handleOnClick}>{children}</NavItemCategoryButton>;
};

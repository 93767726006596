import { memo } from 'react';
import PropTypes from 'prop-types';

import { Link } from 'app/shared/ui';
import {
  DotSt,
  CategorySt,
  DateAndCategoryWrapperSt,
} from 'app/marketplace/contentful/spaces/magazine/entries/DateAndCategory/DateAndCategory.css';
import { useStyledTheme } from 'app/shared/hooks/useStyledTheme';
import { formatDate } from 'app/shared/utils/formatDate';

const DateAndCategoryCmp = ({ activeLink, articleDate, articleCategory }) => {
  const { colors } = useStyledTheme();
  const articleCategoryFields =
    articleCategory && articleCategory.fields ? articleCategory.fields : {};
  const { categoryName, categoryNameSlug } = articleCategoryFields;

  if (!categoryName) {
    return null;
  }

  return (
    <DateAndCategoryWrapperSt>
      {formatDate(articleDate, { day: '2-digit', month: 'long' })}

      <DotSt />

      {activeLink ? (
        <CategorySt>
          <Link
            to={`/magazine/category/${categoryNameSlug}`}
            title={categoryName}
            color={colors.micaBlue}
            hoverColor={colors.actionBlue}
            fontWeight="regular"
            textDecoration="none"
            // eslint-disable-next-line react-perf/jsx-no-new-object-as-prop
            css={{ transition: 'color 200ms ease-in-out' }}
          >
            {categoryName}
          </Link>
        </CategorySt>
      ) : (
        <CategorySt>{categoryName}</CategorySt>
      )}
    </DateAndCategoryWrapperSt>
  );
};

DateAndCategoryCmp.propTypes = {
  activeLink: PropTypes.bool.isRequired,
  articleDate: PropTypes.string.isRequired,
  articleCategory: PropTypes.object.isRequired,
};

export const DateAndCategory = memo(DateAndCategoryCmp);

import { memo } from 'react';
import PropTypes from 'prop-types';
import { css } from 'styled-components';

import { Text } from 'app/shared/ui';
import { CartileLoadingStyle } from 'app/shared/modules/Cartile/CartileUIComponents/CartileUIComponents.css';
import { CartileD2CMtlPrice } from 'app/shared/modules/Cartile/CartileUIComponents/CartilePrice/CartileD2CMtlPrice/CartileD2CMtlPrice';
import { CartilePriceContainersSt } from 'app/shared/modules/Cartile/CartileUIComponents/CartilePrice/CartilePriceContainers/CartilePriceContainers.css';
import { formatCurrency } from 'app/shared/utils/formatNumber';

const CartilePriceId3DefaultCmp = ({
  linkTo,
  isLoading,
  leasingRate,
  handleTracking,
  reducedPrice,
  target,
  highestPrice,
}) => {
  return (
    <CartilePriceContainersSt
      isLoading={isLoading}
      css={[isLoading && CartileLoadingStyle]}
      flexDirection={reducedPrice ? 'column-reverse' : 'column'}
    >
      <CartileD2CMtlPrice
        linkTo={linkTo}
        target={target}
        fontSize="14px"
        monthlyRate={leasingRate?.value}
        handleTracking={handleTracking}
      />
      {reducedPrice && (
        <Text
          data-test-id="id3-cartile-reduced-price-text"
          fontSize="12px"
          m={0}
          color="grayLight"
          fontWeight="bold"
          p={0}
          mb={-2}
          css={css`
            text-decoration: line-through;
          `}
        >
          {formatCurrency(highestPrice)}
        </Text>
      )}
    </CartilePriceContainersSt>
  );
};

CartilePriceId3DefaultCmp.propTypes = {
  isLoading: PropTypes.bool,
  leasingRate: PropTypes.number,
  price: PropTypes.number.isRequired,
  linkTo: PropTypes.string.isRequired,
  vatReclaimable: PropTypes.bool.isRequired,
  handleTracking: PropTypes.func.isRequired,
  reducedPrice: PropTypes.bool.isRequired,
  highestPrice: PropTypes.number.isRequired,
  target: PropTypes.string,
};

CartilePriceId3DefaultCmp.defaultProps = {
  isLoading: false,
  leasingRate: null,
  target: '_self',
};

export const CartilePriceId3Default = memo(CartilePriceId3DefaultCmp);

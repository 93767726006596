import styled, { css } from 'styled-components';
import { Flex } from '@rebass/grid';

import { media } from '../../../variables/media';

export const FlexSt = styled(Flex)`
  z-index: 0;

  ${media.md(css`
    z-index: 2;
  `)};
`;

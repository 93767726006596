/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import {
  CANCELATION_POLICY_GET,
  CANCELATION_POLICY_GET_SUCCESS,
  CANCELATION_POLICY_GET_ERROR,
} from '../../constants';

import { COMMERCE_PUBLIC_FILES_URI } from "app/config";

const commercePublicFilesUri: string = COMMERCE_PUBLIC_FILES_URI

interface ICancelatinPolicy {
  url: string;
  isLoading: boolean;
  error?: IErrorShape;
}

export const initialState: ICancelatinPolicy = {
  isLoading: false,
  url: '',
  error: undefined,
};

export function cancelationPolicy(
  state = initialState,
  action: { type: string; payload: unknown },
) {
  const { type, payload } = action;

  const standardURL =
    `${commercePublicFilesUri}/dealer/widerrufsbelehrung_standard.pdf`;

  switch (type) {
    case CANCELATION_POLICY_GET:
      return { ...state, error: undefined, isLoading: true };
    case CANCELATION_POLICY_GET_SUCCESS:
      return { ...state, url: payload, isLoading: false };
    case CANCELATION_POLICY_GET_ERROR:
      return {
        ...state,
        url: standardURL,
        error: payload,
        isLoading: false,
      };
    default:
      return state;
  }
}

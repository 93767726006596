import { getFinancingLeadMessage } from 'app/shared/modules/FDL/utils/getFinancingLeadMessage';
import {
  getVideoMessage,
  LEAD_SOURCE,
} from 'app/marketplace/contactDealer/utils';
import { createGUID } from 'app/shared/utils/utils';
import { getRouterQuery } from 'app/selectors';
import { getCalculation } from 'app/shared/modules/FDL/CalculatorV3/redux/selector';
import { AUTOBILD_API_URL } from 'app/external/autobild/LeadFlow/MailForm/constants';
import { getCaptiveBank } from 'app/shared/modules/FDL/selectors';

import { getCurrentVehiclePrice } from '../vehicle/selectors';

import * as ActionTypes from './constants';

/**
 * Resets all data regarding a lead
 * @returns {Function} result of async dispatch call
 */
export const resetContactDealer = () => ({ type: ActionTypes.RESET });

/**
 * Resets persisted email lead form fields
 * @returns {Function} result of async dispatch call
 */
export const resetLeadForm = () => ({ type: ActionTypes.RESET_LEAD_FORM });

/**
 * Resets dealer phone and pin
 * @returns {Function} result of async dispatch call
 */
export const resetPhoneInfo = () => ({ type: ActionTypes.RESET_PHONE_INFO });

/**
 * Resets callback info statuses
 * @returns {Function} result of async dispatch call
 */
export const resetCallbackInfo = () => ({ type: ActionTypes.RESET_CALLBACK });

/**
 * Resets email or video info statuses
 * @returns {Function} result of async dispatch call
 */
export const resetEmailOrVideoInfo = () => ({
  type: ActionTypes.RESET_MAIL_VIDEO,
});

/**
 * Resets reCAPTCHA challenge
 * @returns {Function} result of async dispatch call
 */
export const resetRecaptchaChallenge = () => ({
  type: ActionTypes.SET_RECAPTCHA_CHALLENGE_ID,
  payload: null,
});

/**
 * set the message for the dealer contact mail component by:
 * 1) checking to see if there is a calculation already, or
 * 2) checking to see if they have typed in a message already, or
 * 3) opting to use a placeholder message in all other cases
 *
 * @param {object} vehicle vehicle details
 * @param {object} extras extra params to take into consideration
 * @return {Function} result of async dispatch call
 */
export const getInitialLeadMessage = (vehicle, extras = {}) => {
  return (dispatch, getState) => {
    const state = getState();
    const {
      tradeIn: { valuatedVehicle },
      contactDealer: { messages },
      fdlCalculator: { calculations },
    } = state;
    const { id: vehicleId, financingPrecalc } = vehicle;
    const captiveId = financingPrecalc?.searchFilters?.captiveId;
    const leadType = extras?.isRequestVideo ? 'video' : 'email';
    const existingMessage = messages.find(
      (message) => message.id === vehicleId && message.type === leadType,
    )?.message;
    const existingCalculation = calculations.find(
      (calculation) => calculation.id === vehicleId,
    );
    return (() => {
      if (existingMessage) {
        return existingMessage;
      }

      if (leadType === 'video') {
        return getVideoMessage(existingCalculation, captiveId);
      }

      return getFinancingLeadMessage(
        captiveId,
        existingCalculation,
        valuatedVehicle,
      );
    })();
  };
};

/**
 * Saves the message the user will send
 * @param {array} payload: the user's message
 * @returns {Function} result of async dispatch call
 */
export const saveMessage = (payload) => {
  return (dispatch) => {
    dispatch({
      type: ActionTypes.SAVE_MESSAGE,
      payload,
    });
  };
};

/**
 * Dispatches the action to initiate migrating details to the cookies.
 * The migration is done via saga. Check sagas.js
 * @returns {Function} result of async dispatch call
 */
export const migrateContactDetails = () => (dispatch) => {
  dispatch({ type: ActionTypes.REMOVE_CONTACT_DETAILS_PENDING });
};

/**
 * Gather all the common data used for autobild and onsite email/video lead.
 * @returns {object} all common data
 */
export const getCommonDataForFormSubmission = (state, vehicle) => {
  const {
    contactDealer: { message },
    tracking,
    tradeIn: { valuatedVehicle: { id: valuationId } = {} },
  } = state;

  const captiveId = vehicle.financingPrecalc?.searchFilters?.captiveId;

  const { utm_id: utmId } = getRouterQuery(state);

  return { message, tracking, valuationId, captiveId, utmId };
};

/**
 * Dispatches the action to initiate creating an email lead for Autobild.
 * Posting it to the backend is done via saga. Check Mail/sagas.js
 * @param {object} vehicle: vehicle object data
 * @param {object} extras: extra props that are not in the redux state
 * @returns {Function} result of async dispatch call
 */
export const createAutobildRequest = (vehicle, extras) => (
  dispatch,
  getState,
) => {
  const state = getState();
  const {
    message,
    tracking,
    valuationId,
    captiveId,
    utmId,
  } = getCommonDataForFormSubmission(state, vehicle);
  const phone = extras.phoneNumber || extras.phone;
  const leadType = extras.type || 'email';

  const form = {
    ...extras,
    type: leadType,
    message: extras.message || message,
    phone,
    vehicleListingId: vehicle.id,
    leadSource: 'autobild',
    trackingId: tracking.guid,
    ...(utmId && { price: vehicle.price, utmId }),
    ...(leadType === 'email' &&
      valuationId && { tradeInValuationId: valuationId }),
    ...(captiveId && { captiveId }),
  };

  dispatch({
    type: ActionTypes.SUBMIT_AUTOBILD_PENDING,
    payload: { form, vehicleId: vehicle.id, url: AUTOBILD_API_URL },
  });
};

/**
 * Dispatches the action to initiate creating an email or video lead.
 * Posting it to the backend is done via saga. Check Mail/sagas.js
 * @param {object} formObj: user data coming from the form
 * @param {object} vehicleObj: vehicle for which user wants to create a lead
 * @param {object} token: token data for logged in users
 * @param {string} type: type of lead to be created
 * @returns {Function} result of async dispatch call
 */
export const createMailOrVideoRequest = (
  formObj,
  vehicleObj,
  token,
  type = 'email',
) => (dispatch) => {
  const eventId = createGUID();
  const extras = {
    eventId,
    type,
    ...formObj,
    phone: formObj.phoneNumber || '',
  };

  dispatch({
    type: ActionTypes.SUBMIT_MAIL_VIDEO_PENDING,
    payload: { formObj, vehicle: vehicleObj, extras, token },
  });
};

export const createSelectableOneClickLeadsRequest = (
  oneClickLeads,
  leadType = 'email',
) => (dispatch, getState) => {
  const state = getState();
  const { form } = state.activeLeadManagement;

  const body = {
    ...form,
    oneClickLeads,
    oneClickLeadsConsent: form.oneClickLeadsConsent || false,
    leadSource: LEAD_SOURCE,
    isSelectable: true,
  };

  dispatch({
    type:
      leadType === 'callback'
        ? ActionTypes.SUBMIT_CALLBACK_SELECTABLE_ONE_CLICK_LEADS_PENDING
        : ActionTypes.SUBMIT_SELECTABLE_ONE_CLICK_LEADS_PENDING,
    payload: body,
  });
};

/**
 * Dispatches the action to initiate getting the dealer contact info.
 * Posting it to the backend is done via saga. Check Call/sagas.js
 * @param {string} vehicleListingId: the id for the vehicle listing
 * @param {string} callDealerEventId: the id for the event to call dealer
 * @param {string} url: the endpoint url
 * @returns {Function} result of async dispatch call
 */
export const getContactDealerPhoneInfo = (
  vehicle,
  callDealerEventId,
  eventLabel,
  target,
  openSidebar,
  shouldInitiateCall = false,
) => {
  return async (dispatch, getState) => {
    const state = getState();
    const calculation = getCalculation(state, vehicle.id);
    const { utm_id: utmId } = getRouterQuery(state);
    const price = getCurrentVehiclePrice(state);
    const captiveId = getCaptiveBank(state);
    const CaptiveIdInUpperCase = captiveId && captiveId.toUpperCase();

    const form = {
      trackingId: state.tracking.guid,
      eventId: callDealerEventId,
      vehicleListingId: vehicle.id,
      leadSource: LEAD_SOURCE,
      interestedInFinancing: Boolean(calculation),
      ...(utmId && { utmId, price }),
      ...(CaptiveIdInUpperCase && { fdlConditions: CaptiveIdInUpperCase }),
    };

    dispatch({
      type: ActionTypes.GET_PHONE_INFO_PENDING,
      payload: {
        form,
        vehicle,
        eventLabel,
        target,
        openSidebar,
        shouldInitiateCall,
      },
    });
  };
};

/**
 * Dispatches the action to initiate submitting a callback lead.
 * Posting it to the backend is done via saga. Check Callback/sagas.js
 * @param {object} form - Form object from component
 * @param {object} vehicleObj
 * @returns {Function} result of async dispatch call
 */
export const createCallbackRequest = (form, vehicleObj) => {
  return (dispatch, getState) => {
    const state = getState();
    const { utm_id: utmId } = getRouterQuery(state);
    const phone = form?.phoneNumber || form?.phone;

    const body = {
      ...form,
      oneClickLeadsConsent: form.oneClickLeadsConsent || false,
      phone,
      leadSource: LEAD_SOURCE,
      type: 'callback',
      vehicleListingId: vehicleObj.id,
      trackingId: state?.tracking?.guid,
      ...(utmId && { price: vehicleObj.price, utmId }),
    };
    dispatch({ type: ActionTypes.SUBMIT_CALLBACK_PENDING, payload: body });
  };
};

/* eslint-disable react/no-multi-comp, react/jsx-curly-newline,react/jsx-props-no-spreading */
import React from 'react';

import {
  ImageWithInfoSectionSectionWrapperSt,
  DetailSectionItemSt,
  SectionWithImageSt,
  Wrapper,
} from './ImageWithInfo.css';
import { ImageWithInfoProps } from './ImageWithInfo.types';

export const ImageWithInfo = ({
  isReverse = false,
  hasBackground = true,
  bgColor,
  whiteBackground = 25,
  leftSection,
  rightSection,
  rightComponents = {},
  mobileVariant = 'primary',
  // leftWidth will be reverse when isReverse is true
  leftWidth = [50],
}: ImageWithInfoProps): JSX.Element => {
  const { heading, description, cta } = rightComponents;

  const mobileComponent = (
    <DetailSectionItemSt
      id="image-info-mobile"
      alignSelf={['flex-start', 'column']}
      alignItems={['flex-start', 'center']}
      isReverse={isReverse}
      hasBackground={hasBackground}
      whiteBackground={whiteBackground}
      bgColor={bgColor}
    >
      <ImageWithInfoSectionSectionWrapperSt
        leftWidth={leftWidth}
        bgColor={bgColor}
      >
        {heading}
        {description}
        {leftSection}
        {cta}
      </ImageWithInfoSectionSectionWrapperSt>
    </DetailSectionItemSt>
  );

  return (
    <Wrapper showMobileVariant={mobileVariant === 'secondary'}>
      {mobileVariant === 'secondary' && mobileComponent}
      <DetailSectionItemSt
        id="image-info-desktop"
        alignSelf={['flex-start', 'column']}
        alignItems={['flex-start', 'center']}
        isReverse={isReverse}
        hasBackground={hasBackground}
        whiteBackground={whiteBackground}
        bgColor={bgColor}
      >
        <SectionWithImageSt leftWidth={leftWidth}>
          {leftSection}
        </SectionWithImageSt>

        <ImageWithInfoSectionSectionWrapperSt
          leftWidth={leftWidth}
          bgColor={bgColor}
        >
          {rightSection}
        </ImageWithInfoSectionSectionWrapperSt>
      </DetailSectionItemSt>
    </Wrapper>
  );
};

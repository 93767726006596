import { Flex } from '@rebass/grid';
import styled, { css } from 'styled-components';

import { media } from '../variables/media';

import { LayoutChildProps } from './TwoColumnContentLayout.types';

export const LayoutWrapperSt = styled(Flex)`
  width: 100%;
`;

export const LayoutChild = styled(Flex)`
  width: ${({ leftWidth }: LayoutChildProps) =>
    leftWidth?.[0] ? leftWidth[0] : '100%'};
  ${media.md(css`
    width: ${({ leftWidth }: LayoutChildProps) =>
      leftWidth?.[1] ? leftWidth[1] : '100%'};
  `)};
  ${media.lg(css`
    width: ${({ leftWidth }: LayoutChildProps) =>
      leftWidth?.[2] ? leftWidth[2] : '30%'};
  `)};
  ${media.xl(css`
    width: ${({ leftWidth }: LayoutChildProps) =>
      leftWidth?.[3] ? leftWidth[3] : '30%'};
  `)};
`;

import React, { useEffect, useRef } from 'react';

const HorizontalTrustpilotWidget = (): JSX.Element => {
  const ref = useRef(null);

  useEffect(() => {
    if (window?.Trustpilot) {
      window.Trustpilot.loadFromElement(ref.current, true);
    }
  }, []);
  return (
    <div
      className="trustpilot-widget horizontal-trustbox"
      data-locale="de-DE"
      data-template-id="5406e65db0d04a09e042d5fc"
      data-businessunit-id="5c65db740526720001ee299b"
      data-style-height="28px"
      data-style-width="100%"
      data-theme="dark"
      data-text-color="#ffffff"
      ref={ref}
    />
  );
};
export default HorizontalTrustpilotWidget;

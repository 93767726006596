/* istanbul ignore file */
import styled, { css } from 'styled-components';
import { Flex } from '@rebass/grid';

import { Link } from 'app/shared/ui';
import {
  CARTILE_HEIGHT,
  CARTILE_IMAGE_WIDTH,
  CARTILE_MARGIN_BOTTOM_VERTICAL,
  CARTILE_MARGIN_BOTTOM_HORIZONTAL,
} from 'app/shared/modules/Cartile/CartileTemplates/CartileTemplates.constant';
import { media, color, colorV2 } from 'app/shared/styles_js/variables';

export const CartileContainerWrapperSt = styled(Flex).attrs(
  ({ isHorizontal, width }) => ({
    flexDirection: 'column',
    as: 'article',
    width: /* istanbul ignore next */ isHorizontal ? 1 : width,
  }),
)`
  position: relative;
  min-width: ${CARTILE_IMAGE_WIDTH.sm}px;
  transition: transform 0.2s;
  transform-origin: center;
  margin-bottom: ${CARTILE_MARGIN_BOTTOM_HORIZONTAL}px;
  padding-left: 12px;
  padding-right: 12px;

  ${media.md(css`
    margin-bottom: ${({ isHorizontal }) =>
      isHorizontal
        ? `${CARTILE_MARGIN_BOTTOM_HORIZONTAL}px`
        : `${CARTILE_MARGIN_BOTTOM_VERTICAL}px`};

    &:hover {
      transform: scale(1.02);
    }
  `)};
`;

export const CartileContainerSt = styled(Link)`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  border-radius: 4px;
  background-color: ${({ backgroundColor }) => colorV2[backgroundColor]};
  cursor: pointer;
  color: ${colorV2.tarmacGrey};

  && {
    text-decoration: none;
    font-weight: 200;
  }
  &:focus,
  &:hover {
      && {
        text-decoration: none;
        color: ${colorV2.tarmacGrey};
      }
  }
  ${({ isHighlighted }) =>
    isHighlighted
      ? css`
          border: 2px solid ${color.brandHighlighted};
          box-shadow: 0 0 8px 0 rgba(251, 210, 137, 1);
        `
      : css`
          box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.15);
        `}
  
   ${({ isUnavailable }) =>
     isUnavailable &&
     css`
       pointer-events: none;
     `}
  
  ${media.md(({ isHorizontal }) =>
    /* istanbul ignore next */ isHorizontal
      ? css`
          height: ${CARTILE_HEIGHT.horizontal.md}px;
          flex-direction: row;
        `
      : css`
          min-height: ${CARTILE_HEIGHT.vertical.md}px;
          flex-direction: column;
        `,
  )}}
  ${media.lg(({ isHorizontal }) =>
    /* istanbul ignore next */ isHorizontal
      ? css`
          height: ${CARTILE_HEIGHT.horizontal.lg}px;
        `
      : css`
          min-height: ${CARTILE_HEIGHT.vertical.lg}px;
        `,
  )}}
  ${media.xl(({ isHorizontal }) =>
    /* istanbul ignore next */ isHorizontal
      ? css`
          height: ${CARTILE_HEIGHT.horizontal.xl}px;
        `
      : css`
          min-height: ${CARTILE_HEIGHT.vertical.xl}px;
        `,
  )}}
`;

export const createTypes = types => {
  const ActionTypes = {};
  types.forEach(type => {
    if (type.endsWith('_')) {
      Object.assign(ActionTypes, {
        [`${type}STARTED`]: `${type}STARTED`,
        [`${type}COMPLETED`]: `${type}COMPLETED`,
        [`${type}FAILED`]: `${type}FAILED`,
        [`${type}CANCELED`]: `${type}CANCELED`
      });
    } else {
      Object.assign(ActionTypes, {
        [type]: type
      });
    }
  });
  return ActionTypes;
};

export interface ICategory {
  name: string;
  type: string;
  linkTo: string;
  title: string;
}

const clpLink = (value: string, type = 'category'): string =>
  `/gebrauchtwagen?${type}=${value}`;

export const categories = [
  {
    name: 'Limousine',
    type: 'limousine',
    linkTo: clpLink('limousine'),
    title: 'A4, Passat, 3er, C-Klasse',
  },
  {
    name: 'SUV',
    type: 'suv',
    linkTo: clpLink('suv'),
    title: 'Audi Q-Serie, VW Touareg',
  },
  {
    name: 'Kombi',
    type: 'kombi',
    linkTo: clpLink('kombi'),
    title: 'A4 Avant, Passat Variant, Octavia Kombi',
  },
  {
    name: 'Cabrio',
    type: 'cabrio',
    linkTo: clpLink('cabrio'),
    title: 'Beetle Cabriolet, TT Cabrio',
  },
  {
    name: 'Kleinwagen',
    type: 'kleinwagen',
    linkTo: clpLink('kleinwagen'),
    title: 'A1, Polo, Ibiza, Citigo',
  },
  {
    name: 'Kompakt',
    type: 'kompaktklasse',
    linkTo: clpLink('kompaktklasse'),
    title: 'A3, Golf, Octavia',
  },
  {
    name: 'Van',
    type: 'van',
    linkTo: clpLink('van'),
    title: 'Touran, Sharan, Seat Alhambra',
  },
  {
    name: 'Elektro',
    type: 'electric',
    linkTo: clpLink('electric', 'fuelType'),
    title: 'A4, Passat, 3er, C-Klasse',
  },
];

import { Flex } from '@rebass/grid';
import { PropTypes } from 'prop-types';
import { Text, Container } from 'app/shared/ui';

import { BannerSt } from './Notification.css';
import { CloseButton } from './components/CloseButton';

/**
 * Shows a fullWidth banner with a close button
 */
export const InfoNotification = ({
  type,
  title,
  onClickClose,
  className,
  color,
}) => (
  <BannerSt
    className={className}
    type={type}
    color={color}
    aria-live="assertive"
    aria-atomic="true"
    data-testid="shared_info_notification"
  >
    <Container>
      <Flex color="white" width={1} alignItems="center">
        <Text as="header" lineHeight="normal" css="flex: 1;">
          <Flex flexDirection="row" flexWrap="wrap" py={12}>
            {title}
          </Flex>
        </Text>
        <CloseButton
          ml="auto"
          alignSelf="center"
          onClick={onClickClose}
          /* istanbul ignore next */
          color={type === 'success' ? 'micaBlue' : 'white'}
        />
      </Flex>
    </Container>
  </BannerSt>
);

InfoNotification.defaultProps = {
  className: null,
  color: '',
};

InfoNotification.propTypes = {
  title: PropTypes.oneOfType([PropTypes.node, PropTypes.element]).isRequired,
  type: PropTypes.oneOf(['success', 'error']).isRequired,
  onClickClose: PropTypes.func,
  className: PropTypes.string,
  color: PropTypes.string,
};

InfoNotification.defaultProps = {
  onClickClose: () => {},
};

import { getSelectedFinancingOptions } from './getSelectedFinancingOptions';
import { FDLContactDealerMessages, userFinancingSettings } from './messages';

/**
 * @description Generates a string including user's
 * selected financing calculator configuration
 *
 * @param {object} calculationData user's selected financing settings
 */
export function getFinancingMessageWithFinancingData(calculationData) {
  const financingData = getSelectedFinancingOptions(calculationData);

  const templateForFinancingData = Object.keys(financingData)
    .map((key) => userFinancingSettings[key](financingData))
    .filter((val) => val)
    .join('\r\n');

  return FDLContactDealerMessages.financingDetails(templateForFinancingData);
}

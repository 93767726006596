import * as ActionTypes from './constants';

export const setHasCookieConsent = () => {
  return { type: ActionTypes.SET_AGREE_TO_ALL };
};

export const setRejectAllCookies = () => {
  return { type: ActionTypes.SET_REJECT_ALL };
};

export const updateCookieStatus = (status) => {
  return { type: ActionTypes.UPDATE_COOKIE_STATUS, payload: status };
};

export const setHasSavedCookieSettings = () => {
  return { type: ActionTypes.SET_HAS_SAVED_COOKIE_SETTINGS };
};

export const migrateCookieDashboard = () => {
  return { type: ActionTypes.MIGRATE_COOKIE_DASHBOARD };
};

/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable react/prop-types */
import React from 'react';
import { useInView } from 'react-intersection-observer';

/* @ts-ignore */
import smallDataMap from '../../../../../../../../../client/assets/makes/generated/56/makes-sprite.json';
import { Link } from '../../../../../Link/Link';

import { BoxSt, LogoSt } from './BrandImageLink.css';
import { IBrandImageLinkProps } from './BrandImageLink.types';

export const BrandImageLink: React.FC<IBrandImageLinkProps> = ({
  brand: { url, label, id, useRouter = true },
  setTracking,
  closeMenu,
}) => {
  const [ref, inView] = useInView({ triggerOnce: true, rootMargin: '0px' });
  const dataMap = {
    56: smallDataMap,
  };

  const sprite = dataMap[56] && dataMap[56][id];

  const imagesToLoad =
    '/assets/makes/generated/56/cc9394c5f8f0b72eb702b5e335d8d48d-makes-sprite.png';
  return (
    <>
      <BoxSt
        data-tooltip={label}
        data-flow="top"
        data-for={id}
        mr={['8px', '16px', '16px']}
        mb={['8px', 0, 0]}
        width="56px"
        ref={ref}
      >
        <Link
          to={url}
          useRouter={useRouter}
          onClick={(e) => {
            e.persist();
            setTracking('makeLogo', id);
            closeMenu(false);
          }}
        >
          {inView && (
            <LogoSt
              x={sprite.offset_x}
              y={sprite.offset_y}
              size={112}
              image={imagesToLoad}
              isVisible
            />
          )}
        </Link>
      </BoxSt>
    </>
  );
};

/* istanbul ignore file */
/* eslint-disable @typescript-eslint/restrict-template-expressions */
import styled, { css } from 'styled-components';
import { Link } from 'app/shared/ui';

import { media } from 'app/shared/styles_js/variables';

import {
  ITileContainerStProps,
  ITileTitleStProps,
  ITileSubtitleStProps,
} from './Types/Promotion.types';

// This is the tile width where we take as the base from the design for calculating the measurements
// (e.g. the font sizes, area heights, etc.)
// See design guide: https://www.figma.com/file/za0ieFRNF1970k9l3LecJd/Generic-CLP-product-menu
const baseTileWidth = 184;

export const TileContainerSt = styled(Link)<ITileContainerStProps>`
  position: relative;
  display: grid;
  grid-template-rows:
    calc(52 / ${baseTileWidth} * 100%) calc(82 / ${baseTileWidth} * 100%)
    calc(50 / ${baseTileWidth} * 100%);
  place-items: stretch;
  align-items: stretch;
  text-align: center;
  width: ${({ $tileDimension }) => $tileDimension};
  height: ${({ $tileDimension }) => $tileDimension};
  border-radius: 4px;
  color: inherit;
  text-decoration: none !important;
  transition: transform 0.2s ease-in-out;
  background: ${({ $tileBackground }) => $tileBackground};
  box-shadow: 0px 2px 4px rgba(29, 29, 29, 0.06);

  && {
    text-decoration: none !important;
  }

  mark {
    padding: 0 2px;
    box-decoration-break: clone;
  }

  ${media.md(css`
    &:hover,
    &:active,
    &:focus {
      transform: scale(1.02);

      && {
        text-decoration: none !important;
      }
    }
  `)}
`;

export const TileTitleSt = styled.div<ITileTitleStProps>`
  display: grid;
  place-items: center;
  padding: 12px 8px 0;
  font-size: ${({ $tileDimension }) =>
    `calc(14 / ${baseTileWidth} * ${$tileDimension})`};
  line-height: ${({ $tileDimension }) =>
    `calc(20 / ${baseTileWidth} * ${$tileDimension})`};
  font-weight: 300;
  color: ${({ $titleColor }) => $titleColor};
  /* An exception for mobile device with smaller than 360px to fit the content fully and properly */
  @media (max-width: 359px) {
    letter-spacing: -0.12px;
  }

  mark {
    color: ${({ $titleMarkColor }) => $titleMarkColor};
    background: ${({ $titleMarkBackground }) => $titleMarkBackground};
  }

  > p {
    /* stylelint-disable-next-line */
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
    margin: 0;
    padding: 0;
  }
`;

export const TileCarImageSt = styled.div`
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

export const TileSubtitleSt = styled.div<ITileSubtitleStProps>`
  display: grid;
  place-items: center;
  padding: 0 8px 8px;
  font-size: ${({ $tileDimension }) =>
    `calc(10 / ${baseTileWidth} * ${$tileDimension})`};
  line-height: ${({ $tileDimension }) =>
    `calc(14 / ${baseTileWidth} * ${$tileDimension})`};
  font-weight: 400;
  color: ${({ $subtitleColor }) => $subtitleColor};

  mark {
    color: ${({ $subtitleMarkColor }) => $subtitleMarkColor};
    background: ${({ $subtitleMarkBackground }) => $subtitleMarkBackground};
  }

  > p {
    /* stylelint-disable-next-line */
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    overflow: hidden;
    margin: 0;
    padding: 0;
  }
`;

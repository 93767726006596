import { connect } from 'react-redux';

import { breakpointSelector } from 'app/shared/modules/globalEvents/selectors';

import { SpecialOffersSlider } from './SpecialOffersSlider';

function mapStateToProps(state, props) {
  return {
    specialOffers: props.specialOffers,
    breakpoint: breakpointSelector(state),
    error: state.specialOffers.error,
  };
}

export const ConnectedSpecialOffersSlider = connect(
  mapStateToProps,
  {},
)(SpecialOffersSlider);

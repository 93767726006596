import { Asset } from 'contentful';
import { useMemo } from 'react';


type ImageSize = 'small'|'medium'|'large'|'xLarge';
type ResponsiveImagesCFFields =  {title: string} & Record<ImageSize, Asset[]>;
type ResponsiveImageMedia = Record<ImageSize, string>;

interface ResponsiveImageDict {
  title: string;
  media: ResponsiveImageMedia,
  anyResponsiveImagesPresent: boolean;
}


export function useResponsiveImage(fields: ResponsiveImagesCFFields): ResponsiveImageDict  {
  const init = { title: '', media: {small: '', medium: '', large: '', xLarge: ''}, anyResponsiveImagesPresent: false };
  return useMemo<ResponsiveImageDict>(() => {
    if(!fields) {
      return init;
    }
    return Object.entries(fields).reduce(
      (acc, [fieldKey, fieldValue]) => {
      
        const dict = acc;
        if(typeof fieldValue === 'string') {
          dict.title = fieldValue;
          return dict;
        }

        if (Array.isArray(fieldValue)) {
          const { url } = fieldValue[0]?.fields?.file || {};
          dict.media[fieldKey] = url;
          dict.anyResponsiveImagesPresent = true;
        } else {
          dict.media[fieldKey] = undefined;
        }
        return dict;
      },
      init,
    );
  }
  , [fields]);

}

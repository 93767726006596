/**
 * @typedef {import("app/types/entities/Dealer.types").IDealerOpeningTime} IDealerOpeningTime
 */

const getTime = (date) => date.getTime();
const parseTime = (time) => parseInt(time, 10);
const isInvalid = (data) => !data || (data && !data[0]);
const minInMs = (minutes) => minutes * 60 * 1000;
const timeFormat = (time) => (time < 10 ? `0${time}` : time);

/**
 * Gets the last Sunday of a specific month
 * @param {int} month - month to be analysed
 * @returns {int}
 */
export const getLastSundayOfMonth = (month) => {
  const date = new Date();
  date.setMonth(month);
  date.setDate(0);
  return date.getDate() - date.getDay();
};

/**
 * Checks if it is currently Daylight Saving Time in Germany.
 * @returns {Boolean}
 */
export const isDSTInGermany = () => {
  const today = new Date();
  const currentYear = today.getUTCFullYear();
  const lastSundayMarch = new Date(
    currentYear,
    2,
    getLastSundayOfMonth(3),
    0,
    0,
  );
  const lastSundayOctober = new Date(
    currentYear,
    9,
    getLastSundayOfMonth(10),
    0,
    0,
  );
  const isPastLastSundayMarch = today.getTime() >= lastSundayMarch.getTime();
  const isBeforeLastSundayOctober =
    today.getTime() <= lastSundayOctober.getTime();

  // It's never DST in Jan, Feb, Nov and Dec in Germany
  if (today.getMonth() < 2 || today.getMonth() > 9) {
    return false;
  }
  return isPastLastSundayMarch && isBeforeLastSundayOctober;
};

/*
 * Since the closing/opening dates are in Europe/Berlin timezone, we need to
 * make sure that all dates used to compare them with are in Europe/Berlin timezone as well.
 * Due to date constructor with string timezone being incompatible in Internet Explorer,
 * the timezone conversion has to be done manually, using times in milliseconds.
 * Unfortunately, there is no better way of turning localtime into utc.
 */
export const berlinDateTime = () => {
  const localDate = new Date();
  const localTime = localDate.getTime();
  const localOffset = localDate.getTimezoneOffset() * minInMs(1);
  const utcTime = localTime + localOffset;
  const diffTimeZone = isDSTInGermany() ? minInMs(120) : minInMs(60);
  return new Date(utcTime + diffTimeZone);
};

/**
 * Validates whether dealerOpeningTimes array has at least one day with open and closing times
 * @param {IDealerOpeningTime[]} dealerOpeningTimes - array containing data for each day of the week
 * @returns {Boolean}
 */
export const isValidOpeningTimes = (dealerOpeningTimes) =>
  dealerOpeningTimes?.findIndex((day) => day.openFrom && day.openTo) > -1;

/**
 * Gets the closing time specified by the dealership
 * @param {IDealerOpeningTime[]} dealerOpeningTimes - array containing data for each day of the week
 * @param {TJSDay} dayOfWeekToday - day of the week (from 0 [Sunday] to 6 [Saturday])
 * @returns {String}
 */
export const getClosingTime = (
  dealerOpeningTimes,
  dayOfWeekToday = berlinDateTime().getDay(),
) => {
  // In the opening hours received from the API, Sunday is #7 and for JS Sunday is #0
  if (dayOfWeekToday === 0) {
    // eslint-disable-next-line no-param-reassign
    dayOfWeekToday = 7;
  }
  //  On Saturdays, the dealerships close early
  const fallBack = dayOfWeekToday === 6 ? '14:00' : '19:00';

  if (!dealerOpeningTimes || !isValidOpeningTimes(dealerOpeningTimes)) {
    return fallBack;
  }

  const todayOpeningTimes = dealerOpeningTimes.find(
    (ot) => ot.dayOfWeek === dayOfWeekToday,
  );

  return todayOpeningTimes?.openTo;
};

/**
 * Gets the opening time specified by the dealership
 * @param {IDealerOpeningTime[]} dealerOpeningTimes - array containing data for each day of the week
 * @param {TJSDay} dayOfWeekToday - day of the week (from 0 [Sunday] to 6 [Saturday])
 * @returns {String}
 */
export const getOpeningTime = (
  dealerOpeningTimes,
  dayOfWeekToday = berlinDateTime().getDay(),
) => {
  // In the opening hours received from the API, Sunday is #7 and for JS Sunday is #0
  if (dayOfWeekToday === 0) {
    // eslint-disable-next-line no-param-reassign
    dayOfWeekToday = 7;
  }
  //  On Saturdays, the dealerships open late
  const fallBack = dayOfWeekToday === 6 ? '08:30' : '07:00';

  if (!dealerOpeningTimes || !isValidOpeningTimes(dealerOpeningTimes)) {
    return fallBack;
  }

  const todayOpeningTimes = dealerOpeningTimes.find(
    (ot) => ot.dayOfWeek === dayOfWeekToday,
  );

  return todayOpeningTimes?.openFrom;
};

/**
 * By default we state that the dealership is open between 07:00 and 19:00 on
 * weekdays, from 08:30 to 14:00 on Saturdays and closed on Sundays
 * @return {Boolean}
 */
export const isOpenByDefault = () => {
  const today = berlinDateTime();
  const openingDateTime = berlinDateTime();
  const closingDateTime = berlinDateTime();

  //  Closed on Sundays
  if (today.getDay() === 0) {
    return false;
  }
  //  Open from 08:30 to 14:00 on Saturdays
  if (today.getDay() === 6) {
    openingDateTime.setHours(8, 30, 0);
    closingDateTime.setHours(14, 0, 0);
  }
  //  Open from 07:00 to 19:00 on weekdays
  else {
    openingDateTime.setHours(7, 0, 0);
    closingDateTime.setHours(19, 0, 0);
  }
  return (
    getTime(today) >= getTime(openingDateTime) &&
    getTime(today) <= getTime(closingDateTime)
  );
};

/**
 * Checks if dealership is open based on the data provided by them regarding their opening times
 * @param {IDealerOpeningTime[]} dealerOpeningTimes - array containing data for each day of the week
 * @param {boolean} useHardCutOffTimes - should use the hard cut off times
 * @param {TJSDay} dayOfWeek - day of the week (from 0 [Sunday] to 6 [Saturday])
 * @return {Boolean}
 */
export const isDealershipOpen = (
  dealerOpeningTimes,
  useHardCutOffTimes = true,
  dayOfWeek = berlinDateTime().getDay(),
) => {
  const today = berlinDateTime();
  const openingDateTime = berlinDateTime();
  const closingDateTime = berlinDateTime();

  //  Dealerships are always closed on Sundays when relying on the hard cut offs
  if (useHardCutOffTimes && (dayOfWeek === 0 || dayOfWeek === 7)) {
    return false;
  }
  /*
   * defaults to the result of the established hard cut offs
   * if dealer data is invalid or missing
   */
  //  istanbul ignore next
  if (!dealerOpeningTimes || !isValidOpeningTimes(dealerOpeningTimes)) {
    return isOpenByDefault();
  }

  // Get dealership's opening time and format it to [hour, minute]
  const openingTime = getOpeningTime(dealerOpeningTimes, dayOfWeek)?.split(':');
  // Get dealership's closing time and format it to [hour, minute]
  const closingTime = getClosingTime(dealerOpeningTimes, dayOfWeek)?.split(':');

  if (isInvalid(openingTime) || isInvalid(closingTime)) {
    return false;
  }

  if (openingTime) {
    openingDateTime.setHours(
      parseTime(openingTime[0]),
      parseTime(openingTime[1]),
      0,
    );

    if (useHardCutOffTimes) {
      //  On weekdays, if dealership's opening time is before 07:00, ignore it and set as 07:00
      if (dayOfWeek !== 6 && parseTime(openingTime[0]) < 7) {
        openingDateTime.setHours(7, 0, 0);
      }
      //  On Saturdays, if dealership's opening time is before 08:00, ignore it and set as 08:30
      else if (dayOfWeek === 6 && parseTime(openingTime[0]) <= 8) {
        openingDateTime.setHours(8, 30, 0);
      }
    }
  }

  if (closingTime) {
    closingDateTime.setHours(
      parseTime(closingTime[0]),
      parseTime(closingTime[1]),
      0,
    );

    if (useHardCutOffTimes) {
      //  On weekdays, if dealership's closing time is after 19:00, ignore it and set as 19:00
      if (dayOfWeek !== 6 && parseTime(closingTime[0]) > 19) {
        closingDateTime.setHours(19, 0, 0);
      }
      //  On weekends, if dealership's closing time is after 14:00, ignore it and set as 14:00
      else if (dayOfWeek === 6 && parseTime(closingTime[0]) > 14) {
        closingDateTime.setHours(14, 0, 0);
      }
    }
  }
  return (
    getTime(today) >= getTime(openingDateTime) &&
    getTime(today) <= getTime(closingDateTime)
  );
};

export const openingDays = {
  1: 'Montag',
  2: 'Dienstag',
  3: 'Mittwoch',
  4: 'Donnerstag',
  5: 'Freitag',
  6: 'Samstag',
  7: 'Sonntag',
};

/**
 * Gets the next day and time that the dealership is gonna be open
 * @param {Array<Object>} openingTimes - array containing data for each day of the week
 * @returns {Object} containing day and time attributes
 */
export const getNextOpeningTime = (openingTimes) => {
  const now = berlinDateTime();
  let dayOfWeekToday = now.getDay();
  const nextOpenedDay = {};

  //  The week finishes on Sunday (#7) and it starts again on Monday (#1)
  const getNextWeekDay = (day) => (day === 7 ? 1 : day + 1);
  const hasOpeningHoursToday = (day) =>
    openingTimes.find((ot) => ot.dayOfWeek === day && ot.openFrom);

  // In the opening hours received from the API, Sunday is #7 and for JS Sunday is #0
  if (dayOfWeekToday === 0) {
    dayOfWeekToday = 7;
  }

  /*
   * defaults to the result of the established hard cut offs
   * if dealer data is invalid or missing
   */
  if (!openingTimes || !isValidOpeningTimes(openingTimes)) {
    nextOpenedDay.day = 'morgen';
    nextOpenedDay.time = '07:00';

    /*
     * If it's Friday, we say that the next opening time is at 08:30 because
     * on Saturdays the dealerships open a little later
     */
    if (dayOfWeekToday === 5) {
      nextOpenedDay.time = '08:30';
    }

    //  On Saturdays we say, by default, that the next opening day is Monday
    if (dayOfWeekToday === 6) {
      nextOpenedDay.day = `am ${openingDays[1]}`;
    }

    return nextOpenedDay;
  }

  const todayOpeningTimes = hasOpeningHoursToday(dayOfWeekToday);
  if (todayOpeningTimes) {
    // Get dealership's opening time and format it to [hour, minute]
    const dealerOpeningTime = todayOpeningTimes.openFrom.split(':');
    const openingDate = berlinDateTime();

    openingDate.setHours(
      parseTime(dealerOpeningTime[0]),
      parseTime(dealerOpeningTime[1]),
      0,
    );
    /*
     * If the user is checking the website before the dealership today's opening
     * time, we say that it's gonna open today at a certain time
     */
    if (now < openingDate) {
      nextOpenedDay.day = 'heute';
      nextOpenedDay.time = `${timeFormat(openingDate.getHours())}:${timeFormat(
        openingDate.getMinutes(),
      )}`;
      return nextOpenedDay;
    }
  }

  let nextDayOfWeek = getNextWeekDay(dayOfWeekToday);
  //  Looping throughout the days of the week
  while (nextDayOfWeek < 8) {
    const nextDayOpeningTimes = hasOpeningHoursToday(nextDayOfWeek);

    if (nextDayOpeningTimes) {
      nextOpenedDay.day = `am ${openingDays[nextDayOpeningTimes.dayOfWeek]}`;
      nextOpenedDay.time = nextDayOpeningTimes.openFrom;

      //  If the next open day is tomorrow or Sunday, show "tomorrow"
      if (nextDayOfWeek === dayOfWeekToday + 1 || dayOfWeekToday === 7) {
        nextOpenedDay.day = 'morgen';
      }

      return nextOpenedDay;
    }

    nextDayOfWeek = getNextWeekDay(nextDayOfWeek);
  }
};

import styled, { css } from 'styled-components';
import { color } from '../../../styles_js/variables';

export const ItemSt = styled.div`
  display: block;
  width: 100%;
  padding: 10px 5px;
  border-bottom: 1px solid ${color.grayLight};
  background-color: #fff;
  cursor: pointer;

  ${props =>
    props.focused &&
    css`
      background-color: ${color.grayLightest};
    `}

  &:hover {
    background-color: ${color.grayLightest};
  }
`;

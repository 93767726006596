/* eslint-disable @typescript-eslint/no-unsafe-return */
import styled from 'styled-components';
import { Link as ReactRouterLink } from 'react-router';

import { colors } from '../variables/colors';

import { ILinkProps } from './Link.types';

export const RouterLinkSt = styled(ReactRouterLink).withConfig({
  shouldForwardProp: (prop) => !['hoverColor', 'useRouter'].includes(prop),
}) <ILinkProps>`
  padding: 0;
  border: 0;
  background: none;
  color: ${({ color }): string => color && (colors[color] || color)};

  && {
    font-weight: ${({ fontWeight }) => fontWeight || 'normal'};
    text-decoration: ${({ textDecoration }) => textDecoration || 'none'};
  }

  &:hover,
  &:focus {
    /* Match hovercolor to a theme color, else use its value directly else normal color */
    color: ${({ hoverColor, color }): string =>
    (hoverColor && (colors[hoverColor] || hoverColor)) || color};

    && {
      text-decoration: ${({ textDecoration }) => textDecoration || 'none'};
    }
  }

  &:focus {
    /* WebKit-specific. Other browsers will keep their default outline style.
      (Initially tried to also force default via 'outline: initial',
      but that seems to erroneously remove the outline in Firefox altogether.) */
    outline: 5px auto -webkit-focus-ring-color;
    outline-offset: -2px;
  }

  &:hover {
    cursor: pointer;
  }
`;

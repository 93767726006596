/* eslint-disable react/jsx-props-no-spreading */
import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Markdown from 'markdown-to-jsx';
import { Box } from '@rebass/grid';

import {
  getPrintableVoucherScreenContent,
  descriptionMarkdownOptions,
  disclaimerMarkdownOptions,
} from './utils';
import { DisclaimerBoxSt } from './PrintableVoucherCode.css';

export const PrintableVoucherCode = ({ location, campaigns }) => {
  const [description, setDescription] = useState('');
  const [disclaimer, setDisclaimer] = useState('');

  const {
    query: { payload, campaignId },
  } = location;

  // Force print when we get the description or disclaimer
  useEffect(() => {
    if (description || disclaimer) {
      window.document.body.style.overflow = 'auto';
      window.print();
    }
  }, [description, disclaimer]);

  useEffect(() => {
    const printableVoucherScreenContent = getPrintableVoucherScreenContent(
      campaigns,
      campaignId,
      payload,
    );

    setDescription(printableVoucherScreenContent?.description);
    setDisclaimer(printableVoucherScreenContent?.disclaimer);
  }, [campaigns]);

  if (!description && !disclaimer) return null;

  return (
    <Box p={32}>
      <Markdown options={descriptionMarkdownOptions}>{description}</Markdown>
      <DisclaimerBoxSt>
        <Markdown options={disclaimerMarkdownOptions}>{disclaimer}</Markdown>
      </DisclaimerBoxSt>
    </Box>
  );
};

PrintableVoucherCode.propTypes = {
  location: PropTypes.object.isRequired,
  campaigns: PropTypes.array,
};

PrintableVoucherCode.defaultProps = {
  campaigns: [],
};

/* eslint-disable @typescript-eslint/no-unsafe-return,@typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-member-access */
/* eslint-disable no-unexpected-multiline */

import styled, { css } from 'styled-components';

import { Link } from 'app/shared/ui';
import { media, height, fontSizes } from 'app/shared/styles_js/variables';
import { Title } from 'app/shared/components/Title/Title';

export const NavbarWrapperSt = styled.header<{
  isHome?: boolean;
}>`
  height: ${({ isHome }) => (isHome ? `${height.topNavBar}px` : '100%')};

  .list-menu {
    /* Keep the system font with nearest resemblance to the original font initially until the original fonts are loaded to avoid Flash Of Unstyled Text */
    font-family: 'Adjusted Arial Fallback', sans-serif, 'Helvetica Neue',
      Helvetica, Arial;
    letter-spacing: 0.6px;

    .fonts-loaded &,
    .fonts-observer-fail & {
      letter-spacing: 0;
      font-family: 'Objektiv', 'Adjusted Arial Fallback', sans-serif,
        'Helvetica Neue', Helvetica, Arial;
    }
  }
`;

const getLogoPosition = (
  top: string | number,
  left: string | number,
  scale: string | number,
) => css`
  transform: translate3d(${top}, ${left}, 0) scale3d(${scale}, ${scale}, 1);
`;

export const TitleSt = styled(Title)`
  ${getLogoPosition('-10px', '-1px', 0.8)};
  ${media.md(getLogoPosition(0, 0, 1))};

  & > a {
    display: 'inline-flex';
    height: 32px; /* height of logo viewbox */
  }

  & > a > svg {
    width: 96px;
    height: 32px;
  }
`;

export const NavLinkSt = styled(Link)<{
  $isMobileDevice: boolean;
  $isTipModal: boolean;
}>`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;

  pointer-events: ${(props) => (props.$isTipModal ? 'none' : 'auto')};
  ${({ $isMobileDevice, theme: { colors } }) =>
    css`
      &:hover,
      &:active {
        color: ${$isMobileDevice ? colors.micaBlue : colors.actionBlue};
      }

      &:focus {
        color: ${colors.micaBlue};
      }
    `}

  ${media.lg(css`
    flex-direction: row;
  `)};
`;

export const IconSt = styled.span`
  position: relative;
  display: flex;
  font-size: ${fontSizes.h5};

  ${media.lg(css`
    margin-right: 8px;
  `)};

  ${media.lg(
    css`
      margin-right: 0;
    `,
  )};
`;

export const FavIconSt = styled.i`
  position: relative;
  font-size: 24px;

  @keyframes pulseOne {
    0% {
      opacity: 0;
      transform: translateY(30px);
    }

    20% {
      opacity: 0.2;
    }

    50% {
      opacity: 1;
      transform: translateY(0) scale(1);
    }

    90% {
      transform: scale(2);
    }

    100% {
      transform: scale(1);
    }
  }

  &.if-icon-favorite-active {
    color: ${({ theme: { colors } }) => colors.brandSecondaryLight};
    animation: pulseOne 0.7s forwards;
  }
`;

export const PageLoadingSpinnerSt = styled.div<{ isLoading?: boolean }>`
  position: fixed;
  z-index: -5;
  display: flex;
  width: 100%;
  height: 100vh;
  align-items: center;
  justify-content: center;
  transition: opacity 0.6s ease-in-out;
  opacity: ${({ isLoading }) => (isLoading ? '1' : '0')};
  pointer-events: none;
`;

export const IndicatorSt = styled.div<{
  showFavouriteTip: boolean;
  showSaSeDashboardTip: boolean;
}>`
  position: absolute;
  right: -5px;
  bottom: 2px;
  width: 12px;
  height: 12px;
  font-size: 12px;
  line-height: 11px;
  background-color: ${({ theme: { colors } }) => colors.brandPrimary};
  text-align: center;

  & > span {
    display: inline-block;
    color: ${({ theme: { colors } }) => colors.brandSecondaryLighter};
    vertical-align: middle;
  }

  ${media.md(css`
    color: 'inherit';
  `)};
`;

export const SupportLinkSt = styled(Link)`
  /* since there is no color coming from theme on contentful pages, using static colors here */
  /* TODO: add theme support here */
  color: #00846a;

  &:hover,
  &:focus {
    color: #00cea5;
  }
`;

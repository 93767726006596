import { getValueFromSelectedItem } from './getValueFromSelectedItem';

export const prepareParameters = ({ sections = [], specificData = {} }) => {
  const parameters = sections
    ?.reduce(
      (result, { components }) =>
        components &&
        result.concat(
          components.map(component => ({
            ...getValueFromSelectedItem(component),
            id: component.id,
            type: component.type,
          })),
        ),
      [],
    )
    .filter(({ id, value }) => id && (!!value || value === 0));

  return {
    parameters,
    specificData,
  };
};

import styled, { css } from 'styled-components';
import { Box, Flex } from '@rebass/grid';

import { Container } from '../../../Container/Container';
import { GridContainer } from '../../../GridContainer';
import { media } from '../../../variables/media';
import { NavFlyoutContainerSt } from '../NavFlyoutContainer/NavFlyoutContainer.css';

export const BoxSt = styled(Box)<{ isFullHeight: boolean }>`
  border-bottom: 1px solid ${(props) => props.theme.colors.gray};
  margin-bottom: 0;

  ${({ isFullHeight }) =>
    isFullHeight &&
    media.lg(css`
      height: 64px;
      display: flex;
      align-items: center;
    `)};

  ${media.lg(
    css`
      border-bottom: none;
    `,
  )}
  &.active {
    ${NavFlyoutContainerSt} {
      /* stylelint-disable-next-line unit-no-unknown */
      height: 100dvh;
      transform: translateX(0);
      opacity: 1;
      left: 0;

      padding-bottom: 64px;
      ${media.lg(
        css`
          transform: none;
          top: 64px;
          height: auto;
          padding-bottom: 0;
        `,
      )}
    }
  }
`;

export const ContainerSt = styled(Container)`
  ${media.md(
    css`
      margin: 0;
      width: 100%;
      padding: 0 24px 0 24px;
    `,
  )}
`;

export const FlexSt = styled(Flex)`
  grid-column: 1 / span 12;
`;

export const GridContainerSt = styled(GridContainer)`
  width: 100%;
`;

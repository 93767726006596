/* istanbul ignore file */
export const IMAGE_RATIO = 4 / 3;

export const CARTILE_HEIGHT = {
  vertical: {
    sm: 520,
    md: 520,
    lg: 520,
    xl: 520,
  },
  horizontal: {
    sm: 500,
    md: 230,
    lg: 220,
    xl: 218,
  },
};

export const CARTILE_IMAGE_WIDTH = {
  sm: 296,
  md: Math.ceil(CARTILE_HEIGHT.horizontal.md * IMAGE_RATIO),
  lg: Math.ceil(CARTILE_HEIGHT.horizontal.lg * IMAGE_RATIO),
  xl: Math.ceil(CARTILE_HEIGHT.horizontal.xl * IMAGE_RATIO),
};

export const CARTILE_IMAGE_HEIGHT = {
  sm: Math.ceil(CARTILE_IMAGE_WIDTH.sm / IMAGE_RATIO),
  md: CARTILE_HEIGHT.horizontal.md,
  lg: CARTILE_HEIGHT.horizontal.lg,
  xl: CARTILE_HEIGHT.horizontal.xl,
};

export const CARTILE_CONTENT_VERTICAL_HEIGHT = {
  md: CARTILE_HEIGHT.vertical.md - CARTILE_IMAGE_HEIGHT.md,
  lg: CARTILE_HEIGHT.vertical.lg - CARTILE_IMAGE_HEIGHT.lg,
  xl: CARTILE_HEIGHT.vertical.xl - CARTILE_IMAGE_HEIGHT.xl,
};

export const CARTILE_PADDING = 12;
export const CARTILE_MARGIN_BOTTOM_VERTICAL = 32;
export const CARTILE_MARGIN_BOTTOM_HORIZONTAL = 8;
export const CONTENT_HEADER_HEIGHT = 42;
export const CONTENT_FOOTER_HEIGHT_MD = 50;
export const CONTENT_FOOTER_HEIGHT_LG = 56;
export const CONTENT_INFO_MARGIN_VERTICAL = 16;
export const CONTENT_CONTAINER_PADDING_VERTICAL = 12;
export const CONTENT_CONTAINER_PADDING_HORIZONTAL = 16;
export const CONTENT_INFO_MIN_HEIGHT = 60;

import { formatNumber } from 'app/shared/utils/formatNumber';
import {
  IAggregations,
  IDefaultAggregation,
} from 'app/types/apis/search.types';

import { TSearchFilters } from '../../types';

export function updateListingsCountOnFilters({
  state,
  payload,
}: {
  state: TSearchFilters;
  payload: IAggregations;
}): TSearchFilters {
  const filters = {};

  Object.keys(state).forEach((filterCategory) => {
    const filterOptions =
      (payload[filterCategory] as IDefaultAggregation[]) || [];

    if (filterOptions.length > 0) {
      filters[filterCategory] = {
        filterOptions: filterOptions.map(({ displayName, count }) => ({
          optionName: displayName,
          listingsCount: formatNumber(count),
        })),
      };
    }
  });

  return { ...state, ...filters };
}

import styled, { css } from 'styled-components';
import { is, isNot, grid, media } from 'app/shared/styles_js/variables';

export const FlexCol = styled.div`
  display: block;
  flex: 0 1 auto;
  align-self: auto;
  order: 0;
  ${isNot('noPadding')`
    padding-right: ${grid.gutter.sm}px;
    padding-left: ${grid.gutter.sm}px;

    ${media.md(css`
      padding-right: ${grid.gutter.md}px;
      padding-left: ${grid.gutter.md}px;
    `)};
  `};
  ${is('noPadding')`
    padding-right: 0;
    padding-left: 0;
  `};

  ${is('width')`
    width: 100%;

    ${media.md(css`
      width: ${props => props.width};
    `)};
  `};

  ${is('inlineBlock')`
    display: inline-block;
  `};
  ${is('inlineFlex')`
    display: inline-flex;
  `};
  ${is('flex')`
    display: flex;
  `};
  /********************************** order **********************************/
  /****************** http://cssreference.io/property/order ******************/
  ${is('order')`
    order: ${props => props.order};
  `};
  /******************************** flex-basis ********************************/
  /**************** http://cssreference.io/property/flex-basis ****************/
  ${is('basis')`
    flex-basis: ${props => props.basis};
  `};
  /******************************** flex-grow ********************************/
  /**************** http://cssreference.io/property/flex-grow ****************/
  ${is('grow')`
    flex-grow: ${props => props.grow};
  `};
  /******************************* flex-shrink *******************************/
  /*************** http://cssreference.io/property/flex-shrink ***************/
  ${is('shrink')`
    flex-shrink: ${props => props.shrink};
  `};
  ${is('noShrink')`
    flex-shrink: 0;
  `};
`;

/* istanbul ignore file */
import styled from 'styled-components';

import { PopoverSt } from '../../modules/search/SearchPane/SearchInputPopoverRenderer/SearchInputPopoverRenderer.css';
import { color, fontSizes, zIndex } from '../../styles_js/variables';
import { Button } from '../Button/Button';

export const InputContainerSt = styled.div`
  display: flex;
  align-items: stretch;
  background-color: #fff;

  .sk-fading-circle {
    position: relative;
    z-index: 1;
    top: 0;
    left: auto;
    margin-top: 0;
    margin-left: 0;
  }

  & > ${PopoverSt} {
    z-index: ${zIndex.sliderLabelText + 1};
  }

  ${(props) => props.extraStyles};
`;

export const InputSt = styled.input`
  height: 32px;
  flex: 1;
  padding: 5px 8px;
  border: 1px solid ${({ theme }) => theme.colors.grayDark};
  border-right: 0;
  border-radius: 4px;
  color: ${color.brandSecondary};
  font-size: 14px;
  font-weight: 100;
  line-height: 1;

  border-top-right-radius: 0;
  border-bottom-right-radius: 0;

  ::-webkit-search-decoration,
  ::-webkit-search-cancel-button,
  ::-webkit-search-results-button,
  ::-webkit-search-results-decoration {
    display: none;
  }

  &::placeholder {
    color: ${color.grayDark};
    transition: opacity 0.2s ease-in-out;
  }

  &:focus::placeholder {
    opacity: 0;
  }
`;

export const IconButtonSt = styled(Button)`
  display: flex;
  width: 32px;
  min-width: auto;
  height: auto;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme: { colors } }) => colors.actionBlue};
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;

  &:hover,
  &:focus {
    background-color: ${({ theme: { colors } }) => colors.micaBlue};
  }

  & > .sk-fading-circle {
    height: 20px;
    width: 20px;
  }

  & > .sk-fading-circle > .sk-circle::before {
    background-color: ${color.grayLight};
    height: 3px;
    width: 3px;
  }
`;

export const IconAfterSt = styled.i`
  color: ${color.white};
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  font-size: 16px;
`;

export const ErrorTextSt = styled.span`
  display: block;
  margin-top: 8px;
  font-size: ${fontSizes.default};
  color: ${({ theme: { colors } }) => colors.brandDanger};
`;

import { memo } from 'react';
import PropTypes from 'prop-types';

import {
  ArticleButtonContainerSt,
  ButtonSt,
  ButtonTextSt,
} from './ArticleButton.css';

const ArticleButtonCmp = ({ buttonSlug, buttonText, isOpeningInNewTab }) => {
  return (
    <ArticleButtonContainerSt>
      <ButtonSt to={buttonSlug} target={isOpeningInNewTab ? '_blank' : '_self'}>
        <ButtonTextSt>{buttonText}</ButtonTextSt>
      </ButtonSt>
    </ArticleButtonContainerSt>
  );
};

ArticleButtonCmp.propTypes = {
  buttonSlug: PropTypes.string.isRequired,
  buttonText: PropTypes.string.isRequired,
  isOpeningInNewTab: PropTypes.bool,
};

ArticleButtonCmp.defaultProps = {
  isOpeningInNewTab: false,
};

export const ArticleButton = memo(ArticleButtonCmp);

import { HP_STAGE_BANNER } from './_hp-stage-banner';
import { HP_DEALS_AND_SPECIALS_TILES } from './_hp-deals-and-specials-tiles';
import { CLP_BANNER } from './_clp-banner';
import { SPECIAL_OFFERS_PAGE_TILES } from './_special-offers-page-tiles';
import { PDP_TOP_BANNER } from './_pdp-top-banner';
import { CLP_BETWEEN_CAR_TILE_BANNER_1 } from './_clp-between-car-tile-banner-1';
import { CLP_BETWEEN_CAR_TILE_BANNER_2 } from './_clp-between-car-tile-banner-2';
import { CLP_FILTER_MEDIUM_RECTANGLE_BANNER } from './_clp-filter-medium-rectangle-banner';
import { CLP_TOP_BANNER } from './_clp-top-banner';
/**
 * add additional add units here
 */
export const GAM_AD_UNITS = <const>{
  HP_STAGE_BANNER,
  HP_DEALS_AND_SPECIALS_TILES,
  CLP_BANNER,
  SPECIAL_OFFERS_PAGE_TILES,
  PDP_TOP_BANNER,
  CLP_BETWEEN_CAR_TILE_BANNER_1,
  CLP_BETWEEN_CAR_TILE_BANNER_2,
  CLP_FILTER_MEDIUM_RECTANGLE_BANNER,
  CLP_TOP_BANNER,
};

import styled from 'styled-components';
import { Text } from 'app/shared/ui';

export const CartileMtlPriceCalculatorLinkSt = styled.button`
  display: inline-block;
  position: relative;
  z-index: 1;
  text-decoration: underline;
  color: ${/* istanbul ignore next */ ({ theme }) => theme.colors.mintDark};
  background: none;
  border: 0;

  cursor: pointer;
  &:hover {
    color: ${
      /* istanbul ignore next */ ({ theme }) => theme.colors.brandPrimary
    };
  }
`;

export const CartileMtlPriceCalculatorLinkTextSt = styled(Text).attrs(
  ({ fontsize }) => ({
    as: 'div',
    fontWeight: 'bold',
    fontSize: fontsize,
    lineHeight: 'normal',
    textDecoration: 'underline',
  }),
)``;

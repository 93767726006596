/* istanbul ignore file */
import styled from 'styled-components';
import { Heading4 } from 'app/shared/ui';

import { zIndex } from 'app/shared/styles_js/variables';

export const SpecialOffersNavigationSt = styled.div`
  margin-top: -8px;
  margin-bottom: 32px;
`;

export const TitleSt = styled(Heading4).attrs(() => ({
  as: 'h2',
  fontSize: [20, 24, 24],
  lineHeight: [1.67, 1.5],
  fontWeight: 700,
  mt: [24, 0, 0, 0],
  mb: [32, 0, 0, 0],
}))`
  color: #303030;

  z-index: ${zIndex.homeBenefitsTitle};
`;

export const LoadingPageWrapperSt = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
`;

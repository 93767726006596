import {
  TSelectOnClick,
  THandleOnClickProps,
  THandleOnMouseLeaveProps,
} from './NavItem.types';

export const handleBackButtonClick = (
  selectOnClick: TSelectOnClick,
  setIsOpen: (isOpen: boolean) => void,
): void => {
  selectOnClick('');
  return setIsOpen(false);
};

export const handleOnClick = ({
  id,
  selectedItem,
  selectOnClick,
  setIsOpen,
  userAction,
}: THandleOnClickProps): void => {
  userAction?.();

  if (selectedItem === id) {
    setIsOpen(false);
    return selectOnClick('');
  }
  return selectOnClick(id);
};

export const handleOnMouseEnter = (
  id: string,
  selectOnClick: TSelectOnClick,
): void => {
  selectOnClick(id);
};

export const handleOnMouseLeave = ({
  id,
  selectedItem,
  selectOnClick,
  setIsOpen,
}: THandleOnMouseLeaveProps): void => {
  if (selectedItem === id) {
    setIsOpen(false);
    return selectOnClick('');
  }
};

const isBackSpace = (key) => key === 'Backspace';

const getIsDeletingAtSeparatorPosition = (separators, value, key) => {
  return (
    separators.find((separator) => separator.position === value.length) &&
    isBackSpace(key)
  );
};

export const getSeparatorPositions = (mask, charsToReplace) => {
  const newSeparators = [];

  mask.split('').forEach((char, index) => {
    if (charsToReplace.includes(char)) {
      newSeparators.push({ character: char, position: index });
    }
  });
  return newSeparators;
};

export const removeFormatting = (value, separators, type) => {
  const charsToRemove = separators.map(({ character }) => character);
  const pattern = new RegExp(`[${charsToRemove.join('')}]`, 'g');
  let newValue = value.replace(pattern, '');
  if (type !== 'text') {
    newValue = newValue.replace(/\D/g, '');
  }

  return newValue;
};

const getSeparatorAtIndex = (index, separators) => {
  return separators.find((separator) => separator.position === index);
};

export const addMaskToValue = (
  separatorsArr,
  value,
  usedSeparators,
  type,
  key,
) => {
  let formattedValue = '';
  let currentValue = value;

  /* since separators are part of the mask, 
  the user should not have to delete them, 
  this deletes the last separator on backspace and the last character */
  if (getIsDeletingAtSeparatorPosition(separatorsArr, currentValue, key)) {
    currentValue = currentValue.slice(0, -1);
  }

  const pureValue = removeFormatting(currentValue, separatorsArr, type);
  let currentValueIndex = 0;
  for (let i = 0; i < pureValue.length + separatorsArr.length; i += 1) {
    const currentSeparator = getSeparatorAtIndex(i, separatorsArr);

    if (currentSeparator) {
      formattedValue += currentSeparator.character;
    } else {
      formattedValue += pureValue.charAt(currentValueIndex);
      currentValueIndex += 1;
    }
  }

  return isBackSpace(key) ? currentValue : formattedValue;
};

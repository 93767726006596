import { isDealerCashVehicle } from 'app/shared/utils/vehicle';

import { getCampaignData } from '../modules/Campaign/utils';

export const getPromotionPills = (campaigns, vehicle) => {
  const isDealerCashEligible = isDealerCashVehicle(vehicle);

  const campaignData = getCampaignData(
    campaigns,
    vehicle?.markers,
    isDealerCashEligible,
  )?.matchingCampaigns;

  const promotionPills = campaignData
    ?.map((campaign, index) => {
      const markers = vehicle?.markers || [];
      const marker =
        Array.isArray(markers) &&
        markers?.find((item) => item?.campaign?.id === campaign?.id);

      const voucherAmount = marker?.campaign?.voucherAmount || '';

      return {
        pillText:
          campaign?.clpPromotionPillText?.replace(
            /{VOUCHER_AMOUNT}/g,
            voucherAmount,
          ) || /* istanbul ignore next */ '',
        id: index,
        campaignId: campaign?.id,
      };
    })
    .filter((c) => c?.pillText);
  return promotionPills;
};

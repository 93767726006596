/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */

import {
  ACCIDENT_INFO_GET,
  ACCIDENT_INFO_GET_SUCCESS,
  ACCIDENT_INFO_GET_ERROR,
} from '../../constants';

export const initialState = {
  isLoading: false,
  accidentInfo: undefined,
  make: undefined,
  model: undefined,
  error: undefined,
};

export function accidentInfo(
  state = initialState,
  action: { type: string; payload: any },
) {
  const { type, payload } = action;

  switch (type) {
    case ACCIDENT_INFO_GET:
      return { ...state, error: undefined, isLoading: true };
    case ACCIDENT_INFO_GET_SUCCESS:
      return {
        ...state,
        accidentInfo: payload?.content[0]?.accidentInfo ?? '',
        make: payload?.content[0]?.make?.displayName ?? '',
        model: payload?.content[0]?.model?.displayName ?? '',
        isLoading: false,
      };
    case ACCIDENT_INFO_GET_ERROR:
      return { ...state, error: payload, isLoading: false };
    default:
      return state;
  }
}

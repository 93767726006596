/* stylelint-disable max-nesting-depth */
import styled, { css } from 'styled-components';
import { Box, Flex } from '@rebass/grid';

import { media } from 'app/shared/styles_js/variables';

export const TabSt = styled(Flex).attrs(
  /* istanbul ignore next */ ({
    defaultBgColor,
    defaultFontColor,
    ...rest
  }) => ({
    bg: defaultBgColor,
    color: defaultFontColor,
    px: 16,
    py: 8,
    as: 'a',
    justifyContent: 'center',
    ...rest,
  }),
)`
  min-width: 40vw;
  cursor: pointer;
  text-align: center;
  transition: all 0.08s;
  position: relative;

  &:after {
    content: '';
    position: absolute;
    right: 0;
    left: 0;
    bottom: 0;
    width: 100%;
    box-shadow: -1px 1px 4px 1px rgba(0, 0, 0, 0.1);
  }

  ${media.md(css`
    min-width: 120px;
  `)}

  &:active,
  &:hover,
  &:focus {
    outline: 0;
    color: ${
      /* istanbul ignore next */ ({ activeFontColor }) =>
        activeFontColor && activeFontColor
    };
    ${({ active, activeFontColor }) =>
      active &&
      css`
        color: ${activeFontColor};
      `}
  }

  ${({ active, activeFontColor, activeBgColor }) =>
    active &&
    css`
      position: relative;
      background-color: ${activeBgColor};
      color: ${activeFontColor};
      border-right: none;
      z-index: 1;
      box-shadow: inset 0px 9px 20px -8px rgba(0, 0, 0, 0.15) !important;
      font-weight: bold;
      &:after {
        display: none;
      }
    `}
`;

export const TabbedContentTabContainerSt = styled(Box).attrs(() => ({
  as: 'li',
  flex: '1',
  width: 1,
  role: 'presentation',
}))`
  position: relative;
  &:after {
    content: '';
    width: 0;
    height: 54px;
    position: absolute;
    border-right: 1px solid #f5f5f5;
    top: 10px;
    right: 0;
    bottom: 10px;
  }
`;

export const TabsWrapperSt = styled(Flex).attrs(() => ({
  as: 'ul',
  role: 'tablist',
}))`
  width: 100%;
  height: 100%;
  align-items: flex-end;
  padding-left: 4px;
  margin: 0 0 0 -4px;
  overflow-x: visible;
  overflow-y: hidden;
  list-style: none;

  ${media.md(css`
    margin: 0;
    padding-right: 4px;
  `)}
  ${TabbedContentTabContainerSt} {
    &:last-child {
      &:after {
        display: none;
      }
    }
  }
`;

import styled from 'styled-components';

import { color } from 'app/shared/styles_js/variables';

export const ResponsiveImageSt = styled.div`
  height: 100%;
`;

export const LoaderWrapper = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  background-color: ${color.graySubtle};
`;

import { memo } from 'react';
import PropTypes from 'prop-types';

import { CartileContentHeaderContainerSt } from 'app/shared/modules/Cartile/CartileTemplates/CartileContentContainer/CartileContentHeader/CartileContentHeader.css';

const CartileContentHeaderCmp = ({ children, className }) => (
  <CartileContentHeaderContainerSt
    color="inherit"
    fontWeight="regular"
    textDecoration="none"
    className={className}
  >
    {children}
  </CartileContentHeaderContainerSt>
);

CartileContentHeaderCmp.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string.isRequired,
};

export const CartileContentHeader = memo(CartileContentHeaderCmp);

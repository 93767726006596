import { useMemo, useCallback } from 'react';

import { isDealershipOpen } from 'app/marketplace/contactDealer/Call/utils';
import { useRecaptcha } from 'app/shared/modules/recaptcha';
import { createGUID, isMobileViewport, noop } from 'app/shared/utils/utils';
import {
  defineMenuAction,
  triggerTrackAction,
} from 'app/marketplace/vehicle/utils';

/**
 * Hook to use defineMenuAction util to provide lead flow action types handlers
 *
 * @typedef {Object} returnObject
 * @property {function} handleEmailClick - email lead
 * @property {function=} handlePhoneClick - call lead
 * @property {boolean=} isScriptLoading
 *
 /**
 * @typedef {import('app/types/style.types').TBreakpoint} TBreakpoint
 * @typedef {import('app/types/entities/Vehicle.types').IVehicle} IVehicle
 * @typedef {import('app/types/entities/Vehicle.types').IVehicleMarker} IVehicleMarker
 * @typedef {import('app/shared/modules/Campaign/Campaign.types').TCampaignTrackingArgs} TCampaignTrackingArgs
 */

/**
 * @param {string} params.target
 * @param {IVehicle} params.vehicle
 * @param {TBreakpoint} params.breakpoint
 * @param {function} params.openOverlay
 * @param {function} params.closeOverlay
 * @param {function} params.initiateCall
 * @param {TCampaignData} params.campaignData [LEAD] Campaign data
 * @param {function} params.signupForNewsletterWithConsent
 * @param {boolean=} params.shouldShowCallbackFlow
 * @param {boolean=} params.shouldShowCampaignInfoModal
 */

/**
 * @returns {returnObject}
 */
export const useDefineMenuAction = ({
  target,
  vehicle,
  location,
  breakpoint,
  openOverlay,
  closeOverlay,
  initiateCall = noop,
  campaignData = {},
  signupForNewsletterWithConsent,
  shouldShowCallbackFlow = false, // For testing purposes only
  shouldShowCampaignInfoModal = false,
  screenType = 'campaign_info', // form or campaign_info
}) => {
  // eslint-disable-next-line no-unused-vars
  const [isScriptLoading, _, loadScript] = useRecaptcha(false);

  const callDealerEventId = useMemo(createGUID, [vehicle]);

  const handleMenuAction = useCallback(
    (action, autoTriggered = false, additionalContentProps) => {
      defineMenuAction({
        target,
        action,
        vehicle,
        location,
        openOverlay,
        closeOverlay,
        autoTriggered,
        handleMenuAction,
        callDealerEventId,
        shouldCallMLT: true,
        additionalContentProps,
        signupForNewsletterWithConsent,
        campaignData,
        triggerTracking: triggerTrackAction,
        shouldShowCampaignInfoModal,
        screenType,
      });
    },
    [vehicle, campaignData],
  );

  const handleEmailClick = ({
    eventLabel,
    oneClickLeads,
    isFlag248OneClickLeadsShow,
    initExpandedCampaignInfoContentIndex,
    visibleCampaign,
    activeStep,
    paymentType,
    isPhoneLeadFLow,
  } = {}) => {
    handleMenuAction('mail', false, {
      eventLabel,
      oneClickLeads,
      isFlag248OneClickLeadsShow,
      initExpandedCampaignInfoContentIndex,
      visibleCampaign,
      activeStep,
      paymentType,
      isPhoneLeadFLow,
    });
    return 'handleMenuAction'; // For testing purposes only
  };

  const handlePhoneClick = useCallback(
    async (source = '') => {
      const isOpen = isDealershipOpen(vehicle?.dealer?.openingTimes);
      const showCallbackFlow = shouldShowCallbackFlow || !isOpen;

      // callback is the same for desktop/mobile
      if (showCallbackFlow) {
        handleMenuAction('callback', false, {
          showBackButton: isOpen,
          eventLabel: '',
        });
      } else {
        await loadScript();
        // This func handles initiating a call OR opening the call flow
        initiateCall({
          target,
          vehicle,
          callDealerEventId,
          openSidebar: handleMenuAction,
          callThroughSidebar: !isMobileViewport(breakpoint),
          eventLabel: '',
          source,
        });
      }
      return 'handleMenuAction'; // For testing purposes only
    },
    [target, vehicle, callDealerEventId, breakpoint],
  );

  return {
    handleEmailClick,
    handlePhoneClick,
    isScriptLoading,
  };
};

import { BASE_URL } from 'app/config';
import { apiCall } from 'app/actions';
import { getFilterObject } from 'app/marketplace/filter/utils';
import { breakpoints, grid } from 'app/shared/styles_js/variables';
import { getContentfulFilePath } from 'app/shared/utils/utils';
import { columnCount } from 'app/shared/ui/components/variables/spacings';

/**
 * Generate function to get entries
 * @param {Object} queryObj
 * @param {string} space
 * @param {string} base
 * @param {boolean} draft
 * @param {boolean} isStaging The Contentful environment
 * @param {Function} call For testing purposes
 * @returns {function(...[*]=)}
 */

export function getEntries(
  queryObj,
  space,
  base,
  draft = false,
  isStaging = false,
  call = apiCall,
) {
  const staging = isStaging ? `&staging=${isStaging}` : '';
  let url = `/fake-cms-content/${space}?draft=${draft}${staging}`;
  const apiCallOption = {
    method: 'POST',
    body: JSON.stringify(queryObj),
  };

  if (base) url = `${base}${url}`;

  return call(url, apiCallOption, false);
}

export const getEntryFields = (entry) => {
  if (Array.isArray(entry) && entry.length > 0) {
    return getEntryFields(entry[0]);
  }

  return entry && entry.fields;
};

export const getEntryID = (entry) => {
  if (Array.isArray(entry) && entry.length > 0) {
    return getEntryID(entry[0]);
  }

  return entry && entry.sys && entry.sys.id;
};

export const getFileUrl = (entry) =>
  entry && entry.fields && entry.fields.file && entry.fields.file.url;

export const getFileTitle = (entry) =>
  entry && entry.fields && entry.fields.file && entry.fields.file.title;

export const getImageUrlFromEntry = (entry) => entry?.fields?.file?.url;

export const getImageUrl = (image) => {
  return image
    ? `https://${image.substring(2)}`
    : `${BASE_URL}/assets/magazine_article_fallback.png`;
};

// utils function to cut text after n numbers of character
export const getTextWithEllipsis = (text, char) => {
  return text.length > char ? `${text.slice(0, char - 1)}...` : text;
};

export const getFilterObjFromUrl = (url, baseUrl = BASE_URL) => {
  if (!url) return;
  let filterObj;
  if (url.indexOf('?') !== -1) {
    const [base, params] = url.replace(`${baseUrl}/`, '').split('?');
    filterObj = params.split('&').reduce(
      (memo, param) => ({
        ...memo,
        [param.split('=')[0]]: param.split('=')[1],
      }),
      {},
    );
    filterObj.model =
      filterObj.model && filterObj.model.indexOf('_') !== -1
        ? filterObj.model.substring(0, filterObj.model.indexOf('_'))
        : filterObj.model;
    const cleanBase = base[0] === '/' ? base.substring(1) : base;
    const [, make, model] = cleanBase.split('/');
    filterObj.make = make || filterObj.make;
    /* istanbul ignore else */
    if (/* istanbul ignore next */ filterObj.model || model) {
      filterObj.model =
        model || filterObj.model.indexOf(',') !== -1
          ? filterObj.model.split(',')
          : filterObj.model;
    }
  } else {
    const base = url.replace(`${baseUrl}/`, '');
    const [, make, model] = base.split('/');
    filterObj = { make, model };
  }
  Object.keys(filterObj).forEach((key) =>
    filterObj[key] === undefined
      ? /* istanbul ignore next */ delete filterObj[key]
      : '',
  );
  const query = [];

  Object.keys(filterObj).forEach((key) => {
    if (Array.isArray(filterObj[key])) {
      filterObj[key].forEach((item) =>
        query.push(
          getFilterObject({
            category: key,
            value: item,
          }),
        ),
      );
    } else {
      query.push(
        getFilterObject({
          category: key,
          value: filterObj[key],
        }),
      );
    }
  });
  // eslint-disable-next-line consistent-return
  return query;
};

/**
 * This function takes in the URL slug and return the last route name after the '/'.
 * @param {string} item is the URL slug
 * @return {string} the last route name.
 * input -> /Bremen/Bremen
 * output -> Bremen
 */
export const extractLastRouteFromSlug = (item) =>
  item.length > 0 ? item.substr(item.lastIndexOf('/') + 1, item.length) : item;

export const getWidth = (breakpoint) =>
  `width: ${breakpoints[breakpoint] - grid.margin[breakpoint] * 2}px;`;

/**
 * Get a array of widths from a string; used to convert Contentful responses
 * into properly formatted width props for Box comps from rebass-grid.
 * @param {String} stringWidth The width string to be converted to array
 * @returns {Object} The converted width key with an array of numbers
 */
export const getWidthArrayFromString = (stringWidth) => {
  const list = stringWidth.split(',');
  const width = list.map((item) => {
    const strings = item.split('/');
    const numbers = strings.map((str) => Math.floor(str));
    return numbers.length === 1 ? numbers[0] : numbers[0] / numbers[1];
  });

  return {
    width,
  };
};

/**
 * Get a array of paddings from a string; used to convert Contentful responses
 * into properly formatted width props for Box comps from rebass-grid.
 * @param {String} stringPadding The paddings string to be converted to array
 * @returns {Object} The converted p key with an array of paddings
 */
export const getPaddingArrayFromString = (stringPadding) => {
  const list = stringPadding.split(',');
  const p = list.map((item) => `${item}px`);
  return {
    p,
  };
};

export const getWidthUsingColumns = (columns, breakpoint) => {
  const { column, margin } = columnCount[breakpoint];
  const total = column + margin;
  const width =
    columns === 12 && breakpoint === 'sm'
      ? 'calc(100% - 48px)'
      : `${columns * total - margin}px`;
  return `width: ${width};`;
};

/**
 * @typedef {Object} PropsFromPage
 * @property {Object} contentfulLoading
 * @property {Object|null} entry
 */

/**
 * Generate some props from a Page Contentful entry
 * @param {Object} state
 * @param {string} path The page's URL slug; represented by the path field on Contentful entry model
 * @returns {PropsFromPage}
 */
export const getPropsFromPage = (state, path) => {
  const { contentful } = state;
  const { contentfulLoading } = contentful;
  let entry = null;

  if (contentful[path] && contentful[path].length > 0) {
    // eslint-disable-next-line prefer-destructuring
    entry = contentful[path][0];
  } else if (contentful[path] && contentful[path].error) {
    entry = contentful[path];
  }

  return { contentfulLoading, entry };
};

/**
 * Get page name from Contentful entry
 * @param entry
 * @returns {string}
 */
export const getPageName = (entry) => {
  const localizedProps = entry && entry.fields;
  const { path } = localizedProps || { path: '404' };
  return path && path.replace(/\W/g, '');
};

/**
 * Define srcSet attribute content based on Contentful objects representing image entries
 * @param {Object} images
 * @returns {*}
 */
export const setSrcSet = (images) =>
  images.reduce((accumulator, source, index) => {
    if (index !== 0)
      return `${accumulator || ''}${
        accumulator ? ', ' : ''
      }${getContentfulFilePath(source)} ${index + 1}x`;
    return accumulator;
  }, null);

/**
 * Get content from Contentful based on content-type
 * @param {Object} entry
 * @param {string} contentType
 * @returns {*}
 */
export const getContent = (entry, contentType) => {
  return entry?.find((item) => item?.sys?.contentType?.sys?.id === contentType);
};

/**
 * Get contents from Contentful based on content-type
 * @param {Object} entry
 * @param {string} contentType
 * @returns {*}
 */
export const getContents = (entry, contentType) => {
  return entry?.filter(
    (item) => item?.sys?.contentType?.sys?.id === contentType,
  );
};

/**
 * Get content from Contentful based on content-type and markerId
 * @param {Object} entry
 * @param {string} contentType
 * @param {string | undefined} markerId
 * @returns {*}
 */
export const getLeadFlowBannerContent = (entry, contentType, markerId) => {
  return entry?.find(
    (item) =>
      item?.sys?.contentType?.sys?.id === contentType &&
      item?.fields?.title === markerId,
  );
};

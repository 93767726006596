import { Route } from 'react-router';

import { CampaignPrintableRoutes } from 'app/shared/modules/Campaign/CampaignCode/PrintableVoucherCode/routes';
import { AutoBildRoutes } from 'app/external/autobild/routes';
import { HomeRoutes } from 'app/marketplace/home/routes';
import { SearchRoutes } from 'app/marketplace/search/routes';
import { FavouritesRoutes } from 'app/marketplace/favourites/routes';
import { VehicleRoutes } from 'app/marketplace/vehicle/routes';
import { ElectricCarRoutes } from 'app/marketplace/electricCar/routes';
import { ContentfulRoutes } from 'app/marketplace/contentful/routes';
import { CookieDashboardRoutes } from 'app/marketplace/cookieDashboard/routes';
import { LoginRoutes } from 'app/marketplace/accounts/routes';
import { PremiumRoutes } from 'app/marketplace/premium/routes';
import { mobileFilterPageRoutes } from 'app/marketplace/mobileFilterPage/routes';
import { DownTimeRoutes } from 'app/shared/modules/downtime/routes';
import { TradeInRoutes } from 'app/shared/modules/FDL/tradein/routes';
import { DealerProfileRoutes } from 'app/shared/modules/dealerProfile/routes';
import { CheckoutRoutes } from 'app/marketplace/vehicleCheckout/routes';
import { NativeRoutes } from 'app/external/native/routes';

import { ConnectedAppWithErrorBoundary } from './App';
import { DealerRoutes } from './marketplace/Dealer/routes';

export const getRoutes = () => (
  <Route path="/" component={ConnectedAppWithErrorBoundary}>
    {HomeRoutes}
    {SearchRoutes}
    {FavouritesRoutes}
    {VehicleRoutes}
    {ElectricCarRoutes}
    {DealerRoutes}
    {DownTimeRoutes}
    {CookieDashboardRoutes}
    {LoginRoutes}
    {PremiumRoutes}
    {mobileFilterPageRoutes}
    {AutoBildRoutes}
    {CampaignPrintableRoutes}
    {TradeInRoutes}
    {DealerProfileRoutes}
    {CheckoutRoutes}
    {NativeRoutes}
    {ContentfulRoutes}
  </Route>
);

/* Contentful has to be the last route, since it matches the all the other routes */

import { IColorsList } from './index.types';

export const color: Readonly<Partial<IColorsList>> = {
  grayBattleShip: '#656f7c',
  grayButton: '#f2f4f7',
  grayDarker: '#73767a',
  grayDark: '#989ca4',
  gray: '#c7cbd0',
  grayLight: '#d9dbde',
  grayLighter: '#ececec',
  grayLightest: '#f5f7f8',
  graySuperLight: '#f5f8f9',
  grayTable: '#d9dbdf',
  graySubtle: '#c7cbd1',
  mint: '#cdf1ec',
  black: '#000',
  white: '#fff',
  transparent: 'transparent',

  brandPrimaryDark: '#00b994',
  brandPrimary: '#00cea5',
  brandPrimaryLight: '#00d9ae',
  brandPrimaryLighter: '#83d3c3',
  brandPrimaryLightest: '#ccf5ed',
  brandPrimarySuperLight: '#99F0DF',
  brandSecondary: '#002937',
  brandSecondaryLight: '#003648',
  brandSecondaryLighter: '#003e52',
  brandSecondarySuperLight: '#99B2BA',
  brandHighlighted: '#fbd289',
  brandSuccess: 'red',
  brandInfo: 'red',
  brandWarning: '#f37951',
  brandDanger: '#ff267a',
  brandDangerAlt: '#ff579d',
};

export const colorRgb: Readonly<Partial<IColorsList>> = {
  grayBattleShip: 'rgb(101, 111, 124)',
  grayButton: 'rgb(242, 244, 247)',
  grayDarker: 'rgb(115, 118, 122)',
  grayDark: 'rgb(152, 156, 164)',
  gray: 'rgb(199, 203, 208)',
  grayLight: 'rgb(217, 219, 222)',
  grayLighter: 'rgb(236, 236, 236)',
  grayLightest: 'rgb(245, 247, 248)',
  graySuperLight: 'rgb(245, 248, 249)',
  grayTable: 'rgb(217, 219, 223)',
  graySubtle: 'rgb(199, 203, 209)',
  mint: 'rgb(205, 241, 236)',
  black: 'rgb(0, 0, 0)',
  white: 'rgb(255, 255, 255)',
  transparent: 'rgb(0, 0, 0)',

  brandPrimaryDark: 'rgb(0, 185, 148)',
  brandPrimary: 'rgb(0, 206, 165)',
  brandPrimaryLight: 'rgb(0, 217, 174)',
  brandPrimaryLighter: 'rgb(131, 211, 195)',
  brandPrimaryLightest: 'rgb(204, 245, 237)',
  brandPrimarySuperLight: 'rgb(153, 240, 223)',
  brandSecondary: 'rgb(0, 41, 55)',
  brandSecondaryLight: 'rgb(0, 54, 72)',
  brandSecondaryLighter: 'rgb(0, 62, 82)',
  brandSecondarySuperLight: 'rgb(153, 178, 186)',
  brandHighlighted: 'rgb(251, 210, 137)',
  brandSuccess: 'rgb(0, 0, 237)',
  brandInfo: 'rgb(0, 0, 237)',
  brandWarning: 'rgb(243, 121, 81)',
  brandDanger: 'rgb(255, 38, 122)',
  brandDangerAlt: 'rgb(255, 87, 157)',
};

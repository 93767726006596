const messages = {
  defaultMailMessage: `Guten Tag,
ich interessiere mich für dieses Fahrzeug. Bitte kontaktieren Sie mich.`,

  mailMessageFinancing: `Guten Tag,
ich interessiere mich für dieses Fahrzeug und würde mich über ein Finanzierungsangebot freuen. Bitte kontaktieren Sie mich.`,

  defaultVideoMessage: `Guten Tag,
ich interessiere mich für dieses Fahrzeug. Bitte senden Sie mir ein detailliertes Video des Fahrzeugs.`,

  videoMessageFinancing: `Guten Tag,
ich interessiere mich für dieses Fahrzeug. Bitte senden Sie mir ein detailliertes Video des Fahrzeugs.
Darüber hinaus würde ich mich über ein Finanzierungsangebot freuen.`,

  videoMessageFinancingDetails: (
    financingData,
  ) => `Sehr geehrte Damen und Herren,
ich interessiere mich für dieses Fahrzeug. Bitte senden Sie mir ein detailliertes Video des Fahrzeugs. ${financingData}

Mit freundlichen Grüßen`,
};

export default messages;

/* eslint-disable quotes */
// eslint-disable-next-line import/no-extraneous-dependencies
import { Options } from '@sentry/types';
// This configuration file is technology agnostic
// (shared config between browser and server Sentry initialization config)
// please import only types.
// For browser-only changes: see (src/client/initiateSentrySDK)
// For server-only changes: see (src/server/instrumentation)

import { BASE_URL, SENTRY_URL, SENTRY_VERSION } from 'app/config';

const isProduction = BASE_URL === 'https://hey.car';
const sentryRate = isProduction ? 0.5 : 0.25;

/*
 * These are known events on the web-app's Sentry:
 */
const ignoreErrors = [
  "Unable to get property 'getRandomValues' of undefined or null reference",
  // WEB-APP-SVH: https://sentry.io/organizations/default/issues/2131065707
  'Not enough arguments',
  "Object doesn't support this action",
  'Blocked a frame with origin',
  "null is not a constructor (evaluating 'ne(e)')",
  'Error: Entry not found for',
  // WEB-APP-1F40: https://sentry.io/organizations/default/issues/2150166217
  "ne is not a function. (In 'ne(e)', 'ne' is null)",
  'Index or size is negative or greater than the allowed amount',
  'Blocked a frame with origin "https://hey.car" from accessing a frame with origin "https://vars.hotjar.com"',
  'window.matchMedia(...) is null',
  // WEB-APP-JJC: https://sentry.io/organizations/default/issues/2089447507
  'window.getComputedStyle(...) is null',
  // react-slick open issue with react 17
  // https://github.com/akiran/react-slick/issues/1650
  'Unable to preventDefault inside passive event listener invocation',
  // https://sentry.io/organizations/default/issues/2541439361
  "Identifier 'change_ua' has already been declared",
  // https://sentry.io/organizations/default/issues/2751388184
  'No error message',
  // https://sentry.io/organizations/default/issues/2510511480
  'Non-Error exception captured with keys: _reactName, _targetInst, bubbles',
  // https://sentry.io/organizations/default/issues/2509548135
  "Cannot read properties of undefined (reading 'getCurrentLocation')",
  "undefined is not an object (evaluating 't.getCurrentLocation')",
  "Cannot read property 'getCurrentLocation' of undefined",
  // https://sentry.io/organizations/default/issues/2430878032
  "undefined is not an object (evaluating 'webkit.messageHandlers.contextMenuMessageHandler.postMessage')",
  // https://sentry.io/organizations/default/issues/2431583815
  'NetworkError when attempting to fetch resource.',
  // https://sentry.io/organizations/default/issues/2758439173
  'Failed to fetch',
  // https://sentry.io/organizations/default/issues/2510615312
  'Should not already be working.',
  // https://sentry.io/organizations/default/issues/2207825255
  "Cannot read properties of undefined (reading 'replace')",
  // https://sentry.io/organizations/default/issues/2681527961
  "Cannot read properties of null (reading 'slideHandler')",
  // https://sentry.io/organizations/default/issues/1742744804
  'iom is not defined',
  // https://sentry.io/organizations/default/issues/2430826965
  'Cannot convert undefined or null to object',
  // https://sentry.io/organizations/default/issues/2683971075
  "Failed to execute 'invoke' on 'CreateHTMLCallback': The provided callback is no longer runnable.",
  // https://sentry.io/organizations/default/issues/2200939857
  "Cannot read properties of undefined (reading 'toLowerCase')",
  // https://sentry.io/organizations/default/issues/2449036395
  "SecurityError: Failed to execute 'pushState' on 'History': A history state object with URL 'https://hey.car/cookie-richtlinie' cannot be created in a document with origin 'https://hey-car.translate.goog' and URL 'https://hey-car.translate.goog/vehicle...",
  // https://sentry.io/organizations/default/issues/1938548208
  "undefined is not an object (evaluating 'window.webkit.messageHandlers')",
  // https://sentry.io/organizations/default/issues/2216258876
  "Can't find variable: instantSearchSDKJSBridgeClearHighlight",
  // https://sentry.io/organizations/default/issues/2210605625
  'can\'t redefine non-configurable property "userAgent"',
  // https://sentry.io/organizations/default/issues/2590188705
  'DataError: Failed to get the interest cohort: either it is unavailable, or preferences or content settings have denied access.',
];

// https://docs.sentry.io/clients/javascript/tips/
const denyUrls = [
  // Google Adsense
  /pagead\/js/i,
  // Facebook flakiness
  /graph\.facebook\.com/i,
  // Facebook blocked
  /connect\.facebook\.net\/en_US\/all\.js/i,
  // Woopra flakiness
  /eatdifferent\.com\.woopra-ns\.com/i,
  /static\.woopra\.com\/js\/woopra\.js/i,
  // Chrome extensions
  /extensions\//i,
  /^chrome:\/\//i,
  // Other plugins
  /127\.0\.0\.1:4001\/isrunning/i, // Cacaoweb
  /webappstoolbarba\.texthelp\.com\//i,
  /metrics\.itunes\.apple\.com\.edgesuite\.net\//i,
];

const sentryUrl = SENTRY_URL as string;
const enabled = sentryUrl.length > 0;

export const sentryOptions: { isProduction: boolean; options: Options } = {
  options: {
    enabled,
    // Client's Sentry DSN
    dsn: sentryUrl,
    environment: isProduction ? 'production' : 'staging',
    // Generated from commit hash on ci; check .circleci/config.yml
    release: SENTRY_VERSION as string,
    // An array of strings or regexps that'll be used to ignore specific errors based on their type/message
    ignoreErrors,
    denyUrls,
    // The fraction of random events that should be sent to Sentry
    sampleRate: sentryRate,
  } as Options,
  isProduction,
};

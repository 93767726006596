/* eslint-disable no-unused-expressions */
/* istanbul ignore file */
import { IVehicle } from 'app/types/entities/Vehicle.types';
import {
  ICampaign,
  ICampaignDataForMultipleOffers,
  ICampaignFields,
} from 'app/shared/modules/Campaign/Campaign.types';
import { getCampaignData } from 'app/shared/modules/Campaign/utils';

import { TPill } from './getMultipleOfferPillsForPDP.types';

export const getMatchingCampaignsMultiOffers = (
  matchingCampaigns: ICampaignFields[],
): ICampaignDataForMultipleOffers | undefined => {
  const campaignEntry = matchingCampaigns?.find(
    (campaign) => campaign?.campaignDataForMultipleOffers,
  );
  return campaignEntry?.campaignDataForMultipleOffers;
};

export const getCampaignMultiOffers = (
  vehicle: IVehicle,
  campaigns: Array<ICampaign> = [],
): ICampaignDataForMultipleOffers | undefined => {
  const { matchingCampaigns } = getCampaignData(
    campaigns,
    vehicle?.markers,
  ) as { matchingCampaigns: Array<ICampaignFields> };

  return getMatchingCampaignsMultiOffers(matchingCampaigns);
};

export const getMultipleOfferPills = (
  campaigns: Array<ICampaign>,
  vehicle: IVehicle,
): Array<TPill> | null => {
  const campaignDataForMultipleOffers = getCampaignMultiOffers(
    vehicle,
    campaigns,
  );

  if (!campaignDataForMultipleOffers) {
    return null;
  }

  const productOfferings = vehicle?.productOffering?.eligibleFor;
  const fields = campaignDataForMultipleOffers?.fields;

  const allPills = {
    classified: fields?.offerPills[0]?.fields.content.split('\n'),
    DE_DEALER_CASH: fields?.offerPills[1]?.fields.content.split('\n'),
    DE_DEALER_FINANCING_AC: fields?.offerPills[2]?.fields.content.split('\n'),
    DE_DEALER_LEASING: fields?.offerPills[3]?.fields.content.split('\n'),
  };

  // Creating a new object to avoid duplicate pills
  const pillCollection = {} as Record<string, string>;

  // Add classified pills
  allPills.classified?.forEach((pill) => {
    pillCollection[pill] = pill;
  });

  // Add commerce pills
  productOfferings?.forEach((productOffering) => {
    allPills[productOffering]?.forEach((pill) => {
      pillCollection[pill] = pill;
    });
  });

  const pills = Object.keys(pillCollection)
    .map((pill, index) => ({
      pillText: pill,
      id: index,
    }))
    .filter((n) => n.pillText);

  return pills;
};

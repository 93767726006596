import { PureComponent } from 'react';
import PropTypes from 'prop-types';

import { PopoverSt } from './DefaultPopoverRenderer.css';

const KEY_UP = 38;
const KEY_DOWN = 40;
const KEY_ENTER = 13;

export class DefaultPopoverRenderer extends PureComponent {
  constructor(props) {
    super(props);

    this.popoverReference = null;
    this.onNavigate = this.onNavigate.bind(this);
  }

  componentDidMount() {
    document.addEventListener('keydown', this.onNavigate);
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.onNavigate);
  }

  onNavigate(event) {
    const { onSelect, onFocus, focused, items, show } = this.props;
    let index = focused;

    if (!show) {
      return;
    }

    if (event.keyCode === KEY_DOWN) {
      event.preventDefault();
      index += 1;
      if (items[index]) {
        onFocus(index);
        this.popoverReference.scrollTop = index * 44;
      }
    } else if (event.keyCode === KEY_UP) {
      event.preventDefault();
      index -= 1;
      if (items[index]) {
        onFocus(index);
        this.popoverReference.scrollTop = index * 44;
      }
    } else if (event.keyCode === KEY_ENTER) {
      event.preventDefault();
      if (items[index]) {
        onSelect(items[focused], focused, event);
      }
    }
  }

  render() {
    const {
      show,
      items,
      itemRenderer,
      onSelect,
      inputValueExtractor,
      focused
    } = this.props;
    const Items = itemRenderer;

    return (
      <PopoverSt
        show={show && items.length > 0}
        ref={e => {
          this.popoverReference = e;
        }}
      >
        <Items
          itemObjects={items}
          onSelect={onSelect}
          inputValueExtractor={inputValueExtractor}
          focused={focused}
        />
      </PopoverSt>
    );
  }
}

DefaultPopoverRenderer.propTypes = {
  show: PropTypes.bool,
  items: PropTypes.arrayOf(PropTypes.object),
  itemRenderer: PropTypes.func.isRequired,
  onSelect: PropTypes.func.isRequired,
  onFocus: PropTypes.func.isRequired,
  focused: PropTypes.number,
  inputValueExtractor: PropTypes.func.isRequired
};

DefaultPopoverRenderer.defaultProps = {
  items: []
};

export const getToken = state => state.authMarketplace.token;
export const getRefreshToken = state => {
  const token = getToken(state);
  return token && token.refresh_token;
};
export const getAccessToken = state => {
  const token = getToken(state);
  return token && token.access_token;
};
export const getTimeOfTokenCreation = state => {
  const token = getToken(state);
  return token && token.createdAt;
};
export const getTokenExpiresIn = state => {
  const token = getToken(state);
  return token && token.expires_in;
};

export const getUserInfo = state => state.authMarketplace.user;
export const getUserEmail = state => {
  const userInfo = getUserInfo(state);
  return userInfo && userInfo.email;
};

export const getProfile = state => state.authMarketplace.profile;

export const getAuthError = state => state.authMarketplace.error;

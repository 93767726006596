import { CONTENTFUL_HEYCAR } from 'app/config';
import { getEntries } from 'app/marketplace/contentful/utils';

import * as ActionTypes from './constants';

/**
 * Get the CLP default Special Offers Banner
 */
export const getSpecialOffersClpBanner = (isPreviewContentful = false) => {
  return (dispatch) => {
    dispatch({ type: ActionTypes.GET_SPECIALOFFERS_REQUEST });

    const space = CONTENTFUL_HEYCAR;
    const queryObj = {
      content_type: 'SpecialOffersClpBanner',
      // there are multiple clp banners in contentful, we can target a specific one by the category
      'fields.category': 'core',
    };

    return (
      dispatch(getEntries(queryObj, space, null, isPreviewContentful))
        // eslint-disable-next-line promise/always-return
        .then((payload) => {
          dispatch({
            type: ActionTypes.GET_SPECIALOFFERS_CLP_BANNER_SUCCESS,
            payload: payload[0], // There is always only one Banner
          });
        })
        .catch((error) => {
          dispatch({
            type: ActionTypes.GET_SPECIALOFFERS_CLP_BANNER_FAILURE,
            error,
          });
        })
    );
  };
};

/**
 * Get all Special Offers data
 */
export const getSpecialOffers = (isPreviewContentful = false) => {
  return (dispatch) => {
    dispatch({ type: ActionTypes.GET_SPECIALOFFERS_REQUEST });
    const space = CONTENTFUL_HEYCAR;
    // query for all specialOffers, no limits
    const queryObj = {
      content_type: 'specialOffer',
    };

    return (
      dispatch(getEntries(queryObj, space, null, isPreviewContentful))
        // eslint-disable-next-line promise/always-return
        .then((payload) => {
          dispatch({
            type: ActionTypes.GET_SPECIALOFFERS_SUCCESS,
            payload,
          });
        })
        .catch((error) => {
          dispatch({ type: ActionTypes.GET_SPECIALOFFERS_FAILURE, error });
        })
    );
  };
};

export const getSpecialOffersHome = (showDraft = false) => {
  return (dispatch) => {
    dispatch({ type: ActionTypes.GET_SPECIALOFFERSHOME_REQUEST });
    const space = CONTENTFUL_HEYCAR;
    // query for specialOffers visible on homepage
    const queryObj = {
      content_type: 'specialOffersHome',
      include: 3,
    };
    return dispatch(getEntries(queryObj, space, null, showDraft))
      .then((payload) => {
        dispatch({
          type: ActionTypes.GET_SPECIALOFFERSHOME_SUCCESS,
          payload,
        });
      })
      .catch((error) => {
        dispatch({ type: ActionTypes.GET_SPECIALOFFERSHOME_FAILURE, error });
      });
  };
};

/**
 * Get all Special Offers Teaser data
 */
export const getSpecialOfferTeaser = () => {
  return (dispatch) => {
    dispatch({ type: ActionTypes.GET_SPECIALOFFER_TEASER_REQUEST });
    const space = CONTENTFUL_HEYCAR;
    // query for all specialOffers, no limits
    const queryObj = {
      content_type: 'specialOfferTeaser',
    };
    return (
      dispatch(getEntries(queryObj, space))
        // eslint-disable-next-line promise/always-return
        .then((payload) => {
          dispatch({
            type: ActionTypes.GET_SPECIALOFFER_TEASER_SUCCESS,
            payload,
          });
        })
        .catch((error) => {
          dispatch({
            type: ActionTypes.GET_SPECIALOFFER_TEASER_FAILURE,
            error,
          });
        })
    );
  };
};

export const setIsPdpCampaignTopBannerAvailable = (
  isPdpCampaignTopBannerAvailable,
) => ({
  type: ActionTypes.SET_IS_PDP_CAMPAIGN_TOP_BANNER_AVAILABLE,
  payload: { isPdpCampaignTopBannerAvailable },
});

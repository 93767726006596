import PropTypes from 'prop-types';

import { Button, Text1 } from 'app/shared/ui';

import {
  ButtonContainerSt,
  ContentContainerSt,
  MessageBannerSt,
  MessagerContainerSt,
} from './MessageBanner.css';

const message = {
  watchPriceSubscribe: 'Preisbenachrichtigungen erfolgreich aktiviert.',
  watchPriceUnsubscribe: 'Preisbenachrichtigungen erfolgreich deaktiviert.',
  saveSearch: 'Sie haben sich erfolgreich abgemeldet.',
};

export const MessageBanner = ({ onDismiss, bannerType }) => {
  return (
    <MessageBannerSt>
      <ContentContainerSt>
        <MessagerContainerSt>{message[bannerType]}</MessagerContainerSt>
        {bannerType === 'watchPriceSubscribe' && (
          <Text1 fontSize={16}>
            Du wirst nun automatisch bei Preissenkungen deiner Favoriten
            benachrichtigt. So hast du die besten Angebote stets im Blick.
          </Text1>
        )}
        <ButtonContainerSt>
          <Button onClick={onDismiss}>Schließen</Button>
        </ButtonContainerSt>
      </ContentContainerSt>
    </MessageBannerSt>
  );
};

MessageBanner.propTypes = {
  onDismiss: PropTypes.func.isRequired,
  bannerType: PropTypes.string.isRequired,
};

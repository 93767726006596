import SpecialOffersNavigation from 'app/marketplace/specialOffers/SpecialOffersNavigation/SpecialOffersNavigation';
import { IAngebote } from 'app/marketplace/shared/NewMarketplaceTopNavBar/MenuItems/listItems/Angebote/Angebote.types';
import { footerContent } from 'app/marketplace/shared/NewMarketplaceTopNavBar/NewMarketplaceTopNavBar.constants';

export const Angebote = ({
  breakpoint = 'sm',
  isFlag256ShowNewPromotionTiles,
  isMobileDevice,
}: IAngebote): any => {
  const angebote = {
    label: 'Deals',
    itemId: 'deals',
    footercontent: footerContent(isMobileDevice, true),
    ...(isFlag256ShowNewPromotionTiles
      ? {
          useCustomChildren: true,
          customChildren: <SpecialOffersNavigation breakpoint={breakpoint} />,
        }
      : {
          url: '/angebote',
          useRouter: true,
        }),
  };
  return angebote;
};

import { Component } from 'react';
import PropTypes from 'prop-types';

import { MakeLogo } from 'app/shared/components/MakeLogo/MakeLogo';

import { ItemSt } from './SuggestionRenderer.css';

export class SuggestionRenderer extends Component {
  constructor(props) {
    super(props);

    // used to differentiate between scrolling and touching
    this.isClick = null;
    this.onClick = this.onClick.bind(this);
  }

  onClick(e, index) {
    const { onSelect, itemObjects } = this.props;

    onSelect(itemObjects[index], index, e);
  }

  render() {
    const { itemObjects, inputValueExtractor, focused } = this.props;

    return itemObjects.map((itemObject, index) => {
      const inputValue = inputValueExtractor(itemObject);

      return (
        <ItemSt
          key={index}
          isFocused={index === focused}
          // register onMouseDown/onTouchStart, because they fire before onBlur of the parent element
          onMouseDown={e => this.onClick(e, index)}
          onTouchStart={() => {
            this.isClick = true;
          }}
          onTouchMove={() => {
            this.isClick = false;
          }}
          onTouchEnd={e => {
            if (this.isClick) {
              this.onClick(e, index);
            }
          }}
        >
          {inputValue}

          {itemObject.value && (
            <MakeLogo
              size={100}
              makeKey={itemObject.value}
              scaleTo={48}
              adjustPosition={false}
            />
          )}
        </ItemSt>
      );
    });
  }
}

SuggestionRenderer.propTypes = {
  itemObjects: PropTypes.arrayOf(PropTypes.object).isRequired,
  onSelect: PropTypes.func.isRequired,
  inputValueExtractor: PropTypes.func.isRequired,
  focused: PropTypes.number,
};

SuggestionRenderer.defaultProps = {
  itemObjects: [],
};

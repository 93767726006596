import styled, { css } from 'styled-components';
import propTypes from 'prop-types';

import { is, grid, isNot, media } from 'app/shared/styles_js/variables';
import { FlexCol } from '../FlexCol';

export const FlexRow = styled.div`
  display: flex;
  flex-flow: column nowrap;
  align-content: stretch;
  align-items: flex-start;
  justify-content: flex-start;

  ${isNot('noMargin')`
    margin-right: ${grid.gutter.sm * -1}px;
    margin-left: ${grid.gutter.sm * -1}px;
  `};

  ${isNot('column')`
    ${media.md(css`
      flex-direction: row;
    `)};
  `};

  ${isNot('noMargin')`
    ${media.md(css`
      margin-right: ${grid.gutter.md * -1}px;
      margin-left: ${grid.gutter.md * -1}px;
    `)};
  `};

  ${isNot('noWidth')`
    > ${FlexCol} {
      width: 100%;

      ${media.md(css`
        ${is('autoWidth')`
          width: auto;
        `};
        ${isNot('noSplit')`
          width: ${props => 100 / props.columnCount}%;
        `};
      `)};
    }
  `};

  &::after {
    clear: both;
  }

  ${isNot('reset')`
    &::before,
    &::after {
      display: table;
      content: ' ';
    }
  `};

  ${is('reset')`
    &::before,
    &::after {
      display: none;
    }
  `};

  ${is('inline')`
    display: inline-flex;
  `};

  ${is('row')`
    flex-direction: row;
  `};

  ${is('rowReverse')`
    flex-direction: row-reverse;
  `};

  ${is('column')`
    flex-direction: column;
  `};

  ${is('columnReverse')`
    flex-direction: column-reverse;
  `};

  ${is('nowrap')`
    flex-wrap: nowrap;
  `};

  ${is('wrap')`
    flex-wrap: wrap;
  `};

  ${is('wrapReverse')`
    flex-wrap: wrap-reverse;
  `};

  ${is('justifyStart')`
    justify-content: flex-start;
  `};

  ${is('justifyEnd')`
    justify-content: flex-end;
  `};

  ${is('justifyCenter')`
    justify-content: center;
  `};

  ${is('justifyBetween')`
    justify-content: space-between;
  `};

  ${is('justifyAround')`
    justify-content: space-around;
  `};

  ${is('contentStart')`
    align-content: flex-start;
  `};

  ${is('contentEnd')`
    align-content: flex-end;
  `};

  ${is('contentCenter')`
    align-content: center;
  `};

  ${is('contentSpaceBetween')`
    align-content: space-between;
  `};

  ${is('contentSpaceAround')`
    align-content: space-around;
  `};

  ${is('contentStretch')`
    align-content: stretch; /* default */
  `};

  ${is('alignStart')`
    align-items: flex-start;
  `};

  ${is('alignEnd')`
    align-items: flex-end;
  `};

  ${is('alignCenter')`
    align-items: center;
  `};

  ${is('alignBaseline')`
    align-items: baseline;
  `};

  ${is('alignStretch')`
    align-items: stretch;
  `};
`;

FlexRow.propTypes = {
  reset: propTypes.bool,
  inline: propTypes.bool,
  row: propTypes.bool,
  rowReverse: propTypes.bool,
  column: propTypes.bool,
  columnReverse: propTypes.bool,
  noWrap: propTypes.bool,
  wrap: propTypes.bool,
  wrapReverse: propTypes.bool,
  justifyStart: propTypes.bool,
  justifyEnd: propTypes.bool,
  justifyCenter: propTypes.bool,
  justifyBetween: propTypes.bool,
  justifyAround: propTypes.bool,
  contentStart: propTypes.bool,
  contentEnd: propTypes.bool,
  contentCenter: propTypes.bool,
  contentSpaceBetween: propTypes.bool,
  contentSpaceAround: propTypes.bool,
  contentStretch: propTypes.bool,
  alignStart: propTypes.bool,
  alignEnd: propTypes.bool,
  alignCenter: propTypes.bool,
  alignBaseline: propTypes.bool,
  alignStretch: propTypes.bool,
  columnCount: propTypes.number,
  noMargin: propTypes.bool,
  noSplit: propTypes.bool,
  noWidth: propTypes.bool,
};

import { Children, useState, useRef } from 'react';
import PropTypes from 'prop-types';

import { color, colorV2 } from 'app/shared/styles_js/variables';

import {
  TabSt,
  TabsWrapperSt,
  TabbedContentTabContainerSt,
} from './TabbedContent.css';

/**
 *
 * @param {*} props
 */
export const TabbedContent = ({
  children,
  onClickTab,
  activeIndex,
  activeBgColor,
  activeFontColor,
  defaultBgColor,
  defaultFontColor,
  TabComponent,
}) => {
  const scrollRef = useRef();
  const [activeTab, setActiveTab] = useState(activeIndex);

  return (
    <>
      <TabsWrapperSt ref={scrollRef}>
        {children.filter(Boolean).map((child, index) => {
          const isActive = activeTab === index;
          const tabbedContent = (
            <TabbedContentTabContainerSt
              key={`tabs${index}`}
              id={`d2c-car-features-tabs-${index}`}
            >
              <TabComponent
                data-testid={`tabbed-content-tab-container-${index}`}
                role="tab"
                aria-selected={isActive ? 'true' : undefined}
                active={isActive}
                activeBgColor={activeBgColor}
                activeFontColor={activeFontColor}
                defaultBgColor={defaultBgColor}
                defaultFontColor={defaultFontColor}
                onClick={(e) => {
                  onClickTab(e, index);
                  setActiveTab(index);
                  /* istanbul ignore next */
                  if (
                    scrollRef &&
                    scrollRef.current &&
                    scrollRef.current.scrollTo
                  ) {
                    // eslint-disable-next-line no-unused-expressions
                    scrollRef?.current?.scrollTo({
                      left:
                        window.document.querySelector(
                          `#d2c-car-features-tabs-${index}`,
                        ).offsetLeft - 110,
                    });
                  }
                }}
                index={index}
                tabIndex={!isActive ? '-1' : undefined}
                className={isActive ? 'active' : ''}
              >
                {child.props.title}
              </TabComponent>
            </TabbedContentTabContainerSt>
          );

          return child?.props?.title && tabbedContent;
        })}
      </TabsWrapperSt>
      {Children.map(children.filter(Boolean), (child, index) =>
        index === activeTab ? child : null,
      )}
    </>
  );
};

TabbedContent.propTypes = {
  onClickTab: PropTypes.func,
  activeIndex: PropTypes.number,
  activeBgColor: PropTypes.string,
  activeFontColor: PropTypes.string,
  defaultBgColor: PropTypes.string,
  defaultFontColor: PropTypes.string,
  children: PropTypes.any.isRequired,
  TabComponent: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
};

TabbedContent.defaultProps = {
  activeIndex: 0,
  activeBgColor: 'white',
  activeFontColor: colorV2.tarmacGrey || color.brandSecondary,
  defaultBgColor: colorV2.mintLighter || color.brandSecondary,
  defaultFontColor: colorV2.micaBlue || 'white',
  TabComponent: TabSt,
  onClickTab: () => {},
};

/* istanbul ignore file */
import styled, { css } from 'styled-components';
import { Box } from '@rebass/grid';

import { media, grid } from 'app/shared/styles_js/variables';
import { generateContainerCss } from 'app/shared/modules/dealerProfile/DealerProfileGrid/utils';

/**
 * Grid wrapper with 12 tracks and web-app's
 * default container's margins
 */
export const GridContainerSt = styled(Box).attrs(() => ({
  mx: 'auto', // centers content
  mt: 64,
  py: [32, 48, 56, 64],
}))`
  display: grid;
  width: 100%;
  column-gap: ${grid.gutter.sm * 2}px;
  grid-template-columns: repeat(12, 1fr);

  // container's width || margins
  ${({ fluidBreakpoints = [] }) => generateContainerCss(fluidBreakpoints)}

  ${media.md(css`
    column-gap: ${grid.gutter.md * 2}px;
  `)}
`;

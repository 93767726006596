/* istanbul ignore file */
import styled, { css } from 'styled-components';
import { Flex } from '@rebass/grid';

import { Text3 } from 'app/shared/ui';

export const CartileMakeModelTrimContainerSt = styled(Flex).attrs(() => ({
  flexDirection: 'column',
}))`
  color: ${({ theme: { colors } }) => colors.tarmacGrey};
  line-height: 1;

  > * {
    overflow: hidden;
    max-width: 100%;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow-wrap: normal;
    line-height: normal;
  }
`;

export const CartileMakeModelTrimTrimSt = styled(Text3)`
  display: ${() => 'inline-block !important'};
`;

export const CartileMakeModelContainerSt = styled.span`
  ${() =>
    css`
      text-overflow: ellipsis;
      overflow: hidden;
    `}
`;

export const CartileNewListingPillSt = styled.span`
  background-color: ${({ theme: { colors } }) => colors.brandSecondaryLight};
  width: 37px;
  height: 20px;
  padding: 2px 8px;
  color: white;
  border-radius: 10px;
  font-size: 10px;
  margin-right: 8px;
  line-height: 16px;
  text-align: center;
  font-weight: 700;
`;

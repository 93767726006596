import { memo } from 'react';
import PropTypes from 'prop-types';

import { CartileLinkBackgroundSt } from 'app/shared/modules/Cartile/CartileUIComponents/CartileLink/CartileLinkBackground/CartileLinkBackground.css';

const CartileLinkBackgroundCmp = ({ linkTo, target, handleTracking }) => (
  <CartileLinkBackgroundSt
    to={linkTo}
    target={target}
    onClick={handleTracking}
    aria-label="Auto-Detail Link"
    data-testid="shared-components-cartileWide-cartileWideContainer-link"
  />
);

CartileLinkBackgroundCmp.propTypes = {
  target: PropTypes.string.isRequired,
  linkTo: PropTypes.string.isRequired,
  handleTracking: PropTypes.func.isRequired,
};

export const CartileLinkBackground = memo(CartileLinkBackgroundCmp);

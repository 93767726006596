/* eslint-disable react/no-multi-comp */
import React from 'react';
import PropTypes from 'prop-types';

import { SpinnerSt } from './LoadingSpinner.css';

/**
 * Loading animation
 */
export const LoadingSpinner = (props) => {
  const { size, color, ...rest } = props;
  const radius = size * 0.35;
  const width = Math.round(size * 0.08); // width of the loading items
  const height = Math.round(size * 0.22); // height of the loading items
  const top = Math.round(size * 0.5 - radius - height * 0.5);
  const left = Math.round(size * 0.5 - width * 0.5);

  return (
    <SpinnerSt
      radius={radius}
      size={size}
      width={width}
      height={height}
      top={top}
      left={left}
      color={color}
      {...rest}
    >
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
    </SpinnerSt>
  );
};

LoadingSpinner.defaultProps = {
  size: 280,
  color: 'gray',
};

LoadingSpinner.propTypes = {
  size: PropTypes.number,
  color: PropTypes.string,
};

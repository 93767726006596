/* eslint-disable react/prop-types */

import React from 'react';

import { Icon } from '../../../Icon/Icon';

import {
  NavFlyoutBackButtonLabelSt,
  NavFlyoutBackButtonWrapperSt,
} from './NavFlyoutButton.css';
import { INavFlyoutBackButtonProps } from './NavFlyoutBackButton.types';

export const NavFlyoutBackButton = ({
  onClick,
  children,
  ...rest
}: INavFlyoutBackButtonProps): JSX.Element => {
  return (
    <NavFlyoutBackButtonWrapperSt
      onClick={onClick}
      px="24px"
      py="12px"
      mb={['8px', '4px']}
      width="100%"
      alignItems="center"
      justifyContent="flex-start"
      {...rest}
    >
      <Icon icon="arrow-left-short" />{' '}
      <NavFlyoutBackButtonLabelSt>{children}</NavFlyoutBackButtonLabelSt>
    </NavFlyoutBackButtonWrapperSt>
  );
};

import { createSelector } from 'reselect';

export const getGeolocationState = (state) => state.geolocation;
export const locationSuggestionsSelector = createSelector(
  getGeolocationState,
  (geolocationState) => geolocationState.locationSuggestions,
);
export const locationErrorSelector = createSelector(
  getGeolocationState,
  (geolocationState) => geolocationState.error,
);
export const locationPendingSelector = createSelector(
  getGeolocationState,
  (geolocationState) => geolocationState.locationPending,
);
export const currentLocationSelector = createSelector(
  getGeolocationState,
  (geolocationState) => geolocationState.currentLocation,
);

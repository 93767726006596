import { memo } from 'react';
import PropTypes from 'prop-types';

import { CartileAdditionalInfosContainerSt } from 'app/shared/modules/Cartile/CartileTemplates/CartileAdditionalInfosContainer/CartileAdditionalInfosContainer.css';

const CartileAdditionalInfosContainerCmp = ({
  children,
  isHorizontal,
  isUnavailable,
}) => {
  return (
    <CartileAdditionalInfosContainerSt
      isHorizontal={isHorizontal}
      isUnavailable={isUnavailable}
      hasMarginBottom={!!children}
    >
      {children}
    </CartileAdditionalInfosContainerSt>
  );
};

CartileAdditionalInfosContainerCmp.propTypes = {
  children: PropTypes.node,
  isHorizontal: PropTypes.bool.isRequired,
  isUnavailable: PropTypes.bool.isRequired,
};

CartileAdditionalInfosContainerCmp.defaultProps = {
  children: null,
};

export const CartileAdditionalInfosContainer = memo(
  CartileAdditionalInfosContainerCmp,
);

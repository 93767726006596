import { ActionTypes } from './constants';

const initialState = {};

export const notifications = (state = {}, { type, payload } = {}) => {
  switch (type) {
    case ActionTypes.SHOW_NOTIFICATION:
      return { ...payload, isVisible: true };

    case ActionTypes.HIDE_NOTIFICATION:
      return state.id === payload.id ? { ...state, isVisible: false } : state;

    case ActionTypes.CLEAR_NOTIFICATION:
      return initialState;

    default:
      return state;
  }
};

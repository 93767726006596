/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import {
  CALCULATION_INFO_GET,
  CALCULATION_INFO_GET_SUCCESS,
  CALCULATION_INFO_GET_ERROR,
} from '../../constants';
import { ICalculationInfoData } from '../../actions/checkoutJourneyInfo/checkoutJourneyInfo.types';

export const initialState: ICalculationInfoData = {
  downPaymentAmount: 0,
  duration: 0,
  monthlyRate: 0,
  yearlyMileage: 0,
  disclaimer: '',
  error: undefined,
};

export function calculationInfo(
  state = initialState,
  action: { type: string; payload: ICalculationInfoData },
) {
  const { type, payload } = action;

  switch (type) {
    case CALCULATION_INFO_GET:
      return { ...state, error: undefined, isLoading: true };
    case CALCULATION_INFO_GET_SUCCESS:
      return { ...state, ...payload, isLoading: false };
    case CALCULATION_INFO_GET_ERROR:
      return { ...state, error: payload.error, isLoading: false };
    default:
      return state;
  }
}

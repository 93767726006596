import styled, { css } from 'styled-components';
import { Heading4, ContainerSt } from 'app/shared/ui';

import {
  media,
  grid,
  fontSizes,
  evergreenOnly,
  iconSizes,
} from 'app/shared/styles_js/variables';

export const MakesSt = styled(ContainerSt)<{ grid: boolean; md: number }>`
  margin-top: 96px;
  margin-bottom: 40px;

  ${media.lg(
    css`
      margin-top: 128px;
    `,
  )}
  ${media.xl(
    css`
      margin-bottom: 72px;
    `,
  )}
`;

export const MakesTitleSt = styled(Heading4).attrs(
  /* istanbul ignore next */ () => ({
    fontSize: [24, 28, 32],
    lineHeight: [1.67, 1.5],
    fontWeight: 800,
    mb: [24],
  }),
)`
  ${media.md(css`
    grid-column: 1 / span 4;
    width: 25%;
    padding-right: ${grid.gutter.md}px;

    ${evergreenOnly(css`
      width: auto;
      padding-right: 0;
    `)};
  `)};
`;

export const SliderOuterSt = styled.div`
  position: relative;
  overflow: hidden;
  ${media.md(css`
    grid-column: 5 / span 8;
    width: 75%;
    padding-right: ${grid.gutter.md}px;

    ${evergreenOnly(css`
      width: auto;
      padding-right: 0;
    `)};
  `)};

  ${media.xl(css`
    padding: 34px 20px 0 20px;
    margin: -34px -20px 0 -20px;
  `)};

  &::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    width: 100px;
    height: 100%;
    content: '';
    pointer-events: none;
    background: linear-gradient(
      to right,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 1) 100%
    ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  }
`;

export const TooltipSt = styled.div`
  display: none;
  background-color: ${({ theme: { colors } }) => colors.brandSecondary};
  border-radius: 2px;
  color: #fff;
  font-size: ${fontSizes.p3};
  font-weight: 100;
  text-align: center;
  position: absolute;
  top: -34px;
  left: -15%;
  width: 130%;
  padding-top: 4px;
  padding-bottom: 4px;
`;

export const TooltipArrowSt = styled.div`
  border-color: ${({ theme: { colors } }) => colors.brandSecondary} transparent
    transparent transparent;
  border-style: solid;
  border-width: 8px 4px 0;
  position: absolute;
  bottom: -7px;
  left: 46%;
  height: 0;
  width: 0;
`;

const arrowWidth = 80;
const ArrowSt = styled.i<{ disabled?: boolean }>`
  display: inline-block;
  cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
  font-size: ${iconSizes.medium}px;
  grid-column: 1 / span 2;
  width: ${arrowWidth}px;
  ${evergreenOnly(
    media.md(
      css`
        width: auto;
      `,
    ),
  )};

  ${media.xl(css`
    grid-column-end: span 1;
  `)};

  color: ${({ disabled, theme: { colors } }) =>
    disabled ? colors.blueLighter : colors.micaBlue};
  transition: color 200ms ease-in-out;

  &:hover,
  &:active,
  &:focus {
    &.if-icon-arrow-left-short,
    &.if-icon-arrow-right-short {
      color: ${({ disabled, theme: { colors } }) =>
        disabled ? colors.blueLighter : colors.actionBlue};
    }
  }

  &::before {
    display: inline-block;
    transform: translateX(-50%);
    position: relative;
    left: 50%;
  }
`;

export const ArrowLeftSt = styled(ArrowSt)`
  grid-column-start: 9;
  margin-left: calc(100% - ${arrowWidth * 2}px);

  ${media.md(
    evergreenOnly(
      css`
        margin-left: 0;
      `,
    ),
  )};

  ${media.xl(css`
    grid-column-start: 11;
  `)};
`;

export const ArrowRightSt = styled(ArrowSt)`
  grid-column-start: 11;

  ${media.xl(css`
    grid-column-start: 12;
  `)};
`;

import { memo } from 'react';
import PropTypes from 'prop-types';

import { CartileLoadingStyle } from 'app/shared/modules/Cartile/CartileUIComponents/CartileUIComponents.css';
import { CartileCrossedOutPriceTextSt } from 'app/shared/modules/Cartile/CartileUIComponents/CartilePrice/CartileCrossedOutPrice/CartileCrossedOutPrice.css';
import { formatCurrency } from 'app/shared/utils/formatNumber';

const CartileCrossedOutPriceCmp = ({ highestPrice, isLoading }) => {
  return (
    <CartileCrossedOutPriceTextSt css={[isLoading && CartileLoadingStyle]}>
      {formatCurrency(highestPrice)}
    </CartileCrossedOutPriceTextSt>
  );
};

CartileCrossedOutPriceCmp.propTypes = {
  isLoading: PropTypes.bool,
  highestPrice: PropTypes.number.isRequired,
};
CartileCrossedOutPriceCmp.defaultProps = {
  isLoading: false,
};
export const CartileCrossedOutPrice = memo(CartileCrossedOutPriceCmp);

import { CartileLinkBackground } from 'app/shared/modules/Cartile/CartileUIComponents/CartileLink/CartileLinkBackground/CartileLinkBackground';
import { CartileImageRemoveIcon } from 'app/shared/modules/Cartile/CartileUIComponents/CartileLink/CartileImageRemoveIcon/CartileImageRemoveIcon';

export const getDataForCartileLink = ({
  linkTo,
  target,
  isHorizontal,
  isUnavailable,
  hasRemoveIcon,
  handleTracking,
  paddingHorizontal,
  handleToggleFavourites,
}) => {
  if (isUnavailable || hasRemoveIcon) {
    return {
      CartileLinkContainerChildren: CartileImageRemoveIcon,
      CartileLinkContainerChildrenProps: {
        isHorizontal,
        paddingHorizontal,
        handleToggleFavourites,
      },
    };
  }
  return {
    CartileLinkContainerChildren: CartileLinkBackground,
    CartileLinkContainerChildrenProps: {
      linkTo,
      target,
      handleTracking,
    },
  };
};

import { Route, Redirect } from 'react-router';

import { getAsyncRouteComponentLoader } from 'app/shared/utils/utils';
import { ConnectedPageTemplate } from 'app/shared/modules/pageTemplate/PageTemplate/ConnectedPageTemplate';

export const CheckoutRoutes = (
  <Route
    name="FinanceCE"
    component={(props) => (
      <ConnectedPageTemplate target="FinanceCE" {...props} />
    )}
  >
    <Redirect exact from="/online-kauf" to="/auto-online-bestellen" />
    <Route
      path="/checkout/orders/:orderId/damage-report"
      exact
      getComponent={getAsyncRouteComponentLoader('DamagesReport', () =>
        import('app/shared/modules/DamagesReport/DamagesReport'),
      )}
    />
    {/* TODO: change later */}
    <Route
      path="/checkout/:id/:calcId/:checkoutType"
      exact
      getComponent={getAsyncRouteComponentLoader('CheckoutSteps', () =>
        import('./pages/CheckoutSteps/CheckoutSteps'),
      )}
    />
    <Route
      path="/checkout/:id/:checkoutType"
      exact
      getComponent={getAsyncRouteComponentLoader('CheckoutSteps', () =>
        import('./pages/CheckoutSteps/CheckoutSteps'),
      )}
    />
    <Route
      path="/cash-checkout/:id/success"
      exact
      getComponent={getAsyncRouteComponentLoader('CashCheckoutSuccess', () =>
        import('./pages/CashCheckoutSuccess/CashCheckoutSuccess'),
      )}
    />
    <Route
      path="/dealer-cash-checkout/:id/success"
      exact
      getComponent={getAsyncRouteComponentLoader(
        'DealerCashCheckoutSuccess',
        () =>
          import('./pages/DealerCashCheckoutSuccess/DealerCashCheckoutSuccess'),
      )}
    />
    <Route
      path="/financing-journey/:hash"
      getComponent={getAsyncRouteComponentLoader('FinancingJourney', () =>
        import('./pages/FinancingJourney/FinancingJourney'),
      )}
    />
    <Route
      path="/cash-journey/:hash"
      getComponent={getAsyncRouteComponentLoader('CashJourney', () =>
        import('./pages/CashJourney/CashJourney'),
      )}
    />
  </Route>
);

/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/ban-ts-comment */
/* istanbul ignore file */
import { useEffect } from 'react';
import { browserHistory } from 'react-router';

import { Navigation } from 'app/shared/ui';
import { trackingMapping } from 'app/marketplace/shared/components/TrackingMapping/trackingMapping';
import { TBreakpoint } from 'app/types/style.types';
import { LoadingAnimation } from 'app/shared/components/LoadingAnimation/LoadingAnimation';
import { NavContact } from 'app/marketplace/shared/components/NavContact/NavContact';
import { ConnectedMobileNavControls } from 'app/marketplace/shared/components/MobileNavControls/ConnectedMobileNavControls';
import { trackAction } from 'app/shared/utils/tracking';
import { LazyAuthModal as AuthModal } from 'app/shared/modules/overlay/defaultOverlays/auth/AuthModal/LazyAuthModal';

import { getMenuItems } from './MenuItems/MenuItems';
import { getZIndex, getIsHiddenNavPage } from './utils';
import {
  NavbarWrapperSt,
  PageLoadingSpinnerSt,
} from './NewMarketplaceTopNavBar.css';
import { IMarketplaceTopNavBarCmpProps } from './NewMarketplaceTopNavBar.types';
import { FavouriteIcon, Logo } from './NewMarketplaceTopNavBar.constants';
import { UserMenu } from './MenuItems/listItems/UserMenu/UserMenu';

export const NewMarketplaceTopNavbar = (
  props: IMarketplaceTopNavBarCmpProps,
): JSX.Element => {
  const {
    accountRoute,
    openOverlay,
    closeOverlay,
    location: { pathname },
    target,
    isFavourite,
    breakpoint = 'sm' as TBreakpoint,
    isHome,
    hideFavouriteTip,
    favouritesCount,
    showFavouriteTip,
    showSaSeDashboardTip,
    hideSaSeDashboardTip,
    isLoading,
    isMobileDevice,
    isHidden,
    token,
    logoutAction,
    isFlag256ShowNewPromotionTiles,
    checkoutType,
  } = props;

  const isHiddenNavPage = getIsHiddenNavPage(pathname);

  const mobileBackButtonLabel = 'Zurück zur Übersicht';

  // replaces componentDidMount line 56 from MarketplaceTopNavBar.js
  useEffect(() => {
    if (accountRoute) {
      // @ts-ignore
      openOverlay(AuthModal, {
        props: {
          accountRoute,
          fromAccountRoute: !(
            accountRoute === 'profile' || accountRoute === 'searches'
          ),
        },
      });
    }
  }, []);

  const handleLogout = () => {
    logoutAction();
    if (pathname === '/account/searches' || pathname === '/account/profile') {
      browserHistory.push('/');
    }
  };

  // Replaces onHamburgerLink line 145 from MarketplaceTopNavBar.js
  const onMobileHamburguerLink = () => {
    trackAction('hamburger_menu_click', {
      category: target,
      description: 'user clicks on hamburger menu in the top nav bar',
    });
  };

  // TODO: fix name type later
  const setTracking = (type: string, name: any) => {
    const trackingValue = trackingMapping(name);

    switch (type) {
      case 'main':
        return trackAction('topnavigation_open', {
          category: target,
          value: trackingValue,
          description: `user clicks on ${trackingValue} top nav`,
        });
      case 'child':
        return trackAction('topnavigation_select_link_click', {
          category: target,
          value: trackingValue,
          description: `user clicks on ${trackingValue} top nav`,
        });
      case 'backButton':
        return trackAction('topnavigation_back_button', {
          category: target,
          value: 'backButton',
          description: 'user clicks on back button on mobile navigation',
        });
      case 'makeLogo':
        return trackAction('topnavigation_select_link_click', {
          category: target,
          value: trackingValue,
          description: `user clicks on ${trackingValue} logo from nav bar`,
        });
      case 'allMake':
        return trackAction('topnavigation_select_link_click', {
          category: target,
          value: 'all_makes',
          description: 'user clicks on all makes from nav bar',
        });
      default:
        return false;
    }
  };

  // Replaces onAuthClick line 184 from MarketplaceTopNavBar.js
  const onAuthClick = () => {
    trackAction('menu_button_click', {
      category: target,
      value: 'login',
      description: 'user clicks on Anmelden button in top nav',
    });

    // @ts-ignore
    openOverlay(AuthModal);
  };

  // Replaces onFavouriteClick line 196 from MarketplaceTopNavBar.js
  const onFavouriteClick = () => {
    trackAction('favourite_topnav_click', {
      category: target,
      description: 'user clicks on Favourite button in top nav',
    });
  };

  // replaces line 224 inside getNavLinks from MarketplaceTopNavBar.js
  const isTipModal = showFavouriteTip || showSaSeDashboardTip;

  const isLoggedIn = !!token;

  const isHiddenOnScroll =
    !showFavouriteTip && !isHome && isMobileDevice && isHidden;

  const zIndex = getZIndex(pathname, showFavouriteTip, showSaSeDashboardTip);

  const commonNavProps = {
    isMobile: isMobileDevice, // use same layout for mobile and tablet
    isHidden: isHiddenOnScroll,
    zIndex, // this is needed because there are elements on the pdp that are floating. Double check that it works on all pages!
    navLogo: Logo({
      isHome,
      isLoading,
    }),
  };

  // TODO: consider moving this props away from this file in the future
  const navProps = {
    ...commonNavProps,
    //  use login menu items
    userItem: UserMenu(onAuthClick, isLoggedIn, handleLogout, isMobileDevice),
    // regular menu items
    items: getMenuItems({
      isMobileDevice,
      breakpoint,
      isFlag256ShowNewPromotionTiles,
    }),
    navLogo: Logo({
      isHome,
      isLoading,
    }),
    favouritesIcon: FavouriteIcon({
      isMobileDevice,
      onFavouriteClick,
      isTipModal,
      favouritesCount,
      breakpoint,
      isHome,
      showFavouriteTip,
      showSaSeDashboardTip,
      isFavourite,
      hideFavouriteTip,
      openOverlay,
      closeOverlay,
      target,
      hideSaSeDashboardTip,
    }),
    mobileBackButtonLabel,
    hambugerMenuClick: onMobileHamburguerLink,
  };

  const hiddenNavPageNavProps = {
    ...commonNavProps,
    items: [],
    showIconsSection: false,
    customContent:
      breakpoint === 'sm' ? (
        <ConnectedMobileNavControls />
      ) : (
        <NavContact checkoutType={checkoutType} />
      ),
  };

  const navigationProps = isHiddenNavPage ? hiddenNavPageNavProps : navProps;

  return (
    <NavbarWrapperSt isHome={isHome}>
      {/* main navigation  */}
      <Navigation {...navigationProps} setTracking={setTracking} />
      <PageLoadingSpinnerSt isLoading={isLoading}>
        <LoadingAnimation isLoading={isLoading} />
      </PageLoadingSpinnerSt>
    </NavbarWrapperSt>
  );
};

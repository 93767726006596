import { memo } from 'react';
import PropTypes from 'prop-types';

import { CartileLoadingStyle } from 'app/shared/modules/Cartile/CartileUIComponents/CartileUIComponents.css';
import { CartileTotalPrice } from 'app/shared/modules/Cartile/CartileUIComponents/CartilePrice/CartileTotalPrice/CartileTotalPrice';
import { CartileD2CMtlPrice } from 'app/shared/modules/Cartile/CartileUIComponents/CartilePrice/CartileD2CMtlPrice/CartileD2CMtlPrice';
import { CartilePriceContainersSt } from 'app/shared/modules/Cartile/CartileUIComponents/CartilePrice/CartilePriceContainers/CartilePriceContainers.css';

const CartilePriceD2CDefaultCmp = ({
  price,
  linkTo,
  isLoading,
  monthlyRate,
  vatReclaimable,
  handleTracking,
  reducedPrice,
  highestPrice,
}) => (
  <CartilePriceContainersSt
    isLoading={isLoading}
    css={[isLoading && CartileLoadingStyle]}
  >
    <CartileTotalPrice
      color={!reducedPrice ? undefined : 'oldFerrari'}
      size="large"
      price={price}
      vatReclaimable={vatReclaimable}
      isD2C
      highestPrice={highestPrice}
      discount={reducedPrice}
    />
    {Boolean(monthlyRate) && (
      <CartileD2CMtlPrice
        linkTo={linkTo}
        fontSize="14px"
        monthlyRate={monthlyRate}
        handleTracking={handleTracking}
      />
    )}
  </CartilePriceContainersSt>
);

CartilePriceD2CDefaultCmp.propTypes = {
  isLoading: PropTypes.bool,
  monthlyRate: PropTypes.number,
  price: PropTypes.number.isRequired,
  linkTo: PropTypes.string.isRequired,
  vatReclaimable: PropTypes.bool.isRequired,
  handleTracking: PropTypes.func.isRequired,
  reducedPrice: PropTypes.bool.isRequired,
  highestPrice: PropTypes.number.isRequired,
};

CartilePriceD2CDefaultCmp.defaultProps = {
  isLoading: false,
  monthlyRate: null,
};

export const CartilePriceD2CDefault = memo(CartilePriceD2CDefaultCmp);

import styled from 'styled-components';
import { Text } from 'app/shared/ui';

export const CartileMtlPriceLinkSt = styled.button`
  display: inline-block;
  z-index: 1;
  color: ${/* istanbul ignore next */ ({ theme }) => theme.colors.mintDark};
  transition: color 200ms ease-in-out;
  background: none;
  border: 0;
  cursor: pointer;
  text-decoration: underline;

  &:hover {
    color: ${
      /* istanbul ignore next */ ({ theme }) => theme.colors.brandPrimary
    };
  }
`;

export const CartileMtlPriceTextSt = styled(Text).attrs(() => ({
  as: 'div',
  mb: ['4px', 0],
  fontWeight: 'bold',
  lineHeight: 'normal',
  fontSize: ['10px', '10px'],
}))``;

export const CartileMtlPriceNumberSt = styled(Text).attrs(
  /* istanbul ignore next */ ({ fontsize }) => ({
    as: 'span',
    fontSize: fontsize,
    fontWeight: 'bold',
    lineHeight: 'normal',
  }),
)``;

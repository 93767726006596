/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import { Squash as Hamburger } from 'hamburger-react';

import { colors } from '../variables/colors';

import { NavItem } from './components/NavItem/NavItem';
import { NavigationProps } from './Navigation.types';
import {
  MobileWrapperSt,
  IconsWrapper,
  LogoWrapper,
  NavItemsWrapper,
  NavigationWrapper,
  BoxSt,
  HamburgerWrapper,
  FavouriteIconSt,
  GridContainerSt,
} from './Navigation.css';

export const Navigation = ({
  items,
  favouritesIcon,
  userItem,
  mobileBackButtonLabel,
  navLogo,
  zIndex = 1000,
  hambugerMenuClick,
  setTracking,
  isHidden = false,
  menuHoverCallback,
  showIconsSection = true,
  customContent,
}: NavigationProps): JSX.Element => {
  const staticOwns = ['login', 'loginmobile'];
  const [selectedItem, setSelectedItem] = useState('');
  const [isMobileMenuOpen, setMobileMenuOpen] = useState(false);
  const closeMenu = (isClosed: boolean) => {
    /* istanbul ignore next */
    if (isClosed) {
      if (typeof hambugerMenuClick === 'function') {
        hambugerMenuClick();
      }
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }

    setMobileMenuOpen(isClosed);
    setSelectedItem('');
  };

  useEffect(() => {
    if (userItem) {
      closeMenu(false);
    }
  }, [userItem]);

  const ariaOwnsArr = items?.map((item) => item.itemId);
  const extendedOwns = userItem ? [...ariaOwnsArr, ...staticOwns] : ariaOwnsArr;
  const ariaOwns = extendedOwns?.toString()?.replace(/,/g, ' ');

  return (
    <BoxSt $zIndex={zIndex} isHidden={isHidden}>
      <NavigationWrapper
        role="navigation"
        aria-label="Main navigation"
        className={isMobileMenuOpen ? 'open' : ''}
      >
        {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
        {/* @ts-ignore */}
        <GridContainerSt hasDefaultWidth height="100%">
          <LogoWrapper role="button" $zIndex={zIndex}>
            {navLogo}
          </LogoWrapper>

          {/* main navigation items */}
          <NavItemsWrapper
            role="tablist"
            aria-owns={ariaOwns}
            aria-label="Main navigation"
          >
            {items.map((item) => (
              <NavItem
                key={item.itemId}
                item={item}
                selectOnClick={setSelectedItem}
                selectedItem={selectedItem}
                mobileBackButtonLabel={mobileBackButtonLabel}
                setTracking={setTracking}
                closeMenu={closeMenu}
                ariaRole={item.itemId}
                useCustomChildren={item.useCustomChildren}
                customChildren={item.customChildren}
                menuHoverCallback={menuHoverCallback}
              />
            ))}
          </NavItemsWrapper>

          {showIconsSection && (
            <IconsWrapper>
              {favouritesIcon && (
                <FavouriteIconSt alignItems="center">
                  {favouritesIcon}
                </FavouriteIconSt>
              )}
              <MobileWrapperSt>
                <HamburgerWrapper
                  p={2}
                  backgroundColor={
                    /* istanbul ignore next */ isMobileMenuOpen
                      ? 'brandPrimaryDark'
                      : ''
                  }
                >
                  <Hamburger
                    data-test-id="mobile-menu-button"
                    toggled={isMobileMenuOpen}
                    onToggle={closeMenu}
                    size={24}
                    color={
                      /* istanbul ignore next */ isMobileMenuOpen
                        ? colors.white
                        : colors.brandSecondary
                    }
                  />
                </HamburgerWrapper>
              </MobileWrapperSt>
            </IconsWrapper>
          )}
          {customContent && customContent}
        </GridContainerSt>
      </NavigationWrapper>
    </BoxSt>
  );
};

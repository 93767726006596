/* eslint-disable no-use-before-define */
import { noop } from 'app/shared/utils/utils';

import { tokenNearExpiry } from '../../shared/utils/tokenNearExpiry';
import {
  syncFavourites,
  toggleFavourite,
  getFavouriteVehicleIds,
} from '../favourites/actions';
import { trackAction } from '../../shared/utils/tracking';
import { closeOverlay } from '../../shared/modules/overlay/actions';

import { ActionTypes } from './constants';
import { userService } from './service';
import {
  getRefreshToken,
  getAccessToken,
  getTokenExpiresIn,
  getTimeOfTokenCreation,
  getUserInfo,
} from './selectors';
import { to } from './helpers/to';

//---------------------------------------------------------------------------------------------
// ---------------------------- action login  -------------------------------------------------
//---------------------------------------------------------------------------------------------

export const loginSuccess = (token, user) => ({
  type: ActionTypes.LOGIN_COMPLETED,
  payload: { token, user },
});

export const loginFailure = (error) => ({
  type: ActionTypes.LOGIN_FAILED,
  payload: error,
});

export const resetLoginFailure = () => (dispatch) => {
  dispatch({ type: ActionTypes.RESET_LOGIN_FAILURE });
};

export const resendConfirmationEmail = async ({ email }) => {
  const [error, response] = await to(
    userService.resendConfirmationEmail({ email }),
  );

  if (error) {
    trackAction('resent_account_confirmation_email', {
      category: 'user account',
      label: 'resent_account_confirmation_email_fail',
      description: 'account confirmation email was not re-sent to the user',
    });
  }
};

/**
 * used when logging in with authorization code e.g. from logging in with verimi/FB/google
 * @param code
 * @return {function(...[*]=)}
 */
export const loginWithAuthorizationCode = ({ code, redirect_uri }) => async (
  dispatch,
  getState,
) => {
  const [error, token] = await to(
    userService.loginWithAuthorizationCode({ code, redirect_uri }),
  );

  if (error) {
    trackAction('login_response_openid', {
      category: 'user account',
      label: 'login_failed',
      description: `user tried to login to heycar, but it failed: ${error.body}`,
    });

    dispatch(loginFailure(error));
  } else {
    trackAction('login_response_openid', {
      category: 'user account',
      label: 'login_success',
      description: 'user successfully logged in to heycar',
    });

    const user = await to(userService.getUser(token));
    // in case of success, api returns user = [null, {...userInformation}]
    if (user.length > 1) {
      trackAction('get_user_info_after_login', {
        category: 'user account',
        label: 'get_user_info_success',
        description: 'user info was received successfully',
      });
      dispatch(loginSuccess(token, user[1]));
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      dispatch(getProfile());
    } else {
      // in case of fail, api returns user = [{error: errorMessage}]
      trackAction('get_user_info_after_login', {
        category: 'user account',
        label: 'get_user_info_fail',
        description: 'user info was not received',
      });
      dispatch(loginSuccess(token, null));
    }

    const { pendingVehicle } = getState().favourites;
    if (pendingVehicle) {
      dispatch(toggleFavourite(pendingVehicle));
    }

    dispatch(syncFavourites());
    dispatch(getFavouriteVehicleIds());
  }
};

export const login = ({ email, password }, { setSubmitting }) => async (
  dispatch,
  getState,
) => {
  const state = getState();
  const [error, token] = await to(userService.login({ email, password }));

  trackAction('click_submit_login_button', {
    category: 'user account',
    label: 'click_submit_login_button',
    description:
      'user clicks on login button after inserting all needed information into the form',
  });

  if (error) {
    trackAction('login_response', {
      category: 'user account',
      label: 'login_failed',
      description: `user tried to login to heycar, but it failed: ${error.body}`,
    });

    if (
      error.status === 400 &&
      error.body.indexOf('Account is not fully set up') !== -1
    ) {
      resendConfirmationEmail({ email });
    }

    dispatch(loginFailure(error));
  } else {
    trackAction('login_response', {
      category: 'user account',
      label: 'login_success',
      description: 'user successfully logged in to heycar',
    });

    const user = await to(userService.getUser(token));
    // in case of success, api returns user = [null, {...userInformation}]
    if (user.length > 1) {
      trackAction('get_user_info_after_login', {
        category: 'user account',
        label: 'get_user_info_success',
        description: 'user info was received successfully',
      });
      dispatch(loginSuccess(token, user[1]));
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      dispatch(getProfile());
    } else {
      // in case of fail, api returns user = [{error: errorMessage}]
      trackAction('get_user_info_after_login', {
        category: 'user account',
        label: 'get_user_info_fail',
        description: 'user info was not received',
      });
      dispatch(loginSuccess(token, null));
    }

    const { pendingVehicle } = state.favourites;
    const { saSeDashboardTipStatus } = state.authMarketplace;

    if (pendingVehicle) {
      dispatch(toggleFavourite(pendingVehicle));
    }

    dispatch(syncFavourites());
    dispatch(getFavouriteVehicleIds());

    dispatch(closeOverlay());
    if (saSeDashboardTipStatus) {
      setTimeout(() => {
        dispatch({ type: ActionTypes.SHOW_SASE_DASHBOARD_MODAL });
      }, 100);
    }
  }
  setSubmitting(false);
};

export const hideSaSeDashboardTip = () => (dispatch) => {
  dispatch({
    type: ActionTypes.HIDE_SASE_DASHBOARD_MODAL,
  });
};
//---------------------------------------------------------------------------------------------
// ---------------------------- action signup  ------------------------------------------------
//---------------------------------------------------------------------------------------------

export const signupSuccess = (user) => ({
  type: ActionTypes.REGISTER_COMPLETED,
  payload: user,
});
export const signupFailure = (error) => ({
  type: ActionTypes.REGISTER_FAILED,
  payload: error,
});
export const avoidDoubleSignUpNotification = () => async (dispatch, getState) =>
  dispatch({ type: ActionTypes.REMOVE_JUST_REGISTERED });

export const signup = (
  { email, password, marketingConsent },
  { setSubmitting },
) => async (dispatch, getState) => {
  const [error, user] = await to(
    userService.signUp({ email, password, marketingConsent }),
  );

  trackAction('click_submit_sign-up_button', {
    category: 'user account',
    label: 'click_submit_sign-up_button',
    description:
      'user clicks on signup button after inserting all needed information into the form',
  });

  if (error) {
    trackAction('sign-up_response', {
      category: 'user account',
      label: 'sign-up_failed',
      description: `user tried to sign up to heycar, but it failed: ${error.body}`,
    });
    dispatch(signupFailure(error));
  } else {
    trackAction('sign-up_response', {
      category: 'user account',
      label: 'sign-up_success',
      description: 'user successfully signed up to heycar',
    });
    await dispatch(signupSuccess(user));

    const { pendingVehicle } = getState().favourites;
    if (pendingVehicle) {
      dispatch(toggleFavourite(pendingVehicle));
    }
    dispatch(syncFavourites());
  }
  setSubmitting(false);
};

//---------------------------------------------------------------------------------------------
// ---------------------------- action logout  ------------------------------------------------
//---------------------------------------------------------------------------------------------

export const loggedOut = () => {
  return { type: ActionTypes.LOGOUT };
};

export const logOut = () => async (dispatch, getState) => {
  const state = getState();
  const token = getAccessToken(state);
  const refreshToken = getRefreshToken(state);

  const [error, user] = await to(userService.logOut(token, refreshToken));

  if (error) {
    // handle error
  } else {
    dispatch(loggedOut());
  }
};

//---------------------------------------------------------------------------------------------
// ---------------------------- action refresh token  -----------------------------------------
//---------------------------------------------------------------------------------------------

export const refreshToken = () => async (dispatch, getState) => {
  const state = getState();
  const refreshTokenFromStore = getRefreshToken(state);
  const tokenExpiresIn = getTokenExpiresIn(state);
  const tokenCreatedAt = getTimeOfTokenCreation(state);
  const tokenExpirationTime = new Date(tokenCreatedAt);
  const userInfo = getUserInfo(state);

  tokenExpirationTime.setSeconds(
    tokenExpirationTime.getSeconds() + tokenExpiresIn,
  );
  const isTokenNearExpiration = tokenNearExpiry(tokenExpirationTime);

  if (!isTokenNearExpiration) {
    return null;
  }

  const [error, token] = await to(
    userService.getNewToken(refreshTokenFromStore),
  );

  if (error) {
    return dispatch(loginFailure(error));
  }
  if (userInfo) {
    return dispatch(loginSuccess(token, userInfo));
  }
  const user = await to(userService.getUser(token));
  if (user.length > 1) {
    trackAction('get_user_info_after_refresh_token', {
      category: 'user account',
      label: 'get_user_info_success',
      description: 'user info was received successfully',
    });
    return dispatch(loginSuccess(token, user[1]));
  }
  trackAction('get_user_info_after_refresh_token', {
    category: 'user account',
    label: 'get_user_info_fail',
    description: 'user info was not received',
  });
  return dispatch(loginSuccess(token, null));
};

//---------------------------------------------------------------------------------------------
// ---------------------------- get user profile ----------------------------------------------
//---------------------------------------------------------------------------------------------

export const getProfileSuccess = (profileData) => ({
  type: ActionTypes.GET_PROFILE_COMPLETED,
  payload: profileData,
});

export const getProfileFailure = (error) => ({
  type: ActionTypes.GET_PROFILE_FAILED,
  payload: error,
});

export const getProfile = () => async (dispatch, getState) => {
  dispatch({ type: ActionTypes.GET_PROFILE_STARTED });
  try {
    await dispatch(refreshToken());
    const state = getState();
    const accessToken = getAccessToken(state);
    const profile = await userService.getUserProfile(accessToken);
    dispatch(getProfileSuccess(profile));
  } catch (error) {
    dispatch(getProfileFailure(error));
  }
};

//---------------------------------------------------------------------------------------------
// ---------------------------- update user profile ----------------------------------------------
//---------------------------------------------------------------------------------------------

export const updateProfileFailed = (error) => ({
  type: ActionTypes.UPDATE_PROFILE_FAILED,
  payload: error,
});

export const updateProfileSuccess = () => ({
  type: ActionTypes.UPDATE_PROFILE_COMPLETED,
});

export const updateProfile = (
  { firstName, lastName, email, phoneNumber },
  { setSubmitting } = { setSubmitting: noop },
) => async (dispatch, getState) => {
  trackAction('click_update_profile_button', {
    category: 'profile_form',
  });

  setSubmitting(true);
  dispatch({ type: ActionTypes.UPDATE_PROFILE_STARTED });
  try {
    await dispatch(refreshToken());
    const state = getState();
    const accessToken = getAccessToken(state);
    await userService.updateUserProfile(
      { firstName, lastName, email, phoneNumber },
      accessToken,
    );
    dispatch(updateProfileSuccess());
    dispatch(getProfile());
  } catch (error) {
    dispatch(updateProfileFailed(error));
    // eslint-disable-next-line no-console
    console.error(error);
  } finally {
    setSubmitting(false);
  }
};

export const ajaxCallWIthToken = (
  url,
  options = {},
  useBackendApi = false,
  useCommonApi = false,
) => async (dispatch, getState) => {
  return dispatch(refreshToken()).then(async () => {
    const state = getState();
    const token = getAccessToken(state);

    const [error, response] = await to(
      userService.fetchApiWithToken(
        url,
        options,
        token,
        useBackendApi,
        useCommonApi,
      ),
    );

    /* istanbul ignore if */
    if (error) {
      return Promise.reject(error);
    }
    return response;
  });
};

export const userActions = {
  login,
  logOut,
  signup,
  resendConfirmationEmail,
  getProfile,
  updateProfile,
};

import PropTypes from 'prop-types';
import DOMPurify from 'isomorphic-dompurify';
import Markdown from 'markdown-to-jsx';

import { setIsLoadingNextComponent } from 'app/shared/modules/pageTemplate/actions';

import { logger } from './logger';
import { trackAction } from './tracking';

/**
 * @typedef {import('app/types/style.types').TBreakpoint} TBreakpoint
 */

export const DEFAULT_PHONE_PREFIX = '+49';
const mobileRegex = /(?:phone|windows\s+phone|ipod|blackberry|(?:android|bb\d+|meego|silk|googlebot) .+? mobile|palm|windows\s+ce|opera mini|avantgo|mobilesafari|docomo)/i;
const tabletRegex = /(?:ipad|playbook|(?:android|bb\d+|meego|silk)(?! .+? mobile))/i;

export const createGUID = () => {
  const s4 = () => {
    return Math.floor((1 + Math.random()) * 0x10000)
      .toString(16)
      .substring(1);
  };

  return `${s4()}${s4()}${s4()}${s4()}${s4()}${s4()}${s4()}${s4()}`;
};

export const getRandomIntegerBetween = (min = 0, max = 5) => {
  return Math.floor(Math.random() * (max - min + 1)) + min;
};

export const chunkList = (list, chunkSize) => {
  return list
    .map((_, idx) => {
      return idx % chunkSize === 0 ? list.slice(idx, idx + chunkSize) : null;
    })
    .filter((item) => {
      return item;
    });
};

export const monthsDiff = (d1, d2) => {
  let months = (d2.getFullYear() - d1.getFullYear()) * 12;

  months -= d1.getMonth();
  months += d2.getMonth();
  return months <= 0 ? 0 : months;
};

export const closestNumber = (numbers, number, roundUp = false) => {
  // eslint-disable-next-line no-param-reassign
  number = roundUp ? Math.round(number) + 1 : number;
  return numbers.reduce((a, b) => {
    return Math.abs(b - number) < Math.abs(a - number)
      ? Math.abs(b)
      : Math.abs(a);
  });
};

export const isMsBrowser = () => {
  const ua = window.navigator.userAgent;
  const trident = ua.indexOf('Trident/');
  const edge = ua.indexOf('Edge/');

  return [trident, edge].some((browser) => browser > 0);
};

export const isFirefoxBrowser = () => {
  const ua = window.navigator.userAgent.toLowerCase();
  return ua.includes('firefox');
};

export const isSafariBrowser = () => {
  const ua = window.navigator.userAgent.toLowerCase();
  return /^((?!chrome|android).)*safari/i.test(ua);
};

export const isDesktopSafari = () => {
  const ua = window.navigator.userAgent.toLowerCase();
  return /^((?!chrome|android|ipad|iphone).)*safari/i.test(ua);
};

// Utility to detect device server side with regex
export const detectDevice = (userAgent) => {
  if (mobileRegex.test(userAgent)) {
    return 'mobile';
  }
  if (tabletRegex.test(userAgent)) {
    return 'tablet';
  }
  return 'desktop';
};

export const isSIBrowser = () => {
  const ua = window.navigator.userAgent.toLowerCase();
  return ua.includes('samsungbrowser');
};

export const isAndroidDevice = () => {
  const ua = window.navigator.userAgent.toLowerCase();
  return ua.includes('android');
};

export const isIOSDevice = () => {
  return (
    [
      'iPad Simulator',
      'iPhone Simulator',
      'iPod Simulator',
      'iPad',
      'iPhone',
      'iPod',
    ].includes(navigator.platform) ||
    // iPad on iOS 13 detection
    (navigator.userAgent.includes('Mac') && 'ontouchend' in document)
  );
};

export const isIOSIphone = () => {
  return ['iPhone Simulator', 'iPhone'].includes(navigator.platform);
};

/**
 * @param {TBreakpoint} breakpoint
 * @returns {boolean}
 */
export const isMobileViewport = (breakpoint) => ['sm'].includes(breakpoint);

/**
 * @param {TBreakpoint} breakpoint
 * @returns {boolean}
 */
export const isTabletViewport = (breakpoint) => breakpoint === 'md';

/**
 * @param {TBreakpoint} breakpoint
 * @returns {boolean}
 */
export const isLargeViewport = (breakpoint) => breakpoint === 'lg';

/**
 * @param {TBreakpoint} breakpoint
 * @returns {boolean}
 */
export const isMobileOrTabletViewport = (breakpoint) =>
  ['sm', 'md'].includes(breakpoint);

/**
 * @param {TBreakpoint} breakpoint
 * @returns {boolean}
 */
export const isMobileTabletOrDesktop = (breakpoint) =>
  ['sm', 'md', 'lg'].includes(breakpoint);

/**
 * @param {TBreakpoint} breakpoint
 * @returns {boolean}
 */
export const isDesktopViewport = (breakpoint) => breakpoint === 'xl';

export const recursiveParse = (json) => {
  let parsed;

  try {
    parsed = JSON.parse(json);
  } catch (error) {
    return json;
  }

  if (!parsed) {
    return parsed;
  }
  Object.keys(parsed).forEach((key) => {
    if (typeof parsed[key] === 'string') {
      try {
        parsed[key] = recursiveParse(parsed[key]);
      } catch (error) {
        // ignore parsing error, assume variable is supposed to be a string
      }
    }
  });
  return parsed;
};

export const getCookiesContainingKey = (
  cookieString,
  cookieKey,
  allowPrefix,
) => {
  return cookieString
    .split(';')
    .map((cookiePart) => {
      const parts = cookiePart.trim().split('=');

      return {
        [parts[0]]: parts[1],
      };
    })
    .filter((cookie) => {
      const key = Object.keys(cookie)[0];

      if (allowPrefix) {
        return key.indexOf(cookieKey) > -1;
      }
      return key === cookieKey;
    });
};

export const getCookiesNotContainingKey = (cookieString, allowedKey) => {
  return cookieString
    .split(';')
    .map((cookiePart) => {
      const parts = cookiePart.trim().split('=');

      return {
        [parts[0]]: parts[1],
      };
    })
    .filter((cookie) => {
      const key = Object.keys(cookie)[0].toLowerCase();

      return key.indexOf(allowedKey.toLowerCase()) === -1;
    });
};

export const generateSliderOption = (item) => {
  const label = item;
  const value = parseInt(item, 10);

  return {
    label: label.toString(),
    // eslint-disable-next-line no-restricted-globals
    value: isNaN(value) ? item : value,
  };
};

/**
 * String to Integer Converter
 *
 * @export
 * @param {string} str String Value
 * @returns {number} Converted Value
 */
export function stringToInt(str) {
  return parseInt(str, 10);
}

/**
 * Cookie Support
 *
 * @export
 * @param {*} [cookieStorage={}] Cookie Storage from 'redux-persist-cookie-storage'
 * @returns {boolean} Cookie Support
 */
export function isCookieSupported(cookieStorage = {}) {
  // It's needed by Redux Cookie Plugin
  if (cookieStorage.cookies) {
    return true;
  }

  /* istanbul ignore next */
  try {
    const key = 'cookiestoragesupport';
    // Create cookie

    document.cookie = `${key}=1`;
    const ret = document.cookie.indexOf(`${key}=`) !== -1;
    // Delete cookie

    document.cookie = `${key}=1; expires=Thu, 01-Jan-1970 00:00:01 GMT`;
    return ret;
  } catch (error) {
    return false;
  }
}

/* eslint-disable-next-line consistent-return */
export const trackingFlagAction = ({ payload }) => {
  if (!Array.isArray(payload)) {
    return null;
  }

  payload
    .filter(({ flagToken }) => flagToken)
    .forEach(({ flagToken, flagID }) =>
      trackAction(
        'active_experiment',
        {
          label: flagToken,
          value: flagID,
        },
        {
          nonInteraction: 1,
          variant_name: flagToken,
          experiment_id: flagID,
        },
      ),
    );
};

export const validateEmail = (email) => {
  /* eslint-disable-next-line unicorn/regex-shorthand,no-useless-escape */
  const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  return re.test(String(email).trim().toLowerCase());
};

/**
 * Transforms a flat object into an array of objects
 * @param {Object} flatObject - the object that should be turned into an object array
 * @returns {Object[]} - an object of the form {tag: 'name', value: 'value'}
 */
export const convertFlatObjectToTagValues = (flatObject = {}) => {
  return Object.keys(flatObject).map((key) => {
    return {
      tag: key,
      value: flatObject[key],
    };
  });
};

export const cleanPageProperties = (pageProperties) => {
  const pagePropClone = { ...pageProperties };
  const { url } = pagePropClone;

  const disallowedSearchParams = [
    'cid',
    'utm_medium',
    'agid',
    'adid',
    'kw',
    'mt',
    'utm_source',
    'gclid',
  ];
  const seperateTagValues = ['cid', 'agid', 'gclid', 'adid'];

  if (url) {
    const paramsToDelete = [];
    const urlObject = new URL(url);

    // eslint-disable-next-line no-restricted-syntax
    for (const param of urlObject.searchParams.entries()) {
      const name = param[0];
      /* istanbul ignore next */
      const value = param.length > 1 ? param[1] : null;

      /* istanbul ignore next */
      if (disallowedSearchParams.indexOf(name) > -1) {
        paramsToDelete.push(name);
      }

      if (seperateTagValues.indexOf(name) > -1) {
        pagePropClone[name] = value;
      }
    }
    paramsToDelete.forEach((name) => urlObject.searchParams.delete(name));
    pagePropClone.url = urlObject.toString();
  }

  return pagePropClone;
};

export const replaceNbsps = (str) => {
  const re = new RegExp(String.fromCharCode(160), 'g');

  return str.replace(re, ' ');
};

/**
 * @description Scroll the page to ID tag
 * @export
 * @param {string} id ID Tag value
 * @param {number} [time=100] optional - time delay before scrolling
 * @returns {object} setTimeout
 */
export function scrollToId(id, time = 100, offset = 0) {
  return setTimeout(() => {
    const htmlEl = document.querySelector(`#${id}`);
    const winScrollY = window.scrollY || window.pageYOffset;

    if (htmlEl) {
      const scrollY =
        htmlEl.getBoundingClientRect().top - (offset || 60) + winScrollY;

      window.scroll({
        top: scrollY,
        left: 0,
        behavior: 'smooth',
      });
    }
  }, time);
}

/**
 * @description Scroll the page to ID tag by URL Hash data
 * @export
 * @param {number} [time=undefined] optional - time delay before scrolling
 * @returns {object|null} result
 */
export function scrollToHashUrl(time = undefined) {
  if (!window || !window.location.hash) return null;

  const hash = window.location.hash.substr(1);
  return scrollToId(hash, time);
}

export const ChildrenShape = PropTypes.oneOfType([
  PropTypes.arrayOf(PropTypes.node),
  PropTypes.node,
]);

export const getEnvironmentValue = (ENV_NAME, fallBack) => {
  let envValue = fallBack;
  if (
    typeof process !== 'undefined' &&
    !process.browser &&
    process.env[ENV_NAME] !== undefined
  ) {
    envValue = process.env[ENV_NAME];
  }

  if (typeof window !== 'undefined' && window[ENV_NAME]) {
    envValue = window[ENV_NAME];
  }

  return envValue;
};

const errorCodeStartsWith5Regex = /^(5)(\d{2})$/;
export const isServerError = (errorCode) => {
  return errorCodeStartsWith5Regex.test(errorCode);
};

/**
 * @description returns an Effect which instructs the middleware
 * to call a given function with the given arguments.
 *
 *
 * ( useful to replace the 'delay' function from
 * 'redux-saga' when needed to test the sagas files )
 */
export const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

/**
 * A function that returns a function that loads a component
 * @param exportName - the name of the exported component inside of the file you are importing
 * @param componentImportFunction - the function to actually import the file, this can not be abstracted here because of the way webpack does dynamic imports
 * @return {Function} - loads a route and handles the changes on redux/pagetemplate
 */
export const getAsyncRouteComponentLoader = (
  exportName,
  componentImportFunction,
) => {
  if (typeof exportName !== 'string') {
    logger.error(
      `ExportName can't be ${typeof exportName}.`,
      'You have to pass in the export name of the component you are importing',
    );
    throw new TypeError(`ExportName can't be ${typeof exportName}.`);
  }

  if (typeof componentImportFunction !== 'function') {
    logger.error(
      `importFn can't be ${typeof exportName}.`,
      'you have to pass in a function that loads the component',
    );
    throw new TypeError(`importFn can't be ${typeof exportName}.`);
  }

  const routeNames = { [exportName]: false };

  return (_, cb) => {
    const hasWindowObject = typeof window !== 'undefined';
    const routeWasLoaded = routeNames[exportName];

    if (
      hasWindowObject && // we need to be on the client
      !routeWasLoaded && // and the route cannot be loaded from the client already
      !window._WAS_RENDERED_ON_SERVER_ // and we cannot come from a server side render
    ) {
      window.store.dispatch(setIsLoadingNextComponent(true));
    }

    return componentImportFunction().then((c) => {
      // eslint-disable-next-line promise/always-return
      if (
        hasWindowObject && // we need to be on the client
        !routeWasLoaded // and the route cannot be loaded from the client already
      ) {
        window.store.dispatch(setIsLoadingNextComponent(false));
      }
      routeNames[exportName] = true;
      cb(null, c[exportName]);
    });
  };
};

/**
 * Function used to fill out default props
 */
export const noop = () => {};

/**
 * Get path namespace; like magazine from /magazine/(...)
 * @param p {string} the path element
 * @returns {string} the split name or '/' in case of home-page
 */
export const getNameFromRoute = (p) => (p === '/' ? '/' : p.split('/')[1]);

export const hasSpecialOffersTopBanner = (pathname, isHidden) => {
  if (isHidden) return false;

  /** @type {[]} */
  const pagesWithTopBanner = ['/vehicle'];

  /** @type {[]} */
  const list = pagesWithTopBanner.reduce((arr, page) => {
    arr.push(getNameFromRoute(page));
    return arr;
  }, []);

  /** @type {boolean} */
  const isInTheList = !!(
    pathname &&
    !pathname.includes('quickview') &&
    list.indexOf(getNameFromRoute(pathname)) > -1
  );

  return isInTheList;
};

/**
 * Checks if page should render the Slide-in Banner
 * @param {string} pathname
 * @param {boolean} isHidden
 * @param {array<string>} pages
 * @returns {{show: boolean, threshold: number}}
 */
export const hasSlideIn = (pathname, isHidden, pages) => {
  /** @type {[]} */
  const list = pages.reduce((arr, page) => {
    arr.push(getNameFromRoute(page));
    return arr;
  }, []);

  /** @type {boolean} */
  const isInTheList = !!(
    pathname &&
    !pathname.includes('quickview') &&
    list.indexOf(getNameFromRoute(pathname)) > -1
  );
  return {
    show: !isHidden && isInTheList,
    threshold: 0.05,
  };
};

/**
 * Define routes that should not have either the search field or the favourites
 * link on the top navbar.
 * @param {String} pathname The router pathname
 * @param {Array<string>} pages The list of routes
 * @param {Boolean} isHidden To forcefully hide it
 * @returns {Boolean}
 */
export const hasSimplerNavbar = (pathname, pages = [], isHidden) => {
  const list =
    pages &&
    pages.reduce((arr, page) => {
      arr.push(getNameFromRoute(page));
      return arr;
    }, []);

  const isInTheList = !!(
    pathname &&
    list &&
    list.indexOf(getNameFromRoute(pathname)) > -1
  );

  return isHidden || isInTheList;
};

/**
 * true if error is DOMException('The user aborted a request.', 'AbortError')
 * @param {any} error
 * @returns {boolean}
 */
export const isAbortError = (error) => {
  return (
    error.name === 'AbortError' ||
    error.message === 'The user aborted a request.'
  );
};

/**
 * safely inject inner html
 * @param {string | null} content
 */
export function getSanitizedInnerHTML(content) {
  return {
    __html: DOMPurify.sanitize(content, {
      ALLOWED_ATTR: ['itemprop', 'itemtype', 'itemscope'],
    }),
  };
}
/**
 * @description Calculates distance between 2 geo locations
 * @export
 * @param {number} lat1 lat coordinate of first point
 * @param {number} lon1 lon coordinate of first point
 * @param {number} lat2 lat coordinate of second point
 * @param {number} lon2 lon coordinate of second point
 * @returns {number|null}
 */
export const calculateGeoDistance = ({ lat1, lon1, lat2, lon2 }) => {
  if (!lat1 || !lon1 || !lat2 || !lon2) {
    return null;
  }

  const R = 6371; // conversion rate in km
  const ratio = 3.1415 / 180; // (PI / 180)
  const f1 = lat1 * ratio;
  const f2 = lat2 * ratio;
  const df = (lat2 - lat1) * ratio;
  const lambda = (lon2 - lon1) * ratio;

  const a =
    Math.sin(df / 2) * Math.sin(df / 2) +
    Math.cos(f1) * Math.cos(f2) * Math.sin(lambda / 2) * Math.sin(lambda / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

  return Math.round(R * c); // in km
};

/* eslint-disable consistent-return */

/**
 * Set cookie by key and value if on client and cookies were accepted on cookie dashboard
 * @param {string} key The cookie key
 * @param {string} value The cookie value
 * @param {?boolean} [areCookiesAccepted] Did the user accept cookies?
 * @param {?number} [maxAge] Cookie max-age; default is 7 days
 * @param {string} [path] Path in which the cookie belongs to
 * @param {boolean} [isClient] Overwrites CLIENT global variable; for testing purposes
 * @returns {*} The cookie value or null
 */
export const setCookie = (
  key,
  value,
  areCookiesAccepted,
  maxAge = 86400 * 7,
  path,
  isClient = CLIENT,
) => {
  if (isClient && areCookiesAccepted) {
    document.cookie = `${key}=${value}; max-age=${maxAge};${
      path && ` path=${path}`
    }`;
    return value;
  }
};

/**
 * Get cookie by key if on client and cookies were accepted on cookie dashboard
 * @param {string} key The cookie key
 * @param {?boolean} [areCookiesAccepted] Did the user accept cookies?
 * @param {boolean} [isClient] Overwrites CLIENT global variable; for testing purposes
 * @returns {*} The cookie value or null
 */
export const getCookie = (key, areCookiesAccepted, isClient = CLIENT) => {
  if (isClient && areCookiesAccepted) {
    const cookies = document.cookie
      .split('; ')
      .find((row) => row.startsWith(key));
    return cookies && cookies.split('=')[1];
  }
};

/**
 * Delete cookie by key if on client
 * @param {string} key The cookie key
 * @param {string} [path] Path in which the cookie belongs to. [MAY BE REQUIRED BY SOME BROWSERS IN ORDER TO DELETE IT (source: https://www.w3schools.com/js/js_cookies.asp)]
 * @param {boolean} [isClient] Overwrites CLIENT global variable; for testing purposes
 */
export const deleteCookie = (key, path, isClient = CLIENT) => {
  /* istanbul ignore else */
  if (isClient) {
    document.cookie = `${key}=; expires=Thu, 01 Jan 1970 00:00:01 GMT;${
      path && ` path=${path}`
    }`;
  }
};

export const isInViewport = (element) => {
  const rect = element.getBoundingClientRect();
  return (
    rect.top >= 0 &&
    rect.bottom <= (window.innerHeight || document.documentElement.clientHeight)
  );
};

export const isAnyPartOfElementInViewPort = (element) => {
  const rect = element.getBoundingClientRect();
  const windowHeight =
    window.innerHeight || document.documentElement.clientHeight;
  const windowWidth = window.innerWidth || document.documentElement.clientWidth;

  const vertInView = rect.top <= windowHeight && rect.top + rect.height >= 0;
  const horInView = rect.left <= windowWidth && rect.left + rect.width >= 0;

  return vertInView && horInView;
};
/* eslint-enable consistent-return */

/* TODO: Add coverage to the the next funcs */
/* TODO: Move next func to contentful/utils instead */
/**
 * Get a Contentful file path from a file field object
 *
 * @param {object} file The file field object
 * @returns {string|Array<string>}
 */
export const getContentfulFilePath = (file) => file.fields.file.url;

/* TODO: Move next func to contentful/utils instead */
/**
 * Map Contentful's field containing multiple image objects to file path strings with a
 * fallback image
 *
 * @param {...object} sm The sm breakpoint
 * @param {...object} md The sm breakpoint
 * @param {...object} lg The sm breakpoint
 * @param {...object} xl The xl breakpoint
 * @param {string | object} fallBack
 * @returns {Record<TBreakpoint, string>} The file paths mapped by breakpoints
 */
export const mapContentfulMultipleImagesFilePath = (
  { sm, md, lg, xl },
  fallBack = '',
) => {
  return {
    // Set fallback image if user didn't add images to the entry on Contentful
    sm: sm
      ? sm.map((image) => getContentfulFilePath(image))
      : fallBack.sm || fallBack,
    md: md
      ? md.map((image) => getContentfulFilePath(image))
      : fallBack.md || fallBack,
    lg: lg
      ? lg.map((image) => getContentfulFilePath(image))
      : fallBack.lg || fallBack,
    xl: xl
      ? xl.map((image) => getContentfulFilePath(image))
      : fallBack.xl || fallBack,
  };
};

/**
 * Trim Text with a character limit
 * @param {String} text The Text to be trimmed
 * @param {Number} limit The limit number of characters
 * @returns {String} The trimmed text
 */
export const getTrimmedText = (text, limit = 150) =>
  text?.length > limit ? text?.substring(0, limit).concat('...') : text;

export const returnLastValidValueFromArray = (array, index) => {
  if (index < 0) return undefined;
  return array[index] || returnLastValidValueFromArray(array, index - 1);
};

export function getMarkDownContent(content) {
  return (
    <Markdown>
      {getSanitizedInnerHTML(content).__html.replace(/&amp;/g, '&')}
    </Markdown>
  );
}

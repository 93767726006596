/* istanbul ignore file */
export const RECAPTCHA_SITE_TOKEN_V2 = PRODUCTION
  ? '6LdLkigaAAAAANoBovvOkF4yQbhz_I9BGkqEPWi9'
  : '6LfdzCUaAAAAAHaDqotxth6CbPaoQzLq5fWQCE6p';

export const RECAPTCHA_SITE_TOKEN_V3 = PRODUCTION
  ? '6Le-ZLoZAAAAAKo1T2L9M6h_mjLTyoLf4f9_MIiL'
  : '6LdUZboZAAAAAFFLrS4JOqWrK99E81dN2QAMcNtM';

export const TAG_ID = 'recaptcha-script';

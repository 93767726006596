import styled from 'styled-components';
import { zIndex } from '../../styles_js/variables';
import { Durations } from './constants';

const getDuration = props =>
  props.isSlidingIn ? Durations.SLIDE_IN : Durations.SLIDE_OUT;

export const InfoNotificationSt = styled.div`
  position: fixed;
  z-index: ${zIndex.notificationBar + 20};
  left: 0;
  right: 0;
  top: ${props => (props.isSlidingIn ? 0 : '-64px')};
  transition: ${getDuration}ms top;
`;

// NOTE: `position:fixed` and `transform` cannot be used together in chrome,
// therefore we use `top`
export const ActionNotificationSt = styled.div`
  position: fixed;
  z-index: ${zIndex.notificationBar - 1};
  left: 0;
  right: 0;
  top: ${props => (props.isSlidingIn ? '64px' : '-64px')};
  transition: ${getDuration}ms top;
`;

export const ActionNotificationGhostElementSt = styled.div`
  height: ${props => (props.isSlidingIn ? '128px' : 0)};
  transition: ${getDuration}ms height;
`;

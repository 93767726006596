import React from 'react';

import {
  ErrorBoundarySt,
  ErrorIconSt,
  ErrorMessageSt,
} from './ErrorBoundary.css';

export const ErrorFallback = (): JSX.Element => (
  <ErrorBoundarySt>
    <ErrorIconSt>
      <i className="if-icon-tools" />
    </ErrorIconSt>
    <ErrorMessageSt>Leider ist ein Fehler aufgetreten.</ErrorMessageSt>
  </ErrorBoundarySt>
);

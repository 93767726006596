import PropTypes from 'prop-types';

import { Icon } from 'app/shared/ui';

import { CloseButtonSt } from './CloseButton.css';

export const CloseButton = (props) => (
  <CloseButtonSt ariaLabel="Schließen" {...props}>
    <Icon
      icon="close"
      iconSize="xsmall"
      color={props.color}
      aria-hidden="true"
    />
  </CloseButtonSt>
);

CloseButton.propTypes = {
  color: PropTypes.string,
};

CloseButton.defaultProps = {
  color: 'white',
};

import { ActionTypes } from './constants';

const initialState = {
  vehicles: [],
};

export const carComparison = (state = initialState, action) => {
  const { payload } = action;

  switch (action.type) {
    case ActionTypes.ADD_CAR_TO_COMPARISON:
      return {
        ...state,
        vehicles: [...state.vehicles, ...payload],
      };
    case ActionTypes.REMOVE_CAR_FROM_COMPARISON:
      return {
        ...state,
        vehicles: state.vehicles.filter(vehicle => vehicle.id !== payload.id),
      };
    case 'RESET_CAR_COMPARISON':
      return {
        ...state,
        vehicles: [],
      };
    default:
      return state;
  }
};

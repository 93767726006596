import * as ActionTypes from './constants';

export const defaultState = () => {
  return {
    title: 'Gebrauchtwagen bei heycar!',
    description:
      'Du suchst einen Gebrauchtwagen? heycar bietet Dir eine große Auswahl an Gebrauchtwagen von Top Händlern in Deiner Nähe. Jetzt entdecken!',
    keywords: '',
    index: 'noindex,nofollow',
    h1: '',
    h2: '',
    h3: '',
    content: '',
    canonicalPath: '',
  };
};

export function seo(state = defaultState(), action) {
  const { type, payload } = action;

  if (type === ActionTypes.GET_METADATA_SUCCESS) {
    payload.h1 = payload.h1 || '';

    state = { ...state, ...payload };
  }

  if (type === ActionTypes.GET_METADATA_FAILURE) {
    state = { ...state, ...defaultState() };
  }

  if (type === ActionTypes.GET_METADATA_CANCELLED) {
    state = { ...state, ...defaultState() };
  }

  if (type === ActionTypes.GET_METADATA_REQUEST) {
    state = defaultState();
  }

  return state;
}

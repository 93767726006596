import { userInterestsForLeadCreation } from './constants';

/**
 * @description defines if the user is interested in financing and/or trade-in
 *
 * @param {object} calculationData (financingInterest) user's selected financing settings
 * @param {object} valuatedVehicle (tradeInInterest)  user's valuated vehicle for trade-in
 */
export function getUserInterestsForLeadCreation(
  calculationData,
  valuatedVehicle,
) {
  const {
    financingAndTradeIn,
    tradeIn,
    financing,
    none,
  } = userInterestsForLeadCreation;

  if (valuatedVehicle && calculationData) {
    return financingAndTradeIn;
  }

  if (valuatedVehicle) {
    return tradeIn;
  }

  if (calculationData) {
    return financing;
  }

  return none;
}

import { Box, Flex } from '@rebass/grid';

import { Text, Text1, Icon, LinkButton } from 'app/shared/ui';
import { trackAction } from 'app/shared/utils/tracking';

const ADVANTAGES_LIST = [
  'Top-Preise durch Verkauf an Meistbietenden',
  'Stressfrei inkl. kostenloser Abholung',
  'Verkauf nur an geprüfte Händler',
];

export const Carsale24EntryPointPanel = (): JSX.Element => {
  const primaryCTATracking = () => {
    trackAction('carsale24_entrypoint_click', {
      category: 'HP',
      label: 'carsale24_entrypoint_HP',
      value: 'entry_point_box',
      description: 'user clicks on carsale24 entry point on the home page',
    });
  };

  return (
    <Box>
      <Box mx={[16, 16, 32]} mt={24} mb={[24, 16, 24, 32]}>
        <Text1 fontSize={14} lineHeight="24px" textAlign="center">
          Inseriere dein Fahrzeug kostenlos von zu Hause aus und erhalte
          Angebote ausgesuchter Händler.
        </Text1>
      </Box>
      <Box px={[12, 24, 32]} mb={[32, 24, 24, 32]}>
        {ADVANTAGES_LIST.map((listItem, index) => (
          <Flex key={`advantage-${index}`} mb={16}>
            <Icon
              icon="check"
              fontSize={16}
              color="micaBlue"
              mr={16}
              mt="6px"
            />
            <Text fontSize={14} lineHeight="24px" fontWeight="bold">
              {listItem}
            </Text>
          </Flex>
        ))}
      </Box>
      <Flex mx={[12, 16, 32]} mb={24}>
        <LinkButton
          variant="primary"
          width={1}
          to="/verkaufen?customData=home_page"
          onClick={primaryCTATracking}
        >
          Mein Auto verkaufen
        </LinkButton>
      </Flex>
    </Box>
  );
};

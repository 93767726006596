import { createSelector } from 'reselect';

export const getFilterStateSelector = createSelector(
  (state) => {
    return state.filter;
  },
  (filters) => filters,
);

export const performanceRangeSwitchFilterSelector = (state) =>
  state.filter.performanceRangeSwitch;

export const performanceRangeValueFilterSelector = (state) =>
  state.filter.performanceRangeSwitch[0]?.value;

export const budgetRangeSwitchFilterSelector = (state) =>
  state.filter.budgetRangeSwitch[0]?.value;

import * as ActionTypes from './constants';

const defaultState = () => {
  return {
    guid: null,
    locale: null,
    branchesCtaActive: true
  };
};

export function tracking(state = defaultState(), action) {
  const { type, payload } = action;

  if (type === ActionTypes.SET_GUID) {
    return Object.assign({}, state, { guid: payload });
  }
  if (type === ActionTypes.HIDE_BRANCHES_CTA) {
    return Object.assign({}, state, { branchesCtaActive: false });
  }

  return state;
}

import { Box } from '@rebass/grid';
import styled from 'styled-components';

export const CloseButtonSt = styled(Box).attrs(props => ({
  as: 'button',
  type: 'button',
  'aria-label': props.ariaLabel,
}))`
  padding: 0;
  border: none;
  background-color: transparent;
  color: ${/* istanbul ignore next */ ({ type, theme: { colors } }) =>
    type === 'success' ? colors.micaBlue : colors.white};
  cursor: pointer;
`;

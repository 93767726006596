import { memo, useMemo, useState } from 'react';
import { Icon } from 'app/shared/ui';
import PropTypes from 'prop-types';

import { useStyledTheme } from 'app/shared/hooks/useStyledTheme';
import { CartileLoadingStyle } from 'app/shared/modules/Cartile/CartileUIComponents/CartileUIComponents.css';
import {
  CartileFavouriteButtonIconButtonSt,
  FavouriteButtonAnimationSt,
} from 'app/shared/modules/Cartile/CartileUIComponents/CartileFavouriteButton/CartileFavouriteButton.css';
import { useHasMounted } from 'app/shared/hooks/useHasMounted';

const CartileFavouriteButtonCmp = ({
  isLoading,
  isFavourite,
  handleToggleFavourites,
}) => {
  const hasMounted = useHasMounted();
  const theme = useStyledTheme();
  const [showAnimFavIcon, setShowAnimFavIcon] = useState(false);
  const handleFavClick = (e) => {
    e.preventDefault();
    e.stopPropagation();
    handleToggleFavourites();
    /* istanbul ignore next */
    if (!isFavourite) {
      setShowAnimFavIcon(true);
      setTimeout(() => {
        setShowAnimFavIcon(false);
      }, 800);
    }
  };
  const iconColor = useMemo(() => {
    const {
      colors: { brandPrimary, brandSecondaryLight },
    } = theme;
    return isFavourite ? brandPrimary : brandSecondaryLight;
  }, [isFavourite, theme]);

  return (
    <CartileFavouriteButtonIconButtonSt
      css={[isLoading && CartileLoadingStyle]}
      onClick={handleFavClick}
      isFavourite={isFavourite}
    >
      {hasMounted && (
        <Icon
          color={iconColor}
          icon={`favorite-${
            /* istanbul ignore next */ isFavourite ? 'active' : 'inactive'
          }`}
          fontSize={24}
          mt="4px"
        />
      )}
      {showAnimFavIcon && (
        <FavouriteButtonAnimationSt className="if-icon-favorite-active" />
      )}
    </CartileFavouriteButtonIconButtonSt>
  );
};

CartileFavouriteButtonCmp.propTypes = {
  isLoading: PropTypes.bool,
  isFavourite: PropTypes.bool,
  handleToggleFavourites: PropTypes.func.isRequired,
};

CartileFavouriteButtonCmp.defaultProps = {
  isLoading: false,
  isFavourite: false,
};

export const CartileFavouriteButton = memo(CartileFavouriteButtonCmp);

import * as ActionTypes from './constants';

export const defaultState = () => {
  return {
    email: '',
    firstName: '',
    lastName: '',
    phoneNumber: '',
    hasTradeInCar: false,
    brandModel: '',
    kilometer: '',
    registration: '',
  };
};

export function contactDetails(state = defaultState(), action) {
  const { type, payload } = action;

  if (type === ActionTypes.RESET_LEAD_FORM) {
    return defaultState();
  }

  if (type === ActionTypes.STORE_LEAD_FORM) {
    const {
      firstName,
      lastName,
      email,
      phoneNumber,
      hasTradeInCar,
      brandModel,
      kilometer,
      registration,
    } = payload;
    return {
      ...state,
      ...(firstName && { firstName }),
      ...(lastName && { lastName }),
      ...(email && { email }),
      ...(phoneNumber && { phoneNumber }),
      ...(hasTradeInCar && { hasTradeInCar }),
      ...(brandModel && { brandModel }),
      ...(kilometer && { kilometer }),
      ...(registration && { registration }),
    };
  }

  return state;
}

/* istanbul ignore file */
import { createSelector } from 'reselect';

import { getPreviousLocation } from 'app/selectors';

import { PAGE_HOME, PAGE_LISTINGS, PAGE_VEHICLE } from '../constants';

export const getPdpVehicle = (state) => {
  const { PDPvehicle } = state.tradeIn;
  return PDPvehicle;
};

export const getPrevStep = (state) => {
  const step = state.tradeIn.steps[state.tradeIn.step];
  return step || {};
};

export const getScreens = (state) => {
  const { screens } = state.tradeIn;
  return screens || [];
};

export const getStepPayload = (state) => {
  const step = state.tradeIn.steps[state.tradeIn.step];
  return (step && step.payload && step.payload) || {};
};

export const getPrevStepPayload = (state) => {
  if (state.tradeIn.step <= 1) return {};
  const stepIndex = state.tradeIn.step - 1;
  const step = state.tradeIn.steps[stepIndex];
  return (step && step.payload && step.payload) || {};
};

export const getStepScreen = (state) => {
  const step = state.tradeIn.steps[state.tradeIn.step];
  return (step && step.screen) || 0;
};
export const getStep = (state) => {
  return state.tradeIn.step;
};

export const isReady = (state) => {
  return state.tradeIn.isReady;
};

export const getPayload = (state) => {
  if (state.tradeIn.step === 0) return {};
  const step = state.tradeIn.steps[state.tradeIn.step - 1];
  return step;
};

export const getData = (state) => {
  const step = state.tradeIn.steps[state.tradeIn.step];
  return step && step.data;
};

export const getConfig = (state) => {
  const step = state.tradeIn.steps[state.tradeIn.step];
  return step && step.config;
};

export const getRequest = (state) => {
  const step = state.tradeIn.steps[state.tradeIn.step];
  return step && step.request;
};

export const getPreviousPage = (state) => {
  const previousLocation = getPreviousLocation(state);

  if (previousLocation) {
    if (previousLocation === '/') {
      return PAGE_HOME;
    }

    if (previousLocation.includes('gebrauchtwagen')) {
      return PAGE_LISTINGS;
    }

    if (previousLocation.includes('vehicle')) {
      return PAGE_VEHICLE;
    }
  }

  return '';
};

export const getSurveyStatus = (state) => {
  const { showUserSurvey } = state.tradeIn;
  return showUserSurvey;
};

export const getValuatedVehicle = createSelector(
  ({ tradeIn }) => tradeIn.valuatedVehicle,
  (valuatedVehicle) => valuatedVehicle || {},
);

export const hasValuatedVehicle = ({ tradeIn }) => !!tradeIn.valuatedVehicle;

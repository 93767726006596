import { TFurnishingMapping } from 'app/marketplace/constants/extrasMappings.types';

export const comfortMapping: TFurnishingMapping = {
  parkingDistanceControlSystem: 'Parkassistent',
  ambientLight: 'Ambiente-Beleuchtung',
  parkingAssistantCamera: 'Einparkhilfe Kamera',
  parkingAssistantRearSensors: 'Einparkhilfe Sensoren hinten',
  parkingAssistantFrontSensors: 'Einparkhilfe Sensoren vorne',
  electricWindows: 'Elektrische Fensterheber',
  electricExteriorMirrors: 'Elektrische Seitenspiegel',
  headUpDisplay: 'Head-up-Display',
  climatisation: 'Klimatisierung',
  lightSensor: 'Lichtsensor',
  airSuspension: 'Luftfederung',
  panoramaRoof: 'Panoramadach',
  rainSensor: 'Regensensor',
  slidingDoor: 'Schiebetür',
  powerSteering: 'Servolenkung',
  electricAdjustableSeats: 'Elektrische Sitzeinstellung',
  seatHeating: 'Sitzheizung',
  cruiseControl: 'Tempomat',
  centralLockingSystem: 'Zentralverriegelung',
  allWheelDrive: 'Allradantrieb',
  auxiliaryHeating: 'Standheizung',
  parkingAssistantSelfSteeringSystem: 'Einparkhilfe selbstlenkendes System',
  roofRack: 'Dachreling',
  sunroof: 'Schiebedach',
  startStopSystem: 'Start-Stop-Automatik',
  manualClimatisation: 'Klimaanlage',
  automaticClimatisation: 'Klimaautomatik',
  dualZoneAutomaticClimateControl: '2-Zonen-Klimaautomatik',
  electricTrunkDoor: 'Elektronische Heckklappe',
  electricallyAdjustableBackSeats: 'Elektronische Sitzeinstellung (hinten)',
  heatedBackSeats: 'Sitzheizung hinten',
  heatedSteeringWheel: 'Beheizbares Lenkrad',
  leatherSteeringWheel: 'Lederlenkrad',
  luggageCompartmentSeparation: 'Gepäckraumabtrennung',
  lumbarSupport: 'Lordosenstütze',
  paddleShiftGearbox: 'Schaltwippen',
  quadrupleZoneAutomaticClimateControl: '4-Zonen-Klimaautomatik',
  seatMassageSystem: 'Massagesitz',
  slidingDoorLeft: 'Schiebetür links',
  slidingDoorRight: 'Schiebetür rechts',
  slidingDoorsBothSides: 'Schiebetür beidseitig',
  smartphoneWirelessCharging: 'Induktionsladen für Smartphones',
  smokersPackage: 'Raucherpaket',
  soundSystem: 'Soundsystem',
  threesixtyParkingCamera: '360°-Kamera',
  tripleZoneAutomaticClimateControl: '3-Zonen-Klimaautomatik',
  usb: 'USB',
  ventilatedSeats: 'Sitzbelüftung',
  virtualCockpit: 'Volldigitales Kombiinstrument',
  voiceControlSystem: 'Sprachsteuerung',
  wlan: 'WLAN',
};

export const safetyMapping: TFurnishingMapping = {
  abs: 'ABS',
  airbags: 'Airbags',
  esp: 'ESP',
  curveLights: 'Kurvenlicht',
  frontFogLights: 'Nebelscheinwerfer',
  daytimeRunningLights: 'Tagfahrlicht',
  xenonHeadlights: 'Xenonscheinwerfer',
  immobiliser: 'Wegfahrsperre',
  adaptedForDisabled: 'Behindertengerecht',
  childSeatAttachment: 'ISOFIX',
  adaptiveCruiseControl: 'Abstandstempomat',
  alarmSystem: 'Alarmanlage',
  allSeasonTires: 'Ganzjahresreifen',
  automaticInteriorMirror: 'Innenspiegel automatisch abbl.',
  biXenonLights: 'Bi-Xenon Scheinwerfer',
  deadAngleAssistant: 'Totwinkel-Assistent',
  demonstrationModel: 'Vorführwagen',
  emergencyBrakeAssist: 'Notbremsassistent',
  emergencyCallSystem: 'Notrufsystem',
  fatigueWarningSystem: 'Müdigkeitswarner',
  flatTireRepairKit: 'Pannenkit',
  frontPassengerIsofix: 'ISOFIX Beifahrer',
  headlightWasher: 'Scheinwerferreinigungsanlage',
  heatedWindshield: 'Beheizbare Frontscheibe',
  highBeamAssistant: 'Fernlichtassistent',
  hillStartAssist: 'Anfahrassistent',
  keylessGoSystem: 'Schlüssellose Zentralverriegelung',
  laneAssist: 'Spurhalteassistent',
  laserLights: 'Laserlicht Scheinwerfer',
  ledDaylightLights: 'LED-Tagfahrlicht',
  ledLights: 'LED-Scheinwerfer',
  matrixLedSpotlights: 'Blendfreies Fernlicht',
  nightViewAssistant: 'Nachtsicht-Assistent',
  normalSpareTire: 'Reserverad',
  snowTires: 'Winterreifen',
  speedLimitSystem: 'Geschwindigkeitsbegrenzungsanlage',
  summerTires: 'Sommerreifen',
  temporarySpareTire: 'Notrad',
  tirePressureControlSystem: 'Reifendruckkontrollsystem',
  tractionControlSystem: 'Traktionskontrolle',
  trafficSignRecognitionSystem: 'Verkehrszeichenerkennung',
  winterPackage: 'Winterpaket',
};

export const multimediaMapping: TFurnishingMapping = {
  bordcomputer: 'Bordcomputer',
  cdPlayer: 'CD',
  mp3Interface: 'MP3',
  multiFunctionSteeringWheel: 'Multifunktionslenkrad',
  navigationSystem: 'Navigationssystem',
  tv: 'TV',
  bluetooth: 'Bluetooth',
  speakerphone: 'Freisprechanlage',
  tuner: 'Radio',
  androidAuto: 'Android Auto',
  appleCarplay: 'Apple Carplay',
  dabRadio: 'DAB Radio',
  musicStreaming: 'Musikstreaming integriert',
  touchscreen: 'Touchscreen',
};

export const extrasMapping: TFurnishingMapping = {
  lightAlloyWheels: 'Alufelgen',
  trailerCoupling: 'Anhängerkupplung',
  skiBag: 'Skisack',
  sportPackage: 'Sportpaket',
  sportSeats: 'Sportsitze',
  performanceHandlingSystem: 'Sportfahrwerk',
  boughtElectricBattery: 'E-Fahrzeug Batterie (gekauft)',
  centralArmrest: 'Mittelarmlehne',
  companyCar: 'Firmenfahrzeug',
  dieselParticulateFilter: 'Partikelfilter',
  fixedTrailerHitch: 'Anhängerkupplung (starr)',
  foldingPassengerSeat: 'Umklappbarer Beifahrersitz',
  missingElectricBattery: 'E-Fahrzeug Batterie (fehlt)',
  rangeExtender: 'Range-Extender',
  removableTrailerHitch: 'Anhängerkupplung abnehmbar',
  rentedElectricBattery: 'E-Fahrzeug Batterie (gemietet)',
  retractableTrailerHitch: 'Anhängerkupplung schwenkbar',
  steelWheels: 'Stahlräder',
};

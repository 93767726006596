import { Route, Redirect } from 'react-router';

import { getAsyncRouteComponentLoader } from 'app/shared/utils/utils';
import { ConnectedPageTemplate } from 'app/shared/modules/pageTemplate/PageTemplate/ConnectedPageTemplate';

export const SearchRoutes = (
  <Route>
    <Route
      name="quick-clp"
      component={(props) => <ConnectedPageTemplate target="CLP" {...props} />}
    >
      <Route
        exact
        path="/landing/:id"
        getComponent={getAsyncRouteComponentLoader(
          'ConnectedQuickViewClp',
          () => import('./QuickViewClp/ConnectedQuickViewClp'),
        )}
      />
    </Route>

    <Route
      name="search"
      component={(props) => <ConnectedPageTemplate target="CLP" {...props} />}
    >
      <Route
        path="/search"
        getComponent={(loc, cb) => {
          return import('./Search/ConnectedSearch').then((c) =>
            cb(null, c.ConnectedSearch),
          );
        }}
      />

      <Route
        path="/gebrauchtwagen(/:make)(/:model)"
        getComponent={getAsyncRouteComponentLoader('ConnectedSearch', () =>
          import('./Search/ConnectedSearch'),
        )}
      />
    </Route>

    <Route
      name="detail-search"
      component={(props) => (
        <ConnectedPageTemplate target="detail-search" {...props} />
      )}
    >
      <Route
        path="/detailsuche"
        getComponent={(loc, cb) =>
          import(
            './DetailSearch/DetailSearch/ConnectedDetailSearch'
          ).then((c) => cb(null, c.ConnectedDetailSearch))
        }
      />
    </Route>
    {/* Temporary redirects for SEO testing - sync with SEO about the next step  */}
    <Redirect from="/leasing" to="/gebrauchtwagen?leasingRateRange=10-2000" />
    <Redirect
      from="/leasing/bmw"
      to="/gebrauchtwagen?leasingRateRange=10-2000&make=bmw&model=bmw_all&trim=bmw_all_all"
    />
  </Route>
);

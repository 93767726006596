import { Route } from 'react-router';

import { getAsyncRouteComponentLoader } from 'app/shared/utils/utils';

export const AutoBildRoutes = (
  <Route name="AutoBildLead">
    <Route
      path="/partners/autobild/lead/:vehicleId"
      getComponent={getAsyncRouteComponentLoader('ConnectedLeadFlow', () =>
        import('./LeadFlow/ConnectedLeadFlow'),
      )}
    />
  </Route>
);

import styled, { css } from 'styled-components';

import { height, media, zIndex } from '../../styles_js/variables';

const setPosition = (slideAnimation, position) => css`
  transform: translateX(${slideAnimation ? 0 : position})
    scale3d(${slideAnimation ? 1 : 0}, 1, 1);
  width: ${position};
`;

export const SideBarSt = styled.div`
  z-index: ${zIndex.cookieBanner + 1};
  top: 0;
  display: flex;
  flex-direction: column;
  transition: transform 0.2s ease-in-out;
  transform-origin: right;
  position: fixed;
  right: 0;
  height: 100%;

  ${(props) => setPosition(props.slideAnimation, '100vw')}
  ${media.md((props) => setPosition(props.slideAnimation, '320px'))}
  ${media.xl((props) => setPosition(props.slideAnimation, '400px'))}

  ${(props) => props.hasFooter && `padding-bottom: ${height.topNavBar}px`};

  ${media.md(css`
    box-shadow: rgba(0, 0, 0, 0.16) 0 3px 10px, rgba(0, 0, 0, 0.23) 0 3px 10px;
  `)};
`;

export const SideBarHeaderSt = styled.div`
  display: flex;
  height: ${height.topNavBar}px;
  align-items: center;
  background-color: ${({ theme: { colors } }) => colors.brandPrimary};
  color: ${({ theme: { colors } }) => colors.tarmacGrey};
  padding-left: 24px;
  padding-right: 24px;

  & > button {
    border: none;

    &:hover {
      color: ${({ theme: { colors } }) => colors.brandSecondaryLighter};
    }
  }
`;

export const SideBarContentSt = styled.div`
  width: inherit;
  flex: 1;
  background-color: #fff;
  -webkit-overflow-scrolling: touch;
  overflow-y: auto;
  overflow-x: hidden;
`;

export const SideBarFooterSt = styled.div`
  position: fixed;
  z-index: ${zIndex.cookieBanner + 1};
  right: 0;
  bottom: 0;
  width: 100%;
  height: ${height.topNavBar}px;
  transition: transform 0.2s ease-in-out;
  transform-origin: right;

  & > button {
    border-radius: 0;
    height: ${height.topNavBar}px;
    width: 100%;
  }

  ${(props) => setPosition(props.isOpen, '100vw')}
  ${media.md((props) => setPosition(props.isOpen, '320px'))}
  ${media.xl((props) => setPosition(props.isOpen, '400px'))}
`;

export const HeaderIconSt = styled.i`
  margin-left: auto;
  cursor: pointer;
  font-size: 16px;
  color: ${({ theme: { colors } }) => colors.brandSecondaryLight};

  &:hover {
    color: ${({ theme: { colors } }) => colors.brandSecondaryLighter};
  }
`;

import styled, { css } from 'styled-components';
import { Flex, Box } from '@rebass/grid';

import { GridContainer } from '../GridContainer';
import { media } from '../variables/media';

export const GridContainerSt = styled(GridContainer)`
  @media all and (max-width: 1024px) {
    max-width: calc(100% - 48px);
  }
`;

export const NavItemsWrapper = styled(Flex).attrs(() => ({
  as: 'ul',
}))`
  position: absolute;
  padding: 0;
  margin: 0;
  list-style: none;
  box-shadow: rgb(0 0 0 / 15%) 0px 15px 15px 0px;
  height: 100vh;
  left: 0px;
  pointer-events: all;
  top: 64px;
  transform: translateX(110vw);
  transition: transform 300ms ease-in-out 0s;
  width: 100%;
  will-change: transform;
  flex-direction: column;
  background-color: ${({ theme }) => theme.colors.white};

  ${media.lg(css`
    display: flex;
    height: 100%;
    justify-content: center;
    grid-column: 3 / span 8;
    flex-direction: row;
    opacity: 1;
    transform: translateX(0);
    transition: none;
    position: absolute;
    width: 100%;
    box-shadow: none;
    overflow: visible;
    top: 0;
    background-color: inherit;
  `)}
`;

export const NavigationWrapper = styled.nav`
  width: 100%;
  min-height: 64px;
  background: ${({ theme }) => theme.colors.brandPrimary};

  ${media.lg(css`
    padding: 0;
    position: static;
  `)}
  &.open {
    ${NavItemsWrapper} {
      transform: translateX(0px);
    }
  }
`;

export const DesktopWrapperSt = styled.div`
  display: none;

  ${media.lg(css`
    display: block;
  `)};
`;

export const MobileWrapperSt = styled.div`
  display: block;

  ${media.lg(css`
    display: none;
  `)};
`;

export const FavouriteIconSt = styled(Flex)`
  a {
    line-height: 0;
  }
`;

export const LogoWrapper = styled(Flex) <{ $zIndex: number }>`
  height: 64px;
  align-items: center;
  padding-left: 0;
  grid-column: 1 / span 4;
  position: relative;
  z-index: ${({ $zIndex }) => $zIndex};

  & > div {
    line-height: 0;
  }

  ${media.lg(css`
    grid-column: 1 / span 2;
  `)}
`;

export const IconsWrapper = styled(Flex)`
  height: 100%;
  align-items: center;
  justify-content: flex-end;
  grid-column: 10 / span 3;
  margin-right: -24px;

  ${media.lg(css`
    grid-column: 11 / span 2;
  `)};
`;

export const BoxSt = styled(Box) <{ $zIndex: number; isHidden: boolean }>`
  width: 100%;
  position: fixed;
  z-index: ${({ $zIndex }) => $zIndex};
  transform: ${({ isHidden }) =>
    isHidden ? /* istanbul ignore next */ 'translate3d(0, -64px, 0)' : null};
  transition: transform 0.3s linear;
`;

export const HamburgerWrapper = styled(Box)`
  margin-left: 8px;
  line-height: 1;
`;

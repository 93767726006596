import styled, { css } from 'styled-components';
import { Flex } from '@rebass/grid';

import { media } from 'app/shared/styles_js/variables';
import {
  CARTILE_CONTENT_VERTICAL_HEIGHT,
  CONTENT_CONTAINER_PADDING_VERTICAL,
  CONTENT_CONTAINER_PADDING_HORIZONTAL,
} from 'app/shared/modules/Cartile/CartileTemplates/CartileTemplates.constant';

export const CartileHorizontalContentContainerSt = styled(Flex).attrs(() => ({
  flexDirection: 'column',
  justifyContent: 'space-between',
  width: [1, 0],
}))`
  padding: ${CONTENT_CONTAINER_PADDING_VERTICAL}px;
  border-radius: 4px 4px 0 0;
  height: inherit;
  ${({ isUnavailable }) =>
    isUnavailable &&
    css`
      opacity: 0.4;
      pointer-events: none;
    `}
  ${media.md(
    css`
      height: 100%;
      flex: 1 0 auto;
      border-radius: 0 4px 0 4px;
      padding: ${CONTENT_CONTAINER_PADDING_HORIZONTAL}px;
    `,
  )};
  ${media.lg(
    css`
      padding-left: 32px;
    `,
  )};
`;

export const CartileVerticalContentContainerSt = styled(Flex).attrs(() => ({
  flexDirection: 'column',
  justifyContent: 'space-between',
}))`
  width: 100%;
  height: 100%;
  flex-grow: 1;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  padding: ${CONTENT_CONTAINER_PADDING_VERTICAL}px;
  ${media.md(
    css`
      height: ${CARTILE_CONTENT_VERTICAL_HEIGHT.md}px;
    `,
  )};
  ${media.lg(
    css`
      height: ${CARTILE_CONTENT_VERTICAL_HEIGHT.lg}px;
    `,
  )};
  ${media.xl(
    css`
      height: ${CARTILE_CONTENT_VERTICAL_HEIGHT.xl}px;
    `,
  )};
  ${({ isUnavailable }) =>
    isUnavailable &&
    css`
      opacity: 0.4;
      pointer-events: none;
    `}
`;

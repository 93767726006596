/* eslint-disable react/prop-types */
/* eslint-disable react/no-multi-comp */
import React from 'react';
import { Box } from '@rebass/grid';

import { TBreakpoint } from 'app/shared/utils/utils';
import { Button, Text } from 'app/shared/ui';
import { colorV2 } from 'app/shared/styles_js/variables';
import { LazyImage } from 'app/shared/components/LazyImage/LazyImage';
import { GridItem } from 'app/shared/modules/FDL/tradein/components/SectionContents/GridContainer';

import * as Styled from './CLPTeaser.css';

interface Ilink {
  url: string;
  label: string;
  isInternalLink: boolean;
}

interface ICLPTeaserProps {
  title: string;
  description: string;
  link: Ilink;
  onClick: () => void;
  imageUrl: string;
  breakpoint: TBreakpoint;
}

export const CLPTeaser = ({
  title,
  description,
  link,
  imageUrl,
  onClick,
  breakpoint,
}: ICLPTeaserProps): JSX.Element => {
  const renderTeaserContent = () => {
    return (
      <>
        {breakpoint !== 'sm' ? (
          <Styled.Grid>
            <GridItem
              columns={[1, 11, 10]}
              gridRow={1}
              py={24}
              px={[12, 0, 24]}
            >
              <Box>
                <Box pb={[8]}>
                  <Text fontWeight="bold">{title}</Text>
                </Box>

                <Box pb={[8]}>
                  <Text as="div" fontSize={14} lineHeight="20px">
                    {description}
                  </Text>
                </Box>

                <Button variant="tertiary" p={0}>
                  {link.label}
                </Button>
              </Box>
            </GridItem>

            {imageUrl && (
              <Styled.ImageContainer>
                <LazyImage
                  imageUrl={imageUrl}
                  alt="a blue volkswagen golf"
                  width="144px"
                  height="58px"
                />
              </Styled.ImageContainer>
            )}
          </Styled.Grid>
        ) : (
          <Styled.Wrapper>
            {imageUrl && (
              <Styled.ImageContainerSm>
                <LazyImage
                  imageUrl={imageUrl}
                  alt="a blue volkswagen golf"
                  width="144px"
                  height="58px"
                />
              </Styled.ImageContainerSm>
            )}
            <Box
              backgroundColor={colorV2.mustangLighter}
              paddingTop="24px"
              paddingBottom="12px"
            >
              <Text fontWeight="bold">{title}</Text>
              <Text as="div" fontSize={14} lineHeight="20px">
                {description}
              </Text>

              <Button variant="tertiary" p={0}>
                {link.label}
              </Button>
            </Box>
          </Styled.Wrapper>
        )}
      </>
    );
  };

  if (link.isInternalLink) {
    return (
      <Styled.UnstyledLink
        to={link.url}
        useRouter
        onClick={onClick}
        breakpoint={breakpoint}
      >
        {renderTeaserContent()}
      </Styled.UnstyledLink>
    );
  }

  return (
    <Styled.UnstyledLink
      to={link.url}
      useRouter={false}
      target="_blank"
      onClick={onClick}
      breakpoint={breakpoint}
    >
      {renderTeaserContent()}
    </Styled.UnstyledLink>
  );
};

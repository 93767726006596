import styled from 'styled-components';

import { CONTENT_HEADER_HEIGHT } from 'app/shared/modules/Cartile/CartileTemplates/CartileTemplates.constant';

export const CartileContentHeaderContainerSt = styled.div`
  z-index: 1;
  display: flex;
  justify-content: space-between;
  flex: 0 0 auto;
  height: ${CONTENT_HEADER_HEIGHT}px;
`;

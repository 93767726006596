/* istanbul ignore file */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */

import styled from 'styled-components';

import { variables } from '../variables';
import { Button } from '../Button/Button';

const { fontWeights } = variables;

export const ItemSt = styled(Button).attrs(() => ({
  unstyled: true,
  width: 1,
  px: 12,
  py: 12,
}))`
  display: flex;
  width: 100%;
  min-height: 40px;
  align-items: center;
  justify-content: space-between;
  padding: 8px 12px;

  color: ${({ theme }) => theme.colors.grayDark};
  background-color: ${({ theme, isFocused }) =>
    isFocused ? theme.colors.mintLighter : theme.colors.grayLightest};
  cursor: pointer;
  font-size: 14px;
  font-weight: ${fontWeights.regular};
  line-height: 1.5;

  &:hover,
  &:focus {
    background-color: ${({ theme: { colors } }) => colors.mintLighter};
  }
`;

export const SuggestionsWrapperSt = styled.div`
  position: absolute;
  max-height: 200px;
  margin-top: 56px;
  padding: 8px 0;
  background-color: #f5f5f5;
  overflow-y: auto;
  z-index: 100;
  box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.1);
`;

export const SuggestionsPrefixSt = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
  font-size: 14px;
  background-color: ${({ theme }) => theme.colors.grayLightest};
  cursor: default;
  li {
    min-height: 40px;
    font-weight: bold;
    padding: 8px 12px;
    margin-bottom: 0;
    line-height: 1.5;

    &.divider {
      height: 1px;
      min-height: 0;
      margin: 8px 12px;
      padding: 0;
      background-color: ${({ theme }) => theme.colors.grayLight};
    }

    &:first-child {
      font-weight: normal;
      color: ${({ theme }) => theme.colors.grayDark};
    }

    &:last-child {
      margin-bottom: 0;
    }
  }
`;

import { trackAction } from 'app/shared/utils/tracking';
import { height } from 'app/shared/styles_js/variables';
import {
  comfortMapping,
  extrasMapping,
  multimediaMapping,
  safetyMapping,
} from 'app/marketplace/constants/extrasMappings';
import { chunkList } from 'app/shared/utils/utils';

const OFFSET_TOP = height.topNavBar + height.topBanner;
export const PDP_TABS_VALUE = [
  'Komfort',
  'Extras',
  'Sicherheit',
  'Multimedia',
  'Beschreibung',
];

export const handleTabsClickTracking = (idx, vehicleId) => {
  const value = PDP_TABS_VALUE[idx];

  trackAction('vehicle_features_tab_click', {
    category: 'PDP',
    label: `${vehicleId}`,
    value: `${value}`,
  });
};

export const scrollBackToTopOfSection = (breakpoint) => (element) => {
  /* istanbul ignore next */
  const offsetOnMobile = breakpoint === 'sm' ? 47 : 0;
  const winScrollY = window.scrollY || window.pageYOffset;

  if (element) {
    const scrollY =
      element.getBoundingClientRect().top -
      (OFFSET_TOP + offsetOnMobile) +
      winScrollY;
    window.scroll(0, scrollY);
  }
};

export const toggleSeeMoreInfo = (value, isMobile) => {
  trackAction('more_details_vehicle_tab_button_click', {
    category: 'PDP',
    label: 'description is fully display',
    value: `${!value}`,
    description: 'user click to open/close the vehicle details tab',
  });

  if (!value && isMobile) {
    scrollBackToTopOfSection('sm')(
      document.querySelector('#car-features-tabs'),
    );
  }
};

export const trackAccordionSection = (section, value, vehicleId) => {
  trackAction(`${section}_button_click`, {
    category: 'PDP',
    label: `${vehicleId}`,
    value: `${value}`,
    description: 'open/close extras-imprint',
  });
};

export const trackOpen = (section, vehicleId) => {
  trackAccordionSection(section, 1, vehicleId);
};

export const trackClose = (section, vehicleId) => {
  trackAccordionSection(section, 0, vehicleId);
};

export const sortFeaturesByTitle = (a, b) => {
  const nameA = a.props.title.toUpperCase();
  const nameB = b.props.title.toUpperCase();
  if (nameA < nameB) {
    return -1;
  }
  if (nameA > nameB) {
    return 1;
  }
  return 0;
};
export const getFeatureGroups = (features) => {
  const comfortList = features
    .filter((feature) => comfortMapping[feature])
    .map((feature) => (
      <li key={feature} title={comfortMapping[feature]}>
        {comfortMapping[feature]}
      </li>
    ));

  const safetyList = features
    .filter((feature) => safetyMapping[feature])
    .map((feature) => (
      <li key={feature} title={safetyMapping[feature]}>
        {safetyMapping[feature]}
      </li>
    ));

  const multimediaList = features
    .filter((feature) => multimediaMapping[feature])
    .map((feature) => (
      <li key={feature} title={multimediaMapping[feature]}>
        {multimediaMapping[feature]}
      </li>
    ));

  const extrasList = features
    .filter((feature) => extrasMapping[feature])
    .map((feature) => (
      <li key={feature} title={extrasMapping[feature]}>
        {extrasMapping[feature]}
      </li>
    ));

  const chunkSize = 8;
  const comfortListGrouped = chunkList(
    comfortList.sort(sortFeaturesByTitle),
    chunkSize,
  );
  const safetyListGrouped = chunkList(
    safetyList.sort(sortFeaturesByTitle),
    chunkSize,
  );
  const multimediaListGrouped = chunkList(
    multimediaList.sort(sortFeaturesByTitle),
    chunkSize,
  );
  const extrasListGrouped = chunkList(
    extrasList.sort(sortFeaturesByTitle),
    chunkSize,
  );

  return {
    comfortListGrouped,
    safetyListGrouped,
    multimediaListGrouped,
    extrasListGrouped,
  };
};

/* until the campaign is over */

const camapignVehiclesToHide = [
  '2303_VWFS',
  '2303_VWFS_VV',
  '2303_VWFS_SK',
  '2303_VWFS_SE',
];

export const hideDcc = (vehicle) => {
  const isCamapignVehicleToHide = vehicle?.markers?.some(
    (r) => camapignVehiclesToHide.indexOf(r.campaign?.id) >= 0,
  );

  return isCamapignVehicleToHide;
};

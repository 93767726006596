/**
 * Name space for actions related to financing checkout
 * @type {String}
 */
const ns = 'CHECKOUT/';

/**
 * Vehicle
 */
export const VEHICLE_GET = `${ns}VEHICLE_GET`;
export const VEHICLE_GET_SUCCESS = `${ns}VEHICLE_GET_SUCCESS`;
export const VEHICLE_GET_ERROR = `${ns}VEHICLE_GET_ERROR`;
export const VEHICLE_REMOVE = `${ns}VEHICLE_REMOVE`;

/**
 * Order
 */
export const ORDER_POST = `${ns}ORDER_POST`;
export const ORDER_POST_SUCCESS = `${ns}ORDER_POST_SUCCESS`;
export const ORDER_POST_ERROR = `${ns}ORDER_POST_ERROR`;
export const ORDER_REDIRECT_URL_POST = `${ns}ORDER_REDIRECT_URL_POST`;
export const ORDER_REDIRECT_URL_POST_SUCCESS = `${ns}ORDER_REDIRECT_URL_POST_SUCCESS`;
export const ORDER_REDIRECT_URL_POST_ERROR = `${ns}ORDER_REDIRECT_URL_POST_ERROR`;
export const ORDER_REDIRECT_URL_RESET = `${ns}ORDER_REDIRECT_URL_RESET`;

export const ORDER_ADD_VALUES = `${ns}ORDER_ADD_VALUES`;
export const ORDER_STATUS_GET = `${ns}ORDER_STATUS_GET`;
export const ORDER_STATUS_GET_SUCCESS = `${ns}ORDER_STATUS_GET_SUCCESS`;
export const ORDER_STATUS_GET_ERROR = `${ns}ORDER_STATUS_GET_ERROR`;
export const ORDER_RESET = `${ns}ORDER_RESET`;

/**
 * Order PATCH consents
 */
export const DEALER_CASH_CONSENTS_AGREE = 'DEALER_CASH_CONSENTS_AGREE';
export const DEALER_CASH_CONSENTS_RESET = 'DEALER_CASH_CONSENTS_RESET';

/**
 * Order PATCH for appointment scheduled
 */
export const ORDER_PATCH = 'ORDER_PATCH';
export const ORDER_PATCH_SUCCESS = 'ORDER_PATCH_SUCCESS';
export const ORDER_PATCH_ERROR = 'ORDER_PATCH_ERROR';

/**
 * Cash order
 */
export const ORDER_CASH_POST = `${ns}ORDER_CASH_POST`;
export const ORDER_CASH_POST_SUCCESS = `${ns}ORDER_CASH_POST_SUCCESS`;
export const ORDER_CASH_POST_ERROR = `${ns}ORDER_CASH_POST_ERROR`;
export const ORDER_CASH_ADD_CONSENT = `${ns}ORDER_CASH_ADD_CONSENT`;
export const ORDER_SUBMITTED_RESET = `${ns}ORDER_SUBMITTED_RESET`;

/**
 * Leasing order
 */
export const ORDER_LEASING_ADD_VALUES = `${ns}ORDER_LEASING_ADD_VALUES`;

/**
 * Calculation
 */
export const CALCULATION_GET = `${ns}CALCULATION_GET`;
export const CALCULATION_GET_SUCCESS = `${ns}CALCULATION_GET_SUCCESS`;
export const CALCULATION_GET_ERROR = `${ns}CALCULATION_GET_ERROR`;
export const CALCULATION_RESET = `${ns}CALCULATION_RESET`;

/**
 * Financing App
 */
export const PAYMENT_INFO_GET = `${ns}PAYMENT_INFO_GET`;
export const PAYMENT_INFO_GET_ERROR = `${ns}PAYMENT_INFO_GET_ERROR`;
export const PAYMENT_INFO_GET_SUCCESS = `${ns}PAYMENT_INFO_GET_SUCCESS`;

export const CALCULATION_INFO_GET = `${ns}CALCULATION_INFO_GET`;
export const CALCULATION_INFO_GET_SUCCESS = `${ns}CALCULATION_INFO_GET_SUCCESS`;
export const CALCULATION_INFO_GET_ERROR = `${ns}CALCULATION_INFO_GET_ERROR`;

export const PICKUP_LOCATION_GET = `${ns}PICKUP_LOCATION_GET`;
export const PICKUP_LOCATION_GET_ERROR = `${ns}PICKUP_LOCATION_GET_ERROR`;
export const PICKUP_LOCATION_GET_SUCCESS = `${ns}PICKUP_LOCATION_GET_SUCCESS`;
export const PICKUP_LOCATION_RESET = `${ns}PICKUP_LOCATION_RESET`;

export const PARTNER_DEALER_GET = `${ns}PARTNER_DEALER_GET`;
export const PARTNER_DEALER_GET_SUCCESS = `${ns}PARTNER_DEALER_GET_SUCCESS`;
export const PARTNER_DEALER_GET_ERROR = `${ns}PARTNER_DEALER_GET_ERROR`;
export const PARTNER_DEALER_RESET = `${ns}PARTNER_DEALER_RESET`;

export const DELIVERY_LOCATION_GET = `${ns}DELIVERY_LOCATION_GET`;
export const DELIVERY_LOCATION_GET_ERROR = `${ns}DELIVERY_LOCATION_GET_ERROR`;
export const DELIVERY_LOCATION_GET_SUCCESS = `${ns}DELIVERY_LOCATION_GET_SUCCESS`;
export const DELIVERY_LOCATION_RESET = `${ns}DELIVERY_LOCATION_RESET`;
export const DELIVERY_LOCATION_PUT = `${ns}DELIVERY_LOCATION_PUT`;
export const DELIVERY_LOCATION_PUT_ERROR = `${ns}DELIVERY_LOCATION_PUT_ERROR`;
export const DELIVERY_LOCATION_PUT_SUCCESS = `${ns}DELIVERY_LOCATION_PUT_SUCCESS`;

export const INSURANCE_GET = `${ns}INSURANCE_GET`;
export const INSURANCE_GET_ERROR = `${ns}INSURANCE_GET_ERROR`;
export const INSURANCE_GET_SUCCESS = `${ns}INSURANCE_GET_SUCCESS`;
export const INSURANCE_PUT = `${ns}INSURANCE_PUT`;
export const INSURANCE_PUT_ERROR = `${ns}INSURANCE_PUT_ERROR`;
export const INSURANCE_PUT_SUCCESS = `${ns}INSURANCE_PUT_SUCCESS`;

export const SALES_OFFER_PUT = `${ns}SALES_OFFER_PUT`;
export const SALES_OFFER_PUT_SUCCESS = `${ns}SALES_OFFER_PUT_SUCCESS`;
export const SALES_OFFER_PUT_ERROR = `${ns}SALES_OFFER_PUT_ERROR`;

export const CANCELATION_POLICY_GET = `${ns}CANCELATION_POLICY_GET`;
export const CANCELATION_POLICY_GET_SUCCESS = `${ns}CANCELATION_POLICY_GET_SUCCESS`;
export const CANCELATION_POLICY_GET_ERROR = `${ns}CANCELATION_POLICY_GET_ERROR`;

export const DAMAGES_REPORT_GET = `${ns}DAMAGES_REPORT_GET`;
export const DAMAGES_REPORT_GET_SUCCESS = `${ns}DAMAGES_REPORT_GET_SUCCESS`;
export const DAMAGES_REPORT_GET_ERROR = `${ns}DAMAGES_REPORT_GET_ERROR`;

export const ACCIDENT_INFO_GET = `${ns}ACCIDENT_INFO_GET`;
export const ACCIDENT_INFO_GET_SUCCESS = `${ns}ACCIDENT_INFO_GET_SUCCESS`;
export const ACCIDENT_INFO_GET_ERROR = `${ns}ACCIDENT_INFO_GET_ERROR`;

/**
 * Sidebar
 */
export const USER_INFO_UPDATE = `${ns}USER_INFO_UPDATE`;
export const USER_INFO_PHONE_RESET = `${ns}USER_INFO_PHONE_RESET`;
export const ADDRESS_INFO_UPDATE = `${ns}ADDRESS_INFO_UPDATE`;
export const ORDER_INFO_GET = `${ns}ORDER_INFO_GET`;
export const ORDER_INFO_GET_SUCCESS = `${ns}ORDER_ID_GET_SUCCESS`;
export const ORDER_INFO_GET_ERROR = `${ns}ORDER_ID_GET_ERROR`;

/* DEALER SALES OFFER */

export const DEALER_SALES_OFFER_ACCEPT_PUT = `${ns}DEALER_SALES_OFFER_ACCEPT_PUT`;
export const DEALER_SALES_OFFER_ACCEPT_PUT_SUCCESS = `${ns}DEALER_SALES_OFFER_ACCEPT_PUT_SUCCESS`;
export const DEALER_SALES_OFFER_ACCEPT_PUT_ERROR = `${ns}DEALER_SALES_OFFER_ACCEPT_PUT_ERROR`;

export const DEALER_SALES_OFFER_REJECT_PUT = `${ns}DEALER_SALES_OFFER_REJECT_PUT`;
export const DEALER_SALES_OFFER_REJECT_PUT_SUCCESS = `${ns}DEALER_SALES_OFFER_REJECT_PUT_SUCCESS`;
export const DEALER_SALES_OFFER_REJECT_PUT_ERROR = `${ns}DEALER_SALES_OFFER_REJECT_PUT_ERROR`;

export const DEALER_SALES_OFFER_GET = `${ns}DEALER_SALES_OFFER_GET`;
export const DEALER_SALES_OFFER_GET_SUCCESS = `${ns}DEALER_SALES_OFFER_GET_SUCCESS`;
export const DEALER_SALES_OFFER_GET_ERROR = `${ns}DEALER_SALES_OFFER_GET_ERROR`;

/* CHECKOUT TYPE */

export const CHECKOUT_TYPE_SET = `${ns}CHECKOUT_TYPE_SET`;

/* CHECKOUT STEP */
export const CHECKOUT_STEP_SET = `${ns}CHECKOUT_STEP_SET`;
export const CHECKOUT_STEP_RESET = `${ns}CHECKOUT_STEP_RESET`;

import { useEffect, useState, useRef } from 'react';

export const useWindowScroll = (
  hasMounted = false,
  scrollHeight = 100,
): boolean | null => {
  const [pageScrolled, setPageScrolled] = useState(false);
  const isPageScrolled = useRef(false); // A ref is used here to avoid multiple re-renders
  const handleScroll = () => {
    if (!isPageScrolled.current && window.pageYOffset > scrollHeight) {
      isPageScrolled.current = true;
      setPageScrolled(true);
    } else if (isPageScrolled.current && window.pageYOffset <= scrollHeight) {
      isPageScrolled.current = false;
      setPageScrolled(false);
    }
  };
  useEffect(() => {
    // Reset the ref and state mainly for tablet portrait and landscape
    isPageScrolled.current = false;
    setPageScrolled(false);
    if (hasMounted) {
      window.addEventListener('scroll', handleScroll);
      window.addEventListener('resize', handleScroll);
    }

    return /* istanbul ignore next */ () => {
      if (hasMounted) {
        window.removeEventListener('scroll', handleScroll);
        window.removeEventListener('resize', handleScroll);
      }
    };
  }, [scrollHeight, hasMounted]);
  return pageScrolled;
};

import { breakpoints } from './breakpoints';
import { colors as colorV2, colorsRgb as colorV2Rgb } from './colors';
import { color, colorRgb } from './outdated-colors';
import {
  grid,
  height,
  magazine,
  marginTopUnderSearchBar,
  slightlyAbove,
  stickyButton,
  zIndex,
  zIndexModal,
  columnCount,
} from './spacings';
import { iconSizes, fontSizes, fontWeights } from './fonts';
import { media } from './media';
import {
  chromeOnly,
  evergreenOnly,
  getRgba,
  getColor,
  is,
  isNot,
  isOr,
  isSomeNot,
  styledIf,
  supports,
  webkitOnly,
} from './utils';

const exportVariables = {
  breakpoints,

  color,
  colorRgb,
  colorV2,
  colorV2Rgb,

  iconSizes,
  fontSizes,
  fontWeights,

  columnCount,
  grid,
  height,
  magazine,
  marginTopUnderSearchBar,
  slightlyAbove,
  stickyButton,
  zIndex,
  zIndexModal,

  getRgba,
  getColor,
  media,
  styledIf,
  is,
  isNot,
  isOr,
  isSomeNot,
  supports,
  webkitOnly,
  chromeOnly,
  evergreenOnly,
};

export const variables = {
  ...exportVariables,
  variables: exportVariables,
};

export const SEARCH_REQUEST_PENDING = 'SEARCH_REQUEST_PENDING';
export const SEARCH_REQUEST_CANCELLED = 'SEARCH_REQUEST_CANCELLED';

export const SEARCH_GET_VEHICLES = 'SEARCH_GET_VEHICLES';
export const SEARCH_REQUEST_ERROR = 'SEARCH_REQUEST_ERROR';
export const SEARCH_RESET_LISTING = 'SEARCH_RESET_LISTING';
export const SEARCH_GET_VEHICLE_COUNT_PENDING =
  'SEARCH_GET_VEHICLE_COUNT_PENDING';
export const SEARCH_GET_VEHICLE_COUNT_CANCELLED =
  'SEARCH_GET_VEHICLE_COUNT_CANCELLED';

export const SEARCH_GET_LAST_SEARCH_PENDING = 'SEARCH_GET_LAST_SEARCH_PENDING';
export const SEARCH_GET_LAST_SEARCH_SUCCESS = 'SEARCH_GET_LAST_SEARCH_SUCCESS';
export const SEARCH_GET_LAST_SEARCH_FAILURE = 'SEARCH_GET_LAST_SEARCH_FAILURE';
export const OPEN_SIDEBAR_FILTERS = 'OPEN_SIDEBAR_FILTERS';
export const CLOSE_SIDEBAR_FILTERS = 'CLOSE_SIDEBAR_FILTERS';
export const LOADING_TIMEOUT = 3000;

export const SEARCH_GET_VEHICLE_SUGGESTIONS_PENDING =
  'SEARCH_GET_VEHICLE_SUGGESTIONS_PENDING';
export const SEARCH_GET_VEHICLE_SUGGESTIONS_SUCCESS =
  'SEARCH_GET_VEHICLE_SUGGESTIONS_SUCCESS';
export const SEARCH_GET_VEHICLE_SUGGESTIONS_FAILURE =
  'SEARCH_GET_VEHICLE_SUGGESTIONS_FAILURE';

export const SEARCH_GET_VEHICLES_D2C_PENDING =
  'SEARCH_GET_VEHICLES_D2C_PENDING';
export const SEARCH_GET_VEHICLES_D2C_SUCCESS =
  'SEARCH_GET_VEHICLES_D2C_SUCCESS';
export const SEARCH_GET_VEHICLES_D2C_FAILURE =
  'SEARCH_GET_VEHICLES_D2C_FAILURE';

export const SEARCH_GET_REDUCEDPRICE_CARS_PENDING =
  'SEARCH_GET_REDUCEDPRICE_CARS_PENDING';
export const SEARCH_GET_REDUCEDPRICE_CARS_SUCCESS =
  'SEARCH_GET_REDUCEDPRICE_CARS_SUCCESS';
export const SEARCH_GET_REDUCEDPRICE_CARS_FAILURE =
  'SEARCH_GET_REDUCEDPRICE_CARS_FAILURE';

export const HIDE_VEHICLE_COUNT = 'HIDE_VEHICLE_COUNT';
export const SHOW_VEHICLE_COUNT = 'SHOW_VEHICLE_COUNT';

export const GET_PRICE_RANGE = 'GET_PRICE_RANGE';

import { useEffect, useMemo, useState } from 'react';

import { getFilterObject } from 'app/marketplace/filter/utils';

const category = 'model';

export const useModelFilter = ({
  makeFilter,
  modelFilter,
  possibleMakes,
  onChangeFilter,
  handleTracking,
}) => {
  const [selectedModel, setSelectedModel] = useState('');

  useEffect(() => {
    if (modelFilter.length > 0) {
      setSelectedModel(modelFilter[0].value);
    }
  }, []);

  const modelsOptions = useMemo(() => {
    const modelsInTheMake = possibleMakes.find(
      (make) => make.make === makeFilter[0]?.value,
    );
    const cleanedPossibleModels =
      (modelsInTheMake &&
        modelsInTheMake.modelsPerMake?.map((filteredModel) => ({
          label: filteredModel.displayName,
          value: filteredModel.key,
        }))) ||
      [];
    /* istanbul ignore next */
    cleanedPossibleModels.sort((a, b) => {
      const x = a.label.toLowerCase();
      const y = b.label.toLowerCase();
      /* istanbul ignore next */
      if (x < y) {
        return -1;
      }
      /* istanbul ignore next */
      if (x > y) {
        return 1;
      }
      return 0;
    });
    cleanedPossibleModels.unshift({
      label: 'Alle Modelle',
      value: 'all',
    });
    if (
      selectedModel &&
      modelFilter[0] &&
      !modelFilter[0].value.includes('_all') &&
      !cleanedPossibleModels.some((item) => item.value === selectedModel)
    ) {
      cleanedPossibleModels.splice(1, 0, {
        label: modelFilter[0].label,
        value: selectedModel,
      });
    }
    return cleanedPossibleModels;
  });

  const handleSelectedModel = (e) => {
    const { value } = e.currentTarget;
    const idx = e.nativeEvent.target.selectedIndex;
    const label = e.nativeEvent.target?.[idx]?.text;

    setSelectedModel(value);
    if (handleTracking) {
      handleTracking(category);
    }

    const makeFilterObject = makeFilter;
    const modelFilterObject = [
      getFilterObject({
        label,
        value: `${makeFilter[0].value}_${value}`,
        category: 'model',
      }),
    ];
    const trimFilterObject = [
      getFilterObject({
        label: '',
        value: `${makeFilter[0].value}_${value}_all`,
        category: 'trim',
      }),
    ];

    return onChangeFilter(
      {
        make: makeFilterObject,
        model: modelFilterObject,
        trim: trimFilterObject,
      },
      true,
      true,
      false,
    );
  };

  return [selectedModel, modelsOptions, handleSelectedModel];
};

import PropTypes from 'prop-types';
import { browserHistory } from 'react-router';

import { ContainerSt } from 'app/shared/ui';
import { GAM_AD_UNITS } from 'app/shared/modules/Campaign/GAM/ad-units';
import { trackAction } from 'app/shared/utils/tracking';
import { ConnectedSpecialOffersSlider } from 'app/marketplace/specialOffers/SpecialOffersSlider/ConnectedSpecialOffersSlider';
import SpecialOffersHomeNew from 'app/marketplace/specialOffers/SpecialOffersHome/SpecialOffersHomeNew';
import { returnCard } from 'app/marketplace/specialOffers/returnCard';
import { breakpointShape } from 'app/shared/utils/propTypes';
import { useSentryTags } from 'app/shared/hooks/useSentryTags';

import {
  SpecialOffersOuterContainerSt,
  ButtonSt,
  SpecialOffersContainer,
  SpecialOfferTeaserCardSt,
  SpecialOfferTileSt,
  TitleSt,
} from './SpecialOffersHome.css';

const { HP_DEALS_AND_SPECIALS_TILES } = GAM_AD_UNITS;

export const SpecialOffersHome = ({
  specialOffersHome,
  breakpoint,
  resetLocation,
  isFlag256ShowNewPromotionTiles,
}) => {
  useSentryTags({ GROWTH_DE: true });
  const trackClick = () => {
    browserHistory.push('/angebote');
    trackAction('all_special_offers_clicked', {
      category: 'home',
      label: 'Special Offer Teaser',
      description: 'user clicks on all special offers button on homepage',
      value: '/angebote',
    });
  };

  const isMobile = breakpoint === 'sm';
  const hasLessEdgeGap = isMobile && isFlag256ShowNewPromotionTiles;

  if (!specialOffersHome) return null;

  return (
    <SpecialOffersOuterContainerSt>
      <ContainerSt style={hasLessEdgeGap ? { width: 'calc(100% - 24px)' } : {}}>
        {isFlag256ShowNewPromotionTiles ? (
          <SpecialOffersHomeNew breakpoint={breakpoint} />
        ) : (
          <>
            <TitleSt as="h2" width="100%">
              Deals und Specials
            </TitleSt>
            {breakpoint === 'sm' && (
              <SpecialOffersContainer>
                {specialOffersHome?.specialOffersOnMobile?.map(
                  (offer, offerKey) =>
                    returnCard({
                      offer,
                      TeaserComponent: SpecialOfferTeaserCardSt,
                      OfferComponent: SpecialOfferTileSt,
                      resetLocation,
                      gamInfo: {
                        carTiles: HP_DEALS_AND_SPECIALS_TILES,
                        order: offerKey,
                      },
                    }),
                )}
              </SpecialOffersContainer>
            )}
            {breakpoint === 'md' && (
              <ConnectedSpecialOffersSlider
                specialOffers={specialOffersHome.specialOffersOnDesktop}
                breakpoint={breakpoint}
                gamInfo={{
                  carTiles: HP_DEALS_AND_SPECIALS_TILES,
                }}
              />
            )}
            {breakpoint !== 'sm' && breakpoint !== 'md' && (
              <SpecialOffersContainer>
                {specialOffersHome?.specialOffersOnDesktop?.map(
                  (offer, offerKey) =>
                    returnCard({
                      offer,
                      TeaserComponent: SpecialOfferTeaserCardSt,
                      OfferComponent: SpecialOfferTileSt,
                      resetLocation,
                      gamInfo: {
                        carTiles: HP_DEALS_AND_SPECIALS_TILES,
                        order: offerKey,
                      },
                    }),
                )}
              </SpecialOffersContainer>
            )}
            <ButtonSt onClick={trackClick}>
              {breakpoint === 'sm'
                ? 'Alle Aktionsangebote'
                : 'Alle Aktionsangebote entdecken'}
            </ButtonSt>
          </>
        )}
      </ContainerSt>
    </SpecialOffersOuterContainerSt>
  );
};

SpecialOffersHome.propTypes = {
  specialOffersHome: PropTypes.object,
  resetLocation: PropTypes.func.isRequired,
  breakpoint: breakpointShape.isRequired,
  isFlag256ShowNewPromotionTiles: PropTypes.bool,
};

SpecialOffersHome.defaultProps = {
  specialOffersHome: null,
  isFlag256ShowNewPromotionTiles: false,
};

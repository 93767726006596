export const getCalculatorData = (state) => {
  return state.fdlCalculator;
};

export const getCalculatorRequestId = (state) => {
  return state.fdlCalculator.requestId;
};

export const getCalculatorError = (state) => state.fdlCalculator.error;

/**
 * gets calculation data for specific vehicle
 */
export const getCalculation = (state, vehicleListId) =>
  state.fdlCalculator.calculations?.find(
    ({ id }) => id === String(vehicleListId),
  ) || null;

/**
 * Get the specifics component from the section of FDL calculator.
 * @param state {*}
 * @param key string - Id of Component
 * @returns {*|number}
 */
export const getCalculatorComponent = (state, key) =>
  state.fdlCalculator?.sections.reduce((result, { components = [] }) => {
    const component = components?.find(({ id }) => id === key);
    if (component) {
      return component;
    }
    return result;
  }, {});

import React from 'react';

import { IconSt } from './Icon.css';
import { IIconProps } from './Icon.types';

export const Icon = ({
  icon,
  iconSize,
  Component = IconSt,
  className,
  ...props
}: IIconProps): JSX.Element => (
  <Component
    as="i"
    iconSize={iconSize}
    className={[`if-icon-${icon}`, className].join(' ')}
    {...props}
  />
);

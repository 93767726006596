import { Route } from 'react-router';

import { ConnectedPageTemplate } from 'app/shared/modules/pageTemplate/PageTemplate/ConnectedPageTemplate';
import { getAsyncRouteComponentLoader } from 'app/shared/utils/utils';

export const ElectricCarRoutes = (
  <Route
    name="electric-car"
    component={(props) => (
      <ConnectedPageTemplate target="electricCar" {...props} />
    )}
  >
    <Route
      path="/elektro"
      getComponent={getAsyncRouteComponentLoader(
        'ConnectedElectricCarPage',
        () => import('./ConnectedElectricCarPage'),
      )}
    />
  </Route>
);

import _isEmpty from 'lodash.isempty';
import { LOCATION_CHANGE } from 'react-router-redux';
import {
  all,
  debounce,
  delay,
  fork,
  put,
  select,
  takeEvery,
} from 'redux-saga/effects';

import { contentfulCampaignsSelector } from 'app/marketplace/contentful/selectors';

import { getCampaigns, triggerModal } from './actions';
import { ActionTypes } from './constants';

export function* triggerModalOutside({ payload }) {
  yield delay(100);
  yield put(triggerModal(payload.modalId));
}

export function* watchModalTriggers() {
  yield takeEvery(
    ActionTypes.CONTENTFUL_TRIGGER_MODAL_OUTSIDE,
    triggerModalOutside,
  );
}

export function* handleMissingCampaignData({ payload: { pathname, search } }) {
  const isPreviewContentful = search?.includes('isPreviewContentful');

  const isHome = pathname === '/';
  const isCLP = pathname === '/gebrauchtwagen';
  const isAngebote = pathname === '/angebote';
  const isFavourites = pathname === '/favourites';
  const isPDP = pathname?.startsWith('/vehicle');
  const isPrintableVoucher = pathname === '/voucher';
  const isValidUrl =
    isHome ||
    isCLP ||
    isAngebote ||
    isFavourites ||
    isPDP ||
    isPrintableVoucher;

  const campaigns = yield select(contentfulCampaignsSelector);

  if (_isEmpty(campaigns) && isValidUrl) {
    yield put(
      getCampaigns({
        isPreviewContentful,
        // We still need the non-active data from Contentful for the ended campaigns (only for Printable Voucher Screen)
        ...(!isPrintableVoucher &&
          !isPreviewContentful && {
            filters: { 'fields.active': true },
          }),
      }),
    );
  }
}

export function* watchMissingCampaignData() {
  yield debounce(20, LOCATION_CHANGE, handleMissingCampaignData);
}

export function* contentfulSagas() {
  yield all([fork(watchModalTriggers), fork(watchMissingCampaignData)]);
}

/* eslint-disable react/no-multi-comp, react/jsx-curly-newline,react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router';
import { space, textAlign } from 'styled-system';

import { LoadingSpinner } from '../LoadingSpinner/LoadingSpinner';
import { backdrops } from '../../styles_js/backdrops';

import { ButtonSt, UnstyledSt } from './Button.css';

/**
 * General button component that follows the design guidelines
 */
export const Button = (props) => {
  const {
    children,
    isLoading,
    LoadingIndicator,
    disabled,
    variant,
    unstyled,
    type,
    ariaLabel,
    backdrop,
    ...rest
  } = props;

  // Unstyled button for accessibility
  if (unstyled) {
    return (
      <UnstyledSt
        type={type}
        aria-label={ariaLabel}
        disabled={disabled}
        {...rest}
      >
        {children}
      </UnstyledSt>
    );
  }

  return (
    <ButtonSt
      variant={variant}
      backdrop={backdrop}
      type={type}
      aria-label={ariaLabel}
      disabled={isLoading || disabled}
      {...rest}
    >
      {isLoading && LoadingIndicator}
      {children}
    </ButtonSt>
  );
};

Button.defaultProps = {
  variant: 'primary',
  backdrop: undefined,
  onClick: () => {},
  disabled: false,
  isLoading: false,
  LoadingIndicator: <LoadingSpinner mr={12} size={24} color="currentColor" />,
  type: 'button',
  ariaLabel: undefined,
  unstyled: false,
  tertiaryVariant: 'primary',
};

Button.propTypes = {
  variant: PropTypes.oneOf(['primary', 'secondary', 'tertiary', 'quarternary']),
  tertiaryVariant: PropTypes.oneOf(['primary', 'secondary']),
  backdrop: PropTypes.oneOf(Object.keys(backdrops)),
  onClick: PropTypes.func,
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.element]).isRequired,
  disabled: PropTypes.bool,
  isLoading: PropTypes.bool,
  LoadingIndicator: PropTypes.oneOfType([PropTypes.node, PropTypes.element]),
  type: PropTypes.oneOf(['button', 'submit', 'reset']),
  ariaLabel: PropTypes.string,
  unstyled: PropTypes.bool,
  ...space.propTypes,
  ...textAlign.propTypes,
};

/**
 * LinkButton component
 */
export const LinkButton = (props) => {
  const { to, hoverColor, ...rest } = props;
  return (
    <Button
      // eslint-disable-next-line react-perf/jsx-no-new-function-as-prop
      as={({ id, className, title, isExternal, children, target, onClick }) =>
        isExternal ? (
          <a
            id={id}
            className={className}
            href={to}
            target={target}
            onClick={onClick}
          >
            {children}
          </a>
        ) : (
          <Link
            id={id}
            className={className}
            title={title}
            to={to}
            target={target}
            onClick={onClick}
          >
            {children}
          </Link>
        )
      }
      to={to}
      hovercolor={hoverColor}
      {...rest}
    />
  );
};

LinkButton.defaultProps = {
  id: undefined,
  isExternal: false,
  target: null,
  title: undefined,
};

LinkButton.propTypes = {
  ...Button.propTypes,
  id: PropTypes.string,
  isExternal: PropTypes.bool,
  target: PropTypes.string,
  title: PropTypes.string,
  to: PropTypes.string.isRequired,
};

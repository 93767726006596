/* istanbul ignore file */
import styled from 'styled-components';
import { Flex } from '@rebass/grid';

export const MobileNavControlsSt = styled(Flex)`
  z-index: 1;
  height: 100%;
  align-items: center;
  justify-content: flex-end;
  grid-column: 8 / span 8;
  color: ${({ theme: { colors } }) => colors.brandSecondaryLight};
`;

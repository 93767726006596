import { memo } from 'react';
import PropTypes from 'prop-types';

import { CartileLoadingStyle } from 'app/shared/modules/Cartile/CartileUIComponents/CartileUIComponents.css';
import {
  CartileD2CLocationTextSt,
  CartileD2CLocationContainerSt,
} from 'app/shared/modules/Cartile/CartileUIComponents/CartileLocation/CartileD2CLocation/CartileD2CLocation.css';

const CartileD2CLocationCmp = ({ isLoading, isId3Vehicle, hideLocation }) => (
  <CartileD2CLocationContainerSt css={[isLoading && CartileLoadingStyle]}>
    {!hideLocation && (
      <CartileD2CLocationTextSt
        as="i"
        mr="4px"
        mt="2px"
        className="if-icon-delivery"
      />
    )}
    {!hideLocation && (
      <CartileD2CLocationTextSt fontSize={['10px', '12px']}>
        {!isId3Vehicle
          ? 'Lieferung bis zur Haustür'
          : 'Abholbereit in ca. 4 Wochen'}
      </CartileD2CLocationTextSt>
    )}
  </CartileD2CLocationContainerSt>
);

CartileD2CLocationCmp.propTypes = {
  isLoading: PropTypes.bool,
  isId3Vehicle: PropTypes.bool,
  hideLocation: PropTypes.bool,
};

CartileD2CLocationCmp.defaultProps = {
  isLoading: false,
  isId3Vehicle: false,
  hideLocation: false,
};

export const CartileD2CLocation = memo(CartileD2CLocationCmp);

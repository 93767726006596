import { Component, Fragment } from 'react';
import PropTypes from 'prop-types';

import {
  ArrowSt,
  BodySt,
  ContentSt,
  HeaderSt,
  TitleSt,
  BackgroundSt,
} from './AccordionItem.css';

export class AccordionItem extends Component {
  onClick(id) {
    const { onClick, onOpen, onClose, open, alwaysOpen } = this.props;

    if (!alwaysOpen) {
      if (open) {
        onClose();
      } else {
        onOpen();
      }
      onClick(id);
    }
  }

  render() {
    const {
      id,
      open,
      title,
      titleComponent,
      cssId,
      children,
      noBorder,
      disabled,
      className,
      alwaysOpen,
      noMaxHeight,
      arrowsUpDown,
      hasFixedHeight,
      categoryContent,
      hasOpenCloseIcon,
      isAccordionFullWidth,
      isOverflowVisible,
      hasFullWidthContent,
      excludeContentMargin,
      excludeContentPadding,
      setReference,
      backgroundProps,
      htmlTag,
    } = this.props;
    const TitleComponent = titleComponent;
    const defaultMessage = title?.props?.defaultMessage || title || '';
    const dataTestId = `accordion-title-${defaultMessage}`
      .toLowerCase()
      .replace(/\s/g, '-'); // replace whitespace with a "-"
    const isOpen = alwaysOpen || open;
    const Wrapper = htmlTag || (className ? 'div' : Fragment);
    const arrowClasses = arrowsUpDown
      ? `if-icon-arrow-${isOpen ? 'up' : 'down'}-short-short`
      : `if-icon-arrow-${isOpen ? 'down-short' : 'right'}-short`;

    return (
      <Wrapper {...(className ? { className } : {})}>
        {isAccordionFullWidth && (
          <BackgroundSt ref={setReference} {...backgroundProps} />
        )}

        <HeaderSt
          disabled={disabled}
          className={className}
          isFull={hasFullWidthContent}
          hasNoBorder={noBorder}
          noMaxHeight={noMaxHeight}
          isOpen={isOpen}
          onClick={() => !disabled && this.onClick(id)}
          data-testid={dataTestId}
          id={cssId}
        >
          {titleComponent ? (
            <TitleComponent isFull={hasFullWidthContent} selected={isOpen}>
              {title}
            </TitleComponent>
          ) : (
            <TitleSt isFull={hasFullWidthContent} selected={isOpen}>
              {title}
            </TitleSt>
          )}

          {hasOpenCloseIcon && (
            <ArrowSt
              className={arrowClasses}
              disabled={disabled}
              noMaxHeight={noMaxHeight}
            />
          )}
        </HeaderSt>

        <BodySt
          isOpen={isOpen}
          hasNoBorder={noBorder}
          isOverflowVisible={isOverflowVisible}
          hasFixedHeight={!noMaxHeight && hasFixedHeight}
        >
          <ContentSt
            excludeContentMargin={excludeContentMargin}
            excludeContentPadding={excludeContentPadding}
            isFull={hasFullWidthContent}
            isCategory={categoryContent}
            className={className}
          >
            {children}
          </ContentSt>
        </BodySt>
      </Wrapper>
    );
  }
}

AccordionItem.propTypes = {
  className: PropTypes.string,
  children: PropTypes.any,
  excludeContentMargin: PropTypes.bool,
  excludeContentPadding: PropTypes.bool,
  noMaxHeight: PropTypes.bool,
  title: PropTypes.any.isRequired,
  onClick: PropTypes.func,
  onDelete: PropTypes.func,
  onOpen: PropTypes.func,
  onClose: PropTypes.func,
  id: PropTypes.string,
  open: PropTypes.bool,
  initOpen: PropTypes.bool,
  alwaysOpen: PropTypes.bool,
  hasFullWidthContent: PropTypes.bool,
  categoryContent: PropTypes.bool,
  noBorder: PropTypes.bool,
  cssId: PropTypes.string,
  disabled: PropTypes.bool,
  isOverflowVisible: PropTypes.bool,
  hasOpenCloseIcon: PropTypes.bool,
  arrowsUpDown: PropTypes.bool,
  isAccordionFullWidth: PropTypes.bool,
  hasFixedHeight: PropTypes.bool,
  setReference: PropTypes.object,
  backgroundProps: PropTypes.object,
  titleComponent: PropTypes.elementType,
  htmlTag: PropTypes.string,
};

AccordionItem.defaultProps = {
  className: '',
  children: null,
  excludeContentMargin: false,
  excludeContentPadding: false,
  hasFixedHeight: false,
  noMaxHeight: false,
  onClick: () => {},
  onDelete: () => {},
  onOpen: () => {},
  onClose: () => {},
  id: '',
  open: false,
  initOpen: false,
  alwaysOpen: false,
  hasFullWidthContent: false,
  categoryContent: false,
  arrowsUpDown: false,
  cssId: null,
  hasOpenCloseIcon: true,
  isAccordionFullWidth: false,
  setReference: null,
  backgroundProps: {},
  noBorder: false,
  titleComponent: null,
  htmlTag: '',
};

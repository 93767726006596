/* eslint-disable @typescript-eslint/restrict-template-expressions */
import styled from 'styled-components';
import { style } from 'styled-system';
import { Box } from '@rebass/grid';

const iconSize = style({
  // React prop name
  prop: 'iconSize',
  // The corresponding CSS property (defaults to prop argument)
  cssProperty: 'fontSize',
  // key for theme values
  key: 'iconSizes',
  // accessor function for transforming the value
  transformValue: (n, values) => values && `${values[n]}px`,
  // add a fallback scale object or array, if theme is not present
  scale: [8, 16, 32],
});

export const IconSt = styled(Box)`
  ${iconSize};
`;

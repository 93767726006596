import styled, { css } from 'styled-components';
import { Flex } from '@rebass/grid';

import { Heading4, ContainerSt, Link } from 'app/shared/ui';
import { color, height, media, zIndex } from 'app/shared/styles_js/variables';

export const ContentfulStageContainerSt = styled.div`
  position: relative;
  display: flex;
  height: auto;
  justify-content: flex-start;
  padding-top: 8px;
  /* istanbul ignore next */
  margin-top: 0;
  background-color: ${color.brandPrimary};

  ${media.md(css`
    align-items: flex-start;
    justify-content: flex-start;
    min-height: 688px;
    margin-top: -${height.topNavBar}px;
  `)};

  ${media.lg(css`
    min-height: 596px;
  `)};

  ${media.xl(css`
    min-height: 650px;
  `)};
`;

export const OnBoardingContainerWrapperSt = styled.div`
  z-index: ${zIndex.moreLikeThis};
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  align-self: flex-start;
  justify-content: flex-end;

  ${media.sm(css`
    margin-top: 16px;
  `)};
  ${media.md(css`
    margin-top: 0;
    width: auto;
  `)};
`;

export const ContentfulStageWrapperSt = styled(ContainerSt)`
  display: flex;
  width: calc(100% - 24px);
  flex-direction: column-reverse;
  align-items: center;
  justify-content: space-between;
  margin-top: unset;
  /* Need this to target IE10 and above */
  @media (min-width: 768px) and (-ms-high-contrast: none),
    (-ms-high-contrast: active) {
    margin-right: 0;
    margin-left: 0;
  }

  ${media.md(css`
    flex-direction: row;
    margin-top: 32px;
    padding-top: 32px;
    height: calc(100% - 32px);
  `)};
`;

export const OnBoardingContainerSt = styled.div`
  width: 100%;
  height: 100%;
  margin-bottom: 40px;
  border-radius: 4px;

  ${media.md(css`
    height: auto;
    width: 278px;
    margin-right: 32px;
  `)};
  ${media.lg(css`
    height: auto;
    width: 378px;
    margin-right: 82px;
  `)};
  ${media.xl(css`
    height: auto;
    width: 368px;
    margin-right: 100px;
  `)};

  background-color: #fff;
  box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.1);
`;

// Widths are same values from OnBoardingContainerSt
// the 2 CTA buttons need to be same size
export const ButtonWrapperSt = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: space-evenly;

  ${media.md(css`
    width: 278px;
  `)};
  ${media.lg(css`
    width: 378px;
  `)};
  ${media.xl(css`
    width: 368px;
  `)};
`;

export const StageHeading = styled(Heading4).attrs(() => ({
  fontSize: [20, 32, 40],
}))``;

export const StageContainerSt = styled(Flex).attrs(() => ({
  justifyContent: 'space-between',
  alignItems: 'center',
  flexDirection: 'column',
}))`
  position: relative;
  width: 100%;
  margin: 0px;
`;

export const MobileLinkSt = styled(Link)`
  margin-bottom: 15px;
  ${media.sm(css`
    margin-bottom: 0px;
  `)}
`;

import { to } from '../accounts/helpers/to';
import { trackAction } from '../../shared/utils/tracking';

import { premiumService } from './service';

export const PREMIUM_POST_LEAD = 'PREMIUM_POST_LEAD';
export const PREMIUM_FORM_SUBMIT_SUCCESS = 'PREMIUM_FORM_SUBMIT_SUCCESS';
export const PREMIUM_FORM_SUBMIT_FAILURE = 'PREMIUM_FORM_SUBMIT_FAILURE';

export const sendPremiumForm = ({ answers, userInfo, source }) => async (
  dispatch,
  getState,
) => {
  const { leadId } = getState().activeLeadManagement;
  const data = {
    leadId,
    source,
    answers,
    ...userInfo,
    phoneNumber: userInfo?.phoneNumber || '',
  };
  const [error, response] = await to(
    premiumService.sendPremiumForm({
      data,
    }),
  );

  if (error) {
    trackAction('alm_onboarding_submit_error', {
      category: source,
      label: 'alm_onboarding_submit',
      description:
        'user answered all questions and tried to submit the form, but something went wrong.',
    });
  } else {
    dispatch({ type: PREMIUM_FORM_SUBMIT_SUCCESS });
    trackAction('alm_onboarding_submit_success', {
      category: source,
      label: 'alm_onboarding_submit',
      description:
        'user answered all questions and submitted the form successfully.',
    });
  }
};

import PropTypes from 'prop-types';

import { IndeterminateLoadingSpinner } from 'app/shared/ui';

import { ChildrenShape } from '../../utils/utils';

import {
  InputSt,
  LoadingSt,
  ErrorTextSt,
  InputContainerSt,
} from './InputWithoutButton.css';

export const InputWithoutButton = ({ children, onButtonClick, ...props }) => (
  <>
    <InputContainerSt extraStyles={props.extraStyles(props)}>
      {props.pending && (
        <LoadingSt>
          <IndeterminateLoadingSpinner type="secondary" />
        </LoadingSt>
      )}
      <InputSt {...props} ref={props.innerRef} />
      {children}
    </InputContainerSt>
    {props.errorText && <ErrorTextSt>{props.errorText}</ErrorTextSt>}
  </>
);

InputWithoutButton.propTypes = {
  pending: PropTypes.bool,
  errorText: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  iconClass: PropTypes.string.isRequired,
  innerRef: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
  children: ChildrenShape,
  extraStyles: PropTypes.func,
  onButtonClick: PropTypes.func,
};

InputWithoutButton.defaultProps = {
  onButtonClick: () => {},
  extraStyles: () => {},
};

import React from 'react';
import { Box, Flex } from '@rebass/grid';

import { Text } from 'app/shared/ui';
import { formatCurrency } from 'app/shared/utils/formatNumber';
import { CustomPriceButtons } from 'app/marketplace/filter/Filters/FinancingOptionsFilter/CustomPriceButtons/CustomPriceButtons';

import { Suffix } from '../Suffix/Suffix';
import { TFinanceSliderProps } from '../Types';
import { getSelectedPriceRange } from '../helpers';

import { SliderSt, SliderWithInputSt } from './FinanceSlider.css';

export const FinanceSlider = ({
  withInput,
  selectedPrice,
  selectedFinancingOption,
  onCustomPriceClick,
  onDrag,
  onChange,
}: TFinanceSliderProps): JSX.Element => {
  const {
    min,
    max,
    snapPoints,
    searchCategory,
    showPopularRates,
    sliderTitle,
    isMonthly,
    disclaimer,
  } = selectedFinancingOption;

  const sliderProps = {
    algorithm: 'linear',
    snap: true,
    min,
    max,
    snapPoints,
    values: getSelectedPriceRange(selectedPrice) || [max],
    onDrag,
    onChange,
  };

  if (withInput) {
    return (
      <Box>
        <SliderWithInputSt
          {...sliderProps}
          values={getSelectedPriceRange(selectedPrice) || [min, max]}
          suffix={<Suffix searchCategory={searchCategory} />}
          onBlur={onChange}
        />

        {showPopularRates && (
          <CustomPriceButtons
            filterValue={selectedPrice}
            onButtonClick={onCustomPriceClick}
          />
        )}
      </Box>
    );
  }

  return (
    <Box pr={8}>
      <Flex justifyContent="space-between">
        <Text>{sliderTitle}</Text>
        <Text fontWeight="bold">
          {selectedPrice && Number(selectedPrice.split('-')[0]) < max ? (
            <>
              {formatCurrency(Number(selectedPrice))}
              {isMonthly && '/Monat*'}
            </>
          ) : (
            <>{disclaimer ? 'egal*' : 'egal'}</>
          )}
        </Text>
      </Flex>
      <Box>
        <SliderSt {...sliderProps} />
      </Box>
    </Box>
  );
};

FinanceSlider.defaultProps = {
  withInput: false,
};

import styled, { css } from 'styled-components';

import { media } from 'app/shared/styles_js/variables';

export const CartileFavouriteButtonIconButtonSt = styled.div`
  position: relative;
  z-index: 2;
  display: flex;
  width: 44px;
  height: 44px;
  border-radius: 50%;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.15);
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
  margin-left: 10px;
  cursor: pointer;
  background-color: ${
    /* istanbul ignore next */ ({ theme: { colors } }) => colors.white
  };
`;

export const FavouriteButtonAnimationSt = styled.i`
  position: absolute;
  z-index: 9;
  top: 13px;
  color: ${
    /* istanbul ignore next */ ({ theme: { colors } }) => colors.brandPrimary
  };
  font-size: 20px;

  ${media.md(css`
    font-size: 24px;
  `)};

  @keyframes riseUp {
    0% {
      opacity: 0.9;
    }

    20% {
      opacity: 0.7;
    }

    40% {
      opacity: 0.5;
    }

    70% {
      opacity: 0.3;
    }

    100% {
      opacity: 0;
      transform: translateY(-150px);
    }
  }

  &.if-icon-favorite-active {
    animation: riseUp 0.7s forwards;
  }
`;

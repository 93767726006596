import { memo, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';

import { InputBox } from 'app/shared/ui';
import {
  FilterPanelLocationFilterIconSt,
  FilterPanelLocationFilterInputSt,
  FilterPanelLocationFilterErrorTextSt,
  FilterPanelLocationFilterContainerSt,
} from 'app/marketplace/home/Stage/FilterPanelLocationFilter/FilterPanelLocationFilter.css';
import { useLocationFilter } from 'app/marketplace/filter/FiltersHooks/useLocationFilter';
import { trackAction } from 'app/shared/utils/tracking';

const FilterPanelLocationFilterCmp = ({
  locationError,
  resetLocation,
  handleTracking,
  onChangeFilter,
  currentLocation,
  locationPending,
  resetSuggestions,
  locationSuggestions,
  getLocationFromUser,
  getLocationSuggestions,
  setCurrentLocationFromObject,
  isDefaultRadiusVariant,
}) => {
  const [isUserClickLocateMe, setIsUserClickLocateMe] = useState(false);
  const { handleResetLocation, handleOnSelect } = useLocationFilter({
    resetLocation,
    onChangeFilter,
    handleTracking,
    currentLocation,
    setCurrentLocationFromObject,
    isDefaultRadiusVariant,
  });

  useEffect(() => {
    /* istanbul ignore next */
    if (isUserClickLocateMe) {
      handleOnSelect(currentLocation);
      setIsUserClickLocateMe(false);
    }
  }, [currentLocation]);

  const errorText = useMemo(() => {
    if (locationError?.locationDeniedByUser) {
      return 'Ortung fehlgeschlagen. Aktiviere die Ortungsfunktion oder gib einen Standort an.';
    }
    if (locationError?.locationNotFound) {
      return 'Bitte gib eine gültige PLZ oder Stadt ein';
    }
    if (locationError) {
      return 'Leider ist ein Fehler aufgetreten';
    }
    return '';
  }, [locationError]);

  const handleGetLocationFromUser = () => {
    setIsUserClickLocateMe(true);
    getLocationFromUser().catch(() => {
      return false;
    });

    const action = 'homeflow_locateme_click';
    const description = 'user clicks find my location button';
    trackAction(action, {
      category: 'Home',
      label: null,
      value: null,
      description,
    });
  };

  return (
    <FilterPanelLocationFilterContainerSt>
      <FilterPanelLocationFilterInputSt
        isInputWithoutButton
        placeholder="Standort"
        inputRenderer={InputBox}
        locationPending={locationPending}
        currentLocation={currentLocation}
        handleOnBlur={handleResetLocation}
        resetSuggestions={resetSuggestions}
        setLocationFilters={handleOnSelect}
        locationSuggestions={locationSuggestions}
        getLocationSuggestions={getLocationSuggestions}
        setCurrentLocationFromObject={setCurrentLocationFromObject}
      />
      <FilterPanelLocationFilterIconSt
        icon="target"
        iconSize="xsmall"
        onClick={handleGetLocationFromUser}
      />
      {errorText && (
        <FilterPanelLocationFilterErrorTextSt>
          {errorText}
        </FilterPanelLocationFilterErrorTextSt>
      )}
    </FilterPanelLocationFilterContainerSt>
  );
};

FilterPanelLocationFilterCmp.propTypes = {
  locationError: PropTypes.object,
  resetLocation: PropTypes.func.isRequired,
  handleTracking: PropTypes.func.isRequired,
  onChangeFilter: PropTypes.func.isRequired,
  locationPending: PropTypes.bool.isRequired,
  resetSuggestions: PropTypes.func.isRequired,
  currentLocation: PropTypes.object.isRequired,
  getLocationFromUser: PropTypes.func.isRequired,
  locationSuggestions: PropTypes.array.isRequired,
  getLocationSuggestions: PropTypes.func.isRequired,
  setCurrentLocationFromObject: PropTypes.func.isRequired,
  isDefaultRadiusVariant: PropTypes.bool,
};

FilterPanelLocationFilterCmp.defaultProps = {
  locationError: null,
  isDefaultRadiusVariant: false,
};

export const FilterPanelLocationFilter = memo(FilterPanelLocationFilterCmp);

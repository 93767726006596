import { CartileHeaderWithFavButton } from 'app/shared/modules/Cartile/CartileUIComponents/CartileHeader/CartileHeaderWithFavButton/CartileHeaderWithFavButton';
import { isNewListing } from 'app/shared/modules/Cartile/utils';

export const getDataForCartileContentHeader = ({
  vehicle,
  isLoading,
  isFavourite,
  handleToggleFavourites,
  isId3Vehicle,
}) => {
  const {
    filterableTrim,
    make: { displayName: make },
    model: { displayName: model, source, trim: id3Trim },
  } = vehicle;

  const isNewVehicle = isNewListing(vehicle.createdAt);

  const trim = (() => {
    if (isId3Vehicle) {
      return id3Trim;
    }
    if (filterableTrim && filterableTrim !== 'other') {
      return filterableTrim;
    }
    return source;
  })();

  return {
    CartileContentHeaderChildren: CartileHeaderWithFavButton,
    CartileContentHeaderChildrenProps: {
      trim,
      make,
      model,
      isLoading,
      isFavourite,
      isId3Vehicle,
      handleToggleFavourites,
      isNewVehicle,
    },
  };
};

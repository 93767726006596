import styled, { css } from 'styled-components';

import { iconSizes } from '../variables/fonts';

const getLabelColor = ({ theme, disabled, iconSize }) => {
  if (disabled) {
    return theme.colors.gray;
  }
  if (iconSize === iconSizes.xsmall) {
    return theme.colors.grayDarker;
  }

  return theme.rebranding /* istanbul ignore next */
    ? theme.colors.tarmacGrey
    : theme.colors.brandSecondary;
};

const textCursor = (props) => {
  if (props.disabled) return 'not-allowed';
  if (!props.enableClickOnText) return 'default';
  return 'pointer';
};

const getIconColors = ({
  isChecked,
  disabled,
  theme: { rebranding, colors },
}) => {
  const isCheckedAndEnabled = isChecked && !disabled;
  if (isCheckedAndEnabled) {
    return colors.brandPrimary;
  }
  /* istanbul ignore next */ if (rebranding) {
    return disabled ? colors.gray : colors.grayDark;
  }
  return colors.gray;
};

export const CheckboxSt = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  flex-direction: ${(props) => (props.isRight ? 'row-reverse' : null)};
  align-items: ${(props) => props.verticalPosition};
  margin-bottom: 24px;
  cursor: ${(props) => textCursor(props)};
  font-size: ${({ textSize }) => `${textSize}px`};
  user-select: none;
`;

export const ChildrenSt = styled.div`
  display: flex;
  flex: 1;
  line-height: 24px;
  color: ${getLabelColor};
`;

export const IconContainerSt = styled.span`
  display: flex;
  flex-basis: ${(props) => `${parseInt(props.size, 10) + 8}px`};
  flex-direction: column;
  align-items: ${(props) => (props.isRight ? 'flex-end' : null)};
`;

export const IconSt = styled.i`
  font-size: ${(props) => `${props.size}px`};
  cursor: pointer;
  line-height: normal;
  color: ${getIconColors};

  &:active {
    color: ${({ disabled, theme: { colors } }) =>
      !disabled && colors.brandPrimary};
  }
`;

export const ErrorIconSt = styled.i`
  font-size: 14px;
  color: ${({ theme: { colors } }) => colors.brandDanger};
  margin-top: 11px;
  margin-left: 4px;
  ${(props) =>
    props.isRight &&
    css`
      margin-right: 4px;
    `};
`;

import { closestNumber } from '../../utils/closestNumber';

/* eslint-disable no-mixed-operators */
export const linear = (snapPoints) => ({
  getPosition(value, min, max) {
    return ((value - min) / (max - min)) * 100;
  },

  getValue(pos, min, max) {
    const decimal = pos / 100;
    const value = (max - min) * decimal + min;
    const closest =
      snapPoints && snapPoints.length > 0
        ? closestNumber(snapPoints, value, true)
        : Math.round(value);

    if (pos === 0) {
      return min;
    }
    if (pos === 100) {
      return max;
    }

    return closest;
  },
});

export const geometric = (snapPoints) => ({
  getPosition(x, min, max) {
    return (max / (max - min)) ** 0.5 * ((x - min) / max) ** 0.5 * 100;
  },

  getValue(x, min, max) {
    const value = Math.floor((x / 100) ** 2 * (max - min)) + min;
    const closest =
      snapPoints && snapPoints.length > 0
        ? closestNumber(snapPoints, value, true)
        : value;

    return closest;
  },
});

export const logarithmic = (snapPoints) => ({
  getPosition(value, min, max) {
    const minv = Math.log(min);
    const maxv = Math.log(max);
    const scale = (maxv - minv) / 100;

    return (Math.log(value) - minv) / scale;
  },

  getValue(positionPercent, min, max) {
    const minv = Math.log(min);
    const maxv = Math.log(max);
    const scale = (maxv - minv) / 100;
    const value = Math.floor(Math.exp(minv + scale * positionPercent)) || 0;
    const closest =
      snapPoints && snapPoints.length > 0
        ? closestNumber(snapPoints, value, true)
        : value;

    if (positionPercent === 0) {
      return min;
    }
    if (positionPercent === 100) {
      return max;
    }

    return closest;
  },
});
/* eslint-enable no-mixed-operators */

import { Flex } from '@rebass/grid';
import styled, { css } from 'styled-components';
import { Button } from 'app/shared/ui';

import { media } from 'app/shared/styles_js/variables';

export const BannerSt = styled(Flex).attrs(() => ({
  flexDirection: 'row',
  py: '7px',
}))`
  width: 100%;
  height: fit-content;
  min-height: 64px;
  align-items: center;
  justify-content: center;
  margin: auto;
  font-size: 14px;
  background-color: ${({ type, color, theme: { colors } }) => {
    const successBackground = 'mintSuperLight';
    const errorBackground = colors.ferrariDark;
    return type === 'success'
      ? colors[color || successBackground]
      : errorBackground;
  }};

  div {
    align-items: center;
    justify-content: center;
    cursor: default;
    color: ${({ type, theme: { colors } }) => {
      const successColor = colors.mintDark;
      return type === 'success' ? successColor : colors.white;
    }};
  }

  div button {
    padding-left: 16px;
    border: none;
    color: ${({ type, theme: { colors } }) => {
      const successColor = colors.micaBlue;
      return type === 'success' ? successColor : colors.white;
    }};
    background: none;
    cursor: pointer;

    &:hover {
      color: ${({ type, theme: { colors } }) => {
        const successColor = colors.tarmacGrey;
        return type === 'success' ? successColor : colors.white;
      }};
    }
  }

  ${media.md(css`
    height: 64px;
  `)};
`;

export const CtaButtonSt = styled(Button).attrs(() => ({
  type: 'button',
  width: 1,
}))``;

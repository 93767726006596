import styled from 'styled-components';
import {
  space,
  borderRadius,
  size,
  maxHeight,
  maxWidth,
  width,
  height,
} from 'styled-system';

export const ImageSt = styled.img`
  ${space}
  ${borderRadius}
  ${size}
  ${maxWidth}
  ${maxHeight}
  ${width}
  ${height}
`;

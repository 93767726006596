export const API_ERROR = 'FDL/CALCULATOR/API_ERROR';

/**
 * @description Puts calculator on loading state to get values from API
 */
export const API_LOADING = 'FDL/CALCULATOR/API_LOADING';

/**
 * @description Sets initial values or updates calculator values from API response
 */
export const SET_DATA = 'FDL/CALCULATOR/SET_DATA';

/**
 * @description Updates calculator's selected/altered option/value to make new API request
 */
export const SET_SELECTED_VALUE = 'FDL/CALCULATOR/SET_SELECTED_VALUE';

/**
 * @description Financing calculator's initial redux state
 */
export const defaultState = {
  loading: true,
  data: null,
  error: false,
  errorMsg: null,
  isResetToPrecalc: false,
};
export const CHANGE_HAPPENED = 'FDL/CALCULATOR/CHANGE_HAPPENED';
export const RESET_IS_CHANGED = 'FDL/CALCULATOR/RESET_IS_CHANGED';
export const UNSET_CALCULATION_FOR_VEHICLE =
  'FDL/CALCULATOR/UNSET_CALCULATION_FOR_VEHICLE';
export const UPDATE_CALCULATIONS = 'FDL/CALCULATOR/UPDATE_CALCULATIONS';
/**
 * @description Sets changes to default precalc
 */
export const SHOW_RESET_MESSAGE = 'FDL/CALCULATOR/SHOW_RESET_MESSAGE';

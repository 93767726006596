import { LazyContactDealerChoice as ContactDealerChoice } from 'app/marketplace/contactDealer/ContactDealerChoice/LazyContactDealerChoice';

const actions = {
  contactDealerChoice: ContactDealerChoice,
};

/**
 * Define Sidebar to open based on action type
 * @param {string} action The lead action type
 * @returns {[*]}
 */
export const defineSidebar = action => {
  return [actions[action]];
};

/**
 * Verify if action type is invalid and returns true if it is
 * @param {string} action The Action type
 * @returns {boolean}
 */
export const isNotValidLeadAction = action => !(action in actions);

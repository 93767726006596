import React, { memo } from 'react';

import { LoadingAnimationContainerSt } from './LoadingAnimation.css';

// Follow the readme file for its usage until https://heycar.atlassian.net/browse/COP-186 gets fixed
const LoadingAnimationCmp = ({
  isLoading = false,
}: {
  isLoading?: boolean;
}) => {
  return isLoading ? <LoadingAnimationContainerSt /> : null;
};

export const LoadingAnimation = memo(LoadingAnimationCmp);

import { css } from 'styled-components';

import { color } from 'app/shared/styles_js/variables';

export const CartileLoadingStyle = css`
  color: ${color.grayLight} !important;
  background-color: ${color.grayLight};
  pointer-events: none;

  & * {
    opacity: 0;
  }

  &:hover {
    color: ${color.grayLight};
  }
`;

export const CartileFlexboxEllipsisSt = css`
  min-width: 0;
  max-width: 80%;
  & > * {
    display: flex;
    text-overflow: ellipsis;
    align-items: center;
  }
`;

import {
  PARTNER_DEALER_GET,
  PARTNER_DEALER_GET_SUCCESS,
  PARTNER_DEALER_GET_ERROR,
  PARTNER_DEALER_RESET,
} from '../../constants';

export const initialState = {
  locations: [],
  noResults: false,
  error: undefined,
  isLoading: false,
};

export function partnerDealer(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case PARTNER_DEALER_GET:
      return { ...state, error: undefined, isLoading: true };
    case PARTNER_DEALER_GET_SUCCESS:
      return {
        ...state,
        isLoading: false,
        locations: payload?.results || payload?.suggestions || [],
        noResults: !Array.isArray(payload?.results),
      };
    case PARTNER_DEALER_GET_ERROR:
      return { ...state, error: payload, isLoading: false };
    case PARTNER_DEALER_RESET:
      return { ...initialState };
    default:
      return state;
  }
}

/* istanbul ignore file */
import { fork, all } from 'redux-saga/effects';

import { homeSagas } from 'app/marketplace/home/sagas';
import { searchSagas } from 'app/marketplace/search/sagas';
import { accountSagas } from 'app/marketplace/accounts/sagas';
import { overlayRules } from 'app/shared/modules/overlay/sagas';
import { contentfulSagas } from 'app/marketplace/contentful/sagas';
import { specialOfferSagas } from 'app/marketplace/specialOffers/sagas';
import { contactDealerSagas } from 'app/marketplace/contactDealer/sagas';
import { mobileFilterSaga } from 'app/marketplace/mobileFilterPage/sagas';
import { sagas as tradeInSagas } from 'app/shared/modules/FDL/tradein/redux';
import { filterPanelSagas } from 'app/marketplace/home/Stage/FilterPanel/sagas';
import { calculatorSagas } from 'app/shared/modules/FDL/CalculatorV3/redux/sagas';
import { searchFilterSagas } from 'app/marketplace/search/filters/redux/sagas';

export function* rootSaga() {
  const sagas = [
    fork(homeSagas),
    fork(searchSagas),
    fork(searchFilterSagas),
    fork(accountSagas),
    fork(overlayRules),
    fork(tradeInSagas),
    fork(calculatorSagas),
    fork(mobileFilterSaga),
    fork(filterPanelSagas),
    fork(specialOfferSagas),
    fork(contentfulSagas),
    fork(contactDealerSagas),
  ];

  yield all(sagas);
}

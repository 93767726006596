import styled, { css } from 'styled-components';

import { media } from 'app/shared/styles_js/variables';
import { bannerSizes } from 'app/shared/modules/Campaign/GAM/ad-units/_clp-banner';

export const BannerContainerSt = styled.article`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 100%;
  /* stylelint-disable-next-line */
  aspect-ratio: ${`auto ${bannerSizes.sm.width} / ${bannerSizes.sm.height}`};

  @supports not (
    aspect-ratio: ${`auto ${bannerSizes.sm.width} / ${bannerSizes.sm.height}`}
  ) {
    min-height: ${bannerSizes.sm.height}px;
  }

  margin-bottom: 40px;
  margin-right: 12px;
  margin-left: 12px;
  border-radius: 4px;
  overflow: hidden;

  ${media.md(css`
    width: ${bannerSizes.md.width}px;
    height: ${bannerSizes.md.height}px;
    min-height: inherit;

    ${(props) =>
      props?.showCLPBannerInDifferentPosition
        ? /* istanbul ignore next */
          css`
            margin-bottom: 24px;
            margin-top: 8px;
          `
        : css`
            margin-bottom: 32px;
          `}
    margin-right: 0;
    margin-left: 0;
    border-radius: 0;
    overflow: initial;
  `)}

  ${media.lg(css`
    width: ${bannerSizes.lg.width}px;
    height: ${bannerSizes.lg.height}px;
  `)}

  ${media.xl(css`
    width: ${bannerSizes.xl.width}px;
    height: ${bannerSizes.xl.height}px;
    margin-right: 12px;
    margin-left: 12px;
  `)}
`;

export const SpecialOffersBannerSt = styled.img`
  overflow: hidden;
  width: 100%;
  height: auto;
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.15);
  border-radius: 4px;

  ${media.md(css`
    margin-top: 0;
    box-shadow: none;
    border-radius: 0;
  `)}
`;

import { memo } from 'react';
import PropTypes from 'prop-types';

import { CartileLoadingStyle } from 'app/shared/modules/Cartile/CartileUIComponents/CartileUIComponents.css';
import { CartileMtlPrice } from 'app/shared/modules/Cartile/CartileUIComponents/CartilePrice/CartileMtlPrice/CartileMtlPrice';
import { CartileTotalPrice } from 'app/shared/modules/Cartile/CartileUIComponents/CartilePrice/CartileTotalPrice/CartileTotalPrice';
import { CartilePriceContainersSt } from 'app/shared/modules/Cartile/CartileUIComponents/CartilePrice/CartilePriceContainers/CartilePriceContainers.css';
import { CartileMtlPriceCalculatorLink } from 'app/shared/modules/Cartile/CartileUIComponents/CartilePrice/CartileMtlPriceCalculatorLink/CartileMtlPriceCalculatorLink';

const CartilePriceMTLDefaultCmp = ({
  text,
  price,
  target,
  linkTo,
  isLoading,
  monthlyRate,
  vatReclaimable,
  handleTracking,
}) => (
  <CartilePriceContainersSt
    isLoading={isLoading}
    css={[isLoading && CartileLoadingStyle]}
  >
    {monthlyRate ? (
      <CartileMtlPrice
        linkTo={linkTo}
        target={target}
        fontsize="16px"
        monthlyRate={monthlyRate}
        handleTracking={handleTracking}
      />
    ) : (
      <CartileMtlPriceCalculatorLink
        text={text}
        linkTo={linkTo}
        fontsize="12px"
        handleTracking={handleTracking}
      />
    )}
    <CartileTotalPrice
      size="small"
      price={price}
      vatReclaimable={vatReclaimable}
    />
  </CartilePriceContainersSt>
);

CartilePriceMTLDefaultCmp.propTypes = {
  isLoading: PropTypes.bool,
  monthlyRate: PropTypes.number,
  text: PropTypes.object.isRequired,
  price: PropTypes.number.isRequired,
  linkTo: PropTypes.string.isRequired,
  target: PropTypes.string.isRequired,
  vatReclaimable: PropTypes.bool.isRequired,
  handleTracking: PropTypes.func.isRequired,
};

CartilePriceMTLDefaultCmp.defaultProps = {
  isLoading: false,
  monthlyRate: null,
};

export const CartilePriceMTLDefault = memo(CartilePriceMTLDefaultCmp);

import * as ActionTypes from './constants';

interface HomeReducer {
  pending: number;
  vehicleCount: number;
  error: string | null;
  isLoading: boolean;
}

// Discriminating Union type with a common field
type Action =
  | {
      type: 'REQUEST_PENDING';
    }
  | {
      type: 'REQUEST_ERROR';
      error: string | null;
    }
  | {
      type: 'GET_VEHICLE_COUNT';
      payload: number;
    }
  | {
      type: 'SET_LOADING';
      payload: boolean;
    };

export const defaultState = (): HomeReducer => {
  return {
    pending: 0,
    vehicleCount: 0,
    error: null,
    isLoading: true,
  };
};

const decrementPending = (state: Pick<HomeReducer, 'pending'>) => {
  return state.pending > 0 ? state.pending - 1 : 0;
};

export function home(state = defaultState(), action: Action): HomeReducer {
  switch (action.type) {
    case ActionTypes.REQUEST_PENDING:
      return { ...state, pending: state.pending + 1 };
    case ActionTypes.REQUEST_ERROR:
      return {
        ...state,
        error: action.error,
        pending: decrementPending(state),
      };
    case ActionTypes.GET_VEHICLE_COUNT:
      return {
        ...state,
        vehicleCount: action.payload,
        pending: decrementPending(state),
        error: null,
      };
    case ActionTypes.SET_LOADING:
      return { ...state, isLoading: action.payload };
    default: {
      return state;
      // The ideal treatment is having TypeScript to notice the developer that a value is missing.
      //  The removal of a choice is handled by default since the value doesn’t exist, hence TypeScript won’t compile.
      // An exhaustive check needs to be placed to manage any new value.

      // Commenting the exhaustive check for now because there are other action types getting triggered
      // on this reducer which needs home reducer to return current  state
      // return exhaustiveCheck(action);
    }
  }
}

import { ILazyLoadScriptOptions } from '../scripts.types';

import { addScriptIf } from './addScriptIf';
import { boolString, prepareHTML } from './_formatters';

// written in es5 for backwards compatibility,
// since this is the initial markup rendered from the server
// please edit carefully
const SCRIPT_PART = {
  getSetupString: (loadCallback: string): string => `
    var loadTimeout;
    var setupFn = ${loadCallback}
    var loadFn = setupFn();
    `,
  getTriggerLoadFnString: () => `
    function triggerLoad() {
      clearTimeout(loadTimeout);
      loadFn();
      window.removeEventListener('load', triggerLoadAfterTimeout);
      window.removeEventListener('click', triggerLoad);
      window.removeEventListener('scroll', triggerLoad);
    }`,
  getTriggerLoadAfterTimeoutFnString: (
    msDelay: number,
  ) => `function triggerLoadAfterTimeout() {
      loadTimeout = setTimeout(triggerLoad, ${msDelay});
    }`,
  getAddEventListenersString: () => `   
    window.addEventListener('load', triggerLoadAfterTimeout);
    window.addEventListener('click', triggerLoad);
    window.addEventListener('scroll', triggerLoad);`,
};

export const lazyLoadScript = (productionMode: boolean) => ({
  isIIFE,
  msDelay,
  loadCallback,
  loadFnName,
  loadFnArg,
  loadedStatusName,
  extraSetup,
  isTestEnv = process.env.NODE_ENV === 'test',
}: ILazyLoadScriptOptions): string =>
  prepareHTML(isTestEnv)(`
    ${extraSetup || ''}
    window.${loadedStatusName} = ${boolString(productionMode && isIIFE)};
    window.${loadFnName} = function(${loadFnArg || ''}) {};
    ${addScriptIf(productionMode)(`
    window.${loadFnName} = (function(${loadFnArg || ''}){
      ${SCRIPT_PART.getSetupString(loadCallback)}
      ${SCRIPT_PART.getTriggerLoadFnString()}      
      ${SCRIPT_PART.getTriggerLoadAfterTimeoutFnString(msDelay)}
      ${SCRIPT_PART.getAddEventListenersString()}
    })${isIIFE ? '()' : ''};
`)}`);

import { Children, PureComponent } from 'react';
import PropTypes from 'prop-types';

import { ListItemIconSt, ListItemSt, ListSt } from './IconList.css';

export class IconList extends PureComponent {
  render() {
    const { icon, children } = this.props;

    return (
      <ListSt>
        {Children.map(children, (child, idx) => (
          <ListItemSt key={idx}>
            <ListItemIconSt color="brandPrimary" as="i" className={icon} />
            {child}
          </ListItemSt>
        ))}
      </ListSt>
    );
  }
}

IconList.propTypes = {
  children: PropTypes.any,
  icon: PropTypes.string,
};

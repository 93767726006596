/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { Children, useState, cloneElement, ReactElement } from 'react';

import { Container } from 'app/shared/ui';
import {
  PageTemplateContainerSt,
  PageTemplateContentWrapperSt,
} from 'app/shared/modules/pageTemplate/PageTemplate/PageTemplate.css';
import {
  isShowVatDisclaimer,
  getPaddingTopForPageTemplate,
} from 'app/shared/modules/pageTemplate/PageTemplate/utils';
import { Footer } from 'app/shared/components/Footer/Footer';
import { ConnectedTopBanner } from 'app/marketplace/shared/TopBanner/ConnectedTopBanner';
import { DisclaimerMsg } from 'app/marketplace/shared/components/DisclaimerMsg/DisclaimerMsg';
import {
  ICampaign,
  TCampaignData,
} from 'app/shared/modules/Campaign/Campaign.types';
import { ConnectedNotificationCenter } from 'app/shared/modules/notifications/ConnectedNotificationCenter';
import { ConnectedNewMarketplaceTopNavBar } from 'app/marketplace/shared/NewMarketplaceTopNavBar/ConnectedNewMarketplaceTopNavBar';
import { ConnectedSlideInBannersController } from 'app/marketplace/SlideInBanners/SlideInBannersController/ConnectedSlideInBannersController';
import { SeoFooterLinks } from 'app/shared/components/SeoFooterLinks/SeoFooterLinks';
import { TBreakpoint } from 'app/types/style.types';
import { IMarketplaceTopNavBarCmpProps } from 'app/marketplace/shared/NewMarketplaceTopNavBar/NewMarketplaceTopNavBar.types';

export interface IPageTemplate {
  title: string;
  target: string;
  isHome: boolean;
  isLoading: boolean;
  isStaging: boolean;
  hasOverlay: boolean;
  location: ILocation;
  favouriteIds: string[];
  children: ReactElement;
  campaigns: ICampaign[];
  accountRoute?: 'login' | 'signup' | 'profile' | 'searches';
  breakpoint: TBreakpoint;
  campaignData: TCampaignData;
}

// eslint-disable-next-line react/no-multi-comp
export const PageTemplate = ({
  title,
  isHome,
  children,
  location,
  isLoading,
  isStaging,
  campaigns,
  hasOverlay,
  accountRoute,
  favouriteIds,
  target: defaultTarget,
  breakpoint,
  campaignData,
}: IPageTemplate): JSX.Element => {
  const [target, setTarget] = useState<string>(defaultTarget);

  const topNav = {
    setTarget,
  };

  // solve compatibility issue between tsx component rendering connected jsx component
  const fakeProps = {};
  const newMarketplaceTopNavBarProps: Pick<
    IMarketplaceTopNavBarCmpProps,
    'title' | 'target' | 'isHome' | 'location' | 'accountRoute' | 'isLoading'
  > = {
    title,
    target,
    isHome,
    location,
    accountRoute,
    isLoading: Boolean(isLoading),
  };

  const isMobileOrTablet = breakpoint === 'sm' || breakpoint === 'md';

  const hasPromotion = campaignData?.primaryCampaign;

  const hasPromotionOnPDP =
    location?.pathname?.startsWith('/vehicle/') && hasPromotion;

  const ContentWrapperPaddingTop = hasPromotionOnPDP
    ? '40px'
    : getPaddingTopForPageTemplate(location?.pathname);

  const shouldShowSEOLinks =
    !location.pathname.includes('checkout') &&
    !location.pathname.includes('damage-report');

  return (
    <PageTemplateContainerSt>
      <ConnectedNewMarketplaceTopNavBar {...newMarketplaceTopNavBarProps} />

      <ConnectedTopBanner location={location} />

      <ConnectedNotificationCenter {...(fakeProps as any)} />
      <PageTemplateContentWrapperSt
        id="content-wrapper"
        flexDirection="column"
        isLoading={isLoading}
        ContentWrapperPaddingTop={ContentWrapperPaddingTop}
      >
        {Children.map(children, (child) => {
          return cloneElement(child, {
            topNav,
            location,
            isStaging,
          });
        })}

        {isShowVatDisclaimer(location?.pathname, favouriteIds.length === 0) && (
          <Container>
            <DisclaimerMsg
              pathname={location?.pathname}
              search={location?.search}
              campaigns={campaigns}
            />
          </Container>
        )}
        {!isLoading && !hasOverlay && (
          <>
            {shouldShowSEOLinks && <SeoFooterLinks />}
            <Footer target={target} isMobile={isMobileOrTablet} />
          </>
        )}
      </PageTemplateContentWrapperSt>
      <ConnectedSlideInBannersController
        pathname={location?.pathname}
        target={target}
      />
    </PageTemplateContainerSt>
  );
};

// @ts-ignore
export const { TopNavDefaultProps, TopNavPropShape } = PageTemplate;

/* eslint-disable @typescript-eslint/no-unsafe-return */
import { TBreakpoint } from 'app/types/style.types';

import { Angebote } from './listItems/Angebote/Angebote';
import { AutoAbo } from './listItems/AutoAbo/AutoAbo';
import { CarSearchItem } from './listItems/CarSearchItem/CarSearchItem';
import { PaymentItem } from './listItems/PaymentItem/PaymentItem';
import { Ratgeber } from './listItems/Ratgeber/Ratgeber';
// keep the same order

export const getMenuItems = ({
  isMobileDevice,
  breakpoint,
  isFlag256ShowNewPromotionTiles,
}: {
  isMobileDevice: boolean;
  breakpoint: TBreakpoint;
  isFlag256ShowNewPromotionTiles?: boolean;
}): any[] => {
  const params = {
    isMobileDevice,
    breakpoint,
    isFlag256ShowNewPromotionTiles,
  };
  return isMobileDevice
    ? [
        CarSearchItem(isMobileDevice),
        PaymentItem(isMobileDevice),
        Angebote(params),
        Ratgeber(isMobileDevice),
        AutoAbo,
      ]
    : [
        CarSearchItem(isMobileDevice),
        Angebote(params),
        Ratgeber(isMobileDevice),
        AutoAbo,
        PaymentItem(isMobileDevice),
      ];
};

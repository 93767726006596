import React, { useEffect, useRef } from 'react';

import {
  HeadlineSt,
  SliderTrustPilotWidgetInnerContainer,
  SliderTrustPilotWidgetSt,
} from './SliderTrustPilotWidget.css';

const SliderTrustPilotWidget = (): JSX.Element => {
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (window?.Trustpilot) {
      window.Trustpilot.loadFromElement(ref?.current, true);
    }
  }, []);
  return (
    <SliderTrustPilotWidgetSt>
      <SliderTrustPilotWidgetInnerContainer>
        <HeadlineSt>Das sagen unsere Kunden</HeadlineSt>
        <div
          className="trustpilot-widget"
          data-locale="de-DE"
          data-template-id="54ad5defc6454f065c28af8b"
          data-businessunit-id="5c65db740526720001ee299b"
          data-style-height="240px"
          data-style-width="100%"
          data-theme="light"
          data-stars="4,5"
          data-review-languages="de"
          ref={ref}
        />
      </SliderTrustPilotWidgetInnerContainer>
    </SliderTrustPilotWidgetSt>
  );
};
export default SliderTrustPilotWidget;

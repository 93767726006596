/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import {
  SALES_OFFER_PUT,
  SALES_OFFER_PUT_SUCCESS,
  SALES_OFFER_PUT_ERROR,
} from '../../constants';

export const initialState = {
  isLoading: false,
  success: false,
};

export function salesOffer(
  state = initialState,
  action: { type: string; payload: string },
) {
  const { type, payload } = action;

  switch (type) {
    case SALES_OFFER_PUT:
      return { ...state, error: undefined, isLoading: true };
    case SALES_OFFER_PUT_SUCCESS:
      return { ...state, error: undefined, isLoading: false, success: true };
    case SALES_OFFER_PUT_ERROR:
      return { ...state, error: payload, isLoading: false, success: false };
    default:
      return state;
  }
}

import { connect } from 'react-redux';

import { breakpointSelector } from 'app/shared/modules/globalEvents/selectors';
import { openOverlay, closeOverlay } from 'app/shared/modules/overlay/actions';
import {
  signupForNewsletterWithConsent,
  initiateCall,
} from 'app/marketplace/vehicle/actions';

import { CartileCallCta } from './CartileCallCta';
import { ICartileCallCtaProps } from './CartileCallCta.types';

const mapToProps = (state: TRootState): ICartileCallCtaProps => {
  return {
    breakpoint: breakpointSelector(state) as string,
    location: state.routing.locationBeforeTransitions as ILocation,
    isPhoneActionLoading: state.contactDealer.call.loading as boolean,
    openOverlay,
    closeOverlay,
    signupForNewsletterWithConsent,
    initiateCall,
  };
};

export const ConnectedCartileCallCta = connect<ICartileCallCtaProps>(
  mapToProps,
  {
    openOverlay,
    closeOverlay,
    signupForNewsletterWithConsent,
    initiateCall,
  },
)(CartileCallCta);

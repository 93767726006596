import { memo } from 'react';
import PropTypes from 'prop-types';

import { CartileLoadingStyle } from 'app/shared/modules/Cartile/CartileUIComponents/CartileUIComponents.css';
import { CartileTotalPrice } from 'app/shared/modules/Cartile/CartileUIComponents/CartilePrice/CartileTotalPrice/CartileTotalPrice';
import { CartilePriceContainersSt } from 'app/shared/modules/Cartile/CartileUIComponents/CartilePrice/CartilePriceContainers/CartilePriceContainers.css';
import { CartileMtlPriceCalculatorLink } from 'app/shared/modules/Cartile/CartileUIComponents/CartilePrice/CartileMtlPriceCalculatorLink/CartileMtlPriceCalculatorLink';

const CartilePriceDefaultReducedCmp = ({
  text,
  price,
  linkTo,
  isLoading,
  highestPrice,
  vatReclaimable,
  handleTracking,
  showLinkToPDPFinancingSection,
  hideDccVehicle,
}) => (
  <CartilePriceContainersSt
    isLoading={isLoading}
    css={[isLoading && CartileLoadingStyle]}
  >
    <CartileTotalPrice
      discount
      size="large"
      price={price}
      vatReclaimable={vatReclaimable}
      highestPrice={highestPrice}
    />
    {showLinkToPDPFinancingSection && !hideDccVehicle && (
      <CartileMtlPriceCalculatorLink
        text={text}
        fontsize="12px"
        linkTo={linkTo}
        handleTracking={handleTracking}
      />
    )}
  </CartilePriceContainersSt>
);

CartilePriceDefaultReducedCmp.propTypes = {
  isLoading: PropTypes.bool,
  highestPrice: PropTypes.number,
  text: PropTypes.object.isRequired,
  price: PropTypes.number.isRequired,
  linkTo: PropTypes.string.isRequired,
  vatReclaimable: PropTypes.bool.isRequired,
  handleTracking: PropTypes.func.isRequired,
  showLinkToPDPFinancingSection: PropTypes.bool,
};

CartilePriceDefaultReducedCmp.defaultProps = {
  highestPrice: 0,
  isLoading: false,
  showLinkToPDPFinancingSection: false,
};

export const CartilePriceDefaultReduced = memo(CartilePriceDefaultReducedCmp);

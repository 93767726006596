/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';

import * as Styled from './index.css';
import { THiddenContainer } from './types';

export const HiddenContainer = (props: THiddenContainer): JSX.Element => {
  const { children } = props;

  return <Styled.HiddenContainer {...props}>{children}</Styled.HiddenContainer>;
};

import styled from 'styled-components';

export const CartileLinkContainerSt = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  cursor: ${({ isUnavailable }) => (isUnavailable ? 'default' : 'pointer')};
`;

import * as ActionTypes from './constants';
import { updateMessages } from './utils';

export const defaultState = () => {
  return {
    messages: [],
    soldOutVehicleIds: [],
    recaptchaWidgetId: null,
    call: {
      loading: false,
      browserSessionId: null,
      apiError: null,
      phonePin: null,
      dealerPhoneNumber: null,
      displayNumber: null,
    },
    callback: {
      loading: false,
      apiSuccess: false,
      apiError: null,
    },
    emailOrVideo: {
      loading: false,
      apiSuccess: false,
      apiError: null,
      vouchers: null,
    },
  };
};

export function contactDealer(state = defaultState(), action) {
  const { type, payload } = action;

  switch (type) {
    case ActionTypes.RESET:
      return {
        ...defaultState(),
        messages: state.messages, // let's keep the messages
      };

    case ActionTypes.RESET_PHONE_INFO:
      return {
        ...state,
        call: {
          ...state.call,
          loading: false,
          phonePin: null,
          dealerPhoneNumber: null,
        },
      };

    case ActionTypes.GET_PHONE_INFO_PENDING:
      return {
        ...state,
        call: { ...defaultState().call, loading: true },
      };

    case ActionTypes.GET_PHONE_INFO_SUCCESS:
      return {
        ...state,
        call: {
          ...state.call,
          loading: false,
          dealerPhoneNumber: payload.phoneNumber,
          displayNumber: payload.displayNumber,
          phonePin: payload.pin,
          browserSessionId: payload.browserSessionId,
          apiError: null,
        },
      };

    case ActionTypes.GET_PHONE_INFO_FAILURE:
      return {
        ...state,
        call: {
          ...state.call,
          loading: false,
          phonePin: null,
          dealerPhoneNumber: null,
          apiError: payload.error,
        },
      };

    case ActionTypes.RESET_CALLBACK:
      return {
        ...state,
        callback: defaultState().callback,
      };

    case ActionTypes.SUBMIT_CALLBACK_PENDING:
      return {
        ...state,
        callback: {
          ...state.callback,
          loading: true,
          apiSuccess: false,
          apiError: null,
        },
      };

    case ActionTypes.SUBMIT_CALLBACK_SUCCESS:
      return {
        ...state,
        callback: {
          ...state.callback,
          loading: false,
          apiSuccess: true,
          apiError: null,
        },
      };

    case ActionTypes.SUBMIT_CALLBACK_FAILURE:
      return {
        ...state,
        callback: {
          ...state.callback,
          loading: false,
          apiSuccess: false,
          apiError: payload.error,
        },
      };

    case ActionTypes.SAVE_MESSAGE:
      return {
        ...state,
        messages: updateMessages(payload, state),
      };

    case ActionTypes.UNSET_MESSAGE_FOR_VEHICLE:
      return {
        ...state,
        messages: state.messages.filter(
          (message) =>
            message.id !== payload.id || message.type !== payload.leadType,
        ),
      };

    case ActionTypes.RESET_MAIL_VIDEO:
      return {
        ...state,
        emailOrVideo: defaultState().emailOrVideo,
      };

    case ActionTypes.SUBMIT_AUTOBILD_PENDING:
    case ActionTypes.SUBMIT_MAIL_VIDEO_PENDING:
      return {
        ...state,
        emailOrVideo: {
          ...state.emailOrVideo,
          loading: true,
          apiSuccess: false,
          apiError: null,
          vouchers: null,
        },
      };

    case ActionTypes.SUBMIT_MAIL_VIDEO_SUCCESS:
      return {
        ...state,
        emailOrVideo: {
          ...state.emailOrVideo,
          loading: false,
          apiSuccess: true,
          apiError: null,
        },
      };

    case ActionTypes.SUBMIT_SELECTABLE_ONE_CLICK_LEADS_PENDING:
      return {
        ...state,
        payload,
      };

    case ActionTypes.SUBMIT_CALLBACK_SELECTABLE_ONE_CLICK_LEADS_PENDING:
      return {
        ...state,
        payload,
      };

    case ActionTypes.SUBMIT_MAIL_VIDEO_FAILURE:
      return {
        ...state,
        emailOrVideo: {
          ...state.emailOrVideo,
          loading: false,
          apiSuccess: false,
          apiError: payload.error,
        },
      };

    case ActionTypes.VOUCHER_POST_LEAD:
      return {
        ...state,
        emailOrVideo: {
          ...state.emailOrVideo,
          vouchers: payload,
        },
      };

    case ActionTypes.ADD_SOLD_OUT_VEHICLE_ID:
      return {
        ...state,
        soldOutVehicleIds: [
          ...new Set(state.soldOutVehicleIds.concat(payload)),
        ],
      };

    case ActionTypes.RESET_SOLD_OUT_VEHICLE_IDS:
      return {
        ...state,
        soldOutVehicleIds: [],
      };

    case ActionTypes.SET_RECAPTCHA_CHALLENGE_ID:
      return {
        ...state,
        recaptchaWidgetId: payload,
      };

    default:
      return state;
  }
}

import { memo } from 'react';
import PropTypes from 'prop-types';

import { CartileContentInfosWrapperSt } from 'app/shared/modules/Cartile/CartileTemplates/CartileContentContainer/CartileContentInfos/CartileContentInfos.css';

const CartileContentInfosCmp = ({ children, isHorizontal }) => {
  return (
    <CartileContentInfosWrapperSt isHorizontal={isHorizontal}>
      {children}
    </CartileContentInfosWrapperSt>
  );
};

CartileContentInfosCmp.propTypes = {
  children: PropTypes.node.isRequired,
  isHorizontal: PropTypes.bool.isRequired,
};

export const CartileContentInfos = memo(CartileContentInfosCmp);

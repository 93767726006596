import { push, replace } from 'react-router-redux';

import { checkFeatureFlagHasToken } from 'app/selectors';
import { MOBILE_CARTILE_PAGINATION_SIZE, WEB_APP_URL } from 'app/config';
import * as AppActionTypes from 'app/constants';
import * as ActionTypes from 'app/marketplace/search/constants';
import {
  getFiltersFromUri,
  getQueryFromFilters,
} from 'app/marketplace/filter/utils';
import { getIsBuyOnlineVehicle } from 'app/marketplace/utils/getIsD2CVehicle';
import { isAbortError } from 'app/shared/utils/utils';
import {
  getLocationFromCoords,
  resetLocation,
  updateLocationFromUri,
} from 'app/marketplace/geolocation/actions';
import { abortableClientApiCall, apiCall } from 'app/actions';
import { updateVehicleCount } from 'app/marketplace/home/actions';
import {
  overrideFilters,
  resetFilterCategory,
  resetFilters as resetAllFilters,
  setFilters,
  updateAllMakes,
  updateAllUsedCarSeal,
  updateFilters,
  updatePossibleMakes,
  updatePossibleModels,
} from 'app/marketplace/filter/actions';
import * as VehicleActionTypes from 'app/marketplace/vehicle/constants';
import { sanitizeVehicle } from 'app/marketplace/vehicle/utils';
import { getSearchParamsFromVehicleSelected } from 'app/marketplace/search/QuickViewClp/utils';
import { getSEOData } from 'app/marketplace/seo/actions';
import { getPropositionBarEntries } from 'app/marketplace/contentful/shared/ConnectedPropositionBar/actions';
import { getFavouriteVehicleIds } from 'app/marketplace/favourites/actions';
import { DEFAULT_RADIUS } from 'app/marketplace/filter/Filters/Filter.constant';

import { getTotalListingsCount } from './redux/actions';

/* Below two actions creater are for showing and hiding the vehicle count on CLP (seoheading), mobile filter (submit button) and detail filter (submit button) */
export const hideVehicleCount = () => {
  return { type: ActionTypes.HIDE_VEHICLE_COUNT };
};
export const showVehicleCount = () => {
  return { type: ActionTypes.SHOW_VEHICLE_COUNT };
};

let vehiclesAbortInstance;
export const getVehicles = (
  pageSize = MOBILE_CARTILE_PAGINATION_SIZE,
  preventLoadingState = false,
) => {
  return (dispatch, getState) => {
    const state = getState();

    if (!preventLoadingState) {
      dispatch({ type: ActionTypes.SEARCH_REQUEST_PENDING });
    }
    // if abort instance exists:
    // - abort the in flight api call
    // - clear abort instance
    /* istanbul ignore next */
    if (typeof vehiclesAbortInstance?.abort === 'function') {
      vehiclesAbortInstance.abort();
      vehiclesAbortInstance = undefined;
    }
    const query = getQueryFromFilters(state.filter, true);
    const queryParams = query ? `&${query}` : '';
    const staticUrl = `/search?reducedContent=true&size=${pageSize}`;
    const url = `${staticUrl}${queryParams}`;

    // dispatch api call and set promise + abort controller instance
    const { promise, abortController } = dispatch(abortableClientApiCall(url));
    vehiclesAbortInstance = abortController;

    return promise
      .then((payload) => {
        dispatch({ type: ActionTypes.SEARCH_GET_VEHICLES, payload, query });
        dispatch(getTotalListingsCount(payload));
        dispatch({ type: ActionTypes.GET_PRICE_RANGE, payload });
        dispatch(updatePossibleModels(payload));
        dispatch(updatePossibleMakes(payload));
        dispatch(updateAllUsedCarSeal(payload));
        dispatch(updateAllMakes(payload));
        dispatch(updateVehicleCount(payload.totalElements));
        dispatch(showVehicleCount()); // show vehicle count on clp after the results arrived.
      })
      .catch((error) => {
        if (isAbortError(error)) {
          dispatch({ type: ActionTypes.SEARCH_REQUEST_CANCELLED });
          dispatch({ type: ActionTypes.SEARCH_GET_VEHICLE_COUNT_CANCELLED });
        } else {
          dispatch({ type: ActionTypes.SEARCH_REQUEST_ERROR, error });
          dispatch({ type: AppActionTypes.SET_SHORT_CIRCUIT, payload: true });
        }
      });
  };
};

let vehicleCountAbortInstance;
export const getVehicleCount = () => {
  return (dispatch, getState) => {
    dispatch({ type: ActionTypes.SEARCH_GET_VEHICLE_COUNT_PENDING });
    /* istanbul ignore next */
    if (typeof vehicleCountAbortInstance?.abort === 'function') {
      vehicleCountAbortInstance.abort();
      vehicleCountAbortInstance = undefined;
    }

    const filters = getState().filter;

    const url = `/search/count?${getQueryFromFilters(filters)}`;

    const { promise, abortController } = dispatch(abortableClientApiCall(url));

    vehicleCountAbortInstance = abortController;
    return promise
      .then((payload) => {
        dispatch(getTotalListingsCount(payload));
        dispatch(updatePossibleModels(payload));
        dispatch(updatePossibleMakes(payload));
        dispatch(updateAllUsedCarSeal(payload));
        dispatch(updateAllMakes(payload));
        dispatch(showVehicleCount());
      })
      .catch((error) => {
        if (isAbortError(error)) {
          dispatch({ type: ActionTypes.SEARCH_GET_VEHICLE_COUNT_CANCELLED });
        } else {
          dispatch({ type: ActionTypes.SEARCH_REQUEST_ERROR, error });
          dispatch({ type: AppActionTypes.SET_SHORT_CIRCUIT, payload: true });
        }
      });
  };
};

/**
 * this is done to save some work in the usual places where getVehicleCount is used, this version contains the call of
 * updateFilters which is needed to use the search functionality
 */
export const getSearchVehicleCount = () => {
  return (dispatch, getState) => {
    dispatch({ type: ActionTypes.SEARCH_GET_VEHICLE_COUNT_PENDING });

    const filters = getState().filter;
    const url = `/search/count?${encodeURI(getQueryFromFilters(filters))}`;

    return dispatch(apiCall(url))
      .then((payload) => {
        dispatch(getTotalListingsCount(payload));
        dispatch(updateFilters(payload));
        dispatch(updatePossibleModels(payload));
        dispatch(updatePossibleMakes(payload));
        dispatch(updateAllUsedCarSeal(payload));
      })
      .catch((error) => {
        dispatch({ type: ActionTypes.SEARCH_REQUEST_ERROR, error });
        dispatch({ type: AppActionTypes.SET_SHORT_CIRCUIT, payload: true });
      });
  };
};

export const getLastSearch = () => (dispatch) => {
  dispatch({ type: ActionTypes.SEARCH_GET_LAST_SEARCH_PENDING });

  return dispatch(apiCall('/last-search', {}, true, true))
    .then((payload) =>
      dispatch({ type: ActionTypes.SEARCH_GET_LAST_SEARCH_SUCCESS, payload }),
    )
    .catch((error) =>
      dispatch({ type: ActionTypes.SEARCH_GET_LAST_SEARCH_FAILURE, error }),
    );
};

export const getReducedCars = (size = 10) => async (dispatch) => {
  dispatch({ type: ActionTypes.SEARCH_GET_REDUCEDPRICE_CARS_PENDING });
  const url = `/search?reducedContent=true&size=${size}&specialOffers=reducedPrice`;

  try {
    const payload = await dispatch(apiCall(url, { method: 'GET' }));
    dispatch({
      type: ActionTypes.SEARCH_GET_REDUCEDPRICE_CARS_SUCCESS,
      payload,
    });
  } catch (error) {
    dispatch({
      type: ActionTypes.SEARCH_GET_REDUCEDPRICE_CARS_FAILURE,
      error,
    });
  }
};

/**
 * Replaces the current url and adds the latest filter options to the query
 * @param {string} pathname url
 */
export const changeUrl = (pathname) => {
  return (dispatch, getState) => {
    dispatch(
      replace({
        pathname,
        search: `?${getQueryFromFilters(getState().filter, false, true)}`,
      }),
    );
  };
};

/**
 * Replaces the landing url to CLP url
 * @param {string} pathname url
 */
export const changeUrlfromLandingPage = (pathname) => {
  return (dispatch, getState) => {
    dispatch(
      push({
        pathname,
        search: `?${getQueryFromFilters(getState().filter, false, true)}`,
      }),
    );
  };
};

export const resetListing = () => {
  return (dispatch) => {
    dispatch({ type: ActionTypes.SEARCH_RESET_LISTING });
  };
};

/**
 *
 * @param {*} activeFilters
 * @param {*} location object from react-router
 * @param {Object} params object from react-router
 */
export const updateLocation = (activeFilters, location, params) => (
  dispatch,
) => {
  const { query } = location;
  dispatch(
    overrideFilters(
      getFiltersFromUri(params, query, {
        make: activeFilters.possibleMakes,
        model: activeFilters.possibleModels,
        usedCarSeal: activeFilters.allUsedCarSeal,
      }),
    ),
  );

  return dispatch(updateLocationFromUri(query));
};

/**
 *
 * @param {*} filter
 * @param {boolean} override
 * @param {boolean} resetSearchTerm
 */
export const onChangeFilter = (
  filter,
  override = false,
  resetSearchTerm = false,
) => (dispatch, getState) => {
  const activeFilters = getState().filter;
  if (activeFilters.page?.length > 0 && activeFilters.page[0].value !== 0) {
    dispatch(resetFilterCategory(['page']));
  }
  if (override) {
    dispatch(overrideFilters(filter));
  } else {
    dispatch(setFilters(filter));
  }
  // Hide the vehicle count presentation before search api request
  dispatch(hideVehicleCount());
  if (resetSearchTerm) {
    dispatch(resetFilterCategory(['q', 'fullQuery']));
  }
};

/**
 *
 * @param {*} url
 */
export const resetSearch = (url) => (dispatch) => {
  dispatch(resetLocation());
  dispatch(resetFilterCategory(['gLocationId', 'radius', 'lat', 'lon']));
  dispatch(changeUrl(url));
};

/**
 * Resets the categories of Filters that where passed to the action
 * @param {*} categories
 */
export const resetFilters = (categories = []) => (dispatch) => {
  if (Array.isArray(categories) && categories.length >= 1) {
    dispatch(resetFilterCategory(categories));
  } else {
    dispatch(resetAllFilters());
    dispatch(resetLocation());
  }
};

export const openSidebarFilters = () => {
  return {
    type: ActionTypes.OPEN_SIDEBAR_FILTERS,
  };
};

export const closeSidebarFilters = () => {
  return {
    type: ActionTypes.CLOSE_SIDEBAR_FILTERS,
  };
};

export const getVehicleSuggestions = (query) => {
  const url = `/search?reducedContent=true&size=8&${query}`;

  return (dispatch) => {
    dispatch({ type: ActionTypes.SEARCH_GET_VEHICLE_SUGGESTIONS_PENDING });

    return dispatch(apiCall(url, { method: 'GET' }))
      .then((payload) =>
        dispatch({
          type: ActionTypes.SEARCH_GET_VEHICLE_SUGGESTIONS_SUCCESS,
          payload,
        }),
      )
      .catch((error) =>
        dispatch({
          type: ActionTypes.SEARCH_GET_VEHICLE_SUGGESTIONS_FAILURE,
          error,
        }),
      );
  };
};

// get D2c vehicle based on count
export const getD2CVehicles = () => async (dispatch, getState) => {
  const state = getState();
  const query = getQueryFromFilters(state.filter, true, false);
  const queryParams = query ? `&${query}` : '';

  const isFlag281ShowCampaignVehicles = checkFeatureFlagHasToken(
    state,
    281,
    'enabled',
  );

  // How many cars are we displaying in CLP
  const searchSizeinCLP = 3;
  const url = isFlag281ShowCampaignVehicles
    ? `/search?reducedContent=true&size=${searchSizeinCLP}&markerId=2303_VWFS${queryParams}`
    : `/search?reducedContent=true&size=${searchSizeinCLP}&productOffering=DE_D2C_CASH${queryParams}`;

  // request data only when d2c filter is not selected and request url is different
  const shouldFetchVehicles = () => {
    const isD2CFilterSelected = !!state.filter.productOffering?.find((filter) =>
      getIsBuyOnlineVehicle(filter.value),
    );

    const isSameSearch = state.search?.d2cVehicles?.url === url;
    return !isD2CFilterSelected && !isSameSearch;
  };

  if (!shouldFetchVehicles()) return;

  dispatch({ type: ActionTypes.SEARCH_GET_VEHICLES_D2C_PENDING });

  try {
    const payload = await dispatch(apiCall(url, { method: 'GET' }));

    dispatch({
      type: ActionTypes.SEARCH_GET_VEHICLES_D2C_SUCCESS,
      payload: { url, ...payload },
    });
  } catch (error) {
    dispatch({
      type: ActionTypes.SEARCH_GET_VEHICLES_D2C_FAILURE,
      error,
    });
  }
};

// TODO : test this action
/* istanbul ignore next */
export const getOneVehicleAndGetSimilarListing = (id) => {
  return (dispatch, getState) => {
    dispatch({ type: VehicleActionTypes.GET_VEHICLE_REQUEST });
    dispatch({ type: ActionTypes.SEARCH_REQUEST_PENDING });
    const url = `/search?id=${id}`;
    return dispatch(apiCall(url))
      .then(async (payload) => {
        dispatch({
          type: VehicleActionTypes.GET_VEHICLE_SUCCESS,
          payload: {
            content: payload.content.map(sanitizeVehicle),
          },
        });

        const {
          make: { id: make },
          model: { id: model },
          categories,
          price,
          dealer: {
            location: { geoCoordinates },
          },
        } = getState().vehicle.vehicle;
        const {
          firstSearchUrl,
          secondSearchUrl,
        } = getSearchParamsFromVehicleSelected(
          make,
          model,
          price,
          geoCoordinates,
          categories,
          DEFAULT_RADIUS,
        );
        dispatch(getLocationFromCoords(geoCoordinates.lat, geoCoordinates.lon));
        const firstResponse = await dispatch(apiCall(firstSearchUrl));
        if (firstResponse.content.length > 10) {
          return firstResponse;
        }
        return dispatch(apiCall(secondSearchUrl));
      })
      .then((payload) => {
        const query = getQueryFromFilters(getState().filter, true);
        dispatch({
          type: ActionTypes.SEARCH_GET_VEHICLES,
          payload,
          query,
        });
        dispatch(getTotalListingsCount(payload));
        dispatch(updatePossibleModels(payload));
        dispatch(updatePossibleMakes(payload));
        dispatch(updateAllUsedCarSeal(payload));
        dispatch(updateAllMakes(payload));
        dispatch(updateVehicleCount(payload.totalElements));
        dispatch(showVehicleCount()); // show vehicle count on clp after the results arrived.
        return payload.searchFilter;
      })
      .then((searchFilter) => {
        const filtersFromUri = getFiltersFromUri({}, searchFilter, {
          make: getState().filter.possibleMakes,
          model: getState().filter.possibleModels,
          usedCarSeal: getState().filter.allUsedCarSeal,
        });
        return dispatch(overrideFilters(filtersFromUri));
      })
      .then(() => {
        return Promise.all([
          dispatch(getSEOData()),
          dispatch(getFavouriteVehicleIds()),
          dispatch(getPropositionBarEntries(WEB_APP_URL)),
        ]);
      })
      .catch((error) => {
        dispatch({ type: VehicleActionTypes.GET_VEHICLE_FAILURE, error });
        dispatch({ type: ActionTypes.SEARCH_REQUEST_ERROR, error });
        dispatch({ type: AppActionTypes.SET_SHORT_CIRCUIT, payload: true });
      });
  };
};

/* eslint-disable react/no-array-index-key */
import React, { memo, useEffect } from 'react';
import { Helmet } from 'react-helmet';

import { BASE_URL } from 'app/config';

import {
  defaultOrganisationSchema,
  defaultActionSchema,
} from './HtmlHeader.const';

const base = BASE_URL as string;

export type ISchema = {
  [key: string]: string | string[] | ISchema | ISchema[];
};

export interface IHtmlHeader {
  url?: string;
  currentUrl: string;
  index?: string;
  title: string;
  locale?: string;
  jsonLd?: ISchema[];
  showDefaultJsonLd?: boolean;
  imageUrl?: string;
  children?: JSX.Element | string;
  description?: string;
  hreflangDeDe?: string;
  hreflangEnGb?: string;
  hreflangEsEs?: string;
  canonicalPath?: string;
  hreflangDefault?: string;
}

// This is for temporarily logging SEO info (Ticket: MTEC-2241)
// This will be removed after monitoring
const logSeoInfo = async (data: {
  currentUrl: string;
  canonicalUrl: string;
  index: string;
}) => {
  await fetch('/logSeoInfo', {
    method: 'POST',
    headers: {
      Accept: 'application/json, text/plain, */*',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  });
};

export const HtmlHeaderCmp = ({
  url = base,
  currentUrl,
  index = 'noindex,nofollow',
  title,
  locale = 'de-DE',
  jsonLd = [],
  showDefaultJsonLd = true,
  imageUrl = '/assets/heycar_link.jpg',
  children = '',
  description = '',
  hreflangDeDe = '',
  hreflangEnGb = '',
  hreflangEsEs = '',
  canonicalPath = '',
  hreflangDefault = '',
}: IHtmlHeader): JSX.Element => {
  const imgUrl = imageUrl.startsWith('/') ? `${base}${imageUrl}` : imageUrl;
  const usedCanonicalPath = canonicalPath || url;

  useEffect(() => {
    logSeoInfo({
      currentUrl,
      canonicalUrl: usedCanonicalPath,
      index,
      // eslint-disable-next-line no-console
    }).catch(console.error);
  }, [currentUrl, usedCanonicalPath, index]);

  let jsonSchema = [...jsonLd];
  if (showDefaultJsonLd) {
    jsonSchema = [
      ...jsonSchema,
      defaultOrganisationSchema,
      defaultActionSchema,
    ];
  }

  const jsonSchemaTags = jsonSchema.map((schema, schemaIndex) => (
    <script key={schemaIndex} type="application/ld+json">
      {JSON.stringify(schema, null, 2)}
    </script>
  ));

  return (
    <Helmet>
      <title>{title}</title>
      <meta
        name="apple-itunes-app"
        content="app-id=1490142063, affiliate-data=pt=120374927&ct=smartbanner_tracking"
      />
      <meta name="description" content={description} />
      <meta property="og:url" content={url} />
      <meta property="og:site_name" content="heycar" />
      <meta property="og:type" content="website" />
      <meta property="og:locale" content={locale} />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:image:url" content={imgUrl} />
      <meta httpEquiv="Content-Type" content="text/html; charset=utf-8" />

      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:site" content="@heycar_official" />
      <meta name="twitter:creator" content="@heycar_official" />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:url" content={url} />
      <meta name="twitter:image" content={imgUrl} />
      <meta name="twitter:description" content={description} />

      <meta name="robots" content={index} />

      <link rel="canonical" href={usedCanonicalPath} />
      {hreflangDeDe && (
        <link rel="alternate" hrefLang="de-de" href={hreflangDeDe} />
      )}
      {hreflangEnGb && (
        <link rel="alternate" hrefLang="en-gb" href={hreflangEnGb} />
      )}
      {hreflangEsEs && (
        <link rel="alternate" hrefLang="es-es" href={hreflangEsEs} />
      )}
      {hreflangDefault && (
        <link rel="alternate" hrefLang="x-default" href={hreflangDefault} />
      )}

      {children}

      {jsonSchemaTags && jsonSchemaTags}
    </Helmet>
  );
};

export const HtmlHeader = memo(HtmlHeaderCmp);

HtmlHeader.displayName = 'HtmlHeader';

export const LOCATION_BASE_URL = '/auto/gebrauchtwagen/';

const campaignRoutesFns = {
  appendLeadingSlash: (leadingSlash = false) => (path) => {
    if (leadingSlash) {
      return `/${path}`;
    }
    return path;
  },
  removeExcludedPath: (exclude = []) => (path) => !exclude.includes(path),
  removeTrailingWildcards: (noTrailingWildcards = false) => (path) =>
    noTrailingWildcards ? path.replace(/\*$/, '') : path,
};
/**
 *
 * @param {object} param0
 * @param {boolean}  [param0.leadingSlash]        - true if we want to append a leading slash to the route
 * @param {string[]} [param0.exclude]             - list of paths to exclude from route list
 * @param {boolean}  [param0.noTrailingWildcards] - removes wildcards from a route
 * @returns
 */
export const getCampaignRoutes = ({
  leadingSlash,
  exclude,
  noTrailingWildcards,
} = {}) =>
  [
    'test-campaign/*',
    'jahreswagen',
    't-roc-cabriolet-aktion',
    'renault-zoe',
    'nissan-qashqai',
    'id3',
    'pologolf',
    'gespartwagen',
    'audiservicekomfort',
    'lieferung-audi',
    'neuwagenduft',
    'innerpeace',
    'gmd',
  ]
    .filter(campaignRoutesFns.removeExcludedPath(exclude))
    .map(campaignRoutesFns.removeTrailingWildcards(noTrailingWildcards))
    .map(campaignRoutesFns.appendLeadingSlash(leadingSlash));

/* eslint-disable react-perf/jsx-no-new-function-as-prop */
import React from 'react';
import PropTypes from 'prop-types';

import { ContainerSt, InnerContainerSt, LabelSt, ButtonSt } from './index.css';

export const ToggleSwitchExtended = (props) => {
  const { labels, activeOptionIndex, setActiveOption } = props;

  return (
    <ContainerSt>
      <InnerContainerSt>
        {labels.map((label, index) => (
          <LabelSt
            key={`${label}`}
            $isSelected={activeOptionIndex === index}
            onClick={() => setActiveOption(index)}
          >
            {label}
          </LabelSt>
        ))}
      </InnerContainerSt>

      <ButtonSt $position={activeOptionIndex} />
    </ContainerSt>
  );
};

ToggleSwitchExtended.propTypes = {
  labels: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  ).isRequired,
  activeOptionIndex: PropTypes.number.isRequired,
  setActiveOption: PropTypes.func.isRequired,
};

import { createSelector } from 'reselect';

/**
 * @param {number} flagID
 * @returns {(state: object) => { flagID: number, flagToken: string, attachment: object }}
 */
const findFeatureFlagById = (flagID) => (featureFlags) =>
  featureFlags.find(({ flagID: FID } = {}) => FID === flagID);

export function getFeatureFlags(state) {
  return state.app.featureFlags;
}
export const getFeatureFlagByFlagID = (state, flagID) => {
  const { featureFlags } = state?.app || {};
  if (
    featureFlags &&
    typeof featureFlags === 'object' &&
    Array.isArray(featureFlags)
  ) {
    return findFeatureFlagById(flagID)(featureFlags);
  }
  return null;
};

/**
 * @description Checks whether the "featureFlag" contains the given flagToken.
 * @export
 * @param {object} state
 * @param {number} flagID
 * @param {string} flagToken
 * @returns {boolean}
 */
export function checkFeatureFlagHasToken(state, flagID, flagToken) {
  const flag = getFeatureFlagByFlagID(state, flagID);
  if (flag && flag.flagToken === flagToken) {
    return true;
  }
  return false;
}

export const getPreviousLocation = (state) => {
  return state.app.previousLocation;
};

export const getRouterBeforeTransitions = (state) => {
  const { routing } = state;
  return routing && routing.locationBeforeTransitions;
};

export const getRouterQuery = (state) => {
  const router = getRouterBeforeTransitions(state);
  return router && router.query;
};

export const getRouterSearch = (state) => {
  const router = getRouterBeforeTransitions(state);
  return router && router.search;
};

export const getProfileInfoBoxSeen = (state) => {
  return state.user.hasSeenProfileInfo;
};

export const getError = (state) => {
  return state.app.errorMessage;
};

export const getRedirect = (state) => {
  return state.app.redirect;
};

export const deviceSelector = (state) => {
  return state.app.deviceType;
};

/**
 * @typedef {<S,R>(state: S) => R} Selector
 *
 * @typedef {object} FeatureFlag
 * @property {number} flagID
 * @property {string} flagToken
 * @property {object} attachment
 *
 * @description
 * - given a flag id
 * - returns a flag if a flag with the given flag id is found
 * - else returns undefined
 * @param   {number} flagID
 * @returns {Selector<object,FeatureFlag|undefined>}
 */
export const selectFeatureFlagByFlagID = (flagID) =>
  createSelector([getFeatureFlags], findFeatureFlagById(flagID));

/**
 * @description
 * - finds a feature flag by id
 * - returns true if the found flag has the given token
 * - returns false if the found flag does not have the given token
 * @param {number} flagID
 * @returns { (flagToken: string) => Selector<object, boolean> }
 */
export const selectFeatureFlagHasToken = (flagID) => (flagToken) =>
  createSelector(
    [selectFeatureFlagByFlagID(flagID)],
    ({ flagToken: FT } = {}) => FT === flagToken,
  );

import { createSelector } from 'reselect';

export const globalEventSelector = (state) => state.globalEvents;

export const breakpointSelector = createSelector(
  globalEventSelector,
  (globalEvent) => globalEvent.breakpoint,
);

export const topNavbarIsHiddenSelector = createSelector(
  globalEventSelector,
  (globalEvent) => globalEvent.isTopNavBarHidden,
);

export const scrollDirectionSelector = createSelector(
  globalEventSelector,
  (globalEvent) => globalEvent.isScrollDown,
);

export const topNavbarIsStuckSelector = createSelector(
  globalEventSelector,
  (globalEvent) => globalEvent.isTopNavbarStuck,
);

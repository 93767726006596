import { hasPrecalculatedFinance } from 'app/shared/utils/vehicle';

/**
 * check if vehicle attributes are suitable for displaying DCC
 * @param vehicle The vehicle received from the backend
 * @returns {boolean} Returns true if appropriate to display DCC
 */
export const showConsumerLoan = (vehicle) => {
  const { showDcc = false } = vehicle;
  const hasPreCalc = hasPrecalculatedFinance(vehicle);

  return showDcc && !hasPreCalc;
};

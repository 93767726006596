import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const BaseAvatar = styled.div`
  display: inline-block;
  border-radius: 50%;
  background-image: url(${props => props.src || props.placeholder});
  background-size: cover;
  background-position: center;
`;

const FluidAvatar = styled(BaseAvatar)`
  height: 0px;
  width: 100%;
  padding-top: 100%;
`;

const FixedAvatar = styled(BaseAvatar)`
  width: ${props => props.size}px;
  height: ${props => props.size}px;
`;

export const Avatar = props =>
  // eslint-disable-next-line react/destructuring-assignment
  props.size == null ? <FluidAvatar {...props} /> : <FixedAvatar {...props} />;

Avatar.propTypes = {
  /** The source attribute path */
  src: PropTypes.string,
  /** The exact number value in pixels the image should have; the same size is applied for both height and width */
  size: PropTypes.number,
  /** An optional placeholder image */
  placeholder: PropTypes.string,
};

Avatar.defaultProps = {
  src: null,
  size: null,
  placeholder: '/client/assets/home/icon-dealer.svg',
};

import styled from 'styled-components';

export const ToggleButtonContainerSt = styled.div`
  position: relative;
  width: 56px;
  height: 32px;
  background: ${({ theme: { colors }, isOn, colorOn, colorOff }) =>
    isOn ? colors[colorOn] : colors[colorOff]};
  border-radius: 24px;
  cursor: pointer;
`;

export const ToggleButtonIconContainerSt = styled.div`
  z-index: 1;
  position: absolute;
  top: 3px;
  left: ${({ isActive }) => (isActive ? 0 : '4px')};
  display: flex;
  justify-content: center;
  align-items: center;
  width: 26px;
  height: 26px;
  border-radius: 24px;
  background: ${({ theme: { colors } }) => colors.white};

  ${({ isActive }) =>
    isActive
      ? 'transition: all ease-in 140ms; transform: translateX(100%)'
      : 'transition: all ease-in 140ms; transform: translateX(0)'};
`;

export const ToggleButtonIconSt = styled.i`
  font-size: 14px;
  color: ${({ theme: { colors } }) => colors.micaBlue};
`;

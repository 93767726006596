import styled from 'styled-components';

export const SignupFormSt = styled.div`
  padding: 24px 0 5px;
`;

export const MessageSt = styled.span`
  position: absolute;
  height: 16px;
  padding-top: 7px;
  font-size: 12px;
  line-height: 1.33;
  color: ${({ status, theme: { colors } }) =>
    status === 'success' ? colors.brandPrimary : colors.brandDanger};
`;

export const InputSt = styled.input`
  display: block;
  width: 100%;
  max-width: 300px;
  padding: 8px 12px;
  border: '0';
  border-right: none;
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 4px 0 0 4px;
  color: ${({ theme }) => theme.colors.black};
  font-size: 16px;
  font-weight: 100;
  line-height: 1.5;

  &::placeholder {
    color: ${({ theme }) => theme.colors.grayDark};
    font-size: 16px;
    font-weight: 100;
    transition: opacity 0.2s ease-in-out, transform 0.2s ease-in-out;
  }

  &:focus::placeholder {
    opacity: 0;
  }
`;

/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react-perf/jsx-no-new-function-as-prop */
import PropTypes from 'prop-types';

import { Link } from 'app/shared/ui';
import { ConnectedGamBanner } from 'app/shared/modules/Campaign/GAM/GamBanner/ConnectedGamBanner';
import { useHasMounted } from 'app/shared/hooks/useHasMounted';
import { trackAction } from 'app/shared/utils/tracking';
import {
  trackingDataDefaultProps,
  trackingDataShape,
} from 'app/marketplace/contentful/propTypes';
import {
  CardSt,
  CardImageSt,
  CardLabelSt,
} from 'app/marketplace/specialOffers/SpecialOfferTeaserCard/SpecialOfferTeaserCard.css';

export const SpecialOfferTeaserCard = ({
  title,
  cardImage,
  landingUrl,
  target,
  linkLabel,
  hideLink,
  isCarTile,
  trackingData,
  className,
  gamInfo: { carTiles, order },
}) => {
  const hasMounted = useHasMounted();

  /* istanbul ignore next */
  const trackTileClick = () => {
    const { name, ...rest } = trackingData?.fields;
    trackAction(name, { ...rest });
  };

  const shouldUseGam = carTiles && Array.isArray(carTiles);

  let tile = {};
  if (shouldUseGam) {
    tile = carTiles[order];
  }

  const imageUrl = cardImage?.fields?.file?.url;
  if (!imageUrl) return null;

  const ContentfulBanner = (
    <CardImageSt
      src={imageUrl}
      alt={title}
      width={isCarTile ? '340px' : '272px'}
      height={isCarTile ? '350px' : '236px'}
      $hideLink={hideLink}
      $isCarTile={isCarTile}
    />
  );

  return (
    <Link
      to={landingUrl}
      title={title}
      onClick={trackTileClick}
      target={target}
    >
      <CardSt className={className} $showBoxShadow={hasMounted}>
        {shouldUseGam ? (
          <ConnectedGamBanner
            adUnitId={tile.adUnitId}
            adUnitPath={tile.adUnitPath}
            sizes={tile.sizes}
            bannerArea={tile.bannerArea}
            fallbackComponent={ContentfulBanner}
            trackingData={tile.trackingData}
          />
        ) : (
          ContentfulBanner
        )}
        {!hideLink && !isCarTile && (
          <object>
            <CardLabelSt target={target}>{linkLabel}</CardLabelSt>
          </object>
        )}
      </CardSt>
    </Link>
  );
};

SpecialOfferTeaserCard.propTypes = {
  title: PropTypes.string,
  cardImage: PropTypes.object,
  landingUrl: PropTypes.string,
  linkLabel: PropTypes.string,
  className: PropTypes.string,
  hideLink: PropTypes.bool,
  trackingData: trackingDataShape,
  target: PropTypes.string,
  gamInfo: PropTypes.object,
  isCarTile: PropTypes.bool,
};

SpecialOfferTeaserCard.defaultProps = {
  title: '',
  cardImage: {},
  landingUrl: '',
  linkLabel: '',
  className: '',
  hideLink: false,
  trackingData: trackingDataDefaultProps,
  target: null,
  gamInfo: {},
  isCarTile: false,
};

import styled, { css } from 'styled-components';

import { zIndex } from 'app/shared/styles_js/variables';

export const InputSelectSt = styled.div`
  position: relative;
  z-index: ${zIndex.navbar};
  display: block;
  width: 100%;

  ${({ theme: { colors }, useTransparentBg }) =>
    css`
      color: ${colors.tarmacGrey};

      label {
        color: ${colors.grayDark} !important;
      }

      ${useTransparentBg &&
      /* istanbul ignore next */
      css`
        button {
          background-color: ${colors.transparent} !important;
          color: inherit !important;

          &:hover {
            color: ${colors.brandSecondaryLighter} !important;
          }
        }
      `}
    `}
`;

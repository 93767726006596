import styled, { css } from 'styled-components';

export const WrapperSt = styled.div`
  overflow: hidden;
  margin: 16px auto 0;

  ${props => props.style};
`;

export const SliderSt = styled.div`
  display: flex;
  height: 24px;
  align-items: center;
  transition: transform 0.3s ease-in;

  ${props => props.style};
`;

export const CircleSt = styled.span`
  width: 6px;
  height: 6px;
  margin: 0 7px;
  background-color: ${({ theme }) => theme.colors.gray};
  border-radius: 50%;
  transform: scale(1);
  transition: transform 0.3s ease-in;

  ${props =>
    props.active &&
    css`
      background-color: ${({ theme }) => theme.colors.brandPrimary};
      transform: scale(2);
    `};

  ${props =>
    props.medium &&
    css`
      transform: scale((4/3));
    `};
`;

import {
  IAggregations,
  IDefaultAggregation,
} from 'app/types/apis/search.types';

// these commented out types will be gradually added
// as we implement the listings count to each filter
type TSearchFilterKey =
  | 'bodyColor'
  | 'seats'
  | 'interiorColor'
  | 'feature'
  | 'maxOwners'
  | 'emissionClass'
  | 'climatisation'
  | 'model'
  | 'usedCarSeal'
  | 'make'
  | 'specialOffers'
  | 'vehicleCondition'
  | 'doors'
  | 'fuelType'
  | 'gearBox'
  | 'category'
  | 'interiorType'
  | 'airbags';

export interface IFilterOption {
  optionName: IDefaultAggregation['displayName'];
  listingsCount: IDefaultAggregation['count'];
}

export interface ISearchFilterCategory {
  filterOptions: IFilterOption[];
}

export type TSearchFilters = Record<TSearchFilterKey, ISearchFilterCategory>;

export enum SearchFiltersActionTypes {
  /**
   * @description gets the listings count from search response's
   *  `aggregations` and updates the filters
   */
  UPDATE_LISTINGS_COUNT = 'SEARCH_FILTERS/UPDATE_LISTINGS_COUNT',
}

export type TSearchFiltersActions = {
  type: SearchFiltersActionTypes.UPDATE_LISTINGS_COUNT;
  payload: IAggregations;
};

/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* istanbul ignore file */
import styled from 'styled-components';

import { widthHeight } from './IndeterminateLoadingSpinner';

interface ISpinnerWrapperSt {
  size: 's' | 'm' | 'l';
  type: 'primary' | 'secondary';
}

export const SpinnerWrapperSt = styled.div`
  position: relative;
  display: inline-block;
  width: ${({ size }: ISpinnerWrapperSt) => `${widthHeight[size]}px`};
  height: ${({ size }: ISpinnerWrapperSt) => `${widthHeight[size]}px`};

  @keyframes rotateA {
    100% {
      transform: rotate(360deg);
    }
  }

  @keyframes dashA {
    0% {
      stroke-dasharray: 1, 150;
      stroke-dashoffset: 0;
    }
    50% {
      stroke-dasharray: 90, 150;
      stroke-dashoffset: -35;
    }
    100% {
      stroke-dasharray: 90, 150;
      stroke-dashoffset: -124;
    }
  }

  .spinner {
    animation: rotateA 2s linear infinite;

    & .path {
      stroke: ${({ theme: { rebranding, colors }, type }) => {
        if (rebranding) {
          return type === 'primary' ? colors.micaBlue : colors.white;
        }
        return type === 'primary' ? colors.brandSecondary : colors.white;
      }};
      stroke-linecap: round;
      animation: dashA 1.5s ease-in-out infinite;
    }
  }
`;

import styled from 'styled-components';

export const ErrorBoundarySt = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 15px;
`;

export const ErrorIconSt = styled.div`
  font-size: 32px;
`;

export const ErrorMessageSt = styled.div`
  margin-top: 15px;
  text-align: left;
`;

import styled, { css } from 'styled-components';

import { breakpoints, media } from 'app/shared/styles_js/variables';

import { stageBannerSizes } from './constants/shared';

export const StageBannerSt = styled.div`
  width: 100%;
  min-height: ${stageBannerSizes.sm.height}px;
  ${Object.keys(breakpoints).map((bk) =>
    media[bk](css`
      min-height: ${stageBannerSizes[bk].height}px;
    `),
  )};
`;

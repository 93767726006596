import React from 'react';

import { getIsClassifiedsCar } from 'app/marketplace/utils/getIsClassifiedsCar';
import { isDealershipOpen } from 'app/marketplace/contactDealer/Call/utils';

import { ConnectedCartileCallCta as CartileCallCta } from '../CartileUIComponents/CartileCallCta/ConnectedCartileCallCta';
import { ICartileCallCtaProps } from '../CartileUIComponents/CartileCallCta/CartileCallCta.types';

import {
  TGetDataForCartileCallCtaTypes,
  TCartileCTAComponentType,
} from './getDataForCartileCallCta.types';

export const getDataForCartileCallCta = ({
  vehicle,
  isLoading,
  isFlag279ShowCallCta,
  isMobile,
  target,
  breakpoint,
}: TGetDataForCartileCallCtaTypes): TCartileCTAComponentType => {
  if (
    getIsClassifiedsCar(vehicle?.dataSource) &&
    isMobile &&
    isFlag279ShowCallCta
  ) {
    const isOpen = isDealershipOpen(vehicle?.dealer?.openingTimes);

    return {
      CartileFooterCtaContainerChildren: CartileCallCta,
      CartileFooterCtaContainerChildrenProps: {
        isLoading,
        target,
        breakpoint,
        vehicle,
        hideCallCta: !isOpen,
      } as ICartileCallCtaProps,
    };
  }

  return {
    CartileFooterCtaContainerChildren: React.Fragment,
    CartileFooterCtaContainerChildrenProps: undefined,
  };
};

/* eslint-disable react/jsx-props-no-spreading */
/* istanbul ignore file */
import { useEffect, useState } from 'react';

import { promotionTiles } from 'app/marketplace/contentful/pages/SpecialOffersPage/SpecialOffersContainer/promotionTiles';
import ScrollSnapCarousel from 'app/marketplace/specialOffers/ScrollSnapCarousel/ScrollSnapCarousel';
import { PromotionTile } from 'app/marketplace/shared/components/PromotionTile/PromotionTile';
import { trackAction } from 'app/shared/utils/tracking';
import {
  ButtonSt,
  GridContainer,
  TitleSt,
} from 'app/marketplace/specialOffers/SpecialOffersHome/SpecialOffersHomeNew.css';
import { breakpointShape } from 'app/shared/utils/propTypes';

const itemsToShow = {
  sm: 6, // not in a slider but in a grid
  md: 3,
  lg: 4,
  xl: 5,
};

const itemsToScroll = {
  sm: 1,
  md: itemsToShow.md,
  lg: itemsToShow.lg,
  xl: itemsToShow.xl,
};

const gapPerBreakpoint = {
  sm: 17.75,
  md: 24,
  lg: 18,
  xl: 24,
};

export default function SpecialOffersHomeNew({ breakpoint }) {
  const [items, setItems] = useState(promotionTiles);
  const [showAllTiles, setShowAllTiles] = useState(false);
  const isMobile = breakpoint === 'sm';

  useEffect(() => {
    if (isMobile) {
      if (!showAllTiles && items.length > itemsToShow[breakpoint]) {
        setItems([...promotionTiles].slice(0, itemsToShow[breakpoint]));
      }
    } else {
      setItems(promotionTiles);
    }
  }, [isMobile]);

  if (isMobile) {
    const expandAllTiles = () => {
      trackAction('all_special_offers_clicked', {
        category: 'new_car_tile_design_test',
      });
      setItems(promotionTiles); // reset to show all tiles
      setShowAllTiles(true);
    };

    const showExpandTilesButton =
      !showAllTiles && promotionTiles.length > itemsToShow[breakpoint];

    return (
      <div>
        <TitleSt>Deals und Specials</TitleSt>
        <GridContainer>
          {items.map(({ id, trackingData, ...rest }) => (
            <PromotionTile
              key={id}
              {...rest}
              trackingData={{
                name: `home_page_deals_and_specials_car_tile${id}_click`,
                value: trackingData.value,
                label: 'Deals and specials on home page',
                additionalProperties: {
                  banner_source: 'new_car_tile_design_test',
                },
              }}
              // 16px is the gap between tiles, 24px is the total gap
              // between the main container and the device edges,
              // dividing by 2 is for placing 2 items per row
              tileDimension="calc((100vw - 16px - 24px) / 2)"
            />
          ))}
        </GridContainer>
        {showExpandTilesButton && (
          <ButtonSt onClick={expandAllTiles}>Alle Aktionsangebote</ButtonSt>
        )}
      </div>
    );
  }

  const showArrows =
    !isMobile && promotionTiles.length > itemsToShow[breakpoint];
  const tileDimension = 204;

  const handleNextArrowClick = () =>
    trackAction('hp_new_car_tile_right_arrow_click');

  const handlePreviousArrowClick = () =>
    trackAction('hp_new_car_tile_left_arrow_click');

  return (
    <div>
      <ScrollSnapCarousel
        itemsGap={gapPerBreakpoint[breakpoint]}
        itemsToScroll={itemsToScroll[breakpoint]}
        itemsWidth={tileDimension}
        showArrows={showArrows}
        style={{ marginTop: showArrows ? 48 : 32 }}
        title={<TitleSt>Deals und Specials</TitleSt>}
        nextArrowClickCallback={handleNextArrowClick}
        prevArrowClickCallback={handlePreviousArrowClick}
      >
        {items.map(({ id, trackingData, ...rest }) => (
          <PromotionTile
            key={id}
            {...rest}
            trackingData={{
              name: `deals_and_specials_car_tile${id}_click`,
              value: trackingData.value,
              label: 'Deals and specials on home page',
              additionalProperties: {
                banner_source: 'new_car_tile_design_test',
                page: 'home_page',
              },
            }}
            tileDimension={`${tileDimension}px`}
          />
        ))}
      </ScrollSnapCarousel>
    </div>
  );
}

SpecialOffersHomeNew.propTypes = {
  breakpoint: breakpointShape,
};

SpecialOffersHomeNew.defaultProps = {
  breakpoint: 'sm',
};

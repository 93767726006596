import styled from 'styled-components';
import { Flex } from '@rebass/grid';

import { Text } from 'app/shared/ui';

export const CartileCityLocationContainerSt = styled(Flex).attrs(() => ({
  alignItems: 'flex-end',
}))`
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 16px;
  margin-right: 10px;
`;

export const CartileCityLocationTextSt = styled(Text)`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

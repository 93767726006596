/* eslint-disable react/jsx-props-no-spreading */
import { Fragment, useState } from 'react';
import { Flex } from '@rebass/grid';

import { Text3 } from 'app/shared/ui';
import { isFavourite } from 'app/shared/utils/vehicle';
import { GetCartileData } from 'app/shared/modules/Cartile/CartileWebApp';
import { ConnectedSpecialOffersBanner } from 'app/marketplace/specialOffers/SpecialOffersBanner/ConnectedSpecialOffersBanner';
import { ConnectedGamBanner } from 'app/shared/modules/Campaign/GAM/GamBanner/ConnectedGamBanner';
import { useCustomTargetingByFilters } from 'app/shared/modules/Campaign/GAM/hooks/useCustomTargetingByFilters';
import { GAM_AD_UNITS } from 'app/shared/modules/Campaign/GAM/ad-units';

import { Carsale24Teaser } from '../Carsale24Teaser/Carsale24Teaser';

const {
  CLP_BETWEEN_CAR_TILE_BANNER_1,
  CLP_BETWEEN_CAR_TILE_BANNER_2,
} = GAM_AD_UNITS;

export const CarTileList = ({
  width,
  target,
  vehicles,
  utmParams,
  isLoading,
  breakpoint,
  favouriteIds,
  isHorizontal,
  hasRemoveIcon,
  toggleFavourite,
  currentLocation,
  performanceUnit,
  budgetRangeValue,
  paddingHorizontal,
  lazyImageIndex = 2,
  handleCarTileClick,
  additionalTrackingProperties,
  additionalTrackingOptionData,
  showCS24Teaser = false,
  CarTileContainer = Fragment,
  isPushVehicle = false,
  title = null,
  campaigns = [],
  showCLPBannerInDifferentPosition = false,
  selectedFilters = [],
  isDealerCashEligible = false,
  isFlag278ShowNewAdvertisements = false,
  isFlag279ShowCallCta = false,
  activeFilters,
}) => {
  const { customTargeting, markerIds } = useCustomTargetingByFilters(
    selectedFilters,
  );
  const [showFirstGamBanner, setShowFirstGamBanner] = useState(true);
  const [showSecondGamBanner, setShowSecondGamBanner] = useState(true);

  const carTiles = vehicles.map((vehicle, index) => {
    const { CartileContainer, props } = GetCartileData({
      width,
      index,
      target,
      utmParams,
      vehicle,
      isLoading,
      breakpoint,
      isHorizontal,
      hasRemoveIcon,
      performanceUnit,
      currentLocation,
      toggleFavourite,
      budgetRangeValue,
      paddingHorizontal,
      handleCarTileClick,
      additionalTrackingProperties,
      additionalTrackingOptionData,
      isLazy: index > lazyImageIndex,
      isFavourite: isFavourite(favouriteIds, vehicle.id),
      isPushVehicle,
      campaigns,
      isDealerCashEligible,
      isFlag279ShowCallCta,
      activeFilters,
    });

    const vehicleCountBeforeTeaser = breakpoint === 'md' ? 4 : 6;

    /* istanbul ignore next */
    const showCarsale24Teaser =
      showCS24Teaser && target === 'CLP' && index === vehicleCountBeforeTeaser;

    /* istanbul ignore next */
    const isCLPBannerInDifferentPosition =
      showCLPBannerInDifferentPosition &&
      index === vehicleCountBeforeTeaser &&
      target === 'CLP' &&
      selectedFilters?.length === 0;

    /* istanbul ignore next */
    if (isCLPBannerInDifferentPosition) {
      return (
        <CarTileContainer key={vehicle.id}>
          <ConnectedSpecialOffersBanner
            selectedFilters={selectedFilters}
            renderWhenViewable
          />
          <CartileContainer {...props} />
        </CarTileContainer>
      );
    }

    /* istanbul ignore next */
    if (
      showCarsale24Teaser &&
      !isCLPBannerInDifferentPosition &&
      !isFlag278ShowNewAdvertisements
    ) {
      return (
        <CarTileContainer key={vehicle.id}>
          <Carsale24Teaser breakpoint={breakpoint} />
          <CartileContainer {...props} />
        </CarTileContainer>
      );
    }

    const firstGamBannerPositionIndex = {
      sm: 4,
      md: 3,
      lg: 5,
      xl: 5,
    };

    const secondGamBannerPositionIndex = {
      sm: 9,
      md: 7,
      lg: 11,
      xl: 11,
    };

    /* istanbul ignore next */
    const firstGamBannerCallback = (data) => {
      if (!data) setShowFirstGamBanner(false);
    };

    /* istanbul ignore next */
    const secondGamBannerCallback = (data) => {
      if (!data) setShowSecondGamBanner(false);
    };

    return (
      <>
        <CarTileContainer key={vehicle.id}>
          <CartileContainer {...props} />
        </CarTileContainer>
        {
          /* istanbul ignore next */ isFlag278ShowNewAdvertisements &&
            showFirstGamBanner &&
            firstGamBannerPositionIndex[breakpoint] === index && (
              <Flex width={1} p="0 12px" m={['0 0 16px', '-24px 0 16px']}>
                <ConnectedGamBanner
                  adUnitId={CLP_BETWEEN_CAR_TILE_BANNER_1.adUnitId}
                  adUnitPath={CLP_BETWEEN_CAR_TILE_BANNER_1.adUnitPath}
                  sizes={CLP_BETWEEN_CAR_TILE_BANNER_1.sizes}
                  bannerArea={CLP_BETWEEN_CAR_TILE_BANNER_1.bannerArea}
                  fallbackComponent={null}
                  {...(customTargeting && { targeting: customTargeting })}
                  trackingData={CLP_BETWEEN_CAR_TILE_BANNER_1.trackingData}
                  callback={firstGamBannerCallback}
                  markerIds={markerIds}
                />
              </Flex>
            )
        }
        {
          /* istanbul ignore next */ isFlag278ShowNewAdvertisements &&
            showSecondGamBanner &&
            secondGamBannerPositionIndex[breakpoint] === index && (
              <Flex width={1} p="0 12px" m={['0 0 16px', '-24px 0 16px']}>
                <ConnectedGamBanner
                  adUnitId={CLP_BETWEEN_CAR_TILE_BANNER_2.adUnitId}
                  adUnitPath={CLP_BETWEEN_CAR_TILE_BANNER_2.adUnitPath}
                  sizes={CLP_BETWEEN_CAR_TILE_BANNER_2.sizes}
                  bannerArea={CLP_BETWEEN_CAR_TILE_BANNER_2.bannerArea}
                  fallbackComponent={null}
                  {...(customTargeting && { targeting: customTargeting })}
                  trackingData={CLP_BETWEEN_CAR_TILE_BANNER_2.trackingData}
                  callback={secondGamBannerCallback}
                  markerIds={markerIds}
                />
              </Flex>
            )
        }
      </>
    );
  });

  const titleComp = title ? (
    <Text3
      as="div"
      width={1}
      textAlign={['center', 'left']}
      fontSize={[16, null, 20, 16]}
      fontWeight="bold"
      lineHeight={2}
      mt={[16]}
      mb={[16]}
      mx={[12]}
    >
      {title}
    </Text3>
  ) : null;

  return (
    <>
      {titleComp}
      {carTiles}
    </>
  );
};

/* istanbul ignore file */
import styled, { css } from 'styled-components';

import { Link } from 'app/shared/ui';
import {
  CARTILE_HEIGHT,
  CARTILE_IMAGE_HEIGHT,
  CONTENT_HEADER_HEIGHT,
  CONTENT_INFO_MIN_HEIGHT,
  CONTENT_FOOTER_HEIGHT_LG,
  CONTENT_INFO_MARGIN_VERTICAL,
  CONTENT_CONTAINER_PADDING_VERTICAL,
} from 'app/shared/modules/Cartile/CartileTemplates/CartileTemplates.constant';
import { media } from 'app/shared/styles_js/variables';

export const CartileContentInfosWrapperSt = styled.div`
  z-index: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: auto;
  min-height: ${CONTENT_INFO_MIN_HEIGHT}px;
  margin: ${CONTENT_INFO_MARGIN_VERTICAL}px 0;

  & > ul {
    ${media.md(css`
      height: ${({ isHorizontal }) => (isHorizontal ? 'auto' : '100%')};
      margin-top: 0;
      margin-bottom: ${({ isHorizontal }) => (isHorizontal ? '0' : '10px')};
      min-height: unset;
    `)}
  }

  &:hover ul > div > span {
    color: ${({ theme }) => theme.colors.tarmacGrey};
  }
`;

import { Reducer } from 'redux';

import { TSearchFiltersActions, SearchFiltersActionTypes } from './types';
import { defaultState } from './defaultState';
import { updateListingsCountOnFilters } from './utils/updateListingsCountOnFilters';

export function searchFilters(
  state = defaultState,
  action: TSearchFiltersActions,
): ReturnType<Reducer> {
  const { type, payload } = action;

  if (type === SearchFiltersActionTypes.UPDATE_LISTINGS_COUNT) {
    return updateListingsCountOnFilters({ state, payload });
  }

  return state;
}

import styled from 'styled-components';

import { Text } from 'app/shared/ui';
import { color } from 'app/shared/styles_js/variables';

export const CartileCrossedOutPriceTextSt = styled(Text).attrs(() => ({
  fontWeight: 'bold',
  as: 'div',
  textAlign: 'right',
  lineHeight: 'normal',
  fontSize: [10, 10],
}))`
  display: inline-block;
  color: ${color.graySubtle};
  text-decoration: line-through;
`;

import { PureComponent } from 'react';
import PropTypes from 'prop-types';
import throttle from 'lodash.throttle';

import { checkGamScriptsAvailability } from 'server/third-party-scripts/scripts.ts';
import { breakpoints } from 'app/shared/styles_js/variables';
import {
  BOTTOM,
  LEFT,
  RIGHT,
  TOP,
} from 'app/shared/modules/globalEvents/constants';
import { trackAction } from 'app/shared/utils/tracking';

const TOLERANCE = 120;

export const STICKY_LIMIT_MAP = {
  sm: 298,
  md: 340,
  lg: 344,
  xl: 344,
};

export class GlobalEventHandlers extends PureComponent {
  constructor(props) {
    super(props);

    this.isStuck = false;
    this.prevScrollPos = 0;
    this.isScrollDown = null;
    this.scrollPosWhenDirectionChange = 0;
    this.throttledHandleScroll = throttle(this.handleScroll, 500);
  }

  static getCurrentBreakpoint() {
    const width = window.innerWidth;
    const { md, lg, xl } = breakpoints;

    if (width >= md && width < lg) {
      return 'md';
    }
    if (width >= lg && width < xl) {
      return 'lg';
    }
    if (width >= xl) {
      return 'xl';
    }

    return 'sm';
  }

  static getCurrentAdBlockerDetection(pathname) {
    /**
     * We are lazy loading the GAM scripts on not needed pages. So, to avoid failing on ad blocker detection bc
     * of that, we can reckon it as `false` since the majority of our users (98%) do not use ad blockers. It'll
     * eventually be checked on every client-side route change.
     *
     * See ad blocker usage metric: https://app.datadoghq.com/dashboard/35s-pi7-q4d/promotions-metrics-fe
     */
    const isGamBannerNeeded = checkGamScriptsAvailability(pathname);
    if (!isGamBannerNeeded) return false;

    // Custom logic to detect adblocker
    const banner = document.querySelector('#div-gpt-');
    if (banner) {
      const bannerHeight = window
        .getComputedStyle(banner)
        .getPropertyValue('height');
      const bannerVisibility = window
        .getComputedStyle(banner)
        .getPropertyValue('display');
      if (bannerHeight === '2px' && bannerVisibility === 'block') {
        return false;
      }
    }

    return true;
  }

  componentDidMount() {
    const { setBreakpoint, setIsAdBlockerDetected, location } = this.props;
    const breakpoint = GlobalEventHandlers.getCurrentBreakpoint();
    setBreakpoint(breakpoint);
    const isAdBlockerDetected = GlobalEventHandlers.getCurrentAdBlockerDetection(
      location?.pathname,
    );
    setIsAdBlockerDetected(isAdBlockerDetected);
    trackAction(
      'promotions_ad_blocker_detection',
      { category: 'ad_blocker' },
      {
        breakpoint,
        hasAdblocker: isAdBlockerDetected,
        nonInteraction: 1, // Non-interactive event. So that it does not affect Google Analytics bounce rate, etc.
      },
    );

    this.handleScroll();
    window.addEventListener('resize', this.handleResize);
    window.addEventListener('scroll', this.throttledHandleScroll);
    document.addEventListener('mousewheel', this.throttledHandleScroll);
    document.addEventListener('mouseleave', this.handleDocumentMouseLeave);
    window.addEventListener('beforeprint', this.handlePrint);
    window.addEventListener('beforeinstallprompt', this.handleInstallprompt);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
    window.removeEventListener('scroll', this.throttledHandleScroll);
    document.removeEventListener('mousewheel', this.throttledHandleScroll);
    document.removeEventListener('mouseleave', this.handleDocumentMouseLeave);
    window.removeEventListener('beforeprint', this.handlePrint);
    window.removeEventListener('beforeinstallprompt', this.handleInstallprompt);
  }

  handlePrint = () => {
    trackAction('user_prints_page', { category: 'printing' });
  };

  handleResize = () => {
    const breakpoint = GlobalEventHandlers.getCurrentBreakpoint();
    const { breakpoint: currentBreakpoint, setBreakpoint } = this.props;

    if (breakpoint !== currentBreakpoint) {
      setBreakpoint(breakpoint);
    }
  };

  handleDocumentMouseLeave = (e) => {
    const { mouseExitScreenHandler } = this.props;

    let direction;

    if (e.clientY <= 0) {
      direction = TOP;
    } else if (e.clientX >= window.innerWidth) {
      direction = RIGHT;
    } else if (e.clientY >= window.innerHeight) {
      direction = BOTTOM;
    } else if (e.clientX <= 0) {
      direction = LEFT;
    }

    mouseExitScreenHandler(direction);
  };

  handleScroll = () => {
    const {
      breakpoint,
      isTopNavBarHidden,
      setScrollDirection,
      setIsTopNavbarStuck,
      setIsHiddenTopNavBar,
    } = this.props;

    const scrolled =
      window.pageYOffset ||
      document.documentElement?.scrollTop ||
      document.body?.scrollTop ||
      0;

    if (scrolled > STICKY_LIMIT_MAP[breakpoint] && !this.isStuck) {
      setIsTopNavbarStuck({ isTopNavbarStuck: true });
      this.isStuck = true;
    }

    if (scrolled < STICKY_LIMIT_MAP[breakpoint] && this.isStuck) {
      setIsTopNavbarStuck({ isTopNavbarStuck: false });
      this.isStuck = false;
    }

    if (this.prevScrollPos > scrolled) {
      if (this.isScrollDown) {
        setScrollDirection({ isScrollDown: false });
        this.isScrollDown = false;
        if (breakpoint === 'sm') {
          setIsHiddenTopNavBar({ isTopNavBarHidden: false });
        }
      }
    }

    if (this.prevScrollPos < scrolled) {
      if (!this.isScrollDown) {
        setScrollDirection({ isScrollDown: true });
        this.isScrollDown = true;
        this.scrollPosWhenDirectionChange = scrolled;
      }
      if (
        scrolled > this.scrollPosWhenDirectionChange + TOLERANCE &&
        breakpoint === 'sm' &&
        !isTopNavBarHidden
      ) {
        setIsHiddenTopNavBar({ isTopNavBarHidden: true });
      }
    }
    this.prevScrollPos = scrolled;
  };

  handleInstallprompt = (event) => {
    event.preventDefault();
  };

  render() {
    return false;
  }
}

GlobalEventHandlers.propTypes = {
  breakpoint: PropTypes.string,
  setBreakpoint: PropTypes.func.isRequired,
  setIsAdBlockerDetected: PropTypes.func.isRequired,
  isTopNavBarHidden: PropTypes.bool.isRequired,
  setScrollDirection: PropTypes.func.isRequired,
  setIsTopNavbarStuck: PropTypes.func.isRequired,
  setIsHiddenTopNavBar: PropTypes.func.isRequired,
  mouseExitScreenHandler: PropTypes.func.isRequired,
  location: PropTypes.object,
};

GlobalEventHandlers.defaultProps = {
  breakpoint: 'sm',
  location: {},
};

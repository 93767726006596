import { memo } from 'react';
import PropTypes from 'prop-types';

import {
  CartileImageContainerSt,
  CartileVerticalImageContainerSt,
  CartileHorizontalImageContainerSt,
} from 'app/shared/modules/Cartile/CartileTemplates/CartileImageContainer/CartileImageContainer.css';

const CartileImageContainerCmp = ({
  children,
  isHorizontal,
  isUnavailable,
}) => {
  return isHorizontal ? (
    <CartileHorizontalImageContainerSt isempty={!children ? 1 : 0}>
      {children}
    </CartileHorizontalImageContainerSt>
  ) : (
    <CartileVerticalImageContainerSt isunavailable={isUnavailable ? 1 : 0}>
      <CartileImageContainerSt>{children}</CartileImageContainerSt>
    </CartileVerticalImageContainerSt>
  );
};

CartileImageContainerCmp.propTypes = {
  children: PropTypes.node,

  isHorizontal: PropTypes.bool.isRequired,
  isUnavailable: PropTypes.bool.isRequired,
};

CartileImageContainerCmp.defaultProps = {
  children: null,
};

export const CartileImageContainer = memo(CartileImageContainerCmp);

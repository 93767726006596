import { apiCall } from 'app/actions';
import { setSignedUpForNewsletter } from 'app/shared/modules/user/actions';
import { trackIdentify } from 'app/shared/utils/tracking';

import {
  ActionTypes,
  CRM_NEWSLETTER_SUBSCRIBE_URL,
  NEWSLETTER_SOURCE,
  SWEEPSTAKES_SUBSCRIBE_URL,
  USER_SURVEY_SUBSCRIBE_URL,
} from './constants';

/**
 * CRM's Newsletter entrypoint submission function
 * @param {Object} formData
 * @param {string} formData.email
 * @param {string} formData.source
 * @returns {function(*): *}
 */
export const sendForm = (formData) => {
  return async (dispatch, getState) => {
    dispatch({
      type: ActionTypes.NEWSLETTER_SUBMISSION_STARTED,
    });

    const { email, source } = formData;

    const options = {
      method: 'POST',
      body: JSON.stringify({
        email,
        source,
      }),
    };

    // TODO: Add a catch step and refactor the existing entrypoints using this action
    return dispatch(
      apiCall(CRM_NEWSLETTER_SUBSCRIBE_URL, options, true, true, true),
    ).then((response) => {
      const userId = response?.userId;
      trackIdentify(userId, email);

      dispatch({
        type: ActionTypes.NEWSLETTER_SUBMISSION_COMPLETED,
      });

      // Save that the the current section's user have signed up to the newsletter
      dispatch(setSignedUpForNewsletter(true));

      response.ok = true;
      return response;
    });
  };
};

/**
 * User Survey entrypoint submission function
 * @param {Object} formData
 * @param {string} formData.email
 * @param {string} formData.source
 * @returns {function(*): *}
 */
export const sendUserSurveyForm = (formData) => {
  return (dispatch) => {
    dispatch({
      type: ActionTypes.USER_SURVEY_SUBMISSION_STARTED,
    });

    const { email, source } = formData;
    const options = {
      method: 'POST',
      body: JSON.stringify({
        email,
        source,
      }),
    };

    // TODO: Add a catch step and refactor the existing entrypoints using this action
    return dispatch(
      apiCall(USER_SURVEY_SUBSCRIBE_URL, options, true, true),
    ).then((response) => {
      dispatch({
        type: ActionTypes.USER_SURVEY_SUBMISSION_COMPLETED,
      });

      // Save that the the current section's user have signed up to the newsletter
      dispatch(setSignedUpForNewsletter(true));

      response.ok = true;
      return response;
    });
  };
};

/**
 * CRM's Sweepstakes entrypoint submission function
 * @param {Object} formData
 * @param {string} formData.firstName
 * @param {string} formData.lastName
 * @param {string} formData.email
 * @returns {function(*): *}
 */
export const submitSweepStakesForm = (formData) => {
  return (dispatch) => {
    dispatch({
      type: ActionTypes.SWEEPSTAKES_SUBMISSION_STARTED,
    });

    const {
      firstName,
      lastName,
      email,
      lotteryCheckbox: lotteryConsent,
      marketingCheckbox: marketingConsent,
    } = formData;
    const options = {
      method: 'POST',
      body: JSON.stringify({
        firstName,
        lastName,
        email,
        lotteryConsent,
        marketingConsent,
        source: NEWSLETTER_SOURCE.sweepstakesForm,
      }),
    };

    return dispatch(apiCall(SWEEPSTAKES_SUBSCRIBE_URL, options, true, true))
      .then((response) => {
        dispatch({
          type: ActionTypes.SWEEPSTAKES_SUBMISSION_COMPLETED,
        });

        // Save that the the current section's user have signed up to the newsletter
        dispatch(setSignedUpForNewsletter(true));

        response.ok = true;
        return response;
      })
      .catch((error) =>
        dispatch({
          type: ActionTypes.SWEEPSTAKES_SUBMISSION_FAILED,
          payload: { error: { status: error.status } },
        }),
      );
  };
};

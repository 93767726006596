import { ISearchResponse } from 'app/types/apis/search.types';

import { TSearchActions, SearchActionTypes } from './types';

export const getTotalListingsCount = (
  payload: ISearchResponse,
): TSearchActions => ({
  type: SearchActionTypes.GET_TOTAL_LISTINGS_COUNT,
  payload,
});

/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable react/prop-types */
import React from 'react';

import {
  TextSt,
  IconSt,
  LinkSt,
  UserIconSt,
  LabelSt,
  BoxSt,
} from './NavItemLabel.css';
import { INavItemLabelProps } from './NavItemLabel.types';

export const NavItemLabel = ({
  to,
  renderType,
  label,
  onClick,
  setSelectedItem,
  isOpen,
  children,
  useRouter,
  hasItemCategories,
  setTracking,
  id,
  useCustomChildren,
  menuHoverCallback,
  ...rest
}: INavItemLabelProps): React.ReactElement => {
  if (to && !hasItemCategories && !useCustomChildren) {
    return (
      <LinkSt
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...rest}
        onClick={e => {
          e.persist();
          setTracking('main', id);
          setSelectedItem('');
        }}
        to={to}
        useRouter={useRouter}
        textDecoration="none"
        fontWeight="normal"
        className="list-menu"
      >
        {label}
        {children}
      </LinkSt>
    );
  }

  return (
    <TextSt
      width={['100%', '100%', 'auto']}
      justifyContent={['space-between', 'space-between', 'center']}
      alignItems="center"
      pr={['24px', '24px', 0]}
      onClick={onClick}
      isOpen={isOpen}
      className="list-menu"
      hasSubMenu={hasItemCategories || useCustomChildren}
      onMouseEnter={event =>
        menuHoverCallback && menuHoverCallback({ event, tabID: id })
      }
    >
      {renderType === 'icon' ? (
        <BoxSt
          isOpen={hasItemCategories || useCustomChildren}
          className="user-menu-icon"
          data-test-id="nav-user-icon"
          role="button"
          onClick={onClick}
          alignItems="center"
          aria-label="login-button"
        >
          <LabelSt>
            <UserIconSt iconSize="medium" icon="avatar" />
          </LabelSt>
        </BoxSt>
      ) : (
        <LabelSt>{label}</LabelSt>
      )}

      {children}
      {(hasItemCategories || useCustomChildren) && (
        <IconSt icon="arrow-right-short" />
      )}
    </TextSt>
  );
};

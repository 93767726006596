import styled, { css } from 'styled-components';
import { Box } from '@rebass/grid';

import { variables } from '../variables';
const { breakpoints, grid, media } = variables;

const { margin: gridMargin } = grid;
const columns = (cols) => `grid-template-columns: repeat(${cols}, 1fr);`;

export const xlContainerStyles = css`
  ${media.xl(css`
    width: ${breakpoints.xl - gridMargin.xl * 2}px;

    ${({ xl }) => xl > 1 && columns(xl)};
    ${(props) => props.grid && `grid-column-gap: ${grid.gutter.xl}px;`};
  `)};
`;

export const lgContainerStyles = css`
  ${media.lg(css`
    width: ${breakpoints.lg - gridMargin.lg * 2}px;

    ${({ lg }) => lg > 1 && columns(lg)};
    ${(props) => props.grid && `grid-column-gap: ${grid.gutter.lg}px;`};
  `)};
`;

export const mdContainerStyles = css`
  ${media.md(css`
    width: ${breakpoints.md - gridMargin.md * 2}px;

    ${({ md }) => md > 1 && columns(md)};

    ${(props) =>
      props.grid &&
      css`
        display: grid;
        grid-column-gap: ${grid.gutter.md}px;
      `};
  `)};
`;

export const ContainerSt = styled(Box)`
  margin-left: auto;
  margin-right: auto;
  width: calc(100% - ${gridMargin.sm * 2}px);

  ${mdContainerStyles}
  ${lgContainerStyles}
  ${xlContainerStyles}
`;

/* istanbul ignore file */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { connect } from 'react-redux';

import {
  breakpointSelector,
  globalEventSelector,
} from 'app/shared/modules/globalEvents/selectors';
import GamBannerWrapper from 'app/shared/modules/Campaign/GAM/GamBanner/GamBannerWrapper';
import { TBreakpoint } from 'app/types/style.types';
import { IMapStateToProps } from 'app/shared/modules/Campaign/GAM/GamBanner/GamBanner.types';

const mapStateToProps = (state: TRootState): IMapStateToProps => {
  const { preferences } = state.cookieDashboard || {};
  const { isAdBlockerDetected } = globalEventSelector(state) || {};

  return {
    breakpoint: breakpointSelector(state) as TBreakpoint,
    isBlocked: (!preferences?.marketing || isAdBlockerDetected) as boolean,
  };
};

export const ConnectedGamBanner = connect(mapStateToProps)(GamBannerWrapper);

export const checkForError = response => {
  return new Promise((resolve, reject) => {
    if (response.ok) {
      resolve(response);
    } else {
      const { status, statusText } = response;
      const payload = {
        status,
        message: statusText === '' ? 'generic error' : statusText,
      };

      response.text().then(body => {
        let bodyContent = null;

        try {
          bodyContent = JSON.parse(body);
        } catch (error) {
          // json parse failed, just pass through the body
        }
        if (bodyContent && bodyContent.message) {
          payload.message = bodyContent.message;
        }
        payload.body = body;
        reject(payload);
      });
    }
  });
};

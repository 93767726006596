/* eslint-disable react-perf/jsx-no-new-function-as-prop */
import PropTypes from 'prop-types';

import { Button } from 'app/shared/ui';
import { MoreFilterContainerSt } from 'app/marketplace/home/Stage/FilterPanel/FilterPanel.css';
import { trackAction } from 'app/shared/utils/tracking';

const trackDetailSearchClick = (origin) => {
  trackAction(`detailsearch_${origin}_click`, {
    category: origin,
    label: `clicked on the more filters button on ${origin}`,
    value: 'filter_string',
  });
};

export const FilterPanelFooter = ({
  changeUrlfromLandingPage,
  getLocationFromCoords,
  openSidebarFilters,
  deviceType,
  locationCoords,
  origin,
}) => {
  const isTablet = deviceType === 'tablet';
  const isMobileOrTablet = deviceType === 'mobile' || deviceType === 'tablet';

  const handleMoreFilter = () => {
    trackDetailSearchClick(origin);
    if (isMobileOrTablet) {
      if (locationCoords.lat && locationCoords.lon) {
        getLocationFromCoords(locationCoords.lat, locationCoords.lon);
      }
      if (isTablet && openSidebarFilters) {
        openSidebarFilters();
        changeUrlfromLandingPage('/gebrauchtwagen');
      } else {
        changeUrlfromLandingPage('/mobileFilter');
      }
    } else {
      changeUrlfromLandingPage('/detailsuche');
    }
  };
  return (
    <MoreFilterContainerSt>
      Mehr Filter?
      <Button variant="tertiary" onClick={handleMoreFilter}>
        Zur Detailsuche
      </Button>
    </MoreFilterContainerSt>
  );
};

FilterPanelFooter.propTypes = {
  getLocationFromCoords: PropTypes.func.isRequired,
  changeUrlfromLandingPage: PropTypes.func.isRequired,
  openSidebarFilters: PropTypes.func.isRequired,
  deviceType: PropTypes.string.isRequired,
  locationCoords: PropTypes.object.isRequired,
  origin: PropTypes.string.isRequired,
};

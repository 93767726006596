import styled, { css } from 'styled-components';

import { Text2, Button, GridContainer } from 'app/shared/ui';
import { FlexRow } from 'app/marketplace/shared/components/Layouts/FlexRow';
import { FlexCol } from 'app/marketplace/shared/components/Layouts/FlexCol';
import { media, fontSizes } from 'app/shared/styles_js/variables';

export const ButtonArea = styled(FlexRow)`
  ${media.md(css`
    flex-direction: column;
  `)};

  ${media.lg(css`
    flex-direction: row;
  `)};
`;

export const ButtonAreaCol = styled(FlexCol)`
  margin-top: 16px;

  &:first-child {
    margin-top: 0;
  }

  ${media.lg(css`
    width: 50%;
    margin-top: 0;
    &:first-child {
      width: 100%;
      padding-right: 0;
    }
    &:last-child {
      width: 100%;
      padding-left: 16px;
      padding-right: 0;
    }
  `)};

  ${media.xl(css`
    &:first-child {
      padding-right: 16px;
    }
    &:last-child {
      padding-left: 16px;
      padding-right: 16px;
    }
  `)};
`;

export const CustomButton = styled(Button)`
  width: 100%;
  font-size: ${fontSizes.p2};
  height: 44px;
  padding: 0px;
  border-radius: 4px;

  ${media.md(css`
    width: 282px;
  `)};

  ${media.lg(css`
    width: 296px;
  `)};

  ${media.xl(css`
    width: 368px;
  `)};
`;

export const Title = styled.div`
  position: relative;

  ${media.md(css`
    grid-column: 1 / span 4;
  `)};
`;

export const Info = styled(Text2).attrs((props) => ({
  as: 'div',
  m: 0,
  ...props,
}))`
  color: ${({ theme: { colors } }) => colors.tarmacGrey};

  & > p {
    margin: 0;
    margin-bottom: 40px;

    &:last-child {
      margin-bottom: 0;
    }

    ${media.md(css`
      margin-bottom: 48px;
    `)};
  }

  ${media.md(css`
    grid-column: 5 / span 8;
  `)};
`;

export const InnerContainer = styled(GridContainer).attrs(() => ({
  hasDefaultWidth: true,
}))``;

export const Container = styled.div`
  padding: 56px 0;
  margin-top: 96px;
  background-color: ${({ theme: { colors } }) => colors?.sunbeanLighter};
  ${media.md(css`
    padding: 56px 0;
  `)};

  ${media.lg(css`
    margin-top: 128px;
  `)};
`;

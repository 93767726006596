// erstzulassung filter
import { getFilterObject } from 'app/marketplace/filter/utils';

export const MAX_YEAR = new Date().getFullYear();
export const MIN_YEAR = MAX_YEAR - 8;
// location filter
export const DEFAULT_RADIUS = 85;
export const MIN_RADIUS = 10;
export const MAX_RADIUS = 800;
// budget filter
export const MIN_TOTAL_RATE = 1000;
export const MID_TOTAL_RATE = 30000;
export const MAX_TOTAL_RATE = 120000;
// budget filter (mtl. rate)
export const MIN_MONTH_TOTAL_RATE = 0;
export const MID_MONTH_TOTAL_RATE = 500;
export const MAX_MONTH_TOTAL_RATE = 2000;
// kilometerstand filter
export const MIN_TOTAL_MILEAGE = 0;
export const MAX_TOTAL_MILEAGE = 150000;
// leistung filter (kW)
export const MIN_PERFORMANCE = 40;
export const MAX_PERFORMANCE = 368;
// vorbrauch filter (l/km)
export const MIN_CONSUMPTION = 0;
export const MAX_CONSUMPTION = 20;
// category
export const CATEGORY = {
  price: 'priceRange',
  priceSwitch: 'full',
  mtlPrice: 'monthlyRateRange',
  mtlSwitch: 'month',
  category: 'category',
  consumption: 'consumptionRange',
  mileage: 'mileageRange',
  performance: 'performanceRange',
  year: 'yearRange',
  radius: 'radius',
  location: 'location',
  lat: 'lat',
  lon: 'lon',
  gearBox: 'gearBox',
  fuelType: 'fuelType',
  usedCarSeal: 'usedCarSeal',
  doors: 'doors',
  seats: 'seats',
  emissionClass: 'emissionClass',
  interiorType: 'interiorType',
  maxOwners: 'maxOwners',
  feature: 'feature',
  featureComfort: 'featureComfort',
  featureSafety: 'featureSafety',
  featureMultimedia: 'featureMultimedia',
  featureExtras: 'featureExtras',
  vehicleCondition: 'vehicleCondition',
  singleDayRegistration: 'singleDayRegistration',
  bodyColor: 'bodyColor',
  interiorColor: 'interiorColor',
  specials: 'specials',
  multiMakeModelTrim: 'multiMakeModelTrim',
  airbags: 'airbags',
  climatisation: 'climatisation',
};

export const createDefaultRadiusFilter = (value) => {
  const val = parseInt(value, 10);

  return getFilterObject({
    category: CATEGORY.radius,
    value: `${val || DEFAULT_RADIUS}km`,
    label: `${val || DEFAULT_RADIUS}KM`,
  });
};

export const possiblePerformanceUnits = {
  A: 'ps',
  B: 'kW',
};

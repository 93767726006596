import { ISearchFilterCategory, TSearchFilters } from './types';

const filter: ISearchFilterCategory = {
  filterOptions: [],
};

// these commented out filters will be gradually added
// as we implement the listings count to each filter
export const defaultState: TSearchFilters = {
  bodyColor: filter,
  seats: filter,
  interiorColor: filter,
  feature: filter,
  maxOwners: filter,
  emissionClass: filter,
  climatisation: filter,
  model: filter,
  usedCarSeal: filter,
  make: filter,
  specialOffers: filter,
  vehicleCondition: filter,
  doors: filter,
  fuelType: filter,
  gearBox: filter,
  category: filter,
  interiorType: filter,
  airbags: filter,
};

import { PureComponent } from 'react';
import PropTypes from 'prop-types';

import { Heading4, ContainerSt } from 'app/shared/ui';
import {
  ShowMoreSt,
  LastSearchSt,
  ButtonContainerSt,
  CartileContainerSt,
} from 'app/marketplace/home/LastSearch/LastSearch.css';
import { trackAction } from 'app/shared/utils/tracking';
import { CarTileList } from 'app/marketplace/search/CarListing/CarTileList/CarTileList';

const MIN_CARS_ALLOWED = { sm: 4, md: 4, lg: 3, xl: 3 };

export class LastSearch extends PureComponent {
  componentDidMount() {
    const { getLastSearch } = this.props;
    getLastSearch();
  }

  handleTracking = () => {
    const { target } = this.props;

    trackAction('last-search_show-more_button_click', {
      category: `${target}__last-search`,
      description:
        'user clicks on the show me more button in the last search widget',
    });
  };

  render() {
    const {
      target,
      vehicles,
      isLoading,
      breakpoint,
      favouriteIds,
      lastSearchUrl,
      performanceUnit,
      toggleFavourite,
      showFavLoginPopup,
    } = this.props;
    const limitedVehicles = vehicles.slice(0, MIN_CARS_ALLOWED[breakpoint]);

    return (
      vehicles.length > MIN_CARS_ALLOWED[breakpoint] && (
        <LastSearchSt>
          <ContainerSt>
            <Heading4
              as="h2"
              mb={[24, 24, 32]}
              fontSize={[24, 28, 32]}
              lineHeight={[1.67, 1.5]}
              fontWeight={800}
            >
              Autos zu deiner letzten Suche
            </Heading4>
          </ContainerSt>
          <CartileContainerSt>
            <CarTileList
              target={target}
              isLoading={isLoading}
              breakpoint={breakpoint}
              vehicles={limitedVehicles}
              favouriteIds={favouriteIds}
              toggleFavourite={toggleFavourite}
              performanceUnit={performanceUnit}
              budgetRangeValue="" // this is some hack to prevent the last search cartiles to switch to monthly rate when users switch to MTL on the filterpanel, this needs to be updated when a decision is taken about the expected behaviour.
              showFavLoginPopup={showFavLoginPopup}
              handleCarTileClick={this.handleTracking}
            />
          </CartileContainerSt>
          <ButtonContainerSt>
            <ShowMoreSt
              to={`/gebrauchtwagen?${lastSearchUrl}`}
              onClick={this.handleTracking}
            >
              Zeig mir mehr davon
            </ShowMoreSt>
          </ButtonContainerSt>
        </LastSearchSt>
      )
    );
  }
}

LastSearch.propTypes = {
  vehicles: PropTypes.array,
  breakpoint: PropTypes.string,
  target: PropTypes.string.isRequired,
  isLoading: PropTypes.bool.isRequired,
  getLastSearch: PropTypes.func.isRequired,
  favouriteIds: PropTypes.array.isRequired,
  toggleFavourite: PropTypes.func.isRequired,
  lastSearchUrl: PropTypes.string.isRequired,
  showFavLoginPopup: PropTypes.bool.isRequired,
  performanceUnit: PropTypes.string.isRequired,
  budgetRangeValue: PropTypes.string.isRequired,
};

LastSearch.defaultProps = {
  vehicles: [],
  breakpoint: 'sm',
};

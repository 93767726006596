/* eslint-disable @typescript-eslint/no-unsafe-return */
import styled, { css } from 'styled-components';

import { Text3 } from '../Typo/Typo';

import { ITilePillStyleProps } from './TilePill.types';

export const TilePillSt = styled(Text3).attrs((props: ITilePillStyleProps) => ({
  p: ['2px 8px', null, '4px 12px'],
  mr: '4px',
  mb: '4px',
  fontSize: [10, null, 12],
  lineHeight: '16px',
  ...props,
}))<ITilePillStyleProps>`
  display: inline-block;
  font-size: 10px;
  border-radius: 14px;
  ${({ isLoading, theme }: ITilePillStyleProps) =>
    isLoading &&
    css`
      color: ${theme.colors.grayLight};
      background-color: ${theme.colors.grayLight};
      pointer-events: none;

      & * {
        opacity: 0;
      }

      &:hover {
        color: ${theme.colors.grayLight};
      }
    `};
`;

/* eslint-disable no-underscore-dangle */

import {
  generateAdditionalTrackingParams,
  LEAD_CREATION_EVENTS,
  trackTradeInCheckboxClick,
} from 'app/marketplace/contactDealer/trackingUtils';
import { getContent, getContents } from 'app/marketplace/contentful/utils';
import { tvTrackingPixelName } from 'app/marketplace/cookieDashboard/utils';
import { trackAction } from 'app/shared/utils/tracking';
import { createGUID } from 'app/shared/utils/utils';
import { getAverageWebVitalsScores } from 'app/shared/modules/Campaign/utils';

/**
 * @typedef {import('app/types/entities/Vehicle.types').IVehicle} IVehicle
 * @typedef {import('app/shared/modules/Campaign/Campaign.types').TCampaignTrackingArgs} TCampaignTrackingArgs
 *
 */

/**
 *
 * @param {IVehicle} vehicle
 * @param {boolean} isRequestVideo
 */
export const tvTracking = (vehicle, isRequestVideo) => {
  if (
    !isRequestVideo &&
    Array.isArray(window._adftrack) &&
    typeof window.loadTvTracking === 'function' &&
    typeof window.loadTvTrackingPixel === 'function'
  ) {
    window._adftrack.push({
      pm: 1230013,
      divider: encodeURIComponent('|'),
      pagename: encodeURIComponent('XM_HeyCar - E-Mail'),
      order: {
        itms: [
          {
            productname: vehicle.id,
            productsales: vehicle.price,
          },
        ],
      },
    });

    window.loadTvTracking();
    window.loadTvTrackingPixel(tvTrackingPixelName.email);
  }
};

/**
 *
 * @param {IVehicle} vehicle
 * @param {boolean=} hasCampaign
 * @param {boolean} isRequestVideo
 * @param {string=} category
 * @param {string} eventSourceLabel
 * @param oneClickLeadsCheckboxVisible
 */
export const trackOnOpen = ({
  vehicle,
  hasCampaign = false,
  isRequestVideo,
  category = 'PDP',
  eventSourceLabel,
  oneClickLeadsCheckboxVisible = null,
}) => {
  const eventId = createGUID();
  let eventName = 'open-mail-modal';
  let eventDescription = 'user opens lead modal';
  let eventLabel = `mail:${vehicle.id}`;
  let value = null;

  if (hasCampaign) {
    eventName = 'mail_voucher_campaign_button_click';
    eventDescription = 'user clicks on voucher campaign badge';
    eventLabel = `mailVoucherCampaign:${vehicle.id}`;

    /* istanbul ignore next */
    const { markers } = vehicle || {};
    if (markers && Array.isArray(markers) && markers.length > 0) {
      value = markers.map((marker) => marker.id).join();
    }
  }

  if (isRequestVideo) {
    eventName = 'open-video-modal';
    eventDescription = 'user opens video lead modal';
    eventLabel = `video:${vehicle.id}`;
  }

  trackAction(
    eventName,
    {
      category,
      label: `${vehicle.id}`,
      eventId,
      order_id: createGUID(),
      description: eventDescription,
      value,
    },
    generateAdditionalTrackingParams(vehicle, {
      eventId,
      event_label: eventSourceLabel,
      oneClickLeadsCheckboxVisible,
    }),
  );

  trackAction(
    'marketing_contact_dealer',
    {
      category,
      label: eventLabel,
      eventId,
      order_id: createGUID(),
      description: 'user clicks on mail dealer button',
    },
    generateAdditionalTrackingParams(vehicle, {
      eventId,
      event_label: eventSourceLabel,
    }),
  );
};

/**
 *
 * @param {IVehicle} vehicle
 * @param {boolean} isRequestVideo
 * @param {string} category
 * @param {string} eventLabel
 * @param {TCampaignTrackingArgs} campaignTrackingArgs
 */
export const trackOnSubmit = (
  vehicle,
  isRequestVideo,
  category = 'PDP',
  eventLabel,
  campaignTrackingArgs,
) => {
  const eventId = createGUID();
  // trackAction first argument
  let eventName = isRequestVideo
    ? 'send-video-modal-dealer_button_click'
    : 'send-mail-modal-dealer_button_click';

  // trackAction second argument
  let options = {
    category,
    label: `${vehicle.id}`,
    eventId: createGUID(),
    order_id: createGUID(),
    description: 'user clicks on "Send" on lead modal',
  };

  // trackAction third argument
  let additionalProps = generateAdditionalTrackingParams(vehicle, {
    eventId,
    event_label: eventLabel,
  });

  // If a campaign, merge unique campaign data into the arguments
  // `label` here should overwrite the existing label with campaign tracking text
  if (Array.isArray(campaignTrackingArgs)) {
    const [name, opts, addProps] = campaignTrackingArgs;
    if (!isRequestVideo) {
      eventName = name;
    }
    options = { ...options, ...opts };
    additionalProps = { ...additionalProps, ...addProps };
  }
  // fire event tracking
  trackAction(eventName, options, additionalProps);

  // then track everything else
  tvTracking(vehicle, isRequestVideo);
};

/**
 *
 * @param {IVehicle} vehicle
 * @param {string} eventLabel
 * @param {TCampaignData} campaignData
 * @param {string} category
 * @param {boolean} isRequestVideo
 * @param {boolean} hasUserInteractedWithCalculator
 * @param {boolean} hasTradeInData
 * @param oneClickLeadsSelected
 * @param {IUserData} userFormData
 */
export const trackMessageSendSuccess = ({
  vehicle,
  eventLabel,
  campaignData,
  category = 'PDP',
  isRequestVideo = false,
  hasUserInteractedWithCalculator = false,
  hasTradeInData = false,
  oneClickLeadsSelected,
  userFormData = null,
}) => {
  const eventId = createGUID();
  const captiveId = vehicle.financingPrecalc?.searchFilters?.captiveId;
  const actionName = isRequestVideo
    ? LEAD_CREATION_EVENTS.SENT_REQUEST_VIDEO_FORM_SUCCESS
    : LEAD_CREATION_EVENTS.SENT_EMAIL_FORM_SUCCESS;

  const description = (type) =>
    `successful API call for sending of ${type} lead`;

  const webVitals = getAverageWebVitalsScores();

  trackAction(
    actionName,
    {
      category,
      label: `${vehicle.id}`,
      value: (
        campaignData?.matchingCampaigns?.map(
          /* istanbul ignore next */ (campaign) => campaign?.id,
        ) ?? []
      )
        .filter(/* istanbul ignore next */ (c) => c !== '2211_dc_1000')
        .join(),
    },
    generateAdditionalTrackingParams(vehicle, {
      eventId,
      event_label: eventLabel,
      ...(captiveId && { captiveId }),
      ...(captiveId && { fdl: hasUserInteractedWithCalculator }),
      insurancePartners: vehicle.dealer?.insurancePartners,
      description: description(isRequestVideo ? 'video' : 'email'),
      tradein: hasTradeInData,
      oneClickLeadsSelected,
      ...(webVitals && /* istanbul ignore next */ { webVitals }),
    }),
    false,
    userFormData,
  );
};

/**
 * @param {IVehicle} vehicle
 * @param {boolean} isRequestVideo
 * @param {string} category
 * @param {string} eventLabel
 * @param campaignData
 */
export const trackMessageSendFail = (
  vehicle,
  isRequestVideo,
  category = 'PDP',
  eventLabel,
  campaignData,
) => {
  const description = (type) =>
    `unsuccessful API call for sending of ${type} lead`;
  trackAction(
    isRequestVideo ? 'sent_request-video_form_error' : 'sent_email_form_error',
    {
      category,
      description: description(isRequestVideo ? 'video' : 'email'),
      label: `${vehicle.id}`,
      event_label: eventLabel,
      value: (
        campaignData?.matchingCampaigns?.map(
          /* istanbul ignore next */ (campaign) => campaign?.id,
        ) ?? []
      ).join(),
    },
  );
};

/* istanbul ignore next */
export const getCampaignInfoScreenContent = ({
  content,
  vehicleMake,
  vehicleModel,
  voucherAmount,
}) => {
  try {
    // Info Screen Content (Markdown)
    const markdownContainerContents = getContents(content, 'markdownContainer');
    const dynamicMarkdownContent = markdownContainerContents[0]?.fields?.content
      ?.replace(/{VEHICLE_MAKE}/g, vehicleMake || '')
      ?.replace(/{VEHICLE_MODEL}/g, vehicleModel || '')
      ?.replace(/{VOUCHER_VALUE}/g, voucherAmount || '');

    // Disclaimer Content (LegalText)
    const legalTextContent = getContent(content, 'LegalText');

    return {
      accordionTitle: markdownContainerContents[1]?.fields?.content || '',
      description: dynamicMarkdownContent,
      disclaimer: legalTextContent?.fields?.text,
    };
  } catch (error) {
    /* istanbul ignore next */
    return {
      accordionTitle: '',
      description: '',
      disclaimer: '',
      title: '',
    };
  }
};

/* istanbul ignore next */
export const getCampaignSuccessScreenContent = ({
  vehicleMake,
  vehicleModel,
  dealerName,
  campaigns,
  vouchers,
}) => {
  try {
    return campaigns.map((campaign) => {
      const content = campaign?.successScreen;
      const markdownContainerContent = getContent(content, 'markdownContainer');
      const dynamicMarkdownContent = markdownContainerContent?.fields?.content
        .replace(/{VEHICLE_MAKE}/g, vehicleMake)
        .replace(/{VEHICLE_MODEL}/g, vehicleModel)
        .replace(/{DEALER_NAME}/g, dealerName)
        .replace(/{VOUCHER_VALUE}/g, vouchers?.[campaign?.id]?.voucherAmount)
        .replace(
          /{VOUCHER_CODE}/g,
          `<span class="voucherCode" data-campaign-element="pdp-success-screen-voucher-code">${
            vouchers?.[campaign?.id]?.voucherCode
          }</span>`,
        );
      const legalTextContent = getContent(content, 'LegalText');

      return {
        description: dynamicMarkdownContent,
        disclaimer: legalTextContent?.fields?.text,
        id: campaign?.id,
        campaignLogo: campaign?.campaignLogo?.fields,
      };
    });
  } catch (error) {
    /* istanbul ignore next */
    return [];
  }
};

export const getFormattedTradeInInformation = (
  brandModel,
  kilometer,
  registration,
) => {
  let tradeinInformation =
    '<br /><br />Außerdem habe ich Interesse an einer Inzahlungnahme für mein derzeitiges Fahrzeug.';

  if (brandModel) tradeinInformation += `<br /> - ${brandModel}`;
  if (kilometer) tradeinInformation += `<br /> - ${kilometer} km`;
  if (registration)
    tradeinInformation += `<br />- Erstzulassung ${registration}`;

  return tradeinInformation;
};
export const onChangeTradeInCheckbox = (setFieldValue, values, target) => {
  trackTradeInCheckboxClick(!values.hasTradeInCar, target);
  setFieldValue('hasTradeInCar', !values.hasTradeInCar);

  if (!values.hasTradeInCar) {
    setFieldValue('registration', '');
    setFieldValue('brandModel', '');
    setFieldValue('kilometer', '');
  }
};

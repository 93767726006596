import isString from 'lodash.isstring';
import isNil from 'lodash.isnil';

import { TRACKING_LIST } from '../tracking/list';

/**
 * FDL Ready Dealers
 * we use this array to enable/disable banks
 * You need to update this if you want to add/remove bank
 *
 * @type {array}
 * @constant
 */
const fdlReadyDealers = [
  'vwfs',
  'mbb',
  'pfs',
  'aeb',
  'bmwfs',
  'bmwb',
  'rb',
  'nb',
  'instamotion',
];

/**
 * Checks FDL Dealer
 *
 * @export
 * @param {string} fdlDealer FDL Dealer Code (i.e vwfs)
 * @returns {boolean} isFinanceEligible
 */
export function isFinanceEligible(fdlDealer) {
  if (!fdlDealer) {
    return false;
  }
  return Boolean(
    fdlReadyDealers.some((item) => item === fdlDealer.toLowerCase()),
  );
}

/**
 * Check Vehicle Info for FDL Requirements
 *
 * @export
 * @param {object} vehicle Vehicle Info
 * @returns {boolean} FDL Requirement
 */
export function meetsFdlRequirements(vehicle) {
  return (
    !isNil(vehicle.financingPrecalc) &&
    vehicle.financingPrecalc !== {} &&
    !isNil(vehicle.financingPrecalc.searchFilters)
  );
}

/**
 * Full FDL Check for Vehicle
 *
 * @export
 * @param {string} fdlDealer FDL Dealer Code
 * @param {object} vehicle Vehicle Info
 * @returns {boolean} FDL Requirement
 */
export function fdlDealerCheck(fdlDealer, vehicle) {
  const result =
    fdlDealer &&
    vehicle &&
    fdlReadyDealers.some((item) => item === fdlDealer.toLowerCase()) &&
    meetsFdlRequirements(vehicle);

  return result && result !== '';
}

/**
 * Update calculation list with new one
 *
 * @export
 * @param {object} calculation New Calculation Data
 * @param {object} state Current state of FDL Calculator
 * @returns {Array} Calculation List
 */
export function updateCalculations(calculation, state) {
  const { calculations } = state;
  const { id } = calculation;
  const foundIndex = calculations.findIndex((item) => item.id === id);

  if (foundIndex === -1) {
    calculations.push(calculation);
  } else {
    calculations[foundIndex] = calculation;
  }

  return calculations;
}

const definedErrorMessages = {
  default:
    'Verzeihe, hier ist etwas schief gelaufen. Der Finanzierungs-Kalkulator ' +
    'steht für dieses Fahrzeug leider temporär nicht zur Verfügung. Bitte versuche es später wieder ' +
    'oder wende dich direkt an den Händler des Fahrzeugs ' +
    'um ein individuelles Finanzierungsangebot zu erhalten.',
  vwfs2027:
    'Der Abschluss für die Absicherung (PLUS) bei finanziellem Engpass ' +
    'ist mit den gewählten Parametern nicht möglich. Durch Änderung von Anzahlung ' +
    'und / oder Laufzeit ist der Abschluss ggf. dennoch möglich.',
};

/**
 * Get Error Message for FDL
 *
 * @export
 * @param {string} error Error Data
 * @returns {string} Error Message
 */
export function getErrorMessage(error) {
  if (!isString(error)) {
    return definedErrorMessages.default;
  }

  let errorCode = 'default';

  if (error.includes('code: 2027')) {
    errorCode = 'vwfs2027';
  }

  return definedErrorMessages[errorCode];
}

let trackingSettings = {
  name: TRACKING_LIST.FINANCING.CALCULATOR.INTERACTION,
  additionalProperties: {
    listing_id: null,
    captiveId: null,
  },
  properties: {
    category: 'fdl',
  },
};

/**
 * @description Get tracking settings for FDL
 * @export
 * @param {*} [name='interacted_with_fin_calculator'] Name
 * @param {*} [additionalProperties={}] Additional Properties
 * @param {*} [properties={}] Properties
 * @returns {object} setting data
 */
export function getTrackingSettings(
  name = trackingSettings.name,
  additionalProperties = trackingSettings.additionalProperties,
  properties = trackingSettings.properties,
) {
  if (!name) {
    name = trackingSettings.name;
  }

  if (!additionalProperties) {
    additionalProperties = trackingSettings.additionalProperties;
  }

  if (!properties) {
    properties = trackingSettings.properties;
  }

  trackingSettings = {
    ...trackingSettings,
    name,
    additionalProperties,
    properties,
  };

  return trackingSettings;
}

/**
 * Disables calculator on PDP if either vehicle is not anymore
 * for sale or if it is available but offers no financing
 *
 * @export
 * @param {object} vehicle Vehicle object
 * @param {string} vehicle.state Vehicle availability status
 * @param {string} vehicle.financingPrecalc.searchFilters.captiveId Bank provider of the financing
 * @returns {boolean} Calculator should be disabled or not
 */
export function isFDLCalculatorDisabled(vehicle) {
  if (!vehicle) return false;

  const { live, financingPrecalc } = vehicle;
  const captiveId = financingPrecalc?.searchFilters?.captiveId;
  return !live || !fdlDealerCheck(captiveId, vehicle);
}

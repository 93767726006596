// Filters where more than one value can be selected at a time
export const checkBoxFilters = [
  'category',
  'make',
  'model',
  'gearBox',
  'bodyColor',
  'interiorColor',
  'interiorType',
  'fuelType',
  'emissionClass',
  'singleDayRegistration',
  'dealer',
  'feature',
  'seed',
  'seats',
  'doors',
  'vehicleCondition',
  'trim',
  // campaigns for special offers
  'campaign',
  'usedCarSeal',
  'markerId',
  'specialOffers',
  'dataSource',
  'productOffering',
  'airbags',
  'climatisation',
  'unusedCar',
  'boniCheck',
];

// Filters where the value should be a range, and from/to are integers
export const intRangeFilters = [
  'yearRange',
  'mileageRange',
  'priceRange',
  'monthlyRateRange',
  'leasingRateRange',
  'performanceRange',
  'consumptionRange',
  'leasingRateRange',
];

// Filters where the value should be a range, and from/to are floats
export const floatRangeFilters = ['effInterestRateRange'];

// Filters where only one value can be selected at a time
export const radioFilters = [
  'maxOwners',
  'sortBy',
  'page',
  'gLocationId',
  'lat',
  'lon',
  'radius',
  'q',
  'fullQuery',
  'lifeStyle',
  'headerCLP',
  'performanceRangeSwitch',
  'budgetRangeSwitch',
];

// the Filters that should be sent to the api as well
export const apiFilters = [
  'priceRange',
  'monthlyRateRange',
  'leasingRateRange',
  'yearRange',
  'make',
  'model',
  'trim',
  'mileageRange',
  'page',
  'lat',
  'lon',
  'radius',
  'performanceRange',
  'q',
  'feature',
  'seed',
  'seats',
  'doors',
  'vehicleCondition',
  'leasingRateRange',
  'consumptionRange',
  'campaign',
  'usedCarSeal',
  'markerId',
  'specialOffers',
  'captive',
  'financingProduct',
  'bodyColor',
  'hasDcc',
  'airbags',
  'climatisation',
  'unusedCar',
  'hasLeasingRate',
  'priceMargin',
];

export const filtersUrlExcluded = [
  'possibleMakes',
  'possibleModels',
  'error',
  'performanceRangeSwitch',
  'budgetRangeSwitch',
];

// these are Filters that should not be taken into consideration for the pills, and the "isAFilterSelected" function in utils
export const notFiltersThatCountAsFilters = [
  'q',
  'page',
  'error',
  'allMakes',
  'allUsedCarSeal',
  'possibleMakes',
  'possibleModels',
  'performanceRangeSwitch',
  'budgetRangeSwitch',
  'priceMargin',
  'sortBy',
  'fullQuery',
  'lat',
  'lon',
  'lifeStyle',
  'headerCLP',
  // marketing ad IDs
  'gLocationId',
  'gclid',
  'cid',
  'adid',
  'agid',
  'kw',
  'mt',
];

// these params from google ads should not be removed
export const marketingParamsWhitelist = [
  'gLocationId',
  'gclid',
  'cid',
  'adid',
  'agid',
  'kw',
  'mt',
];

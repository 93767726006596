/* eslint-disable no-undef */
import { useEffect } from 'react';
import { setTags } from '@sentry/react';

/**
 * Sets Sentry context tags and cleans them up on hook unmount
 * @param {object} tags - Tags that will be appended to Sentry issues while hook is mounted
 */
export const useSentryTags = (tags) => {
  useEffect(() => {
    setTags(tags);
    return () => {
      const emptyTags = {};
      Object.keys(tags).forEach((key) => {
        emptyTags[key] = undefined;
      });

      setTags(emptyTags);
    };
  }, []);
};

import styled, { css } from 'styled-components';

import {
  CARTILE_IMAGE_WIDTH,
  CARTILE_IMAGE_HEIGHT,
} from 'app/shared/modules/Cartile/CartileTemplates/CartileTemplates.constant';
import { color, media } from 'app/shared/styles_js/variables';

export const CartileHorizontalImageContainerSt = styled.div`
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 100%;
  padding-bottom: ${({ isempty }) => (isempty ? '75%' : '0')};
  border-radius: 4px 4px 0 0;
  text-decoration: none;
  background-color: ${color.grayLight};
  ${media.md(
    css`
      width: ${CARTILE_IMAGE_WIDTH.md}px;
      height: ${CARTILE_IMAGE_HEIGHT.md}px;
      padding-bottom: 0;
      border-radius: 4px 0 0 4px;
    `,
  )};
  ${media.lg(
    css`
      width: ${CARTILE_IMAGE_WIDTH.lg}px;
      height: ${CARTILE_IMAGE_HEIGHT.lg}px;
    `,
  )};
  ${media.xl(
    css`
      width: ${CARTILE_IMAGE_WIDTH.xl}px;
      height: ${CARTILE_IMAGE_HEIGHT.xl}px;
    `,
  )};
  > img {
    display: block;
    width: 100%;
    height: auto;
    padding-bottom: 0;
    margin: 0 auto;
  }
`;

export const CartileVerticalImageContainerSt = styled.div`
  z-index: 1;
  display: flex;
  position: relative;
  padding-top: 75%;
  width: 100%;
  border-radius: 4px 4px 0 0;
  background-color: ${color.grayLight};
  ${({ isunavailable }) =>
    isunavailable &&
    css`
      pointer-events: none;
    `}
`;

export const CartileImageContainerSt = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px 4px 0 0;
`;

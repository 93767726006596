export const SERVER_ERROR_MSG =
  'Hoppla, irgendwas ist schief gelaufen. Versuche es in wenigen Sekunden erneut.';

export const DUPLICATED_EMAIL_ERROR_MSG =
  'E-Mail-Adresse wurde bereits registriert.';

export const INVALID_EMAIL_ERROR_MSG =
  'Bitte gib eine gültige E-Mail Adresse ein.';

export const EMAIL_MISMATCH_ERROR_MSG =
  'Die eingegebenen E-Mail Adressen stimmen nicht überein. Bitte prüfe diese.';

export const INVALID_DATE_ERROR_MSG =
  'Bitte gib ein gültiges Format für das Datum ein.';

export const EMPTY_FIRST_NAME_ERROR_MSG = 'Bitte gib einen Vornamen ein.';

export const EMPTY_LAST_NAME_ERROR_MSG = 'Bitte gib einen Nachnamen ein.';

export const EMPTY_MESSAGE_ERROR_MSG = 'Bitte gib eine Nachricht ein.';

export const EMPTY_DATE_ERROR_MSG = 'Bitte gib ein Datum ein';

export const SPECIAL_CHARS_ERROR_MSG =
  'Du kannst nur Buchstaben, Leerzeichen und "-" verwenden.';

export const IS_MANDATORY_ERROR_MSG = '* Pflichtfeld';

export const ARE_MANDATORY_ERROR_MSG = '* Pflichtfelder';

export const EMPTY_TRADEIN_INPUT_ERROR_MSG = 'Bitte ausfüllen';

import contactDealerMessages from 'app/marketplace/contactDealer/messages';

import { getMailMessageContent as getMailMessageContentTradeIn } from '../tradein/utils';
import { CAPTIVES } from '../constants';

import { FDLContactDealerMessages } from './messages';
import { getUserInterestsForLeadCreation } from './getUserInterestsForLeadCreation';
import { getFinancingMessageWithFinancingData } from './getFinancingMessageWithFinancingData';
import { userInterestsForLeadCreation } from './constants';

/**
 * @description Generates a string with the user's
 * selected financing settings for when creating a mail lead
 *
 * @param {string} captiveId vehicle's financing captive
 * @param {object} calculationData user's selected financing settings
 * @param {object} valuatedVehicle latest user inputted vehicle for trade-in
 * @returns {string} user's selected financing settings
 */
export function getFinancingLeadMessage(
  captiveId,
  calculationData,
  valuatedVehicle,
) {
  const leadInterestType = getUserInterestsForLeadCreation(
    calculationData,
    valuatedVehicle,
  );

  switch (leadInterestType) {
    case userInterestsForLeadCreation.financing: {
      const financingMessageWithFinancingData = getFinancingMessageWithFinancingData(
        calculationData,
      );

      return FDLContactDealerMessages.financingInterest(
        financingMessageWithFinancingData,
      );
    }

    case userInterestsForLeadCreation.tradeIn: {
      return FDLContactDealerMessages.tradeInInterest(
        getMailMessageContentTradeIn(valuatedVehicle),
      );
    }

    case userInterestsForLeadCreation.financingAndTradeIn: {
      const financingMessageWithFinancingData = getFinancingMessageWithFinancingData(
        calculationData,
      );
      const tradeinDetails =
        (calculationData && '\n') +
        getMailMessageContentTradeIn(valuatedVehicle);

      return FDLContactDealerMessages.financingAndTradeInInterest(
        financingMessageWithFinancingData,
        tradeinDetails,
      );
    }

    default:
      if (
        CAPTIVES.VOLKSWAGEN.includes(captiveId) ||
        CAPTIVES.PORSCHE.includes(captiveId)
      ) {
        return contactDealerMessages.mailMessageFinancing;
      }

      return contactDealerMessages.defaultMailMessage;
  }
}

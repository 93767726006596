import styled, { css } from 'styled-components';

import { isNot, media, fontSizes } from '../../styles_js/variables';

const pStandards = css`
  font-style: normal;
  font-stretch: normal;
  letter-spacing: normal;
`;

const fontSizeMixin = (defaultSize, biggerSize) => css`
  font-size: ${fontSizes[defaultSize]};
  ${biggerSize &&
  isNot('noResponsive')`
    ${media.xl(css`
      font-size: ${fontSizes[biggerSize]};
    `)};
  `};
`;

const P = styled.p`
  ${pStandards};
  ${fontSizeMixin('default', 'big')};
`;

P.Small = styled.p`
  ${pStandards};
  ${fontSizeMixin('small', 'default')};
`;

P.Smaller = styled.p`
  ${pStandards};
  ${fontSizeMixin('smaller', 'small')};
`;

P.Smallest = styled.p`
  ${pStandards};
  ${fontSizeMixin('smallest', 'smaller')};
`;

P.Big = styled.p`
  ${pStandards};
  ${fontSizeMixin('big', 'bigger')};
`;

P.Bigger = styled.p`
  ${pStandards};
  ${fontSizeMixin('bigger', 'biggest')};
`;

P.Biggest = styled.p`
  ${pStandards};
  ${fontSizeMixin('biggest')};
`;

const Span = styled.span`
  ${fontSizeMixin('default', 'big')};
`;

Span.Small = styled.span`
  ${fontSizeMixin('small', 'default')};
`;

Span.Smaller = styled.span`
  ${fontSizeMixin('smaller', 'small')};
`;

Span.Smallest = styled.span`
  ${fontSizeMixin('smallest', 'smaller')};
`;

Span.Big = styled.span`
  ${fontSizeMixin('big', 'bigger')};
`;

Span.Bigger = styled.span`
  ${fontSizeMixin('bigger', 'biggest')};
`;

Span.Biggest = styled.span`
  ${fontSizeMixin('biggest')};
`;

const B = styled.b`
  ${fontSizeMixin('default', 'big')};
`;

B.Small = styled.b`
  ${fontSizeMixin('small', 'default')};
`;

B.Smaller = styled.b`
  ${fontSizeMixin('smallest', 'smaller')};
`;

B.Smallest = styled.b`
  ${fontSizeMixin('smallest', 'smaller')};
`;

B.Big = styled.b`
  ${fontSizeMixin('big', 'bigger')};
`;

B.Bigger = styled.b`
  ${fontSizeMixin('bigger', 'biggest')};
`;

B.Biggest = styled.b`
  ${fontSizeMixin('biggest')};
`;

export { B, Span, P };

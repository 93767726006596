import { createTypes } from 'app/shared/utils/createTypes';

export const NEWSLETTER_SOURCE = {
  footer: 'FOOTER',
  slideIn: 'SLIDE_IN',
  sweepstakesForm: 'SWEEPSTAKES_FORM',
  mobileSlideIn: 'SLIDE_IN_MOBILE',
  userSurvey: 'USER_SURVEY_DESKTOP',
  mobileUserSurvey: 'USER_SURVEY_MOBILE',
};

export const CRM_NEWSLETTER_SUBSCRIBE_URL = '/subscriptions/newsletter';
export const USER_SURVEY_SUBSCRIBE_URL = '/subscriptions/survey';
export const SWEEPSTAKES_SUBSCRIBE_URL = '/subscriptions/lottery';
export const SUCCESS_STATUS = 'success';

export const ActionTypes = createTypes([
  'NEWSLETTER_SUBMISSION_',
  'USER_SURVEY_SUBMISSION_',
  'SWEEPSTAKES_SUBMISSION_',
]);

/**
 * Gets a stacktrace at the current position in the stack
 * @returns {string} - the stacktrace
 */
const getStack = () => {
  const e = new Error('NONE');
  if (e.stack) {
    const stack = e.stack.split(/\n/);

    /*
      remove the items we added by artificially throwing the error
      and also remove the call to the logger and add a newline
      */

    stack.splice(0, 3, '\n');

    return stack.join('\n');
  }
  return '';
};

/**
 * A proxy that will forward all console functions to our personal, enhanced logging function
 * (which is inlined in this case)
 * adds a stacktrace for 'log', 'error', and 'warn' calls
 * @type {{}}
 */
const loggerObjectProxy = {
  log: (...args) => {
    args.push(getStack());
    // eslint-disable-next-line no-console
    console.log(...args);
  },
  error: (...args) => {
    args.push(getStack());
    // eslint-disable-next-line no-console
    console.error(...args);
  },
  warn: (...args) => {
    args.push(getStack());
    // eslint-disable-next-line no-console
    console.warn(...args);
  },
};

export const logger = loggerObjectProxy;

import * as ActionTypes from './constants';
import { GeoLocationActionsTypes } from './types';

export const DEFAULT_LOCATION_SUGGESTIONS = [
  {
    place: 'Essen',
    zip: '45127',
    lat: 52.5323,
    lon: 13.3846,
  },
  {
    place: 'München',
    zip: '80331',
    lat: 48.1345,
    lon: 11.571,
  },
  {
    place: 'Berlin',
    zip: '10115',
    lat: 51.4535,
    lon: 7.0102,
  },
];

export const defaultState = () => {
  return {
    locationPending: false,
    currentLocation: {},
    locationSuggestions: DEFAULT_LOCATION_SUGGESTIONS,
    error: null,
  };
};

export function geolocation(state = defaultState(), action) {
  const { type, payload, error } = action;

  if (type === ActionTypes.SET_CURRENT_LOCATION_PENDING) {
    return { ...state, locationPending: true, error: null };
  }
  if (type === ActionTypes.SET_CURRENT_LOCATION_SUCCESS) {
    return {
      ...state,
      currentLocation: payload,
      locationPending: false,
      error: null,
    };
  }
  if (type === ActionTypes.SET_CURRENT_LOCATION_ERROR) {
    const { status } = error;

    return {
      ...state,
      error: status && status === 404 ? null : error,
      currentLocation: {},
      locationPending: false,
    };
  }
  if (type === ActionTypes.RESET_CURRENT_LOCATION) {
    return { ...state, ...defaultState() };
  }
  if (type === ActionTypes.GET_LOCATION_SUGGESTIONS_PENDING) {
    return { ...state, locationPending: true, error: null };
  }
  if (type === ActionTypes.SET_USER_LOCATION_DENIED) {
    return { ...state, locationPending: false, error };
  }
  if (type === ActionTypes.GET_LOCATION_SUGGESTIONS_SUCCESS) {
    return {
      ...state,
      locationSuggestions: payload,
      locationPending: false,
      error: null,
    };
  }
  if (type === ActionTypes.GET_LOCATION_SUGGESTIONS_ERROR) {
    return { ...state, error, locationSuggestions: [], locationPending: false };
  }
  if (type === GeoLocationActionsTypes.RESET_LOCATION_SUGGESTION) {
    return { ...state, locationSuggestions: DEFAULT_LOCATION_SUGGESTIONS };
  }

  return state;
}

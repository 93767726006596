import styled, { css } from 'styled-components';
import Slider from 'react-slick';

import { colors } from '../variables/colors';
import { media } from '../variables/media';

import { ICarouselProps } from './Carousel.types';

export const CarouselSt = styled(Slider)<ICarouselProps>`
  position: relative;
  display: block;
  user-select: none;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;

  &.slick-initialized .slick-slide {
    display: block;
  }

  .slick-track,
  .slick-list {
    transform: translate3d(0, 0, 0);
  }

  .slick-list {
    overflow: hidden;
    visibility: ${({ isCarouselReady }) => !isCarouselReady && 'hidden'};

    ${({ tileGap }) =>
      tileGap &&
      css`
        margin-right: -8px;
        ${media.md(css`
          margin-right: -16px;
        `)};
      `};
  }

  .slick-track {
    position: relative;
    top: 0;
    left: 0;
  }

  .slick-slide {
    display: none;
    height: 100%;
    min-height: 1px;
    float: left;

    & > div {
      ${({ marginBetween }) =>
        marginBetween &&
        css`
          margin-right: ${`${marginBetween[0] / 2}px`};
          ${media.md(css`
            margin-right: ${`${marginBetween[1] / 2}px`};
          `)};
        `};
    }
  }

  .slick-arrow {
    position: absolute;
    top: -56px;
    right: 0;
    font-size: 24px;
    cursor: pointer;

    ${media.xl(css`
      top: -64px;
      right: 0;
    `)};

    &.slick-prev {
      right: 88px;
    }

    :focus {
      outline: none;
    }
  }

  .slick-dots {
    position: absolute;
    display: flex !important;
    width: 100%;
    align-items: center;
    justify-content: center;
    padding: 0;
    margin: 0;
  }

  .slick-dots li {
    display: flex;
    align-items: center;
    list-style: none;
  }

  .slick-dots li button {
    width: 10px;
    height: 10px;
    padding: 0;
    border: none;
    margin: 0;
    margin-right: 5px;
    margin-left: 5px;
    cursor: pointer;
    background-color: ${colors.grayLight};
    opacity: 0.5;
    border-radius: 100%;
  }

  .slick-dots > li.slick-active > button {
    opacity: 1;
  }

  .slick-dots > li:not(.slick-active) > button {
    width: 10px;
    height: 10px;
  }

  .slick-dots > li.slick-active:nth-child(1) + li > button {
    width: 12px;
    height: 12px;
  }

  .slick-dots > li.slick-active:nth-child(1) + li + li > button {
    width: 10px;
    height: 10px;
  }

  .slick-dots > li:nth-last-child(2):not(li.slick-active ~ li) > button {
    width: 12px;
    height: 12px;
  }

  li.slick-active > button {
    width: 12px;
    height: 12px;
    margin-right: 5px;
    margin-left: 5px;
  }
`;

import { useEffect } from 'react';
import { setTags, captureException } from '@sentry/react';

import { IndeterminateLoadingSpinner } from 'app/shared/ui';
import { useRecaptcha } from 'app/shared/modules/recaptcha';

export const NativeRecaptcha = () => {
  const [isScriptLoading, getToken] = useRecaptcha(true);

  const onScriptLoad = async () => {
    if (
      typeof window === 'undefined' ||
      typeof window.ReactNativeWebView === 'undefined'
    )
      return;

    try {
      const token = await getToken('native_recaptcha');

      const message = JSON.stringify({
        action: 'recaptchaToken',
        token,
      });

      window.ReactNativeWebView.postMessage(message);
    } catch (error) {
      setTags({
        environment: PRODUCTION ? 'production' : 'staging',
      });
      captureException(error);

      const message = JSON.stringify({
        action: 'recaptchaToken',
        error: error.toString(),
      });

      window.ReactNativeWebView.postMessage(message);
    }
  };

  useEffect(() => {
    if (isScriptLoading) return;
    onScriptLoad();
  }, [isScriptLoading]);

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
        minHeight: 128,
      }}
    >
      <IndeterminateLoadingSpinner />
    </div>
  );
};

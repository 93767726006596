import styled, { css } from 'styled-components';

import { media } from 'app/shared/styles_js/variables';
import {
  CONTENT_FOOTER_HEIGHT_LG,
  CONTENT_FOOTER_HEIGHT_MD,
} from 'app/shared/modules/Cartile/CartileTemplates/CartileTemplates.constant';

export const CartileContentFooterContainerSt = styled.div`
  z-index: 1;
  display: flex;
  position: relative;
  flex: 0 0 auto;
  justify-content: space-between;
  height: auto;
  &:hover {
    color: unset;
  }
  ${media.md(
    css`
      height: ${CONTENT_FOOTER_HEIGHT_MD}px;
    `,
  )}
  ${media.lg(
    css`
      height: ${CONTENT_FOOTER_HEIGHT_LG}px;
    `,
  )}
`;

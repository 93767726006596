/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import styled from 'styled-components';

import { Heading5 } from '../../../../../../../Typo/Typo';

export const NavItemCategoryTitle = styled(Heading5)`
  margin-bottom: 24px;
  font-weight: 800;
  color: ${(props) => props.theme.colors.tarmacGrey};
`;

import { memo } from 'react';

import {
  LabelSt,
  CategorySt,
  CategoryLinksSt,
} from 'app/marketplace/home/CategoryLinks/CategoryLinks.css';
import { trackAction } from 'app/shared/utils/tracking';
import { TitleSt } from 'app/marketplace/home/GuaranteeBenefits/GuaranteeBenefits.css';
import {
  categories,
  ICategory,
} from 'app/marketplace/home/CategoryLinks/utils';

interface CategoryLinksProps {
  resetFilters: (filters: string[]) => void;
}

const CategoryLinksCmp = ({
  resetFilters,
}: CategoryLinksProps): JSX.Element => {
  const handleOnClick = (label: string) => {
    resetFilters(['allMakes', 'possibleMakes', 'possibleModels']);

    trackAction('car-category-link-list_homepage_click', {
      category: 'home',
      value: label,
      description: 'user clicks on the homepage car category link list',
    });
  };

  return (
    <CategoryLinksSt>
      <TitleSt as="h2" width="100%">
        Unsere Fahrzeugtypen
      </TitleSt>
      {categories.map((category: ICategory) => (
        <CategorySt
          key={category.type}
          title={category.title}
          to={category.linkTo}
          onClick={() => handleOnClick(category.name)}
          data-testid={`marketplace-home-categoryLinks-${category.type}-btn`}
        >
          <i className={`if-icon-${category.type}`} />
          <LabelSt>{category.name}</LabelSt>
        </CategorySt>
      ))}
    </CategoryLinksSt>
  );
};

export const CategoryLinks = memo(CategoryLinksCmp);

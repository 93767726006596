import { ActionTypes } from './constants';

let globalIdCounter = 0;

export const showNotification = element => dispatch => {
  globalIdCounter += 1;
  const id = globalIdCounter;

  dispatch({
    type: ActionTypes.SHOW_NOTIFICATION,
    payload: { id, element },
  });

  return id;
};

export const hideNotification = id => dispatch => {
  dispatch({
    type: ActionTypes.HIDE_NOTIFICATION,
    payload: { id },
  });
};

export const clearNotification = id => dispatch => {
  dispatch({
    type: ActionTypes.CLEAR_NOTIFICATION,
    payload: { id },
  });
};

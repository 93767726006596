/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { getFCP, getLCP, getCLS, getFID, getTTFB, Metric } from 'web-vitals';

import { contentfulCampaignsSelector } from 'app/marketplace/contentful/selectors';
import { markersSelector } from 'app/shared/modules/Campaign/selectors';
import { detectDevice } from 'app/shared/utils/utils';
import { sessionStore } from 'app/shared/utils/storageFactory';
import {
  IWebVitalsMetric,
  IWebVitalsMetricTypes,
  IGetAverageWebVitalScores,
  IFindMetrics,
  IScores,
} from 'app/shared/modules/Campaign/utils.types';

const priorities = ['Low', 'Medium', 'High'];

export const getCampaignData = (
  campaigns: ReturnType<typeof contentfulCampaignsSelector>[],
  markers: ReturnType<typeof markersSelector>[],
  isDealerCashEligible = false,
) => {
  let dealerCashCampaigns = [];

  // Get dealer cash campaign
  if (
    isDealerCashEligible &&
    /* istanbul ignore next */ Array.isArray(campaigns) &&
    /* istanbul ignore next */ campaigns.length > 0
  ) {
    /* istanbul ignore next */
    dealerCashCampaigns = campaigns
      ?.filter((campaign) => campaign?.fields?.id === '2211_dc_1000')
      ?.map((mapItem) => mapItem?.fields);
  }

  if (
    Array.isArray(campaigns) &&
    Array.isArray(markers) &&
    markers.length > 0 &&
    campaigns.length > 0
  ) {
    let matchingCampaigns = campaigns
      .filter((campaign) =>
        markers.some((marker) => campaign?.fields?.id === marker?.campaign?.id),
      )
      .map((mapItem) => mapItem?.fields);

    // Sort campaigns if there are more than one matching campaign
    if (matchingCampaigns?.length > 1) {
      matchingCampaigns = matchingCampaigns.sort((item1, item2) => {
        return (
          // Sort by "campaignPriority" as High > Medium > Low
          priorities.indexOf(item2?.campaignPriority) -
            priorities.indexOf(item1?.campaignPriority) ||
          // Sort alphabetically by "name" if they have the same priority weight
          item1?.name?.localeCompare(item2?.name)
        );
      });
    }

    const primaryCampaign = matchingCampaigns[0];

    const primaryCampaignMarker = markers.find(
      (item) => primaryCampaign?.id === item?.campaign?.id,
    );

    return {
      primaryCampaign,
      matchingCampaigns: [...dealerCashCampaigns, ...matchingCampaigns],
      primaryCampaignMarker,
    };
  }

  return {
    primaryCampaign: null,
    matchingCampaigns: dealerCashCampaigns,
    primaryCampaignMarker: null,
  };
};

export const getPdpPromotionPills = (
  campaigns: ReturnType<typeof contentfulCampaignsSelector>[],
  markers: ReturnType<typeof markersSelector>[],
  isDealerCashEligible = false,
) => {
  const { matchingCampaigns } = getCampaignData(
    campaigns,
    markers,
    isDealerCashEligible,
  );

  const pdpPromotionPills = [...matchingCampaigns]
    .map((campaign) => {
      const marker = markers?.find(
        (item) => item?.campaign?.id === campaign?.id,
      );

      const pillText = campaign?.pdpPromotionPillText?.replace(
        /{VOUCHER_AMOUNT}/g,
        marker?.campaign?.voucherAmount || '',
      );

      return {
        pillText,
        campaignId: campaign?.id,
      };
    })
    .filter((p) => p?.pillText);

  return pdpPromotionPills;
};

export const WEB_VITALS_SS_KEY = 'webVitalsMetrics';

export const retrieveWebVitalsMetrics = (): Array<IWebVitalsMetric> => {
  let webVitalsMetrics: Array<IWebVitalsMetric> = [];

  /**
   * `try`ing to get sessionStorage item, because the third-party cookies and site
   * data might be blocked by the user on browser level and hence access is denied.
   *
   * See: https://sentry.io/share/issue/9011790a48af4d0487000d36a2908b07/
   */
  try {
    const data = sessionStore.getItem(WEB_VITALS_SS_KEY);
    if (data) webVitalsMetrics = JSON.parse(data) as Array<IWebVitalsMetric>;
  } catch {
    // Do nothing for now.
  }

  return webVitalsMetrics;
};

/* istanbul ignore next */
export const storeWebVitalsMetrics = (): void => {
  const storeMetric = (metric: Metric) => {
    const { pathname, search = '' } = window?.location || {};
    if (!pathname) return;

    const isCLP = pathname?.startsWith('/gebrauchtwagen');
    const isPDP = pathname?.startsWith('/vehicle');

    // We will only consider the web vitals metrics on the PDP or CLP for now
    if (isCLP || isPDP) {
      /**
       * `try`ing to store a metric into sessionStorage, because it may fail
       * if the browser reaches the maximum storage limit.
       *
       * See: https://sentry.io/share/issue/751eb40c2b2a4394b8cc80af44407a5c/
       */
      try {
        const { name, value } = metric;
        const webVitalsMetrics = retrieveWebVitalsMetrics();

        webVitalsMetrics.push({
          name,
          value,
          source: isCLP ? 'CLP' : 'PDP',
          path: pathname + search,
        });

        sessionStore.setItem(
          WEB_VITALS_SS_KEY,
          JSON.stringify(webVitalsMetrics),
        );
      } catch {
        // Do nothing for now.
      }
    }
  };

  /**
   * Only the CLS changes on client-side route change. And, reporting on every change
   * can cause some performance issues as the documentation says.
   * See: https://github.com/GoogleChrome/web-vitals#report-the-value-on-every-change
   * So, having reportAllChanges as `true` only makes sense for CLS.
   */
  getFCP(storeMetric);
  getLCP(storeMetric);
  getCLS(storeMetric, true);
  getFID(storeMetric);
  getTTFB(storeMetric);
};

const metrics: IWebVitalsMetricTypes[] = ['FCP', 'LCP', 'CLS', 'FID', 'TTFB'];

export const getAverageWebVitalsScores = (): IGetAverageWebVitalScores | null => {
  try {
    const webVitalsMetrics = retrieveWebVitalsMetrics();
    if (webVitalsMetrics.length === 0) return null;

    const findMetrics = ({ metricName, source }: IFindMetrics): number[] => {
      let filteredMetrics = webVitalsMetrics.filter(
        (metric) => metric.name === metricName,
      );

      if (source) {
        filteredMetrics = filteredMetrics.filter(
          (metric) => metric.source === source,
        );
      }

      return filteredMetrics.map((metric) => metric.value);
    };

    const getAverage = (array: number[]): number | null => {
      if (array.length === 0) return null;

      return (
        array.reduce((total, currentValue) => total + currentValue, 0) /
        array.length
      );
    };

    const scores: IScores = metrics.reduce((accumulator, metricName) => {
      const allAverage = getAverage(findMetrics({ metricName }));
      const clpAverage = getAverage(
        findMetrics({
          metricName,
          source: 'CLP',
        }),
      );
      const pdpAverage = getAverage(
        findMetrics({
          metricName,
          source: 'PDP',
        }),
      );

      return {
        ...accumulator,
        ...(!allAverage && !clpAverage && !pdpAverage
          ? null // ignore if all are null
          : {
              [metricName]: {
                ...(allAverage && { All: allAverage }),
                ...(clpAverage && { CLP: clpAverage }),
                ...(pdpAverage && { PDP: pdpAverage }),
              },
            }),
      };
    }, {} as IScores);

    const webVitals = {
      scores,
      ...(window?.navigator?.userAgent && {
        deviceType: detectDevice(window.navigator.userAgent),
      }),
    };

    return webVitals;
  } catch (_) {
    /* istanbul ignore next */
    return null;
  }
};

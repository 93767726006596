import styled from 'styled-components';

import { Slider, SliderWithInput } from 'app/shared/ui';

export const SliderSt = styled(Slider)`
  width: 100%;
  & > div {
    margin: 0;
  }
`;

export const SliderWithInputSt = styled(SliderWithInput)`
  & > div {
    max-width: 100%;
  }
`;

import PropTypes from 'prop-types';
import Markdown from 'markdown-to-jsx';

import { Link } from 'app/shared/ui';
import { trackAction } from 'app/shared/utils/tracking';
import { useStyledTheme } from 'app/shared/hooks/useStyledTheme';

import { MarkdownContainerSt } from './MarkdownContainer.css';

const trackClick = () => {
  trackAction('contentful_markdown_container_link_click', {
    category: 'contentful_markdown_container',
    label: 'markdown_container_link',
    description:
      'user clicks on a link in a contentful markdown container component',
  });
};

export const MarkdownContainer = ({
  content,
  normalContentFontWeight,
  linkTarget = '_self',
  ...others
}) => {
  const { colors } = useStyledTheme();

  const options = {
    overrides: {
      a: {
        // eslint-disable-next-line react/display-name
        component: ({ href, ...rest }) => {
          const useRouter = href.startsWith('/');
          return (
            <Link
              to={href}
              useRouter={useRouter}
              {...rest}
              target={linkTarget}
            />
          );
        },
        props: {
          onClick: trackClick,
          color: colors.brandPrimaryDark,
        },
      },
    },
  };

  return (
    <MarkdownContainerSt
      className="markdown-container"
      normalContentFontWeight={normalContentFontWeight}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...others}
    >
      <Markdown options={options}>{content}</Markdown>
    </MarkdownContainerSt>
  );
};

MarkdownContainer.propTypes = {
  content: PropTypes.string,
  normalContentFontWeight: PropTypes.bool,
  linkTarget: PropTypes.string,
};

MarkdownContainer.defaultProps = {
  content: '',
  normalContentFontWeight: false,
  linkTarget: '_self',
};

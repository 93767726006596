/* istanbul ignore file */
// TODO: Test this file
import styled, { css } from 'styled-components';
import { Text } from 'app/shared/ui';
import { Flex } from '@rebass/grid';

import { media } from 'app/shared/styles_js/variables';

export const CartileFinancingDisclaimerContainerSt = styled(Flex)`
  position: relative;
  min-height: 18px;
  padding-left: 12px;
  word-break: break-all;
  ${media.md(css`
    padding-left: 0;
  `)};
  ${({ showAll }) =>
    !showAll &&
    css`
      min-height: 10px;
      height: 10px;
      padding-right: 76px;
      ${media.md(css`
        min-height: 10px;
        height: 10px;
      `)};
      overflow: hidden;
    `};
`;

export const CartileFinancingDisclaimerTextSt = styled(Text).attrs(
  ({ theme }) => ({
    fontSize: '8px',
    lineHeight: '10px',
    color: theme.colors.grayDark,
  }),
)``;

export const CartileFinancingDisclaimerShowLessSt = styled(Text).attrs(
  ({ theme }) => ({
    fontSize: '8px',
    fontWeight: 'bold',
    lineHeight: '10px',
    color: theme.colors.grayDarker,
  }),
)`
  padding: 0 3px 0 3px;
  cursor: pointer;
  text-decoration: underline;
  white-space: nowrap;
  transition: color 200ms ease-in-out;

  &:hover {
    color: ${({ theme }) => theme.colors.grayLight};
  }
`;

export const CartileFinancingDisclaimerShowMoreSt = styled(Text).attrs(
  ({ theme }) => ({
    fontSize: '8px',
    lineHeight: '10px',
    color: theme.colors.grayDarker,
  }),
)`
  display: ${({ isLoading }) => (isLoading ? 'none' : 'flex')};
  position: absolute;
  right: 0;
  bottom: 1px;
  padding: 0 10px 0 3px;
  white-space: nowrap;
  background: inherit;
`;

export const CartileFinancingDisclaimerActionTextSt = styled(Text).attrs(
  ({ theme }) => ({
    as: 'div',
    fontSize: '8px',
    lineHeight: '10px',
    fontWeight: 'bold',
    color: theme.colors.grayDarker,
  }),
)`
  cursor: pointer;
  text-decoration: underline;
  transition: color 200ms ease-in-out;

  &:hover {
    color: ${({ theme }) => theme.colors.grayLight};
  }
`;

import { useEffect, useState, useMemo, useRef, useCallback } from 'react';
import * as PropTypes from 'prop-types';
import { Box } from '@rebass/grid';

import {
  ShowMoreSt,
  ShowMoreButtonSt,
  ActionTextSt,
  ShowLessButtonSt,
} from './ShowMore.css';

export const ShowMore = ({
  children,
  lines,
  lineHeight,
  showAll,
  legalText,
  onWhite,
}) => {
  const containerRef = useRef(null);
  const [showMore, setShowMore] = useState(false);
  const [hasShowMoreFeature, setHasShowMoreFeature] = useState(false);

  useEffect(() => {
    /* istanbul ignore next */
    if (window && window.getComputedStyle) {
      const parentHeight =
        containerRef?.current?.parentNode?.getBoundingClientRect().height || 1;
      const textLines = Math.floor(parentHeight / lineHeight);

      if (textLines > lines && !hasShowMoreFeature) {
        setShowMore(!showAll);
        setHasShowMoreFeature(true);
      }
    }
  });

  const handleClick = useCallback(() => {
    setShowMore(!showMore);
  }, [showMore]);

  const textMoreLess = useMemo(() => {
    if (!hasShowMoreFeature) return null;
    return !showMore ? (
      <ShowLessButtonSt
        onClick={handleClick}
        $onWhite={onWhite}
        legalText={legalText}
      >
        weniger anzeigen
      </ShowLessButtonSt>
    ) : (
      <ShowMoreButtonSt $onWhite={onWhite} as="span">
        <span>...&nbsp;</span>
        <ActionTextSt onClick={handleClick} legalText={legalText}>
          mehr anzeigen
        </ActionTextSt>
      </ShowMoreButtonSt>
    );
  }, [hasShowMoreFeature, showMore]);

  return (
    <ShowMoreSt showAll={!showMore} height={lineHeight * lines}>
      <Box ref={containerRef}>{children({ textMoreLess })}</Box>
    </ShowMoreSt>
  );
};

ShowMore.propTypes = {
  children: PropTypes.any.isRequired,
  showAll: PropTypes.bool,
  legalText: PropTypes.bool,
  onWhite: PropTypes.bool,
};

ShowMore.defaultProps = {
  showAll: false,
  legalText: false,
  onWhite: false,
};

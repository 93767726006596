export const getUrlParamsFromObj = (obj = {}) =>
  Object.keys(obj)
    .map((key) => [key, obj[key]].map(encodeURIComponent).join('='))
    .join('&');

export const getObjFromUrlParams = (urlParams = '') =>
  [...new URLSearchParams(urlParams).entries()].reduce(
    (acc, [key, val]) => ({
      ...acc,
      // eslint-disable-next-line no-nested-ternary
      [key]: /* istanbul ignore next */ Object.prototype.hasOwnProperty.call(
        acc,
        key,
      )
        ? Array.isArray(acc[key])
          ? [...acc[key], val]
          : [acc[key], val]
        : val,
    }),
    {},
  );

export const isFuelTypeElectric = (fuelType) => {
  return Boolean(fuelType.find(({ value }) => value === 'electric'));
};

export const isVWElectric = (model) => {
  return Boolean(
    model.find(({ value }) => ['vw_id.3', 'vw_id.4'].includes(value)),
  );
};

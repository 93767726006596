import { footerContent } from 'app/marketplace/shared/NewMarketplaceTopNavBar/NewMarketplaceTopNavBar.constants';

import { RatgeberMenuItems } from '../../MenuItemsList';

export const Ratgeber = (isMobileDevice = false) => {
  return {
    label: 'Ratgeber',
    itemId: 'information',
    itemCategories: RatgeberMenuItems,
    footercontent: footerContent(isMobileDevice),
  };
};

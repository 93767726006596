import { TBreakpoint } from '../components/variables/index.types';
import { breakpoints } from '../components/variables/breakpoints';
import { grid } from '../components/variables/spacings';

export const generateContainerWidth = (
  fluidBreakpoints: TBreakpoint[] = [],
): string => {
  let cssString = '';
  Object.keys(breakpoints).forEach((bkp) => {
    let maxWidth;
    const fluidMaxWidth = '100%';

    if (bkp === 'sm') {
      maxWidth = `calc(100% - ${grid.margin.sm * 2}px)`;
      /* the overflow-x is here we might need to convert
      /* to a bootstrap-style container with horizontal padding */
      if (fluidBreakpoints.includes(bkp)) {
        cssString += `
          max-width: ${fluidMaxWidth};
          overflow-x: hidden;
        `;
      } else {
        cssString += `max-width: ${maxWidth};`;
      }
    } else {
      maxWidth = `${breakpoints[bkp] - grid.margin[bkp] * 2}px`;
      const mq = `@media all and (min-width: ${breakpoints[bkp] as number}px)`;
      if (fluidBreakpoints.includes(bkp as TBreakpoint)) {
        cssString += `${mq} {
          max-width: ${fluidMaxWidth};
          overflow-x: hidden;
        }`;
      } else {
        cssString += `${mq} {
          max-width: ${maxWidth};
          overflow-x: visible;
        }`;
      }
    }
  });

  return cssString;
};

import styled from 'styled-components';
import { Link } from 'app/shared/ui';
import { Flex } from '@rebass/grid';

export const TitleSt = styled(Flex)`
  align-items: center;
`;

export const LogoSt = styled(Link)`
  .logo-text {
    fill: ${({ theme: { colors } }) => colors.brandSecondaryLight};
  }

  .logo-line {
    fill: ${({ theme: { colors } }) => colors.white};
  }
`;

export const TitleTextSt = styled(Flex)`
  font-size: 28px;
  line-height: 1.71;
  font-weight: normal;
  letter-spacing: 0.2px;
  color: #fff;
  text-transform: initial;
`;

import { IPixelCount } from './index.types';

export const iconSizes: IPixelCount = {
  xsmall: 16,
  // TODO: Not intended by design, so should be removed
  small: 20,
  medium: 24,
  large: 32,
  xl: 48,
};

/*
 * This is how design scale the font site
 * for different viewports.
 *
 *       XS  MD  LG  XL
 *
 *  H1   32  40  48  64
 *  H2   32      40  48
 *  H3   24      32  40
 *  H4   20  24      32
 *  H5   20          24
 *  H6   20
 *
 *  P    14  16
 *  P2   12  14
 *  P3   10  12
 *  P4   10
 */

export const fontSizes: {
  [key: string]: string;
} = {
  h1: '64px',
  h2: '48px',
  h3: '40px',
  h4: '32px',
  h5: '24px',
  h6: '20px',

  p: '16px',
  p2: '14px',
  p3: '12px',
  p4: '10px',
};

export const fontWeights: {
  [key: string]: number;
} = {
  regular: 200,
  medium: 300,
  bold: 500,
  xbold: 800,
};

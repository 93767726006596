import {
  PREMIUM_POST_LEAD,
  PREMIUM_FORM_SUBMIT_SUCCESS,
  PREMIUM_FORM_SUBMIT_FAILURE,
} from './actions';

export const initialState = {
  leadId: '',
  phoneNumber: '',
  email: '',
  firstName: '',
  lastName: '',
  hasError: false,
  hasRequestedPremium: false,
  oneClickLeadsConsent: null,
  form: {},
};

export function activeLeadManagement(state = initialState, action) {
  const { type, payload } = action;

  if (type === PREMIUM_POST_LEAD) {
    return { ...state, ...payload };
  }

  if (type === PREMIUM_FORM_SUBMIT_SUCCESS) {
    return { ...state, hasRequestedPremium: true, hasError: false };
  }

  if (type === PREMIUM_FORM_SUBMIT_FAILURE) {
    return { ...state, hasError: true };
  }

  return state;
}

import { cloneElement, useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { InfoNotification } from '../../components/Notification/InfoNotification';
import { ActionNotification } from '../../components/Notification/ActionNotification';

import { Durations } from './constants';
import {
  InfoNotificationSt,
  ActionNotificationSt,
  ActionNotificationGhostElementSt,
} from './NotificationCenter.css';

const isInfoNotification = (element) => element.type === InfoNotification;
const isActionNotification = (element) => element.type === ActionNotification;
const hideDelay = Durations.SLIDE_IN + Durations.INFO_NOTIFICATION_DISPLAY;
const clearDelay = Durations.SLIDE_OUT + 100; // just to be sure

/*
  to support animations, rendering is multi-stepped:

  [ACTION] triggers [PHASE]
  -----------------------------------------
  [SHOW]  -> mount component (hidden)
            -> activate slide in animation
  [HIDE]    <- activate slide out animation
  [CLEAR] <- unmount
*/

export const NotificationCenter = (props) => {
  const { id, element, isVisible, hideNotification, clearNotification } = props;
  const hasElement = !!element;
  const [isSlidingIn, setIsSlidingIn] = useState(false);

  useEffect(() => {
    if (!hasElement) return;

    setIsSlidingIn(isVisible); // needed to start animation after mount

    if (isVisible && isInfoNotification(element)) {
      setTimeout(hideNotification, hideDelay, id);
    }

    if (!isVisible) {
      setTimeout(clearNotification, clearDelay, id);
    }
  }, [element, isVisible]);

  if (!hasElement) return null;

  if (isInfoNotification(element)) {
    // we wrap the `onClickClose` to also hide the notification
    const extendedElememt = cloneElement(element, {
      onClickClose() {
        element.props.onClickClose();
        hideNotification(id);
      },
    });
    return (
      <InfoNotificationSt isSlidingIn={isSlidingIn}>
        {extendedElememt}
      </InfoNotificationSt>
    );
  }

  if (isActionNotification(element)) {
    // we wrap the `onClickClose` and `onClickCtaButton` to also hide the notification
    const extendedElememt = cloneElement(element, {
      onClickCtaButton() {
        element.props.onClickCtaButton?.();
        hideNotification(id);
      },
      onClickClose() {
        element.props.onClickClose();
        hideNotification(id);
      },
    });
    // the ghost element is needed to shift down the content while
    // also allowing content to scroll away beneath the notification
    return (
      <>
        <ActionNotificationSt isSlidingIn={isSlidingIn}>
          {extendedElememt}
        </ActionNotificationSt>
        <ActionNotificationGhostElementSt isSlidingIn={isSlidingIn} />
      </>
    );
  }

  return element;
};

NotificationCenter.propTypes = {
  id: PropTypes.number,
  element: PropTypes.node,
  isVisible: PropTypes.bool,
  hideNotification: PropTypes.func.isRequired,
  clearNotification: PropTypes.func.isRequired,
};

NotificationCenter.defaultProps = {
  id: null,
  element: null,
  isVisible: false,
};

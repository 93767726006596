import { TRACKING_LIST } from 'app/shared/modules/tracking/list';
import {
  getAdditionalVehicleTrackingParams,
  trackAction,
} from 'app/shared/utils/tracking';
import { createGUID } from 'app/shared/utils/utils';

export const generateAdditionalTrackingParams = (
  vehicle,
  paramsOverride = {},
) => {
  const trackingVehicle = getAdditionalVehicleTrackingParams(vehicle);
  const eventId = createGUID();
  const additionalTracking = {
    content_ids: [`${vehicle.id}`],
    content_type: 'vehicle',
    eventId,
    order_id: `${eventId}`,
  };

  return {
    ...additionalTracking,
    ...trackingVehicle,
    ...paramsOverride,
  };
};

export const trackHoneypotSubmit = (vehicle, target = 'MailModal') => {
  trackAction('form_honeypot_submit_click', {
    category: target,
    description: 'user attemps to submit lead form with honeypot input filled',
    label: `${vehicle.id}`,
  });
};

export const trackCheckboxClick = (value, target = 'MailModal') => {
  trackAction(
    `newsletter_subscription_lead_form_checkbox_${value ? '' : 'un'}check`,
    {
      category: target,
      label: 'marketing_consent_lead_form',
      description: `user ${
        value ? '' : 'un'
      }checks marketing opt-in checkbox in lead form`,
    },
  );
};

export const trackTradeInCheckboxClick = (value, target = 'MailModal') => {
  trackAction(`tradein_ckeckbox_${value ? '' : 'un'}check`, {
    category: target,
    label: 'tradein_lead_form',
    description: `user ${
      value ? '' : 'un'
    }checks tradein opt-in checkbox in lead form`,
    value,
  });
};

// Tracks newsletter subscription via marketing opt-in checkbox in lead form
export const trackNewsletterSubscription = (status, target = 'MailModal') => {
  trackAction(`newsletter_subscription_lead_form_${status}`, {
    category: target,
    label: 'marketing_consent_lead_form',
    description: `newsletter subscription via lead form ${status}`,
  });
};

// https://heycar.atlassian.net/browse/PROMO-1938
export const LEAD_CREATION_EVENTS = {
  CALL_START_BUTTON_CLICK: 'call-start_button_click',
  CLICKED_SUBMIT_CALLBACK: 'clicked_submit_callback',
  SENT_EMAIL_FORM_SUCCESS: 'sent_email_form_success',
  SENT_REQUEST_VIDEO_FORM_SUCCESS: 'sent_request-video_form_success',
};

export const mapLeadCreationEvent = ({ actionName, campaignId }) => {
  let suffix = '';
  if (campaignId?.includes('TROC')) {
    suffix = '_Promo-troc';
  }
  return `${actionName}${suffix}`;
};

/* eslint-disable react/no-multi-comp */
import { useState, useCallback, memo, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { Box } from '@rebass/grid';

import { Icon, Button } from 'app/shared/ui';
import { SideBarWrapper } from 'app/shared/modules/overlay/defaultOverlays/sidebar/SideBarWrapper/SideBarWrapper';
import { withOverlay } from 'app/shared/modules/overlay/OverlayRoot/withOverlay';
import { ProgressBar } from 'app/shared/components/ProgressBar/ProgressBar';
import { useStyledTheme } from 'app/shared/hooks/useStyledTheme';

export const DefaultComponent = () => <div />;

/**
 * Sidebar component with multiple step functionalities
 * @param {Object} props
 * @param {Array} props.components - Components to cycle through. Each one represents a "step"
 * @param {Boolean} props.hasIntroComp - Determines whether the first component in array is part of step count
 * @param {Boolean} props.hasFinishComp - Determines whether the last component in array is part of step count
 * @param {Boolean} props.sidebarTitle - Sidebar title. Can later be changed by children components through "setTitle"
 * @param {Function} props.openOverlay - Opens overlay with passed component
 * @param {Function} props.closeOverlay - Closes overlay
 */

export const MultiStepSidebarCmp = memo(
  ({
    children,
    hasIntroComp,
    hasFinishComp,
    sidebarTitle,
    openOverlay,
    closeOverlay,
    ...props
  }) => {
    const { colors } = useStyledTheme();

    const sidebarContentWrapperRef = useRef(null);
    /**
     * Stores and sets sidebar title node
     */
    const [title, setTitle] = useState(sidebarTitle);

    /**
     * Stores and sets current component index in component array
     */
    const [currentStepIdx, setCurrentStepIdx] = useState(0);

    /**
     * State field that can be shared across components
     */
    const [sharedProps, setSharedProps] = useState({});

    /**
     * Scrolls back to the top of the page every time the step changes
     */
    useEffect(() => {
      if (sidebarContentWrapperRef?.current) {
        sidebarContentWrapperRef.current.scrollIntoView();
      }
    }, [currentStepIdx]);

    /**
     * Receives object and merges it with the sharedProps object
     */
    const addSharedProps = useCallback(
      (newProps = {}) => {
        setSharedProps({ ...sharedProps, ...newProps });
      },
      [sharedProps],
    );

    /**
     * Sets previous component in array as current
     */
    const goToPrev = useCallback(() => {
      if (currentStepIdx < 1) return;
      setCurrentStepIdx(currentStepIdx - 1);
    }, [currentStepIdx]);

    /**
     * Sets next component in array as current
     */
    const goToNext = useCallback(
      (newProps = {}) => {
        if (currentStepIdx === children.length - 1) return;
        addSharedProps(newProps);
        setCurrentStepIdx(currentStepIdx + 1);
      },
      [currentStepIdx],
    );

    /**
     * Component to render following current step state
     */
    const CurrentComponent = children[currentStepIdx];

    /* Step total amount minus first and/or last depending on hasIntroComp and hasFinishComp */
    const stepsWithCountAndProgressBar =
      children.length - (hasIntroComp ? 1 : 0) - (hasFinishComp ? 1 : 0);

    /* Offset step index for progress bar and title step count */
    const displayCurrentStep = currentStepIdx + (hasIntroComp ? 0 : 1);

    /**
     * Determines whether progress bar and title step count should be rendered
     * based on hasIntroComp and hasFinishComp
     */
    const shouldDisplayProgress =
      stepsWithCountAndProgressBar > 1 &&
      displayCurrentStep > 0 &&
      displayCurrentStep <= stepsWithCountAndProgressBar;

    /**
     * Used in the next step to hide the back button if there is no intro and the current step is the first
     */
    const hasNoIntroAndFirstStep = !hasIntroComp && currentStepIdx === 0;

    /**
     * Determines whether back button is rendered
     */
    const shouldDisplayBackButton =
      !hasNoIntroAndFirstStep &&
      displayCurrentStep > 0 &&
      displayCurrentStep <= stepsWithCountAndProgressBar &&
      stepsWithCountAndProgressBar > 1;

    /* Title component with Back Button and step count */
    const titleComp = (
      <>
        {shouldDisplayBackButton && (
          <Button
            unstyled
            onClick={() => goToPrev()}
            fontSize={16}
            m={0}
            mr={10}
            css={{ display: 'inherit' }}
          >
            <Icon icon="arrow-left-short" color={colors.tarmacGrey} />
          </Button>
        )}
        {shouldDisplayProgress && (
          <span>
            {`${displayCurrentStep}/${stepsWithCountAndProgressBar}`}&nbsp;
          </span>
        )}
        {title}
      </>
    );

    return (
      <SideBarWrapper
        openOverlay={openOverlay}
        closeOverlay={closeOverlay}
        title={titleComp}
      >
        <Box ref={sidebarContentWrapperRef}>
          {shouldDisplayProgress && (
            <ProgressBar
              totalSteps={stepsWithCountAndProgressBar}
              currentStep={displayCurrentStep}
            />
          )}
          <CurrentComponent
            goToStep={setCurrentStepIdx}
            goToNext={goToNext}
            goToPrev={goToPrev}
            setSidebarTitle={setTitle}
            openOverlay={openOverlay}
            closeOverlay={closeOverlay}
            addSharedProps={addSharedProps}
            {...sharedProps}
            {...props}
          />
        </Box>
      </SideBarWrapper>
    );
  },
);

MultiStepSidebarCmp.displayName = 'MultiStepSidebar';

export const MultiStepSidebar = withOverlay(MultiStepSidebarCmp);

MultiStepSidebarCmp.propTypes = {
  children: PropTypes.array,
  hasIntroComp: PropTypes.bool,
  hasFinishComp: PropTypes.bool,
  sidebarTitle: PropTypes.node,
  openOverlay: PropTypes.func.isRequired,
  closeOverlay: PropTypes.func.isRequired,
};

MultiStepSidebarCmp.defaultProps = {
  children: [DefaultComponent],
  hasIntroComp: false,
  hasFinishComp: false,
  sidebarTitle: '',
};

import PropTypes from 'prop-types';

import { getSelectedFinancingOptions } from 'app/shared/modules/FDL/utils/getSelectedFinancingOptions';
import {
  FDLContactDealerMessages,
  userFinancingSettings,
} from 'app/shared/modules/FDL/utils/messages';
import { isMobileViewport } from 'app/shared/utils/utils';
import { isDealershipOpen } from 'app/marketplace/contactDealer/Call/utils';
import { CAPTIVES } from 'app/shared/modules/FDL/constants';
import {
  ARE_MANDATORY_ERROR_MSG,
  SERVER_ERROR_MSG,
} from 'app/shared/utils/errorMessages';

import { SAVE_SEARCH_EXCEPTIONS } from '../saveSearch/constants';

import contactDealerMessages from './messages';

export const LEAD_SOURCE = 'web';
export const PHONECALL_LEAD_ENDPOINT = '/leads/phone-call';
export const CALLBACK_LEAD_ENDPOINT = '/leads/phone-callback';

export const LEAD_SERVICE_EXCEPTIONS = {
  INVALID_EMAIL: 'supplied_email_invalid',
  CAR_SOLD_OUT: 'already_sold_out_listing_not_published',
  INVALID_PHONE: 'phone_number_not_valid',
  INACTIVE_RECIPIENT: 'inactive_recipient_exception',
  INVALID_DATE: 'java.time.format.DateTimeParseException',
  LOW_SCORE_RECAPTCHA: 'ReCaptchaFailedException',
};

/**
 * @typedef {{id: string, type: string}} IMessage
 * @param {IMessage} message
 * @param {{messages: IMessage[]}} state
 * @returns
 */
export const updateMessages = (message, state) => {
  const { messages } = state;
  const { id, type } = message;
  const foundIndex = messages.findIndex(
    (item) => item.id === id && item.type === type,
  );

  if (foundIndex === -1) {
    messages.push(message);
  } else {
    messages[foundIndex] = message;
  }

  return messages;
};

export const MessageShape = PropTypes.shape({
  id: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  message: PropTypes.string,
});

export const ContactDealerFormShape = PropTypes.shape({
  firstName: PropTypes.string.isRequired,
  lastName: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
  phoneNumber: PropTypes.string.isRequired,
});

export const ProfileShape = PropTypes.shape({
  firstName: PropTypes.string.isRequired,
  lastName: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
  phoneNumber: PropTypes.string.isRequired,
});

/**
 * @typedef {import('app/types/entities/Vehicle.types').IVehicle} IVehicle
 * @typedef {import('app/types/entities/Vehicle.types').IVehicleMarker} IVehicleMarker
 * @typedef {import('app/types/style.types').TBreakpoint} TBreakpoint
 *
 * @param {TBreakpoint} breakpoint
 * @param {IVehicle} vehicle
 * @returns {boolean}
 */
export const showCallbackFlow = (breakpoint, vehicle) => {
  const isMobile = isMobileViewport(breakpoint);
  const isOpen = isDealershipOpen(vehicle.dealer.openingTimes);

  return !isMobile || !isOpen;
};

/**
 * @NOTE
 * this should only be imported on the comparisons page
 * - we will not need to import this anymore once we refactor: see REFACTOR NOTICE above
 *
 * @param {IVehicle} vehicle
 * @param {string} campaignId
 * @returns {IVehicleMarker|undefined}
 */
export const findCampaignMarker = (vehicle, campaignId) =>
  vehicle?.markers?.find((marker) => marker?.id?.includes(campaignId));

/**
 * Get the correct message for video leads
 * @param {{bid: string}} existingCalculation: financing calculator details
 * @param {string} captiveId: vehicle's captive
 */
export const getVideoMessage = (existingCalculation, captiveId = '') => {
  //  Calculation details should only be added for all captive except mbb
  if (existingCalculation && existingCalculation.bid !== 'mbb') {
    const financingData = getSelectedFinancingOptions(existingCalculation);

    return contactDealerMessages.videoMessageFinancingDetails(
      FDLContactDealerMessages.financingDetails(
        Object.keys(financingData)
          .map((key) => userFinancingSettings[key](financingData))
          .filter((val) => val)
          .join('\r\n'),
      ),
    );
  }

  // If there's no calculation, but it's a VW captive, show message with financing
  if (CAPTIVES.VOLKSWAGEN.includes(captiveId)) {
    return contactDealerMessages.videoMessageFinancing;
  }
  return contactDealerMessages.defaultVideoMessage;
};

/**
 *
 * @param {Error} error
 */
export const getErrorMessage = (error) => {
  if (!error) return null;
  if (
    error?.message?.includes(LEAD_SERVICE_EXCEPTIONS.INVALID_EMAIL) ||
    error?.message?.includes(LEAD_SERVICE_EXCEPTIONS.INACTIVE_RECIPIENT) ||
    error?.message?.includes(SAVE_SEARCH_EXCEPTIONS.INVALID_EMAIL)
  ) {
    return {
      target: 'email',
      message: 'Es scheint, dass deine E-Mail-Adresse falsch ist.',
    };
  }

  if (error?.message?.includes(LEAD_SERVICE_EXCEPTIONS.INVALID_PHONE)) {
    return {
      target: 'phoneNumber',
      message: 'Es scheint, dass deine Telefonnummer falsch ist.',
    };
  }

  if (error?.message?.includes(LEAD_SERVICE_EXCEPTIONS.INVALID_DATE)) {
    return {
      target: 'appointmentDate',
      message: ARE_MANDATORY_ERROR_MSG,
    };
  }

  if (typeof error === 'string') {
    if (error?.includes(SAVE_SEARCH_EXCEPTIONS.LIMIT_REACHED)) {
      return {
        message:
          'Du hast die maximale Anzahl gespeicherter Suchen erreicht. Bitte lösche einige, um fortzufahren.',
      };
    }
  }

  return {
    message: SERVER_ERROR_MSG,
  };
};

const formatDate = (date) => {
  //  Adds 0 in front of numbers < 10
  const formatToDecimal = (value) => value.toString().padStart(2, '0');
  const month = formatToDecimal(date.getUTCMonth() + 1);
  const day = formatToDecimal(date.getUTCDate());

  return `${date.getUTCFullYear()}-${month}-${day}`;
};

/**
 * Get today's date formatted as YYYY-MM-DD
 */
export const getToday = () => {
  const date = new Date();
  return formatDate(date);
};

/**
 * Get a date X days from now formatted as YYYY-MM-DD
 * @param {int} amountOfDays: the amount of days from now
 */
export const getDateInXDays = (amountOfDays) => {
  const dateInFuture = new Date();
  dateInFuture.setDate(dateInFuture.getDate() + amountOfDays);

  return formatDate(dateInFuture);
};

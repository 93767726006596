import styled from 'styled-components';
import Rheostat from 'rheostat';

import { is } from '../variables/utils';

export const SliderSt = styled.div`
  position: relative;
`;

export const ValueSt = styled.span`
  ${is('isDelimiter')`
    margin: 0 8px;
  `};
`;

export const ValuesSt = styled.p`
  padding: 0 0 ${(props) => (props.histogramActive ? 30 : 15)}px;
  margin: 0;
`;

export const SliderBodySt = styled.div`
  margin: 0 16px;
`;

export const HistogramSt = styled.div`
  position: absolute;
  bottom: 36px;
  overflow: hidden;
  width: 100%;
  height: 44px;
`;

export const HistogramInnerSt = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  align-items: baseline;
  justify-content: space-between;
  margin: 0 -${(props) => (100 / props.itemCount - 2) / 2}%;
`;

export const HistogramItemSt = styled.div`
  width: ${(props) => 100 / props.itemCount - 2}%;
  height: ${(props) => props.height * 100}%;
  background-color: #f5f7f8;
`;

export const RheostatSt = styled(Rheostat)`
  height: 64px;
  padding: 28px 0;

  & > .rheostat-background {
    border: 4px solid ${({ theme }) => theme.colors.grayLight};
  }

  & > .rheostat-progress {
    position: relative;
    top: -8px;
    height: 8px;
    background-color: ${({ disabled, theme }) =>
      disabled ? theme.colors.grayLight : theme.colors.brandPrimary};
    cursor: pointer;
  }

  & > .rheostat-handle {
    z-index: 2;
    top: 16px;
    width: 32px;
    height: 32px;
    border: 2px solid
      ${({ disabled, theme }) =>
        disabled ? theme.colors.grayLight : theme.colors.brandPrimary};
    margin: 0 -16px;
    cursor: pointer;
    background-color: #fff;
    border-radius: 50%;
    transform: scale(1, 1);
    transition: transform 0.1s ease-in;

    &:focus,
    &:active {
      transform: ${(props) => (props.disabled ? null : 'scale(1.25, 1.25)')};
    }
  }
`;

/* istanbul ignore file */
import {
  all,
  fork,
  put,
  take,
  takeLatest,
  delay,
  select,
} from 'redux-saga/effects';

import { getFeatureFlagByFlagID } from 'app/selectors';
import { setCookie, getCookie, deleteCookie } from 'app/shared/utils/utils';
import { ActionTypes } from 'app/marketplace/accounts/constants';
import { trackAction } from 'app/shared/utils/tracking';
import {
  GET_VEHICLE_SUCCESS,
  RESET_VEHICLE,
} from 'app/marketplace/vehicle/constants';

import * as actions from './actions';
import * as types from './types';

export const DAT_SEARCH_TOKEN = 'tradein_dat_search_token';
export const VALUATED_VEHICLE_DETAIL = 'valuatedVehicleDetail';
export const VALUATED_VEHICLE_ID = 'valuatedVehicleId';

const getCookiesFromRedux = (reduxState) =>
  reduxState?.cookieDashboard?.preferences.functional;

const sixtyDaysInSeconds = 86400 * 60;

// Saga run at the beginning of the app
export function* watchInitial() {
  const reduxState = yield select();
  const areCookiesAccepted = getCookiesFromRedux(reduxState);

  try {
    const cookieValuatedVehicleId = getCookie(
      VALUATED_VEHICLE_ID,
      areCookiesAccepted,
    );

    if (cookieValuatedVehicleId) {
      // refresh cookie's expiration date if is a returning user
      setCookie(
        VALUATED_VEHICLE_ID,
        cookieValuatedVehicleId,
        areCookiesAccepted,
        sixtyDaysInSeconds, // valuated vehicle id cookie should expire only after 60 days
        '/',
      );
    }

    const cookieValuatedVehicleDetail = getCookie(
      VALUATED_VEHICLE_DETAIL,
      areCookiesAccepted,
    );

    if (cookieValuatedVehicleDetail) {
      yield put(
        actions.setValuateVehicleDetail(
          JSON.parse(cookieValuatedVehicleDetail),
        ),
      );

      // refresh cookie's expiration date if is a returning user
      setCookie(
        VALUATED_VEHICLE_DETAIL,
        cookieValuatedVehicleDetail,
        areCookiesAccepted,
        sixtyDaysInSeconds, // valuated vehicle details cookie should expire only after 60 days
        '/',
      );
    }

    // it listens to LOGIN_COMPLETED & REGISTER_COMPLETED actions
    while (true) {
      const { type } = yield take([
        ActionTypes.LOGIN_COMPLETED,
        ActionTypes.REGISTER_COMPLETED,
      ]);
      const {
        tradeIn: { steps, step, screens, valuatedVehicle: { id } = {} },
      } = yield select();
      const { screen } = steps[step];
      const screenName = screens[screen];

      // If there is any valuatedVehicle, bind it to the current user
      if (id) {
        yield put(actions.claimValuation(id));
      }
      if (type === ActionTypes.LOGIN_COMPLETED) {
        trackAction('valuation_login', {
          label: 'last_screen',
          value: screenName,
        });
      } else if (type === ActionTypes.REGISTER_COMPLETED) {
        trackAction('valuation_register', {
          label: 'last_screen',
          value: screenName,
        });

        yield take(ActionTypes.LOGIN_COMPLETED);
      }
    }
  } catch (error) {
    // error
  }
}

export function* watchTradeInRun(action) {
  const reduxState = yield select();
  const areCookiesAccepted = getCookiesFromRedux(reduxState);

  const {
    tradeIn: { steps, step, data, PDPvehicle },
  } = reduxState;
  const test103 = getFeatureFlagByFlagID(reduxState, 103);

  const { screen } = steps[step];
  const { request } = action.payload;
  const lastScreenIndex = 10;
  const vehicleListingId = PDPvehicle?.id;
  const valuationVariant = test103?.flagToken;

  try {
    // Set status to "Pending"
    yield put(actions.setStatus(false));
    if (screen === 0 || screen === 1) {
      let response = {};
      let replyCount = 0;
      let storedToken =
        getCookie(DAT_SEARCH_TOKEN, areCookiesAccepted) || undefined;
      // Try 5 times to get result from API :)
      while (replyCount < 5) {
        replyCount += 1;
        response = yield yield put(
          actions.searchVehicle({
            ...request,
            token: storedToken,
          }),
        );
        if (response.type !== 'ERROR') {
          break;
        }
        yield delay(500);
      }

      // Get token from response to use in next call
      const { token } = response;
      // if the token is not equal with the previous one, replace it with the new one
      if (token && storedToken !== token) {
        setCookie(DAT_SEARCH_TOKEN, token, areCookiesAccepted, '/');
        storedToken = token;
      }

      // If it is first Screen
      if (screen === 0) {
        if (response.type === 'PROGRESS') {
          yield put(actions.nextScreen(response));
        }
      }
      // If it is second Screen
      else if (screen === 1) {
        if (response.type === 'SUCCESS') {
          const vehicle = response.vehicle || response.vehicles[0];
          yield put(actions.setVehicle(vehicle));
          // If there is only one vehicle, jump to the selection of vehicle screen.
          if (
            response.vehicle ||
            (response.vehicles && response.vehicles.length === 1)
          ) {
            yield put(actions.updateStep({ request: { vehicle } }));
            yield put(actions.changeScreen(3, response));
          } else {
            yield put(actions.nextScreen(response));
          }
        } else if (response.type === 'PROGRESS') {
          yield put(actions.nextStep(response));
        }
      }
    } else if (screen >= 2 && screen <= lastScreenIndex - 1) {
      yield put(actions.nextScreen({}));
    } else if (screen === lastScreenIndex) {
      delete data.filters;

      let vehicleId = 0;
      let loopState = 'PENDING';
      let controlFoot = 0;

      // data(body) that will be passed to valuateVehicle endpoint
      const valuateVehicleData = {
        ...data,
        // adds PDP's vehicle id from the page where trade-in was triggered
        vehicleListingId,
        // adds valuation variant to decide dat or autouncle
        valuationVariant,
      };

      if (data.marketingConsent) {
        trackAction('tradein_newsletter_signup_submit_initialized', {
          category: 'newsletter_tradein',
          label: 'newsletter_signup',
          description: 'newsletter signup via trade-in initialized',
        });
      }

      while (loopState === 'PENDING') {
        controlFoot += 1;
        const { id, state } =
          vehicleId === 0
            ? yield yield put(actions.valuateVehicle(valuateVehicleData))
            : yield yield put(actions.getValuateVehicleById(vehicleId));
        loopState = state;
        vehicleId = id;
        if (data.marketingConsent) {
          trackAction('tradein_newsletter_signup_submit_successful', {
            category: 'newsletter_tradein',
            label: 'newsletter_signup',
            description: 'newsletter signup via trade-in successful',
          });
        }

        if (loopState === 'SUCCESSFUL' || loopState === 'DISPATCHED') {
          // set vehicleId to the cookies
          setCookie(
            VALUATED_VEHICLE_ID,
            vehicleId,
            areCookiesAccepted,
            sixtyDaysInSeconds, // valuated vehicle id cookie should expire only after 60 days
            '/',
          );

          // get and store vehicle detail from the API
          const promise = yield put(
            actions.getValuateVehicleDetailById(vehicleId),
          );
          const valuatedVehicleDetail = yield promise;
          setCookie(
            VALUATED_VEHICLE_DETAIL,
            JSON.stringify(valuatedVehicleDetail),
            areCookiesAccepted,
            sixtyDaysInSeconds, // valuated vehicle details cookie should expire only after 60 days
            '/',
          );

          // assigning valuation to the user
          yield put(actions.claimValuation(vehicleId));
          yield put(actions.redirectToSuccessPage);

          // remove DAT token from cookies
          deleteCookie(DAT_SEARCH_TOKEN, '/');
          break;
        } else if (loopState === 'FAILED') {
          yield put(actions.changeScreen(lastScreenIndex + 1));
          break;
        }
        if (controlFoot > 20) {
          break;
        }
        yield delay(1000);
      }
    }
    // Set status to "Ready"
    yield put(actions.setStatus(true));
  } catch (error) {
    if (data.marketingConsent) {
      trackAction('tradein_newsletter_signup_submit_failed', {
        category: 'newsletter_tradein',
        label: 'newsletter_signup',
        description: 'newsletter signup via trade-in failed',
      });
    }
    trackAction('valuation_error', {
      label: 'message',
      value: error.message,
    });
    if (navigator) {
      trackAction('valuation_network', {
        label: 'status',
        value: navigator.onLine,
      });
    }
    // Set status to "Ready"
    yield put(actions.setStatus(true));
    yield put(actions.changeScreen(lastScreenIndex + 1));
  }
}

export function* watchPdpVehicle() {
  try {
    const {
      vehicle: { vehicle },
    } = yield select();

    const PDPvehicle = JSON.parse(JSON.stringify(vehicle));

    if (!PDPvehicle || PDPvehicle?.id === 0) {
      yield put(actions.addVehicleData(null));
    } else {
      yield put(actions.addVehicleData(PDPvehicle));
    }
  } catch (error) {
    // error
  }
}

export function* watchPdpVehicleReset() {
  try {
    if (!window.location.href.includes('/tradein')) {
      yield put(actions.addVehicleData(null));
    }
  } catch (error) {
    // error
  }
}

// case ActionTypes.LOGIN_COMPLETED:
// case ActionTypes.REGISTER_COMPLETED:
// case GET_VEHICLE_SUCCESS:
// case RESET_VEHICLE:
export default function* () {
  yield all([
    fork(watchInitial),
    takeLatest(types.TRADEIN_RUN, watchTradeInRun),
    takeLatest(GET_VEHICLE_SUCCESS, watchPdpVehicle),
    takeLatest(RESET_VEHICLE, watchPdpVehicleReset),
  ]);
}

import { memo } from 'react';
import PropTypes from 'prop-types';

import { Text1 } from 'app/shared/ui';
import {
  CartileMakeModelTrimTrimSt,
  CartileMakeModelTrimContainerSt,
  CartileNewListingPillSt,
  CartileMakeModelContainerSt,
} from 'app/shared/modules/Cartile/CartileUIComponents/CartileMakeModelTrim/CartileMakeModelTrim.css';
import {
  CartileLoadingStyle,
  CartileFlexboxEllipsisSt,
} from 'app/shared/modules/Cartile/CartileUIComponents/CartileUIComponents.css';

const CartileMakeModelTrimCmp = ({
  trim,
  make,
  model,
  isLoading,
  isNewVehicle,
}) => {
  return (
    <CartileMakeModelTrimContainerSt
      css={[isLoading && CartileLoadingStyle, CartileFlexboxEllipsisSt]}
    >
      <Text1 as="span" fontWeight="bold">
        {isNewVehicle && <CartileNewListingPillSt>Neu</CartileNewListingPillSt>}
        <CartileMakeModelContainerSt>
          {make} {model}
        </CartileMakeModelContainerSt>
      </Text1>

      <CartileMakeModelTrimTrimSt title={trim}>
        {trim}
      </CartileMakeModelTrimTrimSt>
    </CartileMakeModelTrimContainerSt>
  );
};

CartileMakeModelTrimCmp.propTypes = {
  isLoading: PropTypes.bool,
  trim: PropTypes.string.isRequired,
  make: PropTypes.string.isRequired,
  model: PropTypes.string.isRequired,
  isNewVehicle: PropTypes.bool,
};

CartileMakeModelTrimCmp.defaultProps = {
  isLoading: false,
  isNewVehicle: false,
};

export const CartileMakeModelTrim = memo(CartileMakeModelTrimCmp);

// Code used from https://michalzalecki.com/why-using-localStorage-directly-is-a-bad-idea/
/* Generates a wrapper to check storage availability on browser and save on memory instead if not */

export function storageFactory(getStorage) {
  let inMemoryStorage = {};

  /**
   * @returns {boolean}
   */
  function isSupported() {
    try {
      const testKey = '__some_random_key_you_are_not_going_to_use__';
      getStorage().setItem(testKey, testKey);
      getStorage().removeItem(testKey);
      return true;
    } catch (error) {
      return false;
    }
  }

  function clear() {
    if (isSupported()) {
      getStorage().clear();
    } else {
      inMemoryStorage = {};
    }
  }

  /**
   * Returns the item's value
   * @param name {string}
   * @returns {string|null}
   */
  function getItem(name) {
    if (isSupported()) {
      return getStorage().getItem(name);
    }
    // eslint-disable-next-line no-prototype-builtins
    if (inMemoryStorage.hasOwnProperty(name)) {
      return inMemoryStorage[name];
    }
    return null;
  }

  function key(index) {
    if (isSupported()) {
      return getStorage().key(index);
    }
    return Object.keys(inMemoryStorage)[index] || null;
  }

  function removeItem(name) {
    if (isSupported()) {
      getStorage().removeItem(name);
    } else {
      delete inMemoryStorage[name];
    }
  }

  /**
   * Sets an item
   * @param name {string}
   * @param value {*}
   */
  function setItem(name, value) {
    if (isSupported()) {
      getStorage().setItem(name, value);
    } else {
      inMemoryStorage[name] = String(value);
    }
  }

  /**
   * @returns {number}
   */
  function length() {
    if (isSupported()) {
      return getStorage().length;
    }
    return Object.keys(inMemoryStorage).length;
  }

  return {
    isSupported,
    getItem,
    setItem,
    removeItem,
    clear,
    key,
    get length() {
      return length();
    },
  };
}

/* istanbul ignore next : there is no need to test this one */
export const localStore = storageFactory(() => localStorage);
/* istanbul ignore next : there is no need to test this one */
export const sessionStore = storageFactory(() => sessionStorage);

import 'isomorphic-fetch';
import { queryParams } from 'app/shared/utils/queryParams';

import { checkForError } from '../../shared/utils/checkForError';
import * as Config from '../../config';

import {
  clientId,
  GET_USER_URL,
  keycloakHeaders,
  LOGIN_OUT_URL,
  LOGIN_URL,
  optionsDefault,
  SIGNUP_URL,
  RESEND_EMAIL_CONFIRMATION_URL,
  USER_PROFILE_URL,
} from './service.config';

export const fetchApi = async (
  url,
  options,
  useBackendApi = false,
  useCommonApi = false,
) => {
  const apiBaseUrl = useCommonApi ? Config.COMMON_API_URL : Config.DE_API_URL;
  const apiUrl = useBackendApi ? `${apiBaseUrl}${url}` : url;

  return fetch(apiUrl, options)
    .then(checkForError)
    .then((response) => response.text())
    .then((response) => {
      return response ? JSON.parse(response) : {};
    })
    .catch((error) => {
      return Promise.reject(error);
    });
};

const fetchApiWithToken = (
  url,
  options,
  token,
  useBackendApi = false,
  useCommonApi = false,
) => {
  const optionsCombined = {
    ...optionsDefault,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    ...options,
  };

  return fetchApi(url, optionsCombined, useBackendApi, useCommonApi);
};

const resendConfirmationEmail = ({ email }) => {
  const options = {
    ...optionsDefault,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      email,
    }),
  };

  return fetchApi(RESEND_EMAIL_CONFIRMATION_URL, options);
};

const signUp = ({ password, email, marketingConsent }) => {
  const options = {
    ...optionsDefault,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      password,
      email,
      marketingConsent,
    }),
  };

  return fetchApi(SIGNUP_URL, options);
};

/**
 * used to log in with an authorization code - this code can come from being redirected to heycar after logging in with
 * an openid provider (verimi, later facebook etc.)
 * @param code
 * @return {Promise<Promise<unknown>>}
 */
const loginWithAuthorizationCode = ({ code, redirect_uri }) => {
  const params = {
    grant_type: 'authorization_code',
    code,
    redirect_uri,
    client_id: clientId,
  };

  const options = {
    ...optionsDefault,
    headers: keycloakHeaders,
    body: queryParams(params),
  };

  return fetchApi(LOGIN_URL, options);
};

const login = ({ email, password }) => {
  const params = {
    grant_type: 'password',
    username: email,
    password,
    client_id: clientId,
  };

  const options = {
    ...optionsDefault,
    headers: keycloakHeaders,
    body: queryParams(params),
  };

  return fetchApi(LOGIN_URL, options);
};

const logOut = (token, refreshToken) => {
  const params = {
    refresh_token: refreshToken,
    client_id: clientId,
  };

  const options = {
    ...optionsDefault,
    headers: { ...keycloakHeaders, Authorization: `Bearer ${token}` },
    body: queryParams(params),
  };

  return fetchApi(LOGIN_OUT_URL, options);
};

const getNewToken = (refreshToken) => {
  const params = {
    grant_type: 'refresh_token',
    refresh_token: refreshToken,
    client_id: clientId,
  };

  const options = {
    ...optionsDefault,
    headers: keycloakHeaders,
    body: queryParams(params),
  };

  return fetchApi(LOGIN_URL, options);
};

const getUser = async (token) => {
  const options = {
    method: 'GET',
  };

  return fetchApiWithToken(GET_USER_URL, options, token.access_token);
};

const getUserProfile = async (accessToken) => {
  const options = {
    method: 'GET',
  };

  return fetchApiWithToken(USER_PROFILE_URL, options, accessToken);
};

const updateUserProfile = async (
  { firstName, lastName, email, phoneNumber },
  accessToken,
) => {
  const options = {
    body: JSON.stringify({ firstName, lastName, email, phoneNumber }),
  };

  return fetchApiWithToken(USER_PROFILE_URL, options, accessToken);
};

export const userService = {
  login,
  signUp,
  getUser,
  logOut,
  getNewToken,
  fetchApiWithToken,
  resendConfirmationEmail,
  getUserProfile,
  updateUserProfile,
  loginWithAuthorizationCode,
};

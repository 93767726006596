/**
 * INFO: if you add a constand action name here that should trigger an update on the CLP if dispatched, then
 * @see search/sagas.js
 * @type {string}
 */
export const SET_FILTERS = 'SET_FILTERS';
export const OVERRIDE_FILTERS = 'OVERRIDE_FILTERS';
export const ERROR = 'ERROR';
export const GET_MAKES_SUCCESS = 'GET_MAKES_SUCCESS';
export const GET_ALL_MAKES_SUCCESS = 'GET_ALL_MAKES_SUCCESS';
export const GET_ALL_USEDCARSEAL_SUCCESS = 'GET_ALL_USEDCARSEAL_SUCCESS';
export const GET_MODELS_SUCCESS = 'GET_MODELS_SUCCESS';
export const SET_COORDS_PENDING = 'SET_COORDS_PENDING';
export const SET_COORDS_SUCCESS = 'SET_COORDS_SUCCESS';

export const RESET_FILTERS = 'RESET_FILTERS';
export const RESET_SINGLE_FILTER = 'RESET_SINGLE_FILTER';
export const RESET_FILTER_CATEGORY = 'RESET_FILTER_CATEGORY';
export const RESET_FILTERS_EXCEPT_CATEGORY = 'RESET_FILTERS_EXCEPT_CATEGORY';
export const RESET_MAKEMODELVARIANTE_FILTER = 'RESET_MAKEMODELVARIANTE_FILTER';
export const UPDATE_MULTIMODEL_FILTER = 'UPDATE_MULTIMODEL_FILTER';
export const UPDATE_MULTITRIM_FILTER = 'UPDATE_MULTITRIM_FILTER';
export const UPDATE_FILTERS = 'UPDATE_FILTERS';

import { formatNumber } from 'app/shared/utils/formatNumber';

/* eslint-disable no-template-curly-in-string */
export const cleanUpModelsUrlParams = (url) => {
  const urlSearchParams = new URLSearchParams(url);
  const hasMake = urlSearchParams.has('make');
  const hasModel = urlSearchParams.has('model');
  const otherProperties = [...urlSearchParams.keys()].filter(
    (key) => !['make', 'model', 'trim'].includes(key),
  );
  const hasOtherProps = otherProperties.length > 0;
  const makeId = urlSearchParams.get('make');
  const modelId = urlSearchParams.get('model');

  if (!hasModel && !hasOtherProps && hasMake) {
    urlSearchParams.set('model', `${makeId}_all`);
  }
  if (hasModel && modelId !== `${makeId}_all`) {
    urlSearchParams.set('model', modelId.replace(`${makeId}_`, ''));
  }
  if (hasModel && hasOtherProps && modelId === `${makeId}_all`) {
    urlSearchParams.delete('model');
  }

  return urlSearchParams.toString();
};

export const seoFormatter = ({ make, model, count, seo }) =>
  ['title', 'description', 'h1', 'h2', 'h3', 'content', 'keywords'].reduce(
    (seoData, key) => {
      if (!seoData[key]) return seoData;
      return {
        ...seoData,
        [key]: seoData[key]
          .split('${makeName}')
          .join(make?.label || '')

          .split('${modelName}')
          .join(model?.label || '')

          .split('${resultCount}')
          .join(count ? formatNumber(count) : ''),
      };
    },
    seo,
  );

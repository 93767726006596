// eslint-disable react/prop-types
import React, { useState, useEffect, forwardRef } from 'react';
import Slider from 'react-slick';

import { Button } from '../Button/Button';
import { Icon } from '../Icon/Icon';

import { CarouselSt } from './Carousel.css';
import { ICarouselProps, INavArrowProps } from './Carousel.types';

const NavArrow = ({ icon, iconSize, ...rest }: INavArrowProps): JSX.Element => {
  const isDisabled = rest.className?.includes('slick-disabled');
  return (
    <Button variant="quarternary" disabled={isDisabled} {...rest}>
      <Icon role="button" icon={icon} iconSize={iconSize} />
    </Button>
  );
};
// eslint-disable-next-line react/no-multi-comp
const CarouselCmp = (
  {
    arrowProps,
    arrows,
    children,
    infinite = false,
    isCarouselReady = false,
    marginBetween = [0, 0],
    nextArrow,
    prevArrow,
    nextArrowIcon = 'arrow-right-long',
    prevArrowIcon = 'arrow-left-long',
    showArrows,
    speed = 700,
    tileGap = true,
    dotsClass,
    ...rest
  }: ICarouselProps,
  ref: React.Ref<Slider>,
): JSX.Element => {
  const [isMounted, setMounted] = useState(false);

  useEffect(() => {
    if (isCarouselReady) {
      setMounted(true);
    }
  }, []);

  return (
    <CarouselSt
      className={isMounted ? 'carousel-mounted' : ''}
      // react slick overwrites
      dotsClass={['slick-dots', dotsClass].filter(Boolean).join(' ')}
      // eslint-disable-next-line jsx-a11y/control-has-associated-label
      customPaging={() => <button type="button" />}
      arrows={showArrows || arrows}
      nextArrow={nextArrow || <NavArrow {...arrowProps} icon={nextArrowIcon} />}
      prevArrow={prevArrow || <NavArrow {...arrowProps} icon={prevArrowIcon} />}
      speed={speed}
      infinite={infinite}
      // custom props
      // isMounted is required to ensure carousel is ready else broken carousel will be visible
      isCarouselReady={!isCarouselReady || (isCarouselReady && isMounted)}
      marginBetween={marginBetween}
      tileGap={tileGap}
      // allow access to other react slick settings
      {...rest}
      ref={ref}
    >
      {children}
    </CarouselSt>
  );
};

CarouselCmp.displayName = 'Carousel';

export const Carousel = forwardRef(CarouselCmp);

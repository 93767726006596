import isString from 'lodash.isstring';

import { fdlDealerCheck } from '../modules/FDL/utils';

export const getPS = (kiloWatt) => {
  return Math.round(kiloWatt * 1.35962);
};

export const getNormalizedVehicle = (vehicle = {}, meta = {}) => {
  const defaultMeta = {
    searchId: '',
  };
  const clonedVehicle = JSON.parse(JSON.stringify(vehicle));
  const clonedMeta = JSON.parse(JSON.stringify(meta));
  const defaultVehicle = {
    _meta: Object.assign(defaultMeta, clonedMeta),
    id: 0,
    categories: [],
    available: 'AVAILABLE',
    dealer: {
      agentImageUrl: '',
      agentName: '',
      email: '',
      id: '',
      location: {
        streetName: '',
        city: '',
        street: '',
        zip: '',
        geoCoordinates: {
          lat: 0,
          lon: 0,
        },
        country: '',
      },
      name: '',
      openingTimes: [],
      phone: '',
      leadPhone: '',
      active: false,
      fdlConditions: '',
    },
    display: null,
    displayBodyColor: '',
    doors: 0,
    emissionPerKm: 0,
    emissionClass: '',
    emissionSticker: '',
    externalId: '',
    features: [],
    firstRegistrationDate: '',
    fuelConsumptionCombined: 0,
    fuelConsumptionInner: 0,
    fuelConsumptionOuter: 0,
    fuelType: '',
    gearBox: '',
    guarantee: 0,
    bodyColor: '',
    images: [],
    make: {
      displayName: '',
      id: '',
    },
    mileage: 0,
    model: {
      displayName: '',
      id: '',
      source: '',
      trim: '',
    },
    performance: 0,
    price: 0,
    previousOwners: 1,
    state: 'PUBLISHED',
    serviceBook: false,
    motDate: '',
    motRenewEligible: false,
    warranty: false,
    seats: 0,
    energyEfficiencyClass: '',
    interiorType: '',
    singleDayRegistration: false,
    envkv: false,
    accidentDamaged: false,
    usedCarSeal: null,
    vatRate: 19,
    vatReclaimable: false,
    financing: {
      calculation: {
        finalRate: null,
        interest: null,
        interestRateEff: null,
        interestRateNominal: null,
        interestSum: null,
        netCreditAmount: null,
        rate: null,
        totalSum: null,
      },
      parameters: {
        campaignId: null,
        dealerId: null,
        downPayment: null,
        duration: null,
        durationOptions: null,
        mileage: null,
        mileageOptions: null,
        rsv: null,
      },
    },
    financingPrecalc: {
      requestId: '',
      result: {
        label: '',
        summary: [],
        price: {
          label: '',
          value: 0,
          format: '',
          disclaimerRef: null,
        },
      },
      sections: [],
      disclaimers: [],
      specificData: {},
      searchFilters: { captiveId: '' },
    },
    live: true,
    remarks: '',
    cubicCapacity: 0,
  };

  const removeFromVehicle = [
    'dealerId',
    'financingAnnualInterestRate',
    'financingFinalInstallment',
    'financingFirstInstallment',
    'financingMonthlyInstallment',
    'financingPeriod',
    'imageBatchId',
    'metallic',
    'score',
  ];

  const removeFromDealer = [
    'latestContractSignature',
    'leadEmail',
    'leadFormat',
    'network',
  ];

  const vehicleObject = Object.assign(defaultVehicle, clonedVehicle);

  removeFromVehicle.forEach((key) => {
    delete vehicleObject[key];
  });
  removeFromDealer.forEach((key) => {
    delete vehicleObject.dealer[key];
  });

  return vehicleObject;
};

const pollutantClassDetailMapping = {
  EURO6C: 'Euro 6C',
  EURO6D: 'Euro 6D',
  EURO6D_TEMP: 'Euro 6D Temp',
};

export const getLoadingVehicle = () => {
  const modifications = {
    dealer: {
      location: {
        city: 'heycar city',
        street: 'heycar street',
        zip: '12345',
        geoCoordinates: {
          lat: 0,
          lon: 0,
        },
        country: 'Deutschland',
      },
    },
    firstRegistrationDate: '2017-05-01',
    fuelType: 'petrol',
    gearBox: 'manual_gear',
    guarantee: 0,
    mileage: 12345,
    model: {
      displayName: 'Das beste heycar',
      trim: 'Wir helfen Dir das beste Auto für Dich zu finden',
    },
  };

  return Object.assign(getNormalizedVehicle({}), modifications);
};

export const getFormattedVehicleDate = (
  value,
  trimFirst = false,
  divider = '/',
  trimYear = false,
) => {
  const spliceIndex = trimFirst ? 1 : 0;
  const sliceIndex = trimYear ? -2 : -4;

  if (value) {
    return value
      .split('-')
      .map((num) => num.slice(sliceIndex))
      .reverse()
      .splice(spliceIndex)
      .join(divider);
  }
  return '';
};

export const isCaptiveBankGroup = (makeId) =>
  ['vw', 'audi', 'porsche', 'seat', 'skoda', 'mercedes-benz'].some(
    (item) => item === makeId,
  );

export function* range(start = 0, end, step) {
  const cur = end === undefined ? 0 : start;
  const max = end === undefined ? start : end;

  for (let i = cur; step < 0 ? i > max : i < max; i += step) {
    yield i;
  }
}

export const formattedOpeningTimes = (openingTimes) => {
  if (!openingTimes) {
    return null;
  }
  return openingTimes.map((time) => {
    if (!time.openFrom || !time.openTo) {
      return { dayOfWeek: time.dayOfWeek, open: false };
    }

    const { openFrom, openTo, dayOfWeek } = time;
    const start = (parseFloat(openFrom) + (/:30/.test(openFrom) ? 0.5 : 0)) * 2;
    const end = (parseFloat(openTo) + (/:30/.test(openTo) ? 0.5 : 0)) * 2;
    const times = [];

    for (const item of range(start, end, 1)) {
      times.push(`${Math.floor(item / 2)}:${item % 2 ? '30' : '00'}`);
    }

    return {
      dayOfWeek,
      open: true,
      times,
    };
  });
};

export const hasPrecalculatedFinance = (vehicle) => {
  const result = fdlDealerCheck(
    vehicle.financingPrecalc?.searchFilters?.captiveId,
    vehicle,
  );

  return isString(result) ? result !== '' : result;
};

export const hideDealerPartOfExternalId = (externalId) => {
  const tokens = externalId.split('-');

  tokens.shift();
  return tokens.join('-');
};

export const isFavourite = (favouriteIds, vehicleId) =>
  (Array.isArray(favouriteIds) &&
    favouriteIds.filter((favouriteId) => favouriteId === vehicleId).length >
      0) ||
  false;

export const isCarInComparison = (compareIds, vehicleId) =>
  (compareIds &&
    compareIds.filter((compareId) => compareId === vehicleId).length > 0) ||
  false;

export const getEmissionClass = (
  emissionClass,
  pollutantClass,
  missingDataLabel,
) => {
  if (
    (emissionClass === '' || emissionClass === 'none') &&
    (pollutantClass === '' || pollutantClass === 'NONE')
  ) {
    return missingDataLabel;
  }
  if (pollutantClass === 'EURO6C') {
    return pollutantClassDetailMapping.EURO6C;
  }
  if (pollutantClass === 'EURO6D') {
    return pollutantClassDetailMapping.EURO6D;
  }
  if (pollutantClass === 'EURO6D_TEMP') {
    return pollutantClassDetailMapping.EURO6D_TEMP;
  }
  if (emissionClass !== '' && emissionClass !== 'none') {
    return emissionClass.replace('euro', 'Euro ');
  }
};

/**
 * @param {{id: string; content: any[]}} param0 - search api response
 * @returns {import('app/types/entities/Vehicle.types').IVehicle[]}
 */
export const normalizeVehiclePayload = ({ id, content }) => {
  const meta = id ? { searchId: id } : {};

  return content.map((v) => getNormalizedVehicle(v, meta));
};

export const isDealerCashVehicle = (vehicle) => {
  return vehicle?.productOffering?.eligibleFor?.includes('DE_DEALER_CASH');
};

export const isDealerFinanceVehicle = (vehicle) => {
  return vehicle?.productOffering?.eligibleFor?.includes(
    'DE_DEALER_FINANCING_AC',
  );
};

export const isDealerLeasingVehicle = (vehicle) => {
  return vehicle?.productOffering?.eligibleFor?.includes('DE_DEALER_LEASING');
};

export const isOnlyDealerLeasingVehicle = (vehicle) => {
  return (
    vehicle?.productOffering?.eligibleFor?.includes('DE_DEALER_LEASING') &&
    vehicle?.productOffering?.eligibleFor?.length === 1
  );
};

export const isD2CLeasingVehicle = (vehicle) => {
  return vehicle?.productOffering?.eligibleFor?.includes('DE_D2C_LEASING');
};

export const isD2CFinanceVehicle = (vehicle) => {
  return vehicle?.productOffering?.eligibleFor?.includes('DE_D2C_FINANCING_AC');
};

export const isD2CCashVehicle = (vehicle) => {
  return vehicle?.productOffering?.eligibleFor?.includes('DE_D2C_CASH');
};

export const isInstamotionCashVehicle = (vehicle) => {
  return vehicle?.productOffering?.eligibleFor?.includes('DE_INSTAMOTION_CASH');
};

export const isInstamotionFinanceVehicle = (vehicle) => {
  return vehicle?.productOffering?.eligibleFor?.includes(
    'DE_INSTAMOTION_FINANCING',
  );
};

export const isClassifiedOnlyVehicle = (vehicle) => {
  // if (!vehicle?.productOffering) return true;
  return vehicle?.productOffering?.eligibleFor?.length === 0;
};

export const getConditionReportURL = (vehicle) => {
  const conditionDocument = vehicle?.documents;

  if (!conditionDocument || conditionDocument?.length === 0) return null;
  if (conditionDocument?.length === 1) {
    return (
      (conditionDocument[0]?.contentType === 'application/pdf' &&
        conditionDocument[0]?.url) ||
      null
    );
  }
  if (conditionDocument?.length > 1) {
    return (
      conditionDocument?.find(
        (item) =>
          item.name.includes('condition_report') &&
          item.contentType === 'application/pdf',
      )?.url || null
    );
  }
};

export const getAccidentInfo = (vehicle) => {
  return vehicle?.accidentInfo ? `/vehicle/${vehicle.id}/accident-info` : '';
};

export const hideVorteileSection = (vehicle) => {
  if (!vehicle.productOffering) {
    return true;
  }

  const types = [
    'DE_DEALER_CASH',
    'DE_DEALER_FINANCING_AC',
    'DE_DEALER_LEASING',
  ];

  const result = types.some((i) =>
    vehicle?.productOffering?.eligibleFor?.includes(i),
  );

  return vehicle?.productOffering?.eligibleFor?.length === 0 || result;
};

/* istanbul ignore file */
import styled, { css } from 'styled-components';

import { media } from 'app/shared/styles_js/variables';
import {
  IBannerContainer,
  IWrapper,
  ILoadingWrapper,
} from 'app/shared/modules/Campaign/GAM/GamBanner/GamBanner.types';

export const Wrapper: React.ElementType = styled.div<IWrapper>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  position: relative;

  ${({ $isScalable, $bannerArea }) =>
    $isScalable
      ? css`
          aspect-ratio: ${`auto ${$bannerArea.sm.width} / ${$bannerArea.sm.height}`};

          ${media.md(css`
            aspect-ratio: ${`auto ${$bannerArea.md.width} / ${$bannerArea.md.height}`};
          `)}

          ${media.lg(css`
            aspect-ratio: ${`auto ${$bannerArea.lg.width} / ${$bannerArea.lg.height}`};
          `)}

          ${media.xl(css`
            aspect-ratio: ${`auto ${$bannerArea.xl.width} / ${$bannerArea.xl.height}`};
          `)}
        `
      : css`
          height: ${$bannerArea.sm.height}px;

          ${media.md(css`
            height: ${$bannerArea.md.height}px;
          `)}

          ${media.lg(css`
            height: ${$bannerArea.lg.height}px;
          `)}

          ${media.xl(css`
            height: ${$bannerArea.xl.height}px;
          `)}
        `};
`;

export const BannerContainer: React.ElementType = styled.div<IBannerContainer>`
  width: 100%;

  ${({ $bannerContainerStyle }) =>
    $bannerContainerStyle &&
    css`
      ${$bannerContainerStyle}
    `};

  ${({ $isHidden }) =>
    $isHidden &&
    css`
      visibility: hidden;
      width: 0;
      height: 0;
      opacity: 0;
      padding: 0;
      margin: -1px;
      border-width: 0;
      clip: rect(0, 0, 0, 0);
      overflow: hidden;
    `};

  /**
   * Make the banner scalable for responsiveness
   * See also: scaleGamBanner in 'app/shared/modules/Campaign/GAM/utils/utils.js'
   */
  ${({ $isScalable, $bannerArea }) =>
    $isScalable &&
    css`
      --aspectRatio: ${`auto ${$bannerArea.sm.width} / ${$bannerArea.sm.height}`};

      ${media.md(css`
        --aspectRatio: ${`auto ${$bannerArea.md.width} / ${$bannerArea.md.height}`};
      `)}

      ${media.lg(css`
        --aspectRatio: ${`auto ${$bannerArea.lg.width} / ${$bannerArea.lg.height}`};
      `)}

      ${media.xl(css`
        --aspectRatio: ${`auto ${$bannerArea.xl.width} / ${$bannerArea.xl.height}`};
      `)}

      > div {
        overflow: hidden;

        @supports not (aspect-ratio: var(--aspectRatio)) {
          position: relative;
          padding-top: ${($bannerArea.sm.height / $bannerArea.sm.width) * 100}%;

          ${media.md(css`
            padding-top: ${($bannerArea.md.height / $bannerArea.md.width) *
            100}%;
          `)}

          ${media.lg(css`
            padding-top: ${($bannerArea.lg.height / $bannerArea.lg.width) *
            100}%;
          `)}

          ${media.xl(css`
            padding-top: ${($bannerArea.xl.height / $bannerArea.xl.width) *
            100}%;
          `)}
        }

        iframe {
          aspect-ratio: var(--aspectRatio);
          width: 100%;
          height: 100%;

          @supports not (aspect-ratio: var(--aspectRatio)) {
            position: absolute;
            inset: 0;
          }
        }
      }
    `};
`;

export const LoadingWrapper: React.ElementType = styled.div<ILoadingWrapper>`
  display: inherit;

  svg {
    fill: ${({ theme: { colors } }) => colors.micaBlue};
    color: ${({ theme: { colors } }) => colors.graySuperLight};
    width: ${({ $useSmallSpinner }) => ($useSmallSpinner ? '1.25rem' : '2rem')};
    height: ${({ $useSmallSpinner }) =>
      $useSmallSpinner ? '1.25rem' : '2rem'};
    will-change: transform;
    animation: spin 1s linear infinite;

    @keyframes spin {
      from {
        transform: rotate(0deg);
      }
      to {
        transform: rotate(360deg);
      }
    }
  }
`;

export const SrOnly: React.ElementType = styled.span`
  clip: rect(0, 0, 0, 0);
  border-width: 0;
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
`;

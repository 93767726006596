import 'abortcontroller-polyfill/dist/abortcontroller-polyfill-only';
import 'isomorphic-fetch';
import { themeV2 } from 'app/shared/ui';
import { getAnonymousSegmentID } from 'app/shared/utils/tracking';

import * as Config from './config';
import { checkForError } from './shared/utils/checkForError';
import * as ActionTypes from './constants';

export const defaultHeader = {
  credentials: 'include',
  headers: {
    Accept: 'application/json',
    'Accept-Language': 'de',
    'Content-Type': 'application/json',
    'Recaptcha-Token': undefined,
    'X-Tracking-Guid': null,
    'X-Heycar-Tenant': 'DE',
  },
};

const apiCallInternal = (
  url,
  options = {},
  apiBaseUrl = null,
  useAnonymousId = false,
) => {
  return (dispatch, getState) => {
    const state = getState();
    const headerOptions = {
      ...defaultHeader,
      headers: {
        ...defaultHeader.headers,
        'X-Tracking-Guid': useAnonymousId
          ? getAnonymousSegmentID()
          : state?.tracking?.guid,
      },
    };

    if (CLIENT) {
      headerOptions.mode = 'cors';
    }

    const apiUrl = apiBaseUrl ? `${apiBaseUrl}${url}` : url;

    options = Object.assign(headerOptions, options);

    return fetch(apiUrl, options)
      .then(checkForError)
      .then((response) => response.text())
      .then((response) => {
        return response ? JSON.parse(response) : {};
      });
  };
};

export const apiCall = (
  url,
  options = {},
  useBackendApi = true,
  useCommonApi = false,
  useAnonymousId = false,
) => {
  const backendApiBaseUrl = useCommonApi
    ? Config.COMMON_API_URL
    : Config.DE_API_URL;
  return apiCallInternal(
    url,
    options,
    useBackendApi ? backendApiBaseUrl : null,
    useAnonymousId,
  );
};

export const checkoutApiCall = (
  url,
  options = {},
  useBackendApi = true,
  useCommonApi = false,
) => {
  const backendApiBaseUrl = useCommonApi
    ? Config.COMMON_API_URL
    : Config.CHECKOUT_API_URL;
  return apiCallInternal(
    url,
    options,
    useBackendApi ? backendApiBaseUrl : null,
  );
};

/**
 * see https://github.com/hey-car/web-app/pull/2253 for thunk/saga usage
 * @param {string} url
 * @param {Object} options
 * @param {boolean} useBackendApi
 * @param {boolean} useCommonApi
 * @returns {(dispatch: any) => {promise: Promise<any>, abortController: AbortController|null}}
 */
export const abortableClientApiCall = (
  url,
  options = {},
  useBackendApi = true,
  useCommonApi = false,
) => {
  let abortController = null;
  let optionsWithSignal = options;
  if (CLIENT && 'AbortController' in window) {
    abortController = new AbortController();
    optionsWithSignal = {
      ...optionsWithSignal,
      signal: abortController.signal,
    };
  }
  return (dispatch) => {
    const promise = dispatch(
      apiCall(url, optionsWithSignal, useBackendApi, useCommonApi),
    );
    return { promise, abortController };
  };
};

export const abortableClientSeoApiCall = (url, options = {}) => {
  let abortController = null;
  let optionsWithSignal = options;
  if (CLIENT && 'AbortController' in window) {
    abortController = new AbortController();
    optionsWithSignal = {
      ...optionsWithSignal,
      signal: abortController.signal,
    };
  }
  return (dispatch) => {
    const promise = dispatch(
      apiCallInternal(url, optionsWithSignal, Config.SEO_API_URL),
    );
    return { promise, abortController };
  };
};

export const checkZip = (zip) => {
  return (dispatch) => {
    const url = `/location/lookup/postcode/${zip}`;

    return dispatch(apiCall(url))
      .then(() => {
        return Promise.resolve();
      })
      .catch(() => {
        return Promise.reject();
      });
  };
};

export const checkPhone = (number) => {
  // TODO: provide a locale to validate international phone numbers
  return (dispatch) => {
    const url = '/validations/phone';
    const options = {
      method: 'POST',
      body: JSON.stringify({
        phone: number,
      }),
    };

    return dispatch(apiCall(url, options))
      .then(() => {
        return Promise.resolve();
      })
      .catch(() => {
        return Promise.reject();
      });
  };
};

export const updatePreviousLocation = (href) => {
  return {
    type: ActionTypes.SET_PREVIOUS_LOCATION,
    payload: href,
  };
};

export const setShortCircuit = (shortCircuit) => {
  return {
    type: ActionTypes.SET_SHORT_CIRCUIT,
    payload: shortCircuit,
  };
};

/**
 * @param {Object} param0
 * @param {301|302|303|307|308} param0.status
 * @param {string} param0.redirectUrl
 */
export const setRedirect = ({ status = 302, redirectUrl }) => ({
  type: ActionTypes.SET_REDIRECT,
  payload: { status, redirectUrl },
});

export const setTheme = () => {
  return {
    type: ActionTypes.SET_THEME,
    payload: themeV2,
  };
};

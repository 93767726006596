import { TrackingList } from './types';

const FINANCING = {
  CALCULATOR: {
    INTERACTION: 'interacted_with_fin_calculator',
    ERROR: 'interacted_with_fin_calculator_error',
    CTA: 'calculator_rate_button_click',
  },
  WIZARD: {
    ENTRY_POINT: 'fdl_wizard_button_click',
    CTA: 'fdl_wizard_result_cta_click',
    STEP_1: 'fdl_wizard_mobility_click_next',
    STEP_2: 'fdl_wizard_rateplan_click_next',
    RESULT: 'fdl_wizard_result',
  },
  LINKS: {
    FINANCING_GUIDE: 'fdl_guide_button_click',
    MONTHLY_RATE_BUTTON: 'monthly_rate_link_click',
    CAROUSEL_PROMOTION: 'vehicle_carousel_calculator_button_click',
    CONSUMER_LOAN_DCC: 'consumerloan_dcc_main_button_click',
    CONSUMER_LOAN_INDIVIDUAL: 'consumerloan_individual_main_button_click',
    PDP_TOP_HEADER: {
      FINANCING_CALCULATOR: 'financing_calculator_jump_link',
      DCC: 'dcc_jump_link',
      INDIVIDUAL_CREDIT: 'individual_credit_jump_link',
    },
  },

  FULL_PRICE_FILTER: 'fullprice_search_button_click',
  MONTHLY_RATE_FILTER: 'budget_search_button_click',
  LEASING_RATE_FILTER: 'leasing_search_button_click',
};

const INTEGRATIONS = {
  TRADE_IN: {
    FOOTER_ENTRY_POINT: 'trade-in_footer_click',
  },
  CARSALE_24: {
    PDP_ENTRY_POINT: 'carsale24_entrypoint_click',
    CLP_ENTRY_POINT: 'carsale24_entrypoint_click',
    MAGAZINE_ENTRY_POINT: 'carsale24_entrypoint_click',
    FOOTER_ENTRY_POINT: 'carsale_footer_click',
  },
  INSURANCE: {
    ALLIANZ: {
      MORE_INFO_CLICK: 'allianz_more_info_click',
      SHOW_PHONE_NUMBER: 'allianz_show_phone_number_click',
      CALL_BACK_REQUEST: 'allianz_call_back_request_click',
    },
  },
  EV: {
    LINKOUT: {
      ALL_EV: 'view_all_ev_click',
      ID_3_LEASING_OFFERS: 'id_3_leasing_offers_click',
      TESLA_MODEL_3_OFFERS: 'tesla_model_3_offers_click',
      ZOE_OFFERS: 'zoe_offers_click',
    },
    EV_BONUS: {
      MODAL_OPEN_CTA: 'ev_bonus_button_click',
    },
  },
};

const ONE_CLICK_LEADS = {
  LEAD_MODAL: {
    MINI_CARTILE: {
      SIMILAR_CARS_CLICK: 'similar_cars_click',
    },
  },
};

/**
 *  declaration of all trackings for ease to find
 *  and clarity on which ones are currently available
 */
export const TRACKING_LIST: TrackingList = {
  FINANCING,
  INTEGRATIONS,
  ONE_CLICK_LEADS,
};

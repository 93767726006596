import { COMMON_API_URL, KEYCLOAK_URL } from 'app/config';

const KEYCLOAK_REALMS_URL = `${KEYCLOAK_URL}/auth/realms/heycar`;
const BASE_OPENID_URL = `${KEYCLOAK_REALMS_URL}/protocol/openid-connect`;
export const SIGNUP_URL = `${KEYCLOAK_REALMS_URL}/registration`;
export const LOGIN_URL = `${BASE_OPENID_URL}/token`;
export const LOGIN_OUT_URL = `${BASE_OPENID_URL}/logout`;
export const GET_USER_URL = `${BASE_OPENID_URL}/userinfo`;
export const AUTH_REDIRECT_URL = `${BASE_OPENID_URL}/auth`;
export const FORGOT_PASSWORD_URL = `${KEYCLOAK_REALMS_URL}/login-actions/reset-credentials`;
export const RESEND_EMAIL_CONFIRMATION_URL = `${KEYCLOAK_REALMS_URL}/resend-confirmation`;
export const USER_PROFILE_URL = `${COMMON_API_URL}/profiles/me`;
export const OPEN_ID_LOGIN_PATH = '/account/openIdLogin';
export const clientId = 'web-app';
export const optionsDefault = {
  method: 'POST',
  headers: {
    'Content-Type': 'application/json',
    'Accept-Language': 'de',
  },
};
export const keycloakHeaders = {
  'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
};

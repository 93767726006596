import { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

import { isSIBrowser, isSafariBrowser } from 'app/shared/utils/utils';
import { breakpoints } from 'app/shared/styles_js/variables';
import { sessionStore } from 'app/shared/utils/storageFactory';
import { OverlaySt } from 'app/shared/components/Overlay/Overlay.css';

export const BODY_OVERLAY_LOCK_CLASS = 'overlay-lock';
export const SCROLL_POSITION_SS_KEY = 'lastScrollYPosition';

const isMobile = () => window.innerWidth < breakpoints.md;
const isSafariMobile = () => isMobile() && isSafariBrowser();

export const Overlay = (props) => {
  const {
    show,
    onClose,
    children,
    className,
    useWhiteBg,
    isTransparent,
    specialStyles,
    isPositionFixed,
  } = props;
  const shouldLock = show && !isTransparent;
  const ref = useRef(null);

  /**
   * This is the solution for preventing the white or transparent blank space at the
   * bottom of the screen when scrolling down for only "Android devices" that uses any
   * browser including SI (Samsung Internet) browser which requires an additional fix.
   *
   * See here: https://heycar.atlassian.net/browse/LEAD-2123
   */
  const updateOverlayStStyle = () => {
    const overlayStElement = ref.current;
    if (overlayStElement) {
      if (isSIBrowser()) {
        overlayStElement.style.height = `${
          screen.availHeight || window.innerHeight
        }px`;
      } else {
        overlayStElement.style.height = isMobile() ? '100vh' : '';
      }
    }
  };

  const updateBodyStyle = (show, shouldLock = false) => {
    if (isPositionFixed) return;
    /**
     * Even if we define a className to the body that includes !important flag
     * in it, its inline overflow style overrides it somehow in iOS. So the
     * certain solution is directly manipulating the overflow style of the body.
     */
    document.body.style.overflow = show && shouldLock ? 'hidden' : 'scroll';

    if (isSafariMobile()) {
      /**
       * Making body position fixed above, scrolls to the top of the page.
       * Thus we lost the latest scroll position of the body. This is the
       * workaround for retaining the scroll position after user closes
       * for example the menu (that uses Overlay) on Safari (mobile).
       *
       * Ticket: https://heycar.atlassian.net/browse/LEAD-2324
       */

      const lastScrollYPosition = sessionStore.getItem(SCROLL_POSITION_SS_KEY);
      if (lastScrollYPosition !== undefined) {
        window.scrollTo(0, lastScrollYPosition);
      }
    }
  };

  useEffect(() => {
    if (isSafariMobile() && shouldLock) {
      sessionStore.setItem(SCROLL_POSITION_SS_KEY, window.scrollY);
    }
  }, []);

  const handleUnmount = () => {
    updateBodyStyle(false);
    if (isSafariMobile()) {
      sessionStore.removeItem(SCROLL_POSITION_SS_KEY);
    }
  };

  useEffect(() => {
    updateBodyStyle(show, shouldLock);
    updateOverlayStStyle();
    return handleUnmount;
  }, [show, isTransparent]);

  return (
    <OverlaySt
      ref={ref}
      isShown={show}
      onClick={onClose}
      onTouchEnd={onClose}
      className={className}
      specialStyles={specialStyles}
      isTransparent={isTransparent}
      useWhiteBg={useWhiteBg && !isTransparent}
    >
      {children}
    </OverlaySt>
  );
};

Overlay.propTypes = {
  onClose: PropTypes.func,
  children: PropTypes.any,
  useWhiteBg: PropTypes.bool,
  className: PropTypes.string,
  specialStyles: PropTypes.any,
  isTransparent: PropTypes.bool,
  show: PropTypes.bool.isRequired,
  isPositionFixed: PropTypes.bool,
};

Overlay.defaultProps = {
  onClose() {},
  className: '',
  children: null,
  useWhiteBg: false,
  specialStyles: null,
  isTransparent: false,
  isPositionFixed: false,
};

import React from 'react';

import { ItemSt, SuggestionsWrapperSt } from './Suggestions.css';
import { TSuggestionsProps } from './Suggestions.types';

export const Suggestions = ({
  items,
  onSelect,
  focusedIndex,
  suggestionsRef,
  prefixCmp,
  showDropdownPrefix,
  variant,
}: TSuggestionsProps): JSX.Element => {
  return (
    <SuggestionsWrapperSt ref={suggestionsRef}>
      {variant === 'suggestions' && showDropdownPrefix && prefixCmp}
      {items.map(
        ({ value, label }, index): JSX.Element => {
          return (
            <ItemSt
              data-testid="pickup-location-suggestion"
              tabIndex={-1}
              key={`${index + 1}`}
              isFocused={index === focusedIndex}
              onClick={() => onSelect({ value, label }, index)}
            >
              {label}
            </ItemSt>
          );
        },
      )}
    </SuggestionsWrapperSt>
  );
};

import { createTypes } from '../../shared/utils/createTypes';

export const ActionTypes = createTypes([
  'REGISTER_',
  'LOGIN_',
  'LOGOUT',
  'PASSWORD_CHANGED',
  'GET_PROFILE_',
  'UPDATE_PROFILE_',
  'REMOVE_JUST_REGISTERED',
  'RESET_LOGIN_FAILURE',
  'SHOW_SASE_DASHBOARD_MODAL',
  'HIDE_SASE_DASHBOARD_MODAL',
]);

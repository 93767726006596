/* istanbul ignore file */
import styled, { css } from 'styled-components';
import { Heading2, Text } from 'app/shared/ui';

import { color } from '../styles_js/variables';

export const carTileGap = 32;
export const flexContainer = css`
  display: flex;
  flex-wrap: wrap;
`;

export const FlexContainerSt = styled.div`
  display: flex;
`;

export const TextSt = styled.span`
  ${(props) => props.color && `color: ${color[props.color]};`}
  ${(props) => props.size && `font-size: ${props.size};`}
  ${(props) => props.lineHeight && `line-height: ${props.lineHeight};`}
  ${(props) => props.fontWeight && `font-weight: ${props.fontWeight};`};
`;

export const Subtitle = styled(Heading2).attrs(() => ({
  fontSize: [24, 28, 32],
  lineHeight: [1.67, 1.5],
}))``;

export const Description = styled(Text).attrs(() => ({
  textAlign: [null, 'center'],
  fontSize: 14,
}))``;

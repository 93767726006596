import styled, { css } from 'styled-components';
import { Flex } from '@rebass/grid';

import { GridContainer, Link } from 'app/shared/ui';
import { media, colorV2 } from 'app/shared/styles_js/variables';

export const Grid = styled(GridContainer)`
  margin: 0 12px 16px;
  border-radius: 4px;
  box-shadow: 0 0 8px 0 rgb(0 0 0 / 15%);
  cursor: pointer;
  transition: transform 0.2s;
  transform-origin: center;
  background-color: ${colorV2.mustangLighter};
  &:hover {
    transform: scale(1.02);
  }

  ${media.xl(css`
    grid-template-columns: repeat(9, 1fr);
  `)};
`;
export const Wrapper = styled.div`
  margin: 24px 12px 16px;
  border-radius: 4px;
  box-shadow: 0 0 8px 0 rgb(0 0 0 / 15%);
  cursor: pointer;
  transition: transform 0.2s;
  transform-origin: center;
  text-align: center;
  position: relative;
  &:hover {
    transform: scale(1.02);
  }
`;

export const ImageContainer = styled(Flex)`
  align-items: center;

  ${media.md(css`
    grid-row: 1;
    grid-column-end: 1;
    grid-column-start: 0;
    > img {
      display: block;
      width: 100%;
      height: auto;
      padding-bottom: 0;
      margin: 0 auto;
    }
  `)};
`;

export const ImageContainerSm = styled(Flex)`
  align-items: center;
  position: absolute;
  top: -50%;
  left: 50%;
  transform: translateX(-50%);
  img {
    display: block;
    width: 110px;
    height: 62px;
    padding-bottom: 0;
    margin: 0 auto;
  }
`;

/* istanbul ignore next */
export const UnstyledLink = styled(Link).attrs(() => ({
  color: 'inherit',
  textDecoration: 'none',
  fontWeight: 'normal',
}))<{ breakpoint?: string }>`
  ${({ breakpoint }) =>
    css`
      margin-top: ${breakpoint === 'sm' ? '24px' : '-24px'};
      width: 100%;
    `}

  &:hover,
  &:focus,
  &:visited,
  &:active {
    color: inherit;
  }
`;

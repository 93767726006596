/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { footerContent } from 'app/marketplace/shared/NewMarketplaceTopNavBar/NewMarketplaceTopNavBar.constants';

export const UserMenu = (
  onAuthClick: () => void,
  isLoggedIn: boolean,
  handleLogout: () => void,
  isMobileDevice: boolean,
) => {
  return {
    label: 'Login',
    itemId: 'login',
    userAction: onAuthClick,
    footercontent: footerContent(isMobileDevice),
    itemCategories: isLoggedIn
      ? [
          {
            title: 'Dein Konto',
            elements: [
              {
                label: 'Meine Einstellungen',
                url: '/account/profile',
              },
              {
                label: 'Meine Suchen',
                url: '/account/searches',
              },
              {
                label: 'Ausloggen',
                onClick: handleLogout,
              },
            ],
          },
        ]
      : undefined,
  };
};

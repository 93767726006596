import React from 'react';
import PropTypes from 'prop-types';

import { iconSizes } from '../variables/fonts';

import {
  ChildrenSt,
  IconSt,
  RadioSt,
  RadioContainerSt,
  ErrorWrapper,
} from './Radio.css';

export const Radio = (props) => {
  const {
    checked,
    children,
    isRight,
    disabled,
    onChange,
    selectedValue,
    tabIndex,
    textSize,
    value,
    size,
    className,
  } = props;
  const handleOnChange = () => {
    if (disabled) return;
    onChange(value);
  };

  const isChecked =
    selectedValue === undefined ? checked : selectedValue === value;
  const classSuffix = isChecked ? 'active' : 'inactive';

  return (
    <RadioSt
      className={className}
      disabled={disabled}
      isRight={isRight}
      onClick={handleOnChange}
      onKeyPress={handleOnChange}
    >
      <IconSt
        size={size}
        disabled={disabled}
        isChecked={isChecked}
        tabIndex={tabIndex}
        className={`if-icon-radiobutton-${classSuffix}`}
      />

      <ChildrenSt disabled={disabled} textSize={textSize}>
        {children}
      </ChildrenSt>
    </RadioSt>
  );
};

Radio.propTypes = {
  onChange: PropTypes.func,
  checked: PropTypes.bool,
  disabled: PropTypes.bool,
  tabIndex: PropTypes.number,
  textSize: PropTypes.number,
  children: PropTypes.any,
  selectedValue: PropTypes.any,
  value: PropTypes.any,
  isRight: PropTypes.bool,
  size: PropTypes.oneOf([iconSizes.xsmall, iconSizes.medium]),
  className: PropTypes.string,
};

Radio.defaultProps = {
  onChange: /* istanbul ignore next */ () => {},
  checked: false,
  disabled: false,
  tabIndex: 0,
  textSize: 14,
  children: '',
  selectedValue: undefined,
  value: '',
  isRight: false,
  size: 24,
  className: '',
};

/**
 * RadioGroup
 */
export const RadioGroup = (props) => {
  const {
    children,
    disabled,
    errorText,
    name,
    onChange,
    value,
    isRight,
    className,
    ...rest
  } = props;
  return (
    <>
      <RadioContainerSt flexDirection="column" {...rest}>
        {children.map((child, idx) =>
          React.cloneElement(child, {
            disabled,
            name,
            onChange,
            selectedValue: value,
            isRight,
            key: idx,
            className,
          }),
        )}
      </RadioContainerSt>
      <ErrorWrapper>{errorText || null}</ErrorWrapper>
    </>
  );
};

RadioGroup.propTypes = {
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  isRight: PropTypes.bool,
  children: PropTypes.any,
  errorText: PropTypes.any,
  name: PropTypes.any,
  value: PropTypes.any,
  className: PropTypes.string,
};

RadioGroup.defaultProps = {
  onChange: /* istanbul ignore next */ () => {},
  disabled: false,
  isRight: false,
  children: '',
  errorText: '',
  name: '',
  value: '',
  className: '',
};

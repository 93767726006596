import { IGAMConfig } from 'app/shared/modules/Campaign/GAM/GamBanner/GamBanner.types';

const dimensions = { width: 340, height: 350 };

export const bannerSizes = {
  sm: [{ ...dimensions }],
  md: [{ ...dimensions }],
  lg: [{ ...dimensions }],
  xl: [{ ...dimensions }],
};

export const bannerArea = {
  sm: { ...dimensions },
  md: { ...dimensions },
  lg: { ...dimensions },
  xl: { ...dimensions },
};

const getHpDealsAndSpecialsTiles = (_: number, index: number): IGAMConfig => ({
  adUnitId: `div-gam-hp-deals-and-specials-car-tile-${index + 1}`,
  adUnitPath: `/22376835783/hp-deals-and-specials-car-tile-${index + 1}`,
  sizes: bannerSizes,
  bannerArea,
  trackingData: {
    clickEvent: {
      eventName: `home_page_deals_and_specials_car_tile${index + 1}_click`,
      placement: 'HP',
    },
    impressionEvent: {
      category: 'home page',
      label: `deals and special car tile ${index + 1} on home page loaded`,
    },
  },
});

export const HP_DEALS_AND_SPECIALS_TILES = [...new Array(4).keys()].map(
  getHpDealsAndSpecialsTiles,
);

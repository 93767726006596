/* eslint-disable consistent-return */

import { apiCall } from 'app/actions';
import { CONTENTFUL_HEYCAR } from 'app/config';
import {
  MAKE_PAGE,
  MODEL_PAGE,
  VARIANT_PAGE,
} from 'app/marketplace/contentful/spaces/seo/constants';
import { generateStateIdentifier } from 'app/marketplace/contentful/spaces/seo/ts-utils';

// Mock contentful response
import featuredArticlesMock from '../../../../fixtures/contentfulMocks/featuredArticles.json';
import latestArticlesMock from '../../../../fixtures/contentfulMocks/latestArticles.json';
import homeMock from '../../../../fixtures/contentfulMocks/home.json';
import autoOnlineBestellenMock from '../../../../fixtures/contentfulMocks/autoOnlineBestellen.json';
import autokaufOnlineSoFunktioniertsMock from '../../../../fixtures/contentfulMocks/autokaufOnlineSoFunktionierts.json';
import allMarke from '../../../../fixtures/contentfulMocks/allMarke.json';
import makes from '../../../../fixtures/contentfulMocks/makes.json';
import electricalArticles from '../../../../fixtures/contentfulMocks/electricalArticles.json';
import homeSeoTexts from '../../../../fixtures/contentfulMocks/homeSeoTexts.json';
import angebote from '../../../../fixtures/contentfulMocks/angebote.json';
import * as AppActionTypes from '../../constants';
import faq1 from '../../../../fixtures/contentfulMocks/4G7CEN6JfOouI7goINqkf7.json';
import faq2 from '../../../../fixtures/contentfulMocks/4GiUxUd9afAnIq3dtwisQo.json';
import faq3 from '../../../../fixtures/contentfulMocks/2gyox8uaKfuYhoRnlnHlsT.json';
import faq4 from '../../../../fixtures/contentfulMocks/5MZKwWbr47Gio9r65JpHG4.json';
import faq5 from '../../../../fixtures/contentfulMocks/7dRiuuba8qiR1Rd407xwnF.json';
import faq6 from '../../../../fixtures/contentfulMocks/4QfEDSd4NuR61V2Dm0o80e.json';
import faq7 from '../../../../fixtures/contentfulMocks/4goQcXgZrOk33JKmsDqBAT.json';
import faq8 from '../../../../fixtures/contentfulMocks/2l0tEhNwDqbhJqyH3Ybdr2.json';
import faq9 from '../../../../fixtures/contentfulMocks/38O1dgIVc94MA0Mz4pRz7N.json';
import faq10 from '../../../../fixtures/contentfulMocks/7mJY9D4hLZ2jMrkGKTBZkq.json';
import campaignsMock from '../../../../fixtures/contentfulMocks/campaigns.json';
import footerFAQs from '../../../../fixtures/contentfulMocks/footerFAQs.json';
import magazineCategoriesMock from '../../../../fixtures/contentfulMocks/magazineCategories.json';
import about from '../../../../fixtures/contentfulMocks/about.json';
import terms from '../../../../fixtures/contentfulMocks/terms.json';
import privacy from '../../../../fixtures/contentfulMocks/privacy.json';
import imprint from '../../../../fixtures/contentfulMocks/imprint.json';
import finanzierungsratgeber from '../../../../fixtures/contentfulMocks/finanzierungsratgeber.json';
import press from '../../../../fixtures/contentfulMocks/press.json';
import muenchen from '../../../../fixtures/contentfulMocks/muenchen.json';
import hamburg from '../../../../fixtures/contentfulMocks/hamburg.json';
import hannover from '../../../../fixtures/contentfulMocks/hannover.json';
import leipzig from '../../../../fixtures/contentfulMocks/leipzig.json';
import d2cExtras from '../../../../fixtures/contentfulMocks/1AAkcCVcbvJ8W5oj0e9M4Q.json';
import imExtras from '../../../../fixtures/contentfulMocks/52NYNmtvN0pP3T1lQfYKUp.json';
import contact from '../../../../fixtures/contentfulMocks/contact.json';

import { getEntries } from './utils';
import { ActionTypes } from './constants';

//---------------------------------------------------------------------------------------------
// -------------------------------- actions for modal  ----------------------------------------
//---------------------------------------------------------------------------------------------

export function addModal(modalId) {
  return { type: ActionTypes.CONTENTFUL_ADD_MODAL, payload: { modalId } };
}

export function addMockFeaturedArticles() {
  return {
    type: ActionTypes.CONTENTFUL_FEATURED_ARTICLES_COMPLETED,
    payload: featuredArticlesMock,
  };
}

export function addMockHome() {
  return {
    type: ActionTypes.CONTENTFUL_ENTRY_COMPLETED,
    payload: { identifier: 'home', entry: homeMock },
  };
}

export function addMockData(identifier, entryData) {
  return {
    type: ActionTypes.CONTENTFUL_ENTRY_COMPLETED,
    payload: {
      identifier,
      entry: entryData,
    },
  };
}

export function addMockedMagazine() {
  return {
    type: ActionTypes.CONTENTFUL_MAGAZINE_CATEGORIES_COMPLETED,
    payload: { identifier: '/magazine', entry: magazineCategoriesMock },
  };
}

export function addMakesMock() {
  return {
    type:
      ActionTypes.CONTENTFUL_GET_LIST_OF_MAKE_PAGES_FROM_SEO_SPACE_COMPLETED,
    payload: {
      makes,
    },
  };
}

export function addAllmarke() {
  return {
    type: ActionTypes.CONTENTFUL_ENTRY_COMPLETED,
    payload: {
      identifier: 'allMarke',
      entry: allMarke,
    },
  };
}

export function mockLatestArticlesMock() {
  return {
    type: ActionTypes.CONTENTFUL_ARTICLES_BY_LATEST_COMPLETED,
    payload: latestArticlesMock,
  };
}

export function removeModal(modalId) {
  return { type: ActionTypes.CONTENTFUL_REMOVE_MODAL, payload: { modalId } };
}

export function triggerModal(modalId) {
  return { type: ActionTypes.CONTENTFUL_TRIGGER_MODAL, payload: { modalId } };
}

export function triggerModalOutside(modalId) {
  return {
    type: ActionTypes.CONTENTFUL_TRIGGER_MODAL_OUTSIDE,
    payload: { modalId },
  };
}

//---------------------------------------------------------------------------------------------
// ----------------- actions for related articles on magazine article page  -------------------
//---------------------------------------------------------------------------------------------

export const updateRelatedArticlesOnStarted = () => ({
  type: ActionTypes.CONTENTFUL_RELATED_ARTICLES_STARTED,
});

export const updateRelatedArticlesOnCompleted = (response, identifier) => ({
  type: ActionTypes.CONTENTFUL_RELATED_ARTICLES_COMPLETED,
  payload: {
    entry: response,
    identifier,
  },
});

export const updateRelatedArticlesOnError = (error, identifier) => ({
  type: ActionTypes.CONTENTFUL_RELATED_ARTICLES_FAILED,
  payload: {
    identifier,
    error,
  },
});

const updateRelatedArticles = () => {
  return (dispatch) => {
    dispatch(addMockedMagazine());
  };
};

export const getRelatedArticleByCategory = (
  space,
  base,
  category,
  currentArticleId,
  isPreview,
) => {
  // when we get article we send new call to get related articles
  const queryObj = {
    content_type: 'articlePage',
    'fields.articleCategory.sys.contentType.sys.id': 'category',
    'fields.articleCategory.fields.categoryNameSlug': category,
    'sys.id[nin]': currentArticleId,
    limit: 2,
    include: 4,
  };

  const apiCallGetRelatedArticles = getEntries(
    queryObj,
    space,
    base,
    isPreview,
  );

  return updateRelatedArticles(apiCallGetRelatedArticles, category);
};

//---------------------------------------------------------------------------------------------
// ------------------------ actions for magazine articles and pages  --------------------------
//---------------------------------------------------------------------------------------------

export const getArticlesFromStore = (stateProperty, urlSlug, category) => (
  dispatch,
  getState,
) => {
  const state = getState();
  const { contentful } = state;
  const articles = contentful[stateProperty];
  let article;

  if (
    stateProperty === 'relatedArticles' ||
    stateProperty === 'articlesByCategory' ||
    stateProperty === 'newsByCategory'
  ) {
    const storeArticles = articles[category];
    article = storeArticles.find((item) => item.fields.urlSlug === urlSlug);
  } else {
    article = articles.find((item) => item.fields.urlSlug === urlSlug);
  }

  return dispatch({
    type: ActionTypes.CONTENTFUL_ENTRY_COMPLETED,
    payload: {
      entry: [article],
      identifier: `/magazine/${urlSlug}`,
    },
  });
};

export const updateMakePagesStarted = () => ({
  type: ActionTypes.CONTENTFUL_GET_LIST_OF_MAKE_PAGES_FROM_SEO_SPACE_STARTED,
});

export const updateMakePagesCompleted = (response) => ({
  type: ActionTypes.CONTENTFUL_GET_LIST_OF_MAKE_PAGES_FROM_SEO_SPACE_COMPLETED,
  payload: {
    makes: response,
  },
});

export const updateMakePagesFailed = (error) => ({
  type: ActionTypes.CONTENTFUL_GET_LIST_OF_MAKE_PAGES_FROM_SEO_SPACE_FAILED,
  payload: {
    error,
  },
});

export const updateEntryOnStarted = () => ({
  type: ActionTypes.CONTENTFUL_ENTRY_STARTED,
});

export const updateEntryOnCompleted = (response, identifier) => ({
  type: ActionTypes.CONTENTFUL_ENTRY_COMPLETED,
  payload: {
    entry: response,
    identifier,
  },
});

export const updateEntryOnError = (error, identifier) => ({
  type: ActionTypes.CONTENTFUL_ENTRY_FAILED,
  payload: {
    identifier,
    error,
  },
});

export const updateGlobalAppOnError = (error) => ({
  type: AppActionTypes.SET_ERROR_MESSAGE,
  payload: {
    error,
  },
});

const getEntryFromUrl = (apiCallGetEntry, identifier) => {
  return async (dispatch) => {
    dispatch(updateEntryOnStarted());

    try {
      const response = await dispatch(apiCallGetEntry);
      dispatch(updateEntryOnCompleted(response, identifier));
      return response;
    } catch (error) {
      dispatch(updateEntryOnError(error, identifier));
      dispatch(updateGlobalAppOnError(error));
    }
  };
};

export function getAllMakesPage() {
  return (dispatch) => {
    dispatch(addMakesMock());
    dispatch(addAllmarke());
  };
}

/**
 * @typedef {Object} FilterObject
 * @property {string} select Select parameter; to select specific fields
 * @property {string} fields.makePath The Make Page path; used by model and variant requests
 * @property {string} fields.modelPath The Model Page path; used by variant requests only
 */

/**
 * Get Seo page
 * @param {string} params.path
 * @param {string} params.space
 * @param {string} params.base
 * @param {boolean} params.isPreviewContentful
 * @param {string} params.contentType
 * @param {boolean} params.isStaging
 * @param {number} params.include
 * @param {FilterObject} params.filterObject
 * @returns {*}
 */
export function getSeoPage(
  path,
  space,
  base,
  contentType,
  isPreviewContentful = false,
  isStaging = false,
  include = 4,
  filterObject = {},
) {
  const queryObj = {
    content_type: contentType,
    'fields.path': path,
    include,
    ...filterObject,
  };

  const apiCallGetPage = getEntries(
    queryObj,
    space,
    base,
    isPreviewContentful,
    isStaging,
  );

  const makePath =
    contentType === MAKE_PAGE ? path : queryObj['fields.makePath'];
  const modelPath =
    contentType === MODEL_PAGE ? path : queryObj['fields.modelPath'];
  const variantPath = contentType === VARIANT_PAGE ? path : null;
  const identifier = generateStateIdentifier({
    makePath,
    modelPath,
    variantPath,
  });

  return getEntryFromUrl(apiCallGetPage, identifier);
}

/**
 * @typedef {Object} FilterObject
 * @property {string} select Select parameter; to select specific fields
 * @property {string} fields.makePath The Make Page path; used by model and variant requests
 * @property {string} fields.modelPath The Model Page path; used by variant requests only
 */

/**
 * Get page
 * @param {string} params.path
 * @param {string} params.space
 * @param {string} params.base
 * @param {boolean} params.isPreviewContentful
 * @param {string} params.contentType
 * @param {boolean} params.isStaging
 * @param {number} params.include
 * @param {FilterObject} params.filterObject
 * @returns {*}
 */
export function getPage(
  path,
  space,
  base,
  isPreviewContentful = false,
  contentType = 'page',
  isStaging = false,
  include = 4,
  filterObject = {},
) {
  const queryObj = {
    content_type: contentType,
    'fields.path': path,
    include,
    ...filterObject,
  };

  if (path === 'home') {
    return (dispatch) => {
      return dispatch(addMockHome());
    };
  }

  if (path === '/auto-online-bestellen') {
    return (dispatch) => {
      return dispatch(
        addMockData('/auto-online-bestellen', autoOnlineBestellenMock),
      );
    };
  }

  if (path === '/faq') {
    return (dispatch) => {
      return dispatch(addMockData('/faq', footerFAQs));
    };
  }

  if (path === '/autokauf-online-so-funktionierts') {
    return (dispatch) => {
      return dispatch(
        addMockData(
          '/autokauf-online-so-funktionierts',
          autokaufOnlineSoFunktioniertsMock,
        ),
      );
    };
  }

  if (path === '/angebote') {
    return (dispatch) => {
      return dispatch(addMockData('/angebote', angebote));
    };
  }

  if (path === '/about') {
    return (dispatch) => {
      return dispatch(addMockData('/about', about));
    };
  }

  if (path === '/terms') {
    return (dispatch) => {
      return dispatch(addMockData('/terms', terms));
    };
  }

  if (path === '/privacy') {
    return (dispatch) => {
      return dispatch(addMockData('/privacy', privacy));
    };
  }

  if (path === '/imprint') {
    return (dispatch) => {
      return dispatch(addMockData('/imprint', imprint));
    };
  }
  if (path === '/finanzierungsratgeber') {
    return (dispatch) => {
      return dispatch(
        addMockData('/finanzierungsratgeber', finanzierungsratgeber),
      );
    };
  }

  if (path === '/contact') {
    return (dispatch) => {
      return dispatch(addMockData('/contact', contact));
    };
  }

  if (path === '/press') {
    return (dispatch) => {
      return dispatch(addMockData('/press', press));
    };
  }

  const apiCallGetPage = getEntries(
    queryObj,
    space,
    base,
    isPreviewContentful,
    isStaging,
  );

  return getEntryFromUrl(apiCallGetPage, path);
}

export const updateArticlesByCategoryOnCompleted = (response, identifier) => ({
  type: ActionTypes.CONTENTFUL_ARTICLES_BY_CATEGORY_COMPLETED,
  payload: {
    entry: response,
    identifier,
  },
});

export const updateListOfMakes = () => {
  return (dispatch) => {
    dispatch(updateArticlesByCategoryOnCompleted(makes, 'makes'));
  };
};

export const getMakePages = () => {
  return updateListOfMakes();
};

export const getArticle = () => {
  return (dispatch) => {
    return dispatch(addMockedMagazine());
  };
};

//---------------------------------------------------------------------------------------------
// --------------------------- actions for Location Pages -------------------------------------
//---------------------------------------------------------------------------------------------

export const getLocationPage = /* istanbul ignore next */ (path) => {
  // found only 4 location pages, could be more
  if (path === '/auto/gebrauchtwagen/bayern/muenchen') {
    return (dispatch) => {
      return dispatch(
        addMockData('/auto/gebrauchtwagen/bayern/muenchen', muenchen),
      );
    };
  }
  if (path === '/auto/gebrauchtwagen/hamburg') {
    return (dispatch) => {
      return dispatch(addMockData('/auto/gebrauchtwagen/hamburg', hamburg));
    };
  }
  if (path === '/auto/gebrauchtwagen/niedersachsen/hannover') {
    return (dispatch) => {
      return dispatch(
        addMockData('/auto/gebrauchtwagen/niedersachsen/hannover', hannover),
      );
    };
  }
  if (path === '/auto/gebrauchtwagen/sachsen/leipzig') {
    return (dispatch) => {
      return dispatch(
        addMockData('/auto/gebrauchtwagen/sachsen/leipzig', leipzig),
      );
    };
  }
};

//---------------------------------------------------------------------------------------------
// ------------------------ actions for jobs  -------------------------------------------------
//---------------------------------------------------------------------------------------------

export const updateJobsOnStarted = () => ({
  type: ActionTypes.CONTENTFUL_GET_JOBS_STARTED,
});

export const updateJobsOnCompleted = (response) => ({
  type: ActionTypes.CONTENTFUL_GET_JOBS_COMPLETED,
  payload: response,
});

export const updateJobsOnError = (error) => ({
  type: ActionTypes.CONTENTFUL_GET_JOBS_FAILED,
  payload: { error },
});

export function getJobs(base) {
  let url = '/greenhouse';

  if (base) {
    url = `${base}${url}`;
  }

  return (dispatch) => {
    dispatch(updateJobsOnStarted());

    return dispatch(apiCall(url, {}, false))
      .then((response) => dispatch(updateJobsOnCompleted(response)))
      .catch((error) => dispatch(updateJobsOnError(error)));
  };
}

//---------------------------------------------------------------------------------------------
// ------------------------ actions for magazine category page ---------------------------------
//---------------------------------------------------------------------------------------------

export const updateArticlesByCategoryOnStarted = () => ({
  type: ActionTypes.CONTENTFUL_ARTICLES_BY_CATEGORY_STARTED,
});

export const updateArticlesByCategoryOnError = (error, identifier) => ({
  type: ActionTypes.CONTENTFUL_ARTICLES_BY_CATEGORY_FAILED,
  payload: {
    identifier,
    error,
  },
});

const updateArticlesByCategory = (apiCallGetEntries, identifier) => {
  return (dispatch) => {
    dispatch(updateArticlesByCategoryOnStarted());

    return dispatch(apiCallGetEntries)
      .then((response) =>
        dispatch(updateArticlesByCategoryOnCompleted(response, identifier)),
      )
      .catch((error) => {
        dispatch(updateArticlesByCategoryOnError(error, identifier));
      });
  };
};

export const getArticlesByCategory = (path, space, base, isDraft = false) => {
  const category = path.replace('/magazine/category/', '');
  const queryObj = {
    content_type: 'articlePage',
    'fields.articleCategory.sys.contentType.sys.id': 'category',
    'fields.articleCategory.fields.categoryNameSlug': category,
    limit: 20,
    include: 4,
  };
  return null;
  // const apiCallGetEntries = getEntries(queryObj, space, base, isDraft);

  // return updateArticlesByCategory(apiCallGetEntries, category);
};

//---------------------------------------------------------------------------------------------
// -------------------- actions get different types of articles ---------------------------
//---------------------------------------------------------------------------------------------

const typesOnStart = {
  featured: ActionTypes.CONTENTFUL_FEATURED_ARTICLES_STARTED,
  latest: ActionTypes.CONTENTFUL_ARTICLES_BY_LATEST_STARTED,
  category: ActionTypes.CONTENTFUL_NEWS_BY_CATEGORY_STARTED,
  categories: ActionTypes.CONTENTFUL_MAGAZINE_CATEGORIES_STARTED,
};

export const updateArticlesOnStarted = (type, featuredCategory) => ({
  type: typesOnStart[type],
  payload: { featuredCategory },
});

const typesOnCompleted = {
  featured: ActionTypes.CONTENTFUL_FEATURED_ARTICLES_COMPLETED,
  latest: ActionTypes.CONTENTFUL_ARTICLES_BY_LATEST_COMPLETED,
  category: ActionTypes.CONTENTFUL_NEWS_BY_CATEGORY_COMPLETED,
  categories: ActionTypes.CONTENTFUL_MAGAZINE_CATEGORIES_COMPLETED,
};

export const updateArticlesOnCompleted = (
  response,
  type,
  featuredCategory,
) => ({
  type: typesOnCompleted[type],
  payload: {
    entry: response,
    featuredCategory,
  },
});

const typesOnError = {
  featured: ActionTypes.CONTENTFUL_FEATURED_ARTICLES_FAILED,
  latest: ActionTypes.CONTENTFUL_ARTICLES_BY_LATEST_FAILED,
  category: ActionTypes.CONTENTFUL_NEWS_BY_CATEGORY_FAILED,
  categories: ActionTypes.CONTENTFUL_MAGAZINE_CATEGORIES_FAILED,
};

export const updateArticlesOnError = (error, type, featuredCategory) => ({
  type: typesOnError[type],
  payload: {
    error,
    featuredCategory,
  },
});

const updateArticles = () => {
  return null;
};

export const getFeaturedArticles = () => {
  return (dispatch) => {
    dispatch(addMockFeaturedArticles());
  };
};

export const getHomeContentful = () => {
  return (dispatch) => {
    dispatch(addMockFeaturedArticles());
  };
};

export const getArticlesByLatest = () => {
  return (dispatch) => {
    // dispatch(mockLatestArticlesMock());
  };
};

export const getNewsByCategory = (space, base, featuredCategory) => {
  const queryObj = {
    content_type: 'articlePage',
    'fields.articleCategory.sys.contentType.sys.id': 'category',
    'fields.articleCategory.fields.categoryNameSlug': featuredCategory,
    order: 'sys.createdAt',
    limit: 4,
    include: 4,
  };

  const apiCallGetEntries = getEntries(queryObj, space, base);

  return updateArticles(apiCallGetEntries, 'category', featuredCategory);
};

const updateCategories = () => {
  return (dispatch) => {
    dispatch(addMockedMagazine());
  };
};

export const getCategories = (space, base) => {
  const queryObj = {
    content_type: 'category',
  };

  const apiCallGetEntries = getEntries(queryObj, space, base);

  return updateCategories(apiCallGetEntries, space, base);
};

export const getAllArticles = /* istanbul ignore next */ (space, base) => {
  return (dispatch) =>
    Promise.all([
      dispatch(getFeaturedArticles(space, base)),
      dispatch(getArticlesByLatest(space, base)),
      dispatch(getCategories(space, base)),
    ]);
};

export const getCampaignHeroFromContentful = (baseUrl) => (dispatch) => {
  const queryObj = {
    content_type: 'campaignHero',
  };
  dispatch({
    type: ActionTypes.GET_CAMPAIGN_HERO_STARTED,
  });

  return dispatch(getEntries(queryObj, CONTENTFUL_HEYCAR, baseUrl))
    .then((response) =>
      dispatch({
        type: ActionTypes.GET_CAMPAIGN_HERO_COMPLETED,
        payload: response[0]?.fields,
      }),
    )
    .catch((error) =>
      dispatch({
        type: ActionTypes.GET_CAMPAIGN_HERO_FAILED,
        payload: error,
      }),
    );
};

export const getUspsFromContentful = (baseUrl) => (dispatch) => {
  const queryObj = {
    content_type: 'LpUsps',
  };
  dispatch({
    type: ActionTypes.GET_USPS_STARTED,
  });

  return dispatch(getEntries(queryObj, CONTENTFUL_HEYCAR, baseUrl))
    .then((response) =>
      dispatch({
        type: ActionTypes.GET_USPS_COMPLETED,
        payload: response[0]?.fields,
      }),
    )
    .catch((error) =>
      dispatch({
        type: ActionTypes.GET_USPS_FAILED,
        payload: error,
      }),
    );
};

export const finishLoading = () => ({ type: ActionTypes.FINISH_LOADING });

//---------------------------------------------------------------------------------------------
// ------------------------     actions get warranty seal data   ------------------------------
//---------------------------------------------------------------------------------------------

export const getWarrantySealData = (id) => (dispatch) => {
  const queryObj = {
    content_type: 'usedCarSeals',
    'fields.sealId': id,
  };

  if (!id) {
    /* istanbul ignore next */
    return dispatch({
      type: ActionTypes.FAILURE_WARRANTYSEALDATA_REQUEST,
      payload: { message: 'invalid id or used car seal does not exist' },
    });
  }

  dispatch({
    type: ActionTypes.CONTENTFUL_GET_JOBS_STARTED,
  });

  return dispatch(getEntries(queryObj, CONTENTFUL_HEYCAR))
    .then((response) =>
      dispatch({
        type: ActionTypes.SUCCESS_WARRANTYSEALDATA_REQUEST,
        payload: response,
      }),
    )
    .catch((error) =>
      dispatch({
        type: ActionTypes.FAILURE_WARRANTYSEALDATA_REQUEST,
        payload: error,
      }),
    );
};

//---------------------------------------------------------------------------------------------
// ------------------------     actions get active campaign data   ------------------------------
//---------------------------------------------------------------------------------------------
export function getCampaigns() {
  return (dispatch) => {
    dispatch(addMockData('campaigns', campaignsMock));
  };
}

//---------------------------------------------------------------------------------------------
// ------------------------     actions get active seo data   ------------------------------
//---------------------------------------------------------------------------------------------
export function getSeoTexts() {
  return (dispatch) => {
    dispatch(addMockData('homeSeoTexts', homeSeoTexts));
  };
}

//---------------------------------------------------------------------------------------------
// -------------------    gets latest articles related to electric vehicles -------------------
//---------------------------------------------------------------------------------------------

export const getArticlesByElectricalCategory = () => {
  return (dispatch) => {
    dispatch(addMockData('electricalArticles', electricalArticles));
  };
};

//---------------------------------------------------------------------------------------------
// ------------------------     actions get active seo data   ------------------------------
//---------------------------------------------------------------------------------------------
export const getFaq = (
  id,
  extra = {
    content_type: 'Section',
    include: 10,
  },
) => async (dispatch) => {
  dispatch({ type: ActionTypes.GET_FAQ });

  const queryObj = {
    'sys.id': id,
  };
  if (extra) {
    queryObj.content_type = extra.content_type;
    queryObj.include = extra.include; // version
  }

  try {
    if (queryObj['sys.id'] === '4G7CEN6JfOouI7goINqkf7') {
      dispatch({ type: ActionTypes.GET_FAQ_SUCCESS, payload: faq1 });
    }
    if (queryObj['sys.id'] === '4GiUxUd9afAnIq3dtwisQo') {
      dispatch({ type: ActionTypes.GET_FAQ_SUCCESS, payload: faq2 });
    }
    if (queryObj['sys.id'] === '2gyox8uaKfuYhoRnlnHlsT') {
      dispatch({ type: ActionTypes.GET_FAQ_SUCCESS, payload: faq3 });
    }
    if (queryObj['sys.id'] === '5MZKwWbr47Gio9r65JpHG4') {
      dispatch({ type: ActionTypes.GET_FAQ_SUCCESS, payload: faq4 });
    }
    if (queryObj['sys.id'] === '7dRiuuba8qiR1Rd407xwnF') {
      dispatch({ type: ActionTypes.GET_FAQ_SUCCESS, payload: faq5 });
    }
    if (queryObj['sys.id'] === '4QfEDSd4NuR61V2Dm0o80e') {
      dispatch({ type: ActionTypes.GET_FAQ_SUCCESS, payload: faq6 });
    }

    if (queryObj['sys.id'] === '4goQcXgZrOk33JKmsDqBAT') {
      dispatch({ type: ActionTypes.GET_FAQ_SUCCESS, payload: faq7 });
    }
    if (queryObj['sys.id'] === '2l0tEhNwDqbhJqyH3Ybdr2') {
      dispatch({ type: ActionTypes.GET_FAQ_SUCCESS, payload: faq8 });
    }
    if (queryObj['sys.id'] === '38O1dgIVc94MA0Mz4pRz7N') {
      dispatch({ type: ActionTypes.GET_FAQ_SUCCESS, payload: faq9 });
    }
    if (queryObj['sys.id'] === '7mJY9D4hLZ2jMrkGKTBZkq') {
      dispatch({ type: ActionTypes.GET_FAQ_SUCCESS, payload: faq10 });
    }
  } catch (error) {
    dispatch({ type: ActionTypes.GET_FAQ_ERROR, payload: error });
  }
};

export const getD2cExtras = (id) => (dispatch) => {
  const response = id === '1AAkcCVcbvJ8W5oj0e9M4Q' ? d2cExtras : imExtras;

  try {
    dispatch({ type: ActionTypes.GET_D2C_EXTRAS_SUCCESS, payload: response });
  } catch (error) {
    dispatch({ type: ActionTypes.GET_D2C_EXTRAS_ERROR, payload: error });
  }
};

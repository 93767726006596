import PropTypes from 'prop-types';
import { IndeterminateLoadingSpinner } from 'app/shared/ui';

import { backdropsShape } from 'app/shared/utils/propTypes';

import { FlexContainerSt } from '../common.css';

import { ButtonSt } from './InputSubmitBox.css';

export const STATUS = {
  INITIAL: 'initial',
  PENDING: 'pending',
  ERROR: 'error',
  SUCCESS: 'success',
};

export const InputSubmitBox = ({
  status,
  ariaButtonLabel,
  backdrop,
  children,
}) => {
  const isLoading = status === STATUS.PENDING;
  /* eslint-disable no-nested-ternary */
  const icon =
    status === STATUS.SUCCESS
      ? 'check'
      : status === STATUS.ERROR
      ? 'attention'
      : 'arrow-right-short';
  /* eslint-enable no-nested-ternary */
  const disabled = status === STATUS.SUCCESS || status === STATUS.PENDING;

  return (
    <FlexContainerSt>
      {children}
      <ButtonSt
        type="submit"
        variant="primary"
        disabled={disabled}
        backdrop={backdrop}
        ariaLabel={ariaButtonLabel}
      >
        {isLoading ? (
          <IndeterminateLoadingSpinner type="secondary" />
        ) : (
          <i className={`if-icon-${icon}`} />
        )}
      </ButtonSt>
    </FlexContainerSt>
  );
};

InputSubmitBox.propTypes = {
  onSubmit: PropTypes.func,
  status: PropTypes.string,
  ariaButtonLabel: PropTypes.string,
  backdrop: backdropsShape,
  children: PropTypes.any,
};

InputSubmitBox.defaultProps = {
  status: STATUS.INITIAL,
  ariaButtonLabel: undefined,
  backdrop: undefined,
};

import { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Title, Container, ListContainer, ListItem } from './List.css';
import { TextWithIcon } from 'app/marketplace/shared/components/TextWithIcon/TextWithIcon';

export class List extends PureComponent {
  render() {
    const { title, list } = this.props;

    return (
      <Container {...this.props}>
        {title && <Title {...this.props}>{title}</Title>}
        <ListContainer>
          {list.map((item, key) => (
            <ListItem key={`TextWithIcon_${key}`}>
              <TextWithIcon {...this.props} text={item} />
            </ListItem>
          ))}
        </ListContainer>
      </Container>
    );
  }
}

List.propTypes = {
  title: PropTypes.string,
  list: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.string),
    PropTypes.arrayOf(PropTypes.object)
  ]).isRequired,
  withColorBlock: PropTypes.bool,
  themeName: PropTypes.oneOf([
    'brandPrimary',
    'brandSecondary',
    'white',
    'grayLightest'
  ])
};

List.defaultProps = {
  themeName: 'brandSecondary',
  withColorBlock: true
};

import styled, { css } from 'styled-components';

import { ListingsContainer } from 'app/marketplace/shared/components/ListingsContainer/ListingsContainer';
import { ListingsContainerModal } from 'app/marketplace/shared/components/ListingsContainerModal/ListingsContainerModal';
import { color, zIndex, media } from 'app/shared/styles_js/variables';

export const getContainerStyles = ({ variant }) => {
  if (variant === 'sidebar') {
    return css`
      padding: 48px 24px 24px;
    `;
  }

  if (variant === 'modal') {
    return css`
      padding: 32px 0;
      margin-top: 16px;
      ${media.md(css`
        padding: 48px;
      `)}
    `;
  }
  return null;
};

export const getTitleStyles = ({ variant, hasHeadline }) => {
  const commonCss = css`
    position: absolute;
    top: -8px;
    height: 4px;
    background-color: ${color.brandPrimary};
    content: '';
  `;
  if (variant === 'sidebar' && hasHeadline) {
    return css`
      &::before {
        left: 0;
        width: 40px;
        ${commonCss}
      }
    `;
  }
  if (variant === 'modal') {
    return css`
      margin-right: 24px;
      margin-left: 24px;
      text-align: left;

      ${media.md(css`
        text-align: center;
      `)}

      ${hasHeadline &&
      css`
        &::before {
          left: 0;
          width: 45px;
          ${commonCss}
        }

        ${media.md(css`
          &::before {
            left: calc(50% - 23px);
          }
        `)}
      `};
    `;
  }
  return null;
};

export const MoreLikeThisSt = styled.div`
  position: relative;
  z-index: ${zIndex.moreLikeThis}; /* Here to make the sticky block in the PDP hide behind this */
  width: 100%;
  padding-top: 48px;
  padding-bottom: 24px;
  background-color: ${({ theme: { colors } }) => colors.sunbeanSuperLight};
  ${getContainerStyles}
`;

export const TitleSt = styled.div`
  position: relative;
  margin-bottom: 40px;
  font-size: 20px;
  font-weight: 500;
  ${getTitleStyles}
`;

const mainListingsWrapperCss = css`
  display: flex;
  flex-wrap: wrap;
`;

export const ListingsContainerModalSt = styled(ListingsContainerModal)`
  ${mainListingsWrapperCss}
`;

export const ListingsContainerSt = styled(ListingsContainer)`
  margin: ${({ variant }) => (variant === 'modal' ? '0px' : '0 -24px')};
  ${mainListingsWrapperCss}
`;

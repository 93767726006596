/* eslint-disable react/display-name */
import {
  Heading3,
  Heading4,
  Heading5,
  Link,
  Text1,
  Text4,
} from 'app/shared/ui';
import { getContent } from 'app/marketplace/contentful/utils';

import { PageBreak } from './PrintableVoucherCode.css';

/**
 * Get printable voucher screen content with dynamic values
 *
 * @param {Array} campaigns The array of all campaigns (from `Campaign` content model on Contentful)
 * @param {String} campaignId The id of the campaign (from `Id` field in Campaign content model on Contentful)
 * @param {String} payload The payload coming from 'location' prop (related to url)
 * @returns {Object|false} Returns either data with description and/or disclaimer properties or false
 */
export const getPrintableVoucherScreenContent = (
  campaigns,
  campaignId,
  payload,
) => {
  try {
    const result = campaigns?.find((item) => item?.fields?.id === campaignId);
    const content = result?.fields?.printableVoucherScreen;
    if (!content) return false;

    const data = JSON.parse(Buffer.from(payload, 'base64').toString('utf8'));
    const {
      createdAt,
      dealerName,
      userFirstName,
      vehicleMake,
      vehicleModel,
      voucherCode,
      voucherAmount,
    } = data;

    // Printable Voucher Content (Markdown)
    const markdownContainerContent = getContent(content, 'markdownContainer');

    // Disclaimer Content (LegalText)
    const disclaimerContent = getContent(content, 'LegalText');

    const date = new Date(createdAt);

    const dynamicMarkdownContent = markdownContainerContent?.fields?.content
      .replace(/{CREATED_AT}/g, date.toLocaleDateString('de-DE'))
      .replace(/{DEALER_NAME}/g, dealerName)
      .replace(/{USER_FIRST_NAME}/g, userFirstName)
      .replace(/{VEHICLE_MAKE}/g, vehicleMake)
      .replace(/{VEHICLE_MODEL}/g, vehicleModel)
      .replace(/{VOUCHER_CODE}/g, voucherCode)
      .replace(/{VOUCHER_VALUE}/g, voucherAmount);

    return {
      description: dynamicMarkdownContent,
      disclaimer: disclaimerContent?.fields?.text,
    };
  } catch (error) {
    return false;
  }
};

const sharedOverrides = {
  a: {
    component: ({ href, ...rest }) => {
      const useRouter = href.startsWith('/');
      return <Link to={href} useRouter={useRouter} {...rest} />;
    },
    props: {
      target: '_blank',
    },
  },
};

export const descriptionMarkdownOptions = {
  overrides: {
    ...sharedOverrides,
    p: {
      component: Text1,
    },
    h3: {
      component: Heading3,
    },
    h4: {
      component: Heading4,
    },
    h5: {
      component: Heading5,
    },
    br: {
      component: PageBreak,
    },
  },
};

export const disclaimerMarkdownOptions = {
  overrides: {
    ...sharedOverrides,
    p: {
      component: (props) => <Text4 as="p" color="grayDark" {...props} />,
    },
  },
};

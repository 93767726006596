import { PureComponent } from 'react';
import PropTypes from 'prop-types';

import { trackAction } from 'app/shared/utils/tracking';
import { NEWSLETTER_SOURCE } from 'app/marketplace/crm/constants';
import {
  InputSubmitBox,
  STATUS,
} from 'app/shared/components/InputSubmitBox/InputSubmitBox';

import { InputSt, MessageSt, SignupFormSt } from './SignupForm.css';

const defaultState = {
  formStatus: STATUS.INITIAL,
  formData: {
    email: '',
    source: NEWSLETTER_SOURCE.footer,
  },
  messageText: '',
};

export default class SignupForm extends PureComponent {
  state = defaultState;

  onFormSubmit = (e) => {
    e.preventDefault();

    this.trackSubmit().init();
    return this.submit();
  };

  submit = () => {
    this.setState({ formStatus: STATUS.PENDING });

    return this.props
      .sendForm(this.state.formData)
      .then(() => {
        this.trackSubmit().success();
        return this.setState({
          formStatus: STATUS.SUCCESS,
          messageText:
            'Wir haben dir eine E-Mail geschickt. Bitte bestätige deine E-Mail-Adresse!',
        });
      })
      .catch(error => {
        this.trackSubmit().fail();
        // handle case for existing user email id
        if (error?.status === 409) {
          return this.setState({
            formStatus: STATUS.ERROR,
            messageText: 'Der Benutzer existiert bereits.',
          });
        }
        return this.setState({
          formStatus: STATUS.ERROR,
          messageText: 'Bitte gib eine gültige E-Mail Adresse ein.',
        });
      });
  };

  onInputChange = (e) => {
    const { formData } = this.state;
    const email = e.target.value;

    this.setState({ formData: { ...formData, email } });
  };

  onInputFocus = () => {
    trackAction('footer_newsletter_input_focus', {
      category: 'newsletter_footer',
      description: 'user clicks email input field in footer',
      label: 'newsletter_signup',
    });
  };

  trackSubmit = () => ({
    init: () =>
      trackAction('footer_newsletter_submit_initialized', {
        category: 'newsletter_footer',
        description: 'user submits email address in footer',
        label: 'newsletter_signup',
      }),
    success: () =>
      trackAction('footer_newsletter_submit_successful', {
        category: 'newsletter_footer',
        description: 'user successfully submits email address in footer',
        label: 'newsletter_signup',
      }),
    fail: () =>
      trackAction('footer_newsletter_submit_failed', {
        category: 'newsletter_footer',
        description: 'user fails to submit email address in footer',
        label: 'newsletter_signup',
      }),
  });

  render() {
    const { ariaInputLabel, ariaButtonLabel } = this.props;
    const { formStatus, messageText } = this.state;

    return (
      <SignupFormSt>
        <form onSubmit={this.onFormSubmit} noValidate>
          <InputSubmitBox
            status={formStatus}
            ariaButtonLabel={ariaButtonLabel}
            backdrop="micaBlue"
          >
            <InputSt
              autoComplete="email"
              aria-label={ariaInputLabel}
              placeholder="E-Mail-Adresse"
              onChange={this.onInputChange}
              onFocus={this.onInputFocus}
            />
          </InputSubmitBox>
          {messageText && (
            <div style={{ marginBottom: '10px' }}>
              <MessageSt status={formStatus}>{messageText}</MessageSt>
            </div>
          )}
        </form>
      </SignupFormSt>
    );
  }
}

SignupForm.propTypes = {
  sendForm: PropTypes.func.isRequired,
  ariaButtonLabel: PropTypes.string,
  ariaInputLabel: PropTypes.string,
};

SignupForm.defaultProps = {
  ariaButtonLabel: undefined,
  ariaInputLabel: undefined,
};

import React, { useState, useEffect } from 'react';
import { ToggleSwitchExtended } from 'app/shared/ui';
import { Box, Flex } from '@rebass/grid';

import { getFilterObject } from 'app/marketplace/filter/utils';
import { CATEGORY } from 'app/marketplace/filter/Filters/Filter.constant';

import { Disclaimer } from './Disclaimer/Disclaimer';
import { getFinancingOption } from './helpers';
import { FILTERS, PRICE_MARGIN, PURCHASING_OPTION } from './constants';
import {
  TFinanceFilterProps,
  IselectedFinancingOption,
  EFinancingCategorySearch,
} from './Types';
import { useFirstMount } from './hooks';
import { FinanceSlider } from './FinanceSlider/FinanceSlider';

export const FinancingOptionsFilter = ({
  onChangeFilter,
  trackPriceChange,
  trackFinancingOptionChange,
  filter,
  showSliderWithInput,
}: TFinanceFilterProps): JSX.Element => {
  const labels = ['Kaufen', 'Finanz.', 'Leasen'];
  const [isDragging, setIsDragging] = useState<boolean>(false);
  const [activeOptionIndex, setActiveOption] = useState<number>(
    filter?.category ? EFinancingCategorySearch[filter.category] : 0,
  );
  const [selectedPrice, setSelectedPrice] = useState(filter?.value || '');
  const [
    selectedFinancingOption,
    setSelectedFinancingOption,
  ] = useState<IselectedFinancingOption>(PURCHASING_OPTION);

  const isFirstMount = useFirstMount();

  const {
    min,
    max,
    searchCategory,
    trackingActionName,
    disclaimer,
    hasShowMoreFeature,
  } = selectedFinancingOption;

  /**
   * Get selected financing option when the user changes the active option in ToggleSwitchExtended component.
   * TODO: we can get rid of this useEffect by updating `ToggleSwitchExtended` component in heycar/ui with adding onClick/onChange event.
   */
  useEffect(() => {
    // isFirstMount flag for checking the price value from the URL.
    if (isFirstMount) {
      setSelectedPrice(filter?.value || '');
    } else {
      setSelectedPrice('');
    }
    setSelectedFinancingOption(getFinancingOption(activeOptionIndex));
  }, [activeOptionIndex]);

  /**
   * Fire Tracking event only when the user changes the financing option.
   */
  useEffect(() => {
    if (!isFirstMount) trackFinancingOptionChange(trackingActionName);
  }, [selectedFinancingOption]);

  /**
   * Reset selected financing option and selected price when user clears the selected filters.
   */
  useEffect(() => {
    if (filter === undefined) {
      setActiveOption(0);
      setSelectedPrice('');
    } else {
      setActiveOption(EFinancingCategorySearch[filter.category]);
      setSelectedPrice(filter.value);
    }
  }, [filter]);

  /**
   * that's for keeping track of any changes related to selected financing option and selected price
   *  so we can fire action to update the global filters with the new changes.
   */
  useEffect(() => {
    let price = selectedPrice;
    let searchFilter;
    const isSearchWithPriceMargin = searchCategory === 'priceRange';
    if (price.split('-').length === 1) {
      price = `${min}-${price.split('-')[0] ? selectedPrice : max}`;
    }

    /**
     * we have a special case when the selected financing option is priceRange and selected price equals to max value
     * in this case we will clear the filters to show all vehicles count
     */
    if (
      (selectedPrice === `${max}` && searchCategory === 'priceRange') ||
      (selectedPrice === `${min}-${max}` && searchCategory === 'priceRange') ||
      (selectedPrice === '' && searchCategory === 'priceRange')
    ) {
      searchFilter = {};
    } else {
      searchFilter = {
        priceMargin: isSearchWithPriceMargin
          ? [
            getFilterObject({
              category: 'priceMargin',
              value: PRICE_MARGIN,
            }),
          ]
          : [],
        [searchCategory]: [
          getFilterObject({
            category: searchCategory,
            value: price || `${min}-${max}`,
          }),
        ],
      };
    }

    /**
     * we need to avoid changing the filters on the first rendering for keeping track of the URL for the SEO
     * changing the filters will cause a change in the URL
     * ex: in the home page when user clicks on the vehicle links the url will be changed to `/gebrauchtwagen/renault/clio`
     * we need to keep it as it's for first rendering but after changing any filter like price range for example
     * the url will be changed to `/gebrauchtwagen?priceRange=7000-98000&make=renault&model=renault_clio`
     * */

    if (!isFirstMount) {
      onChangeFilter(
        {
          ...FILTERS,
          ...searchFilter,
          budgetRangeSwitch: [
            getFilterObject({
              category: 'budgetRangeSwitch',
              value:
                searchCategory === 'priceRange'
                  ? CATEGORY.priceSwitch
                  : CATEGORY.mtlSwitch,
            }),
          ],
        },
        true,
        true,
      );
    }
  }, [isDragging, selectedFinancingOption]);

  const handleOnDrag = (value: number[]) => {
    if (Array.isArray(value)) {
      setSelectedPrice(value.join('-'));
    }
  };

  const handleOnChange = ({ values }: { values: number[] }) => {
    if (Array.isArray(values)) {
      const updatedValue = values.join('-');
      setSelectedPrice(updatedValue);
      trackPriceChange(searchCategory, selectedPrice);
      setIsDragging(!isDragging);
    }
  };

  const handleCustomPriceClick = (value: string) => {
    setSelectedPrice(value);
    trackPriceChange(searchCategory, value);
  };

  return (
    <Box width={1}>
      <Box mb={12}>
        <ToggleSwitchExtended
          labels={labels}
          activeOptionIndex={activeOptionIndex}
          setActiveOption={setActiveOption}
        />
      </Box>

      <Box>
        <Flex flexDirection="column" width={1} mt={[12, 8, 8, 12]}>
          <FinanceSlider
            withInput={showSliderWithInput}
            onCustomPriceClick={handleCustomPriceClick}
            selectedPrice={selectedPrice}
            selectedFinancingOption={selectedFinancingOption}
            onDrag={handleOnDrag}
            onChange={handleOnChange}
          />
        </Flex>
      </Box>

      {disclaimer && (
        <Box>
          <Disclaimer
            disclaimer={disclaimer}
            hasShowMoreFeature={hasShowMoreFeature}
          />
        </Box>
      )}
    </Box>
  );
};

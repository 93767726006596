import PropTypes from 'prop-types';
import { Box } from '@rebass/grid';

import { ContainerSt, OuterBoxSt, ProgressBarSt } from './ProgressBar.css';

export const ProgressBar = ({ currentStep, totalSteps }) => {
  return (
    <Box css={ContainerSt} width={1}>
      <Box css={OuterBoxSt} width={1}>
        <Box css={ProgressBarSt} width={currentStep / totalSteps} />
      </Box>
    </Box>
  );
};

ProgressBar.propTypes = {
  currentStep: PropTypes.number.isRequired,
  totalSteps: PropTypes.number.isRequired,
};

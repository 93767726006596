import { PureComponent } from 'react';
import PropTypes from 'prop-types';

import { ItemSt } from './DefaultItemRenderer.css';

export class DefaultItemRenderer extends PureComponent {
  constructor(props) {
    super(props);

    // used to differentiate between scrolling and touching
    this.isClick = null;
    this.onClick = this.onClick.bind(this);
  }

  onClick(e, index) {
    this.props.onSelect(this.props.itemObjects[index], index, e);
  }

  render() {
    const { itemObjects, inputValueExtractor, focused } = this.props;

    return itemObjects.map((itemObject, index) => {
      const inputValue = inputValueExtractor(itemObject);

      return (
        <ItemSt
          focused={index === focused}
          key={index}
          // register onMouseDown/onTouchStart, because they fire before onBlur of the parent element
          onMouseDown={e => {
            this.onClick(e, index);
          }}
          onTouchStart={() => {
            this.isClick = true;
          }}
          onTouchMove={() => {
            this.isClick = false;
          }}
          onTouchEnd={e => {
            if (this.isClick) {
              this.onClick(e, index);
            }
          }}
        >
          {inputValue}
        </ItemSt>
      );
    });
  }
}

DefaultItemRenderer.propTypes = {
  itemObjects: PropTypes.arrayOf(PropTypes.object).isRequired,
  onSelect: PropTypes.func.isRequired,
  inputValueExtractor: PropTypes.func.isRequired,
  focused: PropTypes.number
};

DefaultItemRenderer.defaultProps = {
  itemObjects: []
};

import { put, call, takeLatest, all } from 'redux-saga/effects';

import { delay } from 'app/shared/utils/utils';
import { trackAction } from 'app/shared/utils/tracking';
import {
  syncCalculationChanges,
  getData,
  loading,
} from 'app/shared/modules/FDL/CalculatorV3/redux/actions';
import {
  CHANGE_HAPPENED,
  API_ERROR,
  SET_SELECTED_VALUE,
  SET_DATA,
} from 'app/shared/modules/FDL/CalculatorV3/redux/constants';
import { TRACKING_LIST } from 'app/shared/modules/tracking/list';

/**
 * @description gets user selected values to update calculations
 */
export function* watchCalculatorUpdate(action) {
  yield call(delay, 500); // delay for catching new request (decrease API call count)
  yield put(loading());
  yield put(getData(action?.item, true));
}

export function watchAPIError(action) {
  const { payload: { id, value } = {} } = action || {};
  trackAction(TRACKING_LIST.FINANCING.CALCULATOR.ERROR, {
    category: 'fdl',
    label: id || 'unknown',
    value: value || null,
  });
}

/**
 * @description gets calculation result and updates calculator
 */
export function* watchCalculationUpdate() {
  yield put(syncCalculationChanges());
}

export function* calculatorSagas() {
  yield all([
    takeLatest([CHANGE_HAPPENED, SET_SELECTED_VALUE], watchCalculatorUpdate),
    takeLatest(API_ERROR, watchAPIError),
    takeLatest(SET_DATA, watchCalculationUpdate),
  ]);
}

import { createSelector } from 'reselect';

import {
  getVehicleSelector,
  getPriceRangeSelector,
} from 'app/marketplace/search/selectors';
import { getPreviousLocation } from 'app/selectors';

import { PAGINATION_SIZE } from '../../config';

export function getVehicle(state) {
  return state.vehicle;
}

export const getVehiclePending = ({ vehicle }) => vehicle.pending;

export function getAutomatedListings(state) {
  return state.vehicle.oneClickLeads?.automatedListings || [];
}

export function getSelectableListings(state) {
  return state.vehicle.oneClickLeads?.selectableListings || [];
}

export function getOneClickLeadPills(state) {
  return state.vehicle.oneClickLeads?.pills || [];
}

export function getRecommendationCriteriaCondition(state) {
  return state.vehicle.oneClickLeads?.condition;
}

export const getVehicleObjectSelector = (state) => state.vehicle.vehicle;

export function getSearchVehicles(state) {
  return getVehicleSelector(state);
}

export function getPriceRange(state) {
  return getPriceRangeSelector(state);
}

export function getCurrentVehicleId(state) {
  return state.vehicle.vehicle.id;
}

export function getCurrentVehicleDealerId(state) {
  return state.vehicle?.vehicle?.dealer?.id;
}

export function getCurrentVehicleMakeId(state) {
  return state.vehicle?.vehicle?.make?.id;
}

export function getVehicleQualityCertificate(state) {
  return state.vehicle?.vehicle?.qualityCertificate;
}

export function getCurrentVehiclePrice(state) {
  const { vehicle } = state.vehicle;
  return vehicle.price;
}

export function getCurrentPage(state) {
  return state.filter.page[0]?.value;
}

export function getVehicleNumber(currentIndex, currentPage) {
  return currentPage * PAGINATION_SIZE + currentIndex + 1;
}

export const selectCurrentVehicleIndex = createSelector(
  getSearchVehicles,
  getCurrentVehicleId,
  (vehicles, vehicleId) => {
    return vehicles.findIndex((v, index) => v.id === vehicleId);
  },
);

export const selectCurrentPage = createSelector(getCurrentPage, (page) => page);

export const selectCurrentVehicleNumber = createSelector(
  [selectCurrentVehicleIndex, getCurrentPage],
  (index, page) => getVehicleNumber(index, page),
);

export const selectHasSearchVehicles = createSelector(
  [getVehicleSelector, selectCurrentVehicleNumber, getPreviousLocation],
  (searchVehicles, currentVehicleNumber, previousLocation) =>
    searchVehicles.length > 0 &&
    currentVehicleNumber > 0 &&
    /\/gebrauchtwagen|\/vehicle/.test(previousLocation),
);

export const vehicleImagesSelector = createSelector(
  ({ vehicle }) => vehicle.vehicle.images,
  (images) => images || [],
);

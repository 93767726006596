/* istanbul ignore file */
/* eslint-disable react/jsx-props-no-spreading */
import { memo, useEffect, useLayoutEffect, useRef, useState } from 'react';
import _isEmpty from 'lodash.isempty';

import LoadingSpinner from 'app/shared/modules/Campaign/GAM/GamBanner/LoadingSpinner';
import { useHasMounted } from 'app/shared/hooks/useHasMounted';
import { useInterval } from 'app/shared/modules/Campaign/hooks/useInterval';
import { cachedScaleGamBanner } from 'app/shared/modules/Campaign/GAM/utils/utils';
import { trackAction } from 'app/shared/utils/tracking';
import { BannerContainer } from 'app/shared/modules/Campaign/GAM/GamBanner/GamBanner.css';
import {
  ISlotData,
  TGamBannerProps,
} from 'app/shared/modules/Campaign/GAM/GamBanner/GamBanner.types';
import { TBreakpoint } from 'app/shared/utils/utils';

const GamBanner = ({
  adUnitId,
  adUnitPath,
  sizes,
  bannerArea,
  targeting,
  fallbackComponent,
  callback,
  breakpoint, // from redux state
  trackingData: {
    clickEvent,
    impressionEvent,
    impressionViewableEvent,
    renderEvent,
  },
  markerIds,
  bannerContainerStyle,
  isAvailableToCollectDurationMetric,
  renderingStartTime,
  isScalable,
  useSmallSpinner,
  showCloseButton,
  onCloseButtonClick,
  ...rest
}: TGamBannerProps): JSX.Element => {
  const hasMounted = useHasMounted();
  const [googletagApiReady, setGoogletagApiReady] = useState<
    boolean | undefined
  >(
    typeof window !== 'undefined' && window?.googletag
      ? googletag?.apiReady
      : undefined,
  );
  const [slot, setSlot] = useState<googletag.Slot | null>(null);
  const [slotData, setSlotData] = useState<ISlotData>({});
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [shouldRenderFallback, setShouldRenderFallback] = useState<boolean>(
    false,
  );
  const slotDataRef = useRef<ISlotData>({});

  /**
   * Watch the `googletag.apiReady` property
   *
   * GPT executes its logic asynchronously and hence the googletag.apiReady may not be available even though
   * the component has mounted. To avoid `undefined` case, this watcher is for checking it repeatedly until
   * it gets defined.
   */
  const [clearIntervalFn] = useInterval(
    () => {
      if (
        typeof window !== 'undefined' &&
        window?.googletag &&
        googletag?.apiReady
      ) {
        setGoogletagApiReady(true);
        clearIntervalFn();
      }
    },
    10,
    (window.googletag && googletag?.apiReady) || googletagApiReady,
  );

  /**
   * Handle the banner image click
   */
  const onBannerImageClick = () => {
    if (!_isEmpty(slotData)) {
      const {
        eventName,
        placement,
        category,
        label,
        variantFlagId,
      } = clickEvent;
      const { orderId, lineItemId, creativeId } = slotData;

      if (eventName) {
        trackAction(
          eventName,
          {
            value: markerIds || '',
            category,
            label,
          },
          {
            banner_source: 'GAM',
            page: placement,
            order_id: orderId,
            line_item_id: lineItemId,
            creative_id: creativeId,
            ...(variantFlagId && { variantFlagId }),
          },
        );
      }
    }
  };

  const handleCloseButtonClick = (gamData: ISlotData) => {
    if (onCloseButtonClick) {
      onCloseButtonClick(gamData);
    }
  };

  /**
   * This event is fired when the creative code is injected into a slot. This event
   * will occur before the creative's resources are fetched, so the creative may not
   * be visible yet. If you need to know when all creative resources for a slot have
   * finished loading, consider the onSlotOnload instead.
   */
  const onSlotRenderEnded = (
    event: googletag.events.SlotRenderEndedEvent,
  ): void => {
    const targetSlotElementId = event?.slot?.getSlotElementId();
    if (adUnitId === targetSlotElementId) {
      if (!event.isEmpty) {
        const { campaignId, lineItemId, creativeId } = event;
        const gamData = {
          orderId: campaignId,
          lineItemId,
          creativeId,
        };
        slotDataRef.current = gamData;

        setSlotData(gamData);
        if (callback) callback(gamData);
        if (isScalable) cachedScaleGamBanner(adUnitId);

        const { eventName, label, category } = renderEvent || impressionEvent;
        trackAction(
          eventName || 'promotions_gam_banner_load_impression',
          {
            label,
            category,
            ...(markerIds && { value: markerIds }),
          },
          {
            breakpoint,
            creativeId,
            lineItemId,
            nonInteraction: 1, // Non-interactive event. So that it does not affect Google Analytics bounce rate, etc.
            orderId: campaignId,
          },
        );

        if (showCloseButton && onCloseButtonClick) {
          const adContainerElem = document.querySelector(
            `#${adUnitId}`,
          ) as HTMLElement;
          adContainerElem?.insertAdjacentHTML(
            'beforeend',
            '<button type="button" class="closeBtn"><i class="if-icon-close"/></button>',
          );

          const closeBtn = document.querySelector(
            `#${adUnitId} .closeBtn`,
          ) as HTMLButtonElement;
          closeBtn?.addEventListener('click', () => {
            handleCloseButtonClick(gamData);
          });
        }
      } else {
        if (callback) callback();
        if (isLoading) setIsLoading(false);
        if (!shouldRenderFallback) setShouldRenderFallback(true);
      }
    }
  };

  /**
   * This event is fired when the creative's iframe fires its load event.
   */
  const onSlotOnload = (event: googletag.events.SlotOnloadEvent): void => {
    const targetSlotElementId = event?.slot?.getSlotElementId();
    if (adUnitId === targetSlotElementId) {
      if (isLoading) setIsLoading(false);

      // Collect the duration metric only if it is available
      if (isAvailableToCollectDurationMetric && renderingStartTime) {
        const renderingEndTime = performance.now();
        const renderDuration = renderingEndTime - renderingStartTime;

        trackAction(
          'promotions_gam_banner_load_duration',
          {}, // no category, label or description for now
          {
            adUnitPath,
            breakpoint,
            nonInteraction: 1, // Non-interactive event. So that it does not affect Google Analytics bounce rate, etc.
            renderDuration, // in ms
          },
        );

        if (
          window?.googletag &&
          googletag?.pubadsReady &&
          typeof googletag?.pubads?.()?.removeEventListener === 'function' // To fix: https://sentry.io/share/issue/546b84ef40d8486a833cec9ff90aaa33/
        ) {
          // Removing the `slotOnload` googletag event to avoid unnecessary resendings since the `renderDuration` metric has already been sent
          googletag.pubads().removeEventListener('slotOnload', onSlotOnload);
        }
      }
    }
  };

  const onImpressionViewable = (
    event: googletag.events.ImpressionViewableEvent,
  ): void => {
    const targetSlotElementId = event?.slot?.getSlotElementId();
    if (impressionViewableEvent && adUnitId === targetSlotElementId) {
      const { lineItemId, creativeId, orderId } = slotDataRef.current;
      const { eventName, label, category } = impressionViewableEvent;
      trackAction(
        eventName,
        {
          label,
          category,
          ...(markerIds && { value: markerIds }),
        },
        {
          breakpoint,
          creativeId,
          lineItemId,
          nonInteraction: 1, // Non-interactive event. So that it does not affect Google Analytics bounce rate, etc.
          orderId,
        },
      );
    }
  };

  /**
   * Main logic to render the banner
   */
  useLayoutEffect(() => {
    let responsiveSlot: googletag.Slot | null = null;

    if (window?.googletag && (googletag?.apiReady || googletagApiReady)) {
      googletag.cmd.push(() => {
        responsiveSlot = googletag.defineSlot(
          adUnitPath,
          [
            [0, 0],
            Object.keys(sizes).flatMap((item) =>
              sizes[item as TBreakpoint].map((size) => [
                size.width,
                size.height,
              ]),
            ),
          ].flat(1) as googletag.MultiSize,
          adUnitId,
        );

        if (responsiveSlot) {
          responsiveSlot.addService(googletag.pubads());

          const mapping = googletag
            .sizeMapping()
            .addSize(
              [1280, 0],
              sizes.xl.map((size) => [
                size.width,
                size.height,
              ]) as googletag.MultiSize,
            )
            .addSize(
              [1024, 0],
              sizes.lg.map((size) => [
                size.width,
                size.height,
              ]) as googletag.MultiSize,
            )
            .addSize(
              [768, 0],
              sizes.md.map((size) => [
                size.width,
                size.height,
              ]) as googletag.MultiSize,
            )
            .addSize(
              [320, 0],
              sizes.sm.map((size) => [
                size.width,
                size.height,
              ]) as googletag.MultiSize,
            )
            .addSize(
              [0, 0],
              sizes.sm.map((size) => [
                size.width,
                size.height,
              ]) as googletag.MultiSize,
            )
            .build();
          responsiveSlot.defineSizeMapping(mapping);

          // Initial targeting set
          if (targeting && !_isEmpty(targeting)) {
            responsiveSlot.updateTargetingFromMap(targeting);
          }

          setSlot(responsiveSlot);
        }

        if (googletag?.pubadsReady) {
          googletag
            .pubads()
            .addEventListener('slotRenderEnded', onSlotRenderEnded);
          googletag.pubads().addEventListener('slotOnload', onSlotOnload);
          googletag
            .pubads()
            .addEventListener('impressionViewable', onImpressionViewable);
        }

        if (responsiveSlot) googletag.display(adUnitId);
      });
    }

    return () => {
      if (
        responsiveSlot &&
        window?.googletag &&
        googletagApiReady &&
        googletag?.pubadsReady
      ) {
        googletag
          .pubads()
          .removeEventListener('slotRenderEnded', onSlotRenderEnded);
        googletag.pubads().removeEventListener('slotOnload', onSlotOnload);
        googletag
          .pubads()
          .removeEventListener('impressionViewable', onImpressionViewable);
        googletag.destroySlots([responsiveSlot]);
        setSlot(null);
      }
    };
  }, [googletagApiReady]);

  const refreshSlot = () => {
    if (slot && window?.googletag && googletag?.pubadsReady) {
      googletag.pubads().refresh([slot], { changeCorrelator: false });
    }
  };

  /**
   * Refresh the slot on `breakpoint` change to get relevant banner size
   */
  useEffect(() => {
    // To ignore the initial `breakpoint` value on the very first mount
    if (hasMounted) {
      refreshSlot();
    }
  }, [breakpoint]);

  /**
   * Update the custom targetings on `targeting` change and refresh the slot with the latest data
   */
  useEffect(() => {
    if (
      slot &&
      targeting &&
      !_isEmpty(targeting) &&
      hasMounted // To ignore the initial `targeting` value on the very first mount because it's already being set in the main logic `useEffect`
    ) {
      slot.clearTargeting();
      slot.updateTargetingFromMap(targeting);
      refreshSlot();
    }
  }, [JSON.stringify(targeting)]);

  /**
   * Add a click event listener to the iframe image to track the click event
   */
  useEffect(() => {
    let imgElem: HTMLImageElement | null | undefined;
    if (
      !isLoading &&
      !shouldRenderFallback &&
      !_isEmpty(slotData) &&
      document
    ) {
      const adContainerElem = document.querySelector(`#${adUnitId}`);
      const iframeElem = adContainerElem?.querySelector(
        'iframe',
      ) as HTMLIFrameElement;
      imgElem = iframeElem?.contentDocument?.querySelector('img.img_ad');
      return imgElem?.addEventListener('click', onBannerImageClick);
    }

    return () => {
      return imgElem?.removeEventListener('click', onBannerImageClick);
    };
  }, [isLoading, shouldRenderFallback, JSON.stringify(slotData)]);

  /**
   * For Firefox versions 103 and below, in PRIVATE mode, the callback events
   * (slotRenderEnded, slotOnload) listeners are not fired.
   * That's why we need to rely on the _loaded_ property to render the fallback.
   */
  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    if (hasMounted && googletagApiReady && !googletag?._loaded_) {
      if (isLoading) setIsLoading(false);
      if (!shouldRenderFallback) setShouldRenderFallback(true);
    }
  }, [googletagApiReady, hasMounted]);

  return (
    <>
      <BannerContainer
        id={adUnitId}
        $bannerArea={bannerArea}
        $isScalable={isScalable}
        $isHidden={shouldRenderFallback || isLoading}
        $bannerContainerStyle={bannerContainerStyle}
        {...rest}
      />
      {isLoading && <LoadingSpinner useSmallSpinner={useSmallSpinner} />}
      {!isLoading && shouldRenderFallback && <>{fallbackComponent}</>}
    </>
  );
};

export default memo(GamBanner);

import styled from 'styled-components';

import { color, fontSizes, zIndex } from 'app/shared/styles_js/variables';
import { PopoverSt } from 'app/shared/modules/search/SearchPane/SearchInputPopoverRenderer/SearchInputPopoverRenderer.css';

export const InputContainerSt = styled.div`
  display: flex;
  align-items: stretch;
  background-color: #fff;

  .sk-fading-circle {
    position: relative;
    z-index: 1;
    top: 0;
    left: auto;
    margin-top: 0;
    margin-left: 0;
  }

  & > ${PopoverSt} {
    z-index: ${zIndex.navbar};
  }

  ${(props) => props.extraStyles};
`;

export const LoadingSt = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  height: auto;
  align-items: center;
  justify-content: center;
  background-color: white;
  opacity: 0.7;

  & > .sk-fading-circle {
    width: 20px;
    height: 20px;
  }

  & > .sk-fading-circle > .sk-circle::before {
    width: 3px;
    height: 3px;
    background-color: ${color.brandSecondary};
    border-radius: 50%;
  }
`;

export const InputSt = styled.input`
  width: 100%;
  height: 48px;
  flex: 1;
  padding: 5px 8px;
  border: 1px solid ${({ theme }) => theme.colors.grayDark};
  color: ${({ theme: { colors } }) => colors.tarmacGrey};
  font-size: 14px;
  font-weight: 100;
  line-height: 1;

  border-radius: 4px;

  ::-webkit-search-decoration,
  ::-webkit-search-cancel-button,
  ::-webkit-search-results-button,
  ::-webkit-search-results-decoration {
    display: none;
  }

  &::placeholder {
    color: ${color.brandSecondary};
    transition: opacity 0.2s ease-in-out;
  }

  &:focus::placeholder {
    opacity: 0;
  }

  &::-ms-clear {
    display: none;
    width: 0;
    height: 0;
  }
`;

export const ErrorTextSt = styled.span`
  position: absolute;
  display: block;
  margin-top: 8px;
  font-size: ${fontSizes.default};
  color: ${({ theme: { colors } }) => colors.brandDanger};
`;

import React, { FunctionComponent } from 'react';
import {
  ErrorBoundary as SentryErrorBoundary,
  withErrorBoundary as sentryWithErrorBoundary,
} from '@sentry/react';

import { ErrorFallback } from 'app/shared/components/ErrorBoundary/ErrorFallback';

interface ErrorBoundaryProps {
  children?: JSX.ElementChildrenAttribute | JSX.Element;
  fallbackComponent?: React.ReactNode;
}

export const ErrorBoundary = ({
  children,
  fallbackComponent = ErrorFallback,
}: ErrorBoundaryProps): JSX.Element => (
  <SentryErrorBoundary fallback={fallbackComponent}>
    {children}
  </SentryErrorBoundary>
);

export const withErrorBoundary = (
  Component: FunctionComponent,
  { fallback = ErrorFallback, ...rest } = {},
): FunctionComponent =>
  sentryWithErrorBoundary(Component, {
    fallback,
    ...rest,
  });

const MESSAGE_FINANCING_INTEREST = (
  financingMessageWithFinancingData,
) => `Sehr geehrte Damen und Herren,
ich interessiere mich für dieses Fahrzeug. ${financingMessageWithFinancingData}

Bitte kontaktieren Sie mich.
Mit freundlichen Grüßen`;

const MESSAGE_FINANCING_INTEREST_WITH_FINANCING_DETAILS = (
  financingData,
) => `Außerdem habe ich Interesse an einer Finanzierung mit folgenden Details:

${financingData}`;

const MESSAGE_TRADEIN_INTEREST = (
  tradeinDetails,
) => `Sehr geehrte Damen und Herren,
ich interessiere mich für dieses Fahrzeug und würde mich über ein Finanzierungsangebot freuen. ${tradeinDetails}

Bitte kontaktieren Sie mich.
Mit freundlichen Grüßen`;

const MESSAGE_FINANCING_AND_TRADEIN_INTEREST = (
  financingMessageWithFinancingData,
  tradeinDetails,
) => `Sehr geehrte Damen und Herren,
ich interessiere mich für dieses Fahrzeug und würde mich über ein Finanzierungsangebot freuen. ${financingMessageWithFinancingData}${tradeinDetails}

Bitte kontaktieren Sie mich.
Mit freundlichen Grüßen`;

export const FDLContactDealerMessages = {
  financingInterest: MESSAGE_FINANCING_INTEREST,
  financingDetails: MESSAGE_FINANCING_INTEREST_WITH_FINANCING_DETAILS,
  tradeInInterest: MESSAGE_TRADEIN_INTEREST,
  financingAndTradeInInterest: MESSAGE_FINANCING_AND_TRADEIN_INTEREST,
};

export const userFinancingSettings = {
  financingProduct: (data) => `- ${data.financingProduct};`,
  downPayment: (data) => `- ${data.downPayment} Anzahlung;`,
  duration: (data) => `- ${data.duration} Laufzeit;`,
  mileage: (data) => `- ${data.mileage} km/Jahr Laufleistung;`,
  optionalServices: (data) => (data.optionalServices) ? `- Zusatzleistungen: ${data.optionalServices};` : null,
  rate: (data) => `- Monatliche Rate: ${data.rate};`,
  finalRate: (data) => (data.financingProduct === 'PrivatLeasing') ? null : `- Schlussrate: ${data.finalRate};`,
};

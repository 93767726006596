import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { deviceSelector } from 'app/selectors';
import {
  locationErrorSelector,
  currentLocationSelector,
  locationPendingSelector,
  locationSuggestionsSelector,
} from 'app/marketplace/geolocation/selectors';
import {
  resetLocation,
  resetSuggestions,
  getLocationFromUser,
  getLocationFromCoords,
  getLocationSuggestions,
  setCurrentLocationFromObject,
} from 'app/marketplace/geolocation/actions';
import {
  onChangeFilter,
  openSidebarFilters,
  changeUrlfromLandingPage,
} from 'app/marketplace/search/actions';
import {
  getShowVehicleCount,
  getVehicleCountSelector,
  getLoadingStateSelector,
} from 'app/marketplace/search/selectors';
import { getFilterStateSelector } from 'app/marketplace/filter/selectors';
import { FilterPanel } from 'app/marketplace/home/Stage/FilterPanel/FilterPanel';

export const mapStateToProps = createStructuredSelector({
  filters: getFilterStateSelector,
  locationError: locationErrorSelector,
  isLoading: getLoadingStateSelector,
  showVehicleCount: getShowVehicleCount,
  vehicleCount: getVehicleCountSelector,
  currentLocation: currentLocationSelector,
  locationPending: locationPendingSelector,
  locationSuggestions: locationSuggestionsSelector,
  deviceType: (state) => deviceSelector(state),
});

export const ConnectedFilterPanel = connect(mapStateToProps, {
  resetLocation,
  onChangeFilter,
  resetSuggestions,
  openSidebarFilters,
  getLocationFromUser,
  getLocationFromCoords,
  getLocationSuggestions,
  changeUrlfromLandingPage,
  setCurrentLocationFromObject,
})(FilterPanel);

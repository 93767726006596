/* eslint-disable import/no-unresolved */
/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/ban-ts-comment */
import { BASE_URL } from 'app/config';
import { getImageUrl } from 'app/shared/components/MakeLogo/utils';

/* @ts-ignore */
import smallDataMap from '../../../../client/assets/makes/generated/100/makes-sprite.json';

import { LogoSt, WrapperSt, MakesLogoImageSt } from './MakeLogo.css';

export interface IMakeLogo {
  makeKey: string;
  size: number;
  adjustPosition: boolean;
  scaleTo: number;
  className?: string;
  isVisible?: boolean;
  shouldLoadAllMakes?: boolean;
}

export const MakeLogo = ({
  makeKey,
  size,
  adjustPosition,
  scaleTo,
  className,
  isVisible = false,
  shouldLoadAllMakes = false,
}: IMakeLogo): JSX.Element => {
  const dataMap = {
    100: smallDataMap,
  };
  const image = dataMap[size] && dataMap[size].image;
  const sprite = dataMap[size] && dataMap[size][makeKey];

  const imagesToLoad = shouldLoadAllMakes
    ? getImageUrl(BASE_URL, image)
    : `/client/assets/makes/100/${makeKey}.png`;

  if (!sprite) {
    return <></>;
  }

  return (
    <WrapperSt className={className}>
      {shouldLoadAllMakes ? (
        <LogoSt
          x={sprite.offset_x}
          y={sprite.offset_y}
          size={size}
          scaleTo={scaleTo}
          adjustPosition={adjustPosition}
          image={imagesToLoad}
          isVisible={isVisible}
        />
      ) : (
        <MakesLogoImageSt
          src={imagesToLoad}
          alt={`${makeKey} logo`}
          width={92}
          height={92}
        />
      )}
    </WrapperSt>
  );
};

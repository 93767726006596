/* istanbul ignore file */
import { createRef, PureComponent } from 'react';
import PropTypes from 'prop-types';
import { css } from 'styled-components';
import isEmpty from 'lodash.isempty';

import { getLocationString } from 'app/marketplace/filter/utils';
import { InputWithButton } from 'app/shared/components/InputWithButton/InputWithButton';
import { InputWithoutButton } from 'app/shared/components/InputWithoutButton/InputWithoutButton';
import { InputSelect } from 'app/shared/components/InputSelect/InputSelect';
import { SearchInputPopoverRenderer } from 'app/shared/modules/search/SearchPane/SearchInputPopoverRenderer/SearchInputPopoverRenderer';
import { SuggestionRenderer } from 'app/shared/components/SuggestionRenderer/SuggestionRenderer';
import { color } from 'app/shared/styles_js/variables';
import { ItemSt } from 'app/shared/components/SuggestionRenderer/SuggestionRenderer.css';
import { noop } from 'app/shared/utils/utils';

export class InputSearchWithSuggestions extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      value: props.currentLocation
        ? getLocationString(
            props.currentLocation.zip,
            props.currentLocation.place,
          )
        : '',
      currentLocation: props.currentLocation,
    };

    this.inputSelect = createRef();
    this.input = null;

    this.onChange = this.onChange.bind(this);
    this.onSelect = this.onSelect.bind(this);
  }

  componentDidUpdate(prevProps) {
    const { activeFilters: prevActiveFilters } = prevProps;
    const {
      currentLocation: { zip },
      activeFilters,
    } = this.props;

    // we need this to reset value of input field if activeFilters are changed
    // but we do not have current location fiter active
    if (activeFilters !== prevActiveFilters && !zip) {
      this.setState({ value: '' });
    }
  }

  static getDerivedStateFromProps(props, state) {
    const { currentLocation: stateLocation } = state;
    const { currentLocation: propsLocation } = props;
    const { zip: propZip, place: propPlace } = propsLocation;
    const { zip: stateZip } = stateLocation;

    if (propZip !== stateZip) {
      return {
        value: getLocationString(propZip, propPlace),
        currentLocation: props.currentLocation,
      };
    }
    return null;
  }

  /**
   * updates filter depending on location
   * and blurs the input field.
   *
   * @param {*} location selected location choice
   * @returns {undefined}
   */
  onSelect(location) {
    if (isEmpty(location) || !location?.place) return;
    const { setCurrentLocationFromObject, setLocationFilters } = this.props;
    setLocationFilters(location);
    setCurrentLocationFromObject(location);
    this.input.focus();
  }

  onChange(value) {
    const { getLocationSuggestions, resetSuggestions } = this.props;
    this.setState({ value });
    // Fetch suggestions with every keystroke
    if (value.length > 0) {
      getLocationSuggestions(value, 5);
    } else {
      resetSuggestions();
      this.input.blur();
    }
  }

  getPopoverRendererProps = () => {
    const { value } = this.state;
    const { currentLocation } = this.props;
    const locationPlace =
      currentLocation.value ||
      `${currentLocation.zip}, ${currentLocation.place}` ||
      '';
    const selectFirstOnEnter = value?.length > 0 && value !== locationPlace;
    return {
      selectFirstOnEnter,
      inputValueExtractor: (value) => (
        <div>
          <span>{value.zip},</span> {value.place}
        </div>
      ),
      extraStyles: css`
        top: 100%;

        & > ${ItemSt} {
          padding-right: 10px;
          padding-left: 10px;
          border: 0;

          color: ${color.grayDarker};
        }
      `,
    };
  };

  getLocationErrorText() {
    const { locationError } = this.props;

    if (locationError && locationError.locationNotFound) {
      return 'Bitte gib eine gültige PLZ oder Stadt ein';
    }
    if (locationError) {
      return 'Leider ist ein Fehler aufgetreten';
    }

    return '';
  }

  getInputRendererPropsExtraStyles = ({ hasFocus }) => {
    return css`
      & input {
        height: 48px;
      }

      & button {
        width: 48px;
      }
      ${hasFocus &&
      css`
        & input {
          border-bottom-left-radius: 0;
        }

        & button {
          border-bottom-right-radius: 0;
        }
      `}
    `;
  };

  getInputRendererProps = () => {
    const { locationPending, locationSuggestions } = this.props;

    const errorText = this.getLocationErrorText();

    return {
      pending: locationPending,
      onButtonClick: () => {
        if (locationSuggestions.length > 0) {
          // Select the first item from the popover list
          this.onSelect(locationSuggestions[0]);
        }
      },
      innerRef: (c) => {
        if (c) {
          this.input = c;
        }
      },
      iconClass: 'if-icon-search',
      extraStyles: this.getInputRendererPropsExtraStyles,
      errorText,
    };
  };

  render() {
    const {
      placeholder,
      className,
      handleOnBlur,
      activeFilters,
      locationSuggestions,
      isInputWithoutButton,
    } = this.props;
    const { value } = this.state;

    return (
      <InputSelect
        className={className}
        ref={this.inputSelect}
        value={{ place: value }}
        items={locationSuggestions}
        onInputBlur={handleOnBlur}
        itemSearch={(items) => items}
        debounceTime={200}
        inputValueExtractor={(value) => value.place}
        inputRenderer={
          isInputWithoutButton ? InputWithoutButton : InputWithButton
        }
        popoverRenderer={SearchInputPopoverRenderer}
        itemRenderer={SuggestionRenderer}
        onChange={this.onChange}
        onSelect={this.onSelect}
        placeholder={placeholder}
        inputRendererProps={this.getInputRendererProps()}
        popoverRendererProps={this.getPopoverRendererProps()}
        blurOnSelect
        disabled={!!activeFilters.find((filter) => filter.value === 'GWC_D2C')}
        data-qa={`location-input-${placeholder}`}
      />
    );
  }
}

InputSearchWithSuggestions.propTypes = {
  placeholder: PropTypes.string,
  className: PropTypes.string,
  handleOnBlur: PropTypes.func,
  activeFilters: PropTypes.array,
  locationError: PropTypes.object,
  isInputWithoutButton: PropTypes.bool,
  locationPending: PropTypes.bool.isRequired,
  resetSuggestions: PropTypes.func.isRequired,
  currentLocation: PropTypes.object.isRequired,
  locationSuggestions: PropTypes.array.isRequired,
  getLocationSuggestions: PropTypes.func.isRequired,
  setLocationFilters: PropTypes.func,
  setCurrentLocationFromObject: PropTypes.func.isRequired,
};

InputSearchWithSuggestions.defaultProps = {
  className: '',
  placeholder: 'Postleitzahl oder Stadt',
  activeFilters: [],
  locationError: null,
  isInputWithoutButton: false,
  handleOnBlur: noop,
  setLocationFilters: noop,
};

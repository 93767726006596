import { put, fork, all, debounce } from 'redux-saga/effects';
import { LOCATION_CHANGE } from 'react-router-redux';
import _isEmpty from 'lodash.isempty';

import { resetFilters } from 'app/marketplace/filter/actions';
import { resetLocation } from 'app/marketplace/geolocation/actions';

let previousLocation = {};

/**
 * Reset All Filters when coming back to HomePage
 * */
export function* handleComebacktoHomePage({ payload: { pathname } }) {
  if (window._WAS_RENDERED_ON_SERVER_) {
    return;
  }
  if (pathname === '/' && !_isEmpty(previousLocation)) {
    yield put(resetLocation());
    yield put(resetFilters());
  }
  previousLocation = { pathname };
}

export function* resetFiltersOnHomePage() {
  yield debounce(20, LOCATION_CHANGE, handleComebacktoHomePage);
}

export function* homeSagas() {
  yield all([fork(resetFiltersOnHomePage)]);
}

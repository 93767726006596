export const generateStateIdentifier = ({
  makePath,
  modelPath,
  variantPath,
}: {
  makePath?: string;
  modelPath?: string;
  variantPath?: string;
}) => [makePath, modelPath, variantPath].filter((e) => e).join('.');


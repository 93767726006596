import {
  trackAction,
  getAdditionalVehicleTrackingParams,
} from 'app/shared/utils/tracking';
import { isMobileViewport } from 'app/shared/utils/utils';
import {
  hasPrecalculatedFinance,
  isDealerLeasingVehicle,
  isDealerFinanceVehicle,
} from 'app/shared/utils/vehicle';
import {
  getIsD2CVehicle,
  getHasFinanceJourney,
} from 'app/marketplace/utils/getIsD2CVehicle';
import { getIsId3Vehicle } from 'app/marketplace/utils/getIsId3Vehicle';
import { getDataForCartileLink } from 'app/shared/modules/Cartile/CartileGetData/getDataForCartileLink';
import { getDataForCartileImage } from 'app/shared/modules/Cartile/CartileGetData/getDataForCartileImage';
import { getCartilePriceTracking } from 'app/shared/modules/Cartile/CartileGetData/getCartilePriceTracking';
import { CartileContainer } from 'app/shared/modules/Cartile/CartileTemplates/CartileContainer/CartileContainer';
import { getDataForCartileContentInfos } from 'app/shared/modules/Cartile/CartileGetData/getDataForCartileContentInfos';
import { getDataForCartileContentHeader } from 'app/shared/modules/Cartile/CartileGetData/getDataForCartileContentHeader';
import { getDataForCartileContentFooter } from 'app/shared/modules/Cartile/CartileGetData/getDataForCartileContentFooter';
import { getDataForCartileAdditionalInfosContainer } from 'app/shared/modules/Cartile/CartileGetData/getDataForCartileAdditionalInfosContainer';
import { hideDcc } from 'app/marketplace/vehicle/Details/utils';
import { checkVehicleReservedStatus } from 'app/marketplace/vehicle/utils';

import { showConsumerLoan } from '../FDL/utils/showConsumerLoan';

import { isNewListing } from './utils';
import { getDataForCartileCallCta } from './CartileGetData/getDataForCartileCallCta';

export const handleFavourites = ({
  isFavourite,
  toggleFavourite,
  vehicle,
  target,
  reducedPrice,
}) => {
  const FavouriteClickStatus = isFavourite ? 'unclick' : 'click';
  toggleFavourite(vehicle);
  trackAction('favourites_button_click', {
    category: target,
    label: 'click_status',
    value: `${FavouriteClickStatus}${reducedPrice ? '_reduced_price' : ''}`,
  });
};

export const handleCartileTracking = ({
  id,
  breakpoint,
  handleCarTileClick,
  isD2CVehicle,
  isId3Vehicle,
  target,
  additionalProperties,
  isPushVehicle,
  additionalTrackingOptionData = {},
}) => {
  if (!isMobileViewport(breakpoint) && handleCarTileClick) {
    handleCarTileClick(id);
  }
  if (isD2CVehicle) {
    const cartileActionName = isPushVehicle
      ? 'cartile_d2c_click_d2c_push'
      : 'cartile_d2c_click';

    return trackAction(
      cartileActionName,
      {
        category: target,
        value: id,
        description: 'user clicks on vehicle Cartile link to pdp',
        ...additionalTrackingOptionData,
      },
      additionalProperties,
    );
  }
  if (isId3Vehicle) {
    return trackAction(
      'cartile_click_id3_push',
      {
        category: target,
        value: id,
        description: 'user clicks on vehicle Cartile on id3 push to select pdp',
        ...additionalTrackingOptionData,
      },
      additionalProperties,
    );
  }
  return trackAction(
    'cartile_click',
    {
      category: target,
      value: id,
      description: 'user clicks on vehicle Cartile link to pdp',
      ...additionalTrackingOptionData,
    },
    additionalProperties,
  );
};

/**
 * Temporary type def. TODO: Replace when file is converted to typescript
 * @typedef {import('app/marketplace/search/HighlightedCarCarousel/HighlightedCarCarousel.types').ICarTileDataArgs} ICarTileDataArgs
 */

/**
 * Returns car tile container and props
 * @param {ICarTileDataArgs} param0
 * @returns {{props: Record<string, unknown>; CartileContainer: (...args: any) => JSX.Element}}
 */
export const GetCartileData = ({
  index,
  utmParams = '',
  width,
  isLazy,
  target,
  vehicle,
  isLoading,
  breakpoint,
  isFavourite,
  isHorizontal,
  hasRemoveIcon,
  performanceUnit,
  currentLocation,
  backgroundColor,
  toggleFavourite,
  budgetRangeValue,
  paddingHorizontal,
  handleCarTileClick,
  additionalTrackingProperties,
  additionalTrackingOptionData,
  isPushVehicle,
  campaigns,
  isDealerCashEligible,
  isFlag279ShowCallCta = false,
  activeFilters,
}) => {
  const {
    id,
    live,
    externalId,
    reducedPrice,
    isHighlighted,
    _meta: { searchId = '' },
    dataSource,
    createdAt,
  } = vehicle;

  const isUnavailable =
    checkVehicleReservedStatus(vehicle) || !live || !externalId;
  const hasFinance = hasPrecalculatedFinance(vehicle);
  const isD2CVehicle = getIsD2CVehicle(dataSource);
  const isId3Vehicle = getIsId3Vehicle(dataSource);
  const isDealerLeasing = isDealerLeasingVehicle(vehicle);
  const isDealerFinance = isDealerFinanceVehicle(vehicle);
  const isMonthlyRateFilterSelected = budgetRangeValue === 'month';
  const hasFinanceJourney = getHasFinanceJourney(activeFilters);
  const hideDccVehicle = hideDcc(vehicle);
  const showFinancingOptions =
    hasFinance &&
    (isMonthlyRateFilterSelected ||
      isD2CVehicle ||
      hasFinanceJourney ||
      isDealerLeasing ||
      isDealerFinance);

  const showLinkToPDPFinancingSection = hasFinance || showConsumerLoan(vehicle);

  const handleToggleFavourites = /* istanbul ignore next */ () => {
    const fnParamsObj = {
      isFavourite,
      vehicle,
      toggleFavourite,
      reducedPrice,
      target,
    };
    handleFavourites(fnParamsObj);
  };

  const linkTo = isId3Vehicle
    ? `/vehicle/${id}`
    : `/vehicle/${id}?dealerId=${vehicle.dealer.id}${utmParams}`;

  const linkTarget = isId3Vehicle ? '_self' : '';

  const fetchpriority =
    isD2CVehicle && index === 0 && breakpoint === 'sm' ? 'high' : 'auto';

  /**
   * Cartile Tracking
   */
  const handleTracking = /* istanbul ignore next */ () => {
    const additionalProperties = {
      index,
      searchId,
      breakpoint,
      isFavourite,
      isD2CVehicle,
      isNewVehicle: isNewListing(createdAt),
      ...additionalTrackingProperties,
      ...getAdditionalVehicleTrackingParams(vehicle),
    };
    const fnParamsObj = {
      id,
      breakpoint,
      handleCarTileClick,
      isD2CVehicle,
      isId3Vehicle,
      target,
      additionalProperties,
      additionalTrackingOptionData,
      isPushVehicle,
    };
    handleCartileTracking(fnParamsObj);
  };

  /**
   * Cartile Link
   */
  const {
    CartileLinkContainerChildren,
    CartileLinkContainerChildrenProps,
  } = getDataForCartileLink({
    linkTo,
    isHorizontal,
    isUnavailable,
    hasRemoveIcon,
    handleTracking,
    paddingHorizontal,
    target: linkTarget,
    handleToggleFavourites,
  });
  /**
   * Cartile Image
   */
  const {
    CartileImageContainerChildren,
    CartileImageContainerChildrenProps,
  } = getDataForCartileImage({
    isLazy,
    vehicle,
    isLoading,
    isHorizontal,
    isUnavailable,
    fetchpriority,
  });

  /**
   * Cartile Content
   */
  const {
    CartileContentHeaderChildren,
    CartileContentHeaderChildrenProps,
  } = getDataForCartileContentHeader({
    vehicle,
    isLoading,
    isFavourite,
    isPushVehicle,
    isId3Vehicle,
    handleToggleFavourites,
  });

  const {
    CartileContentInfosChildren,
    CartileContentInfosChildrenProps,
  } = getDataForCartileContentInfos({
    vehicle,
    isLoading,
    performanceUnit,
    campaigns,
    isDealerCashEligible,
  });

  const [
    {
      CartileContentFooterLocationChildren,
      CartileContentFooterLocationChildrenProps,
    },
    {
      CartileContentFooterPriceChildren,
      CartileContentFooterPriceChildrenProps,
    },
  ] = getDataForCartileContentFooter({
    target,
    vehicle,
    isLoading,
    isHorizontal,
    isD2CVehicle,
    currentLocation,
    showFinancingOptions,
    getCartilePriceTracking,
    showLinkToPDPFinancingSection,
    isId3Vehicle,
    hideDccVehicle,
  });

  const {
    CartileFooterCtaContainerChildren,
    CartileFooterCtaContainerChildrenProps,
  } = getDataForCartileCallCta({
    vehicle,
    isLoading,
    isFlag279ShowCallCta,
    isMobile: isMobileViewport(breakpoint),
    target,
    breakpoint,
  });

  /**
   * Cartile Additional Infos
   */
  const {
    CartileAdditionalInfosChildren,
    CartileAdditionalInfosChildrenProps,
  } = getDataForCartileAdditionalInfosContainer({
    vehicle,
    isLoading,
    showFinancingOptions,
  });

  const className = getIsD2CVehicle(dataSource)
    ? 'CartileContentHeader-d2cCartile'
    : 'CartileContentHeader-nonD2CCartile';

  return {
    CartileContainer,
    props: {
      width,
      linkTo,
      index,
      isLoading,
      isHorizontal,
      isHighlighted,
      isUnavailable,
      hasRemoveIcon,
      handleTracking,
      backgroundColor,
      paddingHorizontal,
      target: linkTarget,
      className,
      cartileLinkContainer: {
        CartileLinkContainerChildren,
        CartileLinkContainerChildrenProps,
      },
      cartileImageContainer: {
        CartileImageContainerChildren,
        CartileImageContainerChildrenProps,
      },
      cartileContentContainer: {
        CartileContentHeaderChildren,
        CartileContentHeaderChildrenProps,
        CartileContentInfosChildren,
        CartileContentInfosChildrenProps,
        CartileContentFooterLocationChildren,
        CartileContentFooterLocationChildrenProps,
        CartileContentFooterPriceChildren,
        CartileContentFooterPriceChildrenProps,
        CartileFooterCtaContainerChildren,
        CartileFooterCtaContainerChildrenProps,
      },
      cartileAdditionalInfosContainer: {
        CartileAdditionalInfosChildren,
        CartileAdditionalInfosChildrenProps,
      },
      isPushVehicle,
    },
  };
};

/* istanbul ignore file */
import {
  Children,
  CSSProperties,
  ElementType,
  ReactNode,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import debounce from 'lodash.debounce';
import smoothscroll from 'smoothscroll-polyfill';

import {
  CarouselContainerSt,
  CarouselItem,
  HeaderSt,
  NavArrowSt,
  NavButtonsSt,
  ScrollSnapCarouselSt,
} from 'app/marketplace/specialOffers/ScrollSnapCarousel/ScrollSnapCarousel.css';

interface IScrollSnapCarouselProps {
  children: ReactNode;
  itemsGap?: number;
  itemsToScroll?: number;
  itemsWidth: number;
  navigation?: ElementType | ReactNode;
  showArrows?: boolean;
  title?: ElementType | ReactNode;
  style?: CSSProperties;
  [x: string]: any;
  nextArrowClickCallback?: () => void;
  prevArrowClickCallback?: () => void;
  target?: string;
  oneClickLeadsCount?: number;
}

const ScrollSnapCarousel = ({
  children,
  itemsGap = 17.75,
  itemsToScroll = 1,
  itemsWidth,
  navigation = null,
  showArrows = false,
  title = null,
  nextArrowClickCallback,
  prevArrowClickCallback,
  target = '',
  oneClickLeadsCount = 0,
  ...rest
}: IScrollSnapCarouselProps): JSX.Element => {
  const containerRef = useRef<HTMLDivElement | null>(null);
  const [leftArrowDisabled, setLeftArrowDisabled] = useState<boolean>(true);
  const [rightArrowDisabled, setRightArrowDisabled] = useState<boolean>(false);
  const scrollHandler = (event: Event) => {
    const {
      scrollLeft,
      scrollWidth,
      clientWidth,
    } = event.target as HTMLElement;
    const isAtStart = scrollLeft === 0;
    setLeftArrowDisabled(isAtStart);
    const isAtEnd = scrollLeft + clientWidth >= scrollWidth;
    setRightArrowDisabled(isAtEnd);
  };

  const debouncedScrollHandler = useCallback(debounce(scrollHandler, 200), []);

  useEffect(() => {
    smoothscroll.polyfill();
  }, []);

  useEffect(() => {
    const node = containerRef?.current;

    if (node && showArrows) {
      node.addEventListener('scroll', debouncedScrollHandler, {
        passive: true,
      });
    }

    return () => {
      if (node) node.removeEventListener('scroll', debouncedScrollHandler);
    };
  }, [containerRef, showArrows]);

  const swipe = (direction: string) => {
    const scrollAmount = itemsToScroll * (itemsWidth + itemsGap);

    const node = containerRef?.current;
    if (node) {
      node.scrollBy({
        top: 0,
        left: direction === 'left' ? -1 * scrollAmount : scrollAmount,
        behavior: 'smooth',
      });
    }
  };

  const handleLeftArrowClick = () => {
    if (!leftArrowDisabled) {
      swipe('left');
      if (prevArrowClickCallback) prevArrowClickCallback();
    }
  };
  const handleRightArrowClick = () => {
    if (!rightArrowDisabled) {
      swipe('right');
      if (nextArrowClickCallback) nextArrowClickCallback();
    }
  };

  return (
    <ScrollSnapCarouselSt>
      {(title || showArrows || navigation) && (
        <HeaderSt>
          <div>{title}</div>
          {showArrows ? (
            <NavButtonsSt
              isTargetSelectableOneClickLeads={
                target === 'SelectableOneClickLeads'
              }
              oneClickLeadsCount={oneClickLeadsCount}
            >
              <NavArrowSt
                $disabled={leftArrowDisabled}
                className="carousel-arrow"
                direction="left-long"
                onClick={handleLeftArrowClick}
              />
              <NavArrowSt
                $disabled={rightArrowDisabled}
                className="carousel-arrow"
                direction="right-long"
                onClick={handleRightArrowClick}
              />
            </NavButtonsSt>
          ) : (
            navigation
          )}
        </HeaderSt>
      )}
      <CarouselContainerSt
        ref={containerRef}
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        $itemsGap={itemsGap}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...rest}
      >
        {Children.map(children, (child) => {
          return child ? (
            <CarouselItem id="carousel-item">{child}</CarouselItem>
          ) : null;
        })}
      </CarouselContainerSt>
    </ScrollSnapCarouselSt>
  );
};

export default ScrollSnapCarousel;

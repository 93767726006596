/* eslint-disable react-perf/jsx-no-new-object-as-prop */
import { Link, Text4 } from 'app/shared/ui';

export const RecaptchaDisclaimer = (textProps) => {
  return (
    <Text4 as="p" color="grayDarker" m={0} {...textProps}>
      Diese Website ist durch reCAPTCHA von Google geschützt. Für reCAPTCHA
      gelten die Google-
      <Link
        to="https://policies.google.com/privacy"
        useRouter={false}
        target="_blank"
        color="grayDarker"
        hoverColor="grayDarker"
      >
        Datenschutzerklärung
      </Link>{' '}
      und -
      <Link
        to="https://policies.google.com/terms"
        useRouter={false}
        target="_blank"
        color="grayDarker"
        hoverColor="grayDarker"
      >
        Nutzungsbedingungen
      </Link>
      .
    </Text4>
  );
};

import styled from 'styled-components';

const getDateAndCategoryColor = ({ theme: { colors } }) => colors.grayDarker;

export const DotSt = styled.div`
  display: inline-block;
  width: 1px;
  height: 1px;
  border: 1px solid ${getDateAndCategoryColor};
  margin: 2px 7px 0 7px;
  background-color: ${getDateAndCategoryColor};
  border-radius: 50%;
`;

export const CategorySt = styled.div`
  overflow: hidden;
  max-width: 50%;
  white-space: nowrap;
  text-overflow: ellipsis;
`;
export const DateAndCategoryWrapperSt = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-top: 8px;
  line-height: 2.67;
  font-size: 12px;
  color: ${/* istanbul ignore next */ getDateAndCategoryColor};
  white-space: nowrap;
`;

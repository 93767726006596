import styled, { css } from 'styled-components';

import { Radio, Icon } from 'app/shared/ui';
import { color, is, media, zIndex } from 'app/shared/styles_js/variables';
import { Modal } from 'app/shared/components/Modal/Modal';
import { ModalContentSt } from 'app/shared/components/Modal/Modal.css';
import { FlexRow } from 'app/marketplace/shared/components/Layouts/FlexRow';
import { FlexCol } from 'app/marketplace/shared/components/Layouts/FlexCol';
import { P } from 'app/shared/components/Typography';
import { List } from 'app/marketplace/contentful/shared/List/List';
import { Button } from 'app/shared/components/Button/Button';

export const RadioSt = styled(Radio)`
  i {
    align-self: center;
  }

  & > span {
    margin-right: 4px;
  }

  ${media.md(css`
    & > span {
      margin-right: unset;
    }
  `)}
`;

export const RatePlanImage = styled.img`
  margin-top: 8px;
  width: 100%;
  padding: 8px;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 0px 4px 0px;

  ${media.md(css`
    margin-top: unset;
    width: unset;
    padding: unset;
    box-shadow: unset;
  `)}
`;

export const CustomList = styled(List)`
  margin: 20px 0 35px;
`;

export const ResultTitle = styled.h4`
  font-size: 24px;
  font-weight: bold;
  line-height: 1.67;
`;

export const ResultDescription = styled.p`
  font-size: 16px;
  line-height: 2;
`;

export const Image = styled.img`
  position: relative;
  height: 100%;
  width: 100%;
`;

export const Title = styled.div`
  position: relative;
  width: 80%;
  margin: 0 auto 56px;
  text-align: center;
  font-weight: 500;

  &::after {
    position: absolute;
    right: 0;
    bottom: -16px;
    left: 0;
    width: 10%;
    height: 1px;
    margin-right: auto;
    margin-left: auto;
    content: '';
    background-color: ${color.brandSecondary};
  }
`;

export const RadioText = styled(P)`
  margin: 0;
  line-height: 1.7;
`;

export const ModalFlexCol = styled(FlexCol)`
  box-sizing: content-box;
  padding-right: 0;
  padding-left: 0;
  margin-right: 0;
  margin-left: 0;

  ${media.md(css`
    box-sizing: border-box;
    padding-right: 16px;
    padding-left: 16px;
  `)};
`;

export const RadioCol = styled(ModalFlexCol)`
  margin-bottom: 16px;
`;

export const RadioColSt = styled(RadioCol)`
  width: calc(100% - 24px);

  ${media.md(css`
    width: unset;
  `)};
`;

export const ModalFlexRow = styled(FlexRow).attrs((props) => ({
  noSplit: true,
  ...props,
}))`
  margin-top: 30px;

  ${media.md(css`
    flex-direction: column;
    padding-bottom: 0;
  `)};
`;

export const ModalSt = styled(Modal)`
  display: flex;
  overflow: hidden;
  flex-direction: column;
  justify-content: space-between;

  ${media.md(css`
    padding-bottom: 36px;
    justify-content: center;
  `)};

  ${is('open')`
    display: block;

    ${media.md(css`
      display: flex;

      & ${ModalContentSt} {
        display: flex;
        justify-content: space-between;
        width: 696px;
        min-height: 496px;
        max-height: 600px;
        padding: 32px 64px;
      }
    `)};
  `};

  .if-icon-close {
    position: fixed;
    z-index: ${zIndex.navbar};
    top: 18px;
    color: ${color.black};
    font-size: 16px;

    ${media.md(css`
      position: absolute;
      top: 24px;
    `)};
  }
`;

export const ButtonArea = styled(FlexRow)`
  flex-direction: row;
  width: 100%;
  margin: auto;
  padding-bottom: 24px;

  ${media.md(css`
    width: 60%;
    margin: auto;
    padding-bottom: 100px;
    padding-bottom: 0;
    position: unset;
    bottom: unset;
  `)};
`;

export const NavigateButton = styled(Button)`
  margin: auto;
`;

export const HeaderNavigateSt = styled.span`
  position: absolute;
  top: 12px;
  line-height: 1.71;
  font-size: 16px;
  cursor: pointer;

  ${media.md(css`
    top: 20px;
  `)};
`;

export const HeaderIconSt = styled(Icon)`
  color: ${color.brandSecondary};
  margin-right: 11px;
`;

export const ImageWrapperSt = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  width: 200px;
  margin-left: 24px;
`;

import { Icon, Link } from 'app/shared/ui';
import { trackAction } from 'app/shared/utils/tracking';
import { TCheckoutStages } from 'app/marketplace/vehicleCheckout/pages/CheckoutSteps/CheckoutSteps.types';

import { TMobileNavControlsProps } from './MobileNavControls.types';
import { MobileNavControlsSt } from './MobileNavControls.css';

const trackingLabels: Record<TCheckoutStages, string> = {
  OPTIONS: 'financing_selection',
  CALCULATOR: 'financingrate',
  PERSONAL_INFO: 'personal_details',
};

export const MobileNavControls = ({
  vehicleId,
  dealerId,
  checkoutType,
  checkoutStep,
}: TMobileNavControlsProps): JSX.Element => {
  const onClick = () => {
    trackAction('checkout_back_to_pdp_click', {
      label: trackingLabels[checkoutStep],
      value: checkoutType,
    });
  };

  return (
    <MobileNavControlsSt>
      <Link
        to={`/vehicle/${vehicleId}?dealerId=${dealerId}`}
        onClick={onClick}
        color="micaBlue"
        hoverColor="actionBlue"
        textDecoration="none"
        fontWeight="normal"
      >
        <Icon icon="close" iconSize="xsmall" />
      </Link>
    </MobileNavControlsSt>
  );
};

import PropTypes from 'prop-types';
import { Carousel } from 'app/shared/ui';
import _isEmpty from 'lodash.isempty';

import {
  SpecialOffersSliderSt,
  SpecialOfferTileSt,
  NavArrowSt,
  SpecialOfferTeaserCardSt,
} from 'app/marketplace/specialOffers/SpecialOffersSlider/SpecialOffersSlider.css';
import { returnCard } from 'app/marketplace/specialOffers/returnCard';
import { breakpointShape } from 'app/shared/utils/propTypes';

const tilesCountPerBreakpoint = {
  sm: 1,
  md: 2,
  lg: 2,
  xl: 3,
};

export const SpecialOffersSlider = ({
  specialOffers,
  breakpoint,
  error,
  gamInfo: { carTiles },
}) => {
  if (_isEmpty(specialOffers) || !_isEmpty(error)) return null;

  const slidesToShow = tilesCountPerBreakpoint[breakpoint];
  const isMobile = breakpoint === 'sm';

  return (
    <SpecialOffersSliderSt>
      <Carousel
        slidesToShow={slidesToShow}
        slidesToScroll={slidesToShow}
        isCarouselReady
        centerMode={isMobile}
        centerPadding="24px"
        infinite={false}
        showArrows={!isMobile}
        prevArrow={<NavArrowSt direction="left" />}
        nextArrow={<NavArrowSt direction="right" />}
      >
        {specialOffers.map((specialOffer, specialOfferKey) =>
          returnCard({
            offer: specialOffer,
            TeaserComponent: SpecialOfferTeaserCardSt,
            OfferComponent: SpecialOfferTileSt,
            gamInfo: {
              carTiles,
              order: specialOfferKey,
            },
          }),
        )}
      </Carousel>
    </SpecialOffersSliderSt>
  );
};

SpecialOffersSlider.propTypes = {
  specialOffers: PropTypes.array,
  breakpoint: breakpointShape.isRequired,
  error: PropTypes.object,
  gamInfo: PropTypes.object,
};

SpecialOffersSlider.defaultProps = {
  specialOffers: [],
  error: {},
  gamInfo: {},
};

import styled, { css } from 'styled-components';
import { Heading4, ContainerSt } from 'app/shared/ui';

import { grid, media, evergreenOnly } from 'app/shared/styles_js/variables';
import { SEOList } from 'app/shared/styles_js/text.css';

export const SeoSt = styled.div`
  padding: 40px 0;
  background-color: ${({ theme: { colors } }) => colors.sunbeanSuperLight};

  ${media.md(css`
    padding: 72px 0;
  `)};
`;

export const HeadlineSt = styled(Heading4).attrs(
  /* istanbul ignore next */ () => ({
    fontSize: [24, 28, 32],
    lineHeight: [1.67, 1.5],
    fontWeight: 800,
  }),
)`
  width: 100%;
  margin-bottom: 40px;
  grid-column: 1 / -1;

  ${media.md(css`
    .grid-container:not(:first-of-type) & {
      margin: 32px 0 56px;
    }
  `)};

  ${media.xl(css`
    margin-bottom: 56px;
  `)}
`;

export const ListSt = styled(SEOList)`
  margin: 0 0 40px 0;
  ${media.md(css`
    width: 25%;
    padding-right: ${grid.gutter.md}px;

    ${evergreenOnly(css`
      width: auto;
      padding-right: 0;
    `)}
  `)}

  ${media.xl(css`
    margin-top: 0;
  `)}

  a {
    line-height: 1.5;
    margin-bottom: 0.5rem;
  }

  a:hover {
    font-weight: unset;
    text-decoration: none;
  }

  strong {
    color: ${({ theme: { colors } }) => colors.tarmacGrey};
  }
`;

export const FieldListSt = styled(SEOList)`
  margin: 0;

  ${media.md(css`
    width: 25%;

    ${evergreenOnly(css`
      width: auto;
    `)}
  `)}

  ${media.xl(css`
    margin-top: 0;
  `)}

  a:hover {
    font-weight: unset;
    text-decoration: none;
  }
`;
export const GridContainerSt = styled(ContainerSt)`
  display: grid;
  flex-wrap: wrap;
  grid-template-columns: repeat(2, 1fr);
`;

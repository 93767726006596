import {
  SUBMIT_ENTRIES_REQUEST,
  SUCCESS_ENTRIES_REQUEST,
  FAILURE_ENTRIES_REQUEST,
} from './actions';

export const defaultHeaderCLPState = () => ({
  pending: false,
  error: null,
  entries: [],
});

export function headerCLP(state = defaultHeaderCLPState(), action) {
  const { error, payload, type } = action;
  switch (type) {
    case SUBMIT_ENTRIES_REQUEST:
      return { ...state, pending: true };
    case SUCCESS_ENTRIES_REQUEST: {
      const mergedEntries = state.entries.concat(
        payload.filter(
          ({ fields: urlId }) =>
            state.entries.find(
              ({ fields: { urlId: entryUrlId } }) => entryUrlId === urlId,
            ) !== null,
        ),
      );
      return {
        ...state,
        entries: mergedEntries,
        pending: false,
      };
    }
    case FAILURE_ENTRIES_REQUEST:
      return { ...state, error, pending: false };
    default:
      return state;
  }
}

import React, {
  HtmlHTMLAttributes,
  ReactElement,
  ReactNode,
  SyntheticEvent,
} from 'react';

import { TBrandsInfoType } from '../NavFlyoutBrands/NavFlyoutBrands.types';

export type THandleOnClickProps = {
  id: string;
  selectedItem: string;
  selectOnClick: TSelectOnClick;
  setIsOpen: (isOpen: boolean) => void;
  userAction?: () => void;
};

export type THandleOnMouseLeaveProps = {
  id: string;
  selectedItem: string;
  selectOnClick: TSelectOnClick;
  setIsOpen: (isOpen: boolean) => void;
};

type TCategoryElementsProps = {
  url?: string;
  onClick?: () => void;
  label: ReactNode;
  useRouter?: boolean;
};

export type TItemCategoryProps = {
  title: ReactNode;
  elements: TCategoryElementsProps[];
};

export type TItemProps = {
  isVisibleOnDesktop?: boolean;
  label: ReactNode;
  itemId: string;
  itemCategories?: (TItemCategoryProps | ReactNode)[];
  url?: string;
  useRouter?: boolean;
  brandsInfo?: TBrandsInfoType;
  fullWidthCategory?: React.ReactNode;
  useCustomChildren?: boolean;
  customChildren?: ReactElement;
  footercontent?: ReactNode;
};
export interface IUserItem extends TItemProps {
  userAction: () => void;
}

export const isItemCategory = (
  item: TItemCategoryProps | ReactNode,
): item is TItemCategoryProps => {
  return (
    (item as TItemCategoryProps).title !== undefined &&
    (item as TItemCategoryProps).elements !== undefined
  );
};

export const isUserItem = (item: IUserItem | TItemProps): item is IUserItem => {
  return (item as IUserItem).userAction !== undefined;
};

export type TTarget = '_self' | '_blank' | '_parent' | '_top';

export type TonClickEvent = SyntheticEvent<HTMLAnchorElement | HTMLSpanElement>;

export type TSelectOnClick = (itemId: string) => void;
interface TMenuHoverCallback {
  event: SyntheticEvent;
  tabID?: string;
}
export interface INavItemProps extends HtmlHTMLAttributes<HTMLDivElement> {
  mobileBackButtonLabel: ReactNode;
  selectedItem: string;
  useRouter?: boolean;
  target?: TTarget;
  selectOnClick: TSelectOnClick;
  item: TItemProps | IUserItem;
  setTracking: (type: string, id?: ReactNode) => void;
  closeMenu: (isClosed: boolean) => void;
  renderType?: 'icon';
  ariaRole: string;
  useCustomChildren?: boolean;
  customChildren?: ReactElement;
  menuHoverCallback?: ({ event, tabID }: TMenuHoverCallback) => void;
}

export type NavItemCategoryProps = {
  item: TItemCategoryProps;
  index: number;
  setSelectedItem: TSelectOnClick;
  setTracking: (type: string, id?: ReactNode) => void;
  closeMenu: (isClosed: boolean) => void;
};

import styled, { css, DefaultTheme } from 'styled-components';
import { Flex } from '@rebass/grid';

import { TColor, TGetRgba } from '../variables/index.types';
import { media } from '../variables/media';

import { TType } from './ImageWithInfo.types';

export const DetailSectionItemSt = styled(Flex)<{
  isReverse: boolean;
  hasBackground: boolean;
  whiteBackground: number;
  bgColor: TColor;
  theme: DefaultTheme;
}>`
  flex-direction: column;
  ${media.md(css<{
    isReverse: boolean;
    hasBackground: boolean;
    whiteBackground: number;
    bgColor: TColor;
    theme: {
      getRgba: TGetRgba;
    };
  }>`
    padding: 50px 0;
    ${({ isReverse }) => {
      if (!isReverse) {
        return css`
          flex-direction: row;
        `;
      }
      return css`
        flex-direction: row-reverse;
      `;
    }};
    ${({
      hasBackground,
      isReverse,
      whiteBackground,
      bgColor,
      theme: { getRgba },
    }) => {
      if (hasBackground) {
        if (!isReverse) {
          return css`
            background: linear-gradient(
              90deg,
              rgb(255, 255, 255),
              ${whiteBackground}%,
              ${getRgba(bgColor)} ${whiteBackground}%
            );
          `;
        }

        return css`
          background: linear-gradient(
            270deg,
            rgb(255, 255, 255),
            ${whiteBackground}%,
            ${getRgba(bgColor)} ${whiteBackground}%
          );
        `;
      }
      return null;
    }};
  `)};
`;

export const SectionWithImageSt = styled.div<{
  leftWidth: number | number[];
}>`
  background-color: ${({ theme }) => theme.colors.white};
  width: 100%;

  ${media.md(css<{ type: TType; leftWidth?: number }>`
    max-width: ${({ leftWidth }) => leftWidth}%;
  `)};
`;

export const ImageWithInfoSectionSectionWrapperSt = styled.div<{
  leftWidth: number | number[];
  bgColor: TColor;
  theme: {
    getRgba: TGetRgba;
  };
}>`
  align-self: flex-start;
  width: 100%;

  ${media.md(css<{
    type: TType;
    leftWidth: number;
    bgColor: TColor;
    theme: {
      getRgba: TGetRgba;
    };
  }>`
    max-width: ${({ leftWidth }) => 100 - leftWidth}%;
    padding: 0 40px;
    ${({ bgColor, theme: { getRgba } }) => {
      return bgColor
        ? css`
            background-color: ${getRgba(bgColor)};
          `
        : null;
    }};
  `)};

  ${({ leftWidth }) => {
    if (Array.isArray(leftWidth)) {
      return css`
        ${leftWidth[0] &&
        media.sm(css`
          max-width: ${100 - leftWidth[0]}%;
        `)};
        ${leftWidth[1] &&
        media.md(css`
          max-width: ${100 - leftWidth[1]}%;
        `)};
        ${leftWidth[2] &&
        media.lg(css`
          max-width: ${100 - leftWidth[2]}%;
        `)};
        ${leftWidth[3] &&
        media.xl(css`
          max-width: ${100 - leftWidth[3]}%;
        `)};
      `;
    }

    return null;
  }};
`;

export const Wrapper = styled.div`
  ${({ showMobileVariant }: { showMobileVariant: boolean }) =>
    showMobileVariant &&
    css`
      #image-info-desktop {
        display: none;
      }

      ${media.md(css`
        #image-info-mobile {
          display: none;
        }
        #image-info-desktop {
          display: flex;
        }
      `)}
    `}
`;

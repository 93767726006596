import { routerReducer as routing } from 'react-router-redux';

import { home } from './marketplace/home/reducer';
import { reducer as fdlCalculator } from './shared/modules/FDL/reducer';
import tradeIn from './shared/modules/FDL/tradein/redux';
import { getFilterReducerForType } from './marketplace/filter/reducer';
import { search } from './marketplace/search/reducer';
import { searchFilters } from './marketplace/search/filters/redux/reducer';
import { contentful } from './marketplace/contentful/reducer';
import { crm } from './marketplace/crm/reducer';
import { vehicle } from './marketplace/vehicle/reducer';
import { seo } from './marketplace/seo/reducer';
import { globalEvents } from './shared/modules/globalEvents/reducer';
import { geolocation } from './marketplace/geolocation/reducer';
import { pageTemplate } from './shared/modules/pageTemplate/reducer';
import { headerCLP } from './marketplace/contentful/shared/ConnectedHeaderCLP/reducer';
import { moreLikeThisListings } from './marketplace/moreLikeThisListings/reducer';
import { propositionBar } from './marketplace/contentful/shared/ConnectedPropositionBar/reducer';
import { overlay } from './shared/modules/overlay/reducer';
import { notifications } from './shared/modules/notifications/reducer';
import { saveSearch } from './marketplace/saveSearch/reducer';
import { specialOffers } from './marketplace/specialOffers/reducer';
import { dealerProfile } from './shared/modules/dealerProfile/reducer';
// vehicle checkout
import { vehicle as checkoutVehicle } from './marketplace/vehicleCheckout/redux/reducers/checkoutSharedInfo/vehicle';
import { calculation as checkoutCalculation } from './marketplace/vehicleCheckout/redux/reducers/checkoutSharedInfo/calculation';
import { checkoutOrderInfo } from './marketplace/vehicleCheckout/redux/reducers/checkoutOrderInfo';
import { checkoutJourneyInfo } from './marketplace/vehicleCheckout/redux/reducers/checkoutJourneyInfo';

export const memoryStorageReducers = {
  routing,
  home,
  filter: getFilterReducerForType(),
  search,
  searchFilters,
  contentful,
  vehicle,

  // finance checkout
  checkoutVehicle,
  checkoutOrderInfo,
  checkoutCalculation,
  checkoutJourneyInfo,

  //  Oh leads lead us to the money
  seo,
  globalEvents,
  geolocation,
  fdlCalculator,
  pageTemplate,
  headerCLP,
  moreLikeThisListings,
  propositionBar,
  saveSearch,
  overlay,
  notifications,
  tradeIn,
  specialOffers,
  dealerProfile,
  crm,
};

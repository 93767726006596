import styled, { css } from 'styled-components';
import { LinkButton } from 'app/shared/ui';

import { media } from '../../../../../../shared/styles_js/variables';
import { ArticleContainerSt } from '../SharedComponentsSt/SharedComponentsSt.css';

export const ArticleButtonContainerSt = styled(ArticleContainerSt)`
  display: flex;
  justify-content: center;
`;

export const ButtonSt = styled(LinkButton)`
  display: flex;
  width: 100%;
  justify-content: center;
  padding-right: 48px;
  padding-left: 48px;

  ${media.md(css`
    width: auto;
  `)};
`;

export const ButtonTextSt = styled.div`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

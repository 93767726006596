import styled, { css } from 'styled-components';
import { Flex } from '@rebass/grid';

export const PageTemplateContainerSt = styled.main``;

interface IPageTemplateContentWrapper {
  ContentWrapperPaddingTop: string;
  isLoading: boolean;
}

export const PageTemplateContentWrapperSt = styled(
  Flex,
)<IPageTemplateContentWrapper>`
  flex: 1;
  padding-top: ${
    /* istanbul ignore next */ ({ ContentWrapperPaddingTop }) =>
      ContentWrapperPaddingTop
  };

  ${({ isLoading }) =>
    isLoading &&
    css`
      opacity: 0;
    `};
`;

import { getNormalizedVehicle } from 'app/shared/utils/vehicle';

import * as ActionTypes from './constants';

export const defaultState = () => {
  return {
    pending: false,
    prevSearchUrl: '/gebrauchtwagen',
    vehicle: getNormalizedVehicle({}),
    error: null,
  };
};

export function vehicle(state = defaultState(), action) {
  const { type, payload, error } = action;

  if (type === ActionTypes.GET_VEHICLE_REQUEST) {
    return { ...state, pending: true };
  }

  if (type === ActionTypes.GET_VEHICLE_SUCCESS) {
    return {
      ...state,
      vehicle: getNormalizedVehicle(payload.content[0]),
      pending: false,
      error: null,
    };
  }

  if (type === ActionTypes.GET_VEHICLE_FAILURE) {
    return {
      ...state,
      error,
      pending: false,
      vehicle: getNormalizedVehicle({}),
    };
  }

  if (type === ActionTypes.RESET_VEHICLE) {
    return {
      ...state,
      vehicle: getNormalizedVehicle({}),
    };
  }

  if (type === ActionTypes.SET_SEARCH_URL) {
    return { ...state, prevSearchUrl: action.payload.searchUrl };
  }

  if (type === ActionTypes.GET_ADDITIONAL_LISTINGS_REQUEST) {
    return { ...state, pending: true };
  }

  if (type === ActionTypes.GET_ADDITIONAL_LISTINGS_SUCCESS) {
    return {
      ...state,
      pending: false,
      oneClickLeads: {
        automatedListings: payload.automatedListings,
        selectableListings: payload.selectableListings,
        condition: payload.condition,
        pills: payload.pills,
      },
    };
  }

  if (type === ActionTypes.GET_ADDITIONAL_LISTINGS_FAILURE) {
    return {
      ...state,
      pending: false,
      oneClickLeads: {
        error,
        automatedListings: [],
        selectableListings: [],
        condition: 0,
      },
    };
  }

  if (type === ActionTypes.GET_VEHICLE_RESERVATION_STATUS_REQUEST) {
    return { ...state, pending: true };
  }

  if (type === ActionTypes.GET_VEHICLE_RESERVATION_STATUS_SUCCESS) {
    return {
      ...state,
      pending: false,
      vehicle: { ...state.vehicle, available: payload.available },
    };
  }

  if (type === ActionTypes.GET_VEHICLE_RESERVATION_STATUS_FAILURE) {
    return { ...state, pending: false };
  }

  if (type === ActionTypes.SET_SEARCH_URL) {
    return { ...state, prevSearchUrl: action.payload.searchUrl };
  }

  return state;
}

import { ActionTypes } from './constants';

export const initialProfile = () => ({
  firstName: '',
  lastName: '',
  email: '',
  phoneNumber: '',
});

export const initialState = () => ({
  token: null,
  error: null,
  user: null,
  profile: initialProfile(),
  passwordChanged: false,
  justRegistered: false,
  showSaSeDashboardTip: false,
  saSeDashboardTipStatus: true,
});

export const auth = (state = initialState(), action) => {
  switch (action.type) {
    case ActionTypes.LOGIN_COMPLETED:
      return {
        ...state,
        token: {
          ...action.payload.token,
          createdAt: new Date(),
        },
        user: action.payload.user,
        error: null,
      };
    case ActionTypes.REGISTER_COMPLETED:
      return {
        ...state,
        token: {
          ...action.payload,
          createdAt: new Date(),
        },
        justRegistered: true,
        user: null,
        error: null,
      };
    case ActionTypes.LOGOUT:
      return initialState();
    case ActionTypes.PASSWORD_CHANGED:
      return {
        ...state,
        passwordChanged: action.payload,
      };
    case ActionTypes.GET_PROFILE_COMPLETED:
      return {
        ...state,
        error: null,
        profile: {
          ...initialProfile(),
          ...action.payload,
        },
      };
    case ActionTypes.UPDATE_PROFILE_FAILED:
      return {
        ...state,
        error: action.payload,
      };
    case ActionTypes.GET_PROFILE_FAILED:
    case ActionTypes.LOGIN_FAILED:
    case ActionTypes.REGISTER_FAILED:
      return {
        ...state,
        error: action.payload,
        token: null,
        user: null,
      };
    case ActionTypes.RESET_LOGIN_FAILURE:
      return {
        ...state,
        error: null,
      };
    case ActionTypes.REMOVE_JUST_REGISTERED:
      return {
        ...state,
        justRegistered: false,
      };
    case ActionTypes.SHOW_SASE_DASHBOARD_MODAL:
      return {
        ...state,
        showSaSeDashboardTip: true,
        saSeDashboardTipStatus: false,
      };

    case ActionTypes.HIDE_SASE_DASHBOARD_MODAL:
      return {
        ...state,
        saSeDashboardTipStatus: false,
        showSaSeDashboardTip: false,
      };
    default:
      return state;
  }
};

auth.blacklist = ['error', 'showSaSeDashboardTip'];

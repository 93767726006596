import { memo } from 'react';
import PropTypes from 'prop-types';

import { CARTILE_PADDING } from 'app/shared/modules/Cartile/CartileTemplates/CartileTemplates.constant';
import { CartileImageRemoveIconSt } from 'app/shared/modules/Cartile/CartileUIComponents/CartileLink/CartileImageRemoveIcon/CartileImageRemoveIcon.css';

const CartileImageRemoveIconCmp = ({
  isHorizontal,
  paddingHorizontal,
  handleToggleFavourites,
}) => (
  <CartileImageRemoveIconSt
    className="if-icon-close"
    isHorizontal={isHorizontal}
    onClick={handleToggleFavourites}
    paddingHorizontal={paddingHorizontal}
  />
);

CartileImageRemoveIconCmp.propTypes = {
  paddingHorizontal: PropTypes.number,
  isHorizontal: PropTypes.bool.isRequired,
  handleToggleFavourites: PropTypes.func.isRequired,
};

CartileImageRemoveIconCmp.defaultProps = {
  paddingHorizontal: CARTILE_PADDING,
};

export const CartileImageRemoveIcon = memo(CartileImageRemoveIconCmp);

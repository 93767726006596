import styled from 'styled-components';

import { Button } from 'app/shared/ui';

export const ButtonSt = styled(Button)`
  width: 10px;
  min-width: 50px;
  border-radius: 0 4px 4px 0;
  transition: background-color 200ms ease-in-out;
`;

import { mappedServices } from './constants';

/**
 * @description gets user's selected financing optional services
 * */
export const getFinancingOptionalsServices = parameters => {
  const parts = [];

  const rateProtectionServices = parameters.find(({ id }) => id === 'rsv');
  if (rateProtectionServices && rateProtectionServices.value !== 'none') {
    parts.push(rateProtectionServices.label);
  }

  const additonalServices = parameters.find(
    ({ id }) => id === 'additionalServices',
  );

  if (additonalServices) {
    additonalServices.value.split(',').forEach(value => {
      const mappedService = mappedServices.find(
        service => service.value === value,
      );
      if (mappedService) {
        parts.push(mappedService.label);
      }
    });
  }

  return parts?.join(', ') || undefined;
};

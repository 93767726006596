import { Box, Flex } from '@rebass/grid';
import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import { Text } from 'app/shared/ui';
import { Button } from 'app/shared/components/Button/Button';
import { FlexCol } from 'app/marketplace/shared/components/Layouts/FlexCol';
import { FINANCING_OPTIONS } from 'app/shared/modules/FDL/constants';
import { TRACKING_LIST } from 'app/shared/modules/tracking/list';

import { trackAction } from '../../../utils/tracking';

import {
  ModalSt,
  ModalFlexRow,
  Title,
  ButtonArea,
  Image,
  ResultTitle,
  ResultDescription,
  CustomList,
  RatePlanImage,
  NavigateButton,
  RadioSt,
  RadioCol,
  RadioColSt,
  RadioText,
  HeaderNavigateSt,
  HeaderIconSt,
  ImageWrapperSt,
} from './FDLWizard.css';

/* istanbul ignore next */
const modalRoot = CLIENT
  ? document.querySelector('#modals')
  : {
      appendChild: () => {},
      removeChild: () => {},
    };
const step1Answers = ['ownership', 'usage', 'flexibility'];
const step2Answers = ['with_finalrate', 'without_finalrate'];

const resultToFinancingProduct = {
  render3WayFinancing: FINANCING_OPTIONS.THREE_WAY_FINANCING,
  renderStandardFinancing: FINANCING_OPTIONS.STANDARD_FINANCING,
  renderPrivateLeasing: FINANCING_OPTIONS.PRIVATE_LEASING,
};

/**
 * @memberof ConnectedFDLWizard.js
 * @description Finanzierungsratgeber's (financing guide page) wizard
 * that guides the user through a group of questions and returns the
 * recommended financing type based on the given answers.
 */
export class FDLWizard extends PureComponent {
  constructor(props) {
    super(props);
    this.renderSteps = this.renderSteps.bind(this);
    this.renderStep1 = this.renderStep1.bind(this);
    this.renderStep2 = this.renderStep2.bind(this);
    this.renderStep3 = this.renderStep3.bind(this);
    this.onClickCheckboxes = this.onClickCheckboxes.bind(this);
    this.onClickBack = this.onClickBack.bind(this);
    this.onClickNext = this.onClickNext.bind(this);
    this.calculateResult = this.calculateResult.bind(this);
    this.render3WayFinancing = this.render3WayFinancing.bind(this);
    this.renderStandardFinancing = this.renderStandardFinancing.bind(this);
    this.renderPrivateLeasing = this.renderPrivateLeasing.bind(this);
    this.onRedirectClick = this.onRedirectClick.bind(this);
    this.onClose = this.onClose.bind(this);

    this.state = {
      step: 1,
      firstQuestion: 0,
      secondQuestion: 0,
    };
  }

  componentDidMount() {
    const { modalId, addModal } = this.props;

    if (modalId) {
      addModal(modalId);
    }
    this.el = document.createElement('div');
    modalRoot.appendChild(this.el);
  }

  componentWillUnmount() {
    const { modalId, removeModal } = this.props;

    if (modalId) {
      removeModal(modalId);
    }
    modalRoot.removeChild(this.el);
  }

  onClose() {
    const { modalId, triggerModal, toggleWizardPopup, onClose } = this.props;

    if (modalId) {
      triggerModal(modalId);
    } else {
      toggleWizardPopup();
    }

    this.setState({
      step: 1,
      firstQuestion: 0,
      secondQuestion: 0,
    });

    if (onClose) {
      onClose();
    }
  }

  onClickCheckboxes(question, answer) {
    this.setState({
      [question]: answer,
    });
  }

  onClickBack() {
    const { step } = this.state;

    if (step === 1) {
      this.onClose();

      return;
    }

    this.setState({
      step: step - 1,
    });
  }

  onClickNext() {
    const { step, firstQuestion, secondQuestion } = this.state;

    if (step === 1) {
      trackAction(TRACKING_LIST.FINANCING.WIZARD.STEP_1, {
        label: step1Answers[firstQuestion],
      });
    }

    if (step === 2) {
      trackAction(TRACKING_LIST.FINANCING.WIZARD.STEP_2, {
        label: step2Answers[secondQuestion],
      });
      trackAction(TRACKING_LIST.FINANCING.WIZARD.RESULT, {
        label: this.calculateResult()
          .replace('render', '')
          .replace(/\.?([A-Z])/g, (x, y) => {
            return `_${y.toLowerCase()}`;
          })
          .replace(/^_/, ''),
      });
      this.setState({
        step: 3,
        result: this.calculateResult(),
      });

      return;
    }

    this.setState({
      step: step + 1,
    });
  }

  /**
   * @description redirects from the financing guide page to the
   * CLP (Catalogue Listing Page) with financing product filter selected
   */
  onRedirectClick() {
    const { modalId, triggerModal, toggleWizardPopup, goToSearch } = this.props;

    if (modalId) {
      triggerModal(modalId);
    } else {
      toggleWizardPopup();
    }
    const result = this.calculateResult();
    trackAction(TRACKING_LIST.FINANCING.WIZARD.CTA, {
      label: result
        .replace('render', '')
        .replace(/\.?([A-Z])/g, (x, y) => {
          return `_${y.toLowerCase()}`;
        })
        .replace(/^_/, ''),
    });

    goToSearch(resultToFinancingProduct[result], true);
  }

  calculateResult() {
    const { firstQuestion, secondQuestion } = this.state;

    if (secondQuestion === 0) {
      return 'render3WayFinancing';
    }

    if (firstQuestion === 0) {
      return 'renderStandardFinancing';
    }

    return 'renderPrivateLeasing';
  }

  renderStep1() {
    const { firstQuestion } = this.state;

    return (
      <>
        <Title>
          <h3>Mobilität</h3>
        </Title>
        <ModalFlexRow columnCount={3}>
          <RadioCol>
            <RadioSt
              checked={firstQuestion === 0}
              onChange={() => this.onClickCheckboxes('firstQuestion', 0)}
            >
              <RadioText>
                <span>
                  Besitz ist mir wichtig. Das Fahrzeug soll am Ende der
                  Finanzierung in mein Eigentum übergehen.
                </span>
              </RadioText>
            </RadioSt>
          </RadioCol>
          <RadioCol>
            <RadioSt
              checked={firstQuestion === 1}
              onChange={() => this.onClickCheckboxes('firstQuestion', 1)}
            >
              <RadioText>
                <span>
                  Ich möchte das Fahrzeug über die Laufzeit nutzen und dann die
                  Möglichkeit haben, auf ein Neues umzusteigen.
                </span>
              </RadioText>
            </RadioSt>
          </RadioCol>
          <RadioCol>
            <RadioSt
              checked={firstQuestion === 2}
              onChange={() => this.onClickCheckboxes('firstQuestion', 2)}
            >
              <RadioText>
                <span>
                  Ich möchte flexibel sein und nicht heute entscheiden, ob ich
                  das Fahrzeug langfristig behalten möchte oder nicht.
                </span>
              </RadioText>
            </RadioSt>
          </RadioCol>
        </ModalFlexRow>
      </>
    );
  }

  renderStep2() {
    const { secondQuestion } = this.state;

    return (
      <>
        <Title>
          <h3>Ratenplan</h3>
        </Title>
        <ModalFlexRow columnCount={2}>
          <RadioColSt>
            <RadioSt
              checked={secondQuestion === 0}
              onChange={() => this.onClickCheckboxes('secondQuestion', 0)}
            >
              <Flex flexDirection={['column', 'row']}>
                <RadioText>
                  <span>
                    Besonders niedrige Monatsraten, dafür eine höhere
                    Schlussrate.
                  </span>
                </RadioText>
                <RatePlanImage src="/assets/fdl-wizard-wfinal.svg" />
              </Flex>
            </RadioSt>
          </RadioColSt>
          <RadioColSt>
            <RadioSt
              checked={secondQuestion === 1}
              onChange={() => this.onClickCheckboxes('secondQuestion', 1)}
            >
              <Flex flexDirection={['column', 'row']}>
                <RadioText>
                  <span>
                    Gleichbleibende monatliche Raten über die gesamte Laufzeit.
                  </span>
                </RadioText>
                <RatePlanImage src="/assets/fdl-wizard-wofinal.svg" />
              </Flex>
            </RadioSt>
          </RadioColSt>
        </ModalFlexRow>
      </>
    );
  }

  render3WayFinancing() {
    return (
      <Box>
        <Flex>
          <Box>
            <Text mb={16} lineHeight={2}>
              <ResultTitle>3-Wege-Finanzierung</ResultTitle>
            </Text>
            <Text as="p">
              Das Finanzierungsprodukt, das am besten zu deinen Präferenzen
              passt, ist die 3-Wege-Finanzierung.
            </Text>
          </Box>
          <ImageWrapperSt>
            <Image src="/assets/icon-cup.svg" />
          </ImageWrapperSt>
        </Flex>
        <Box>
          <Text as="p" fontWeight="bold">
            <ResultDescription>Deine Vorteile:</ResultDescription>
          </Text>

          <CustomList
            themeName="white"
            list={[
              <span key={1}>Niedrige monatliche Raten</span>,
              <span key={2}>Viel Entscheidungsfreiheit</span>,
              <span key={3}>
                Drei flexible Optionen zur Ablösung der Schlussrate
              </span>,
            ]}
          />
        </Box>
      </Box>
    );
  }

  renderStandardFinancing() {
    return (
      <Box>
        <Flex>
          <Box>
            <Text mb={16} lineHeight={2}>
              <ResultTitle>Standardfinanzierung</ResultTitle>
            </Text>

            <Text as="p">
              Das Finanzierungsprodukt, das am besten zu deinen Präferenzen
              passt, ist die Standardfinanzierung.
            </Text>
          </Box>
          <ImageWrapperSt>
            <Image src="/assets/icon-cup.svg" />
          </ImageWrapperSt>
        </Flex>
        <Box>
          <Text as="p" fontWeight="bold">
            <ResultDescription>Deine Vorteile:</ResultDescription>
          </Text>
          <CustomList
            themeName="white"
            list={[
              <Text key={1}>
                <span>Keine Kilometer-Einschränkung</span>
              </Text>,
              <Text key={2}>
                <span>Oft gibt es günstige Kreditangebote</span>
              </Text>,
              <Text key={3}>
                <span>
                  Gute Planbarkeit durch gleichbleibende monatliche Raten bis
                  zum Schluss
                </span>
              </Text>,
            ]}
          />
        </Box>
      </Box>
    );
  }

  renderPrivateLeasing() {
    return (
      <Box>
        <Flex>
          <Box>
            <Text mb={16} lineHeight={2}>
              <ResultTitle>Privatleasing</ResultTitle>
            </Text>
            <Text as="p">
              Das Finanzierungsprodukt, das am besten zu deinen Präferenzen
              passt, ist Privatleasing.
            </Text>
          </Box>
          <ImageWrapperSt>
            <Image src="/assets/icon-cup.svg" />
          </ImageWrapperSt>
        </Flex>
        <Text as="p" fontWeight="bold">
          <ResultDescription>Deine Vorteile:</ResultDescription>
        </Text>
        <CustomList
          themeName="white"
          list={[
            <span key={1}>Kein Gebrauchtwagenrisiko am Vertragsende</span>,
            <span key={2}>
              Relativ niedrige Monatsraten oftmals selbst ohne Anzahlung
            </span>,
            <span key={3}>
              Immer ein neuwertiges Auto und aktuelles Model fahren
            </span>,
          ]}
        />
      </Box>
    );
  }

  renderStep3() {
    const { result } = this.state;

    return (
      <Flex flexDirection="column">
        {this[result]()}
        <Flex m={[0, 'auto']} width={[1, '60%']}>
          <NavigateButton
            fullSize
            bStyle="primary"
            onClick={this.onRedirectClick}
          >
            <span>Jetzt passende Angebote finden</span>
          </NavigateButton>
        </Flex>
      </Flex>
    );
  }

  renderSteps() {
    const { step } = this.state;

    switch (step) {
      case 3:
        return this.renderStep3();
      case 2:
        return this.renderStep2();
      default:
        return this.renderStep1();
    }
  }

  render() {
    const { isOpen } = this.props;
    const { step } = this.state;

    return (
      isOpen && (
        <ModalSt open={isOpen} onClose={this.onClose}>
          {step !== 1 && (
            <HeaderNavigateSt onClick={this.onClickBack}>
              <HeaderIconSt icon="arrow-left-short" />
              <Text fontsize={16}>Zurück</Text>
            </HeaderNavigateSt>
          )}
          <Box mt={48}>{this.renderSteps()}</Box>
          {step !== 3 && (
            <ButtonArea columnCount={1}>
              <FlexCol>
                <Button fullSize bStyle="primary" onClick={this.onClickNext}>
                  <span>Weiter</span>
                </Button>
              </FlexCol>
            </ButtonArea>
          )}
        </ModalSt>
      )
    );
  }
}

FDLWizard.defaultProps = {
  isOpen: false,
};

FDLWizard.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  modalId: PropTypes.string,
  addModal: PropTypes.func,
  removeModal: PropTypes.func,
  goToSearch: PropTypes.func,
  triggerModal: PropTypes.func,
  toggleWizardPopup: PropTypes.func,
};

import { connect } from 'react-redux';
import { NotificationCenter } from './NotificationCenter';
import { hideNotification, clearNotification } from './actions';

const mapStateToProps = ({ notifications }) => ({ ...notifications });
const mapDispatchToProps = { hideNotification, clearNotification };
export const ConnectedNotificationCenter = connect(
  mapStateToProps,
  mapDispatchToProps,
)(NotificationCenter);

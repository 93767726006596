import {
  PICKUP_LOCATION_GET,
  PICKUP_LOCATION_GET_ERROR,
  PICKUP_LOCATION_GET_SUCCESS,
  PICKUP_LOCATION_RESET,
} from '../../constants';

export const initialState = {
  /**
   * Example:
   * [{
   *   "id": "e8b1520d-8f3a-4b92-8a0f-38c738c89396",
   *   "dealerId": "f9940685-012f-4b2e-ab5f-b9123bd7d777",
   *   "city": "Daun",
   *   "street": "Bitburger Str.",
   *   "brand": "VW, Skoda, DasWeltAUto",
   *   "companyNumber": 20079,
   *   "region": 184
   * }, ...]
   * @type {Array}
   */
  locations: [],
  noResults: false,
  error: undefined,
};

export function pickupLocation(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case PICKUP_LOCATION_GET:
      return { ...state, error: undefined };
    case PICKUP_LOCATION_GET_SUCCESS:
      return {
        ...state,
        locations: payload?.results || payload?.suggestions || [],
        noResults: !Array.isArray(payload?.results),
      };
    case PICKUP_LOCATION_GET_ERROR:
      return { ...state, error: payload };
    case PICKUP_LOCATION_RESET:
      return { ...initialState };
    default:
      return state;
  }
}

/* eslint-disable react/no-multi-comp */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* istanbul ignore file */
import { Text, Link } from 'app/shared/ui';
import { LazyFavouriteTip as FavouriteTip } from 'app/shared/modules/overlay/defaultOverlays/favouritesAuth/FavouriteTip/LazyFavouriteTip';
import { LazySaveSearchTipModal as SaveSearchTipModal } from 'app/marketplace/saveSearch/SaveSearchTipModal/LazySaveSearchTipModal';

import {
  SupportLinkSt,
  IconSt,
  FavIconSt,
  IndicatorSt,
  TitleSt,
  NavLinkSt,
} from './NewMarketplaceTopNavBar.css';
import { FavIconProps, ILogoProps } from './NewMarketplaceTopNavBar.types';

export const WHATS_APP_LINK = 'https://wa.me/4930255585288';

export const footerContent = (
  isMobileDevice: boolean,
  isD2C = false,
): JSX.Element => {
  const emailAddress = isD2C ? 'kontakt@hey.car' : 'kundenservice@hey.car';
  const phoneNumber = isD2C ? '493030809241' : '4930255585508';
  const isWhatsAppActive = false;
  /* ^^Removing WA options until CRM decides to have it back */

  return (
    <Text>
      {'Wir begleiten dich von der Suche bis zur Abholung. E-Mail an '}
      <SupportLinkSt useRouter={false} to={`mailto:${emailAddress}`}>
        {emailAddress}
      </SupportLinkSt>
      {' oder Anruf unter: '}
      <SupportLinkSt useRouter={false} to={`tel:00${phoneNumber}`}>
        {`+${phoneNumber}`}
      </SupportLinkSt>
      {isWhatsAppActive && isMobileDevice && (
        <span id="whatsapp-link">
          {' oder direkt per '}
          <Link useRouter={false} to={WHATS_APP_LINK}>
            WhatsApp
          </Link>
          {'  beraten lassen.'}
        </span>
      )}
    </Text>
  );
};

export const Logo = ({ isHome, isLoading }: ILogoProps): JSX.Element => (
  <TitleSt isHome={isHome} isStuck={isLoading} isHidden={false} to="/" />
);

// replaces components on line 273 from MarketplaceTopNavBar.js
export const FavouriteIcon = ({
  isMobileDevice,
  onFavouriteClick,
  isTipModal,
  favouritesCount,
  showFavouriteTip,
  showSaSeDashboardTip,
  isFavourite,
  hideFavouriteTip,
  openOverlay,
  closeOverlay,
  target,
  hideSaSeDashboardTip,
}: FavIconProps): JSX.Element => (
  <NavLinkSt
    textDecoration="none"
    fontWeight="normal"
    color="micaBlue"
    $isMobileDevice={isMobileDevice}
    key="account"
    onClick={onFavouriteClick}
    to="/favourites"
    $isTipModal={isTipModal}
  >
    <IconSt>
      <FavIconSt
        className={
          isFavourite ? 'if-icon-favorite-active' : 'if-icon-favorite-inactive'
        }
      />
      {showFavouriteTip && (
        <FavouriteTip
          hideFavouriteTip={hideFavouriteTip}
          openOverlay={openOverlay}
          closeOverlay={closeOverlay}
          target={target}
          isNewNavigation
        />
      )}

      {showSaSeDashboardTip && (
        <SaveSearchTipModal
          hideSaSeDashboardTip={hideSaSeDashboardTip}
          openOverlay={openOverlay}
          closeOverlay={closeOverlay}
          target={target}
          isNewNavigation
        />
      )}

      {favouritesCount !== undefined && favouritesCount > 0 && (
        <IndicatorSt
          showFavouriteTip={showFavouriteTip}
          showSaSeDashboardTip={showSaSeDashboardTip}
        >
          <span>{favouritesCount}</span>
        </IndicatorSt>
      )}
    </IconSt>
  </NavLinkSt>
);
